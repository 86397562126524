import { useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  deleteActionGroup as deleteSingleActionGroup,
  footprintActionGroupCreate,
  footprintActionGroupDelete,
  upsertActionGroup,
  upsertDocumentActionGroup,
} from "../services/graphql/mutations"
import {
  actionGroup,
  listActionGroups,
  searchActionGroups,
} from "../services/graphql/queries"
import {
  deepCopy,
  logger,
  lowercaseFirstCharacter,
  Status,
} from "../services/utilities/utility"
import { MainContext } from "./main"
import ActionGroup from "../models/actionGroup"
import ActionGroupsDocument from "../models/actionGroupsDocument"

interface AutocompleteOption {
  label: string
  id: string
}

interface ActionGroupsContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  actionGroupsList: ActionGroup[]
  getActionGroupsList: (withLoading?: boolean) => void
  doneChanges: boolean
  cancelChanges: () => void
  currentActionGroup: ActionGroup
  setCurrentActionGroup: Dispatch<SetStateAction<ActionGroup>>
  getCurrentActionGroup: (actionId: string) => Promise<boolean>
  preChangesCurrentActionGroup: ActionGroup
  actionGroupsListNextToken: string | null
  loadMoreActionGroups: () => Promise<boolean>
  hasSearched: boolean
  setHasSearched: Dispatch<SetStateAction<boolean | null>>
  searchCanBecomeHabit: boolean | null
  setSearchCanBecomeHabit: Dispatch<SetStateAction<boolean | null>>
  searchLang: AutocompleteOption | null
  setSearchLang: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchCategory: AutocompleteOption | null
  setSearchCategory: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchTitle: string
  setSearchTitle: Dispatch<SetStateAction<string>>
  searchFeatured: boolean | null
  setSearchFeatured: Dispatch<SetStateAction<boolean | null>>
  searchActionGroupsList: (
    withLoading?: boolean,
    withNextToken?: boolean
  ) => Promise<boolean>
  createActionGroup: (input: {
    actions: { id: string }[]
    canBecomeHabit: boolean
    handle: string
    image: string
    maxIterations: number
    category: {
      id: string
    }
    sdgs: { primary: boolean; id: string }[]
    sdgTargets: { primary: boolean; id: string }[]
    featured: boolean
    footprintPrediction?: number
    badge?: {
      id: string
    }
  }) => Promise<string | boolean>
  createActionGroupDocument: (input: {
    parentId: string
    type: string
    actionGroupDocumentItems: {
      lang: string
      title: string
      default: boolean
      body: object[]
      benchmark?: string
    }[]
  }) => Promise<boolean>
  hasError: boolean
  addTranslation: (translationToAdd: string) => void
  handleError: boolean
  setHandleError: Dispatch<SetStateAction<boolean>>
  maxIterationsError: boolean
  setMaxIterationsError: Dispatch<SetStateAction<boolean>>
  categoryError: boolean
  setCategoryError: Dispatch<SetStateAction<boolean>>
  secondarySdgsError: boolean
  setSecondarySdgsError: Dispatch<SetStateAction<boolean>>
  secondarySdgTargetsError: boolean
  setSecondarySdgTargetsError: Dispatch<SetStateAction<boolean>>
  footprintPredictionError: boolean
  setFootprintPredictionError: Dispatch<SetStateAction<boolean>>
  upsertActionGroupParent: () => Promise<boolean>
  translationsErrors: { lang: string; hasErrors: boolean }[]
  setTranslationsErrors: Dispatch<
    SetStateAction<{ lang: string; hasErrors: boolean }[]>
  >
  copyDetailsFromDefault: (itemToCopyToIndex: number) => void
  copyBodyFromDefault: (itemToCopyToIndex: number) => void
  upsertActionGroupDocument: () => Promise<boolean>
  deleteActionGroup: (actionGroupToDeleteId: string) => Promise<boolean>
  upsertFootprintQuestion: (
    actionGroupId: string,
    questionId?: string
  ) => Promise<boolean>
  getAllActionGroupsList: () => Promise<string[][]>
}

const ActionGroupsContext = createContext<ActionGroupsContextInterface>({
  loading: true,
  setLoading: () => {},
  updatingList: false,
  setUpdatingList: () => {},
  editMode: false,
  setEditMode: () => {},
  actionGroupsList: [],
  getActionGroupsList: () => {},
  doneChanges: false,
  cancelChanges: () => {},
  currentActionGroup: new ActionGroup(),
  setCurrentActionGroup: () => {},
  getCurrentActionGroup: async () => true,
  preChangesCurrentActionGroup: new ActionGroup(),
  actionGroupsListNextToken: null,
  loadMoreActionGroups: async () => true,
  hasSearched: false,
  setHasSearched: () => {},
  searchCanBecomeHabit: null,
  setSearchCanBecomeHabit: () => {},
  searchLang: null,
  setSearchLang: () => {},
  searchCategory: null,
  setSearchCategory: () => {},
  searchTitle: "",
  setSearchTitle: () => {},
  searchFeatured: null,
  setSearchFeatured: () => {},
  searchActionGroupsList: async () => true,
  createActionGroup: async () => "",
  createActionGroupDocument: async () => true,
  hasError: false,
  addTranslation: () => {},
  handleError: false,
  setHandleError: () => {},
  maxIterationsError: false,
  setMaxIterationsError: () => {},
  categoryError: false,
  setCategoryError: () => {},
  secondarySdgsError: false,
  setSecondarySdgsError: () => {},
  secondarySdgTargetsError: false,
  setSecondarySdgTargetsError: () => {},
  footprintPredictionError: false,
  setFootprintPredictionError: () => {},
  upsertActionGroupParent: async () => true,
  translationsErrors: [],
  setTranslationsErrors: () => {},
  copyDetailsFromDefault: () => {},
  copyBodyFromDefault: () => {},
  upsertActionGroupDocument: async () => true,
  deleteActionGroup: async () => true,
  upsertFootprintQuestion: async () => true,
  getAllActionGroupsList: async () => [],
})

const ActionGroupsController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // states
  const [loading, setLoading] = useState<boolean>(true) // loading
  const [updatingList, setUpdatingList] = useState<boolean>(false) // to show loader
  const [editMode, setEditMode] = useState<boolean>(true) // if all page should be disabled or not
  const [actionGroupsList, setActionGroupsList] = useState<ActionGroup[]>([]) // list of all action groups
  const [doneChanges, setDoneChanges] = useState<boolean>(false) // if user has done changes
  const [currentActionGroup, setCurrentActionGroup] = useState<ActionGroup>(
    new ActionGroup()
  ) // current action group
  const [preChangesCurrentActionGroup, setPreChangesCurrentActionGroup] =
    useState<ActionGroup>(new ActionGroup()) // fetched current action group
  const [actionGroupsListNextToken, setActionGroupsListNextToken] = useState<
    string | null
  >(null) // next token for action groups list
  const [hasSearched, setHasSearched] = useState<boolean>(false) // if user has searched or not

  // errors for action group edit
  const [handleError, setHandleError] = useState<boolean>(false) // error for action handle
  const [maxIterationsError, setMaxIterationsError] = useState<boolean>(false) // error for max iterations
  const [categoryError, setCategoryError] = useState<boolean>(false) // error for category
  const [secondarySdgsError, setSecondarySdgsError] = useState<boolean>(false) // error for secondary sdgs
  const [secondarySdgTargetsError, setSecondarySdgTargetsError] =
    useState<boolean>(false) // error for secondary sdg targets
  const [footprintPredictionError, setFootprintPredictionError] =
    useState<boolean>(false) // error for footprint prediction
  const [translationsErrors, setTranslationsErrors] = useState<
    { lang: string; hasErrors: boolean }[]
  >([]) // errors array for translations
  const hasError: boolean =
    handleError ||
    maxIterationsError ||
    categoryError ||
    secondarySdgsError ||
    secondarySdgTargetsError ||
    footprintPredictionError ||
    translationsErrors.filter((item) => item.hasErrors).length !== 0 // if there are errors or not

  // reset all errors
  const resetErrors = () => {
    setHandleError(false)
    setMaxIterationsError(false)
    setCategoryError(false)
    setSecondarySdgsError(false)
    setSecondarySdgTargetsError(false)
    setFootprintPredictionError(false)
    const newTranslationsErrors: { lang: string; hasErrors: boolean }[] = []
    currentActionGroup.document.items.forEach((item) => {
      newTranslationsErrors.push({ lang: item.lang, hasErrors: false })
    })
    setTranslationsErrors(newTranslationsErrors)
  }

  // search states
  const [searchCanBecomeHabit, setSearchCanBecomeHabit] = useState<
    boolean | null
  >(null)
  const [searchLang, setSearchLang] = useState<AutocompleteOption | null>(null)
  const [searchCategory, setSearchCategory] =
    useState<AutocompleteOption | null>(null)
  const [searchFeatured, setSearchFeatured] = useState<boolean | null>(null)
  const [searchTitle, setSearchTitle] = useState<string>("")

  // queries
  const [listActionGroupsQuery] = useLazyQuery(listActionGroups)
  const [actionGroupQuery] = useLazyQuery(actionGroup)
  const [searchActionGroupsQuery] = useLazyQuery(searchActionGroups)

  // mutations
  const [upsertActionGroupMutation] = useMutation(upsertActionGroup)
  const [upsertDocumentActionGroupMutation] = useMutation(
    upsertDocumentActionGroup
  )
  const [deleteActionGroupMutation] = useMutation(deleteSingleActionGroup)
  const [footprintActionGroupCreateMutation] = useMutation(
    footprintActionGroupCreate
  )
  const [footprintActionGroupDeleteMutation] = useMutation(
    footprintActionGroupDelete
  )

  // get action groups list
  const getActionGroupsList = useCallback(
    async (withLoading: boolean = true) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter((item) => item.name === "Series")[0].loading = true
        setPanelStatus([...panelStatus])
      }

      try {
        logger(Status.Api, "QUERY actionGroupsList", listActionGroups)
        const { data } = await listActionGroupsQuery({
          variables: {
            input: { limit: Math.round(window.innerHeight / 74) + 10 },
          },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "action groups list", data.actionGroupsList.items)

        setActionGroupsList(data.actionGroupsList.items)
        setActionGroupsListNextToken(data.actionGroupsList.nextToken)

        setHasSearched(false)
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Series")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "Series")[0].loading = false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `actionGroupsList`, e.message)
        }
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Series")[0].status = "error"
        panelStatus.filter((item) => item.name === "Series")[0].loading = false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // load more action groups
  const loadMoreActionGroups = async () => {
    try {
      logger(Status.Api, "QUERY actionGroupsList", listActionGroups)
      const { data } = await listActionGroupsQuery({
        variables: { input: { nextToken: actionGroupsListNextToken } },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "action groups list", [
        ...actionGroupsList,
        ...data.actionGroupsList.items,
      ])

      setActionGroupsList([...actionGroupsList, ...data.actionGroupsList.items])
      setActionGroupsListNextToken(data.actionGroupsList.nextToken)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `actionGroupsList`, e.message)
      }
      return false
    }
  }

  // search action groups
  const searchActionGroupsList = async (
    withLoading = true,
    withNextToken = false
  ) => {
    if (withLoading) {
      setUpdatingList(true)
    }

    try {
      const input: {
        limit: number
        canBecomeHabit?: boolean
        lang?: string
        category?: string
        title?: string
        featured?: boolean
        nextToken?: string
      } = {
        limit: Math.round(window.innerHeight / 74) + 10,
        canBecomeHabit: searchCanBecomeHabit,
        lang: searchLang ? searchLang.id : null,
        category: searchCategory ? searchCategory.id : null,
        title: searchTitle,
        featured: searchFeatured,
      }

      if (hasSearched && withNextToken) {
        input.nextToken = actionGroupsListNextToken
      }

      logger(Status.Api, "QUERY actionGroupsSearch", searchActionGroups)
      const { data } = await searchActionGroupsQuery({
        variables: { input: input },
        fetchPolicy: "no-cache",
      })

      if (input.nextToken) {
        logger(Status.Info, "action groups list", [
          ...actionGroupsList,
          ...data.actionGroupsSearch.items,
        ])

        setActionGroupsList([
          ...actionGroupsList,
          ...data.actionGroupsSearch.items,
        ])
        setActionGroupsListNextToken(data.actionGroupsSearch.nextToken)
      } else {
        logger(Status.Info, "action groups list", data.actionGroupsSearch.items)

        setActionGroupsList(data.actionGroupsSearch.items)
        setActionGroupsListNextToken(data.actionGroupsSearch.nextToken)
      }

      setHasSearched(true)
      if (withLoading) {
        setUpdatingList(false)
      }
      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `actionGroupsSearch`, e.message)
      }
      if (withLoading) {
        setUpdatingList(false)
      }
      return false
    }
  }

  // get current action group
  const getCurrentActionGroup = useCallback(
    async (actionGroupId: string) => {
      try {
        logger(Status.Api, "QUERY actionGroupGet", actionGroup)
        const { data } = await actionGroupQuery({
          variables: { input: { id: actionGroupId } },
          fetchPolicy: "no-cache",
        })
        logger(
          Status.Info,
          `action group ${actionGroupId}`,
          data.actionGroupGet
        )

        setCurrentActionGroup(data.actionGroupGet)
        setPreChangesCurrentActionGroup(deepCopy(data.actionGroupGet))

        // set translations errors array
        data.actionGroupGet.document.items.forEach(
          (item: ActionGroupsDocument) => {
            translationsErrors.push({ lang: item.lang, hasErrors: false })
          }
        )
        setTranslationsErrors([...translationsErrors])

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `actionGroupGet`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // create action group
  const createActionGroup = async (input: {
    actions: { id: string }[]
    canBecomeHabit: boolean
    handle: string
    image: string
    maxIterations: number
    category: {
      id: string
    }
    sdgs: { primary: boolean; id: string }[]
    sdgTargets: { primary: boolean; id: string }[]
    featured: boolean
    footprintPrediction?: number
    badge?: {
      id: string
    }
  }) => {
    try {
      logger(Status.Api, "MUTATION actionGroupUpsert", upsertActionGroup)
      const { data } = await upsertActionGroupMutation({
        variables: { input: input },
      })
      logger(Status.Info, `action group created`, data.actionGroupUpsert)

      return data.actionGroupUpsert
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `actionGroupUpsert`, e.message)
      }
      return false
    }
  }

  // create action group document
  const createActionGroupDocument = async (input: {
    parentId: string
    type: string
    actionGroupDocumentItems: {
      lang: string
      title: string
      default: boolean
      body: object[]
      benchmark?: string
    }[]
  }) => {
    try {
      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentActionGroup)
      await upsertDocumentActionGroupMutation({
        variables: { input: input },
      })
      logger(Status.Info, `document created`)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // upsert action group parent
  const upsertActionGroupParent = async () => {
    try {
      const input = {
        id: currentActionGroup.id,
        handle: currentActionGroup.handle,
        canBecomeHabit: currentActionGroup.canBecomeHabit,
        maxIterations: currentActionGroup.maxIterations,
        image: currentActionGroup.image,
        actions: currentActionGroup.actions.map((item) => {
          return {
            id: item.action.id,
          }
        }),
        badge: {
          id: currentActionGroup.badge.id,
        },
        category: {
          id: currentActionGroup.category.id,
        },
        sdgs: [
          ...currentActionGroup.sdgs.map((sdg) => {
            return { id: sdg.sdg.id, primary: sdg.primary }
          }),
        ],
        sdgTargets: [
          ...currentActionGroup.sdgTargets.map((sdgTarget) => {
            return { id: sdgTarget.sdgTarget.id, primary: sdgTarget.primary }
          }),
        ],
        featured: currentActionGroup.featured,
        footprintPrediction: currentActionGroup.footprintPrediction,
      }

      logger(Status.Api, "MUTATION actionGroupUpsert", upsertActionGroup)
      const { data } = await upsertActionGroupMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `action group ${data.actionGroupUpsert.id} upserted`,
        data.actionGroupUpsert
      )

      currentActionGroup.id = data.actionGroupUpsert.id
      currentActionGroup.updatedAt = data.actionGroupUpsert.updatedAt
      currentActionGroup.document.parentId =
        data.actionGroupUpsert.document.parentId
      currentActionGroup.handle = data.actionGroupUpsert.handle
      currentActionGroup.image = data.actionGroupUpsert.image
      currentActionGroup.maxIterations = data.actionGroupUpsert.maxIterations
      currentActionGroup.category = data.actionGroupUpsert.category
      currentActionGroup.sdgs = data.actionGroupUpsert.sdgs
      currentActionGroup.sdgTargets = data.actionGroupUpsert.sdgTargets
      currentActionGroup.footprint = data.actionGroupUpsert.footprint
      currentActionGroup.canBecomeHabit = data.actionGroupUpsert.canBecomeHabit
      currentActionGroup.actions = data.actionGroupUpsert.actions
      currentActionGroup.badge = data.actionGroupUpsert.badge
      currentActionGroup.featured = data.actionGroupUpsert.featured
      setCurrentActionGroup({ ...currentActionGroup })
      setPreChangesCurrentActionGroup(deepCopy({ ...currentActionGroup }))

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `actionGroupUpsert`, e.message)
      }
      return false
    }
  }

  // upsert the footprint question of the current action group
  const upsertFootprintQuestion = async (
    actionGroupId: string,
    questionId?: string
  ) => {
    try {
      if (questionId) {
        logger(
          Status.Api,
          "MUTATION footprintActionGroupCreate",
          footprintActionGroupCreate
        )
        await footprintActionGroupCreateMutation({
          variables: {
            input: {
              actionGroupId: actionGroupId,
              questionId: questionId,
            },
          },
        })

        logger(
          Status.Info,
          `footprint question for action group ${actionGroupId} changed`
        )
      } else {
        logger(
          Status.Api,
          "MUTATION footprintActionGroupDelete",
          footprintActionGroupDelete
        )
        await footprintActionGroupDeleteMutation({
          variables: {
            input: {
              actionGroupId: actionGroupId,
            },
          },
        })

        logger(
          Status.Info,
          `footprint question for action group ${actionGroupId} deleted`
        )
      }

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(
          Status.Error,
          `footprintActionGroupCreate or footprintActionGroupDelete`,
          e.message
        )
      }
      return false
    }
  }

  // upsert action group document
  const upsertActionGroupDocument = async () => {
    try {
      // parse data for inpout
      const currentActionGroupCopy = deepCopy(currentActionGroup)
      currentActionGroupCopy.document.items.forEach((item: any) => {
        const newBody = []
        item.body.forEach((bodyItem: any) => {
          const { sliceType, __typename, ...rest } = bodyItem
          newBody.push({
            [lowercaseFirstCharacter(bodyItem.sliceType)]: {
              ...rest,
            },
          })
        })
        item.body = newBody

        item.default = item.isDefault
        delete item.isDefault
        delete item.type
        delete item.updatedAt
        delete item.parentId
        delete item.__typename
      })

      const input = {
        actionGroupDocumentItems: currentActionGroupCopy.document.items,
        parentId: currentActionGroupCopy.document.parentId,
        type: "ActionGroup",
      }

      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentActionGroup)
      const { data } = await upsertDocumentActionGroupMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `action group ${data.documentUpsert.parentId} document upserted`,
        data.documentUpsert
      )

      const currentActionSecondCopy: ActionGroup = deepCopy(currentActionGroup)
      currentActionSecondCopy.document = data.documentUpsert
      setCurrentActionGroup(currentActionSecondCopy)
      setPreChangesCurrentActionGroup(deepCopy(currentActionSecondCopy))

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // delete action group
  const deleteActionGroup = async (actionGroupToDeleteId: string) => {
    try {
      logger(Status.Api, "MUTATION actionGroupDelete", deleteSingleActionGroup)
      const { data } = await deleteActionGroupMutation({
        variables: { input: { id: actionGroupToDeleteId } },
      })
      logger(
        Status.Info,
        `action group ${data.actionGroupDelete.id} deleted: ${data.actionGroupDelete.deleted}`
      )

      return data.actionGroupDelete.deleted
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `actionGroupDelete`, e.message)
      }
      return false
    }
  }

  // get all action groups list for csv download
  const getAllActionGroupsList = async () => {
    try {
      let nextToken: string = null
      let list = []

      if (
        searchLang ||
        searchTitle ||
        searchFeatured ||
        searchCategory ||
        searchCanBecomeHabit
      ) {
        const input: {
          limit: number
          nextToken: string
          canBecomeHabit?: boolean
          lang?: string
          category?: string
          title?: string
          featured?: boolean
        } = {
          limit: 50,
          nextToken: nextToken,
          canBecomeHabit: searchCanBecomeHabit,
          lang: searchLang ? searchLang.id : null,
          category: searchCategory ? searchCategory.id : null,
          title: searchTitle,
          featured: searchFeatured,
        }

        do {
          logger(Status.Api, "QUERY actionGroupsSearch", searchActionGroups)
          const { data } = await searchActionGroupsQuery({
            variables: {
              input: input,
            },
            fetchPolicy: "no-cache",
          })
          list.push(...data.actionGroupsSearch.items)
          nextToken = data.actionGroupsSearch.nextToken
        } while (nextToken !== null)
      } else {
        do {
          logger(Status.Api, "QUERY actionGroupsList", listActionGroups)
          const { data } = await listActionGroupsQuery({
            variables: {
              input: { limit: 50, nextToken: nextToken },
            },
            fetchPolicy: "no-cache",
          })
          list.push(...data.actionGroupsList.items)
          nextToken = data.actionGroupsList.nextToken
        } while (nextToken !== null)
      }

      let data = [
        [
          "id",
          "title",
          "translations",
          "habit",
          "featured",
          "category",
          "points",
        ],
      ]
      for (let i = 0; i < list.length; i++) {
        data.push([
          list[i].id,
          list[i].document.items.filter((item: any) => item.isDefault)[0].title,
          list[i].document.items.map((item: any) => item.lang),
          list[i].canBecomeHabit ? "yes" : "no",
          list[i].featured ? "yes" : "no",
          list[i].category.name,
          list[i].points,
        ])
      }
      logger(Status.Info, `all action groups list`, data)

      return data
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        if (
          searchLang ||
          searchTitle ||
          searchFeatured ||
          searchCategory ||
          searchCanBecomeHabit
        ) {
          logger(Status.Error, `actionGroupsSearch`, e.message)
        } else {
          logger(Status.Error, `actionGroupsList`, e.message)
        }
      }
    }
  }

  // add translation
  const addTranslation = (translationToAdd: string) => {
    const documentToAdd: ActionGroupsDocument = {
      body: [],
      isDefault: false,
      lang: translationToAdd,
      title: "Title",
      type: "ActionGroup",
      benchmark: null,
    }

    translationsErrors.push({
      lang: translationToAdd,
      hasErrors: false,
    })
    setTranslationsErrors([...translationsErrors])

    currentActionGroup.document.items.push(documentToAdd)
    setCurrentActionGroup({ ...currentActionGroup })
  }

  // copy details from default translation
  const copyDetailsFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentActionGroup.document.items.filter(
      (item) => item.isDefault
    )[0]

    currentActionGroup.document.items[itemToCopyToIndex].title =
      defaultItem.title
    currentActionGroup.document.items[itemToCopyToIndex].benchmark =
      defaultItem.benchmark

    setCurrentActionGroup({ ...currentActionGroup })
  }

  // copy body from default translation
  const copyBodyFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentActionGroup.document.items.filter(
      (item) => item.isDefault
    )[0]

    currentActionGroup.document.items[itemToCopyToIndex].body = defaultItem.body

    setCurrentActionGroup({ ...currentActionGroup })
  }

  // cancel changes
  const cancelChanges = () => {
    setCurrentActionGroup(deepCopy(preChangesCurrentActionGroup))
    resetErrors()
  }

  // check if user has done changes
  useEffect(() => {
    const currentActionGroupCopy: ActionGroup = deepCopy(currentActionGroup)
    const preChangesCurrentActionGroupCopy: ActionGroup = deepCopy(
      preChangesCurrentActionGroup
    )
    delete currentActionGroupCopy.badge
    delete preChangesCurrentActionGroupCopy.badge

    if (
      JSON.stringify(currentActionGroupCopy) !==
        JSON.stringify(preChangesCurrentActionGroupCopy) ||
      (currentActionGroup.badge &&
        preChangesCurrentActionGroup.badge &&
        currentActionGroup.badge.id !==
          preChangesCurrentActionGroup.badge.id) ||
      (!currentActionGroup.badge && preChangesCurrentActionGroup.badge) ||
      (currentActionGroup.badge && !preChangesCurrentActionGroup.badge)
    ) {
      setDoneChanges(true)
    } else {
      setDoneChanges(false)
    }
  }, [currentActionGroup])

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter((item) => item.name === "Series")[0].updateFunction =
      getActionGroupsList
  }, [])

  return (
    <ActionGroupsContext.Provider
      value={{
        loading,
        setLoading,
        updatingList,
        setUpdatingList,
        editMode,
        setEditMode,
        actionGroupsList,
        getActionGroupsList,
        doneChanges,
        cancelChanges,
        currentActionGroup,
        setCurrentActionGroup,
        getCurrentActionGroup,
        preChangesCurrentActionGroup,
        actionGroupsListNextToken,
        loadMoreActionGroups,
        hasSearched,
        setHasSearched,
        searchCanBecomeHabit,
        setSearchCanBecomeHabit,
        searchLang,
        setSearchLang,
        searchCategory,
        setSearchCategory,
        searchTitle,
        setSearchTitle,
        searchFeatured,
        setSearchFeatured,
        searchActionGroupsList,
        createActionGroup,
        createActionGroupDocument,
        hasError,
        addTranslation,
        handleError,
        setHandleError,
        maxIterationsError,
        setMaxIterationsError,
        categoryError,
        setCategoryError,
        secondarySdgsError,
        setSecondarySdgsError,
        secondarySdgTargetsError,
        setSecondarySdgTargetsError,
        footprintPredictionError,
        setFootprintPredictionError,
        upsertActionGroupParent,
        translationsErrors,
        setTranslationsErrors,
        copyDetailsFromDefault,
        copyBodyFromDefault,
        upsertActionGroupDocument,
        deleteActionGroup,
        upsertFootprintQuestion,
        getAllActionGroupsList,
      }}
    >
      {children}
    </ActionGroupsContext.Provider>
  )
}

export { ActionGroupsController, ActionGroupsContext }
