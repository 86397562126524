import {
  AddCircleRounded,
  CloseRounded,
  ContentCopyRounded,
  DeleteRounded,
  DragHandleRounded,
} from "@mui/icons-material"
import {
  AppBar,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grow,
  IconButton,
  Paper,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { useNavigate, useParams } from "react-router-dom"
import EditContainer from "../../components/global/editContainer"
import EditHeading from "../../components/global/editHeading"
import LoadingBackdrop from "../../components/global/loadingBackdrop"
import PageSpinner from "../../components/global/pageSpinner"
import { JourneysContext } from "../../controllers/journeys"
import { MainContext } from "../../controllers/main"
import Journey from "../../models/journey"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import {
  DialogTransition,
  copyTextToClipboard,
  isPrivacyVersionValid,
  isValidDate,
  isValidURL,
} from "../../services/utilities/utility"
import JourneysList from "../journey/journeysList"
import CantPerformActionDialog from "../../components/global/cantPerformActionDialog"
import { ChannelsContext } from "../../controllers/channels"
import "../../styles/channelEdit.scss"
import TeamsList from "../team/teamsList"
import Team from "../../models/team"
import { TeamsContext } from "../../controllers/teams"

const ChannelEdit = () => {
  const {
    animation,
    setAnimation,
    setChangesSaved,
    showPadding,
    setClipboardFeedbackOpen,
  } = useContext(MainContext)
  const {
    loading: controllerLoading,
    setLoading: setControllerLoading,
    editMode,
    setEditMode,
    doneChanges,
    cancelChanges,
    getChannel,
    currentChannel,
    setCurrentChannel,
    updateAllChannel,
  } = useContext(ChannelsContext)
  const {
    setSearchValue,
    setSearchLanguage,
    setSearchTopic,
    setSearchSdg,
    setSearchSdgTarget,
    setSearchType,
    setJourneysList,
  } = useContext(JourneysContext)
  const { resetTeamsFilters } = useContext(TeamsContext)
  const { channelId } = useParams()
  const navigate = useNavigate()

  // prevent tab close
  useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault()
      console.log("beforeunload event triggered")
      return (event.returnValue = "Are you sure you want to exit?")
    }

    window.addEventListener("beforeunload", handleTabClose)

    return () => {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [])

  // loading
  const [loading, setLoading] = useState<boolean>(true)

  // page error
  const [pageError, setPageError] = useState<boolean>(false)

  // fetch current journey
  const fetchAll = async () => {
    const noErrors = await getChannel(channelId)
    if (noErrors) {
      setLoading(false)
      setControllerLoading(false)
    } else {
      setPageError(true)
    }
  }

  useEffect(() => {
    fetchAll()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // journeys
  const [selectedJourneys, setSelectedJourneys] = useState<Journey[]>([])
  const [journeysToFilter, setJourneysToFilter] = useState<Journey[]>([])
  const [addJourneysDialogOpen, setAddJourneysDialogOpen] =
    useState<boolean>(false)

  // can't add journeys dialog
  const [cantAddJourneyDialogOpen, setCantAddJourneyDialogOpen] =
    useState<boolean>(false)

  // reset journeys list filters
  const resetJourneysFilters = () => {
    setSearchValue("")
    setSearchLanguage(null)
    setSearchTopic(null)
    setSearchSdg(null)
    setSearchSdgTarget(null)
    setSearchType(["Free", "Business"])
  }

  // drag and drop
  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result

    if (destination.index === source.index) {
      return
    }

    let sourceItem = currentChannel.journeys[source.index]

    let indexToDelete = currentChannel.journeys.findIndex(
      (item) => item.journey.id === draggableId
    )
    currentChannel.journeys.splice(indexToDelete, 1)
    currentChannel.journeys.splice(destination.index, 0, sourceItem)

    setCurrentChannel({ ...currentChannel })
  }

  // premium journeys max number and dialog
  const journeysMaxNumber = 25
  const [maxNumberDialogOpen, setMaxNumberDialogOpen] = useState<boolean>(false)

  // change team dialog
  const [changeTeamDialogOpen, setChangeTeamDialogOpen] =
    useState<boolean>(false)
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)

  return loading ? (
    <PageSpinner
      showBackButton={pageError}
      backButtonOnClick={() => {
        setAnimation(false)
        navigate("/channels")
      }}
    />
  ) : (
    <EditContainer>
      <EditHeading
        saveButtonLoading={controllerLoading}
        backButtonOnClick={() => {
          setAnimation(false)
          setTimeout(() => {
            navigate("/channels")
          }, 300)
        }}
        title={currentChannel.projectId}
        showButtons
        showEditButton
        editModeActive={editMode}
        setEditModeActive={setEditMode}
        saveButtonDisabled={
          (currentChannel.journeys &&
            currentChannel.journeys.filter(
              (journey) =>
                !isValidDate(journey.startsAt) || !isValidDate(journey.endsAt)
            ).length > 0) ||
          !isValidURL(currentChannel.webhookUrl) ||
          !currentChannel.issuer.length ||
          !isPrivacyVersionValid(currentChannel.privacyVersion)
        }
        undoButtonDisabled={!doneChanges}
        doneChanges={doneChanges}
        undoButtonOnClick={cancelChanges}
        saveButtonOnClick={async () => {
          setControllerLoading(true)

          const noErrors = await updateAllChannel(
            channelId,
            currentChannel.clientCredentialId,
            currentChannel.issuer,
            currentChannel.oldTeamId,
            currentChannel.projectId,
            currentChannel.teamId,
            currentChannel.webhookUrl,
            currentChannel.reportUrl ?? "",
            currentChannel.privacyVersion,
            currentChannel.journeys.map((item) => {
              return {
                id: item.journey.id,
                startsAt: item.startsAt,
                endsAt: item.endsAt,
              }
            })
          )

          if (noErrors) {
            setControllerLoading(false)
            setChangesSaved(true)
          } else {
            setControllerLoading(false)
          }
        }}
      />
      <Grow
        in={animation}
        timeout={300}
        style={{
          margin: showPadding === "yes" ? 16 : 0,
          marginTop: 0,
        }}
      >
        <Stack spacing={2}>
          <Card>
            <CardContent
              style={{ maxHeight: "calc(100vh - 150px)", overflowY: "scroll" }}
            >
              <Stack spacing={2}>
                <Typography variant="h6" className="card-title">
                  General
                </Typography>
                <Stack direction="row" spacing={2}>
                  <TextField
                    fullWidth
                    label="Project ID"
                    size="small"
                    value={currentChannel.projectId}
                    onChange={(e) => {
                      setCurrentChannel({
                        ...currentChannel,
                        projectId: e.target.value,
                      })
                    }}
                    onBlur={() => {
                      if (currentChannel.projectId) {
                        setCurrentChannel({
                          ...currentChannel,
                          projectId: currentChannel.projectId.trim(),
                        })
                      }
                    }}
                    disabled={!editMode}
                  />
                  <TextField
                    fullWidth
                    label="Client credential ID"
                    size="small"
                    value={currentChannel.clientCredentialId}
                    onChange={(e) => {
                      setCurrentChannel({
                        ...currentChannel,
                        clientCredentialId: e.target.value,
                      })
                    }}
                    onBlur={() => {
                      if (currentChannel.clientCredentialId) {
                        setCurrentChannel({
                          ...currentChannel,
                          clientCredentialId:
                            currentChannel.clientCredentialId.trim(),
                        })
                      }
                    }}
                    disabled={!editMode}
                  />
                  <div
                    className="privacy-version-text-field"
                    style={{ width: "100%" }}
                  >
                    <TextField
                      fullWidth
                      label="Privacy version"
                      size="small"
                      value={currentChannel.privacyVersion}
                      onChange={(e) => {
                        setCurrentChannel({
                          ...currentChannel,
                          privacyVersion: e.target.value,
                        })
                      }}
                      error={
                        !isPrivacyVersionValid(currentChannel.privacyVersion)
                      }
                      helperText={
                        !isPrivacyVersionValid(currentChannel.privacyVersion)
                          ? "Invalid version"
                          : ""
                      }
                      disabled={!editMode}
                    />
                  </div>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <TextField
                    fullWidth
                    label="Issuer"
                    size="small"
                    value={currentChannel.issuer}
                    onChange={(e) => {
                      setCurrentChannel({
                        ...currentChannel,
                        issuer: e.target.value,
                      })
                    }}
                    onBlur={() => {
                      if (currentChannel.issuer) {
                        setCurrentChannel({
                          ...currentChannel,
                          issuer: currentChannel.issuer.trim(),
                        })
                      }
                    }}
                    disabled={!editMode}
                  />
                  <TextField
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <Button
                          sx={{ paddingInline: 3 }}
                          onClick={() => {
                            setChangeTeamDialogOpen(true)
                          }}
                          disabled={!editMode}
                        >
                          Change
                        </Button>
                      ),
                    }}
                    fullWidth
                    label="Team"
                    size="small"
                    value={currentChannel.teamName}
                    disabled={!editMode}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <TextField
                    fullWidth
                    label="Webhook URL"
                    size="small"
                    value={currentChannel.webhookUrl}
                    onChange={(e) => {
                      setCurrentChannel({
                        ...currentChannel,
                        webhookUrl: e.target.value,
                      })
                    }}
                    error={!isValidURL(currentChannel.webhookUrl)}
                    helperText={
                      !isValidURL(currentChannel.webhookUrl)
                        ? "Invalid URL"
                        : ""
                    }
                    FormHelperTextProps={{
                      style: {
                        margin: 0,
                        marginBottom: -25,
                      },
                    }}
                    onBlur={() => {
                      if (currentChannel.webhookUrl) {
                        setCurrentChannel({
                          ...currentChannel,
                          webhookUrl: currentChannel.webhookUrl.trim(),
                        })
                      }
                    }}
                    disabled={!editMode}
                  />
                  <TextField
                    fullWidth
                    label="Report URL"
                    size="small"
                    value={currentChannel.reportUrl ?? ""}
                    onChange={(e) => {
                      setCurrentChannel({
                        ...currentChannel,
                        reportUrl: e.target.value,
                      })
                    }}
                    error={!isValidURL(currentChannel.reportUrl)}
                    helperText={
                      !isValidURL(currentChannel.reportUrl) ? "Invalid URL" : ""
                    }
                    FormHelperTextProps={{
                      style: {
                        margin: 0,
                        marginBottom: -25,
                      },
                    }}
                    onBlur={() => {
                      if (currentChannel.reportUrl) {
                        setCurrentChannel({
                          ...currentChannel,
                          reportUrl: currentChannel.reportUrl.trim(),
                        })
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          style={{ width: 36, height: 36 }}
                          onClick={() => {
                            copyTextToClipboard(currentChannel.reportUrl)
                            setClipboardFeedbackOpen(true)
                          }}
                          disabled={!editMode}
                        >
                          <ContentCopyRounded style={{ fontSize: 20 }} />
                        </IconButton>
                      ),
                    }}
                    disabled={!editMode}
                  />
                </Stack>
                <Typography className="card-title" variant="h6">
                  Journeys
                </Typography>
                {!currentChannel.journeys ||
                (currentChannel.journeys && !currentChannel.journeys.length) ? (
                  <Typography>No Journeys added</Typography>
                ) : (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="column-1">
                      {(provided) => (
                        <Stack
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {currentChannel.journeys.map(
                            (
                              item: {
                                startsAt: string
                                endsAt: string
                                journey: Journey
                              },
                              index: number
                            ) => {
                              const journey = item.journey

                              return (
                                <Draggable
                                  key={journey.id.toString()}
                                  draggableId={journey.id.toString()}
                                  index={index}
                                >
                                  {(provided) => (
                                    <Card
                                      style={{
                                        minHeight: 60,
                                        paddingLeft: 14,
                                      }}
                                      variant="outlined"
                                      key={journey.id}
                                      onClick={() => {
                                        window.open(
                                          `/journeys/${item.journey.id}`
                                        )
                                      }}
                                      className="episodes-stack-item"
                                      {...provided.draggableProps}
                                      ref={provided.innerRef}
                                      sx={{
                                        boxShadow: "none",
                                        borderTopLeftRadius:
                                          index === 0 ? undefined : 0,
                                        borderTopRightRadius:
                                          index === 0 ? undefined : 0,
                                        borderBottomLeftRadius:
                                          index ===
                                          currentChannel.journeys.length - 1
                                            ? undefined
                                            : 0,
                                        borderBottomRightRadius:
                                          index ===
                                          currentChannel.journeys.length - 1
                                            ? undefined
                                            : 0,
                                        borderTop:
                                          index === 0 ? undefined : "none",
                                      }}
                                    >
                                      <Stack
                                        direction="row"
                                        style={{
                                          height: 60,
                                          position: "relative",
                                        }}
                                        alignItems="center"
                                        spacing={4}
                                      >
                                        <div
                                          style={{
                                            width: 30,
                                            height: 60,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            transition: "100ms",
                                            cursor: "grab",
                                            marginLeft: editMode ? 20 : -31,
                                            marginRight: -8,
                                          }}
                                          {...provided.dragHandleProps}
                                        >
                                          <DragHandleRounded />
                                        </div>
                                        <div
                                          style={{
                                            position: "relative",
                                            minWidth: 48,
                                            maxWidth: 48,
                                          }}
                                        >
                                          {journey.stage === "PUBLISHED" ||
                                          journey.hasSibling ? (
                                            <Chip
                                              size="small"
                                              label="Live"
                                              color="success"
                                              style={{
                                                position: "relative",
                                                zIndex: 1,
                                              }}
                                            />
                                          ) : journey.stage === "ARCHIVED" ? (
                                            <Chip
                                              size="small"
                                              label="Archived"
                                              style={{
                                                position: "relative",
                                                zIndex: 1,
                                              }}
                                            />
                                          ) : (
                                            <Chip
                                              size="small"
                                              label="Draft"
                                              color="warning"
                                              style={{
                                                position: "relative",
                                                zIndex: 1,
                                              }}
                                            />
                                          )}
                                          {journey.hasSibling ? (
                                            <Chip
                                              size="small"
                                              label="Draft"
                                              color="warning"
                                              style={{
                                                position: "absolute",
                                                left: 2,
                                                top: 1.5,
                                              }}
                                            />
                                          ) : null}
                                        </div>
                                        <div
                                          style={{
                                            minWidth: 40,
                                            height: 40,
                                            borderRadius: 4,
                                            backgroundImage: `url(${
                                              journey.image ??
                                              noImagePlaceholder
                                            })`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                          }}
                                        />
                                        <Typography
                                          style={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: "50%",
                                          }}
                                        >
                                          {journey.title}
                                        </Typography>
                                        <div className="date-text-field">
                                          <TextField
                                            disabled={!editMode}
                                            onClick={(e) => {
                                              e.stopPropagation()
                                            }}
                                            size="small"
                                            label="Starts at"
                                            value={item.startsAt}
                                            onChange={(e) => {
                                              item.startsAt = e.target.value
                                              setCurrentChannel({
                                                ...currentChannel,
                                              })
                                            }}
                                            error={!isValidDate(item.startsAt)}
                                            helperText={
                                              isValidDate(item.startsAt)
                                                ? "yyyy-mm-dd"
                                                : "Invalid date"
                                            }
                                            style={{
                                              minWidth: 136,
                                              maxWidth: 136,
                                            }}
                                            onBlur={() => {
                                              if (item.startsAt) {
                                                item.startsAt =
                                                  item.startsAt.trim()
                                                setCurrentChannel({
                                                  ...currentChannel,
                                                })
                                              }
                                            }}
                                          />
                                        </div>
                                        <div
                                          className="date-text-field"
                                          style={{
                                            marginLeft: 16,
                                            marginRight: 74,
                                          }}
                                        >
                                          <TextField
                                            disabled={!editMode}
                                            size="small"
                                            label="Ends at"
                                            value={item.endsAt}
                                            onClick={(e) => {
                                              e.stopPropagation()
                                            }}
                                            onChange={(e) => {
                                              item.endsAt = e.target.value
                                              setCurrentChannel({
                                                ...currentChannel,
                                              })
                                            }}
                                            error={!isValidDate(item.endsAt)}
                                            helperText={
                                              isValidDate(item.endsAt)
                                                ? "yyyy-mm-dd"
                                                : "Invalid date"
                                            }
                                            style={{
                                              minWidth: 136,
                                              maxWidth: 136,
                                            }}
                                            onBlur={() => {
                                              if (item.endsAt) {
                                                item.endsAt = item.endsAt.trim()
                                                setCurrentChannel({
                                                  ...currentChannel,
                                                })
                                              }
                                            }}
                                          />
                                        </div>
                                        <IconButton
                                          style={{
                                            position: "absolute",
                                            right: 18,
                                            transition: "100ms",
                                            zIndex: 1,
                                            cursor: editMode
                                              ? "pointer"
                                              : "default",
                                          }}
                                          disabled={!editMode}
                                          onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            currentChannel.journeys.splice(
                                              index,
                                              1
                                            )
                                            setCurrentChannel({
                                              ...currentChannel,
                                            })
                                          }}
                                          color="error"
                                        >
                                          <DeleteRounded
                                            style={{ width: 22 }}
                                          />
                                        </IconButton>
                                      </Stack>
                                    </Card>
                                  )}
                                </Draggable>
                              )
                            }
                          )}
                          {provided.placeholder}
                        </Stack>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
                <Button
                  onClick={() => {
                    setJourneysList([])
                    setJourneysToFilter(
                      currentChannel.journeys.map((item) => item.journey)
                    )
                    setAddJourneysDialogOpen(true)
                  }}
                  variant="outlined"
                  startIcon={<AddCircleRounded />}
                  fullWidth
                  disabled={!editMode}
                >
                  Add Journeys
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Grow>
      {/* add journey dialog */}
      <Dialog
        fullScreen
        open={addJourneysDialogOpen}
        onClose={() => {
          setAddJourneysDialogOpen(false)
          setSelectedJourneys([])
          resetJourneysFilters()
        }}
        TransitionComponent={DialogTransition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setAddJourneysDialogOpen(false)
                setSelectedJourneys([])
                resetJourneysFilters()
              }}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Journeys
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                // check if there are draft journey in selection
                for (let i = 0; i < selectedJourneys.length; i++) {
                  if (
                    selectedJourneys[i].stage === "DRAFT" &&
                    !selectedJourneys[i].hasSibling
                  ) {
                    setAddJourneysDialogOpen(false)
                    setSelectedJourneys([])
                    resetJourneysFilters()
                    setTimeout(() => {
                      setCantAddJourneyDialogOpen(true)
                    }, 150)
                    return
                  }
                }

                currentChannel.journeys.push(
                  ...selectedJourneys.map((journey) => {
                    let date = new Date().toISOString()
                    date = date.slice(0, date.indexOf("T"))

                    return {
                      startsAt: date,
                      endsAt: date,
                      journey: journey,
                    }
                  })
                )
                setCurrentChannel({ ...currentChannel })

                setAddJourneysDialogOpen(false)
                setSelectedJourneys([])
                resetJourneysFilters()
              }}
              disabled={!selectedJourneys.length}
            >
              Select
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ backgroundColor: "#f5f5f5" }}>
          <JourneysList
            forDialog
            forDialogType={["Business"]}
            selectedJourneys={selectedJourneys}
            setSelectedJourneys={setSelectedJourneys}
            journeysToFilter={journeysToFilter}
          />
        </Paper>
      </Dialog>
      {/* can't add journeys dialog */}
      <Dialog
        open={cantAddJourneyDialogOpen}
        onClose={() => {
          setCantAddJourneyDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-cant-add-journey"
        aria-describedby="alert-dialog-description-cant-add-journey"
      >
        <DialogTitle id="alert-dialog-title-cant-add-journey">
          These Journeys cannot be added
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-cant-add-journey">
            There are one or more Journeys that are not published
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCantAddJourneyDialogOpen(false)
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* can't add journeys for max number dialog */}
      <CantPerformActionDialog
        open={maxNumberDialogOpen}
        setOpen={setMaxNumberDialogOpen}
        content={`The maximum number of Journeys is ${journeysMaxNumber}`}
      />
      {/* loading backdrop */}
      <LoadingBackdrop open={controllerLoading} />
      {/* change team dialog */}
      <Dialog
        fullScreen
        open={changeTeamDialogOpen}
        onClose={() => {
          setChangeTeamDialogOpen(false)
          setSelectedTeam(null)
          resetTeamsFilters()
        }}
        TransitionComponent={DialogTransition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Episodes
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                setCurrentChannel({
                  ...currentChannel,
                  teamId: selectedTeam.id,
                  oldTeamId: selectedTeam.oldId
                    ? selectedTeam.oldId.toString()
                    : "",
                  teamName: selectedTeam.name,
                })

                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              disabled={!selectedTeam}
            >
              Select
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ backgroundColor: "#f5f5f5" }}>
          <TeamsList
            forDialog
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
          />
        </Paper>
      </Dialog>
    </EditContainer>
  )
}

export default ChannelEdit
