import { Cancel, Search, UndoRounded } from "@mui/icons-material"
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material"
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useState,
} from "react"

const CurrentNewsListTopBar = ({
  loading,
  textFieldValue,
  textFieldPlaceholder,
  textFieldDisabled = false,
  textFieldOnChange,
  textFieldOnKeyDown,
  cancelButtonOnClick,
  searchButtonOnClick,
  doneChanges,
  undoButtonOnClick,
  saveButtonLoading,
  saveButtonOnClick,
}: {
  loading: boolean
  textFieldValue: string
  textFieldPlaceholder: string
  textFieldDisabled?: boolean
  textFieldOnChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  textFieldOnKeyDown: KeyboardEventHandler<HTMLDivElement>
  cancelButtonOnClick: MouseEventHandler<HTMLDivElement>
  searchButtonOnClick: MouseEventHandler<HTMLButtonElement>
  doneChanges: boolean
  undoButtonOnClick: () => void
  saveButtonLoading: boolean
  saveButtonOnClick: () => void
}) => {
  // undo dialog
  const [undoDialogOpen, setUndoDialogOpen] = useState<boolean>(false)

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      style={{
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 4,
      }}
    >
      <TextField
        disabled={loading || textFieldDisabled}
        size="small"
        fullWidth
        id="outlined-basic"
        placeholder={textFieldPlaceholder}
        variant="outlined"
        value={textFieldValue}
        onChange={textFieldOnChange}
        onKeyDown={textFieldOnKeyDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: textFieldValue.length > 0 && (
            <InputAdornment position="start">
              <div
                style={{
                  marginTop: 6,
                  marginRight: -6,
                  cursor: "pointer",
                }}
                onClick={loading ? () => {} : cancelButtonOnClick}
              >
                <Cancel style={{ width: 18 }} />
              </div>
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="outlined"
        disabled={textFieldValue.length < 3 || loading}
        onClick={searchButtonOnClick}
        style={{ height: 40 }}
      >
        Search
      </Button>
      <Divider orientation="vertical" flexItem />
      <ButtonGroup
        variant="outlined"
        aria-label="outlined button group"
        style={{ minWidth: 183, height: 40 }}
      >
        <Button
          onClick={() => {
            setUndoDialogOpen(true)
          }}
          disabled={!doneChanges || saveButtonLoading}
        >
          <UndoRounded />
        </Button>
        <Button
          variant="contained"
          disabled={!doneChanges || saveButtonLoading}
          onClick={saveButtonOnClick}
        >
          Save changes
        </Button>
      </ButtonGroup>
      <Dialog
        open={undoDialogOpen}
        onClose={() => {
          setUndoDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to undo all changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All changes will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setUndoDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              undoButtonOnClick()
              setUndoDialogOpen(false)
            }}
            autoFocus
          >
            Undo
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default CurrentNewsListTopBar
