import {
  Button,
  CardContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { MainContext } from "../../controllers/main"
import { NewsContext } from "../../controllers/news"
import EmbedSlice from "../../models/bodySlices/embedSlice"
import ImageSlice from "../../models/bodySlices/imageSlice"
import QuoteSlice from "../../models/bodySlices/quoteSlice"
import TextSlice from "../../models/bodySlices/textSlice"
import News from "../../models/news"
import { BodySliceType, MediaSize } from "../../services/config/enum"
import {
  deepCopy,
  isValidURL,
  stripHtml,
  testYtURL,
} from "../../services/utilities/utility"
import BlockEditor from "../blockEditor/blockEditor"
import ImagePicker from "../global/imagePicker"

const NewsTranslationsEdit = ({ itemIndex }: { itemIndex: number }) => {
  const { showPadding } = useContext(MainContext)
  const {
    currentNews,
    setCurrentNews,
    editMode,
    translationsErrors,
    setTranslationsErrors,
    copyDetailsFromDefault,
    copyBodyFromDefault,
  } = useContext(NewsContext)

  // current item
  const currentItem = currentNews.document.items[itemIndex]

  // errors managment
  const [titleError, setTitleError] = useState<boolean>(false) // error for title
  const [taglineError, setTaglineError] = useState<boolean>(false) // error for tagline
  const [redirectUrlError, setRedirectUrlError] = useState<boolean>(false) // error for redirect url
  const [bodyError, setBodyError] = useState<boolean>(false) // error for body

  // update translations errors on errors change
  useEffect(() => {
    if (titleError || taglineError || redirectUrlError || bodyError) {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = true
    } else {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = false
    }

    setTranslationsErrors([...translationsErrors])
  }, [titleError, taglineError, redirectUrlError, bodyError])

  // update errors on currentItem change
  useEffect(() => {
    if (currentItem.title.length < 3 || currentItem.title.length > 60) {
      setTitleError(true)
    } else {
      setTitleError(false)
    }

    if (currentItem.tagline && currentItem.tagline.length > 60) {
      setTaglineError(true)
    } else {
      setTaglineError(false)
    }

    if (currentItem.redirectUrl && !isValidURL(currentItem.redirectUrl)) {
      setRedirectUrlError(true)
    } else {
      setRedirectUrlError(false)
    }

    let errorInBody = false
    currentItem.body.forEach((bodyItem) => {
      if (
        (bodyItem.sliceType === BodySliceType.BodyTextSlice &&
          !stripHtml(bodyItem.text).length) ||
        (bodyItem.sliceType === BodySliceType.BodyQuoteSlice &&
          !bodyItem.quoteText.length) ||
        (bodyItem.sliceType === BodySliceType.BodyImageSlice &&
          (!bodyItem.imgUrl.length || !isValidURL(bodyItem.imgUrl))) ||
        (bodyItem.sliceType === BodySliceType.BodyEmbedSlice &&
          (!bodyItem.embedUrl.length || !testYtURL(bodyItem.embedUrl)))
      ) {
        errorInBody = true
      }
    })
    if (errorInBody) {
      setBodyError(true)
    } else {
      setBodyError(false)
    }
  }, [currentItem])

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" style={{ height: 28 }}>
          <Typography variant="h6" className="card-title">
            Details
          </Typography>
          <Stack
            alignItems="center"
            justifyContent="flex-end"
            style={{ width: "100%" }}
            direction="row"
            spacing={2}
          >
            <FormGroup style={{ marginRight: -16 }}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editMode}
                    checked={currentItem.isDefault}
                    onChange={(e) => {
                      const currentNewsCopy: News = deepCopy(currentNews)
                      currentNewsCopy.document.items[itemIndex].isDefault =
                        e.target.checked
                      if (e.target.checked) {
                        currentNewsCopy.document.items.forEach(
                          (item, index) => {
                            if (index !== itemIndex) {
                              item.isDefault = false
                            }
                          }
                        )
                      }
                      setCurrentNews(currentNewsCopy)
                    }}
                  />
                }
                label="Default"
              />
            </FormGroup>
            <Divider orientation="vertical" flexItem />
            <Button
              size="small"
              variant="outlined"
              style={{ height: 26, fontSize: 12 }}
              onClick={() => {
                copyDetailsFromDefault(itemIndex)
              }}
              disabled={
                currentItem.isDefault ||
                !editMode ||
                !currentNews.document.items.filter((item) => item.isDefault)
                  .length
              }
            >
              Copy from default
            </Button>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Stack spacing={2} style={{ width: "50%" }}>
            <TextField
              fullWidth
              size="small"
              label="Title"
              value={currentItem.title}
              onChange={(e) => {
                const currentNewsCopy: News = deepCopy(currentNews)
                currentNewsCopy.document.items[itemIndex].title = e.target.value
                setCurrentNews(currentNewsCopy)

                // errors check
                if (e.target.value.length < 3 || e.target.value.length > 60) {
                  setTitleError(true)
                } else {
                  setTitleError(false)
                }
              }}
              disabled={!editMode}
              error={titleError}
              helperText={
                currentItem.title.length < 3
                  ? "Minimum length is 3 characters"
                  : currentItem.title.length > 60
                  ? "Maximum length is 60 characters"
                  : ""
              }
              onBlur={() => {
                if (currentNews.document.items[itemIndex].title) {
                  const currentNewsCopy: News = deepCopy(currentNews)
                  currentNewsCopy.document.items[itemIndex].title =
                    currentNewsCopy.document.items[itemIndex].title.trim()
                  setCurrentNews(currentNewsCopy)
                }
              }}
            />
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                size="small"
                label="Tagline"
                value={currentItem.tagline ? currentItem.tagline : ""}
                onChange={(e) => {
                  const currentNewsCopy: News = deepCopy(currentNews)
                  if (e.target.value.length) {
                    currentNewsCopy.document.items[itemIndex].tagline =
                      e.target.value
                  } else {
                    currentNewsCopy.document.items[itemIndex].tagline = null
                  }
                  setCurrentNews(currentNewsCopy)

                  // errors check
                  if (e.target.value.length > 60) {
                    setTaglineError(true)
                  } else {
                    setTaglineError(false)
                  }
                }}
                disabled={!editMode}
                error={taglineError}
                helperText={
                  currentItem.tagline && currentItem.tagline.length > 60
                    ? "Maximum length is 60 characters"
                    : ""
                }
                onBlur={() => {
                  if (currentNews.document.items[itemIndex].tagline) {
                    const currentNewsCopy: News = deepCopy(currentNews)
                    currentNewsCopy.document.items[itemIndex].tagline =
                      currentNewsCopy.document.items[itemIndex].tagline.trim()
                    setCurrentNews(currentNewsCopy)
                  }
                }}
              />
              <TextField
                fullWidth
                size="small"
                label="Redirect URL"
                value={currentItem.redirectUrl ? currentItem.redirectUrl : ""}
                onChange={(e) => {
                  const currentNewsCopy: News = deepCopy(currentNews)
                  if (e.target.value.length) {
                    currentNewsCopy.document.items[itemIndex].redirectUrl =
                      e.target.value
                  } else {
                    currentNewsCopy.document.items[itemIndex].redirectUrl = null
                  }
                  setCurrentNews(currentNewsCopy)

                  // errors check
                  if (e.target.value.length && !isValidURL(e.target.value)) {
                    setRedirectUrlError(true)
                  } else {
                    setRedirectUrlError(false)
                  }
                }}
                disabled={!editMode}
                error={redirectUrlError}
                helperText={
                  currentItem.redirectUrl &&
                  !isValidURL(currentItem.redirectUrl)
                    ? "Invalid URL"
                    : ""
                }
                onBlur={() => {
                  if (currentNews.document.items[itemIndex].redirectUrl) {
                    const currentNewsCopy: News = deepCopy(currentNews)
                    currentNewsCopy.document.items[itemIndex].redirectUrl =
                      currentNewsCopy.document.items[
                        itemIndex
                      ].redirectUrl.trim()
                    setCurrentNews(currentNewsCopy)
                  }
                }}
              />
            </Stack>
          </Stack>
          <ImagePicker
            title="Image"
            pickedImage={currentItem.image}
            setPickedImage={(selectedImage: {
              url: string
              id: string
              description?: string
              type?: MediaSize
            }) => {
              const currentNewsCopy: News = deepCopy(currentNews)
              currentNewsCopy.document.items[itemIndex].image =
                selectedImage.url
              setCurrentNews(currentNewsCopy)
            }}
            filters={{ category: "thumbnail" }}
            disableCategoryFilter
            width="50%"
            disabled={!editMode}
          />
        </Stack>
        {!currentItem.redirectUrl && (
          <Stack spacing={2}>
            <Stack direction="row" style={{ height: 28 }}>
              <Typography variant="h6" className="card-title">
                Body
              </Typography>
              <Stack
                alignItems="center"
                justifyContent="flex-end"
                style={{ width: "100%" }}
                direction="row"
                spacing={2}
              >
                <Button
                  size="small"
                  variant="outlined"
                  style={{ height: 26, fontSize: 12 }}
                  onClick={() => {
                    copyBodyFromDefault(itemIndex)
                  }}
                  disabled={
                    currentItem.isDefault ||
                    !editMode ||
                    !currentNews.document.items.filter((item) => item.isDefault)
                      .length
                  }
                >
                  Copy from default
                </Button>
              </Stack>
            </Stack>
            <BlockEditor
              body={currentItem.body}
              setBody={(
                body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
              ) => {
                const currentNewsCopy: News = deepCopy(currentNews)
                currentNewsCopy.document.items[itemIndex].body = body
                setCurrentNews(currentNewsCopy)
              }}
              editMode={editMode}
            />
          </Stack>
        )}
      </Stack>
    </CardContent>
  )
}

export default NewsTranslationsEdit
