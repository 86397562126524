import { Stack, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { DraggableProvided } from "react-beautiful-dnd"
import ImageSlice from "../../../models/bodySlices/imageSlice"
import { MediaSize } from "../../../services/config/enum"
import ImagePicker from "../../global/imagePicker"
import SliceTitle from "../common/sliceTitle"
import SliceWrapper from "../common/sliceWrapper"
import { isValidURL } from "../utilities/isValidUrl"

const ImageSliceComponent = ({
  item,
  prizes,
  setPrizes,
  editMode,
  sliceIndex,
  removePrize,
  provided,
  withCaptionTitleError,
  withCaptionError,
}: {
  item: ImageSlice
  prizes: ImageSlice[]
  setPrizes: (prizes: ImageSlice[]) => void
  editMode: boolean
  sliceIndex: number
  removePrize: (sliceToRemoveIndex: number) => void
  provided: DraggableProvided
  withCaptionTitleError?: boolean
  withCaptionError?: boolean
}) => {
  // states
  const [imgUrl, setImgUrl] = useState<string>(item.imgUrl)
  const [imgCaption, setImgCaption] = useState<string | null>(item.imgCaption)
  const [imgCaptionTitle, setImgCaptionTitle] = useState<string | null>(
    item.imgCaptionTitle
  )

  // update states state when prizes changes
  useEffect(() => {
    if (item.imgUrl !== imgUrl) {
      setImgUrl(item.imgUrl)
    }
    if (item.imgCaption !== imgCaption) {
      setImgCaption(item.imgCaption)
    }
    if (item.imgCaptionTitle !== imgCaptionTitle) {
      setImgCaptionTitle(item.imgCaptionTitle)
    }
  }, [item])

  // update prizes when states change
  useEffect(() => {
    if (
      item.imgUrl !== imgUrl ||
      item.imgCaption !== imgCaption ||
      item.imgCaptionTitle !== imgCaptionTitle
    ) {
      item.imgUrl = imgUrl
      item.imgCaption = imgCaption
      item.imgCaptionTitle = imgCaptionTitle
      setPrizes(prizes)
    }
  }, [imgUrl, imgCaption, imgCaptionTitle])

  // errors
  const [imgUrlError, setImgUrlError] = useState<boolean>(false)

  useEffect(() => {
    if (!imgUrl.length || !isValidURL(imgUrl)) {
      setImgUrlError(true)
    } else {
      setImgUrlError(false)
    }
  }, [imgUrl])

  return (
    <SliceWrapper
      editMode={editMode}
      sliceIndex={sliceIndex}
      removePrize={removePrize}
      provided={provided}
    >
      <SliceTitle>Prize</SliceTitle>
      <Stack direction="row" spacing={1}>
        <ImagePicker
          title="Image"
          error={imgUrlError}
          pickedImage={imgUrl}
          setPickedImage={(selectedImage: {
            url: string
            id: string
            description?: string
            type?: MediaSize
          }) => {
            setImgUrl(selectedImage.url)
          }}
          filters={{ category: "other" }}
          width="50%"
          height={88}
          disabled={!editMode}
        />
        <Stack spacing={1} style={{ width: "50%" }}>
          <TextField
            fullWidth
            size="small"
            label="Title"
            value={imgCaptionTitle ?? ""}
            onChange={(e) => {
              if (e.target.value.length) {
                setImgCaptionTitle(e.target.value)
              } else {
                setImgCaptionTitle(null)
              }
            }}
            disabled={!editMode}
            error={withCaptionTitleError && !imgCaptionTitle}
          />
          <TextField
            fullWidth
            size="small"
            label="Description"
            value={imgCaption ?? ""}
            onChange={(e) => {
              if (e.target.value.length) {
                setImgCaption(e.target.value)
              } else {
                setImgCaption(null)
              }
            }}
            disabled={!editMode}
            error={withCaptionError && !imgCaption}
          />
        </Stack>
      </Stack>
    </SliceWrapper>
  )
}

export default ImageSliceComponent
