import { Box, InputAdornment, Stack, TextField } from "@mui/material"
import { useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import { answerCustomChallenge, signIn } from "../../services/server/auth"
import { LoadingButton } from "@mui/lab"
import CenterWrapper from "../../components/global/centerWrapper"
import solarClientLogo from "../../assets/images/solar-client-logo.png"

const SignIn = () => {
  const { setIsAuth, setError, setErrorMessage } = useContext(MainContext)

  const [email, setEmail] = useState<string>("") // email input
  const [code, setCode] = useState<string>("") // code input
  const [loading, setLoading] = useState<boolean>(false) // signin and confirm code loading
  const [codeSended, setCodeSended] = useState<boolean>(false) // if code has been send or not

  // send code to user email
  const sendCode = async () => {
    setLoading(true)
    let response = false
    try {
      response = await signIn(email)
    } catch (error) {
      setError(true)
      console.info(error)
      setErrorMessage(error.message)
    }
    setLoading(false)
    if (response) setCodeSended(true)
  }

  // check code entered by user
  const checkCode = async () => {
    setLoading(true)
    try {
      await answerCustomChallenge(code)
      setLoading(false)
      setIsAuth(true)
    } catch (error) {
      setError(true)
      setErrorMessage(error.message)
      setLoading(false)
    }
  }

  return (
    <CenterWrapper>
      <Box
        sx={{
          width: "300px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img src={solarClientLogo} style={{ width: 280, marginBottom: 10 }} />
        {codeSended ? (
          <form
            onSubmit={(e) => {
              e.preventDefault()

              if (code.length > 0) {
                checkCode()
              }
            }}
          >
            <Stack gap={1}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Code"
                variant="outlined"
                value={code}
                type="password"
                onChange={(e: any) => {
                  if (
                    e.target.value.length < 7 &&
                    e.target.value.match(/^[0-9]*$/)
                  ) {
                    setCode(e.target.value)
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ marginLeft: 23 }}>
                      {code.length}/6
                    </InputAdornment>
                  ),
                }}
                sx={{ minWidth: 300 }}
              />
              <LoadingButton
                variant="contained"
                loading={loading}
                disabled={code.length === 0}
                onClick={checkCode}
              >
                Submit
              </LoadingButton>
            </Stack>
          </form>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault()

              if (email.length > 0) {
                sendCode()
              }
            }}
          >
            <Stack gap={1}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e: any) => {
                  setEmail(e.target.value)
                }}
                sx={{ minWidth: 300 }}
              />
              <LoadingButton
                variant="contained"
                loading={loading}
                disabled={
                  !email
                    .toLowerCase()
                    .match(
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    )
                }
                onClick={sendCode}
              >
                Sign in
              </LoadingButton>
            </Stack>
          </form>
        )}
      </Box>
    </CenterWrapper>
  )
}

export default SignIn
