import { useEffect, useState } from "react"
import EmbedSlice from "../../../models/bodySlices/embedSlice"
import ImageSlice from "../../../models/bodySlices/imageSlice"
import QuoteSlice from "../../../models/bodySlices/quoteSlice"
import TextSlice from "../../../models/bodySlices/textSlice"
import SliceTitle from "../common/sliceTitle"
import BlockEditorHtmlEditor from "../common/blockEditorHtmlEditor"
import SliceWrapper from "../common/sliceWrapper"
import { DraggableProvided } from "react-beautiful-dnd"

const TextSliceComponent = ({
  item,
  body,
  setBody,
  editMode,
  sliceIndex,
  removeSlice,
  provided,
}: {
  item: TextSlice
  body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
  setBody: (body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]) => void
  editMode: boolean
  sliceIndex: number
  removeSlice: (sliceToRemoveIndex: number) => void
  provided: DraggableProvided
}) => {
  // states
  const [text, setText] = useState<string>(item.text)

  // track editor update
  const [updated, setUpdated] = useState<boolean>(false)

  // update states state when body changes
  useEffect(() => {
    if (item.text !== text) {
      setText(item.text)
      setUpdated(!updated)
    }
  }, [item])

  // update body when states change
  useEffect(() => {
    if (item.text !== text) {
      item.text = text
      setBody(body)
    }
  }, [text])

  // errors
  const [textError, setTextError] = useState<boolean>(false)
  const [textErrorType, setTextErrorType] = useState<string>("")

  return (
    <SliceWrapper
      editMode={editMode}
      sliceIndex={sliceIndex}
      removeSlice={removeSlice}
      provided={provided}
    >
      <SliceTitle>Text</SliceTitle>
      <BlockEditorHtmlEditor
        text={text}
        setText={setText}
        max={10000}
        min={0}
        updated={updated}
        disabled={!editMode}
        error={textError}
        setError={setTextError}
        errorType={textErrorType}
        setErrorType={setTextErrorType}
      />
    </SliceWrapper>
  )
}

export default TextSliceComponent
