import { useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  productPublish,
  productUpsert,
  upsertDocumentProduct,
} from "../services/graphql/mutations"
import {
  productsStagedList,
  productsSearch,
  productStagedGet,
  listCurrencies,
} from "../services/graphql/queries"
import { deepCopy, logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"
import { lowercaseFirstCharacter } from "../services/utilities/utility"
import {
  GamificationProductType,
  ProductAvailability,
  ProductType,
  ProductVisibility,
  Stage,
} from "../services/config/enum"
import Document from "../models/document"
import Product from "../models/product"
import ProductPrice from "../models/productPrice"
import { AutocompleteOption } from "../services/config/interfaces"
import Currency from "../models/currency"

interface MarketplaceContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  productsList: Product[]
  setProductsList: Dispatch<SetStateAction<Product[]>>
  getProductsList: (withLoading?: boolean) => void
  doneChanges: boolean
  cancelChanges: () => void
  currentProduct: Product
  setCurrentProduct: Dispatch<SetStateAction<Product>>
  getCurrentProduct: (productId: string) => Promise<boolean>
  preChangesCurrentProduct: Product
  addTranslation: (translationToAdd: string) => void
  upsertProductParent: () => Promise<boolean>
  hasError: boolean
  translationsErrors: { lang: string; hasErrors: boolean }[]
  setTranslationsErrors: Dispatch<
    SetStateAction<{ lang: string; hasErrors: boolean }[]>
  >
  upsertProductDocument: (publish?: boolean) => Promise<boolean>
  copyDetailsFromDefault: (itemToCopyToIndex: number) => void
  copyBodyFromDefault: (itemToCopyToIndex: number) => void
  createProduct: (input: {
    availability: ProductAvailability
    defaultCurrency: string
    handle: string
    image: string
    limitPerUser: number
    prices: ProductPrice[]
    projectId: string
    sorting: number
    sponsoredBy: string
    type: ProductType
    visibility: ProductVisibility
    gamificationType?: GamificationProductType
    availableAtLocations: { id: string; type: string }[]
    teamId?: string
  }) => Promise<string | boolean>
  createProductDocument: (input: {
    parentId: string
    type: string
    productDocumentItems: {
      body: object[]
      default: boolean
      lang: string
      title: string
    }[]
  }) => Promise<boolean>
  productsListNextToken: string | null
  loadMoreProducts: () => Promise<boolean>
  hasSearched: boolean
  setHasSearched: Dispatch<SetStateAction<boolean | null>>
  searchLang: AutocompleteOption | null
  setSearchLang: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchStage: AutocompleteOption | null
  setSearchStage: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchVisibility: AutocompleteOption | null
  setSearchVisibility: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchTitle: string
  setSearchTitle: Dispatch<SetStateAction<string>>
  searchProductsList: (
    withLoading?: boolean,
    withNextToken?: boolean
  ) => Promise<boolean>
  resetFilters: () => void
  handleError: boolean
  setHandleError: Dispatch<SetStateAction<boolean>>
  projectIdError: boolean
  setProjectIdError: Dispatch<SetStateAction<boolean>>
  limitPerUserError: boolean
  setLimitPerUserError: Dispatch<SetStateAction<boolean>>
  sortingError: boolean
  setSortingError: Dispatch<SetStateAction<boolean>>
  sponsoredByError: boolean
  setSponsoredByError: Dispatch<SetStateAction<boolean>>
  availableAtLocationsError: boolean
  setAvailableAtLocationsError: Dispatch<SetStateAction<boolean>>
  pricesError: boolean
  setPricesError: Dispatch<SetStateAction<boolean>>
  publishProduct: () => Promise<boolean>
  currenciesListLoading: boolean
  currenciesList: Currency[]
}

const MarketplaceContext = createContext<MarketplaceContextInterface>({
  loading: true,
  setLoading: () => {},
  updatingList: false,
  setUpdatingList: () => {},
  editMode: false,
  setEditMode: () => {},
  productsList: [],
  setProductsList: () => {},
  getProductsList: () => {},
  doneChanges: false,
  cancelChanges: () => {},
  currentProduct: null,
  setCurrentProduct: () => {},
  getCurrentProduct: async () => true,
  preChangesCurrentProduct: null,
  addTranslation: () => {},
  upsertProductParent: async () => true,
  hasError: false,
  translationsErrors: [],
  setTranslationsErrors: () => {},
  upsertProductDocument: async () => true,
  copyDetailsFromDefault: () => {},
  copyBodyFromDefault: () => {},
  createProduct: async () => true,
  createProductDocument: async () => true,
  productsListNextToken: null,
  loadMoreProducts: async () => true,
  hasSearched: false,
  setHasSearched: () => {},
  searchLang: null,
  setSearchLang: () => {},
  searchStage: null,
  setSearchStage: () => {},
  searchVisibility: null,
  setSearchVisibility: () => {},
  searchTitle: "",
  setSearchTitle: () => {},
  searchProductsList: async () => true,
  resetFilters: () => {},
  handleError: false,
  setHandleError: () => {},
  projectIdError: false,
  setProjectIdError: () => {},
  limitPerUserError: false,
  setLimitPerUserError: () => {},
  sortingError: false,
  setSortingError: () => {},
  sponsoredByError: false,
  setSponsoredByError: () => {},
  availableAtLocationsError: false,
  setAvailableAtLocationsError: () => {},
  pricesError: false,
  setPricesError: () => {},
  publishProduct: async () => true,
  currenciesListLoading: true,
  currenciesList: [],
})

const MarketplaceController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // states
  const [loading, setLoading] = useState<boolean>(true) // loading
  const [updatingList, setUpdatingList] = useState<boolean>(false) // to show loader
  const [editMode, setEditMode] = useState<boolean>(true) // if all page should be disabled or not
  const [productsList, setProductsList] = useState<Product[]>([]) // list of all products
  const [doneChanges, setDoneChanges] = useState<boolean>(false) // if user has done changes
  const [currentProduct, setCurrentProduct] = useState<Product>() // current product
  const [preChangesCurrentProduct, setPreChangesCurrentProduct] =
    useState<Product>() // fetched current product
  const [productsListNextToken, setProductsListNextToken] = useState<
    string | null
  >(null) // next token for products list
  const [hasSearched, setHasSearched] = useState<boolean>(false) // if user has searched or not
  const [currenciesListLoading, setCurrenciesListLoading] =
    useState<boolean>(true) // currencies list loading
  const [currenciesList, setCurrenciesList] = useState<Currency[]>([]) // list of all available currencies

  // search states
  const [searchLang, setSearchLang] = useState<AutocompleteOption | null>(null)
  const [searchStage, setSearchStage] = useState<AutocompleteOption | null>(
    null
  )
  const [searchVisibility, setSearchVisibility] =
    useState<AutocompleteOption | null>(null)
  const [searchTitle, setSearchTitle] = useState<string>("")

  // errors for product edit
  const [handleError, setHandleError] = useState<boolean>(false)
  const [projectIdError, setProjectIdError] = useState<boolean>(false)
  const [limitPerUserError, setLimitPerUserError] = useState<boolean>(false)
  const [sortingError, setSortingError] = useState<boolean>(false)
  const [sponsoredByError, setSponsoredByError] = useState<boolean>(false)
  const [availableAtLocationsError, setAvailableAtLocationsError] =
    useState<boolean>(false)
  const [pricesError, setPricesError] = useState<boolean>(false)
  const [translationsErrors, setTranslationsErrors] = useState<
    { lang: string; hasErrors: boolean }[]
  >([]) // errors array for translations
  const hasError: boolean =
    handleError ||
    projectIdError ||
    limitPerUserError ||
    sortingError ||
    sponsoredByError ||
    availableAtLocationsError ||
    pricesError ||
    translationsErrors.filter((item) => item.hasErrors).length !== 0 // if there are errors or not

  // reset all errors
  const resetErrors = () => {
    setHandleError(false)
    setProjectIdError(false)
    setLimitPerUserError(false)
    setSortingError(false)
    setSponsoredByError(false)
    setAvailableAtLocationsError(false)
    setPricesError(false)
    const newTranslationsErrors: { lang: string; hasErrors: boolean }[] = []
    currentProduct.document.items.forEach((item) => {
      newTranslationsErrors.push({ lang: item.lang, hasErrors: false })
    })
    setTranslationsErrors(newTranslationsErrors)
  }

  // queries
  const [productsStagedListQuery] = useLazyQuery(productsStagedList)
  const [productsSearchQuery] = useLazyQuery(productsSearch)
  const [productStagedGetQuery] = useLazyQuery(productStagedGet)
  const [listCurrenciesQuery] = useLazyQuery(listCurrencies)

  // mutations
  const [productUpsertMutation] = useMutation(productUpsert)
  const [upsertDocumentProductMutation] = useMutation(upsertDocumentProduct)
  const [productPublishMutation] = useMutation(productPublish)

  // get products list
  const getProductsList = useCallback(
    async (withLoading: boolean = true) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter((item) => item.name === "Marketplace")[0].loading =
          true
        setPanelStatus([...panelStatus])
      }

      try {
        logger(Status.Api, "QUERY productsStagedList", productsStagedList)
        const { data } = await productsStagedListQuery({
          variables: {
            input: {
              limit: Math.round(window.innerHeight / 74) + 10,
            },
          },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "products list", data.productsStagedList.items)

        setProductsList(data.productsStagedList.items)
        setProductsListNextToken(data.productsStagedList.nextToken)

        setHasSearched(false)
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Marketplace")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "Marketplace")[0].loading =
          false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `productsStagedList`, e.message)
        }
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Marketplace")[0].status =
          "error"
        panelStatus.filter((item) => item.name === "Marketplace")[0].loading =
          false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // load more products
  const loadMoreProducts = async () => {
    try {
      logger(Status.Api, "QUERY productsStagedList", productsStagedList)
      const { data } = await productsStagedListQuery({
        variables: {
          input: {
            limit: Math.round(window.innerHeight / 74) + 10,
            nextToken: productsListNextToken,
          },
        },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "products list", [
        ...productsList,
        ...data.productsStagedList.items,
      ])

      setProductsList([...productsList, ...data.productsStagedList.items])
      setProductsListNextToken(data.productsStagedList.nextToken)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `productsStagedList`, e.message)
      }
      return false
    }
  }

  // search products
  const searchProductsList = async (
    withLoading = true,
    withNextToken = false
  ) => {
    if (withLoading) {
      setUpdatingList(true)
    }

    try {
      const input: {
        limit: number
        title?: string
        lang?: string
        stage?: string
        visibility?: string
        nextToken?: string
      } = {
        limit: Math.round(window.innerHeight / 74) + 10,
        title: searchTitle,
        lang: searchLang ? searchLang.id : null,
        stage: searchStage ? searchStage.id : null,
        visibility: searchVisibility ? searchVisibility.id : null,
      }

      if (hasSearched && withNextToken) {
        input.nextToken = productsListNextToken
      }

      logger(Status.Api, "QUERY productsSearch", productsSearch)
      const { data } = await productsSearchQuery({
        variables: {
          input: input,
        },
        fetchPolicy: "no-cache",
      })

      if (input.nextToken) {
        logger(Status.Info, "products list", [
          ...productsList,
          ...data.productsSearch.items,
        ])

        setProductsList([...productsList, ...data.productsSearch.items])
        setProductsListNextToken(data.productsSearch.nextToken)
      } else {
        logger(Status.Info, "products list", data.productsSearch.items)

        setProductsList(data.productsSearch.items)
        setProductsListNextToken(data.productsSearch.nextToken)
      }

      setHasSearched(true)
      setUpdatingList(false)
      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `productsSearch`, e.message)
      }
      setUpdatingList(false)
      return false
    }
  }

  // get current product
  const getCurrentProduct = useCallback(
    async (productId: string) => {
      try {
        logger(Status.Api, "QUERY productStagedGet", productStagedGet)
        const { data } = await productStagedGetQuery({
          variables: { input: { id: productId } },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, `product ${productId}`, data.productStagedGet)

        setCurrentProduct(data.productStagedGet)
        setPreChangesCurrentProduct(deepCopy(data.productStagedGet))
        data.productStagedGet.document.items.forEach((item: Document) => {
          translationsErrors.push({ lang: item.lang, hasErrors: false })
        })
        setTranslationsErrors([...translationsErrors])

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `productStagedGet`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // upsert product parent
  const upsertProductParent = async () => {
    try {
      const input = {
        availability: currentProduct.availability,
        defaultCurrency: currentProduct.defaultCurrency,
        handle: currentProduct.handle,
        id: currentProduct.id,
        image: currentProduct.image,
        prices: currentProduct.prices.map((price) => {
          return {
            amount: price.amount,
            currency: price.currency,
          }
        }),
        projectId: currentProduct.projectId,
        sorting: currentProduct.sorting,
        sponsoredBy: currentProduct.sponsoredBy,
        teamId: currentProduct.team.id,
        type: currentProduct.type,
        limitPerUser: currentProduct.limitPerUser,
        visibility: currentProduct.visibility,
        availableAtLocations: currentProduct.availableAtLocations.map(
          (item) => {
            return {
              id: item.id,
              type: item.__typename,
            }
          }
        ),
      }

      if (currentProduct.type === ProductType.Gamification) {
        input["gamificationType"] = currentProduct.gamificationType
      }

      logger(Status.Api, "MUTATION productUpsert", productUpsert)
      const { data } = await productUpsertMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `product ${data.productUpsert.id} upserted`,
        data.productUpsert
      )

      setCurrentProduct((current) => {
        return { ...data.productUpsert, document: current.document }
      })
      setPreChangesCurrentProduct((current) => {
        return { ...deepCopy(data.productUpsert), document: current.document }
      })

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `productUpsert`, e.message)
      }
      return false
    }
  }

  // upsert product document
  const upsertProductDocument = async (publish = false) => {
    try {
      // parse data for input
      const currentProductCopy = deepCopy(currentProduct)
      currentProductCopy.document.items.forEach((item: any) => {
        const newBody = []
        if (item.body) {
          item.body.forEach((bodyItem: any) => {
            const { sliceType, __typename, ...rest } = bodyItem
            newBody.push({
              [lowercaseFirstCharacter(bodyItem.sliceType)]: {
                ...rest,
              },
            })
          })
        }
        item.body = newBody

        item.default = item.isDefault
        delete item.isDefault
        delete item.type
        delete item.updatedAt
        delete item.parentId
        delete item.__typename
      })

      const input = {
        productDocumentItems: currentProductCopy.document.items,
        parentId: `#id#${currentProductCopy.id}#stage#${
          publish ? "PUBLISHED" : "DRAFT"
        }`,
        type: "Product",
      }

      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentProduct)
      const { data } = await upsertDocumentProductMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `product ${currentProduct.id} document upserted`,
        data.documentUpsert
      )

      setCurrentProduct((current) => {
        return { ...current, document: data.documentUpsert }
      })
      setPreChangesCurrentProduct((current) => {
        return { ...current, document: deepCopy(data.documentUpsert) }
      })

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // publish product
  const publishProduct = async () => {
    try {
      logger(Status.Api, "MUTATION productPublish", productPublish)
      await Promise.all([
        productPublishMutation({
          variables: { input: { id: currentProduct.id } },
        }),
        upsertProductDocument(true),
      ])
      logger(Status.Info, `product ${currentProduct.id} published`)

      // update current product locally
      setCurrentProduct({
        ...currentProduct,
        stage: Stage.PUBLISHED,
      })
      setPreChangesCurrentProduct({
        ...preChangesCurrentProduct,
        stage: Stage.PUBLISHED,
      })

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `productPublish`, e.message)
      }
      return false
    }
  }

  // create product
  const createProduct = async (input: {
    availability: ProductAvailability
    defaultCurrency: string
    handle: string
    image: string
    limitPerUser: number
    prices: ProductPrice[]
    projectId: string
    sorting: number
    sponsoredBy: string
    type: ProductType
    visibility: ProductVisibility
    gamificationType?: GamificationProductType
    availableAtLocations: { id: string; type: string }[]
    teamId?: string
  }) => {
    try {
      logger(Status.Api, "MUTATION productUpsert", productUpsert)
      const { data } = await productUpsertMutation({
        variables: { input: input },
      })
      logger(Status.Info, `product created`, data.productUpsert)

      return data.productUpsert.id
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `productUpsert`, e.message)
      }
      return false
    }
  }

  // create product document
  const createProductDocument = async (input: {
    parentId: string
    type: string
    productDocumentItems: {
      body: object[]
      default: boolean
      lang: string
      title: string
    }[]
  }) => {
    try {
      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentProduct)
      const { data } = await upsertDocumentProductMutation({
        variables: { input: input },
      })
      logger(Status.Info, `document created`, data.documentUpsert)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // add translation
  const addTranslation = (translationToAdd: string) => {
    const documentToAdd: Document = {
      body: [],
      isDefault: false,
      lang: translationToAdd,
      title: "Title",
      type: "Product",
    }

    translationsErrors.push({
      lang: translationToAdd,
      hasErrors: false,
    })
    setTranslationsErrors([...translationsErrors])

    currentProduct.document.items.push(documentToAdd)
    setCurrentProduct({ ...currentProduct })
  }

  // copy details from default translation
  const copyDetailsFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = deepCopy(
      currentProduct.document.items.filter((item) => item.isDefault)[0]
    )

    currentProduct.document.items[itemToCopyToIndex].title = defaultItem.title

    setCurrentProduct({ ...currentProduct })
  }

  // copy body from default translation
  const copyBodyFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = deepCopy(
      currentProduct.document.items.filter((item) => item.isDefault)[0]
    )

    currentProduct.document.items[itemToCopyToIndex].body = defaultItem.body

    setCurrentProduct({ ...currentProduct })
  }

  // cancel changes
  const cancelChanges = () => {
    setCurrentProduct(deepCopy(preChangesCurrentProduct))
    resetErrors()
  }

  // check if user has done changes
  useEffect(() => {
    if (currentProduct) {
      const currentProductCopy = deepCopy(currentProduct)
      const preChangesCurrentProductCopy = deepCopy(preChangesCurrentProduct)

      delete currentProductCopy.team.document
      delete currentProductCopy.team.__typename
      delete preChangesCurrentProductCopy.team.document
      delete preChangesCurrentProductCopy.team.__typename

      if (
        JSON.stringify(currentProductCopy) !==
        JSON.stringify(preChangesCurrentProductCopy)
      ) {
        setDoneChanges(true)
      } else {
        setDoneChanges(false)
      }
    }
  }, [currentProduct])

  // reset list filters
  const resetFilters = () => {
    setSearchLang(null)
    setSearchStage(null)
    setSearchVisibility(null)
    setSearchTitle("")
  }

  // get currencies list
  const getCurrenciesList = async () => {
    try {
      logger(Status.Api, "QUERY currenciesList", listCurrencies)
      const { data } = await listCurrenciesQuery({
        variables: { input: { limit: 100 } },
      })
      logger(Status.Info, "currencies list", data.currenciesList.items)

      setCurrenciesList(data.currenciesList.items)

      panelStatus.filter((item) => item.name === "Currencies")[0].status =
        "success"
      panelStatus.filter((item) => item.name === "Currencies")[0].loading =
        false
      setPanelStatus([...panelStatus])

      setCurrenciesListLoading(false)
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `currenciesList`, e.message)
      }
      panelStatus.filter((item) => item.name === "Currencies")[0].status =
        "error"
      panelStatus.filter((item) => item.name === "Currencies")[0].loading =
        false
      setPanelStatus([...panelStatus])
    }
  }

  // add update function to panel status and do initial fecth
  useEffect(() => {
    panelStatus.filter(
      (item) => item.name === "Marketplace"
    )[0].updateFunction = getProductsList
    panelStatus.filter((item) => item.name === "Currencies")[0].updateFunction =
      getProductsList

    getCurrenciesList()
  }, [])

  return (
    <MarketplaceContext.Provider
      value={{
        loading,
        setLoading,
        updatingList,
        setUpdatingList,
        editMode,
        setEditMode,
        productsList,
        setProductsList,
        getProductsList,
        doneChanges,
        cancelChanges,
        currentProduct,
        setCurrentProduct,
        getCurrentProduct,
        preChangesCurrentProduct,
        addTranslation,
        upsertProductParent,
        hasError,
        translationsErrors,
        setTranslationsErrors,
        upsertProductDocument,
        copyDetailsFromDefault,
        copyBodyFromDefault,
        createProduct,
        createProductDocument,
        productsListNextToken,
        loadMoreProducts,
        hasSearched,
        setHasSearched,
        searchLang,
        setSearchLang,
        searchStage,
        setSearchStage,
        searchVisibility,
        setSearchVisibility,
        searchTitle,
        setSearchTitle,
        searchProductsList,
        resetFilters,
        handleError,
        setHandleError,
        projectIdError,
        setProjectIdError,
        limitPerUserError,
        setLimitPerUserError,
        sortingError,
        setSortingError,
        sponsoredByError,
        setSponsoredByError,
        availableAtLocationsError,
        setAvailableAtLocationsError,
        pricesError,
        setPricesError,
        publishProduct,
        currenciesListLoading,
        currenciesList,
      }}
    >
      {children}
    </MarketplaceContext.Provider>
  )
}

export { MarketplaceController, MarketplaceContext }
