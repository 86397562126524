import { useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import Category from "../models/category"
import {
  categoriesSearch,
  categoryGet,
  listCategories,
  listMainCategories,
  listMainTopics,
  listSdgs,
  listSdgTargets,
  listTopics,
  mainCategoryGet,
  sdgGet,
  sdgsSearch,
  sdgTargetGet,
  sdgTargetsSearch,
  topicGet,
  topicsSearch,
} from "../services/graphql/queries"
import { deepCopy, logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"
import Sdg from "../models/sdg"
import SdgTarget from "../models/sdgTarget"
import Topic from "../models/topic"
import {
  categoryUpsert,
  mainCategoryUpsert,
  sdgTargetUpsert,
  sdgUpsert,
  topicUpsert,
} from "../services/graphql/mutations"
import { mainCategoriesSearch } from "../services/graphql/queries"

interface AutocompleteOption {
  label: string
  id: string
}

interface TagsContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  categoriesLoading: boolean
  categoriesList: Category[]
  getCategoriesList: (withLoading?: boolean) => Promise<boolean>
  mainCategoriesLoading: boolean
  mainCategoriesList: Category[]
  getMainCategoriesList: (withLoading?: boolean) => Promise<boolean>
  topicsLoading: boolean
  topicsList: Topic[]
  getTopicsList: (withLoading?: boolean) => Promise<boolean>
  mainTopicsLoading: boolean
  mainTopicsList: Topic[]
  sdgsLoading: boolean
  sdgsList: Sdg[]
  getSdgsList: (withLoading?: boolean) => Promise<boolean>
  sdgTargetsLoading: boolean
  sdgTargetsList: SdgTarget[]
  getSdgTargetsList: (withLoading?: boolean) => Promise<boolean>
  currentTab: number
  setCurrentTab: Dispatch<SetStateAction<number>>
  updatingCategoriesList: boolean
  updatingMainCategoriesList: boolean
  updatingSdgsList: boolean
  updatingSdgTargetsList: boolean
  updatingTopicsList: boolean
  upsertCategory: (input: {
    id?: string
    name: string
    code: string
    backColor: string
    backTagColor: string
    foreColor: string
    mainCategory?: string
    translations: {
      name: string
      lang: string
    }[]
  }) => Promise<Category | boolean>
  upsertMainCategory: (input: {
    id?: string
    name: string
    code: string
    backColor: string
    backTagColor: string
    foreColor: string
    translations: {
      lang: string
      name: string
      description?: string
      cta?: string
    }[]
  }) => Promise<Category | boolean>
  upsertSdg: (input: {
    id?: string
    name: string
    image: string
    code: string
    translations: { name: string; image: string; lang: string }[]
  }) => Promise<Sdg | boolean>
  upsertSdgTarget: (input: {
    id?: string
    code: string
    translations: { description: string; lang: string }[]
  }) => Promise<SdgTarget | boolean>
  upsertTopic: (input: {
    id?: string
    name: string
    code: string
    mainTopic: string
    translations: { name: string; lang: string }[]
  }) => Promise<Topic | boolean>
  currentCategory: Category | null
  setCurrentCategory: Dispatch<SetStateAction<Category | null>>
  preChangesCurrentCategory: Category | null
  currentMainCategory: Category | null
  setCurrentMainCategory: Dispatch<SetStateAction<Category | null>>
  preChangesCurrentMainCategory: Category | null
  currentSdg: Sdg | null
  setCurrentSdg: Dispatch<SetStateAction<Sdg | null>>
  preChangesCurrentSdg: Sdg | null
  currentSdgTarget: SdgTarget | null
  setCurrentSdgTarget: Dispatch<SetStateAction<SdgTarget | null>>
  preChangesCurrentSdgTarget: SdgTarget | null
  currentTopic: Topic | null
  setCurrentTopic: Dispatch<SetStateAction<Topic | null>>
  preChangesCurrentTopic: Topic | null
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  doneChangesCategory: boolean
  doneChangesMainCategory: boolean
  doneChangesSdg: boolean
  doneChangesSdgTarget: boolean
  doneChangesTopic: boolean
  cancelChangesCategory: () => void
  cancelChangesMainCategory: () => void
  cancelChangesSdg: () => void
  cancelChangesSdgTarget: () => void
  cancelChangesTopic: () => void
  getCurrentCategory: (categoryId: string) => Promise<boolean>
  getCurrentMainCategory: (mainCategoryId: string) => Promise<boolean>
  getCurrentSdg: (sdgId: string) => Promise<boolean>
  getCurrentSdgTarget: (sdgTargetId: string) => Promise<boolean>
  getCurrentTopic: (topicId: string) => Promise<boolean>
  addTranslationToCategory: (translationToAdd: string) => void
  addTranslationToMainCategory: (translationToAdd: string) => void
  addTranslationToSdg: (translationToAdd: string) => void
  addTranslationToSdgTarget: (translationToAdd: string) => void
  addTranslationToTopic: (translationToAdd: string) => void
  categoriesFilters: {
    name: string
    mainCategory: AutocompleteOption | null
    lang: AutocompleteOption | null
  }
  setCategoriesFilters: Dispatch<
    SetStateAction<{
      name: string
      mainCategory: AutocompleteOption | null
      lang: AutocompleteOption | null
    }>
  >
  mainCategoriesFilters: {
    name: string
    lang: AutocompleteOption | null
  }
  setMainCategoriesFilters: Dispatch<
    SetStateAction<{
      lang: AutocompleteOption | null
    }>
  >
  sdgsFilters: {
    name: string
    lang: AutocompleteOption | null
  }
  setSdgsFilters: Dispatch<
    SetStateAction<{
      lang: AutocompleteOption | null
    }>
  >
  sdgTargetsFilters: {
    lang: AutocompleteOption | null
  }
  setSdgTargetsFilters: Dispatch<
    SetStateAction<{
      lang: AutocompleteOption | null
    }>
  >
  topicsFilters: {
    name: string
    lang: AutocompleteOption | null
    mainTopic: AutocompleteOption | null
  }
  setTopicsFilters: Dispatch<
    SetStateAction<{
      name: string
      lang: AutocompleteOption | null
      mainTopic: AutocompleteOption | null
    }>
  >
}

const TagsContext = createContext<TagsContextInterface>({
  loading: false,
  setLoading: () => {},
  categoriesLoading: true,
  categoriesList: [],
  getCategoriesList: async () => true,
  mainCategoriesLoading: true,
  mainCategoriesList: [],
  getMainCategoriesList: async () => true,
  topicsLoading: true,
  topicsList: [],
  getTopicsList: async () => true,
  mainTopicsLoading: true,
  mainTopicsList: [],
  sdgsLoading: true,
  sdgsList: [],
  getSdgsList: async () => true,
  sdgTargetsLoading: true,
  sdgTargetsList: [],
  getSdgTargetsList: async () => true,
  currentTab: 0,
  setCurrentTab: () => {},
  updatingCategoriesList: false,
  updatingMainCategoriesList: false,
  updatingSdgsList: false,
  updatingSdgTargetsList: false,
  updatingTopicsList: false,
  upsertCategory: async () => false,
  upsertMainCategory: async () => false,
  upsertSdg: async () => false,
  upsertSdgTarget: async () => false,
  upsertTopic: async () => false,
  currentCategory: null,
  setCurrentCategory: () => {},
  preChangesCurrentCategory: null,
  currentMainCategory: null,
  setCurrentMainCategory: () => {},
  preChangesCurrentMainCategory: null,
  currentSdg: null,
  setCurrentSdg: () => {},
  preChangesCurrentSdg: null,
  currentSdgTarget: null,
  setCurrentSdgTarget: () => {},
  preChangesCurrentSdgTarget: null,
  currentTopic: null,
  setCurrentTopic: () => {},
  preChangesCurrentTopic: null,
  editMode: true,
  setEditMode: () => {},
  doneChangesCategory: false,
  doneChangesMainCategory: false,
  doneChangesSdg: false,
  doneChangesSdgTarget: false,
  doneChangesTopic: false,
  cancelChangesCategory: () => {},
  cancelChangesMainCategory: () => {},
  cancelChangesSdg: () => {},
  cancelChangesSdgTarget: () => {},
  cancelChangesTopic: () => {},
  getCurrentCategory: async () => true,
  getCurrentMainCategory: async () => true,
  getCurrentSdg: async () => true,
  getCurrentSdgTarget: async () => true,
  getCurrentTopic: async () => true,
  addTranslationToCategory: () => {},
  addTranslationToMainCategory: () => {},
  addTranslationToSdg: () => {},
  addTranslationToSdgTarget: () => {},
  addTranslationToTopic: () => {},
  categoriesFilters: {
    name: "",
    mainCategory: null,
    lang: null,
  },
  setCategoriesFilters: () => {},
  mainCategoriesFilters: {
    name: "",
    lang: null,
  },
  setMainCategoriesFilters: () => {},
  sdgsFilters: {
    name: "",
    lang: null,
  },
  setSdgsFilters: () => {},
  sdgTargetsFilters: {
    lang: null,
  },
  setSdgTargetsFilters: () => {},
  topicsFilters: {
    name: "",
    lang: null,
    mainTopic: null,
  },
  setTopicsFilters: () => {},
})

const TagsController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // loadings
  const [loading, setLoading] = useState<boolean>(false) // general loading
  const [categoriesLoading, setCategoriesLoading] = useState<boolean>(true) // loading for categories
  const [mainCategoriesLoading, setMainCategoriesLoading] =
    useState<boolean>(true) // loading for main categories
  const [sdgsLoading, setSdgsLoading] = useState<boolean>(true) // loading for sdgs
  const [sdgTargetsLoading, setSdgTargetsLoading] = useState<boolean>(true) // loading for sdg targets
  const [topicsLoading, setTopicsLoading] = useState<boolean>(true) // loading for topics
  const [mainTopicsLoading, setMainTopicsLoading] = useState<boolean>(true) // loading for main topics
  // update loadings
  const [updatingCategoriesList, setUpdatingCategoriesList] =
    useState<boolean>(false) // loading for updating categories list
  const [updatingMainCategoriesList, setUpdatingMainCategoriesList] =
    useState<boolean>(false) // loading for updating main categories list
  const [updatingSdgsList, setUpdatingSdgsList] = useState<boolean>(false) // loading for updating sdgs list
  const [updatingSdgTargetsList, setUpdatingSdgTargetsList] =
    useState<boolean>(false) // loading for updating sdg targets list
  const [updatingTopicsList, setUpdatingTopicsList] = useState<boolean>(false) // loading for updating topics list

  // states
  // lists
  const [categoriesList, setCategoriesList] = useState<Category[]>([]) // all categories list
  const [mainCategoriesList, setMainCategoriesList] = useState<Category[]>([]) // all main categories list
  const [sdgsList, setSdgsList] = useState<Sdg[]>([]) // all sdgs list
  const [sdgTargetsList, setSdgTargetsList] = useState<SdgTarget[]>([]) // all sdg targets list
  const [topicsList, setTopicsList] = useState<Topic[]>([]) // all topics list
  const [mainTopicsList, setMainTopicsList] = useState<Topic[]>([]) // all main topics list
  // current states
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null) // current category
  const [preChangesCurrentCategory, setPreChangesCurrentCategory] =
    useState<Category | null>(null) // pre changes current category
  const [currentMainCategory, setCurrentMainCategory] =
    useState<Category | null>(null) // current main category
  const [preChangesCurrentMainCategory, setPreChangesCurrentMainCategory] =
    useState<Category | null>(null) // pre changes current main category
  const [currentSdg, setCurrentSdg] = useState<Sdg | null>(null) // current sdg
  const [preChangesCurrentSdg, setPreChangesCurrentSdg] = useState<Sdg | null>(
    null
  ) // pre changes current sdg
  const [currentSdgTarget, setCurrentSdgTarget] = useState<SdgTarget | null>(
    null
  ) // current sdg target
  const [preChangesCurrentSdgTarget, setPreChangesCurrentSdgTarget] =
    useState<SdgTarget | null>(null) // pre changes current sdg target
  const [currentTopic, setCurrentTopic] = useState<Topic | null>(null) // current topic
  const [preChangesCurrentTopic, setPreChangesCurrentTopic] =
    useState<Topic | null>(null) // pre changes current topic
  // done changes states
  const [doneChangesCategory, setDoneChangesCategory] = useState<boolean>(false) // if changes have been made to current category
  const [doneChangesMainCategory, setDoneChangesMainCategory] =
    useState<boolean>(false) // if changes have been made to current main category
  const [doneChangesSdg, setDoneChangesSdg] = useState<boolean>(false) // if changes have been made to current sdg
  const [doneChangesSdgTarget, setDoneChangesSdgTarget] =
    useState<boolean>(false) // if changes have been made to current sdg target
  const [doneChangesTopic, setDoneChangesTopic] = useState<boolean>(false) // if changes have been made to current topic
  // search states
  const [categoriesFilters, setCategoriesFilters] = useState<{
    name: string
    mainCategory: AutocompleteOption | null
    lang: AutocompleteOption | null
  }>({
    name: "",
    mainCategory: null,
    lang: null,
  })
  const [mainCategoriesFilters, setMainCategoriesFilters] = useState<{
    name: string
    lang: AutocompleteOption | null
  }>({
    name: "",
    lang: null,
  })
  const [sdgsFilters, setSdgsFilters] = useState<{
    name: string
    lang: AutocompleteOption | null
  }>({
    name: "",
    lang: null,
  })
  const [sdgTargetsFilters, setSdgTargetsFilters] = useState<{
    lang: AutocompleteOption | null
  }>({
    lang: null,
  })
  const [topicsFilters, setTopicsFilters] = useState<{
    name: string
    lang: AutocompleteOption | null
    mainTopic: AutocompleteOption | null
  }>({
    name: "",
    lang: null,
    mainTopic: null,
  })
  // has searched states
  const [hasSearchedCategories, setHasSearchedCategories] =
    useState<boolean>(false) // if user has searched or not categories
  const [hasSearchedMainCategories, setHasSearchedMainCategories] =
    useState<boolean>(false) // if user has searched or not main categories
  const [hasSearchedSdgs, setHasSearchedSdgs] = useState<boolean>(false) // if user has searched or not sdgs
  const [hasSearchedSdgTargets, setHasSearchedSdgTargets] =
    useState<boolean>(false) // if user has searched or not sdg targets
  const [hasSearchedTopics, setHasSearchedTopics] = useState<boolean>(false) // if user has searched or not topics
  // general
  const [currentTab, setCurrentTab] = useState<number>(0) // current tab for tags list
  const [editMode, setEditMode] = useState<boolean>(true) // if user is in edit mode or not

  // queries
  const [listCategoriesQuery] = useLazyQuery(listCategories)
  const [listMainCategoriesQuery] = useLazyQuery(listMainCategories)
  const [listTopicsQuery] = useLazyQuery(listTopics)
  const [listMainTopicsQuery] = useLazyQuery(listMainTopics)
  const [listSdgsQuery] = useLazyQuery(listSdgs)
  const [listSdgTargetsQuery] = useLazyQuery(listSdgTargets)
  const [categoryGetQuery] = useLazyQuery(categoryGet)
  const [mainCategoryGetQuery] = useLazyQuery(mainCategoryGet)
  const [sdgGetQuery] = useLazyQuery(sdgGet)
  const [sdgTargetGetQuery] = useLazyQuery(sdgTargetGet)
  const [topicGetQuery] = useLazyQuery(topicGet)
  const [categoriesSearchQuery] = useLazyQuery(categoriesSearch)
  const [mainCategoriesSearchQuery] = useLazyQuery(mainCategoriesSearch)
  const [sdgsSearchQuery] = useLazyQuery(sdgsSearch)
  const [sdgTargetsSearchQuery] = useLazyQuery(sdgTargetsSearch)
  const [topicsSearchQuery] = useLazyQuery(topicsSearch)

  // mutations
  const [categoryUpsertMutation] = useMutation(categoryUpsert)
  const [mainCategoryUpsertMutation] = useMutation(mainCategoryUpsert)
  const [sdgUpsertMutation] = useMutation(sdgUpsert)
  const [sdgTargetUpsertMutation] = useMutation(sdgTargetUpsert)
  const [topicUpsertMutation] = useMutation(topicUpsert)

  // get categories list
  const getCategoriesList = useCallback(
    async (withLoading = true) => {
      if (withLoading) {
        setCategoriesLoading(true)
        panelStatus.filter((item) => item.name === "Categories")[0].loading =
          true
        setPanelStatus([...panelStatus])
      } else {
        setUpdatingCategoriesList(true)
      }
      setHasSearchedCategories(false)

      try {
        logger(Status.Api, "QUERY categoriesList", listCategories)
        const { data } = await listCategoriesQuery({
          variables: { input: {} },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "categories list", data.categoriesList.items)

        setCategoriesList(data.categoriesList.items)

        setCategoriesLoading(false)
        setUpdatingCategoriesList(false)
        panelStatus.filter((item) => item.name === "Categories")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "Categories")[0].loading =
          false
        setPanelStatus([...panelStatus])
        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `categoriesList`, e.message)
        }
        setCategoriesLoading(false)
        setUpdatingCategoriesList(false)
        panelStatus.filter((item) => item.name === "Categories")[0].status =
          "error"
        panelStatus.filter((item) => item.name === "Categories")[0].loading =
          false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // get main categories list
  const getMainCategoriesList = useCallback(
    async (withLoading = true) => {
      if (withLoading) {
        setMainCategoriesLoading(true)
        panelStatus.filter(
          (item) => item.name === "Main Categories"
        )[0].loading = true
        setPanelStatus([...panelStatus])
      } else {
        setUpdatingMainCategoriesList(true)
      }
      setHasSearchedMainCategories(false)

      try {
        logger(Status.Api, "QUERY listMainCategories", listMainCategories)
        const { data } = await listMainCategoriesQuery({
          fetchPolicy: "no-cache",
          variables: { input: {} },
        })
        logger(
          Status.Info,
          "main categories list",
          data.mainCategoriesList.items
        )

        setMainCategoriesList(data.mainCategoriesList.items)

        setMainCategoriesLoading(false)
        setUpdatingMainCategoriesList(false)
        panelStatus.filter(
          (item) => item.name === "Main Categories"
        )[0].status = "success"
        panelStatus.filter(
          (item) => item.name === "Main Categories"
        )[0].loading = false
        setPanelStatus([...panelStatus])
        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `listMainCategories`, e.message)
        }
        setMainCategoriesLoading(false)
        setUpdatingMainCategoriesList(false)
        panelStatus.filter(
          (item) => item.name === "Main Categories"
        )[0].status = "error"
        panelStatus.filter(
          (item) => item.name === "Main Categories"
        )[0].loading = false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // get sdgs list
  const getSdgsList = useCallback(
    async (withLoading = true) => {
      if (withLoading) {
        setSdgsLoading(true)
        panelStatus.filter((item) => item.name === "SDGs")[0].loading = true
        setPanelStatus([...panelStatus])
      } else {
        setUpdatingSdgsList(true)
      }
      setHasSearchedSdgs(false)

      try {
        logger(Status.Api, "QUERY listSdgs", listSdgs)
        const { data } = await listSdgsQuery({
          variables: { input: {} },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, `sdgs list`, data.sdgsList.items)

        setSdgsList(data.sdgsList.items)
        setSdgsLoading(false)
        setUpdatingSdgsList(false)
        panelStatus.filter((item) => item.name === "SDGs")[0].status = "success"
        panelStatus.filter((item) => item.name === "SDGs")[0].loading = false
        setPanelStatus([...panelStatus])
        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `sdgsList`, e.message)
        }
        setSdgsLoading(false)
        panelStatus.filter((item) => item.name === "SDGs")[0].status = "error"
        panelStatus.filter((item) => item.name === "SDGs")[0].loading = false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // get sdg targets list
  const getSdgTargetsList = useCallback(
    async (withLoading = true) => {
      if (withLoading) {
        setSdgTargetsLoading(true)
        panelStatus.filter((item) => item.name === "SDG targets")[0].loading =
          true
        setPanelStatus([...panelStatus])
      } else {
        setUpdatingSdgTargetsList(true)
      }
      setHasSearchedSdgTargets(false)

      try {
        logger(Status.Api, "QUERY listSdgTargets", listSdgTargets)
        const { data } = await listSdgTargetsQuery({
          variables: { input: {} },
          fetchPolicy: "no-cache",
        })
        let listCopy = deepCopy(data.sdgTargetsList.items)
        listCopy.sort((a: { id: string }, b: { id: string }) => {
          let slicedA = a.id.slice(a.id.indexOf("#") + 1, a.id.indexOf("."))
          let slicedB = b.id.slice(b.id.indexOf("#") + 1, b.id.indexOf("."))

          if (parseInt(slicedA) < parseInt(slicedB)) {
            return -1
          }
          if (parseInt(slicedA) > parseInt(slicedB)) {
            return 1
          }
          return 0
        })
        logger(Status.Info, `sdg targets list`, listCopy)

        setSdgTargetsList(listCopy)
        setSdgTargetsLoading(false)
        setUpdatingSdgTargetsList(false)
        panelStatus.filter((item) => item.name === "SDG targets")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "SDG targets")[0].loading =
          false
        setPanelStatus([...panelStatus])
        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `sdgTargetsList`, e.message)
        }
        setSdgTargetsLoading(false)
        setUpdatingSdgTargetsList(false)
        panelStatus.filter((item) => item.name === "SDG targets")[0].status =
          "error"
        panelStatus.filter((item) => item.name === "SDG targets")[0].loading =
          false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // get topics list
  const getTopicsList = useCallback(
    async (withLoading = true) => {
      if (withLoading) {
        setTopicsLoading(true)
        panelStatus.filter((item) => item.name === "Topics")[0].loading = true
        setPanelStatus([...panelStatus])
      } else {
        setUpdatingTopicsList(true)
      }
      setHasSearchedTopics(false)

      try {
        logger(Status.Api, "QUERY topicsList", listTopics)
        const { data } = await listTopicsQuery({
          variables: { input: {} },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, `topics list`, data.topicsList.items)

        setTopicsList(data.topicsList.items)
        setTopicsLoading(false)
        setUpdatingTopicsList(false)
        panelStatus.filter((item) => item.name === "Topics")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "Topics")[0].loading = false
        setPanelStatus([...panelStatus])
        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `topicsList`, e.message)
        }
        setTopicsLoading(false)
        setUpdatingTopicsList(false)
        panelStatus.filter((item) => item.name === "Topics")[0].status = "error"
        panelStatus.filter((item) => item.name === "Topics")[0].loading = false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // get main topics list
  const getMainTopicsList = useCallback(async () => {
    setMainTopicsLoading(true)
    panelStatus.filter((item) => item.name === "Main Topics")[0].loading = true
    setPanelStatus([...panelStatus])

    try {
      logger(Status.Api, "QUERY mainTopicsList", listMainTopics)
      const { data } = await listMainTopicsQuery({
        variables: { input: {} },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, `main topics list`, data.mainTopicsList.items)

      setMainTopicsList(data.mainTopicsList.items)
      setMainTopicsLoading(false)
      panelStatus.filter((item) => item.name === "Main Topics")[0].status =
        "success"
      panelStatus.filter((item) => item.name === "Main Topics")[0].loading =
        false
      setPanelStatus([...panelStatus])
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `mainTopicsList`, e.message)
      }
      setMainTopicsLoading(false)
      panelStatus.filter((item) => item.name === "Main Topics")[0].status =
        "error"
      panelStatus.filter((item) => item.name === "Main Topics")[0].loading =
        false
      setPanelStatus([...panelStatus])
    }
  }, [setError, setErrorMessage])

  // upsert category
  const upsertCategory = async (input: {
    id?: string
    name: string
    code: string
    backColor: string
    backTagColor: string
    foreColor: string
    mainCategory?: string
    translations: {
      name: string
      lang: string
    }[]
  }) => {
    try {
      logger(Status.Api, "MUTATION categoryUpsert", categoryUpsert)
      const { data } = await categoryUpsertMutation({
        variables: { input: input },
      })
      logger(Status.Info, `category upserted`, data.categoryUpsert)

      setCurrentCategory(data.categoryUpsert)
      setPreChangesCurrentCategory(deepCopy(data.categoryUpsert))

      return data.categoryUpsert
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `categoryUpsert`, e.message)
      }
      return false
    }
  }

  // upsert main category
  const upsertMainCategory = async (input: {
    id?: string
    name: string
    code: string
    backColor: string
    backTagColor: string
    foreColor: string
    translations: {
      lang: string
      name: string
      description?: string
      cta?: string
    }[]
  }) => {
    try {
      logger(Status.Api, "MUTATION mainCategoryUpsert", mainCategoryUpsert)
      const { data } = await mainCategoryUpsertMutation({
        variables: { input: input },
      })
      logger(Status.Info, `main category upserted`, data.mainCategoryUpsert)

      setCurrentMainCategory(data.mainCategoryUpsert)
      setPreChangesCurrentMainCategory(deepCopy(data.mainCategoryUpsert))

      return data.mainCategoryUpsert
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `mainCategoryUpsert`, e.message)
      }
      return false
    }
  }

  // upsert sdg
  const upsertSdg = async (input: {
    id?: string
    name: string
    image: string
    code: string
    translations: { name: string; image: string; lang: string }[]
  }) => {
    try {
      logger(Status.Api, "MUTATION sdgUpsert", sdgUpsert)
      const { data } = await sdgUpsertMutation({ variables: { input: input } })
      logger(Status.Info, `sdg upserted`, data.sdgUpsert)

      setCurrentSdg(data.sdgUpsert)
      setPreChangesCurrentSdg(deepCopy(data.sdgUpsert))

      return data.sdgUpsert
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `sdgUpsert`, e.message)
      }
      return false
    }
  }

  // upsert sdg target
  const upsertSdgTarget = async (input: {
    id?: string
    code: string
    translations: { description: string; lang: string }[]
  }) => {
    try {
      logger(Status.Api, "MUTATION sdgTargetUpsert", sdgTargetUpsert)
      const { data } = await sdgTargetUpsertMutation({
        variables: { input: input },
      })
      logger(Status.Info, `sdg target upserted`, data.sdgTargetUpsert)

      setCurrentSdgTarget(data.sdgTargetUpsert)
      setPreChangesCurrentSdgTarget(deepCopy(data.sdgTargetUpsert))

      return data.sdgTargetUpsert
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `sdgTargetUpsert`, e.message)
      }
      return false
    }
  }

  // upsert topic
  const upsertTopic = async (input: {
    id?: string
    name: string
    code: string
    mainTopic: string
    translations: { name: string; lang: string }[]
  }) => {
    try {
      logger(Status.Api, "MUTATION topicUpsert", topicUpsert)
      const { data } = await topicUpsertMutation({
        variables: { input: input },
      })
      logger(Status.Info, `topic upserted`, data.topicUpsert)

      setCurrentTopic(data.topicUpsert)
      setPreChangesCurrentTopic(deepCopy(data.topicUpsert))

      return data.topicUpsert
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `topicUpsert`, e.message)
      }
      return false
    }
  }

  // cancel changes to current category
  const cancelChangesCategory = () => {
    setCurrentCategory(deepCopy(preChangesCurrentCategory))
  }

  // cancel changes to current main category
  const cancelChangesMainCategory = () => {
    setCurrentMainCategory(deepCopy(preChangesCurrentMainCategory))
  }

  // cancel changes to current sdg
  const cancelChangesSdg = () => {
    setCurrentSdg(deepCopy(preChangesCurrentSdg))
  }

  // cancel changes to current sdg target
  const cancelChangesSdgTarget = () => {
    setCurrentSdgTarget(deepCopy(preChangesCurrentSdgTarget))
  }

  // cancel changes to current topic
  const cancelChangesTopic = () => {
    setCurrentTopic(deepCopy(preChangesCurrentTopic))
  }

  // get current category
  const getCurrentCategory = async (categoryId: string) => {
    try {
      logger(Status.Api, "QUERY categoryGet", categoryGet)
      const { data } = await categoryGetQuery({
        variables: { input: { id: categoryId } },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, `category ${categoryId}`, data.categoryGet)

      setCurrentCategory(data.categoryGet)
      setPreChangesCurrentCategory(deepCopy(data.categoryGet))

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `categoryGet`, e.message)
      }
      return false
    }
  }

  // get current main category
  const getCurrentMainCategory = async (mainCategoryId: string) => {
    try {
      logger(Status.Api, "QUERY mainCategoryGet", mainCategoryGet)
      const { data } = await mainCategoryGetQuery({
        variables: { input: { id: mainCategoryId } },
        fetchPolicy: "no-cache",
      })
      logger(
        Status.Info,
        `main category ${mainCategoryId}`,
        data.mainCategoryGet
      )

      setCurrentMainCategory(data.mainCategoryGet)
      setPreChangesCurrentMainCategory(deepCopy(data.mainCategoryGet))

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `mainCategoryGet`, e.message)
      }
      return false
    }
  }

  // get current sdg
  const getCurrentSdg = async (sdgId: string) => {
    try {
      logger(Status.Api, "QUERY sdgGet", sdgGet)
      const { data } = await sdgGetQuery({
        variables: { input: { id: sdgId } },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, `sdg ${sdgId}`, data.sdgGet)

      setCurrentSdg(data.sdgGet)
      setPreChangesCurrentSdg(deepCopy(data.sdgGet))

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `sdgGet`, e.message)
      }
      return false
    }
  }

  // get current sdg target
  const getCurrentSdgTarget = async (sdgTargetId: string) => {
    try {
      logger(Status.Api, "QUERY sdgTargetGet", sdgTargetGet)
      const { data } = await sdgTargetGetQuery({
        variables: { input: { id: sdgTargetId } },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, `sdg target ${sdgTargetId}`, data.sdgTargetGet)

      setCurrentSdgTarget(data.sdgTargetGet)
      setPreChangesCurrentSdgTarget(deepCopy(data.sdgTargetGet))

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `sdgTargetGet`, e.message)
      }
      return false
    }
  }

  // get current topic
  const getCurrentTopic = async (topicId: string) => {
    try {
      logger(Status.Api, "QUERY topicGet", topicGet)
      const { data } = await topicGetQuery({
        variables: { input: { id: topicId } },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, `topic ${topicId}`, data.topicGet)

      setCurrentTopic(data.topicGet)
      setPreChangesCurrentTopic(deepCopy(data.topicGet))

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `topicGet`, e.message)
      }
      return false
    }
  }

  // search categories
  const searchCategories = async () => {
    setUpdatingCategoriesList(true)
    setHasSearchedCategories(true)

    try {
      logger(Status.Api, "QUERY categoriesSearch", categoriesSearch)
      const { data } = await categoriesSearchQuery({
        variables: {
          input: {
            name: categoriesFilters.name,
            lang: categoriesFilters.lang ? categoriesFilters.lang.id : null,
            mainCategory: categoriesFilters.mainCategory
              ? categoriesFilters.mainCategory.id
              : null,
            limit: 1000,
          },
        },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, `categories list`, data.categoriesSearch.items)

      setCategoriesList(data.categoriesSearch.items)

      setUpdatingCategoriesList(false)
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `categoriesSearch`, e.message)
      }
      setUpdatingCategoriesList(false)
    }
  }

  useEffect(() => {
    if (
      Object.keys(categoriesFilters).filter((key) => categoriesFilters[key])
        .length
    ) {
      searchCategories()
    } else if (hasSearchedCategories) {
      getCategoriesList(false)
    }
  }, [categoriesFilters])

  // search main categories
  const searchMainCategories = async () => {
    setUpdatingMainCategoriesList(true)
    setHasSearchedMainCategories(true)

    try {
      logger(Status.Api, "QUERY mainCategoriesSearch", mainCategoriesSearch)
      const { data } = await mainCategoriesSearchQuery({
        variables: {
          input: {
            name: mainCategoriesFilters.name,
            lang: mainCategoriesFilters.lang
              ? mainCategoriesFilters.lang.id
              : null,
            limit: 1000,
          },
        },
        fetchPolicy: "no-cache",
      })
      logger(
        Status.Info,
        `main categories list`,
        data.mainCategoriesSearch.items
      )

      setMainCategoriesList(data.mainCategoriesSearch.items)

      setUpdatingMainCategoriesList(false)
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `mainCategoriesSearch`, e.message)
      }
      setUpdatingMainCategoriesList(false)
    }
  }

  useEffect(() => {
    if (
      Object.keys(mainCategoriesFilters).filter(
        (key) => mainCategoriesFilters[key]
      ).length
    ) {
      searchMainCategories()
    } else if (hasSearchedMainCategories) {
      getMainCategoriesList(false)
    }
  }, [mainCategoriesFilters])

  // search sdgs
  const searchSdgs = async () => {
    setUpdatingSdgsList(true)
    setHasSearchedSdgs(true)

    try {
      logger(Status.Api, "QUERY sdgsSearch", sdgsSearch)
      const { data } = await sdgsSearchQuery({
        variables: {
          input: {
            name: sdgsFilters.name,
            lang: sdgsFilters.lang ? sdgsFilters.lang.id : null,
            limit: 1000,
          },
        },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, `sdgs list`, data.sdgsSearch.items)

      setSdgsList(data.sdgsSearch.items)

      setUpdatingSdgsList(false)
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `sdgsSearch`, e.message)
      }
      setUpdatingSdgsList(false)
    }
  }

  useEffect(() => {
    if (Object.keys(sdgsFilters).filter((key) => sdgsFilters[key]).length) {
      searchSdgs()
    } else if (hasSearchedSdgs) {
      getSdgsList(false)
    }
  }, [sdgsFilters])

  // search sdg targets
  const searchSdgTargets = async () => {
    setUpdatingSdgTargetsList(true)
    setHasSearchedSdgTargets(true)

    try {
      logger(Status.Api, "QUERY sdgTargetsSearch", sdgTargetsSearch)
      const { data } = await sdgTargetsSearchQuery({
        variables: {
          input: {
            lang: sdgTargetsFilters.lang ? sdgTargetsFilters.lang.id : null,
            limit: 1000,
          },
        },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, `sdg targets list`, data.sdgTargetsSearch.items)

      setSdgTargetsList(data.sdgTargetsSearch.items)

      setUpdatingSdgTargetsList(false)
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `sdgTargetsSearch`, e.message)
      }
      setUpdatingSdgTargetsList(false)
    }
  }

  useEffect(() => {
    if (
      Object.keys(sdgTargetsFilters).filter((key) => sdgTargetsFilters[key])
        .length
    ) {
      searchSdgTargets()
    } else if (hasSearchedSdgTargets) {
      getSdgTargetsList(false)
    }
  }, [sdgTargetsFilters])

  // search topics
  const searchTopics = async () => {
    setUpdatingTopicsList(true)
    setHasSearchedTopics(true)

    try {
      logger(Status.Api, "QUERY topicsSearch", topicsSearch)
      const { data } = await topicsSearchQuery({
        variables: {
          input: {
            name: topicsFilters.name,
            lang: topicsFilters.lang ? topicsFilters.lang.id : null,
            mainTopic: topicsFilters.mainTopic
              ? topicsFilters.mainTopic.id
              : null,
            limit: 1000,
          },
        },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, `topics list`, data.topicsSearch.items)

      setTopicsList(data.topicsSearch.items)

      setUpdatingTopicsList(false)
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `topicsSearch`, e.message)
      }
      setUpdatingTopicsList(false)
    }
  }

  useEffect(() => {
    if (Object.keys(topicsFilters).filter((key) => topicsFilters[key]).length) {
      searchTopics()
    } else if (hasSearchedTopics) {
      getTopicsList(false)
    }
  }, [topicsFilters])

  // add translation to current category
  const addTranslationToCategory = (translationToAdd: string) => {
    currentCategory.translations.push({
      name: `${translationToAdd.toUpperCase()}`,
      lang: translationToAdd,
    })
    setCurrentCategory({ ...currentCategory })
  }

  // add translation to current main category
  const addTranslationToMainCategory = (translationToAdd: string) => {
    currentMainCategory.translations.push({
      name: `${translationToAdd.toUpperCase()}`,
      lang: translationToAdd,
    })
    setCurrentMainCategory({ ...currentMainCategory })
  }

  // add translation to current sdg
  const addTranslationToSdg = (translationToAdd: string) => {
    currentSdg.translations.push({
      name: `${translationToAdd.toUpperCase()}`,
      image: currentSdg.image,
      lang: translationToAdd,
    })
    setCurrentSdg({ ...currentSdg })
  }

  // add translation to current sdg target
  const addTranslationToSdgTarget = (translationToAdd: string) => {
    currentSdgTarget.translations.push({
      description: `${translationToAdd.toUpperCase()}`,
      lang: translationToAdd,
    })
    setCurrentSdgTarget({ ...currentSdgTarget })
  }

  // add translation to current topic
  const addTranslationToTopic = (translationToAdd: string) => {
    currentTopic.translations.push({
      name: `${translationToAdd.toUpperCase()}`,
      lang: translationToAdd,
    })
    setCurrentTopic({ ...currentTopic })
  }

  // check if changes has been made to current category
  useEffect(() => {
    if (
      JSON.stringify(currentCategory) ===
      JSON.stringify(preChangesCurrentCategory)
    ) {
      setDoneChangesCategory(false)
    } else {
      setDoneChangesCategory(true)
    }
  }, [currentCategory])

  // check if changes has been made to current main category
  useEffect(() => {
    if (
      JSON.stringify(currentMainCategory) ===
      JSON.stringify(preChangesCurrentMainCategory)
    ) {
      setDoneChangesMainCategory(false)
    } else {
      setDoneChangesMainCategory(true)
    }
  }, [currentMainCategory])

  // check if changes has been made to current sdg
  useEffect(() => {
    if (JSON.stringify(currentSdg) === JSON.stringify(preChangesCurrentSdg)) {
      setDoneChangesSdg(false)
    } else {
      setDoneChangesSdg(true)
    }
  }, [currentSdg])

  // check if changes has been made to current sdg target
  useEffect(() => {
    if (
      JSON.stringify(currentSdgTarget) ===
      JSON.stringify(preChangesCurrentSdgTarget)
    ) {
      setDoneChangesSdgTarget(false)
    } else {
      setDoneChangesSdgTarget(true)
    }
  }, [currentSdgTarget])

  // check if changes has been made to current topic
  useEffect(() => {
    if (
      JSON.stringify(currentTopic) === JSON.stringify(preChangesCurrentTopic)
    ) {
      setDoneChangesTopic(false)
    } else {
      setDoneChangesTopic(true)
    }
  }, [currentTopic])

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter((item) => item.name === "Categories")[0].updateFunction =
      getCategoriesList
    panelStatus.filter(
      (item) => item.name === "Main Categories"
    )[0].updateFunction = getMainCategoriesList
    panelStatus.filter((item) => item.name === "SDGs")[0].updateFunction =
      getSdgsList
    panelStatus.filter(
      (item) => item.name === "SDG targets"
    )[0].updateFunction = getSdgTargetsList
    panelStatus.filter((item) => item.name === "Topics")[0].updateFunction =
      getTopicsList
    panelStatus.filter(
      (item) => item.name === "Main Topics"
    )[0].updateFunction = getMainTopicsList
  }, [])

  // initial fetch
  useEffect(() => {
    getCategoriesList()
    getMainCategoriesList()
    getSdgsList()
    getSdgTargetsList()
    getTopicsList()
    getMainTopicsList()
  }, [])

  return (
    <TagsContext.Provider
      value={{
        loading,
        setLoading,
        categoriesLoading,
        categoriesList,
        getCategoriesList,
        mainCategoriesLoading,
        mainCategoriesList,
        getMainCategoriesList,
        topicsLoading,
        topicsList,
        getTopicsList,
        mainTopicsLoading,
        mainTopicsList,
        sdgsLoading,
        sdgsList,
        getSdgsList,
        sdgTargetsLoading,
        sdgTargetsList,
        getSdgTargetsList,
        currentTab,
        setCurrentTab,
        updatingCategoriesList,
        updatingMainCategoriesList,
        updatingSdgsList,
        updatingSdgTargetsList,
        updatingTopicsList,
        upsertCategory,
        upsertMainCategory,
        upsertSdg,
        upsertSdgTarget,
        upsertTopic,
        currentCategory,
        setCurrentCategory,
        preChangesCurrentCategory,
        currentMainCategory,
        setCurrentMainCategory,
        preChangesCurrentMainCategory,
        currentSdg,
        setCurrentSdg,
        preChangesCurrentSdg,
        currentSdgTarget,
        setCurrentSdgTarget,
        preChangesCurrentSdgTarget,
        currentTopic,
        setCurrentTopic,
        preChangesCurrentTopic,
        editMode,
        setEditMode,
        doneChangesCategory,
        doneChangesMainCategory,
        doneChangesSdg,
        doneChangesSdgTarget,
        doneChangesTopic,
        cancelChangesCategory,
        cancelChangesMainCategory,
        cancelChangesSdg,
        cancelChangesSdgTarget,
        cancelChangesTopic,
        getCurrentCategory,
        getCurrentMainCategory,
        getCurrentSdg,
        getCurrentSdgTarget,
        getCurrentTopic,
        addTranslationToCategory,
        addTranslationToMainCategory,
        addTranslationToSdg,
        addTranslationToSdgTarget,
        addTranslationToTopic,
        categoriesFilters,
        setCategoriesFilters,
        mainCategoriesFilters,
        setMainCategoriesFilters,
        sdgsFilters,
        setSdgsFilters,
        sdgTargetsFilters,
        setSdgTargetsFilters,
        topicsFilters,
        setTopicsFilters,
      }}
    >
      {children}
    </TagsContext.Provider>
  )
}

export { TagsController, TagsContext }
