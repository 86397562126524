import { useLazyQuery } from "@apollo/client"
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import Country from "../models/country"
import Region from "../models/region"
import {
  listCountries,
  listRegions,
  listSubRegions,
} from "../services/graphql/queries"
import { logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"

interface CountriesContextInterface {
  loading: boolean
  regionsList: Region[]
  subRegionsList: Region[]
  countriesList: Country[]
}

const CountriesContext = createContext<CountriesContextInterface>({
  loading: true,
  regionsList: [],
  subRegionsList: [],
  countriesList: [],
})

const CountriesController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // loadings
  const [regionsLoading, setRegionsLoading] = useState<boolean>(true) // loading for regions
  const [subRegionsLoading, setSubRegionsLoading] = useState<boolean>(true) // loading for subregions
  const [countriesLoading, setCountriesLoading] = useState<boolean>(true) // loading for countries
  const loading = countriesLoading || regionsLoading || subRegionsLoading // global loading

  // states
  const [regionsList, setRegionsList] = useState<Region[]>([]) // all regions list
  const [subRegionsList, setSubRegionsList] = useState<Region[]>([]) // all subregions list
  const [countriesList, setCountriesList] = useState<Country[]>([]) // all countries list

  // queries
  const [listRegionsQuery] = useLazyQuery(listRegions)
  const [listSubRegionsQuery] = useLazyQuery(listSubRegions)
  const [listCountriesQuery] = useLazyQuery(listCountries)

  // get regions list
  const getRegionsList = useCallback(async () => {
    panelStatus.filter((item) => item.name === "Regions")[0].loading = true
    setPanelStatus([...panelStatus])

    try {
      logger(Status.Api, "QUERY regionsList", listRegions)
      const { data } = await listRegionsQuery({
        variables: { input: { limit: 1000 } },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "regions list", data.regionsList.items)

      setRegionsList(data.regionsList.items)

      setRegionsLoading(false)
      panelStatus.filter((item) => item.name === "Regions")[0].status =
        "success"
      panelStatus.filter((item) => item.name === "Regions")[0].loading = false
      setPanelStatus([...panelStatus])
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `regionsList`, e.message)
      }
      setRegionsLoading(false)
      panelStatus.filter((item) => item.name === "Regions")[0].status = "error"
      panelStatus.filter((item) => item.name === "Regions")[0].loading = false
      setPanelStatus([...panelStatus])
    }
  }, [setError, setErrorMessage])

  // get subregions list
  const getSubRegionsList = useCallback(async () => {
    panelStatus.filter((item) => item.name === "SubRegions")[0].loading = true
    setPanelStatus([...panelStatus])

    try {
      logger(Status.Api, "QUERY subregionsList", listSubRegions)
      const { data } = await listSubRegionsQuery({
        variables: { input: { limit: 1000 } },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "subregions list", data.subregionsList.items)

      setSubRegionsList(data.subregionsList.items)

      setSubRegionsLoading(false)
      panelStatus.filter((item) => item.name === "SubRegions")[0].status =
        "success"
      panelStatus.filter((item) => item.name === "SubRegions")[0].loading =
        false
      setPanelStatus([...panelStatus])
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `subregionsList`, e.message)
      }
      setSubRegionsLoading(false)
      panelStatus.filter((item) => item.name === "SubRegions")[0].status =
        "error"
      panelStatus.filter((item) => item.name === "SubRegions")[0].loading =
        false
      setPanelStatus([...panelStatus])
    }
  }, [setError, setErrorMessage])

  // get countries list
  const getCountriesList = useCallback(async () => {
    panelStatus.filter((item) => item.name === "Countries")[0].loading = true
    setPanelStatus([...panelStatus])

    try {
      logger(Status.Api, "QUERY countriesList", listCountries)
      const { data } = await listCountriesQuery({
        variables: { input: { limit: 1000 } },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "countries list", data.countriesList.items)

      setCountriesList(data.countriesList.items)

      setCountriesLoading(false)
      panelStatus.filter((item) => item.name === "Countries")[0].status =
        "success"
      panelStatus.filter((item) => item.name === "Countries")[0].loading = false
      setPanelStatus([...panelStatus])
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `countriesList`, e.message)
      }
      setCountriesLoading(false)
      panelStatus.filter((item) => item.name === "Countries")[0].status =
        "error"
      panelStatus.filter((item) => item.name === "Countries")[0].loading = false
      setPanelStatus([...panelStatus])
    }
  }, [setError, setErrorMessage])

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter((item) => item.name === "Regions")[0].updateFunction =
      getRegionsList
    panelStatus.filter((item) => item.name === "SubRegions")[0].updateFunction =
      getSubRegionsList
    panelStatus.filter((item) => item.name === "Countries")[0].updateFunction =
      getCountriesList
  }, [])

  // initial fetch
  useEffect(() => {
    getRegionsList()
    getSubRegionsList()
    getCountriesList()
  }, [])

  return (
    <CountriesContext.Provider
      value={{
        loading,
        regionsList,
        subRegionsList,
        countriesList,
      }}
    >
      {children}
    </CountriesContext.Provider>
  )
}

export { CountriesController, CountriesContext }
