import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import Navigation from "./Navigation"
import { MainContext, MainController } from "./controllers/main"
import { BrowserRouter } from "react-router-dom"
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material"
import { ErrorBoundary } from "react-error-boundary"
import { useEffect, useState } from "react"
import {
  DialogTransition,
  copyTextToClipboard,
} from "./services/utilities/utility"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import awLogo from "./assets/images/aw-logo.png"
import { gt } from "semver"
import ThemeProvider from "./theme"

// in this page:
// BrowserRouter -> to make react router work
// MainController and MainContext -> wrap all app with main controller
// Navigation -> checks if user is signed in or not and return appropriate view
// Snackbar -> the error snackbar toggled by setError, included in main controller

const App = () => {
  // copy error to clipboard
  const [copied, setCopied] = useState<boolean>(false)

  // change page title if current env is dev
  useEffect(() => {
    if (process.env.REACT_APP_ENV === "dev") {
      document.title = "Solar Client DEV"
    }
  }, [])

  return (
    <ErrorBoundary
      FallbackComponent={(value) => (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack spacing={3} alignItems="center">
            <Typography variant="h4">Ooops! A fatal error occurred</Typography>
            <Typography
              variant="body2"
              style={{
                marginTop: 8,
                marginBottom: -4,
                width: 500,
                textAlign: "center",
              }}
            >
              Error details
            </Typography>
            <div
              style={{
                backgroundColor: "#f5f5f5",
                padding: 16,
                maxHeight: 350,
                overflowY: "scroll",
                fontSize: 14,
                borderRadius: 4,
                border: "1.5px solid #cfcfcf",
                position: "relative",
              }}
            >
              <div
                id="error-stack"
                dangerouslySetInnerHTML={{
                  __html: `<code>${value.error.stack.replaceAll(
                    " at ",
                    "<br /> at "
                  )}</code>`,
                }}
              />
              <Button
                style={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  backgroundColor: copied ? null : "#4859ad",
                  boxShadow: "none",
                  color: !copied ? null : "#4859ad",
                }}
                size="small"
                variant={copied ? "outlined" : "contained"}
                onClick={() => {
                  copyTextToClipboard(value.error.stack)
                  setCopied(true)
                }}
              >
                {copied ? "Copied!" : "Copy"}
              </Button>
            </div>
          </Stack>
        </div>
      )}
    >
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <BrowserRouter>
          <MainController>
            <MainContext.Consumer>
              {(context) => (
                <ThemeProvider>
                  {context.manager.maintenance ? (
                    <div
                      style={{
                        width: "100%",
                        height: window.innerHeight,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: 15,
                      }}
                    >
                      <img
                        src={awLogo}
                        style={{ height: 50 }}
                        className="animated-logo"
                      />
                      <Typography variant="h5">We'll be back!</Typography>
                      <Typography
                        variant="body2"
                        sx={{ width: 250, textAlign: "center" }}
                      >
                        The client is down for mainteinance, please check back
                        soon.
                      </Typography>
                    </div>
                  ) : (
                    <div style={{ position: "relative" }}>
                      <Navigation />
                      <Snackbar open={context.error}>
                        <Alert severity="error">
                          {context.errorMessage ??
                            "Something went wrong, reload the page or try again"}
                        </Alert>
                      </Snackbar>
                      <Snackbar open={context.changesSaved}>
                        <Alert severity="success">Changes saved</Alert>
                      </Snackbar>
                      <Snackbar
                        open={context.clipboardFeedbackOpen}
                        autoHideDuration={2000}
                        onClose={() => {
                          context.setClipboardFeedbackOpen(false)
                        }}
                      >
                        <Alert severity="success">Copied to clipboard!</Alert>
                      </Snackbar>
                      <Dialog
                        open={
                          gt(
                            context.manager.version,
                            process.env.REACT_APP_VERSION
                          ) &&
                          !context.ignoreManager &&
                          context.manager.phase === "deployed"
                        }
                        TransitionComponent={DialogTransition}
                      >
                        <DialogTitle>New update is available</DialogTitle>
                        <DialogContent>
                          <Typography>
                            A new version of the Solar Client is available!
                          </Typography>
                          <br />
                          <Typography>
                            Current version: {process.env.REACT_APP_VERSION}
                          </Typography>
                          <Typography>
                            New version:{" "}
                            <span style={{ fontWeight: 600 }}>
                              {context.manager.version}
                            </span>
                          </Typography>
                          {context.manager.changelog ? (
                            <Stack>
                              <br />
                              <Typography style={{ fontWeight: 600 }}>
                                What's new?
                              </Typography>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: context.manager.changelog,
                                }}
                              />
                            </Stack>
                          ) : null}
                          {context.manager.forceUpdate && (
                            <>
                              <br />
                              <Typography>Please update to continue</Typography>
                            </>
                          )}
                        </DialogContent>
                        <DialogActions>
                          {!context.manager.forceUpdate && (
                            <Button
                              onClick={() => {
                                context.setIgnoreManager(true)
                              }}
                            >
                              Dismiss
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            onClick={() => {
                              window.location.href = window.location.href
                            }}
                          >
                            Update
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  )}
                </ThemeProvider>
              )}
            </MainContext.Consumer>
          </MainController>
        </BrowserRouter>
      </LocalizationProvider>
    </ErrorBoundary>
  )
}

export default App
