import { useEffect, useState } from "react"
import EmbedSlice from "../../../models/bodySlices/embedSlice"
import ImageSlice from "../../../models/bodySlices/imageSlice"
import QuoteSlice from "../../../models/bodySlices/quoteSlice"
import TextSlice from "../../../models/bodySlices/textSlice"
import SliceTitle from "../common/sliceTitle"
import SliceWrapper from "../common/sliceWrapper"
import { Stack, TextField } from "@mui/material"
import { isValidYtUrl } from "../utilities/isValidYtUrl"
import { DraggableProvided } from "react-beautiful-dnd"

const EmbedSliceComponent = ({
  item,
  body,
  setBody,
  editMode,
  sliceIndex,
  removeSlice,
  provided,
}: {
  item: EmbedSlice
  body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
  setBody: (body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]) => void
  editMode: boolean
  sliceIndex: number
  removeSlice: (sliceToRemoveIndex: number) => void
  provided: DraggableProvided
}) => {
  // states
  const [embedUrl, setEmbedUrl] = useState<string>(item.embedUrl)
  const [embedText, setEmbedText] = useState<string | null>(item.embedText)
  const [embedCaption, setEmbedCaption] = useState<string | null>(
    item.embedCaption
  )
  const [embedCaptionTitle, setEmbedCaptionTitle] = useState<string | null>(
    item.embedCaptionTitle
  )

  // update states state when body changes
  useEffect(() => {
    if (item.embedUrl !== embedUrl) {
      setEmbedUrl(item.embedUrl)
    }
    if (item.embedText !== embedText) {
      setEmbedText(item.embedText)
    }
    if (item.embedCaption !== embedCaption) {
      setEmbedCaption(item.embedCaption)
    }
    if (item.embedCaptionTitle !== embedCaptionTitle) {
      setEmbedCaptionTitle(item.embedCaptionTitle)
    }
  }, [item])

  // update body when states change
  useEffect(() => {
    if (
      item.embedUrl !== embedUrl ||
      item.embedText !== embedText ||
      item.embedCaption !== embedCaption ||
      item.embedCaptionTitle !== embedCaptionTitle
    ) {
      item.embedUrl = embedUrl
      item.embedText = embedText
      item.embedCaption = embedCaption
      item.embedCaptionTitle = embedCaptionTitle
      setBody(body)
    }
  }, [embedUrl, embedText, embedCaption, embedCaptionTitle])

  // errors
  const [embedUrlError, setEmbedUrlError] = useState<boolean>(false)

  useEffect(() => {
    if (!embedUrl.length || !isValidYtUrl(embedUrl)) {
      setEmbedUrlError(true)
    } else {
      setEmbedUrlError(false)
    }
  }, [embedUrl])

  return (
    <SliceWrapper
      editMode={editMode}
      sliceIndex={sliceIndex}
      removeSlice={removeSlice}
      provided={provided}
    >
      <SliceTitle>Embed</SliceTitle>
      <TextField
        size="small"
        label="URL"
        value={embedUrl}
        onChange={(e) => {
          setEmbedUrl(e.target.value)
        }}
        disabled={!editMode}
        error={embedUrlError}
        helperText={
          !embedUrl.length
            ? "This field is required"
            : !isValidYtUrl(embedUrl)
            ? "Invalid URL"
            : ""
        }
      />
      <Stack direction="row" spacing={1}>
        <TextField
          fullWidth
          size="small"
          label="Text"
          value={embedText ?? ""}
          onChange={(e) => {
            if (e.target.value.length) {
              setEmbedText(e.target.value)
            } else {
              setEmbedText(null)
            }
          }}
          disabled={!editMode}
        />
        <TextField
          fullWidth
          size="small"
          label="Caption title"
          value={embedCaptionTitle ?? ""}
          onChange={(e) => {
            if (e.target.value.length) {
              setEmbedCaptionTitle(e.target.value)
            } else {
              setEmbedCaptionTitle(null)
            }
          }}
          disabled={!editMode}
        />
        <TextField
          fullWidth
          size="small"
          label="Caption"
          value={embedCaption ?? ""}
          onChange={(e) => {
            if (e.target.value.length) {
              setEmbedCaption(e.target.value)
            } else {
              setEmbedCaption(null)
            }
          }}
          disabled={!editMode}
        />
      </Stack>
    </SliceWrapper>
  )
}

export default EmbedSliceComponent
