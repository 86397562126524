import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material"
import { useContext, useEffect, useState } from "react"
import ListFilters from "../global/listFilters"
import { TeamsContext } from "../../controllers/teams"
import { useParams } from "react-router-dom"
import { Cancel, Search } from "@mui/icons-material"

const MembersListTopBar = ({ loading }: { loading: boolean }) => {
  const {
    hasSearchedMembers,
    setHasSearchedMembers,
    searchGroup,
    setSearchGroup,
    searchIsAdmin,
    setSearchIsAdmin,
    searchText,
    setSearchText,
    searchTextType,
    setSearchTextType,
    updatingMembersList,
    setUpdatingMembersList,
    getTeamUsers,
    currentTeam,
  } = useContext(TeamsContext)
  const { teamId } = useParams()

  // search when filters update
  const [firstRender, setFirstRender] = useState<boolean>(true)

  const getList = async (text: string) => {
    setUpdatingMembersList(true)
    await getTeamUsers(teamId, searchGroup, searchIsAdmin, text)
    setUpdatingMembersList(false)
  }

  useEffect(() => {
    if (!firstRender) {
      getList(searchText)
    } else {
      setFirstRender(false)
    }
  }, [searchGroup, searchIsAdmin])

  return (
    <Stack
      style={{
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 4,
      }}
      spacing={1}
    >
      <Stack direction="row" gap={1}>
        <FormControl
          size="small"
          disabled={loading || updatingMembersList}
          style={{ width: 120 }}
        >
          <InputLabel id="text-type">Search by</InputLabel>
          <Select
            labelId="text-type"
            label="Search by"
            value={searchTextType}
            onChange={(e) => {
              setSearchTextType(e.target.value as "email" | "sub")

              setSearchText("")
              if (hasSearchedMembers) {
                setHasSearchedMembers(false)
                getList("")
              }
            }}
          >
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="sub">SUB</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          size="small"
          disabled={loading || updatingMembersList}
          label={searchTextType === "email" ? "Email" : "SUB"}
          placeholder="Search User"
          value={searchText}
          onChange={(e) => {
            if (e.target.value) {
              setSearchText(e.target.value)
            } else if (hasSearchedMembers) {
              setHasSearchedMembers(false)
              setSearchText("")
              getList("")
            } else {
              setSearchText("")
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && searchText) {
              setHasSearchedMembers(true)
              getList(searchText)
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: searchText.length > 0 && (
              <InputAdornment position="start">
                <div
                  style={{
                    marginTop: 6,
                    marginRight: -6,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!loading) {
                      setHasSearchedMembers(false)
                      setSearchText("")
                      getList("")
                    }
                  }}
                >
                  <Cancel style={{ width: 18 }} />
                </div>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="outlined"
          disabled={!searchText || loading || updatingMembersList}
          onClick={() => {
            setHasSearchedMembers(true)
            getList(searchText)
          }}
        >
          Search
        </Button>
      </Stack>
      <ListFilters
        disabled={loading || updatingMembersList}
        filters={[
          {
            type: "singular",
            label: "Group",
            value: searchGroup,
            setValue: setSearchGroup,
            options: currentTeam.groups.map((item) => {
              return {
                id: item.groupId,
                label: item.name,
              }
            }),
          },
          {
            type: "boolean",
            label: "Admin",
            value: searchIsAdmin,
            setValue: setSearchIsAdmin,
          },
        ]}
      />
    </Stack>
  )
}

export default MembersListTopBar
