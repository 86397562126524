import { useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { upsertBadge, upsertDocumentBadge } from "../services/graphql/mutations"
import { badge, listBadges, searchBadges } from "../services/graphql/queries"
import { deepCopy, logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"
import { lowercaseFirstCharacter } from "../services/utilities/utility"
import Badge from "../models/badge"
import { BadgeType } from "../services/config/enum"
import Document from "../models/document"

interface AutocompleteOption {
  label: string
  id: string
}

interface BadgesContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  badgesList: Badge[]
  setBadgesList: Dispatch<SetStateAction<Badge[]>>
  getBadgesList: (withLoading?: boolean) => void
  doneChanges: boolean
  cancelChanges: () => void
  currentBadge: Badge
  setCurrentBadge: Dispatch<SetStateAction<Badge>>
  getCurrentBadge: (badgeId: string) => Promise<boolean>
  preChangesCurrentBadge: Badge
  addTranslation: (translationToAdd: string) => void
  upsertBadgeParent: () => Promise<boolean>
  hasError: boolean
  translationsErrors: { lang: string; hasErrors: boolean }[]
  setTranslationsErrors: Dispatch<
    SetStateAction<{ lang: string; hasErrors: boolean }[]>
  >
  upsertBadgeDocument: () => Promise<boolean>
  copyDetailsFromDefault: (itemToCopyToIndex: number) => void
  copyBodyFromDefault: (itemToCopyToIndex: number) => void
  createBadge: (input: {
    badgeType: BadgeType
    image: string
    category?: { id: string }
  }) => Promise<string | boolean>
  createBadgeDocument: (input: {
    parentId: string
    type: string
    badgeDocumentItems: {
      body: object[]
      default: boolean
      lang: string
      title: string
    }[]
  }) => Promise<boolean>
  badgesListNextToken: string | null
  loadMoreBadges: () => Promise<boolean>
  hasSearched: boolean
  setHasSearched: Dispatch<SetStateAction<boolean | null>>
  searchLang: AutocompleteOption | null
  setSearchLang: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchCategory: AutocompleteOption | null
  setSearchCategory: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchTitle: string
  setSearchTitle: Dispatch<SetStateAction<string>>
  searchBadgesList: (
    withLoading?: boolean,
    withNextToken?: boolean
  ) => Promise<boolean>
  resetFilters: () => void
}

const BadgesContext = createContext<BadgesContextInterface>({
  loading: true,
  setLoading: () => {},
  updatingList: false,
  setUpdatingList: () => {},
  editMode: false,
  setEditMode: () => {},
  badgesList: [],
  setBadgesList: () => {},
  getBadgesList: () => {},
  doneChanges: false,
  cancelChanges: () => {},
  currentBadge: new Badge(),
  setCurrentBadge: () => {},
  getCurrentBadge: async () => true,
  preChangesCurrentBadge: new Badge(),
  addTranslation: () => {},
  upsertBadgeParent: async () => true,
  hasError: false,
  translationsErrors: [],
  setTranslationsErrors: () => {},
  upsertBadgeDocument: async () => true,
  copyDetailsFromDefault: () => {},
  copyBodyFromDefault: () => {},
  createBadge: async () => true,
  createBadgeDocument: async () => true,
  badgesListNextToken: null,
  loadMoreBadges: async () => true,
  hasSearched: false,
  setHasSearched: () => {},
  searchLang: null,
  setSearchLang: () => {},
  searchCategory: null,
  setSearchCategory: () => {},
  searchTitle: "",
  setSearchTitle: () => {},
  searchBadgesList: async () => true,
  resetFilters: () => {},
})

const BadgesController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // states
  const [loading, setLoading] = useState<boolean>(true) // loading
  const [updatingList, setUpdatingList] = useState<boolean>(false) // to show loader
  const [editMode, setEditMode] = useState<boolean>(true) // if all page should be disabled or not
  const [badgesList, setBadgesList] = useState<Badge[]>([]) // list of all badges
  const [doneChanges, setDoneChanges] = useState<boolean>(false) // if user has done changes
  const [currentBadge, setCurrentBadge] = useState<Badge>(new Badge()) // current badge
  const [preChangesCurrentBadge, setPreChangesCurrentBadge] = useState<Badge>(
    new Badge()
  ) // fetched current badge
  const [badgesListNextToken, setBadgesListNextToken] = useState<string | null>(
    null
  ) // next token for badges list
  const [hasSearched, setHasSearched] = useState<boolean>(false) // if user has searched or not

  // search states
  const [searchCategory, setSearchCategory] =
    useState<AutocompleteOption | null>(null)
  const [searchLang, setSearchLang] = useState<AutocompleteOption | null>(null)
  const [searchTitle, setSearchTitle] = useState<string>("")

  // errors for badge edit
  const [translationsErrors, setTranslationsErrors] = useState<
    { lang: string; hasErrors: boolean }[]
  >([]) // errors array for translations
  const hasError: boolean =
    translationsErrors.filter((item) => item.hasErrors).length !== 0 // if there are errors or not

  // reset all errors
  const resetErrors = () => {
    const newTranslationsErrors: { lang: string; hasErrors: boolean }[] = []
    currentBadge.document.items.forEach((item) => {
      newTranslationsErrors.push({ lang: item.lang, hasErrors: false })
    })
    setTranslationsErrors(newTranslationsErrors)
  }

  // queries
  const [badgesListQuery] = useLazyQuery(listBadges)
  const [badgeQuery] = useLazyQuery(badge)
  const [searchBadgesQuery] = useLazyQuery(searchBadges)

  // mutations
  const [upsertBadgeMutation] = useMutation(upsertBadge)
  const [upsertDocumentMutation] = useMutation(upsertDocumentBadge)

  // get badges list
  const getBadgesList = useCallback(
    async (withLoading: boolean = true) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter((item) => item.name === "Badges")[0].loading = true
        setPanelStatus([...panelStatus])
      }

      try {
        logger(Status.Api, "QUERY badgesList", listBadges)
        const { data } = await badgesListQuery({
          variables: {
            input: {
              badgeType: BadgeType.ActionGroup,
              limit: Math.round(window.innerHeight / 74) + 10,
            },
          },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "badges list", data.badgesList.items)

        setBadgesList(data.badgesList.items)
        setBadgesListNextToken(data.badgesList.nextToken)

        setHasSearched(false)
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Badges")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "Badges")[0].loading = false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `badgesList`, e.message)
        }
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Badges")[0].status = "error"
        panelStatus.filter((item) => item.name === "Badges")[0].loading = false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // load more badges
  const loadMoreBadges = async () => {
    try {
      logger(Status.Api, "QUERY badgesList", listBadges)
      const { data } = await badgesListQuery({
        variables: {
          input: {
            badgeType: BadgeType.ActionGroup,
            nextToken: badgesListNextToken,
          },
        },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "badges list", [
        ...badgesList,
        ...data.badgesList.items,
      ])

      setBadgesList([...badgesList, ...data.badgesList.items])
      setBadgesListNextToken(data.badgesList.nextToken)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `badgesList`, e.message)
      }
      return false
    }
  }

  // search badges
  const searchBadgesList = async (
    withLoading = true,
    withNextToken = false
  ) => {
    if (withLoading) {
      setUpdatingList(true)
    }

    try {
      const input: {
        limit: number
        text?: string
        lang?: string
        categoryId?: string
        nextToken?: string
      } = {
        limit: Math.round(window.innerHeight / 74) + 10,
        text: searchTitle,
        lang: searchLang ? searchLang.id : null,
        categoryId: searchCategory ? searchCategory.id : null,
      }

      if (hasSearched && withNextToken) {
        input.nextToken = badgesListNextToken
      }

      logger(Status.Api, "QUERY badgesSearch", searchBadges)
      const { data } = await searchBadgesQuery({
        variables: {
          input: input,
        },
        fetchPolicy: "no-cache",
      })

      if (input.nextToken) {
        logger(Status.Info, "badges list", [
          ...badgesList,
          ...data.badgesSearch.items,
        ])

        setBadgesList([...badgesList, ...data.badgesSearch.items])
        setBadgesListNextToken(data.badgesSearch.nextToken)
      } else {
        logger(Status.Info, "badges list", data.badgesSearch.items)

        setBadgesList(data.badgesSearch.items)
        setBadgesListNextToken(data.badgesSearch.nextToken)
      }

      setHasSearched(true)
      setUpdatingList(false)
      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `badgesSearch`, e.message)
      }
      setUpdatingList(false)
      return false
    }
  }

  // get current badge
  const getCurrentBadge = useCallback(
    async (badgeId: string) => {
      try {
        logger(Status.Api, "QUERY badgeGet", badge)
        const { data } = await badgeQuery({
          variables: { input: { id: badgeId } },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, `badge ${badgeId}`, data.badgeGet)

        setCurrentBadge(data.badgeGet)
        setPreChangesCurrentBadge(deepCopy(data.badgeGet))
        data.badgeGet.document.items.forEach((item: Document) => {
          translationsErrors.push({ lang: item.lang, hasErrors: false })
        })
        setTranslationsErrors([...translationsErrors])

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `badgeGet`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // upsert badge parent
  const upsertBadgeParent = async () => {
    try {
      const input = {
        id: currentBadge.id,
        image: currentBadge.image,
        badgeType: currentBadge.badgeType,
        category: currentBadge.category
          ? {
              id: currentBadge.category.id,
            }
          : null,
      }

      logger(Status.Api, "MUTATION badgeUpsert", upsertBadge)
      const { data } = await upsertBadgeMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `badge ${data.badgeUpsert.id} upserted`,
        data.badgeUpsert
      )

      const currentBadgeCopy: Badge = deepCopy(currentBadge)
      currentBadgeCopy.id = data.badgeUpsert.id
      currentBadgeCopy.updatedAt = data.badgeUpsert.updatedAt
      currentBadgeCopy.document.parentId = data.badgeUpsert.id
      currentBadgeCopy.image = data.badgeUpsert.image
      currentBadgeCopy.badgeType = data.badgeUpsert.badgeType
      setCurrentBadge(currentBadgeCopy)
      setPreChangesCurrentBadge(deepCopy(currentBadgeCopy))

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `badgeUpsert`, e.message)
      }
      return false
    }
  }

  // upsert badge document
  const upsertBadgeDocument = async () => {
    try {
      // parse data for inpout
      const currentBadgeCopy = deepCopy(currentBadge)
      currentBadgeCopy.document.items.forEach((item: any) => {
        const newBody = []
        if (item.body) {
          item.body.forEach((bodyItem: any) => {
            const { sliceType, __typename, ...rest } = bodyItem
            newBody.push({
              [lowercaseFirstCharacter(bodyItem.sliceType)]: {
                ...rest,
              },
            })
          })
        }
        item.body = newBody

        item.default = item.isDefault
        delete item.isDefault
        delete item.type
        delete item.updatedAt
        delete item.parentId
        delete item.__typename
      })

      const input = {
        badgeDocumentItems: currentBadgeCopy.document.items,
        parentId: currentBadgeCopy.document.parentId,
        type: "Badge",
      }

      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentBadge)
      const { data } = await upsertDocumentMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `badge ${data.documentUpsert.parentId} document upserted`,
        data.documentUpsert
      )

      const currentBadgeSecondCopy: Badge = deepCopy(currentBadge)
      currentBadgeSecondCopy.document = data.documentUpsert
      setCurrentBadge(currentBadgeSecondCopy)
      setPreChangesCurrentBadge(deepCopy(currentBadgeSecondCopy))

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // create badge
  const createBadge = async (input: {
    badgeType: BadgeType
    image: string
    category?: { id: string }
  }) => {
    try {
      logger(Status.Api, "MUTATION badgeUpsert", upsertBadge)
      const { data } = await upsertBadgeMutation({
        variables: { input: input },
      })
      logger(Status.Info, `badge created`, data.badgeUpsert)

      return data.badgeUpsert.id
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `badgeUpsert`, e.message)
      }
      return false
    }
  }

  // create badge document
  const createBadgeDocument = async (input: {
    parentId: string
    type: string
    badgeDocumentItems: {
      body: object[]
      default: boolean
      lang: string
      title: string
    }[]
  }) => {
    try {
      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentBadge)
      const { data } = await upsertDocumentMutation({
        variables: { input: input },
      })
      logger(Status.Info, `document created`, data.documentUpsert)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // add translation
  const addTranslation = (translationToAdd: string) => {
    const documentToAdd: Document = {
      body: [],
      isDefault: false,
      lang: translationToAdd,
      title: "Title",
      type: "Badge",
    }

    translationsErrors.push({
      lang: translationToAdd,
      hasErrors: false,
    })
    setTranslationsErrors([...translationsErrors])

    currentBadge.document.items.push(documentToAdd)
    setCurrentBadge({ ...currentBadge })
  }

  // copy details from default translation
  const copyDetailsFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentBadge.document.items.filter(
      (item) => item.isDefault
    )[0]

    currentBadge.document.items[itemToCopyToIndex].title = defaultItem.title

    setCurrentBadge({ ...currentBadge })
  }

  // copy body from default translation
  const copyBodyFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentBadge.document.items.filter(
      (item) => item.isDefault
    )[0]

    currentBadge.document.items[itemToCopyToIndex].body = defaultItem.body

    setCurrentBadge({ ...currentBadge })
  }

  // cancel changes
  const cancelChanges = () => {
    setCurrentBadge(deepCopy(preChangesCurrentBadge))
    resetErrors()
  }

  // check if user has done changes
  useEffect(() => {
    if (
      JSON.stringify(currentBadge) !== JSON.stringify(preChangesCurrentBadge)
    ) {
      setDoneChanges(true)
    } else {
      setDoneChanges(false)
    }
  }, [currentBadge])

  // reset list filters
  const resetFilters = () => {
    setSearchCategory(null)
    setSearchLang(null)
    setSearchTitle("")
  }

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter((item) => item.name === "Badges")[0].updateFunction =
      getBadgesList
  }, [])

  return (
    <BadgesContext.Provider
      value={{
        loading,
        setLoading,
        updatingList,
        setUpdatingList,
        editMode,
        setEditMode,
        badgesList,
        setBadgesList,
        getBadgesList,
        doneChanges,
        cancelChanges,
        currentBadge,
        setCurrentBadge,
        getCurrentBadge,
        preChangesCurrentBadge,
        addTranslation,
        upsertBadgeParent,
        hasError,
        translationsErrors,
        setTranslationsErrors,
        upsertBadgeDocument,
        copyDetailsFromDefault,
        copyBodyFromDefault,
        createBadge,
        createBadgeDocument,
        badgesListNextToken,
        loadMoreBadges,
        hasSearched,
        setHasSearched,
        searchLang,
        setSearchLang,
        searchCategory,
        setSearchCategory,
        searchTitle,
        setSearchTitle,
        searchBadgesList,
        resetFilters,
      }}
    >
      {children}
    </BadgesContext.Provider>
  )
}

export { BadgesController, BadgesContext }
