import { useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { nftCatalogGet, nftCatalogsListGql } from "../services/graphql/queries"
import { deepCopy, logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"
import NftCatalog from "../models/nftCatalog"
import {
  nftCatalogCreate,
  nftCatalogUpdate,
} from "../services/graphql/mutations"
import NftAttribute from "../models/nftAttribute"

interface NftsContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  nftCatalogsList: NftCatalog[]
  setNftCatalogsList: Dispatch<SetStateAction<NftCatalog[]>>
  getNftCatalogsList: (withLoading?: boolean) => void
  doneChanges: boolean
  cancelChanges: () => void
  currentNftCatalog: NftCatalog
  setCurrentNftCatalog: Dispatch<SetStateAction<NftCatalog>>
  getCurrentNftCatalog: (catalogId: string) => Promise<boolean>
  preChangesCurrentNftCatalog: NftCatalog
  updateNftCatalog: () => Promise<boolean>
  hasError: boolean
  createNftCatalog: (input: {
    name: string
    description: string
    mintAt: string
    symbol: string
    image: string
    kind: string
    projectId: string
    sourceId: string
    sourceKind: string
    attributes: NftAttribute[]
  }) => Promise<string | boolean>
  nftCatalogsListNextToken: string | null
  loadMoreNftCatalogs: () => Promise<boolean>
  nameError: boolean
  setNameError: Dispatch<SetStateAction<boolean>>
  descriptionError: boolean
  setDescriptionError: Dispatch<SetStateAction<boolean>>
  mintAtError: boolean
  setMintAtError: Dispatch<SetStateAction<boolean>>
  symbolError: boolean
  setSymbolError: Dispatch<SetStateAction<boolean>>
  attributesError: boolean
  setAttributesError: Dispatch<SetStateAction<boolean>>
  contractsError: boolean
  setContractsError: Dispatch<SetStateAction<boolean>>
}

const NftsContext = createContext<NftsContextInterface>({
  loading: true,
  setLoading: () => {},
  updatingList: false,
  setUpdatingList: () => {},
  editMode: false,
  setEditMode: () => {},
  nftCatalogsList: [],
  setNftCatalogsList: () => {},
  getNftCatalogsList: () => {},
  doneChanges: false,
  cancelChanges: () => {},
  currentNftCatalog: null,
  setCurrentNftCatalog: () => {},
  getCurrentNftCatalog: async () => true,
  preChangesCurrentNftCatalog: null,
  updateNftCatalog: async () => true,
  hasError: false,
  createNftCatalog: async () => true,
  nftCatalogsListNextToken: null,
  loadMoreNftCatalogs: async () => true,
  nameError: false,
  setNameError: () => {},
  descriptionError: false,
  setDescriptionError: () => {},
  mintAtError: false,
  setMintAtError: () => {},
  symbolError: false,
  setSymbolError: () => {},
  attributesError: false,
  setAttributesError: () => {},
  contractsError: false,
  setContractsError: () => {},
})

const NftsController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // states
  const [loading, setLoading] = useState<boolean>(true) // loading
  const [updatingList, setUpdatingList] = useState<boolean>(false) // to show loader
  const [editMode, setEditMode] = useState<boolean>(true) // if all page should be disabled or not
  const [nftCatalogsList, setNftCatalogsList] = useState<NftCatalog[]>([]) // list of all nft catalogs
  const [doneChanges, setDoneChanges] = useState<boolean>(false) // if user has done changes
  const [currentNftCatalog, setCurrentNftCatalog] = useState<NftCatalog>() // current nft catalog
  const [preChangesCurrentNftCatalog, setPreChangesCurrentNftCatalog] =
    useState<NftCatalog>() // fetched current nft catalog
  const [nftCatalogsListNextToken, setNftCatalogsListNextToken] = useState<
    string | null
  >(null) // next token for nft catalogs list

  // errors for product edit
  const [nameError, setNameError] = useState<boolean>(false)
  const [descriptionError, setDescriptionError] = useState<boolean>(false)
  const [mintAtError, setMintAtError] = useState<boolean>(false)
  const [symbolError, setSymbolError] = useState<boolean>(false)
  const [attributesError, setAttributesError] = useState<boolean>(false)
  const [contractsError, setContractsError] = useState<boolean>(false)
  const hasError: boolean =
    nameError ||
    descriptionError ||
    mintAtError ||
    symbolError ||
    attributesError ||
    contractsError

  // reset all errors
  const resetErrors = () => {
    setNameError(false)
    setDescriptionError(false)
    setMintAtError(false)
    setSymbolError(false)
    setAttributesError(false)
    setContractsError(false)
  }

  // queries
  const [nftCatalogsListQuery] = useLazyQuery(nftCatalogsListGql)
  const [nftCatalogGetQuery] = useLazyQuery(nftCatalogGet)

  // mutations
  const [nftCatalogUpdateMutation] = useMutation(nftCatalogUpdate)
  const [nftCatalogCreateMutation] = useMutation(nftCatalogCreate)

  // get nft catalogs list
  const getNftCatalogsList = useCallback(
    async (withLoading: boolean = true) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter((item) => item.name === "NFT catalogs")[0].loading =
          true
        setPanelStatus([...panelStatus])
      }

      try {
        logger(Status.Api, "QUERY nftCatalogsList", nftCatalogsListGql)
        const { data } = await nftCatalogsListQuery({
          variables: {
            input: {
              limit: Math.round(window.innerHeight / 74) + 10,
            },
          },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "nft catalogs list", data.nftCatalogsList.items)

        setNftCatalogsList(data.nftCatalogsList.items)
        setNftCatalogsListNextToken(data.nftCatalogsList.nextToken)

        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "NFT catalogs")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "NFT catalogs")[0].loading =
          false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `nftCatalogsList`, e.message)
        }
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "NFT catalogs")[0].status =
          "error"
        panelStatus.filter((item) => item.name === "NFT catalogs")[0].loading =
          false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // load more nft catalogs
  const loadMoreNftCatalogs = async () => {
    try {
      logger(Status.Api, "QUERY nftCatalogsList", nftCatalogsListGql)
      const { data } = await nftCatalogsListQuery({
        variables: {
          input: {
            limit: Math.round(window.innerHeight / 74) + 10,
            nextToken: nftCatalogsListNextToken,
          },
        },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "nft catalogs list", [
        ...nftCatalogsList,
        ...data.nftCatalogsList.items,
      ])

      setNftCatalogsList([...nftCatalogsList, ...data.nftCatalogsList.items])
      setNftCatalogsListNextToken(data.nftCatalogsList.nextToken)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `nftCatalogsList`, e.message)
      }
      return false
    }
  }

  // get current nft catalog
  const getCurrentNftCatalog = useCallback(
    async (catalogId: string) => {
      try {
        logger(Status.Api, "QUERY nftCatalogGet", nftCatalogGet)
        const { data } = await nftCatalogGetQuery({
          variables: { input: { id: catalogId } },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, `nft catalog ${catalogId}`, data.nftCatalogGet)

        setCurrentNftCatalog(data.nftCatalogGet)
        setPreChangesCurrentNftCatalog(deepCopy(data.nftCatalogGet))

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `nftCatalogGet`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // update nft catalog
  const updateNftCatalog = async () => {
    try {
      const input = {
        id: currentNftCatalog.id,
        name: currentNftCatalog.name,
        description: currentNftCatalog.description,
        mintAt: currentNftCatalog.mintAt,
        symbol: currentNftCatalog.symbol,
        image: currentNftCatalog.image,
        attributes: currentNftCatalog.attributes.map((attribute) => {
          return {
            trait_type: attribute.trait_type,
            value: attribute.value,
          }
        }),
        contracts: currentNftCatalog.contracts,
      }

      logger(Status.Api, "MUTATION nftCatalogUpdate", nftCatalogUpdate)
      const { data } = await nftCatalogUpdateMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `nft catalog ${data.nftCatalogUpdate.id} upserted`,
        data.nftCatalogUpdate
      )

      setCurrentNftCatalog(data.nftCatalogUpdate)
      setPreChangesCurrentNftCatalog(deepCopy(data.nftCatalogUpdate))

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `nftCatalogUpdate`, e.message)
      }
      return false
    }
  }

  // create nft catalog
  const createNftCatalog = async (input: {
    name: string
    description: string
    mintAt: string
    symbol: string
    image: string
    kind: string
    projectId: string
    sourceId: string
    sourceKind: string
    attributes: NftAttribute[]
  }) => {
    try {
      logger(Status.Api, "MUTATION nftCatalogCreate", nftCatalogCreate)
      const { data } = await nftCatalogCreateMutation({
        variables: { input: input },
      })
      logger(Status.Info, `nft catalog created`, data.nftCatalogCreate)

      return data.nftCatalogCreate.id
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `nftCatalogCreate`, e.message)
      }
      return false
    }
  }

  // cancel changes
  const cancelChanges = () => {
    setCurrentNftCatalog(deepCopy(preChangesCurrentNftCatalog))
    resetErrors()
  }

  // check if user has done changes
  useEffect(() => {
    if (currentNftCatalog) {
      const currentNftCatalogCopy = deepCopy(currentNftCatalog)
      const preChangesCurrentNftCatalogCopy = deepCopy(
        preChangesCurrentNftCatalog
      )

      if (
        JSON.stringify(currentNftCatalogCopy) !==
        JSON.stringify(preChangesCurrentNftCatalogCopy)
      ) {
        setDoneChanges(true)
      } else {
        setDoneChanges(false)
      }
    }
  }, [currentNftCatalog])

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter(
      (item) => item.name === "NFT catalogs"
    )[0].updateFunction = getNftCatalogsList
  }, [])

  return (
    <NftsContext.Provider
      value={{
        loading,
        setLoading,
        updatingList,
        setUpdatingList,
        editMode,
        setEditMode,
        nftCatalogsList,
        setNftCatalogsList,
        getNftCatalogsList,
        doneChanges,
        cancelChanges,
        currentNftCatalog,
        setCurrentNftCatalog,
        getCurrentNftCatalog,
        preChangesCurrentNftCatalog,
        updateNftCatalog,
        hasError,
        createNftCatalog,
        nftCatalogsListNextToken,
        loadMoreNftCatalogs,
        nameError,
        setNameError,
        descriptionError,
        setDescriptionError,
        mintAtError,
        setMintAtError,
        symbolError,
        setSymbolError,
        attributesError,
        setAttributesError,
        contractsError,
        setContractsError,
      }}
    >
      {children}
    </NftsContext.Provider>
  )
}

export { NftsController, NftsContext }
