import { LoadingButton } from "@mui/lab"
import {
  Button,
  CardActions,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  DialogTransition,
  isPrivacyVersionValid,
  isValidURL,
} from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import { useNavigate } from "react-router-dom"
import { MainContext } from "../../controllers/main"
import { ChannelsContext } from "../../controllers/channels"
import { CloseRounded } from "@mui/icons-material"
import TeamsList from "../../views/team/teamsList"
import Team from "../../models/team"
import { TeamsContext } from "../../controllers/teams"

const CreateChannelDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
  topBarText,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
  topBarText: string
}) => {
  const { setAnimation } = useContext(MainContext)
  const { loading, createNewChannel } = useContext(ChannelsContext)
  const { resetTeamsFilters } = useContext(TeamsContext)
  const navigate = useNavigate()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // project id
  const [projectId, setProjectId] = useState<string>("")

  // client credential id
  const [clientCredentialId, setClientCredentialId] = useState<string>("")

  // webhook url
  const [webhookUrl, setWebhookUrl] = useState<string>("")

  // report url
  const [reportUrl, setReportUrl] = useState<string>("")

  // issuer
  const [issuer, setIssuer] = useState<string>("")

  // privacy version
  const [privacyVersion, setPrivacyVersion] = useState<string>("")

  // team
  const [team, setTeam] = useState<Team | null>(null)

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  // change team dialog
  const [changeTeamDialogOpen, setChangeTeamDialogOpen] =
    useState<boolean>(false)
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)

  // validate id
  const isValidId = (id: string) => {
    const regex = /^[a-zA-Z0-9_.]+$/g
    return regex.test(id)
  }

  // slides
  const slides = [
    {
      label: "IDs",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            IDs
          </Typography>
          <TextField
            label="Project ID"
            size="small"
            value={projectId}
            onChange={(e) => {
              setProjectId(e.target.value)
            }}
            error={projectId.length !== 0 && !isValidId(projectId)}
            helperText={
              projectId.length !== 0 && !isValidId(projectId)
                ? "Invalid characters"
                : ""
            }
            onBlur={() => {
              if (projectId) {
                setProjectId((current) => current.trim())
              }
            }}
          />
          <TextField
            label="Client credential ID"
            size="small"
            value={clientCredentialId}
            onChange={(e) => {
              setClientCredentialId(e.target.value)
            }}
            error={
              clientCredentialId.length !== 0 && !isValidId(clientCredentialId)
            }
            helperText={
              clientCredentialId.length !== 0 && !isValidId(clientCredentialId)
                ? "Invalid characters"
                : ""
            }
            onBlur={() => {
              if (clientCredentialId) {
                setClientCredentialId((current) => current.trim())
              }
            }}
          />
        </Stack>
      ),
    },
    {
      label: "URLs",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            URLs
          </Typography>
          <TextField
            label="Webhook URL"
            size="small"
            value={webhookUrl}
            onChange={(e) => {
              setWebhookUrl(e.target.value)
            }}
            error={webhookUrl.length !== 0 && !isValidURL(webhookUrl)}
            helperText={
              webhookUrl.length !== 0 && !isValidURL(webhookUrl)
                ? "Invalid URL"
                : ""
            }
            onBlur={() => {
              if (webhookUrl) {
                setWebhookUrl((current) => current.trim())
              }
            }}
          />
          <TextField
            label="Report URL"
            size="small"
            value={reportUrl}
            onChange={(e) => {
              setReportUrl(e.target.value)
            }}
            error={reportUrl.length !== 0 && !isValidURL(reportUrl)}
            helperText={
              reportUrl.length !== 0 && !isValidURL(reportUrl)
                ? "Invalid URL"
                : ""
            }
            onBlur={() => {
              if (reportUrl) {
                setReportUrl((current) => current.trim())
              }
            }}
          />
          <TextField
            label="Issuer"
            size="small"
            value={issuer}
            onChange={(e) => {
              setIssuer(e.target.value)
            }}
            onBlur={() => {
              if (issuer) {
                setIssuer((current) => current.trim())
              }
            }}
          />
        </Stack>
      ),
    },
    {
      label: "Privacy",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Privacy
          </Typography>
          <TextField
            label="Privacy version"
            size="small"
            value={privacyVersion}
            onChange={(e) => {
              setPrivacyVersion(e.target.value)
            }}
            error={
              privacyVersion.length !== 0 &&
              !isPrivacyVersionValid(privacyVersion)
            }
            helperText={
              privacyVersion.length !== 0 &&
              !isPrivacyVersionValid(privacyVersion)
                ? "Invalid version, correct format: x.x.x"
                : ""
            }
          />
        </Stack>
      ),
    },
    {
      label: "Team",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Team
          </Typography>
          <TextField
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Button
                  sx={{ paddingInline: 3 }}
                  onClick={() => {
                    setChangeTeamDialogOpen(true)
                  }}
                >
                  {team ? "Change" : "Select"}
                </Button>
              ),
            }}
            fullWidth
            label="Team"
            size="small"
            value={team ? team.name : ""}
          />
        </Stack>
      ),
    },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setProjectId("")
        setClientCredentialId("")
        setWebhookUrl("")
        setReportUrl("")
        setIssuer("")
        setPrivacyVersion("")
        setTeam(null)
        setCurrentSlide(0)
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (projectId.length || clientCredentialId.length) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [projectId, clientCredentialId])

  // create channel
  const createChannel = async () => {
    const newChannel = await createNewChannel(
      projectId,
      clientCredentialId,
      issuer,
      webhookUrl,
      reportUrl,
      privacyVersion,
      team.oldId ? team.oldId.toString() : "",
      team.id
    )

    setDialogOpen(false)

    setAnimation(false)
    setTimeout(() => {
      navigate(`/channels/${newChannel.id}`, { state: { enableEditing: true } })
    }, 250)
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText={topBarText}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            {topBarText}
          </Typography>
          <Stepper
            activeStep={currentSlide}
            style={{
              paddingTop: 24,
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 8,
              maxWidth: "100%",
              overflow: "overlay",
            }}
          >
            {slides.map((slide) => {
              return (
                <Step key={slide.label}>
                  <StepLabel>{slide.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (!slides[currentSlide - 1]) {
                    if (showDiscardDialog) {
                      setDiscardDialogOpen(true)
                    } else {
                      handleDialogChange(e)
                    }
                  } else {
                    setCurrentSlide(currentSlide - 1)
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  (currentSlide === 0 && !projectId.length) ||
                  (projectId.length !== 0 && !isValidId(projectId)) ||
                  (currentSlide === 0 && !clientCredentialId.length) ||
                  (clientCredentialId.length !== 0 &&
                    !isValidId(clientCredentialId)) ||
                  (currentSlide === 1 && !webhookUrl.length) ||
                  (webhookUrl.length !== 0 && !isValidURL(webhookUrl)) ||
                  (currentSlide === 1 && !issuer.length) ||
                  (currentSlide === 2 && !privacyVersion.length) ||
                  (currentSlide === 3 && !team)
                }
                onMouseDown={
                  !slides[currentSlide + 1]
                    ? () => {
                        createChannel()
                      }
                    : () => {
                        setCurrentSlide(currentSlide + 1)
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Save" : "Next"}
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* change team dialog */}
      <Dialog
        fullScreen
        open={changeTeamDialogOpen}
        onClose={() => {
          setChangeTeamDialogOpen(false)
          setSelectedTeam(null)
          resetTeamsFilters()
        }}
        TransitionComponent={DialogTransition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Episodes
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                setTeam(selectedTeam)

                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              disabled={!selectedTeam}
            >
              Select
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ backgroundColor: "#f5f5f5" }}>
          <TeamsList
            forDialog
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
          />
        </Paper>
      </Dialog>
      <LoadingBackdrop open={loading} />
    </Dialog>
  )
}

export default CreateChannelDialog
