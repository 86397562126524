import { Chip, Stack, Typography } from "@mui/material"
import leafIcon from "../../assets/icons/leaf.svg"

const PointsBadge = ({ points }: { points: number }) => {
  return (
    <Chip
      size="small"
      label={
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <img src={leafIcon} style={{ minWidth: 9, height: 11 }} />
          <Typography style={{ fontSize: 13, fontWeight: 600 }}>
            {points}
          </Typography>
        </Stack>
      }
      sx={{ backgroundColor: "#F0F7FF", color: "#00A0FD" }}
    />
  )
}

export default PointsBadge
