import { LoadingButton } from "@mui/lab"
import {
  AppBar,
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material"
import Dialog from "@mui/material/Dialog"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { EpisodesContext } from "../../controllers/episodes"
import Sdg from "../../models/sdg"
import SdgTarget from "../../models/sdgTarget"
import Topic from "../../models/topic"
import {
  capitalizeFirstCharacter,
  DialogTransition,
  enumAsArray,
  renderOption,
  renderTags,
} from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import ImagePicker from "../global/imagePicker"
import { JourneysContext } from "../../controllers/journeys"
import {
  AddCircleRounded,
  CloseRounded,
  DeleteRounded,
  DragHandleRounded,
} from "@mui/icons-material"
import Episode from "../../models/episode"
import EpisodesList from "../../views/episode/episodesList"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import { Esg } from "../../services/config/enum"
import { MainContext } from "../../controllers/main"
import { useNavigate } from "react-router-dom"
import { LanguagesContext } from "../../controllers/languages"
import { TagsContext } from "../../controllers/tags"
import { AutocompleteOption } from "../../services/config/interfaces"
import ClearableSelect from "../global/clearableSelect"

const CreateJourneyDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
  topBarText,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
  topBarText: string
}) => {
  const { setAnimation } = useContext(MainContext)
  const { languages } = useContext(LanguagesContext)
  const {
    categoriesLoading,
    categoriesList,
    topicsList,
    getTopicsList,
    sdgsList,
    getSdgsList,
    sdgTargetsList,
    getSdgTargetsList,
    topicsLoading,
    sdgsLoading,
    sdgTargetsLoading,
  } = useContext(TagsContext)
  const {
    setSearchValue,
    setSearchLanguage,
    setSearchTopic,
    setSearchSdg,
    setSearchSdgTarget,
    setEpisodesList,
  } = useContext(EpisodesContext)
  const { createNewJourney, loading } = useContext(JourneysContext)
  const navigate = useNavigate()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // get topics list
  useEffect(() => {
    if (dialogOpen && !topicsList.length) {
      getTopicsList()
    }
    if (dialogOpen && !sdgsList.length) {
      getSdgsList()
    }
    if (dialogOpen && !sdgTargetsList.length) {
      getSdgTargetsList()
    }
  }, [getTopicsList, getSdgsList, getSdgTargetsList, dialogOpen])

  // master language
  const [lang, setLang] = useState<string>("")

  // name
  const [name, setName] = useState<string>("")

  // text
  const [text, setText] = useState<string>("")

  // primary topic
  const [primaryTopic, setPrimaryTopic] = useState<AutocompleteOption | null>(
    null
  )

  // secondary topics
  const [secondaryTopics, setSecondaryTopics] = useState<AutocompleteOption[]>(
    []
  )
  const [secondaryTopicsError, setSecondaryTopicsError] =
    useState<boolean>(false)

  // primary sdg
  const [primarySdg, setPrimarySdg] = useState<AutocompleteOption | null>(null)

  // secondary sdgs
  const [secondarySdgs, setSecondarySdgs] = useState<AutocompleteOption[]>([])
  const [secondarySdgsError, setSecondarySdgsError] = useState<boolean>(false)

  // primary sdg target
  const [primarySdgTarget, setPrimarySdgTarget] =
    useState<AutocompleteOption | null>(null)

  // secondary sdg targets
  const [secondarySdgTargets, setSecondarySdgTargets] = useState<
    AutocompleteOption[]
  >([])

  // category
  const [category, setCategory] = useState<AutocompleteOption | null>(null)
  const [esg, setEsg] = useState<string | null>("")

  // thumbnail
  const [thumbnail, setThumbnail] = useState<string>("")

  // episodes
  const [addEpisodesDialogOpen, setAddEpisodesDialogOpen] =
    useState<boolean>(false)
  const [selectedEpisodes, setSelectedEpisodes] = useState<Episode[]>([])
  const [episodesToFilter, setEpisodesToFilter] = useState<Episode[]>([])
  const [episodes, setEpisodes] = useState<
    { sorting: number; episode: Episode }[]
  >([])

  // type
  const [type, setType] = useState<string>("Free")

  // reset episodes list filters
  const resetEpisodesFilters = () => {
    setSearchValue("")
    setSearchLanguage(null)
    setSearchTopic(null)
    setSearchSdg(null)
    setSearchSdgTarget(null)
  }

  // drag and drop
  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result

    if (destination.index === source.index) {
      return
    }

    let sourceItem = episodes[source.index]

    let indexToDelete = episodes.findIndex(
      (item) => item.episode.id === draggableId
    )
    episodes.splice(indexToDelete, 1)
    episodes.splice(destination.index, 0, sourceItem)

    setEpisodes([...episodes])
  }

  // can't add episodes dialog
  const [cantAddEpisodeDialogOpen, setCantAddEpisodeDialogOpen] =
    useState<boolean>(false)

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  // slides
  const slides = [
    {
      label: "Language",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Master language
          </Typography>
          <FormControl fullWidth size="small">
            <InputLabel id="language-select">Master language</InputLabel>
            <Select
              labelId="language-select"
              label="Master language"
              value={lang}
              onChange={(e) => {
                setLang(e.target.value)
                setEpisodes([])
              }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {lang.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      ),
    },
    {
      label: "Text",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Text
          </Typography>
          <TextField
            error={name.length !== 0 && name.length < 3}
            fullWidth
            label={`Title (${lang.toUpperCase()})`}
            variant="outlined"
            size="small"
            type="text"
            value={name}
            onChange={(e) => {
              setName(capitalizeFirstCharacter(e.target.value))
            }}
            helperText={
              name.length !== 0 && name.length < 3
                ? "Minimum length is 3 characters"
                : ""
            }
          />
          <TextField
            error={text.length !== 0 && text.length < 3}
            fullWidth
            label={`Description (${lang.toUpperCase()})`}
            variant="outlined"
            size="small"
            type="text"
            multiline
            rows={2}
            value={text}
            onChange={(e) => {
              setText(capitalizeFirstCharacter(e.target.value))
            }}
            helperText={
              text.length !== 0 && text.length < 3
                ? "Minimum length is 3 characters"
                : ""
            }
          />
        </Stack>
      ),
    },
    {
      label: "Category",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Category
          </Typography>
          <Autocomplete
            loading={categoriesLoading}
            fullWidth
            blurOnSelect
            size="small"
            value={category}
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={categoriesList.map((item) => {
              return {
                id: item.id,
                label: item.name,
              }
            })}
            renderInput={(params) => <TextField {...params} label="Category" />}
            onChange={(e: any, newValue: AutocompleteOption | null) => {
              setCategory(newValue)
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
          <ClearableSelect
            label="ESG"
            value={esg ?? ""}
            onChange={(newValue) => {
              setEsg(newValue)
            }}
          >
            {enumAsArray(Esg).map((key: string) => (
              <MenuItem key={key} value={key}>
                {capitalizeFirstCharacter(key)}
              </MenuItem>
            ))}
          </ClearableSelect>
        </Stack>
      ),
    },
    {
      label: "Topics",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Topics
          </Typography>
          <Autocomplete
            loading={topicsLoading}
            getOptionDisabled={(option: AutocompleteOption) => {
              if (
                secondaryTopics.filter(
                  (topic: AutocompleteOption) => topic.id === option.id
                )[0]
              ) {
                return true
              } else {
                return false
              }
            }}
            size="small"
            value={primaryTopic}
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              topicsList.length
                ? topicsList.map((topic: Topic) => {
                    return { label: topic.name, id: topic.id }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField {...params} label="Primary topic" />
            )}
            onChange={(e: any, newValue: AutocompleteOption | null) => {
              setPrimaryTopic(newValue)
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
          <Autocomplete
            loading={topicsLoading}
            getOptionDisabled={(option: AutocompleteOption) =>
              option.id === primaryTopic?.id
            }
            onBlur={() => {
              setSecondaryTopicsError(false)
            }}
            multiple
            size="small"
            value={secondaryTopics}
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              topicsList.length
                ? topicsList.map((topic: Topic) => {
                    return { label: topic.name, id: topic.id }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Secondary topics"
                error={secondaryTopicsError}
                helperText={
                  secondaryTopicsError
                    ? "The maximum number of secondary topics is 3"
                    : null
                }
              />
            )}
            onChange={(e: any, newValues: AutocompleteOption[]) => {
              if (newValues.length < 4) {
                setSecondaryTopics(newValues)
                setSecondaryTopicsError(false)
              } else {
                setSecondaryTopicsError(true)
              }
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
        </Stack>
      ),
    },
    {
      label: "SDGs",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            SDGs and SDG targets
          </Typography>
          <Autocomplete
            loading={sdgsLoading}
            getOptionDisabled={(option: AutocompleteOption) => {
              if (
                secondarySdgs.filter(
                  (sdg: AutocompleteOption) => sdg.id === option.id
                )[0]
              ) {
                return true
              } else {
                return false
              }
            }}
            size="small"
            value={primarySdg}
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              sdgsList.length
                ? sdgsList.map((sdg: Sdg) => {
                    let idForLabel =
                      sdg.id.charAt(4) === "0"
                        ? sdg.id.slice(5) + ". "
                        : sdg.id.slice(4) + ". "

                    return { label: idForLabel + sdg.name, id: sdg.id }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField {...params} label="Primary SDG" />
            )}
            onChange={(e: any, newValue: AutocompleteOption | null) => {
              setPrimarySdg(newValue)
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
          <Autocomplete
            loading={sdgsLoading}
            getOptionDisabled={(option: AutocompleteOption) =>
              option.id === primarySdg?.id
            }
            onBlur={() => {
              setSecondarySdgsError(false)
            }}
            multiple
            size="small"
            value={secondarySdgs}
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              sdgsList.length
                ? sdgsList.map((sdg: Sdg) => {
                    let idForLabel =
                      sdg.id.charAt(4) === "0"
                        ? sdg.id.slice(5) + ". "
                        : sdg.id.slice(4) + ". "

                    return { label: idForLabel + sdg.name, id: sdg.id }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Secondary SDGs"
                error={secondarySdgsError}
                helperText={
                  secondarySdgsError
                    ? "The maximum number of secondary SDGs is 3"
                    : null
                }
              />
            )}
            onChange={(e: any, newValues: AutocompleteOption[]) => {
              if (newValues.length < 4) {
                setSecondarySdgs(newValues)
                setSecondarySdgsError(false)
              } else {
                setSecondarySdgsError(true)
              }
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
          <Autocomplete
            loading={sdgTargetsLoading}
            getOptionDisabled={(option: AutocompleteOption) => {
              if (
                secondarySdgTargets.filter(
                  (sdgTarget: AutocompleteOption) => sdgTarget.id === option.id
                )[0]
              ) {
                return true
              } else {
                return false
              }
            }}
            size="small"
            value={primarySdgTarget}
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              sdgTargetsList.length
                ? sdgTargetsList.map((sdgTarget: SdgTarget) => {
                    return { label: sdgTarget.id.slice(4), id: sdgTarget.id }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField {...params} label="Primary SDG target" />
            )}
            onChange={(e: any, newValue: AutocompleteOption | null) => {
              setPrimarySdgTarget(newValue)
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
          <Autocomplete
            loading={sdgTargetsLoading}
            getOptionDisabled={(option: AutocompleteOption) =>
              option.id === primarySdgTarget?.id
            }
            multiple
            size="small"
            value={secondarySdgTargets}
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              sdgTargetsList.length
                ? sdgTargetsList.map((sdgTarget: SdgTarget) => {
                    return { label: sdgTarget.id.slice(4), id: sdgTarget.id }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField {...params} label="Secondary SDG targets" />
            )}
            onChange={(e: any, newValues: AutocompleteOption[]) => {
              setSecondarySdgTargets(newValues)
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
        </Stack>
      ),
    },
    {
      label: "Thumbnail",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Thumbnail
          </Typography>
          <ImagePicker
            filters={{
              category: "thumbnail",
            }}
            disableCategoryFilter
            title="Thumbnail"
            pickedImage={thumbnail}
            setPickedImage={(selectedImage: {
              url: string
              id: string
              description?: string
            }) => {
              setThumbnail(selectedImage.url)
            }}
            height={200}
            removeButton
          />
        </Stack>
      ),
    },
    {
      label: "Episodes",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Episodes
          </Typography>
          <div
            style={{
              width: "100%",
              height: 256,
              maxHeight: 256,
              overflowY: "scroll",
              paddingLeft: 1,
              paddingRight: 1,
              paddingTop: 2,
              paddingBottom: 1,
            }}
          >
            <Stack spacing={2}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="column-1">
                  {(provided) => (
                    <Stack
                      spacing={2}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                          marginTop: -16,
                        }}
                      />
                      {episodes.map((item, index) => (
                        <Draggable
                          key={item.episode.id.toString()}
                          draggableId={item.episode.id.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <Card
                              variant="outlined"
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                            >
                              <CardContent
                                id={item.episode.id}
                                style={{
                                  overflow: "hidden",
                                  height: 65,
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  display: "flex",
                                  alignItems: "center",
                                  position: "relative",
                                }}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={5}
                                  style={{
                                    marginLeft: 0,
                                    transition: "200ms",
                                    height: 65,
                                    width: "90%",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: 30,
                                      height: 26,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      transition: "100ms",
                                      cursor: "grab",
                                    }}
                                    {...provided.dragHandleProps}
                                  >
                                    <DragHandleRounded />
                                  </div>
                                  <div
                                    style={{
                                      position: "relative",
                                      marginLeft: 16,
                                    }}
                                  >
                                    {item.episode.stage === "PUBLISHED" ||
                                    item.episode.hasSibling ? (
                                      <Chip
                                        size="small"
                                        label="Live"
                                        color="success"
                                        style={{
                                          position: "relative",
                                          zIndex: 1,
                                          width: 45.26,
                                        }}
                                      />
                                    ) : (
                                      <Chip
                                        size="small"
                                        label="Draft"
                                        color="warning"
                                        style={{
                                          position: "relative",
                                          zIndex: 1,
                                          width: 45.26,
                                        }}
                                      />
                                    )}
                                    {item.episode.hasSibling ? (
                                      <Chip
                                        size="small"
                                        label="Draft"
                                        color="warning"
                                        style={{
                                          position: "absolute",
                                          left: 8,
                                          top: 1.2,
                                        }}
                                      />
                                    ) : null}
                                  </div>
                                  <div
                                    style={{
                                      width: 40,
                                      minWidth: 40,
                                      height: 40,
                                      borderRadius: 4,
                                      backgroundImage: `url(${
                                        item.episode.image ?? noImagePlaceholder
                                      })`,
                                      backgroundSize: "cover",
                                      backgroundPosition: "center",
                                    }}
                                  />
                                  <Tooltip title={item.episode.title}>
                                    <Typography
                                      style={{
                                        maxWidth: 300,
                                        width: 300,
                                        minWidth: 44,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        cursor: "default",
                                      }}
                                    >
                                      {item.episode.title}
                                    </Typography>
                                  </Tooltip>
                                </Stack>
                                <IconButton
                                  style={{
                                    position: "absolute",
                                    right: 18,
                                    transition: "100ms",
                                    zIndex: 1,
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    episodes.splice(index, 1)
                                    setEpisodes([...episodes])
                                  }}
                                  color="error"
                                >
                                  <DeleteRounded style={{ width: 22 }} />
                                </IconButton>
                              </CardContent>
                            </Card>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Stack>
                  )}
                </Droppable>
              </DragDropContext>
              <Button
                startIcon={<AddCircleRounded />}
                style={{
                  maxHeight: 40,
                  minHeight: 40,
                  minWidth: "100%",
                  marginRight: 1,
                  marginTop: !episodes.length ? -2 : 16,
                }}
                variant="outlined"
                onClick={() => {
                  setEpisodesList([])
                  setEpisodesToFilter(episodes.map((item) => item.episode))
                  setAddEpisodesDialogOpen(true)
                }}
              >
                Add Episodes
              </Button>
            </Stack>
          </div>
        </Stack>
      ),
    },
    {
      label: "Type",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Type
          </Typography>
          <FormControl fullWidth size="small">
            <InputLabel id="type-select">Type</InputLabel>
            <Select
              labelId="type-select"
              label="Type"
              value={type}
              onChange={(e) => {
                setType(e.target.value)
              }}
            >
              <MenuItem value="Free">Free</MenuItem>
              <MenuItem value="Business">Business</MenuItem>
              <MenuItem value="Custom">Custom</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      ),
    },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setLang("")
        setName("")
        setText("")
        setPrimaryTopic(null)
        setSecondaryTopics([])
        setSecondaryTopicsError(false)
        setPrimarySdg(null)
        setSecondarySdgs([])
        setSecondarySdgsError(false)
        setPrimarySdgTarget(null)
        setSecondarySdgTargets([])
        setCategory(null)
        setEsg("")
        setThumbnail("")
        setEpisodes([])
        setType("Free")
        setCurrentSlide(0)
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (name.length || text.length) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [name, text])

  // create journey
  const createJourney = async () => {
    let input: {
      lang: string
      title: string
      text: string
      image: string
      topics: { id: string; primary: boolean }[]
      sdgs: { id: string; primary: boolean }[]
      sdgTargets: { id: string; primary: boolean }[]
      category: { id: string }
      episodes: { id: string; sorting: number }[]
      type: string
      publishedLangs: { lang: string; title: string }[]
      esg?: string
    } = {
      lang: lang,
      title: name,
      text: text,
      image: thumbnail.length ? thumbnail : null,
      topics: [
        { id: primaryTopic.id, primary: true },
        ...secondaryTopics.map((topic) => {
          return { id: topic.id, primary: false }
        }),
      ],
      sdgs: [
        { id: primarySdg.id, primary: true },
        ...secondarySdgs.map((sdg) => {
          return { id: sdg.id, primary: false }
        }),
      ],
      sdgTargets: [
        ...secondarySdgTargets.map((sdg) => {
          return { id: sdg.id, primary: false }
        }),
      ],
      category: { id: category.id },
      episodes: episodes.map((item, index) => {
        return {
          id: item.episode.id,
          sorting: index + 1,
        }
      }),
      type: type,
      publishedLangs: [{ lang: lang, title: name }],
    }

    if (primarySdgTarget) {
      input.sdgTargets.unshift({ id: primarySdgTarget.id, primary: true })
    }
    if (esg) {
      input.esg = esg
    }

    const newJourney = await createNewJourney(input)

    setDialogOpen(false)

    setAnimation(false)
    setTimeout(() => {
      navigate(`/journeys/${newJourney.id}`, { state: { enableEditing: true } })
    }, 250)
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText={topBarText}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            {topBarText}
          </Typography>
          <div
            style={{
              height: 56,
              width: "100%",
              minWidth: 345,
              maxWidth: 850,
              overflow: "overlay",
            }}
          >
            <Stepper
              activeStep={currentSlide}
              style={{
                paddingTop: 24,
                paddingLeft: 8,
                paddingRight: 8,
                paddingBottom: 8,
              }}
            >
              {slides.map((slide) => {
                return (
                  <Step key={slide.label}>
                    <StepLabel>{slide.label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          </div>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (!slides[currentSlide - 1]) {
                    if (showDiscardDialog) {
                      setDiscardDialogOpen(true)
                    } else {
                      handleDialogChange(e)
                    }
                  } else {
                    setCurrentSlide(currentSlide - 1)
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  (currentSlide === 0 && !lang.length) ||
                  (currentSlide === 1 &&
                    (name.length < 3 || text.length < 3)) ||
                  (currentSlide === 2 && !category) ||
                  (currentSlide === 3 && !primaryTopic) ||
                  (currentSlide === 4 && !primarySdg) ||
                  (currentSlide === 6 && !episodes.length)
                }
                onMouseDown={
                  !slides[currentSlide + 1]
                    ? () => {
                        createJourney()
                      }
                    : () => {
                        setCurrentSlide(currentSlide + 1)
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Save" : "Next"}
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* add episodes dialog */}
      <Dialog
        fullScreen
        open={addEpisodesDialogOpen}
        onClose={() => {
          setAddEpisodesDialogOpen(false)
          setSelectedEpisodes([])
          resetEpisodesFilters()
        }}
        TransitionComponent={DialogTransition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setAddEpisodesDialogOpen(false)
                setSelectedEpisodes([])
                resetEpisodesFilters()
              }}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Episodes
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                // check if selected episodes are compatible with journey published langs
                for (let i = 0; i < selectedEpisodes.length; i++) {
                  if (
                    !selectedEpisodes[i].publishedLangs.filter(
                      (item) => item.lang === lang
                    )[0] ||
                    (selectedEpisodes[i].stage === "DRAFT" &&
                      !selectedEpisodes[i].hasSibling)
                  ) {
                    setAddEpisodesDialogOpen(false)
                    setSelectedEpisodes([])
                    resetEpisodesFilters()
                    setTimeout(() => {
                      setCantAddEpisodeDialogOpen(true)
                    }, 150)
                    return
                  }
                }

                for (let i = 0; i < selectedEpisodes.length; i++) {
                  episodes.push({
                    sorting: 0,
                    episode: selectedEpisodes[i],
                  })
                }
                setEpisodes([...episodes])

                setAddEpisodesDialogOpen(false)
                setSelectedEpisodes([])
                resetEpisodesFilters()
              }}
              disabled={!selectedEpisodes.length}
            >
              Select
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ backgroundColor: "#f5f5f5" }}>
          <EpisodesList
            forDialog
            forDialogLanguage={lang}
            episodesToFilter={episodesToFilter}
            selectedEpisodes={selectedEpisodes}
            setSelectedEpisodes={setSelectedEpisodes}
          />
        </Paper>
      </Dialog>
      {/* can't add episodes dialog */}
      <Dialog
        open={cantAddEpisodeDialogOpen}
        onClose={() => {
          setCantAddEpisodeDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-cant-add-episode"
        aria-describedby="alert-dialog-description-cant-add-episode"
      >
        <DialogTitle id="alert-dialog-title-cant-add-episode">
          These Episodes cannot be added
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-cant-add-episode">
            There are one or more Episodes without {lang.toUpperCase()}{" "}
            translation or master Episode published
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCantAddEpisodeDialogOpen(false)
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* loading backdrop */}
      <LoadingBackdrop open={loading} />
    </Dialog>
  )
}

export default CreateJourneyDialog
