import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import { DialogTransition } from "../../services/utilities/utility"
import { CloseRounded } from "@mui/icons-material"
import NftCatalogsList from "../../views/nft/nftCatalogsList"
import NftCatalog from "../../models/nftCatalog"

const ChangeNftCatalogDialog = ({
  open,
  setOpen,
  selectedNftCatalog,
  setSelectedNftCatalog,
  setNftCatalog,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  selectedNftCatalog: NftCatalog
  setSelectedNftCatalog: Dispatch<SetStateAction<NftCatalog>>
  setNftCatalog: Dispatch<SetStateAction<NftCatalog>>
}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false)
        setSelectedNftCatalog(null)
      }}
      TransitionComponent={DialogTransition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setOpen(false)
              setSelectedNftCatalog(null)
            }}
            aria-label="close"
          >
            <CloseRounded />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Select Nft Catalog
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => {
              setNftCatalog(selectedNftCatalog)

              setOpen(false)
              setSelectedNftCatalog(null)
            }}
            disabled={!selectedNftCatalog}
          >
            Select
          </Button>
        </Toolbar>
      </AppBar>
      <Paper style={{ backgroundColor: "#f5f5f5" }}>
        <NftCatalogsList
          forDialog
          selectedNftCatalog={selectedNftCatalog}
          setSelectedNftCatalog={setSelectedNftCatalog}
        />
      </Paper>
    </Dialog>
  )
}

export default ChangeNftCatalogDialog
