import { Card, CardContent, Stack, TextField, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { NftsContext } from "../../controllers/nfts"

const NftContractCard = ({
  index,
  contract,
}: {
  index: number
  contract: string
}) => {
  const { currentNftCatalog, setCurrentNftCatalog, editMode } =
    useContext(NftsContext)

  // errors
  const [error, setError] = useState<boolean>(false)

  // check for errors
  useEffect(() => {
    if (!contract) {
      setError(true)
    } else {
      setError(false)
    }
  }, [contract])

  return (
    <Card
      variant="outlined"
      sx={{
        boxShadow: "none",
        borderTopLeftRadius: !index ? undefined : 0,
        borderTopRightRadius: !index ? undefined : 0,
        borderBottomLeftRadius:
          index === currentNftCatalog.contracts.length - 1 ? undefined : 0,
        borderBottomRightRadius:
          index === currentNftCatalog.contracts.length - 1 ? undefined : 0,
        borderTop: index === 0 ? undefined : "none",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <CardContent
        style={{
          paddingTop: 12,
          paddingBottom: 12,
          transition: "150ms",
        }}
      >
        <Stack gap={1.5}>
          <Stack
            direction="row"
            alignItems="center"
            style={{ position: "relative" }}
            gap={1}
          >
            <Typography
              variant="h6"
              color={error ? "error" : "default"}
              style={{
                fontSize: "1rem",
                height: 24.88,
              }}
            >
              Contract {index + 1}
            </Typography>
            {error ? (
              <Typography
                variant="overline"
                color="error"
                style={{ fontSize: "0.5rem", marginTop: 5 }}
              >
                (error)
              </Typography>
            ) : null}
          </Stack>
          <TextField
            fullWidth
            size="small"
            label="Contract"
            disabled={!editMode}
            value={contract}
            onChange={(e) => {
              currentNftCatalog.contracts[index] = e.target.value
              setCurrentNftCatalog({ ...currentNftCatalog })
            }}
            error={error}
            InputProps={{
              readOnly: true,
            }}
          />
        </Stack>
      </CardContent>
    </Card>
  )
}

export default NftContractCard
