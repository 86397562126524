import { LinearProgress, TableCell, TableRow, Typography } from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import ListTable from "../../global/listTable"
import TableSpinner from "../../global/tableSpinner"
import { MainContext } from "../../../controllers/main"
import ListTopBar from "../../global/listTopBar"
import { TagsContext } from "../../../controllers/tags"
import TitleTableCell from "../../global/titleTableCell"
import { calculateTimeElapsed } from "../../../services/utilities/utility"
import SdgTarget from "../../../models/sdgTarget"
import CreateSdgTargetDialog from "./createSdgTargetDialog"
import { useNavigate } from "react-router-dom"
import ListFilters from "../../global/listFilters"
import { AutocompleteOption } from "../../../services/config/interfaces"
import TranslationsStack from "../../global/translationsStack"

const SdgTargetsListTab = () => {
  const { showPadding, setAnimation } = useContext(MainContext)
  const {
    sdgTargetsLoading,
    updatingSdgTargetsList,
    sdgTargetsList,
    getSdgTargetsList,
    sdgTargetsFilters,
    setSdgTargetsFilters,
  } = useContext(TagsContext)
  const navigate = useNavigate()

  // list background update
  const backgroundUpdate = () => {
    if (!sdgTargetsList.length) {
      getSdgTargetsList()
    } else {
      getSdgTargetsList(false)
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    backgroundUpdate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create action dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  return (
    <div style={{ position: "relative" }}>
      {updatingSdgTargetsList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 136,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <ListTopBar
        loading={sdgTargetsLoading}
        textFieldValue={""}
        // textFieldDisabled={updatingSdgTargetsList}
        textFieldDisabled
        textFieldPlaceholder="Search SDG Targets"
        addButtonLabel="Add SDG Target"
        textFieldOnChange={() => {}}
        textFieldOnKeyDown={() => {}}
        cancelButtonOnClick={() => {}}
        searchButtonOnClick={() => {}}
        addButtonOnClick={handleDialogChange}
      />
      <ListFilters
        disabled={updatingSdgTargetsList}
        filters={[
          {
            type: "language",
            value: sdgTargetsFilters.lang,
            setValue: (newValue: AutocompleteOption) => {
              sdgTargetsFilters.lang = newValue
              setSdgTargetsFilters({ ...sdgTargetsFilters })
            },
          },
        ]}
        style={{ paddingInline: 10 }}
      />
      {sdgTargetsLoading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 248px)"
              : "calc(100vh - 115px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 248px)"
              : "calc(100vh - 115px)"
          }
          headingItems={["Title & ID", "Code", "Translations", "Updated"]}
          tableBody={sdgTargetsList.map((item: SdgTarget) => (
            <TableRow
              key={item.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
              hover
              onClick={() => {
                setAnimation(false)
                setTimeout(() => {
                  navigate(`/tags/sdgtarget/${encodeURIComponent(item.id)}`)
                }, 250)
              }}
            >
              <TitleTableCell
                title={
                  item.translations.filter((item) => item.lang === "en")[0]
                    ? item.translations.filter((item) => item.lang === "en")[0]
                        .description
                    : item.translations[0].description
                }
                id={item.id}
                titleMaxWidth={380}
              />
              <TableCell>
                <Typography>{item.code}</Typography>
              </TableCell>
              <TableCell>
                <TranslationsStack translations={item.translations} />
              </TableCell>
              <TableCell style={{ minWidth: 120 }}>
                <Typography variant="caption">
                  {calculateTimeElapsed(item.updatedAt)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        />
      )}
      <CreateSdgTargetDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
      />
    </div>
  )
}

export default SdgTargetsListTab
