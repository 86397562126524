import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import { TagsContext } from "../../../controllers/tags"
import {
  Autocomplete,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { HexColorInput, HexColorPicker } from "react-colorful"
import "../../../styles/categoryGeneralEdit.scss"
import { renderOption, renderTags } from "../../../services/utilities/utility"

interface AutocompleteOption {
  id: string
  label: string
}

const CategoryGeneralEdit = ({
  errors,
}: {
  errors: { type: string; error: boolean }[]
}) => {
  const { showPadding } = useContext(MainContext)
  const {
    currentCategory,
    setCurrentCategory,
    editMode,
    mainCategoriesLoading,
    mainCategoriesList,
  } = useContext(TagsContext)

  // errors
  const nameError = errors.filter((item) => item.type === "name")[0].error
  const codeError = errors.filter((item) => item.type === "code")[0].error

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Text
        </Typography>
        <TextField
          label="Name"
          value={currentCategory.name}
          onChange={(e) => {
            currentCategory.name = e.target.value
            setCurrentCategory({ ...currentCategory })
          }}
          size="small"
          onBlur={() => {
            currentCategory.name = currentCategory.name.trim()
            setCurrentCategory({ ...currentCategory })
          }}
          disabled={!editMode}
          error={nameError}
          helperText={nameError ? "This field is required" : ""}
        />
        {/* <TextField
          label="Code"
          value={currentCategory.code}
          onChange={(e) => {
            currentCategory.code = e.target.value.toUpperCase()
            setCurrentCategory({ ...currentCategory })
          }}
          size="small"
          onBlur={() => {
            currentCategory.code = currentCategory.code.trim()
            setCurrentCategory({ ...currentCategory })
          }}
          placeholder="SHO"
          disabled={!editMode}
          error={codeError}
          helperText={codeError ? "This field is required" : ""}
        /> */}
        <Typography variant="h6" className="card-title">
          Colors
        </Typography>
        <Stack direction="row" spacing={3}>
          <Stack spacing={2} style={{ width: "33.3%" }}>
            <Typography>Back color</Typography>
            <HexColorPicker
              className="color-picker"
              color={currentCategory.backColor}
              onChange={(newColor) => {
                currentCategory.backColor = newColor.toUpperCase()
                setCurrentCategory({ ...currentCategory })
              }}
              style={{
                pointerEvents: !editMode ? "none" : "auto",
                opacity: !editMode ? 0.5 : 1,
                transition: "120ms",
              }}
            />
            <HexColorInput
              className={editMode ? "color-input" : "color-input-disabled"}
              color={currentCategory.backColor}
              onChange={(newColor) => {
                currentCategory.backColor = newColor.toUpperCase()
                setCurrentCategory({ ...currentCategory })
              }}
              prefixed
              disabled={!editMode}
            />
          </Stack>
          <Stack spacing={2} style={{ width: "33.3%" }}>
            <Typography>Back tag color</Typography>
            <HexColorPicker
              className="color-picker"
              color={currentCategory.backTagColor}
              onChange={(newColor) => {
                currentCategory.backTagColor = newColor.toUpperCase()
                setCurrentCategory({ ...currentCategory })
              }}
              style={{
                pointerEvents: !editMode ? "none" : "auto",
                opacity: !editMode ? 0.5 : 1,
                transition: "120ms",
              }}
            />
            <HexColorInput
              className={editMode ? "color-input" : "color-input-disabled"}
              color={currentCategory.backTagColor}
              onChange={(newColor) => {
                currentCategory.backTagColor = newColor.toUpperCase()
                setCurrentCategory({ ...currentCategory })
              }}
              prefixed
              disabled={!editMode}
            />
          </Stack>
          <Stack spacing={2} style={{ width: "33.3%" }}>
            <Typography>Fore color</Typography>
            <HexColorPicker
              className="color-picker"
              color={currentCategory.foreColor}
              onChange={(newColor) => {
                currentCategory.foreColor = newColor.toUpperCase()
                setCurrentCategory({ ...currentCategory })
              }}
              style={{
                pointerEvents: !editMode ? "none" : "auto",
                opacity: !editMode ? 0.5 : 1,
                transition: "120ms",
              }}
            />
            <HexColorInput
              className={editMode ? "color-input" : "color-input-disabled"}
              color={currentCategory.foreColor}
              onChange={(newColor) => {
                currentCategory.foreColor = newColor.toUpperCase()
                setCurrentCategory({ ...currentCategory })
              }}
              prefixed
              disabled={!editMode}
            />
          </Stack>
        </Stack>
        <Typography variant="h6" className="card-title">
          Main Category
        </Typography>
        <Autocomplete
          loading={mainCategoriesLoading}
          fullWidth
          blurOnSelect
          size="small"
          value={
            currentCategory.mainCategory
              ? {
                  id: currentCategory.mainCategory.id,
                  label: currentCategory.mainCategory.name,
                }
              : null
          }
          disablePortal
          isOptionEqualToValue={(
            option: AutocompleteOption,
            value: AutocompleteOption
          ) => option.id === value.id}
          options={mainCategoriesList.map((item) => {
            return {
              id: item.id,
              label: item.name,
            }
          })}
          renderInput={(params) => (
            <TextField {...params} label="Main Category" />
          )}
          onChange={(e: any, newValue: AutocompleteOption | null) => {
            if (newValue) {
              currentCategory.mainCategory = {
                id: newValue.id,
                name: newValue.label,
              }
            } else {
              currentCategory.mainCategory = null
            }
            setCurrentCategory({ ...currentCategory })
          }}
          renderOption={renderOption}
          renderTags={renderTags}
        />
      </Stack>
    </CardContent>
  )
}

export default CategoryGeneralEdit
