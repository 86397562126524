import { Search } from "@mui/icons-material"
import { InputAdornment, Stack, TextField } from "@mui/material"

const LeaguesListTopBar = () => {
  return (
    <Stack
      style={{
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 4,
      }}
      spacing={1}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          disabled
          size="small"
          fullWidth
          id="outlined-basic"
          placeholder="Search Leagues"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Stack>
  )
}

export default LeaguesListTopBar
