import { ChevronLeftRounded, InfoRounded } from "@mui/icons-material"
import {
  Box,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  List,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material"
import DrawerItems from "../../services/navigation/drawerItems"
import {
  isDev,
  useMockDataForEpisode,
  useMockDataForJourneysList,
  useMockDataForMediaList,
} from "../../services/config/constants"
import { useContext } from "react"
import { MainContext } from "../../controllers/main"
import awLogo from "../../assets/images/aw-logo.png"
import { styled } from "@mui/material/styles"

const drawerWidth: number = 210

const Drawer = styled(MuiDrawer)(() => ({
  "& .MuiDrawer-paper": {
    whiteSpace: "nowrap",
    width: drawerWidth,
  },
}))

const MobileDrawer = () => {
  const { drawerOpen, toggleDrawer } = useContext(MainContext)

  return (
    <Drawer open={drawerOpen} anchor="left" onClose={toggleDrawer}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <div style={{ width: "100%", height: 42 }}>
          <img style={{ width: 42, height: 42 }} src={awLogo} />
        </div>
        <IconButton
          onClick={() => {
            toggleDrawer()
          }}
        >
          <ChevronLeftRounded />
        </IconButton>
      </Toolbar>
      <Divider />
      <Box
        sx={{
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "column",
          height: "inherit",
        }}
      >
        <List
          component="nav"
          style={{
            maxHeight: "84vh",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
          className="horizontal-scroll"
        >
          <DrawerItems drawerOpen={drawerOpen} />
        </List>
        <Box>
          <Stack>
            <Stack style={{ marginLeft: 16, marginBottom: 10 }}>
              {useMockDataForEpisode || useMockDataForMediaList ? (
                <Typography
                  component="p"
                  variant="caption"
                  noWrap
                  color="grey"
                  textAlign="left"
                >
                  Using mock data for:
                </Typography>
              ) : null}
              {useMockDataForEpisode && (
                <Typography
                  component="p"
                  variant="caption"
                  noWrap
                  color="grey"
                  textAlign="left"
                >
                  • episode details
                </Typography>
              )}
              {useMockDataForJourneysList && (
                <Typography
                  component="p"
                  variant="caption"
                  noWrap
                  color="grey"
                  textAlign="left"
                >
                  • journeys list
                </Typography>
              )}
              {useMockDataForMediaList && (
                <Typography
                  component="p"
                  variant="caption"
                  noWrap
                  color="grey"
                  textAlign="left"
                >
                  • media list
                </Typography>
              )}
            </Stack>
            <Stack>
              <Stack
                direction="row"
                alignItems="center"
                spacing={drawerOpen ? 0.6 : 0.4}
              >
                <Typography
                  component="p"
                  variant="overline"
                  noWrap
                  color="grey"
                  textAlign="left"
                  style={{
                    marginBottom: -8,
                    fontWeight: 500,
                    marginLeft: 16,
                    fontSize: drawerOpen ? null : 11,
                    transition: "100ms",
                  }}
                >
                  {isDev
                    ? drawerOpen
                      ? "Development"
                      : "Dev"
                    : drawerOpen
                    ? "Production"
                    : "Prod"}
                </Typography>
                <Tooltip
                  title={
                    isDev
                      ? "None of the content you create in this panel will be visible inside AWorld app"
                      : "All the content you create and publish in this panel will be visible inside AWorld app"
                  }
                >
                  <InfoRounded
                    style={{
                      fontSize: drawerOpen ? 14 : 13,
                      color: "#747474",
                      marginTop: drawerOpen ? 6 : 7,
                      cursor: "pointer",
                      transition: "100ms",
                    }}
                  />
                </Tooltip>
              </Stack>
              <Typography
                component="p"
                variant="caption"
                noWrap
                gutterBottom
                color="grey"
                textAlign="left"
                style={{
                  marginLeft: 16,
                }}
              >
                {drawerOpen
                  ? `Solar Client v${process.env.REACT_APP_VERSION}`
                  : `v${process.env.REACT_APP_VERSION}`}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Drawer>
  )
}

export default MobileDrawer
