import { LoadingButton } from "@mui/lab"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd"

const ListTableDragDrop = ({
  headingItems,
  tableBody,
  height = "calc(100vh - 200px)",
  showLoadMoreButton = false,
  loadMoreButtonOnClick,
  loadMoreButtonLoading,
  onDragEnd,
}: {
  headingItems: string[]
  tableBody: any
  height?: string
  showLoadMoreButton?: boolean
  loadMoreButtonOnClick?: () => void
  loadMoreButtonLoading?: boolean
  onDragEnd: (result: DropResult) => void
}) => {
  return (
    <TableContainer sx={{ height: height }}>
      <Table
        sx={{ minWidth: 650 }}
        aria-label="sticky table"
        stickyHeader
        size="small"
      >
        {showLoadMoreButton && (
          <caption style={{ marginTop: -10 }}>
            <LoadingButton
              variant="outlined"
              fullWidth
              onClick={loadMoreButtonOnClick}
              loading={loadMoreButtonLoading}
            >
              Load more
            </LoadingButton>
          </caption>
        )}
        <TableHead>
          <TableRow>
            {headingItems.map((item: string) => (
              <TableCell key={item}>{item}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="column-1">
            {(provided) => (
              <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                {tableBody}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  )
}

export default ListTableDragDrop
