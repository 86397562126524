import { LoadingButton } from "@mui/lab"
import {
  Button,
  CardActions,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Dialog,
  MenuItem,
  Select,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  DialogTransition,
  capitalizeFirstCharacter,
  enumAsArray,
} from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import { useNavigate } from "react-router-dom"
import { MainContext } from "../../controllers/main"
import {
  MissionDifficultyLevel,
  MissionSpecialType,
  MissionTimeframe,
  MissionType,
} from "../../services/config/enum"
import { LanguagesContext } from "../../controllers/languages"
import Team from "../../models/team"
import { isDev } from "../../services/config/constants"
import { CloseRounded, OpenInNewRounded } from "@mui/icons-material"
import { TeamsContext } from "../../controllers/teams"
import TeamsList from "../../views/team/teamsList"
import { MissionsContext } from "../../controllers/missions"

const CreateMissionDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
}) => {
  const { setAnimation } = useContext(MainContext)
  const { loading, setLoading, createMission, createMissionDocument } =
    useContext(MissionsContext)
  const { languages, languagesForEpisodeTabs } = useContext(LanguagesContext)
  const { resetTeamsFilters } = useContext(TeamsContext)
  const navigate = useNavigate()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // configuration
  const [handle, setHandle] = useState<string>("")
  const [projectId, setProjectId] = useState<string>("")
  const [type, setType] = useState<MissionType>(MissionType.episode)
  const [specialType, setSpecialType] = useState<MissionSpecialType | null>(
    null
  )
  const [timeframe, setTimeframe] = useState<MissionTimeframe>(
    MissionTimeframe.DAILY
  )
  const [difficultyLevel, setDifficultyLevel] =
    useState<MissionDifficultyLevel>(MissionDifficultyLevel.easy)
  const [target, setTarget] = useState<number>(1)
  const [team, setTeam] = useState<Team | null>(null)

  // change team dialog
  const [changeTeamDialogOpen, setChangeTeamDialogOpen] =
    useState<boolean>(false)
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)

  // document
  const [defaultTranslation, setDefaultTranslation] = useState<string>("")
  const [title, setTitle] = useState<string>("")

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  // slides
  const slides = [
    {
      label: "Configuration",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Configuration
          </Typography>
          <Stack direction="row" gap={2}>
            <TextField
              fullWidth
              size="small"
              label="Handle"
              value={handle}
              onChange={(e) => {
                setHandle(e.target.value)
              }}
            />
            <TextField
              fullWidth
              size="small"
              label="Project ID"
              value={projectId}
              onChange={(e) => {
                setProjectId(e.target.value)
              }}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="type">Type</InputLabel>
              <Select
                labelId="type"
                label="Type"
                value={type}
                onChange={(e) => {
                  setType(e.target.value as MissionType)
                }}
              >
                {enumAsArray(MissionType).map((item: string, index) => (
                  <MenuItem key={index} value={item}>
                    {capitalizeFirstCharacter(item)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="special-type">Special type</InputLabel>
              <Select
                labelId="special-type"
                label="Special type"
                value={specialType ?? "none"}
                onChange={(e) => {
                  if (e.target.value === "none") {
                    setSpecialType(null)
                  } else {
                    setSpecialType(e.target.value as MissionSpecialType)
                  }
                }}
              >
                <MenuItem value="none">None</MenuItem>
                {enumAsArray(MissionSpecialType).map((item: string, index) => (
                  <MenuItem key={index} value={item}>
                    {capitalizeFirstCharacter(item)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="timeframe">Timeframe</InputLabel>
              <Select
                labelId="timeframe"
                label="Timeframe"
                value={timeframe}
                onChange={(e) => {
                  setTimeframe(e.target.value as MissionTimeframe)
                }}
              >
                {enumAsArray(MissionTimeframe).map((item: string, index) => (
                  <MenuItem key={index} value={item}>
                    {capitalizeFirstCharacter(item.toLowerCase())}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" gap={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="difficulty-level">Difficulty level</InputLabel>
              <Select
                labelId="difficulty-level"
                label="Difficulty level"
                value={difficultyLevel}
                onChange={(e) => {
                  setDifficultyLevel(e.target.value as MissionDifficultyLevel)
                }}
              >
                {enumAsArray(MissionDifficultyLevel).map(
                  (item: string, index) => (
                    <MenuItem key={index} value={item}>
                      {capitalizeFirstCharacter(item)}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              size="small"
              label="Target"
              type="number"
              value={target ?? ""}
              onChange={(e) => {
                if (e.target.value) {
                  setTarget(parseFloat(e.target.value))
                } else {
                  setTarget(null)
                }
              }}
              error={!target || !Number.isInteger(target) || target <= 0}
              helperText={
                !target || !Number.isInteger(target) || target <= 0
                  ? "Invalid value"
                  : ""
              }
            />
          </Stack>
          <TextField
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Stack direction="row" spacing={0}>
                  {team ? (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        if (isDev) {
                          window.open(
                            window.location.protocol +
                              "//" +
                              window.location.host +
                              "/teams/" +
                              team.id
                          )
                        } else {
                          window.open(
                            window.location.protocol +
                              "//" +
                              window.location.host +
                              "/teams/" +
                              team.id
                          )
                        }
                      }}
                    >
                      <OpenInNewRounded
                        style={{
                          fontSize: 15,
                          opacity: 0.9,
                        }}
                      />
                    </Button>
                  ) : null}
                  <Button
                    sx={{ paddingInline: 2 }}
                    onClick={() => {
                      setChangeTeamDialogOpen(true)
                    }}
                  >
                    Change
                  </Button>
                  {team ? (
                    <Button
                      sx={{ paddingInline: 2 }}
                      onClick={() => {
                        setTeam(null)
                      }}
                    >
                      Reset
                    </Button>
                  ) : null}
                </Stack>
              ),
            }}
            fullWidth
            label="Team"
            size="small"
            value={
              team
                ? team.document.items.find((item) => item.isDefault).title
                : "Global (App)"
            }
          />
        </Stack>
      ),
    },
    {
      label: "Language",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Default language
          </Typography>
          <FormControl fullWidth size="small">
            <InputLabel id="language-select">Default language</InputLabel>
            <Select
              labelId="language-select"
              label="Default language"
              value={defaultTranslation}
              onChange={(e) => {
                setDefaultTranslation(e.target.value)
              }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {lang.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      ),
    },
    {
      label: "Details",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Details in{" "}
            {defaultTranslation.length
              ? languagesForEpisodeTabs.filter(
                  (item) => item.value === defaultTranslation
                )[0].label
              : ""}
          </Typography>
          <TextField
            size="small"
            label="Title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
            onBlur={() => {
              if (title) {
                setTitle((current) => current.trim())
              }
            }}
          />
        </Stack>
      ),
    },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setHandle("")
        setProjectId("")
        setType(MissionType.episode)
        setSpecialType(null)
        setTimeframe(MissionTimeframe.DAILY)
        setDifficultyLevel(MissionDifficultyLevel.easy)
        setTarget(1)
        setTeam(null)
        setDefaultTranslation("")
        setTitle("")
        setCurrentSlide(0)
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (handle || projectId || team) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [handle, projectId, team])

  // create mission
  const createNewMission = async () => {
    setLoading(true)

    const input: {
      handle: string
      projectId?: string
      target: number
      type: MissionType
      timeframe: MissionTimeframe
      difficultyLevel: MissionDifficultyLevel
      specialType?: MissionSpecialType
      teamId?: string
    } = {
      handle: handle,
      target: target,
      type: type,
      timeframe: timeframe,
      difficultyLevel: difficultyLevel,
    }

    if (projectId) {
      input.projectId = projectId
    }
    if (specialType) {
      input.specialType = specialType
    }
    if (team) {
      input.teamId = team.id
    }

    const result = await createMission(input)

    if (result !== false) {
      const newMissionId = result as string

      const documentInput: {
        parentId: string
        type: string
        missionCatalogDocumentItems: {
          lang: string
          title: string
          default: boolean
        }[]
      } = {
        parentId: `#id#${newMissionId}#stage#DRAFT`,
        type: "MissionCatalog",
        missionCatalogDocumentItems: [
          {
            lang: defaultTranslation,
            title: title,
            default: true,
          },
        ],
      }

      await createMissionDocument(documentInput)

      setDialogOpen(false)
      setAnimation(false)
      setTimeout(() => {
        navigate(`/missions/${newMissionId}`)
      }, 250)
    } else {
      setLoading(false)
    }
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText="Add Mission"
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            Add Mission
          </Typography>
          <Stepper
            activeStep={currentSlide}
            style={{
              paddingTop: 24,
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 8,
              maxWidth: "100%",
              overflow: "overlay",
            }}
          >
            {slides.map((slide) => {
              return (
                <Step key={slide.label}>
                  <StepLabel>{slide.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (!slides[currentSlide - 1]) {
                    if (showDiscardDialog) {
                      setDiscardDialogOpen(true)
                    } else {
                      handleDialogChange(e)
                    }
                  } else {
                    setCurrentSlide(currentSlide - 1)
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  (currentSlide === 0 && !handle) ||
                  (currentSlide === 0 &&
                    (!target || !Number.isInteger(target) || target <= 0)) ||
                  (currentSlide === 1 && !defaultTranslation) ||
                  (currentSlide === 2 && !title)
                }
                onMouseDown={
                  !slides[currentSlide + 1]
                    ? () => {
                        createNewMission()
                      }
                    : () => {
                        setCurrentSlide(currentSlide + 1)
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Save" : "Next"}
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={loading} />
      {/* change team dialog */}
      <Dialog
        fullScreen
        open={changeTeamDialogOpen}
        onClose={() => {
          setChangeTeamDialogOpen(false)
          setSelectedTeam(null)
          resetTeamsFilters()
        }}
        TransitionComponent={DialogTransition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Team
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                setTeam(selectedTeam)

                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              disabled={!selectedTeam}
            >
              Select
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ backgroundColor: "#f5f5f5" }}>
          <TeamsList
            forDialog
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
          />
        </Paper>
      </Dialog>
    </Dialog>
  )
}

export default CreateMissionDialog
