import { LoadingButton } from "@mui/lab"
import {
  Button,
  CardActions,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Dialog,
  MenuItem,
  Select,
  AppBar,
  Toolbar,
  IconButton,
  Card,
  Tooltip,
  Chip,
  Autocomplete,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  DialogTransition,
  isValidURL,
  lowercaseFirstCharacter,
  renderOption,
  renderTags,
  stripHtml,
  testYtURL,
} from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import { useNavigate } from "react-router-dom"
import { MainContext } from "../../controllers/main"
import { ActionGroupsContext } from "../../controllers/actionGroups"
import ImagePicker from "../global/imagePicker"
import { BodySliceType } from "../../services/config/enum"
import Action from "../../models/action"
import {
  AddCircleRounded,
  CloseRounded,
  DeleteRounded,
} from "@mui/icons-material"
import ActionsList from "../../views/action/actionsList"
import { LanguagesContext } from "../../controllers/languages"
import { ActionsContext } from "../../controllers/actions"
import EmbedSlice from "../../models/bodySlices/embedSlice"
import ImageSlice from "../../models/bodySlices/imageSlice"
import QuoteSlice from "../../models/bodySlices/quoteSlice"
import TextSlice from "../../models/bodySlices/textSlice"
import BlockEditor from "../blockEditor/blockEditor"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import BadgePicker from "../global/badgePicker"
import Badge from "../../models/badge"
import { TagsContext } from "../../controllers/tags"
import Sdg from "../../models/sdg"
import SdgTarget from "../../models/sdgTarget"
import ActionGroup from "../../models/actionGroup"
import { FootprintQuestionsContext } from "../../controllers/footprintQuestions"

interface AutocompleteOption {
  id: string
  label: string
}

const CreateActionGroupDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
  topBarText,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
  topBarText: string
}) => {
  const { setAnimation } = useContext(MainContext)
  const {
    loading,
    setLoading,
    createActionGroup,
    createActionGroupDocument,
    upsertFootprintQuestion,
  } = useContext(ActionGroupsContext)
  const {
    categoriesLoading,
    categoriesList,
    sdgsList,
    sdgTargetsList,
    sdgsLoading,
    sdgTargetsLoading,
  } = useContext(TagsContext)
  const { resetFilters, setActionsList } = useContext(ActionsContext)
  const { languages, languagesForEpisodeTabs } = useContext(LanguagesContext)
  const { footprintQuestionsLoading, footprintQuestionsList } = useContext(
    FootprintQuestionsContext
  )
  const navigate = useNavigate()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // configuration
  const [canBecomeHabit, setCanBecomeHabit] = useState<boolean>(true)
  const [handle, setHandle] = useState<string>("")
  const [maxIterations, setMaxIterations] = useState<number | null>(1)
  const [question, setQuestion] = useState<AutocompleteOption | null>(null)
  const [category, setCategory] = useState<AutocompleteOption | null>(null)
  const [featured, setFeatured] = useState<boolean>(false)
  const [footprintPrediction, setFootprintPrediction] = useState<number | null>(
    0
  )
  const [footprintPredictionError, setFootprintPredictionError] =
    useState<boolean>(false)

  // image
  const [image, setImage] = useState<string>("")

  // badge
  const [badge, setBadge] = useState<Badge | null>(null)

  // actions
  const [actions, setActions] = useState<Action[]>([])
  const [selectedActions, setSelectedActions] = useState<Action[]>([])
  const [actionsToFilter, setActionsToFilter] = useState<Action[]>([])
  const [addActionsDialogOpen, setAddActionsDialogOpen] =
    useState<boolean>(false)

  // sdgs
  const [primarySdg, setPrimarySdg] = useState<AutocompleteOption | null>(null)
  const [secondarySdgs, setSecondarySdgs] = useState<AutocompleteOption[]>([])
  const [secondarySdgsError, setSecondarySdgsError] = useState<boolean>(false)

  // sdg targets
  const [primarySdgTarget, setPrimarySdgTarget] =
    useState<AutocompleteOption | null>(null)
  const [secondarySdgTargets, setSecondarySdgTargets] = useState<
    AutocompleteOption[]
  >([])
  const [secondarySdgTargetsError, setSecondarySdgTargetsError] =
    useState<boolean>(false)

  // document
  const [defaultTranslation, setDefaultTranslation] = useState<string>("")
  const [title, setTitle] = useState<string>("")
  const [benchmark, setBenchmark] = useState<string>("")
  const [body, setBody] = useState<
    (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
  >([])

  // check errors in body
  const [bodyError, setBodyError] = useState<boolean>(false)

  useEffect(() => {
    let errorInBody = false
    body.forEach((bodyItem) => {
      if (
        (bodyItem.sliceType === BodySliceType.BodyTextSlice &&
          !stripHtml(bodyItem.text).length) ||
        (bodyItem.sliceType === BodySliceType.BodyQuoteSlice &&
          !bodyItem.quoteText.length) ||
        (bodyItem.sliceType === BodySliceType.BodyImageSlice &&
          (!bodyItem.imgUrl.length || !isValidURL(bodyItem.imgUrl))) ||
        (bodyItem.sliceType === BodySliceType.BodyEmbedSlice &&
          (!bodyItem.embedUrl.length || !testYtURL(bodyItem.embedUrl)))
      ) {
        errorInBody = true
      }
    })
    if (errorInBody) {
      setBodyError(true)
    } else {
      setBodyError(false)
    }
  }, [body])

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  // slides
  const slides = [
    {
      label: "Configuration",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Configuration
          </Typography>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              size="small"
              label="Handle"
              value={handle}
              onChange={(e) => {
                setHandle(e.target.value)
              }}
              onBlur={() => {
                if (handle) {
                  setHandle((current) => current.trim())
                }
              }}
            />
            <TextField
              fullWidth
              size="small"
              type="number"
              label="Maximum iterations"
              value={maxIterations ?? ""}
              onChange={(e) => {
                if (e.target.value.length) {
                  if (
                    e.target.value.includes(".") ||
                    e.target.value.includes(",")
                  ) {
                    setMaxIterations(parseFloat(e.target.value))
                  } else {
                    setMaxIterations(parseInt(e.target.value))
                  }
                } else {
                  setMaxIterations(null)
                }
              }}
              error={
                maxIterations === null ||
                (maxIterations !== null && maxIterations < 1) ||
                (maxIterations !== null &&
                  maxIterations.toString().includes("."))
              }
              helperText={
                maxIterations === null ||
                (maxIterations !== null && maxIterations < 1) ||
                (maxIterations !== null &&
                  maxIterations.toString().includes("."))
                  ? "Invalid value"
                  : ""
              }
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="become-habit-select">Can become habit</InputLabel>
              <Select
                labelId="become-habit-select"
                label="Can become habit"
                value={canBecomeHabit ? "yes" : "no"}
                onChange={(e) => {
                  if (e.target.value === "yes") {
                    setCanBecomeHabit(true)
                  } else {
                    setCanBecomeHabit(false)
                  }
                }}
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="Featured">Featured</InputLabel>
              <Select
                labelId="Featured"
                label="Featured"
                value={featured ? "yes" : "no"}
                onChange={(e) => {
                  if (e.target.value === "yes") {
                    setFeatured(true)
                  } else {
                    setFeatured(false)
                  }
                }}
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              size="small"
              type="number"
              label="Footprint prediction"
              value={footprintPrediction ?? ""}
              onChange={(e) => {
                if (e.target.value.length) {
                  if (
                    e.target.value.includes(".") ||
                    e.target.value.includes(",")
                  ) {
                    setFootprintPrediction(parseFloat(e.target.value))
                    if (parseFloat(e.target.value) > 0) {
                      setFootprintPredictionError(true)
                    } else {
                      setFootprintPredictionError(false)
                    }
                  } else {
                    setFootprintPrediction(parseInt(e.target.value))
                    if (parseInt(e.target.value) > 0) {
                      setFootprintPredictionError(true)
                    } else {
                      setFootprintPredictionError(false)
                    }
                  }
                } else {
                  setFootprintPrediction(null)
                  setFootprintPredictionError(true)
                }
              }}
              error={footprintPredictionError}
              helperText={footprintPredictionError ? "Invalid value" : ""}
              FormHelperTextProps={{
                style: {
                  margin: 0,
                  marginBottom: -25,
                },
              }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <Autocomplete
              loading={categoriesLoading}
              fullWidth
              blurOnSelect
              size="small"
              value={category}
              disablePortal
              isOptionEqualToValue={(
                option: AutocompleteOption,
                value: AutocompleteOption
              ) => option.id === value.id}
              options={categoriesList.map((item) => {
                return {
                  id: item.id,
                  label: item.name,
                }
              })}
              renderInput={(params) => (
                <TextField {...params} label="Category" />
              )}
              onChange={(e: any, newValue: AutocompleteOption | null) => {
                setCategory(newValue)
                setActions([])
                setBadge(null)
              }}
              renderOption={renderOption}
              renderTags={renderTags}
            />
            <Autocomplete
              fullWidth
              size="small"
              loading={footprintQuestionsLoading}
              value={question}
              disablePortal
              isOptionEqualToValue={(
                option: AutocompleteOption,
                value: AutocompleteOption
              ) => option.id === value.id}
              options={
                footprintQuestionsList.length
                  ? footprintQuestionsList.map((item) => {
                      return {
                        id: item.sk,
                        label: item.description,
                      }
                    })
                  : []
              }
              renderInput={(params) => (
                <TextField {...params} label="Footprint question" />
              )}
              renderOption={(props, option) => (
                <span
                  {...props}
                  key={option.id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography style={{ fontSize: 14 }}>
                    {option.label}
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>{option.id}</Typography>
                </span>
              )}
              onChange={(e: any, newValue: AutocompleteOption | null) => {
                setQuestion(newValue)
              }}
            />
          </Stack>
        </Stack>
      ),
    },
    {
      label: "Image",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Image
          </Typography>
          <ImagePicker
            title="Image"
            pickedImage={image}
            setPickedImage={(selectedImage: {
              url: string
              id: string
              description?: string
            }) => {
              setImage(selectedImage.url)
            }}
            filters={{ category: "actionGroup" }}
            disableCategoryFilter
            height={200}
          />
        </Stack>
      ),
    },
    {
      label: "Badge",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Badge
          </Typography>
          <BadgePicker
            title="Badge"
            pickedBadge={badge}
            setPickedBadge={(selectedBadge: Badge) => {
              setBadge(selectedBadge)
            }}
            height={200}
            categoryFilter={category}
          />
        </Stack>
      ),
    },
    {
      label: "Actions",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Actions
          </Typography>
          <Stack
            spacing={2}
            style={{
              maxHeight: 260,
              overflowY: "scroll",
              overflowX: "hidden",
              marginTop: 14,
              paddingTop: 2,
            }}
          >
            {actions.map((action, index) => (
              <Card
                variant="outlined"
                key={action.id}
                style={{ minHeight: 65 }}
              >
                <CardContent
                  style={{
                    overflow: "hidden",
                    height: 65,
                    paddingTop: 0,
                    paddingBottom: 0,
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={5}>
                    <div
                      style={{
                        width: 42,
                        height: 42,
                        borderRadius: 4,
                        backgroundImage: `url(${
                          action.image ?? noImagePlaceholder
                        })`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    />
                    <Tooltip
                      title={
                        action.document.items.filter(
                          (item) => item.isDefault
                        )[0].title
                      }
                    >
                      <Typography
                        style={{
                          maxWidth: 210,
                          width: 210,
                          minWidth: 44,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          cursor: "default",
                        }}
                      >
                        {
                          action.document.items.filter(
                            (item) => item.isDefault
                          )[0].title
                        }
                      </Typography>
                    </Tooltip>
                    <Stack direction="row" spacing={1}>
                      {languages.map((lang: string) => (
                        <Chip
                          key={lang}
                          color={
                            action.document.items.filter(
                              (documentItem) => documentItem.lang === lang
                            )[0]
                              ? "success"
                              : "default"
                          }
                          label={lang.toUpperCase()}
                          size="small"
                          style={{ width: 34 }}
                        />
                      ))}
                    </Stack>
                    <Chip
                      size="small"
                      label={
                        action.isRepeatable ? "Repeatable" : "Not repeatable"
                      }
                      color={action.isRepeatable ? "success" : "warning"}
                    />
                  </Stack>
                  <IconButton
                    style={{
                      position: "absolute",
                      right: 18,
                      transition: "100ms",
                      zIndex: 1,
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      actions.splice(index, 1)
                      setActions([...actions])
                    }}
                    color="error"
                  >
                    <DeleteRounded style={{ width: 22 }} />
                  </IconButton>
                </CardContent>
              </Card>
            ))}
            <Button
              startIcon={<AddCircleRounded />}
              style={{
                maxHeight: 40,
                minHeight: 40,
                minWidth: "100%",
                marginRight: 1,
                marginTop: !actions.length ? -2 : 16,
              }}
              variant="outlined"
              onClick={() => {
                setActionsToFilter(actions)
                setActionsList([])
                setAddActionsDialogOpen(true)
              }}
            >
              Add Actions
            </Button>
          </Stack>
        </Stack>
      ),
    },
    {
      label: "Tags",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Topics, SDGs and SDG targets
          </Typography>
          <Stack direction="row" spacing={2}>
            <Autocomplete
              fullWidth
              loading={sdgsLoading}
              getOptionDisabled={(option: AutocompleteOption) => {
                if (
                  secondarySdgs.filter(
                    (sdg: AutocompleteOption) => sdg.id === option.id
                  )[0]
                ) {
                  return true
                } else {
                  return false
                }
              }}
              size="small"
              value={primarySdg}
              disablePortal
              isOptionEqualToValue={(
                option: AutocompleteOption,
                value: AutocompleteOption
              ) => option.id === value.id}
              options={
                sdgsList.length
                  ? sdgsList.map((sdg: Sdg) => {
                      let idForLabel =
                        sdg.id.charAt(4) === "0"
                          ? sdg.id.slice(5) + ". "
                          : sdg.id.slice(4) + ". "

                      return { label: idForLabel + sdg.name, id: sdg.id }
                    })
                  : []
              }
              renderInput={(params) => (
                <TextField {...params} label="Primary SDG" />
              )}
              onChange={(e: any, newValue: AutocompleteOption | null) => {
                setPrimarySdg(newValue)
              }}
              renderOption={renderOption}
              renderTags={renderTags}
            />
            <Autocomplete
              fullWidth
              loading={sdgsLoading}
              getOptionDisabled={(option: AutocompleteOption) =>
                option.id === primarySdg?.id
              }
              onBlur={() => {
                setSecondarySdgsError(false)
              }}
              multiple
              size="small"
              value={secondarySdgs}
              disablePortal
              isOptionEqualToValue={(
                option: AutocompleteOption,
                value: AutocompleteOption
              ) => option.id === value.id}
              options={
                sdgsList.length
                  ? sdgsList.map((sdg: Sdg) => {
                      let idForLabel =
                        sdg.id.charAt(4) === "0"
                          ? sdg.id.slice(5) + ". "
                          : sdg.id.slice(4) + ". "

                      return { label: idForLabel + sdg.name, id: sdg.id }
                    })
                  : []
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Secondary SDGs"
                  error={secondarySdgsError}
                  helperText={
                    secondarySdgsError
                      ? "The maximum number of secondary SDGs is 3"
                      : null
                  }
                />
              )}
              onChange={(e: any, newValues: AutocompleteOption[]) => {
                if (newValues.length < 4) {
                  setSecondarySdgs(newValues)
                  setSecondarySdgsError(false)
                } else {
                  setSecondarySdgsError(true)
                }
              }}
              renderOption={renderOption}
              renderTags={renderTags}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <Autocomplete
              fullWidth
              loading={sdgTargetsLoading}
              getOptionDisabled={(option: AutocompleteOption) => {
                if (
                  secondarySdgTargets.filter(
                    (sdgTarget: AutocompleteOption) =>
                      sdgTarget.id === option.id
                  )[0]
                ) {
                  return true
                } else {
                  return false
                }
              }}
              size="small"
              value={primarySdgTarget}
              disablePortal
              isOptionEqualToValue={(
                option: AutocompleteOption,
                value: AutocompleteOption
              ) => option.id === value.id}
              options={
                sdgTargetsList.length
                  ? sdgTargetsList.map((sdgTarget: SdgTarget) => {
                      return { label: sdgTarget.id.slice(4), id: sdgTarget.id }
                    })
                  : []
              }
              renderInput={(params) => (
                <TextField {...params} label="Primary SDG target" />
              )}
              onChange={(e: any, newValue: AutocompleteOption | null) => {
                setPrimarySdgTarget(newValue)
              }}
              renderOption={renderOption}
              renderTags={renderTags}
            />
            <Autocomplete
              fullWidth
              loading={sdgTargetsLoading}
              getOptionDisabled={(option: AutocompleteOption) =>
                option.id === primarySdgTarget?.id
              }
              multiple
              size="small"
              onBlur={() => {
                setSecondarySdgTargetsError(false)
              }}
              value={secondarySdgTargets}
              disablePortal
              isOptionEqualToValue={(
                option: AutocompleteOption,
                value: AutocompleteOption
              ) => option.id === value.id}
              options={
                sdgTargetsList.length
                  ? sdgTargetsList.map((sdgTarget: SdgTarget) => {
                      return { label: sdgTarget.id.slice(4), id: sdgTarget.id }
                    })
                  : []
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Secondary SDG targets"
                  error={secondarySdgTargetsError}
                  helperText={
                    secondarySdgTargetsError
                      ? "The maximum number of secondary SDG targets is 3"
                      : null
                  }
                />
              )}
              onChange={(e: any, newValues: AutocompleteOption[]) => {
                if (newValues.length < 4) {
                  setSecondarySdgTargets(newValues)
                  setSecondarySdgTargetsError(false)
                } else {
                  setSecondarySdgTargetsError(true)
                }
              }}
              renderOption={renderOption}
              renderTags={renderTags}
            />
          </Stack>
        </Stack>
      ),
    },
    {
      label: "Language",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Default language
          </Typography>
          <FormControl fullWidth size="small">
            <InputLabel id="language-select">Default language</InputLabel>
            <Select
              labelId="language-select"
              label="Default language"
              value={defaultTranslation}
              onChange={(e) => {
                setDefaultTranslation(e.target.value)
              }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {lang.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      ),
    },
    {
      label: "Details",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Details in{" "}
            {defaultTranslation.length
              ? languagesForEpisodeTabs.filter(
                  (item) => item.value === defaultTranslation
                )[0].label
              : ""}
          </Typography>
          <TextField
            size="small"
            label="Title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
            onBlur={() => {
              if (title) {
                setTitle((current) => current.trim())
              }
            }}
          />
          <TextField
            size="small"
            label="Benchmark"
            value={benchmark}
            onChange={(e) => {
              setBenchmark(e.target.value)
            }}
            placeholder="Un volo andata e ritorno da Milano a Parigi"
            onBlur={() => {
              if (benchmark) {
                setBenchmark((current) => current.trim())
              }
            }}
          />
        </Stack>
      ),
    },
    {
      label: "Body",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Body in{" "}
            {defaultTranslation.length
              ? languagesForEpisodeTabs.filter(
                  (item) => item.value === defaultTranslation
                )[0].label
              : ""}
          </Typography>
          <div
            style={{
              height: 260,
              maxHeight: 260,
              overflowY: "scroll",
            }}
          >
            <BlockEditor
              body={body}
              setBody={(
                body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
              ) => {
                setBody([...body])
              }}
            />
          </div>
        </Stack>
      ),
    },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setCanBecomeHabit(true)
        setHandle("")
        setMaxIterations(1)
        setQuestion(null)
        setCategory(null)
        setFeatured(false)
        setFootprintPrediction(0)
        setImage("")
        setBadge(null)
        setActions([])
        setPrimarySdg(null)
        setSecondarySdgs([])
        setSecondarySdgsError(false)
        setPrimarySdgTarget(null)
        setSecondarySdgTargets([])
        setSecondarySdgTargetsError(false)
        setDefaultTranslation("")
        setTitle("")
        setBenchmark("")
        setBody([])
        setCurrentSlide(0)
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (handle.length || question || category) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [handle, question, category])

  // create action group
  const createNewActionGroup = async () => {
    setLoading(true)

    const input: {
      actions: { id: string }[]
      canBecomeHabit: boolean
      handle: string
      image: string
      maxIterations: number
      category: {
        id: string
      }
      sdgs: { primary: boolean; id: string }[]
      sdgTargets: { primary: boolean; id: string }[]
      featured: boolean
      footprintPrediction: number
      badge?: {
        id: string
      }
    } = {
      actions: [
        ...actions.map((action) => {
          return {
            id: action.id,
          }
        }),
      ],
      canBecomeHabit: canBecomeHabit,
      handle: handle,
      image: image,
      maxIterations: maxIterations,
      category: {
        id: category.id,
      },
      sdgs: [
        ...secondarySdgs.map((sdg) => {
          return { id: sdg.id, primary: false }
        }),
      ],
      sdgTargets: [
        ...secondarySdgTargets.map((sdgTarget) => {
          return { id: sdgTarget.id, primary: false }
        }),
      ],
      featured: featured,
      footprintPrediction: footprintPrediction,
    }

    if (badge) {
      input.badge = {
        id: badge.id,
      }
    }
    if (primarySdg) {
      input.sdgs.unshift({ id: primarySdg.id, primary: true })
    }
    if (primarySdgTarget) {
      input.sdgTargets.unshift({ id: primarySdgTarget.id, primary: true })
    }

    const result = await createActionGroup(input)

    if (result !== false) {
      const newActionGroup = result as ActionGroup

      if (question) {
        upsertFootprintQuestion(newActionGroup.id, question.id)
      }

      let bodyToSet = []
      const newBody = []
      body.forEach((bodyItem: any) => {
        const { sliceType, ...rest } = bodyItem
        newBody.push({
          [lowercaseFirstCharacter(bodyItem.sliceType)]: {
            ...rest,
          },
        })
      })
      bodyToSet = newBody

      const documentInput: {
        parentId: string
        type: string
        actionGroupDocumentItems: {
          lang: string
          title: string
          default: boolean
          body: object[]
          benchmark?: string
        }[]
      } = {
        parentId: newActionGroup.document.parentId,
        type: "ActionGroup",
        actionGroupDocumentItems: [
          {
            lang: defaultTranslation,
            title: title,
            default: true,
            body: bodyToSet,
          },
        ],
      }

      if (benchmark) {
        documentInput.actionGroupDocumentItems[0].benchmark = benchmark
      }

      await createActionGroupDocument(documentInput)

      setDialogOpen(false)
      setAnimation(false)
      setTimeout(() => {
        navigate(`/series/${newActionGroup.id}`)
      }, 250)
    } else {
      setLoading(false)
    }
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText={topBarText}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            {topBarText}
          </Typography>
          <Stepper
            activeStep={currentSlide}
            style={{
              paddingTop: 24,
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 8,
              maxWidth: "100%",
              overflow: "overlay",
            }}
          >
            {slides.map((slide) => {
              return (
                <Step key={slide.label}>
                  <StepLabel>{slide.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (!slides[currentSlide - 1]) {
                    if (showDiscardDialog) {
                      setDiscardDialogOpen(true)
                    } else {
                      handleDialogChange(e)
                    }
                  } else {
                    setCurrentSlide(currentSlide - 1)
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  (currentSlide === 0 && !handle.length) ||
                  (currentSlide === 0 && maxIterations === null) ||
                  (currentSlide === 0 && !category) ||
                  (currentSlide === 0 && footprintPredictionError) ||
                  (currentSlide === 0 &&
                    maxIterations !== null &&
                    maxIterations < 1) ||
                  (currentSlide === 0 &&
                    maxIterations !== null &&
                    maxIterations.toString().includes(".")) ||
                  (currentSlide === 1 && !image) ||
                  (currentSlide === 2 && !badge) ||
                  (currentSlide === 3 && !actions.length) ||
                  (currentSlide === 4 && secondarySdgsError) ||
                  (currentSlide === 4 && secondarySdgTargetsError) ||
                  (currentSlide === 5 && !defaultTranslation) ||
                  (currentSlide === 6 && !title) ||
                  (currentSlide === 7 && bodyError)
                }
                onMouseDown={
                  !slides[currentSlide + 1]
                    ? () => {
                        createNewActionGroup()
                      }
                    : () => {
                        setCurrentSlide(currentSlide + 1)
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Save" : "Next"}
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* add actions dialog */}
      <Dialog
        fullScreen
        open={addActionsDialogOpen}
        onClose={() => {
          setAddActionsDialogOpen(false)
          setSelectedActions([])
          resetFilters()
        }}
        TransitionComponent={DialogTransition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setAddActionsDialogOpen(false)
                setSelectedActions([])
                resetFilters()
              }}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Actions
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                setActions([...actions, ...selectedActions])
                setAddActionsDialogOpen(false)
                setSelectedActions([])
                resetFilters()
              }}
              disabled={!selectedActions.length}
            >
              Select
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ backgroundColor: "#f5f5f5" }}>
          <ActionsList
            forDialog
            actionsToFilter={actionsToFilter}
            selectedActions={selectedActions}
            setSelectedActions={setSelectedActions}
            categoryFilter={category}
            alreadyAssignedFilter
          />
        </Paper>
      </Dialog>
      <LoadingBackdrop open={loading} />
    </Dialog>
  )
}

export default CreateActionGroupDialog
