import {
  AppBar,
  Button,
  CardContent,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material"
import { useContext, useMemo, useState } from "react"
import { MainContext } from "../../controllers/main"
import {
  MissionDifficultyLevel,
  MissionSpecialType,
  MissionTimeframe,
  MissionType,
  Stage,
} from "../../services/config/enum"
import {
  DialogTransition,
  capitalizeFirstCharacter,
  enumAsArray,
} from "../../services/utilities/utility"
import { CloseRounded, OpenInNewRounded } from "@mui/icons-material"
import Team from "../../models/team"
import { TeamsContext } from "../../controllers/teams"
import TeamsList from "../../views/team/teamsList"
import { MissionsContext } from "../../controllers/missions"
import { isDev } from "../../services/config/constants"

const MissionGeneralEdit = () => {
  const { showPadding } = useContext(MainContext)
  const {
    currentMission,
    setCurrentMission,
    editMode,
    handleError,
    setHandleError,
    targetError,
    setTargetError,
  } = useContext(MissionsContext)
  const { resetTeamsFilters } = useContext(TeamsContext)

  // change team dialog
  const [changeTeamDialogOpen, setChangeTeamDialogOpen] =
    useState<boolean>(false)
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)

  // edit disabled
  const editDisabled = useMemo(
    () => currentMission.stage === Stage.PUBLISHED || currentMission.hasSibling,
    [currentMission]
  )

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Configuration
        </Typography>
        <Stack direction="row" gap={2}>
          <TextField
            fullWidth
            size="small"
            label="Handle"
            disabled={!editMode}
            value={currentMission.handle}
            onChange={(e) => {
              setCurrentMission({
                ...currentMission,
                handle: e.target.value,
              })

              // errors check
              if (!e.target.value) {
                setHandleError(true)
              } else {
                setHandleError(false)
              }
            }}
            error={handleError}
            helperText={handleError ? "This field is required" : ""}
          />
          <TextField
            fullWidth
            size="small"
            label="Project ID"
            disabled={!editMode || editDisabled}
            value={currentMission.projectId}
            onChange={(e) => {
              setCurrentMission({
                ...currentMission,
                projectId: e.target.value,
              })
            }}
          />
        </Stack>
        <Stack direction="row" gap={2}>
          <FormControl
            fullWidth
            size="small"
            disabled={!editMode || editDisabled}
          >
            <InputLabel id="type">Type</InputLabel>
            <Select
              labelId="type"
              label="Type"
              value={currentMission.type}
              onChange={(e) => {
                setCurrentMission({
                  ...currentMission,
                  type: e.target.value as MissionType,
                })
              }}
            >
              {enumAsArray(MissionType).map((item: string, index) => (
                <MenuItem key={index} value={item}>
                  {capitalizeFirstCharacter(
                    item
                      .split(/(?<=[a-z])(?=[A-Z])/)
                      .join(" ")
                      .toLowerCase()
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            size="small"
            disabled={!editMode || editDisabled}
          >
            <InputLabel id="special-type">Special type</InputLabel>
            <Select
              labelId="special-type"
              label="Special type"
              value={currentMission.specialType ?? "none"}
              onChange={(e) => {
                if (e.target.value === "none") {
                  setCurrentMission({
                    ...currentMission,
                    specialType: null,
                  })
                } else {
                  setCurrentMission({
                    ...currentMission,
                    specialType: e.target.value as MissionSpecialType,
                  })
                }
              }}
            >
              <MenuItem value="none">None</MenuItem>
              {enumAsArray(MissionSpecialType).map((item: string, index) => (
                <MenuItem key={index} value={item}>
                  {capitalizeFirstCharacter(item)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            size="small"
            disabled={!editMode || editDisabled}
          >
            <InputLabel id="timeframe">Timeframe</InputLabel>
            <Select
              labelId="timeframe"
              label="Timeframe"
              value={currentMission.timeframe}
              onChange={(e) => {
                setCurrentMission({
                  ...currentMission,
                  timeframe: e.target.value as MissionTimeframe,
                })
              }}
            >
              {enumAsArray(MissionTimeframe).map((item: string, index) => (
                <MenuItem key={index} value={item}>
                  {capitalizeFirstCharacter(
                    item
                      .split(/(?<=[a-z])(?=[A-Z])/)
                      .join(" ")
                      .toLowerCase()
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" gap={2}>
          <FormControl
            fullWidth
            size="small"
            disabled={!editMode || editDisabled}
          >
            <InputLabel id="difficulty-level">Difficulty level</InputLabel>
            <Select
              labelId="difficulty-level"
              label="Difficulty level"
              value={currentMission.difficultyLevel}
              onChange={(e) => {
                setCurrentMission({
                  ...currentMission,
                  difficultyLevel: e.target.value as MissionDifficultyLevel,
                })
              }}
            >
              {enumAsArray(MissionDifficultyLevel).map(
                (item: string, index) => (
                  <MenuItem key={index} value={item}>
                    {capitalizeFirstCharacter(
                      item
                        .split(/(?<=[a-z])(?=[A-Z])/)
                        .join(" ")
                        .toLowerCase()
                    )}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            size="small"
            label="Target"
            type="number"
            value={currentMission.target ?? ""}
            onChange={(e) => {
              if (e.target.value) {
                setCurrentMission({
                  ...currentMission,
                  target: parseFloat(e.target.value),
                })
              } else {
                setCurrentMission({
                  ...currentMission,
                  target: null,
                })
              }

              // errors check
              if (
                !e.target.value ||
                !Number.isInteger(parseFloat(e.target.value)) ||
                parseFloat(e.target.value) <= 0
              ) {
                setTargetError(true)
              } else {
                setTargetError(false)
              }
            }}
            error={targetError}
            helperText={targetError ? "Invalid value" : ""}
            disabled={!editMode || editDisabled}
          />
        </Stack>
        <TextField
          disabled={!editMode || editDisabled}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <Stack direction="row" spacing={0}>
                {currentMission.team.id !== "team_default" && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      if (isDev) {
                        window.open(
                          window.location.protocol +
                            "//" +
                            window.location.host +
                            "/teams/" +
                            currentMission.team.id
                        )
                      } else {
                        window.open(
                          window.location.protocol +
                            "//" +
                            window.location.host +
                            "/teams/" +
                            currentMission.team.id
                        )
                      }
                    }}
                  >
                    <OpenInNewRounded
                      style={{
                        fontSize: 15,
                        opacity: 0.9,
                      }}
                    />
                  </Button>
                )}
                <Button
                  sx={{ paddingInline: 2 }}
                  onClick={() => {
                    setChangeTeamDialogOpen(true)
                  }}
                  disabled={!editMode || editDisabled}
                >
                  Change
                </Button>
                {currentMission.team.id !== "team_default" && (
                  <Button
                    sx={{ paddingInline: 2 }}
                    onClick={() => {
                      setCurrentMission({
                        ...currentMission,
                        team: {
                          id: "team_default",
                          document: null,
                        },
                      })
                    }}
                    disabled={!editMode || editDisabled}
                  >
                    Reset
                  </Button>
                )}
              </Stack>
            ),
          }}
          fullWidth
          label="Team"
          size="small"
          value={
            currentMission.team.id !== "team_default"
              ? currentMission.team.document.items.find(
                  (item) => item.isDefault
                ).title
              : "Global (App)"
          }
        />
        {/* <Typography variant="h6" className="card-title">
          Image
        </Typography>
        <ImagePicker
          title="Image"
          pickedImage={currentMission.image ?? ""}
          setPickedImage={(selectedImage: {
            url: string
            id: string
            description?: string
          }) => {
            currentMission.image = selectedImage.url
            setCurrentMission({ ...currentMission })
          }}
          filters={{ category: "product" }}
          disableCategoryFilter
          height={200}
          disabled={!editMode}
        /> */}
      </Stack>
      {/* change team dialog */}
      <Dialog
        fullScreen
        open={changeTeamDialogOpen}
        onClose={() => {
          setChangeTeamDialogOpen(false)
          setSelectedTeam(null)
          resetTeamsFilters()
        }}
        TransitionComponent={DialogTransition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Team
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                currentMission.team = selectedTeam
                setCurrentMission({ ...currentMission })

                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              disabled={!selectedTeam}
            >
              Select
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ backgroundColor: "#f5f5f5" }}>
          <TeamsList
            forDialog
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
          />
        </Paper>
      </Dialog>
    </CardContent>
  )
}

export default MissionGeneralEdit
