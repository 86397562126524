import {
  Alert,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grow,
  IconButton,
  List,
  ListItem,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import EditContainer from "../../components/global/editContainer"
import EditHeading from "../../components/global/editHeading"
import PageSpinner from "../../components/global/pageSpinner"
import { MainContext } from "../../controllers/main"
import "../../styles/channelEdit.scss"
import { deepCopy } from "../../services/utilities/utility"
import { LanguagesContext } from "../../controllers/languages"
import { AddCircleRounded } from "@mui/icons-material"
import LoadingBackdrop from "../../components/global/loadingBackdrop"
import CantPerformActionDialog from "../../components/global/cantPerformActionDialog"
import { ActionsContext } from "../../controllers/actions"
import ActionGeneralEdit from "../../components/action/actionGeneralEdit"
import ActionTranslationsEdit from "../../components/action/actionTranslationsEdit"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import ActionGlobalPreview from "../../components/action/actionGlobalPreview"

const ActionEdit = () => {
  const { animation, setAnimation, setChangesSaved, showPadding } =
    useContext(MainContext)
  const { languages, languagesForEpisodeTabs } = useContext(LanguagesContext)
  const {
    loading: controllerLoading,
    setLoading: setControllerLoading,
    editMode,
    setEditMode,
    doneChanges,
    cancelChanges,
    currentAction,
    getCurrentAction,
    preChangesCurrentAction,
    addTranslation,
    hasError,
    upsertActionParent,
    upsertActionDocument,
    deleteAction,
  } = useContext(ActionsContext)
  const { actionId } = useParams()
  const navigate = useNavigate()

  // prevent tab close
  useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault()
      console.log("beforeunload event triggered")
      return (event.returnValue = "Are you sure you want to exit?")
    }

    window.addEventListener("beforeunload", handleTabClose)

    return () => {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [])

  // loading
  const [loading, setLoading] = useState<boolean>(true)

  // page error
  const [pageError, setPageError] = useState<boolean>(false)

  // fetch current journey
  const fetchAll = async () => {
    const noErrors = await getCurrentAction(actionId)
    if (noErrors) {
      setLoading(false)
      setControllerLoading(false)
    } else {
      setPageError(true)
    }
  }

  useEffect(() => {
    fetchAll()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // tabs
  const [selectedTab, setSelectedTab] = useState<number>(0)

  // add translation dialog
  const [addTranslationOpen, setAddTranslationOpen] = useState<boolean>(false)
  const [translationToAdd, setTranslationToAdd] = useState<
    { value: string; label: string; flag?: string } | undefined
  >()

  const handleAddTranslationClose = () => {
    setAddTranslationOpen(false)
    setTimeout(() => {
      setTranslationToAdd(undefined)
    }, 150)
  }

  // no default translation alert dialog
  const [
    noDefaultTranslationAlertDialogOpen,
    setNoDefaultTranslationAlertDialogOpen,
  ] = useState<boolean>(false)

  // info dialog
  const [infoDialogOpen, setInfoDialogOpen] = useState<boolean>(false)

  // delete action dialog
  const [deleteActionDialogOpen, setDeleteActionDialogOpen] =
    useState<boolean>(false)

  const handleDeleteActionDialog = useCallback(() => {
    setDeleteActionDialogOpen((current) => !current)
  }, [])

  // can't delete action dialog
  const [cantDeleteDialogOpen, setCantDeleteDialogOpen] =
    useState<boolean>(false)

  // global preview
  const [globalPreviewOpen, setGlobalPreviewOpen] = useState<boolean>(false)
  const [currentPreviewLanguage, setCurrentPreviewLanguage] = useState<
    string | null
  >(null)

  useEffect(() => {
    if (!loading) {
      if (selectedTab === 0) {
        if (currentAction.document.items.filter((item) => item.isDefault)[0]) {
          setCurrentPreviewLanguage(
            currentAction.document.items.filter((item) => item.isDefault)[0]
              .lang
          )
        }
      } else {
        setCurrentPreviewLanguage(
          currentAction.document.items[selectedTab - 1].lang
        )
      }
    }
  }, [selectedTab, loading])

  // boolean to update editor when copying details from default or canceling changes
  const [updateEditor, setUpdateEditor] = useState<boolean>(false)

  // copy to clipboard feedback
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false)

  return loading ? (
    <PageSpinner
      showBackButton={pageError}
      backButtonOnClick={() => {
        setAnimation(false)
        navigate("/actions")
      }}
    />
  ) : (
    <EditContainer>
      <EditHeading
        saveButtonLoading={controllerLoading}
        backButtonOnClick={() => {
          setAnimation(false)
          setTimeout(() => {
            navigate("/actions")
          }, 300)
        }}
        title={
          preChangesCurrentAction.document.items.filter(
            (documentItem) => documentItem.isDefault
          )[0].title
        }
        id={preChangesCurrentAction.id}
        showButtons
        showEditButton
        deleteButtonOnClick={handleDeleteActionDialog}
        editModeActive={editMode}
        setEditModeActive={setEditMode}
        saveButtonDisabled={!doneChanges || hasError}
        undoButtonDisabled={!doneChanges}
        doneChanges={doneChanges}
        undoButtonOnClick={() => {
          if (selectedTab > preChangesCurrentAction.document.items.length) {
            setSelectedTab(preChangesCurrentAction.document.items.length)
          }
          cancelChanges()
          setUpdateEditor((current) => !current)
        }}
        saveButtonOnClick={async () => {
          if (
            currentAction.document.items.filter((item) => item.isDefault).length
          ) {
            setControllerLoading(true)

            let noErrors = await upsertActionParent()

            if (noErrors) {
              noErrors = await upsertActionDocument()

              if (noErrors) {
                setControllerLoading(false)
                setChangesSaved(true)
              } else {
                setControllerLoading(false)
              }
            } else {
              setControllerLoading(false)
            }
          } else {
            setNoDefaultTranslationAlertDialogOpen(true)
          }
        }}
      />
      <Grow
        in={animation}
        timeout={300}
        style={{
          margin: showPadding === "yes" ? 16 : 0,
          marginTop: 0,
        }}
      >
        <Stack>
          {/* languages tabs */}
          <Stack direction="row" justifyContent="flex-start">
            <Card
              variant="outlined"
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: showPadding === "yes" ? null : 0,
                borderBottom: "none",
                borderLeft: showPadding === "yes" ? null : "none",
              }}
            >
              <Stack direction="row" justifyContent="flex-start">
                <Tabs
                  value={selectedTab}
                  onChange={(e: any, newValue: number) => {
                    setSelectedTab(newValue)
                  }}
                  aria-label="languages tabs"
                >
                  <Tab label="General" style={{ minWidth: 180 }} />
                  {currentAction.document.items.map((item) => (
                    <Tab
                      key={item.lang}
                      style={{ minWidth: 180 }}
                      label={
                        <Stack direction="row" spacing={1} alignItems="center">
                          <span>
                            {
                              languagesForEpisodeTabs.filter(
                                (arrayItem) => arrayItem.value === item.lang
                              )[0].flag
                            }
                          </span>
                          {currentAction.document.items.filter(
                            (documentItem) => documentItem.lang === item.lang
                          )[0].isDefault ? (
                            <Stack
                              direction="row"
                              spacing={0.5}
                              alignItems="center"
                            >
                              <span>
                                {
                                  languagesForEpisodeTabs.filter(
                                    (arrayItem) => arrayItem.value === item.lang
                                  )[0].label
                                }
                              </span>
                              <span style={{ fontSize: 10, marginTop: 2 }}>
                                (Default)
                              </span>
                            </Stack>
                          ) : (
                            <span>
                              {
                                languagesForEpisodeTabs.filter(
                                  (arrayItem) => arrayItem.value === item.lang
                                )[0].label
                              }
                            </span>
                          )}
                        </Stack>
                      }
                    />
                  ))}
                </Tabs>
                <Divider orientation="vertical" variant="middle" flexItem />
                <div
                  style={{
                    minWidth: 70,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    disabled={!editMode}
                    aria-label="add language button"
                    component="span"
                    style={{ width: 48, height: 48 }}
                    onClick={() => {
                      setAddTranslationOpen(true)
                    }}
                  >
                    <AddCircleRounded />
                  </IconButton>
                </div>
              </Stack>
            </Card>
          </Stack>
          <Card style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
            {!selectedTab ? (
              <ActionGeneralEdit
                setInfoDialogOpen={setInfoDialogOpen}
                setGlobalPreviewOpen={setGlobalPreviewOpen}
                setFeedbackOpen={setFeedbackOpen}
              />
            ) : (
              <ActionTranslationsEdit
                itemIndex={selectedTab - 1}
                updateEditor={updateEditor}
                setUpdateEditor={setUpdateEditor}
              />
            )}
          </Card>
        </Stack>
      </Grow>
      <LoadingBackdrop open={controllerLoading} />
      {/* global preview */}
      {currentPreviewLanguage &&
      currentAction.document.items.filter(
        (item) => item.lang === currentPreviewLanguage
      )[0] &&
      currentAction.category ? (
        <ActionGlobalPreview
          isGlobalPreviewOpen={globalPreviewOpen}
          setIsGlobalPreviewOpen={setGlobalPreviewOpen}
          currentPreviewLanguage={currentPreviewLanguage}
          setCurrentPreviewLanguage={setCurrentPreviewLanguage}
        />
      ) : null}
      {/* add translation dialog */}
      <Dialog onClose={handleAddTranslationClose} open={addTranslationOpen}>
        <DialogTitle>Add translation</DialogTitle>
        {translationToAdd ? (
          <DialogContent>
            <DialogContentText id="alert-dialog-description-add-translation">
              Are you sure you want to add{" "}
              <b>{translationToAdd.label.toUpperCase()}</b>?
            </DialogContentText>
          </DialogContent>
        ) : languagesForEpisodeTabs.filter((item) => {
            if (
              item.value !== "general" &&
              !currentAction.document.items.filter(
                (translationItem) => translationItem.lang === item.value
              )[0]
            ) {
              return item
            }
          }).length ? (
          <List sx={{ pt: 0, minWidth: 300 }}>
            {languagesForEpisodeTabs
              .filter((item) => {
                if (
                  item.value !== "general" &&
                  !currentAction.document.items.filter(
                    (translationItem) => translationItem.lang === item.value
                  )[0]
                ) {
                  return item
                }
              })
              .map((item) => (
                <ListItem
                  button
                  onClick={() => {
                    setTranslationToAdd(item)
                  }}
                  key={item.value}
                >
                  <Stack direction="row" spacing={1}>
                    <span>{item.flag}</span>
                    <span>{item.label.toUpperCase()}</span>
                  </Stack>
                </ListItem>
              ))}
          </List>
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description-add-translation">
              You have already added all languages available
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleAddTranslationClose}>Cancel</Button>
          {translationToAdd && (
            <Button
              onClick={() => {
                addTranslation(translationToAdd.value)
                handleAddTranslationClose()
                setSelectedTab(currentAction.document.items.length)
              }}
            >
              Add {translationToAdd.label}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {/* no default translation alert dialog */}
      <CantPerformActionDialog
        open={noDefaultTranslationAlertDialogOpen}
        setOpen={setNoDefaultTranslationAlertDialogOpen}
        content="There must be a default translation"
      />
      {/* info dialog */}
      <Dialog
        open={infoDialogOpen}
        onClose={() => {
          setInfoDialogOpen(false)
        }}
        aria-labelledby="dialog-info"
        aria-describedby="dialog-action-info"
      >
        <DialogTitle id="dialog-info">Appears in</DialogTitle>
        <DialogContent>
          <Stack spacing={2} style={{ maxHeight: 384, overflowY: "scroll" }}>
            {!currentAction.actionGroups.length ? (
              <Typography style={{ fontSize: "1.1rem" }}>
                This Action doesn't appear in any Series
              </Typography>
            ) : null}
            {currentAction.actionGroups.map((item) => (
              <Card
                key={item.actionGroup.id}
                variant="outlined"
                style={{ minHeight: 72, cursor: "pointer" }}
                onClick={() => {
                  window.open("/series/" + item.actionGroup.id)
                }}
                className="journey-card"
              >
                <CardContent style={{ paddingBottom: 16 }}>
                  <Stack direction="row" spacing={3} alignItems="center">
                    <div
                      style={{
                        width: 42,
                        height: 42,
                        borderRadius: 4,
                        backgroundImage: `url(${
                          item.actionGroup.image ?? noImagePlaceholder
                        })`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    />
                    <Typography>
                      {
                        item.actionGroup.document.items.filter(
                          (documentItem) => documentItem.isDefault
                        )[0].title
                      }
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      {languages.map((lang: string) => (
                        <Chip
                          key={lang}
                          color={
                            item.actionGroup.document.items.filter(
                              (documentItem) => documentItem.lang === lang
                            )[0]
                              ? "success"
                              : "default"
                          }
                          label={lang.toUpperCase()}
                          size="small"
                          style={{ width: 34 }}
                        />
                      ))}
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              setInfoDialogOpen(false)
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* delete action dialog */}
      <Dialog onClose={handleDeleteActionDialog} open={deleteActionDialogOpen}>
        <DialogTitle>Delete Action</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-add-translation">
            Are you sure you want to delete this Action? The operation is
            irreversible
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={controllerLoading}
            onClick={handleDeleteActionDialog}
          >
            Cancel
          </Button>
          <Button
            disabled={controllerLoading}
            color="error"
            onClick={async () => {
              setControllerLoading(true)

              const deleted = await deleteAction(currentAction.id)

              if (deleted) {
                setTimeout(() => {
                  setControllerLoading(false)
                  navigate("/actions", { state: { actionDeleted: true } })
                }, 1000)
              } else {
                handleDeleteActionDialog()
                setControllerLoading(false)
                setCantDeleteDialogOpen(true)
              }
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* can't delete action dialog */}
      <CantPerformActionDialog
        open={cantDeleteDialogOpen}
        setOpen={setCantDeleteDialogOpen}
        content="This Action cannot be deleted"
      />
      {/* copy to clickboard */}
      <Snackbar
        open={feedbackOpen}
        autoHideDuration={2000}
        onClose={() => {
          setFeedbackOpen(false)
        }}
      >
        <Alert severity="success">Copied to clipboard!</Alert>
      </Snackbar>
    </EditContainer>
  )
}

export default ActionEdit
