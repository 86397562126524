import { LoadingButton } from "@mui/lab"
import {
  Button,
  CardActions,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
  Dialog,
  TextField,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"
import { EpisodesContext } from "../../controllers/episodes"
import { MainContext } from "../../controllers/main"
import Episode from "../../models/episode"
import { DialogTransition } from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"

const CreateEpisodeJSONDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
  topBarText,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
  topBarText: string
}) => {
  const { setAnimation } = useContext(MainContext)
  const { loading, createNewEpisode, updateTranslation } =
    useContext(EpisodesContext)
  const navigate = useNavigate()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // episode json
  const [episodeJson, setEpisodeJson] = useState<string>("")

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setEpisodeJson("")
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (episodeJson.length) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [episodeJson])

  // create episode
  const createEpisode = async () => {
    const episode: Episode = JSON.parse(episodeJson)

    const input = {
      lang: episode.lang,
      title: episode.title,
      text: episode.text,
      image: episode.image,
      topics: episode.topics.map((item) => {
        return {
          primary: item.primary,
          id: item.topic.id,
        }
      }),
      sdgs: episode.sdgs.map((item) => {
        return {
          primary: item.primary,
          id: item.sdg.id,
        }
      }),
      sdgTargets: episode.sdgTargets.map((item) => {
        return {
          primary: item.primary,
          id: item.sdgTarget.id,
        }
      }),
      esg: episode.esg,
      category: { id: episode.category.id },
      defaultTranslation: {
        title: episode.title,
        slides: [],
        quiz: [],
      },
      doNotRecommend:
        episode.doNotRecommend === null ? false : episode.doNotRecommend,
    }

    const createdEpisode: Episode = await createNewEpisode(input, true)

    for (let i = 0; i < episode.translations.length; i++) {
      await updateTranslation(
        null,
        createdEpisode.id,
        episode.translations[i].lang,
        episode.translations[i].title,
        episode.translations[i].slides,
        episode.translations[i].quiz,
        true
      )
    }

    setDialogOpen(false)

    setAnimation(false)
    setTimeout(() => {
      navigate(`/episodes/${createdEpisode.id}`, {
        state: { enableEditing: true },
      })
    }, 250)
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText={topBarText}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            {topBarText}
          </Typography>
          <TextField
            label="Episode JSON"
            multiline
            rows={14}
            style={{ margin: 16, width: "calc(100% - 32px)" }}
            value={episodeJson}
            onChange={(e) => {
              setEpisodeJson(e.target.value)
            }}
          />
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (showDiscardDialog) {
                    setDiscardDialogOpen(true)
                  } else {
                    handleDialogChange(e)
                  }
                }}
              >
                Close
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={!episodeJson.length}
                onClick={() => {
                  createEpisode()
                }}
              >
                Add
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={loading} />
    </Dialog>
  )
}

export default CreateEpisodeJSONDialog
