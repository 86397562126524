import { IconButton } from "@mui/material"
import { copyTextToClipboard } from "../../services/utilities/utility"
import { ContentCopyRounded } from "@mui/icons-material"
import { CSSProperties, useContext } from "react"
import { MainContext } from "../../controllers/main"

const CopyToClipboardButton = ({
  textToCopy,
  size = "small",
  style,
}: {
  textToCopy: string
  size?: "smaller" | "small" | "big" | "bigger"
  style?: CSSProperties
}) => {
  const { setClipboardFeedbackOpen } = useContext(MainContext)

  return (
    <IconButton
      sx={{
        width:
          size === "smaller"
            ? 16
            : size === "small"
            ? 20
            : size === "big"
            ? 25
            : 40,
        height:
          size === "smaller"
            ? 16
            : size === "small"
            ? 20
            : size === "big"
            ? 25
            : 40,
      }}
      onClick={(e) => {
        e.stopPropagation()
        copyTextToClipboard(textToCopy)
        setClipboardFeedbackOpen(true)
      }}
      style={{ ...style }}
    >
      <ContentCopyRounded
        sx={{
          fontSize:
            size === "smaller"
              ? 10
              : size === "small"
              ? 12
              : size === "big"
              ? 15
              : 20,
        }}
      />
    </IconButton>
  )
}

export default CopyToClipboardButton
