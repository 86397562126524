import { JSONSchema } from "@aworld/utils/jsonSchema"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import { Form } from "@rjsf/mui"
import { WidgetProps } from "@rjsf/utils"
import validator from "@rjsf/validator-ajv8"
import { Dispatch, SetStateAction, useCallback } from "react"
import { capitalizeFirstCharacter } from "../../services/utilities/utility"
import "../../styles/jsonForm.scss"

const JsonForm = ({
  mergedSchemas,
  formData,
  setFormData,
  setFormErrors,
  disabled,
}: {
  mergedSchemas: any[] | JSONSchema
  formData: any
  setFormData: Dispatch<SetStateAction<any>>
  setFormErrors: Dispatch<SetStateAction<any[]>>
  disabled?: boolean
}) => {
  // custom components
  const customTextWidget = useCallback(
    (props: WidgetProps) => (
      <TextField
        size="small"
        type="text"
        label={
          props.label === "sub"
            ? "SUB"
            : capitalizeFirstCharacter(props.label.replace("_", " "))
        }
        value={props.value ?? ""}
        required={props.required}
        onChange={(event) => props.onChange(event.target.value)}
        disabled={props.disabled}
      />
    ),
    []
  )

  const customEmailWidget = useCallback(
    (props: WidgetProps) => (
      <TextField
        size="small"
        type="email"
        label={
          props.label === "sub"
            ? "SUB"
            : capitalizeFirstCharacter(props.label.replace("_", " "))
        }
        value={props.value ?? ""}
        required={props.required}
        onChange={(event) => props.onChange(event.target.value)}
        disabled={props.disabled}
      />
    ),
    []
  )

  const customSelectWidget = useCallback(
    (props: WidgetProps) => (
      <FormControl fullWidth size="small" disabled={props.disabled}>
        <InputLabel>
          {capitalizeFirstCharacter(props.label.replace("_", " "))}
        </InputLabel>
        <Select
          label={capitalizeFirstCharacter(props.label.replace("_", " "))}
          value={props.value ?? ""}
          required={props.required}
          onChange={(e) => {
            props.onChange(e.target.value)
          }}
        >
          {props.schema.enum.map((item, index) => (
            <MenuItem key={index} value={item as string}>
              {(item as string).toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
    []
  )

  return (
    <Form
      className="json-form"
      schema={mergedSchemas}
      validator={validator}
      uiSchema={{
        "ui:submitButtonOptions": {
          norender: true,
        },
      }}
      widgets={{
        TextWidget: customTextWidget,
        EmailWidget: customEmailWidget,
        SelectWidget: customSelectWidget,
      }}
      formData={formData}
      onChange={(e) => {
        setFormData(e.formData)
        setFormErrors(e.errors)
      }}
      disabled={disabled}
    />
  )
}

export default JsonForm
