import {
  Alert,
  Chip,
  LinearProgress,
  Snackbar,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ListContainer from "../../components/global/listContainer"
import ListTable from "../../components/global/listTable"
import TableSpinner from "../../components/global/tableSpinner"
import { MainContext } from "../../controllers/main"
import {
  calculateTimeElapsed,
  registerAnalyticsEvent,
} from "../../services/utilities/utility"
import { CountriesContext } from "../../controllers/countries"
import { ChallengesContext } from "../../controllers/challenges"
import Challenge from "../../models/challenge"
import { ChallengeType } from "../../services/config/enum"
import ChallengesListTopBar from "../../components/challenge/challengesListTopBar"
import CreateChallengeDialog from "../../components/challenge/createChallengeDialog"
import { OpenInNewRounded } from "@mui/icons-material"
import TitleTableCell from "../../components/global/titleTableCell"
import { isDev } from "../../services/config/constants"
import TranslationsStack from "../../components/global/translationsStack"

const ChallengesList = ({
  forDialog = false,
  challengesToFilter = [],
  selectedChallenge,
  setSelectedChallenge,
}: {
  forDialog?: boolean
  challengesToFilter?: Challenge[]
  selectedChallenge?: Challenge | null
  setSelectedChallenge?: Dispatch<SetStateAction<Challenge | null>>
}) => {
  const { setAnimation, showPadding, cmdPressed } = useContext(MainContext)
  const { loading: countriesControllerLoading } = useContext(CountriesContext)
  const {
    loading,
    updatingList,
    setUpdatingList,
    challengesList,
    getChallengesList,
    challengesListNextToken,
    loadMoreChallenges,
    hasSearched,
    searchMetric,
    searchTeam,
    searchTime,
    searchTitle,
    setSearchTitle,
    searchChallengesList,
  } = useContext(ChallengesContext)
  const navigate = useNavigate()
  const location = useLocation()

  // list background update
  const backgroundUpdate = () => {
    if (!challengesList.length) {
      getChallengesList()
    } else if (!loading && !hasSearched) {
      setUpdatingList(true)
      getChallengesList(false)
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    backgroundUpdate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create action dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // handle challenge deleted feedback
  const [challengeDeletedFeedbackOpen, setChallengeDeletedFeedbackOpen] =
    useState<boolean>(false)

  useEffect(() => {
    if (location.state && (location.state as any).challengeDeleted) {
      setChallengeDeletedFeedbackOpen(true)
    }
  }, [])

  // load more button loading
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // register analytics event
  useEffect(() => {
    registerAnalyticsEvent({ type: "page_view", name: "challenges_list" })
  }, [])

  return (
    <ListContainer style={{ position: "relative" }}>
      {updatingList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 143,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <ChallengesListTopBar
        forDialog={forDialog}
        loading={loading || loadMoreLoading}
        textFieldValue={searchTitle}
        textFieldPlaceholder="Search Challenges"
        addButtonLabel="Add Challenge"
        addButtonDisabled={countriesControllerLoading}
        textFieldOnChange={(e) => {
          setSearchTitle(e.target.value)
          if (e.target.value.length === 0 && hasSearched) {
            setUpdatingList(true)

            if (!searchMetric && !searchTeam && !searchTime) {
              getChallengesList(false)
            } else {
              searchChallengesList()
            }
          }
        }}
        textFieldOnKeyDown={(e) => {
          if (e.key === "Enter" && searchTitle.length > 2) {
            searchChallengesList()
          }
        }}
        cancelButtonOnClick={() => {
          setSearchTitle("")
          if (hasSearched) {
            setUpdatingList(true)

            if (!searchMetric && !searchTeam && !searchTime) {
              getChallengesList(false)
            } else {
              searchChallengesList()
            }
          }
        }}
        searchButtonOnClick={() => {
          searchChallengesList()
        }}
        addButtonOnClick={handleDialogChange}
      />
      {loading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
          headingItems={[
            "Image",
            "Title & ID",
            "Translations",
            "Status",
            "Type",
            "Progress",
            "Members",
            "Team",
            "Updated",
          ]}
          nextToken={challengesListNextToken}
          loadingMoreItems={loadMoreLoading}
          setLoadingMoreItems={setLoadMoreLoading}
          loadMore={
            hasSearched
              ? async () => {
                  await searchChallengesList(false, true)
                  return true
                }
              : loadMoreChallenges
          }
          tableBody={challengesList
            .filter((item) => {
              if (
                !challengesToFilter.filter((item2) => item2.id === item.id)[0]
              ) {
                return item
              }
            })
            .map((item: Challenge) => (
              <TableRow
                key={item.id}
                style={{
                  cursor: "pointer",
                  backgroundColor:
                    selectedChallenge && selectedChallenge.id === item.id
                      ? "lightgray"
                      : null,
                }}
                hover
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
                onClick={() => {
                  if (!forDialog) {
                    if (cmdPressed) {
                      window.open(`${window.location.href}/${item.id}`)
                    } else {
                      setAnimation(false)
                      setTimeout(() => {
                        navigate(`/challenges/${item.id}`)
                      }, 250)
                    }
                  } else {
                    if (selectedChallenge && selectedChallenge.id === item.id) {
                      setSelectedChallenge(null)
                    } else {
                      setSelectedChallenge(item)
                    }
                  }
                }}
              >
                <TableCell>
                  <div
                    style={{
                      width: 42,
                      height: 42,
                      borderRadius: 4,
                      backgroundImage: `url(${
                        item.document.items.filter(
                          (documentItem) => documentItem.isDefault
                        )[0].image ?? noImagePlaceholder
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                </TableCell>
                <TitleTableCell title={item.name} id={item.id} />
                <TableCell>
                  <TranslationsStack documentItems={item.document.items} />
                </TableCell>
                <TableCell>
                  {new Date(item.startsAt) > new Date() ? (
                    <Chip size="small" label="Not started" color="default" />
                  ) : new Date(item.endsAt) >= new Date() ? (
                    <Chip size="small" label="Current" color="success" />
                  ) : (
                    <Chip size="small" label="Ended" color="error" />
                  )}
                </TableCell>
                <TableCell>
                  {item.type === ChallengeType.featured ? (
                    <Chip size="small" label="Featured" color="success" />
                  ) : item.type === ChallengeType.reserved ? (
                    <Chip size="small" label="Reserved" color="warning" />
                  ) : (
                    <Chip size="small" label="Res. & Feat." color="primary" />
                  )}
                </TableCell>
                <TableCell>
                  {Math.round(
                    ((item.currentAmount / item.targetAmount) * 100 +
                      Number.EPSILON) *
                      10
                  ) / 10}
                  %
                </TableCell>
                <TableCell>{item.userCount}</TableCell>
                <TableCell>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 8,
                    }}
                    onClick={(e) => {
                      if (item.team) {
                        e.stopPropagation()
                        if (isDev) {
                          window.open(
                            window.location.protocol +
                              "//" +
                              window.location.host +
                              "/teams/" +
                              item.team.id
                          )
                        } else {
                          window.open(
                            window.location.protocol +
                              "//" +
                              window.location.host +
                              "/teams/" +
                              item.team.id
                          )
                        }
                      }
                    }}
                  >
                    {item.team ? (
                      <OpenInNewRounded
                        style={{ fontSize: 15, opacity: 0.9 }}
                      />
                    ) : (
                      <div style={{ opacity: 0.2 }}>N.A.</div>
                    )}
                  </div>
                </TableCell>
                <TableCell sx={{ minWidth: 110 }}>
                  <Typography variant="caption">
                    {calculateTimeElapsed(item.updatedAt)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        />
      )}
      {!forDialog ? (
        <CreateChallengeDialog
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          handleDialogChange={handleDialogChange}
          topBarText="Add Challenge"
        />
      ) : null}
      <Snackbar
        open={challengeDeletedFeedbackOpen}
        onClose={() => {
          setChallengeDeletedFeedbackOpen(false)
        }}
        autoHideDuration={3000}
      >
        <Alert severity="success">Challenge deleted</Alert>
      </Snackbar>
    </ListContainer>
  )
}

export default ChallengesList
