export const textColors = [
  {
    label: "Blue",
    value: "#22214B",
  },
  {
    label: "White",
    value: "#FFFFFE",
  },
]
export const boxBackColors = [
  {
    label: "Blue",
    value: "#22214B",
  },
  {
    label: "White",
    value: "#FFFFFE",
  },
  {
    label: "Nude",
    value: "#F3D2C1",
  },
  {
    label: "Fuchsia",
    value: "#D41455",
  },
]
export const tagBoxBackColors = [
  {
    label: "Blue",
    value: "#22214B",
  },
  {
    label: "Fuchsia",
    value: "#D41455",
  },
  {
    label: "Lilac",
    value: "#C9ADCD",
  },
  {
    label: "Yellow",
    value: "#F7C552",
  },
  {
    label: "Peach",
    value: "#FACB9D",
  },
]
