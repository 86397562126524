import { useContext, useState } from "react"
import { LanguagesContext } from "../../controllers/languages"
import { Chip, IconButton, Stack } from "@mui/material"
import Document from "../../models/document"
import { ChevronRightRounded } from "@mui/icons-material"

const TranslationsStack = ({
  publishedLangs,
  documentItems,
  translations,
}: {
  publishedLangs?: {
    lang: string
    title: string
  }[]
  documentItems?: Document[]
  translations?: { lang: string }[]
}) => {
  const { languages } = useContext(LanguagesContext)

  // expanded to show all translations or not
  const [expanded, setExpanded] = useState<boolean>(false)

  if (publishedLangs || documentItems || translations) {
    return (
      <div
        style={{
          width: 124,
          height: 42.5,
          position: "relative",
          display: "flex",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        <Stack
          style={{
            width: expanded
              ? languages.length * 37 + (languages.length - 1) * 8 + 42
              : 92,
            height: 36,
            backgroundColor: expanded ? "white" : "rgba(0, 0, 0, 0)",
            position: "absolute",
            left: expanded ? -60 : 0,
            transition: "180ms",
            borderRadius: 16,
            overflow: "hidden",
            boxShadow: expanded ? "0 6px 14px 0 #c4c4c4" : "none",
            paddingLeft: 6,
            paddingRight: 36,
          }}
          direction="row"
          spacing={1}
          alignItems="center"
          onClick={(e) => {
            e.stopPropagation()
            setExpanded((current) => !current)
          }}
        >
          {languages.map((lang: string) => (
            <Chip
              key={lang}
              color={
                translations
                  ? translations.some((item) => item.lang === lang)
                    ? "success"
                    : "default"
                  : publishedLangs
                  ? publishedLangs.filter(
                      (publishedLang) => publishedLang.lang === lang
                    )[0]
                    ? "success"
                    : "default"
                  : documentItems.filter(
                      (documentItem) => documentItem.lang === lang
                    )[0]
                  ? "success"
                  : "default"
              }
              label={
                lang.includes("_")
                  ? lang.slice(lang.indexOf("_") + 1).toUpperCase()
                  : lang.toUpperCase()
              }
              size="small"
              style={{ width: 37, minWidth: 37 }}
            />
          ))}
        </Stack>
        <IconButton
          style={{
            height: 24,
            width: 24,
            position: "absolute",
            right: expanded
              ? -(languages.length * 37 + (languages.length - 1) * 8) + 148
              : 6,
            transition: "180ms",
          }}
          onClick={(e) => {
            e.stopPropagation()
            setExpanded((current) => !current)
          }}
        >
          <ChevronRightRounded
            style={{
              transform: expanded ? "rotate(180deg)" : "none",
              transition: "180ms",
            }}
          />
        </IconButton>
      </div>
    )
  } else {
    return <Stack direction="row" spacing={1}></Stack>
  }
}

export default TranslationsStack
