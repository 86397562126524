import {
  Backdrop,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material"
import { Dispatch, SetStateAction, useState } from "react"
import { DeviceFrameset } from "react-device-frameset"
import QuoteSlice from "../../models/bodySlices/quoteSlice"
import TextSlice from "../../models/bodySlices/textSlice"
import { BodySliceType, TeamAccessType } from "../../services/config/enum"
import quotationMarksIcon from "../../assets/images/quotation-marks.png"
import ImageSlice from "../../models/bodySlices/imageSlice"
import EmbedSlice from "../../models/bodySlices/embedSlice"
import getVideoId from "get-video-id"
import Challenge from "../../models/challenge"
import { months } from "../../services/config/constants"
import {
  ChevronLeftRounded,
  InfoOutlined,
  LockOpenOutlined,
  LockOutlined,
  PeopleOutline,
} from "@mui/icons-material"
import ChallengesDocument from "../../models/challengesDocument"

const ChallengeGlobalPreview = ({
  isGlobalPreviewOpen,
  setIsGlobalPreviewOpen,
  currentPreviewLanguage,
  setCurrentPreviewLanguage,
  currentChallenge,
}: {
  isGlobalPreviewOpen: boolean
  setIsGlobalPreviewOpen: Dispatch<SetStateAction<boolean>>
  currentPreviewLanguage: string
  setCurrentPreviewLanguage: Dispatch<SetStateAction<string>>
  currentChallenge: Challenge
}) => {
  // current document item
  const currentItem: ChallengesDocument =
    currentChallenge.document.items.filter(
      (item) => item.lang === currentPreviewLanguage
    )[0]

  // parse challenge date
  const parseChallengeDate = (isoDate: string) => {
    const date = new Date(isoDate)
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
  }

  // scrolled to top boolean to trigger top bar
  const [scrolledToTop, setScrolledToTop] = useState<boolean>(false)

  // screen to view (challenge card or challenge page)
  const [screenToView, setScreenToView] = useState(0)

  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isGlobalPreviewOpen}
      onClick={() => {
        setIsGlobalPreviewOpen(false)
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* device */}
        <div
          style={{
            zoom: window.innerHeight / 1000,
            marginTop: 110,
          }}
        >
          {screenToView === 0 ? (
            <DeviceFrameset
              device="iPhone X"
              style={{ position: "relative" }}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  lineHeight: "24px",
                  color: "#464646",
                  marginLeft: 14,
                  marginTop: 46,
                }}
              >
                Featured Challenges
              </div>
              {/* challenge card */}
              <div
                style={{
                  width: "calc(100% - 28px)",
                  marginLeft: 14,
                  borderRadius: 12,
                  boxShadow: "0px 0px 24px rgba(17, 67, 97, 0.08)",
                  overflow: "hidden",
                  marginTop: 10,
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
                onClick={() => {
                  setScreenToView(1)
                }}
              >
                <img
                  src={currentItem.image}
                  style={{ width: "100%", height: 149, objectFit: "cover" }}
                />
                <div
                  style={{
                    width: "100%",
                    height: 4,
                    backgroundColor: "#479CF7",
                  }}
                />
                <div
                  style={{
                    boxSizing: "border-box",
                    paddingTop: 6,
                    paddingInline: 14,
                    paddingBottom: 10,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      lineHeight: "19px",
                      color: "#464646",
                    }}
                  >
                    {currentItem.title}
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      lineHeight: "19px",
                      color: "#464646",
                      maxWidth: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {currentItem.subtitle}
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 9,
                    left: 9,
                    backgroundColor: "#E6F0FC",
                    height: 18,
                    boxSizing: "border-box",
                    paddingInline: 8,
                    display: "flex",
                    flexDirection: "row",
                    borderRadius: 4,
                    alignItems: "center",
                    gap: 3,
                  }}
                >
                  <PeopleOutline style={{ color: "#469DF6", fontSize: 14 }} />
                  <div
                    style={{
                      fontSize: 11,
                      fontWeight: 300,
                      lineHeight: "13px",
                      color: "#469DF6",
                    }}
                  >
                    {currentChallenge.userCount}
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 117,
                    left: 9,
                    backgroundColor: "#E6F0FC",
                    height: 22,
                    boxSizing: "border-box",
                    paddingInline: 8,
                    display: "flex",
                    borderRadius: 4,
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: 11,
                      fontWeight: 300,
                      lineHeight: "13px",
                      color: "#469DF6",
                    }}
                  >
                    {Math.round(
                      (currentChallenge.currentAmount /
                        currentChallenge.targetAmount) *
                        100
                    )}
                    %
                  </div>
                </div>
              </div>
            </DeviceFrameset>
          ) : (
            <DeviceFrameset
              device="iPhone X"
              style={{ position: "relative" }}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: 30,
                  height: 30,
                  borderRadius: 15,
                  backgroundColor: "white",
                  top: 55,
                  left: 6,
                  zIndex: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  boxShadow: scrolledToTop
                    ? "none"
                    : "0px 0px 24px rgba(17, 67, 97, 0.08)",
                  transition: "box-shadow 100ms",
                }}
                onClick={() => {
                  setScreenToView(0)
                }}
              >
                <ChevronLeftRounded style={{ color: "#464646" }} />
              </div>
              {scrolledToTop && (
                <div
                  style={{
                    width: "100%",
                    height: 95,
                    position: "absolute",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 10,
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                    boxSizing: "border-box",
                    paddingBottom: 13,
                  }}
                >
                  <div
                    style={{
                      fontSize: 20,
                      fontWeight: 600,
                      lineHeight: "23.8px",
                      color: "#464646",
                      maxWidth: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {currentItem.title}
                  </div>
                </div>
              )}
              <div
                id="preview-container"
                className="hide-scrollbars"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  overflowY: "scroll",
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: 38,
                  boxSizing: "border-box",
                }}
                onScroll={() => {
                  const container = document.getElementById("preview-container")
                  if (container.scrollTop > 330) {
                    setScrolledToTop(true)
                  } else {
                    setScrolledToTop(false)
                  }
                }}
              >
                <div
                  style={{
                    width: "100%",
                    minHeight: 385,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "sticky",
                    top: 0,
                    backgroundImage: `url(${currentItem.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                    marginTop: -26,
                    color: "#464646",
                    display: "flex",
                    flexDirection: "column",
                    boxSizing: "border-box",
                    borderTopRightRadius: 24,
                    borderTopLeftRadius: 24,
                    backgroundColor: "white",
                  }}
                >
                  <div
                    style={{
                      fontSize: 16,
                      lineHeight: "19px",
                      fontWeight: 400,
                      marginTop: 10,
                      textAlign: "center",
                    }}
                  >
                    Challenge
                  </div>
                  <div
                    style={{
                      fontSize: 23,
                      lineHeight: "27px",
                      fontWeight: 700,
                      marginTop: 11,
                      textAlign: "center",
                      boxSizing: "border-box",
                      paddingInline: 16,
                    }}
                  >
                    {currentItem.title}
                  </div>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{ marginTop: 8 }}
                    gap={0.5}
                  >
                    <InfoOutlined style={{ color: "#479CF6" }} />
                    <div
                      style={{
                        fontSize: 15,
                        lineHeight: "18px",
                        fontWeight: 400,
                        textAlign: "center",
                        boxSizing: "border-box",
                        color: "#479CF6",
                      }}
                    >
                      What is a challenge?
                    </div>
                  </Stack>
                  <div
                    style={{
                      width: "100%",
                      height: 50,
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 33,
                      boxSizing: "border-box",
                      paddingInline: 14,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: 50,
                        display: "flex",
                        flexDirection: "row",
                        gap: 9,
                      }}
                    >
                      <div
                        style={{
                          width: "50%",
                          height: "100%",
                          backgroundColor: "#F7F7F7",
                          borderRadius: 6,
                          display: "flex",
                          flexDirection: "column",
                          gap: 3,
                          alignItems: "center",
                          boxSizing: "border-box",
                          paddingTop: 5,
                        }}
                      >
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            lineHeight: "19px",
                          }}
                        >
                          Begin
                        </div>
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {parseChallengeDate(currentChallenge.startsAt)}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          height: "100%",
                          backgroundColor: "#F7F7F7",
                          borderRadius: 6,
                          display: "flex",
                          flexDirection: "column",
                          gap: 3,
                          alignItems: "center",
                          boxSizing: "border-box",
                          paddingTop: 5,
                        }}
                      >
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            lineHeight: "19px",
                          }}
                        >
                          Ends
                        </div>
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {parseChallengeDate(currentChallenge.endsAt)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "calc(100% - 28px)",
                      marginLeft: 14,
                      height: 67,
                      backgroundColor: "#F7F7F7",
                      borderRadius: 6,
                      marginTop: 10,
                      display: "flex",
                      flexDirection: "column",
                      boxSizing: "border-box",
                      paddingInline: 9,
                      paddingTop: 9,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: 18,
                        backgroundColor: "#E4F0FD",
                        borderRadius: 7,
                        overflow: "hidden",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: `${
                            (currentChallenge.currentAmount /
                              currentChallenge.targetAmount) *
                            100
                          }%`,
                          height: "100%",
                          backgroundColor: "#089EFD",
                          position: "absolute",
                          left: 0,
                        }}
                      />
                      <div
                        style={{
                          fontSize: 13,
                          fontWeight: 700,
                          color: "white",
                          position: "relative",
                        }}
                      >
                        {Math.round(
                          (currentChallenge.currentAmount /
                            currentChallenge.targetAmount) *
                            100
                        )}
                        %
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        lineHeight: "16.7px",
                        marginTop: 9,
                        alignSelf: "center",
                      }}
                    >
                      {Math.round(currentChallenge.currentAmount)} of{" "}
                      {currentChallenge.targetAmount}{" "}
                      {currentChallenge.metric.unit === "L" ||
                      currentChallenge.metric.unit === "Kg" ||
                      currentChallenge.metric.unit === "kWh"
                        ? currentChallenge.metric.unit
                        : ""}{" "}
                      {currentChallenge.metric.name}
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      lineHeight: "19px",
                      marginLeft: 14,
                      marginTop: 22,
                    }}
                  >
                    Leaderboard
                  </div>
                  <div
                    style={{
                      width: "calc(100% - 28px)",
                      marginLeft: 14,
                      height: 45,
                      backgroundColor: "#F7F7F7",
                      borderRadius: 6,
                      marginTop: 12,
                      display: "flex",
                      alignItems: "center",
                      boxSizing: "border-box",
                      paddingLeft: 13,
                      fontSize: 15,
                      fontWeight: 400,
                    }}
                  >
                    {currentChallenge.userCount} members
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      lineHeight: "19px",
                      marginLeft: 14,
                      marginTop: 30,
                    }}
                  >
                    Description
                  </div>
                  <div
                    style={{
                      paddingInline: 16,
                      marginTop: 12,
                    }}
                  >
                    {currentItem.body.map((item, index) => {
                      if (item.sliceType === BodySliceType.BodyTextSlice) {
                        const bodyItem = item as TextSlice
                        return (
                          <div
                            key={index}
                            style={{
                              fontSize: 16,
                              lineHeight: "22px",
                              fontWeight: 400,
                              boxSizing: "border-box",
                              marginBottom: 16,
                            }}
                            dangerouslySetInnerHTML={{ __html: bodyItem.text }}
                          />
                        )
                      } else if (
                        item.sliceType === BodySliceType.BodyQuoteSlice
                      ) {
                        const bodyItem = item as QuoteSlice
                        return (
                          <div
                            key={index}
                            style={{
                              width: "calc(100% - 30px)",
                              backgroundColor: "#F7F7F7",
                              boxSizing: "border-box",
                              padding: 15,
                              marginBottom: 15,
                              marginLeft: 15,
                              display: "flex",
                              flexDirection: "column",
                              position: "relative",
                            }}
                          >
                            <img
                              src={quotationMarksIcon}
                              style={{
                                position: "absolute",
                                top: 7,
                                left: 4,
                                width: 22,
                                height: 15,
                              }}
                            />
                            <div
                              style={{
                                fontSize: 16,
                                lineHeight: 1.33,
                                boxSizing: "border-box",
                                paddingInline: 15,
                              }}
                            >
                              {bodyItem.quoteText}
                            </div>
                            <div
                              style={{
                                fontSize: 16,
                                lineHeight: 1.33,
                                boxSizing: "border-box",
                                fontWeight: 600,
                                width: "100%",
                                textAlign: "right",
                                marginTop: 5,
                              }}
                            >
                              {bodyItem.author}
                            </div>
                            <div
                              style={{
                                fontSize: 14,
                                lineHeight: 1.1,
                                boxSizing: "border-box",
                                fontStyle: "italic",
                                width: "100%",
                                textAlign: "right",
                              }}
                            >
                              {bodyItem.authorTitle}
                            </div>
                          </div>
                        )
                      } else if (
                        item.sliceType === BodySliceType.BodyImageSlice
                      ) {
                        const bodyItem = item as ImageSlice
                        return (
                          <div
                            key={index}
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: 16,
                            }}
                          >
                            <img
                              src={bodyItem.imgUrl}
                              style={{ width: "100%" }}
                            />
                            <div
                              style={{
                                fontSize: 16,
                                lineHeight: 1.33,
                                boxSizing: "border-box",
                                fontWeight: 600,
                                paddingInline: 16,
                                marginTop: 4,
                              }}
                            >
                              {bodyItem.imgCaptionTitle}
                            </div>
                            <div
                              style={{
                                fontSize: 14,
                                lineHeight: 1.1,
                                boxSizing: "border-box",
                                paddingInline: 16,
                                fontStyle: "italic",
                              }}
                            >
                              {bodyItem.imgCaption}
                            </div>
                          </div>
                        )
                      } else if (
                        item.sliceType === BodySliceType.BodyEmbedSlice
                      ) {
                        const bodyItem = item as EmbedSlice
                        return (
                          <div
                            key={index}
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: 16,
                            }}
                          >
                            <iframe
                              style={{ width: "100%", height: 210 }}
                              src={
                                "https://www.youtube.com/embed/" +
                                getVideoId(bodyItem.embedUrl).id
                              }
                            />
                            <div
                              style={{
                                fontSize: 16,
                                lineHeight: 1.33,
                                boxSizing: "border-box",
                                fontWeight: 600,
                                paddingInline: 16,
                                marginTop: 2,
                              }}
                            >
                              {bodyItem.embedCaptionTitle}
                            </div>
                            <div
                              style={{
                                fontSize: 14,
                                lineHeight: 1.1,
                                boxSizing: "border-box",
                                paddingInline: 16,
                                fontStyle: "italic",
                              }}
                            >
                              {bodyItem.embedCaption}
                            </div>
                            <div
                              style={{
                                fontSize: 14,
                                lineHeight: 1.1,
                                boxSizing: "border-box",
                                paddingInline: 16,
                                marginTop: 2,
                              }}
                            >
                              {bodyItem.embedText}
                            </div>
                          </div>
                        )
                      }
                    })}
                  </div>
                </div>
                <div style={{ backgroundColor: "white", position: "relative" }}>
                  {currentChallenge.team ? (
                    <div
                      style={{
                        marginTop: 20,
                        boxSizing: "border-box",
                        paddingInline: 16,
                        display: "flex",
                        flexDirection: "column",
                        color: "#464646",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          lineHeight: "19px",
                        }}
                      >
                        Organized by
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 70,
                          backgroundColor: "white",
                          borderRadius: 8,
                          marginTop: 16,
                          boxShadow: "0px 0px 24px rgba(17, 67, 97, 0.08)",
                          display: "flex",
                          flexDirection: "column",
                          boxSizing: "border-box",
                          paddingTop: 10,
                          paddingInline: 14,
                        }}
                      >
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 500,
                            lineHeight: "19px",
                            maxWidth: "100%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {currentChallenge.team.name}
                        </div>
                        <div
                          style={{
                            height: 17,
                            display: "flex",
                            flexDirection: "row",
                            gap: 4,
                            color: "#AFAFAF",
                            marginTop: 10,
                          }}
                        >
                          {currentChallenge.team.accessType ===
                          TeamAccessType.public ? (
                            <LockOpenOutlined style={{ fontSize: 14 }} />
                          ) : (
                            <LockOutlined style={{ fontSize: 14 }} />
                          )}
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: "17px",
                            }}
                          >
                            {currentChallenge.team.accessType ===
                            TeamAccessType.public
                              ? "Public"
                              : "Private"}{" "}
                            team
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </DeviceFrameset>
          )}
        </div>
        {/* language selection */}
        {currentChallenge.document.items.length ? (
          <Card
            style={{ position: "absolute", top: 10, width: 350, height: 70 }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <CardContent>
              <div
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                <FormControl fullWidth size="small">
                  <InputLabel id="language-select-news-global-preview">
                    Language
                  </InputLabel>
                  <Select
                    labelId="language-select-news-global-preview"
                    label="Language"
                    value={currentPreviewLanguage}
                    onChange={(e) => {
                      e.preventDefault()
                      e.stopPropagation()

                      setCurrentPreviewLanguage(e.target.value)
                    }}
                  >
                    {currentChallenge.document.items.map((item, index) => (
                      <MenuItem key={index} value={item.lang}>
                        {item.lang.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </CardContent>
          </Card>
        ) : null}
      </div>
    </Backdrop>
  )
}

export default ChallengeGlobalPreview
