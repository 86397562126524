import Action from "./action"
import ActionGroupsDocument from "./actionGroupsDocument"
import Badge from "./badge"
import Category from "./category"
import Sdg from "./sdg"
import SdgTarget from "./sdgTarget"

class ActionGroup {
  id?: string
  updatedAt?: string
  canBecomeHabit?: boolean
  image?: string
  handle?: string
  maxIterations?: number
  points?: number
  actions?: { action: Action }[]
  sdgs?: {
    primary: boolean
    sdg: Sdg
  }[]
  sdgTargets?: {
    primary: boolean
    sdgTarget: SdgTarget
  }[]
  document?: { items: ActionGroupsDocument[]; parentId: string }
  badge?: Badge
  category?: Category
  featured?: boolean
  footprintPrediction?: number
  footprint?: {
    questionId: string
    pk?: string
    sk?: string
  }

  constructor(
    id?: string,
    updatedAt?: string,
    canBecomeHabit?: boolean,
    image?: string,
    handle?: string,
    maxIterations?: number,
    points?: number,
    actions?: { action: Action }[],
    sdgs?: {
      primary: boolean
      sdg: Sdg
    }[],
    sdgTargets?: {
      primary: boolean
      sdgTarget: SdgTarget
    }[],
    document?: { items: ActionGroupsDocument[]; parentId: string },
    badge?: Badge,
    category?: Category,
    featured?: boolean,
    footprintPrediction?: number,
    footprint?: {
      questionId: string
      pk?: string
      sk?: string
    }
  ) {
    this.id = id
    this.updatedAt = updatedAt
    this.canBecomeHabit = canBecomeHabit
    this.image = image
    this.handle = handle
    this.maxIterations = maxIterations
    this.points = points
    this.actions = actions
    this.sdgs = sdgs
    this.sdgTargets = sdgTargets
    this.document = document
    this.badge = badge
    this.category = category
    this.featured = featured
    this.footprintPrediction = footprintPrediction
    this.footprint = footprint
  }
}

export default ActionGroup
