import {
  AddCircleRounded,
  CloseRounded,
  DeleteRounded,
  DragHandleRounded,
} from "@mui/icons-material"
import {
  AppBar,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import EditContainer from "../../components/global/editContainer"
import LoadingBackdrop from "../../components/global/loadingBackdrop"
import { JourneysContext } from "../../controllers/journeys"
import { MainContext } from "../../controllers/main"
import { TeamJourneysRelationsContext } from "../../controllers/teamJourneysRelations"
import Journey from "../../models/journey"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import { DialogTransition } from "../../services/utilities/utility"
import JourneysList from "../journey/journeysList"
import EditHeadingFade from "../../components/global/editHeadingFade"
import CantPerformActionDialog from "../../components/global/cantPerformActionDialog"

const FreeJourneysRelationEdit = () => {
  const { animation, setChangesSaved, showPadding } = useContext(MainContext)
  const {
    loading: controllerLoading,
    setLoading: setControllerLoading,
    editMode,
    setEditMode,
    doneChanges,
    currentFreeJourneysList,
    setCurrentFreeJourneysList,
    cancelChanges,
    upsertTeamJourneysRelation,
    getFreeJourneys,
  } = useContext(TeamJourneysRelationsContext)
  const {
    setSearchValue,
    setSearchLanguage,
    setSearchTopic,
    setSearchSdg,
    setSearchSdgTarget,
    setSearchType,
    setJourneysList,
  } = useContext(JourneysContext)

  // prevent tab close
  useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault()
      console.log("beforeunload event triggered")
      return (event.returnValue = "Are you sure you want to exit?")
    }

    window.addEventListener("beforeunload", handleTabClose)

    return () => {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [])

  // loading
  const [loading, setLoading] = useState<boolean>(true)

  // fetch current journey
  const fetchAll = async () => {
    let noErrors = await getFreeJourneys()
    if (noErrors) {
      setLoading(false)
      setControllerLoading(false)
    }
  }

  useEffect(() => {
    fetchAll()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // journeys
  const [selectedJourneys, setSelectedJourneys] = useState<Journey[]>([])
  const [journeysToFilter, setJourneysToFilter] = useState<Journey[]>([])
  const [addJourneysDialogOpen, setAddJourneysDialogOpen] =
    useState<boolean>(false)

  // can't add journeys dialog
  const [cantAddJourneyDialogOpen, setCantAddJourneyDialogOpen] =
    useState<boolean>(false)

  // reset journeys list filters
  const resetJourneysFilters = () => {
    setSearchValue("")
    setSearchLanguage(null)
    setSearchTopic(null)
    setSearchSdg(null)
    setSearchSdgTarget(null)
    setSearchType(["Free", "Business"])
  }

  // drag and drop
  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result

    if (destination.index === source.index) {
      return
    }

    let sourceItem = currentFreeJourneysList[source.index]

    let indexToDelete = currentFreeJourneysList.findIndex(
      (item) => item.id === draggableId
    )
    currentFreeJourneysList.splice(indexToDelete, 1)
    currentFreeJourneysList.splice(destination.index, 0, sourceItem)

    setCurrentFreeJourneysList([...currentFreeJourneysList])
  }

  // premium journeys max number and dialog
  const journeysMaxNumber = 25
  const [maxNumberDialogOpen, setMaxNumberDialogOpen] = useState<boolean>(false)

  return (
    <EditContainer>
      <EditHeadingFade
        showBackButton={false}
        saveButtonLoading={controllerLoading}
        title="Manage free Journeys"
        showButtons
        showEditButton
        editModeActive={editMode}
        setEditModeActive={setEditMode}
        saveButtonDisabled={false}
        undoButtonDisabled={!doneChanges}
        doneChanges={doneChanges}
        undoButtonOnClick={cancelChanges}
        saveButtonOnClick={async () => {
          setControllerLoading(true)

          let input = {
            teamId: "team_default",
            journeyIds: currentFreeJourneysList.map((item: Journey) => item.id),
          }
          let noErrors = await upsertTeamJourneysRelation(input)

          if (noErrors) {
            noErrors = await getFreeJourneys()
            if (noErrors) {
              setControllerLoading(false)
              setChangesSaved(true)
            } else {
              setControllerLoading(false)
            }
          } else {
            setControllerLoading(false)
          }
        }}
      />
      <Fade
        in={animation}
        timeout={350}
        style={{ margin: showPadding ? 16 : 0, marginTop: 0 }}
      >
        <Card
          style={{
            height:
              showPadding === "yes"
                ? "calc(100vh - 151px)"
                : "calc(100vh - 135px)",
            overflowY: "scroll",
          }}
        >
          <CardContent style={{ paddingBottom: 16, position: "relative" }}>
            {loading && (
              <LinearProgress
                color="primary"
                style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
              />
            )}
            <Stack spacing={2}>
              {!currentFreeJourneysList.length && !loading ? (
                <Typography>No Journeys added</Typography>
              ) : (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="column-1">
                    {(provided) => (
                      <Stack
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {currentFreeJourneysList.map(
                          (journey: Journey, index: number) => (
                            <Draggable
                              key={journey.id.toString()}
                              draggableId={journey.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <Card
                                  style={{
                                    minHeight: 60,
                                    paddingLeft: 14,
                                  }}
                                  variant="outlined"
                                  key={journey.id}
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                  sx={{
                                    boxShadow: "none",
                                    borderTopLeftRadius:
                                      index === 0 ? undefined : 0,
                                    borderTopRightRadius:
                                      index === 0 ? undefined : 0,
                                    borderBottomLeftRadius:
                                      index ===
                                      currentFreeJourneysList.length - 1
                                        ? undefined
                                        : 0,
                                    borderBottomRightRadius:
                                      index ===
                                      currentFreeJourneysList.length - 1
                                        ? undefined
                                        : 0,
                                    borderTop: index === 0 ? undefined : "none",
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    style={{
                                      height: 60,
                                      position: "relative",
                                    }}
                                    alignItems="center"
                                    spacing={4}
                                  >
                                    <div
                                      style={{
                                        width: 30,
                                        height: 60,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        transition: "100ms",
                                        cursor: "grab",
                                        marginLeft:
                                          editMode && !loading ? 20 : -31,
                                        marginRight: -8,
                                      }}
                                      {...provided.dragHandleProps}
                                    >
                                      <DragHandleRounded />
                                    </div>
                                    <div
                                      style={{
                                        position: "relative",
                                        width: 48,
                                      }}
                                    >
                                      {journey.stage === "PUBLISHED" ||
                                      journey.hasSibling ? (
                                        <Chip
                                          size="small"
                                          label="Live"
                                          color="success"
                                          style={{
                                            position: "relative",
                                            zIndex: 1,
                                          }}
                                        />
                                      ) : journey.stage === "ARCHIVED" ? (
                                        <Chip
                                          size="small"
                                          label="Archived"
                                          style={{
                                            position: "relative",
                                            zIndex: 1,
                                          }}
                                        />
                                      ) : (
                                        <Chip
                                          size="small"
                                          label="Draft"
                                          color="warning"
                                          style={{
                                            position: "relative",
                                            zIndex: 1,
                                          }}
                                        />
                                      )}
                                      {journey.hasSibling ? (
                                        <Chip
                                          size="small"
                                          label="Draft"
                                          color="warning"
                                          style={{
                                            position: "absolute",
                                            left: 1,
                                            top: 1,
                                          }}
                                        />
                                      ) : null}
                                    </div>
                                    <div
                                      style={{
                                        minWidth: 40,
                                        height: 40,
                                        borderRadius: 4,
                                        backgroundImage: `url(${
                                          journey.image ?? noImagePlaceholder
                                        })`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                      }}
                                    />
                                    <Typography style={{ minWidth: 21 }}>
                                      {journey.lang.toUpperCase()}
                                    </Typography>
                                    <Typography
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        width: "50%",
                                      }}
                                    >
                                      {journey.title}
                                    </Typography>
                                    <IconButton
                                      style={{
                                        position: "absolute",
                                        right: 18,
                                        top: 9,
                                        transition: "100ms",
                                        zIndex: 1,
                                        opacity: editMode && !loading ? 1 : 0,
                                        cursor:
                                          editMode && !loading
                                            ? "pointer"
                                            : "default",
                                      }}
                                      onClick={(e) => {
                                        if (editMode && !loading) {
                                          e.preventDefault()
                                          e.stopPropagation()
                                          currentFreeJourneysList.splice(
                                            index,
                                            1
                                          )
                                          setCurrentFreeJourneysList([
                                            ...currentFreeJourneysList,
                                          ])
                                        }
                                      }}
                                      color="error"
                                    >
                                      <DeleteRounded
                                        color="error"
                                        style={{ width: 22 }}
                                      />
                                    </IconButton>
                                  </Stack>
                                </Card>
                              )}
                            </Draggable>
                          )
                        )}
                        {provided.placeholder}
                      </Stack>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
              <Button
                onClick={() => {
                  setJourneysList([])
                  setJourneysToFilter(currentFreeJourneysList)
                  setAddJourneysDialogOpen(true)
                }}
                variant="outlined"
                startIcon={<AddCircleRounded />}
                fullWidth
                disabled={!editMode || loading}
                style={{
                  marginTop:
                    loading && !currentFreeJourneysList.length ? 4 : null,
                }}
              >
                Add Journeys
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Fade>
      {/* add journey dialog */}
      <Dialog
        fullScreen
        open={addJourneysDialogOpen}
        onClose={() => {
          setAddJourneysDialogOpen(false)
          setSelectedJourneys([])
          resetJourneysFilters()
        }}
        TransitionComponent={DialogTransition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setAddJourneysDialogOpen(false)
                setSelectedJourneys([])
                resetJourneysFilters()
              }}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Journeys
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                // check if there are draft journey in selection
                for (let i = 0; i < selectedJourneys.length; i++) {
                  if (
                    selectedJourneys[i].stage === "DRAFT" &&
                    !selectedJourneys[i].hasSibling
                  ) {
                    setAddJourneysDialogOpen(false)
                    setSelectedJourneys([])
                    resetJourneysFilters()
                    setTimeout(() => {
                      setCantAddJourneyDialogOpen(true)
                    }, 150)
                    return
                  }
                }

                // check if premium journeys max number has been reached
                if (
                  currentFreeJourneysList.length + selectedJourneys.length >
                  journeysMaxNumber
                ) {
                  setAddJourneysDialogOpen(false)
                  setSelectedJourneys([])
                  resetJourneysFilters()
                  setTimeout(() => {
                    setMaxNumberDialogOpen(true)
                  }, 150)
                  return
                }

                setCurrentFreeJourneysList([
                  ...currentFreeJourneysList,
                  ...selectedJourneys,
                ])

                setAddJourneysDialogOpen(false)
                setSelectedJourneys([])
                resetJourneysFilters()
              }}
              disabled={!selectedJourneys.length}
            >
              Select
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ backgroundColor: "#f5f5f5" }}>
          <JourneysList
            forDialog
            forDialogType={["Free"]}
            forDialogTypeDisabled
            selectedJourneys={selectedJourneys}
            setSelectedJourneys={setSelectedJourneys}
            journeysToFilter={journeysToFilter}
          />
        </Paper>
      </Dialog>
      {/* can't add journeys dialog */}
      <Dialog
        open={cantAddJourneyDialogOpen}
        onClose={() => {
          setCantAddJourneyDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-cant-add-journey"
        aria-describedby="alert-dialog-description-cant-add-journey"
      >
        <DialogTitle id="alert-dialog-title-cant-add-journey">
          These Journeys cannot be added
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-cant-add-journey">
            There are one or more Journeys that are not published
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCantAddJourneyDialogOpen(false)
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* can't add journeys for max number dialog */}
      <CantPerformActionDialog
        open={maxNumberDialogOpen}
        setOpen={setMaxNumberDialogOpen}
        content={`The maximum number of Journeys is ${journeysMaxNumber}`}
      />
      {/* loading backdrop */}
      <LoadingBackdrop open={controllerLoading} />
    </EditContainer>
  )
}

export default FreeJourneysRelationEdit
