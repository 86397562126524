import { Alert, LinearProgress, Snackbar } from "@mui/material"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useLocation } from "react-router-dom"
import ListContainer from "../../components/global/listContainer"
import ListTable from "../../components/global/listTable"
import TableSpinner from "../../components/global/tableSpinner"
import { MainContext } from "../../controllers/main"
import { TeamsContext } from "../../controllers/teams"
import Team from "../../models/team"
import TeamsListTopBar from "../../components/teams/teamsListTopBar"
import CreateTeamDialog from "../../components/teams/createTeamDialog"
import TeamsListRow from "./teamsListRow"

const TeamsList = ({
  forDialog = false,
  teamsToFilter = [],
  selectedTeam,
  setSelectedTeam,
  selectedTeams = [],
  setSelectedTeams,
}: {
  forDialog?: boolean
  teamsToFilter?: Team[]
  selectedTeam?: Team
  setSelectedTeam?: Dispatch<SetStateAction<Team>>
  selectedTeams?: Team[]
  setSelectedTeams?: Dispatch<SetStateAction<Team[]>>
}) => {
  const { showPadding } = useContext(MainContext)
  const {
    loading,
    updatingList,
    setUpdatingList,
    currentTeamsList,
    getTeamsList,
    teamsListNextToken,
    loadMoreTeams,
    hasSearched,
    searchTeamsList,
    searchTitle,
    setSearchTitle,
    searchLang,
    searchStakeholderType,
    searchAccessType,
    searchIsPrimary,
    searchOrganizationType,
    searchStage,
  } = useContext(TeamsContext)
  const location = useLocation()

  // list background update
  const backgroundUpdate = () => {
    if (!currentTeamsList.length) {
      getTeamsList()
    } else if (!loading && !hasSearched) {
      setUpdatingList(true)
      getTeamsList(false)
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    backgroundUpdate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create team dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // handle team deleted feedback
  const [teamDeletedFeedbackOpen, setTeamDeletedFeedbackOpen] =
    useState<boolean>(false)

  useEffect(() => {
    if (location.state && (location.state as any).teamDeleted) {
      setTeamDeletedFeedbackOpen(true)
    }
  }, [])

  // load more button loading
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  return (
    <ListContainer style={{ position: "relative" }}>
      {updatingList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 143,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <TeamsListTopBar
        forDialog={forDialog}
        loading={loading}
        textFieldValue={searchTitle}
        textFieldPlaceholder="Search Teams"
        addButtonLabel="Add Team"
        textFieldOnChange={(e) => {
          setSearchTitle(e.target.value)
          if (e.target.value.length === 0 && hasSearched) {
            setUpdatingList(true)

            if (
              !searchLang &&
              !searchStakeholderType &&
              !searchAccessType &&
              !searchIsPrimary &&
              !searchOrganizationType &&
              !searchStage
            ) {
              getTeamsList(false)
            } else {
              searchTeamsList()
            }
          }
        }}
        textFieldOnKeyDown={(e) => {
          if (e.key === "Enter" && searchTitle.length > 2) {
            searchTeamsList()
          }
        }}
        cancelButtonOnClick={() => {
          setSearchTitle("")
          if (hasSearched) {
            setUpdatingList(true)

            if (
              !searchLang &&
              !searchStakeholderType &&
              !searchAccessType &&
              !searchIsPrimary &&
              !searchOrganizationType &&
              !searchStage
            ) {
              getTeamsList(false)
            } else {
              searchTeamsList()
            }
          }
        }}
        searchButtonOnClick={() => {
          searchTeamsList()
        }}
        addButtonOnClick={handleDialogChange}
      />
      {loading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
          headingItems={[
            "Image",
            "Title & ID",
            "Primary",
            "Translations",
            "Stage",
            "Access",
            "Stakeholder",
            "Organization",
            "Members",
            "Updated",
          ]}
          nextToken={teamsListNextToken}
          loadingMoreItems={loadMoreLoading}
          setLoadingMoreItems={setLoadMoreLoading}
          loadMore={
            !searchTitle.length &&
            !searchLang &&
            !searchStakeholderType &&
            !searchAccessType &&
            !searchIsPrimary &&
            !searchOrganizationType &&
            !searchStage
              ? loadMoreTeams
              : async () => {
                  const result = await searchTeamsList(true)
                  return result
                }
          }
          tableBody={currentTeamsList
            .filter((item) => {
              if (!teamsToFilter.filter((item2) => item2.id === item.id)[0]) {
                return item
              }
            })
            .map((item: Team) => (
              <TeamsListRow
                key={item.id}
                item={item}
                selectedTeam={selectedTeam}
                setSelectedTeam={setSelectedTeam}
                selectedTeams={selectedTeams}
                setSelectedTeams={setSelectedTeams}
                forDialog={forDialog}
              />
            ))}
        />
      )}
      <CreateTeamDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
        topBarText="Add Team"
      />
      <Snackbar
        open={teamDeletedFeedbackOpen}
        onClose={() => {
          setTeamDeletedFeedbackOpen(false)
        }}
        autoHideDuration={3000}
      >
        <Alert severity="success">Team deleted</Alert>
      </Snackbar>
    </ListContainer>
  )
}

export default TeamsList
