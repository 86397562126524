import { useContext, useEffect, useState } from "react"
import { EpisodesContext } from "../../controllers/episodes"
import EpisodeSlide from "../../models/episodeSlide"
import SlideConstraints from "../../models/slideConstraints"
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import {
  onSlideBackgroundChange,
  onSlideCaptionTextFieldChange,
  onSlideCaptionTitleTextFieldChange,
  onSlideEmbedURLTextFieldChange,
  onSlideTextColorTextFieldChange,
} from "../../services/utilities/episodeUtility"
import { deepCopy, testYtURL } from "../../services/utilities/utility"
import HtmlEditor from "../global/htmlEditor"
import BigPreview from "../episode/bigPreview"
import ImagePicker from "../global/imagePicker"
import { textColors } from "../../services/config/colorsForSlides"
import { Slide } from "@aworld/app-preview"

const EmbedSlide = ({
  slide,
  language,
  index,
  constraints,
  changesCancelled,
}: {
  slide: EpisodeSlide
  language: string
  index: number
  constraints: SlideConstraints
  changesCancelled: boolean
}) => {
  const {
    currentEpisode,
    setCurrentEpisode,
    slidesErrors,
    setSlidesErrors,
    editMode,
  } = useContext(EpisodesContext)

  // errors
  const [textError, setTextError] = useState<boolean>(false)
  const [textErrorType, setTextErrorType] = useState<string>("")
  const [captionTitleError, setCaptionTitleError] = useState<boolean>(false)
  const [captionTitleErrorType, setCaptionTitleErrorType] = useState<string>("")
  const [captionError, setCaptionError] = useState<boolean>(false)
  const [captionErrorType, setCaptionErrorType] = useState<string>("")
  const [embedUrlError, setEmbedUrlError] = useState<boolean>(false)

  // check errors
  const [isFirstRender, setisFirstRender] = useState<boolean>(true)

  useEffect(() => {
    if (isFirstRender) {
      setisFirstRender(false)

      // first errors check
      if (
        slide.text.replace(/(<([^>]+)>)/gi, "").length >
        constraints.text.maxSize
      ) {
        setTextError(true)
        setTextErrorType(
          "Maximum length is " + constraints.text.maxSize + " characters"
        )
      }
      if (
        slide.text.replace(/(<([^>]+)>)/gi, "").length <
        constraints.text.minSize
      ) {
        setTextError(true)
        setTextErrorType(
          "Minimum length is " + constraints.text.minSize + " characters"
        )
      }
      if (slide.captionTitle.length > constraints.captionTitle.maxSize) {
        setCaptionTitleError(true)
        setCaptionTitleErrorType(
          "Maximum length is " +
            constraints.captionTitle.maxSize +
            " characters"
        )
      }
      if (slide.captionTitle.length < constraints.captionTitle.minSize) {
        setCaptionTitleError(true)
        setCaptionTitleErrorType(
          "Minimum length is " +
            constraints.captionTitle.minSize +
            " characters"
        )
      }
      if (slide.caption.length > constraints.caption.maxSize) {
        setCaptionError(true)
        setCaptionErrorType(
          "Maximum length is " + constraints.caption.maxSize + " characters"
        )
      }
      if (slide.caption.length < constraints.caption.minSize) {
        setCaptionError(true)
        setCaptionErrorType(
          "Minimum length is " + constraints.caption.minSize + " characters"
        )
      }
      if ((slide.embedUrl && !testYtURL(slide.embedUrl)) || !slide.embedUrl) {
        setEmbedUrlError(true)
      }

      return
    }

    let newSlidesErrors: {
      lang: string
      slides: { slideId: string; error: boolean }[]
    }[] = deepCopy(slidesErrors)

    if (!textError && !captionTitleError && !captionError && !embedUrlError) {
      newSlidesErrors
        .filter((item) => item.lang === language)[0]
        .slides.filter((item) => item.slideId === slide.id)[0].error = false
      setSlidesErrors(newSlidesErrors)
    } else {
      newSlidesErrors
        .filter((item) => item.lang === language)[0]
        .slides.filter((item) => item.slideId === slide.id)[0].error = true
      setSlidesErrors(newSlidesErrors)
    }
  }, [textError, captionTitleError, captionError, embedUrlError])

  // changes cancelled
  useEffect(() => {
    if (changesCancelled) {
      setTextError(false)
      setTextErrorType("")
      setCaptionTitleError(false)
      setCaptionTitleErrorType("")
      setCaptionError(false)
      setCaptionErrorType("")
      setEmbedUrlError(false)
    }
  }, [changesCancelled])

  // render iframe
  const [renderIframe, setRenderIframe] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setRenderIframe(true)
    }, 350)
  }, [])

  // big preview
  const [isBigPreviewOpen, setIsBigPreviewOpen] = useState<boolean>(false)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8}>
        <Stack spacing={2}>
          <Typography variant="h6" className="card-subtitle">
            Text
          </Typography>
          <HtmlEditor
            disabled={!editMode}
            slide={slide}
            constraints={constraints}
            currentEpisode={currentEpisode}
            setCurrentEpisode={setCurrentEpisode}
            slideLanguage={language}
            index={index}
            error={textError}
            setError={setTextError}
            errorType={textErrorType}
            setErrorType={setTextErrorType}
            changesCancelled={changesCancelled}
          />
          <Stack direction="row" spacing={2}>
            <TextField
              disabled={!editMode}
              value={slide.captionTitle ? slide.captionTitle : ""}
              fullWidth
              label="Caption title"
              variant="outlined"
              size="small"
              error={captionTitleError}
              helperText={captionTitleErrorType}
              onChange={(e: any) => {
                onSlideCaptionTitleTextFieldChange(
                  currentEpisode,
                  setCurrentEpisode,
                  e,
                  language,
                  slide,
                  index
                )
                if (e.target.value.length > constraints.captionTitle.maxSize) {
                  setCaptionTitleError(true)
                  setCaptionTitleErrorType(
                    "Maximum length is " +
                      constraints.captionTitle.maxSize +
                      " characters"
                  )
                } else if (
                  e.target.value.length < constraints.captionTitle.minSize
                ) {
                  setCaptionTitleError(true)
                  setCaptionTitleErrorType(
                    "Minimum length is " +
                      constraints.captionTitle.minSize +
                      " characters"
                  )
                } else {
                  setCaptionTitleError(false)
                  setCaptionTitleErrorType("")
                }
              }}
            />
            <TextField
              disabled={!editMode}
              value={slide.caption ? slide.caption : ""}
              fullWidth
              label="Caption"
              variant="outlined"
              size="small"
              error={captionError}
              helperText={captionErrorType}
              onChange={(e: any) => {
                onSlideCaptionTextFieldChange(
                  currentEpisode,
                  setCurrentEpisode,
                  e,
                  language,
                  slide,
                  index
                )
                if (e.target.value.length > constraints.caption.maxSize) {
                  setCaptionError(true)
                  setCaptionErrorType(
                    "Maximum length is " +
                      constraints.caption.maxSize +
                      " characters"
                  )
                } else if (
                  e.target.value.length < constraints.caption.minSize
                ) {
                  setCaptionError(true)
                  setCaptionErrorType(
                    "Minimum length is " +
                      constraints.caption.minSize +
                      " characters"
                  )
                } else {
                  setCaptionError(false)
                  setCaptionErrorType("")
                }
              }}
            />
          </Stack>
          <FormControl fullWidth disabled={!editMode}>
            <InputLabel id="text-color-label-embedSlide" size="small">
              Text color
            </InputLabel>
            <Select
              size="small"
              labelId="text-color-label-embedSlide"
              value={slide.textColor ? slide.textColor : ""}
              label="Text color"
              onChange={(e: any) => {
                onSlideTextColorTextFieldChange(
                  currentEpisode,
                  setCurrentEpisode,
                  e.target.value,
                  language,
                  slide,
                  index
                )
              }}
            >
              {textColors.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="h6" className="card-subtitle">
            Background
          </Typography>
          <ImagePicker
            filters={{
              category: "background",
            }}
            disabled={!editMode}
            title="Background"
            pickedImage={slide.background ? slide.background : ""}
            setPickedImage={(selectedImage: {
              url: string
              id: string
              description?: string
            }) => {
              onSlideBackgroundChange(
                currentEpisode,
                setCurrentEpisode,
                selectedImage,
                language,
                slide,
                index
              )
            }}
            width="100%"
          />
          <Typography variant="h6" className="card-subtitle">
            Video
          </Typography>
          <TextField
            disabled={!editMode}
            error={embedUrlError}
            helperText={embedUrlError ? "Invalid URL" : ""}
            value={slide.embedUrl ? slide.embedUrl : ""}
            fullWidth
            label="Embed URL"
            variant="outlined"
            size="small"
            onChange={(e: any) => {
              onSlideEmbedURLTextFieldChange(
                currentEpisode,
                setCurrentEpisode,
                e,
                language,
                slide,
                index
              )
              if (!testYtURL(e.target.value)) {
                setEmbedUrlError(true)
              } else {
                setEmbedUrlError(false)
              }
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Stack spacing={2}>
          <Typography variant="h6" className="card-subtitle">
            Preview
          </Typography>
          <div
            style={{
              zoom: 0.6,
              marginTop: 30,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              maxHeight: "100%",
              cursor: "pointer",
              opacity: isBigPreviewOpen ? 0 : 1,
              transition: "200ms",
            }}
            onClick={() => {
              setIsBigPreviewOpen(true)
            }}
          >
            <Slide
              slide={slide}
              constraints={constraints}
              className="preview"
              primaryTopicName={
                currentEpisode.topics.find((topic) => topic.primary)
                  ? currentEpisode.topics.find((topic) => topic.primary).topic
                      .name
                  : null
              }
              episodeTitle={
                currentEpisode.translations.find(
                  (item) => item.lang === language
                ).title ?? null
              }
              numberOfSlides={
                currentEpisode.translations.find(
                  (item) => item.lang === language
                ).slides.length
              }
              numberOfQuiz={
                currentEpisode.translations.find(
                  (item) => item.lang === language
                ).quiz.length
              }
              currentSlide={index}
            />
          </div>
        </Stack>
      </Grid>
      <BigPreview
        isBigPreviewOpen={isBigPreviewOpen}
        setIsBigPreviewOpen={setIsBigPreviewOpen}
      >
        <div
          style={{
            zoom: window.innerHeight / 950,
            marginTop: 70,
          }}
        >
          <Slide
            slide={slide}
            constraints={constraints}
            className="preview"
            primaryTopicName={
              currentEpisode.topics.find((topic) => topic.primary)
                ? currentEpisode.topics.find((topic) => topic.primary).topic
                    .name
                : null
            }
            episodeTitle={
              currentEpisode.translations.find((item) => item.lang === language)
                .title ?? null
            }
            numberOfSlides={
              currentEpisode.translations.find((item) => item.lang === language)
                .slides.length
            }
            numberOfQuiz={
              currentEpisode.translations.find((item) => item.lang === language)
                .quiz.length
            }
            currentSlide={index}
          />
        </div>
      </BigPreview>
    </Grid>
  )
}

export default EmbedSlide
