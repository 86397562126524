import NewsDocument from "./newsDocument"

class News {
  id?: string
  startsAt?: string
  endsAt?: string
  updatedAt?: string
  teamId?: string
  points?: number
  document?: { items: NewsDocument[]; parentId: string }

  constructor(
    id?: string,
    startsAt?: string,
    endsAt?: string,
    updatedAt?: string,
    teamId?: string,
    points?: number,
    document?: { items: NewsDocument[]; parentId: string }
  ) {
    this.id = id
    this.startsAt = startsAt
    this.endsAt = endsAt
    this.updatedAt = updatedAt
    this.teamId = teamId
    this.points = points
    this.document = document
  }
}

export default News
