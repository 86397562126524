import {
  Alert,
  Chip,
  LinearProgress,
  Snackbar,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import { useCallback, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ListContainer from "../../components/global/listContainer"
import ListTable from "../../components/global/listTable"
import TableSpinner from "../../components/global/tableSpinner"
import { MainContext } from "../../controllers/main"
import { CountriesContext } from "../../controllers/countries"
import ActionGroupsListTopBar from "../../components/actionGroup/actionGroupsListTopBar"
import { ActionGroupsContext } from "../../controllers/actionGroups"
import CreateActionGroupDialog from "../../components/actionGroup/createActionGroupDialog"
import ActionGroup from "../../models/actionGroup"
import {
  calculateTimeElapsed,
  registerAnalyticsEvent,
} from "../../services/utilities/utility"
import TitleTableCell from "../../components/global/titleTableCell"
import TranslationsStack from "../../components/global/translationsStack"

const ActionGroupsList = () => {
  const { setAnimation, showPadding, cmdPressed } = useContext(MainContext)
  const { loading: countriesControllerLoading } = useContext(CountriesContext)
  const {
    loading,
    updatingList,
    setUpdatingList,
    actionGroupsList,
    getActionGroupsList,
    actionGroupsListNextToken,
    loadMoreActionGroups,
    hasSearched,
    searchCanBecomeHabit,
    searchLang,
    searchCategory,
    searchFeatured,
    searchTitle,
    setSearchTitle,
    searchActionGroupsList,
  } = useContext(ActionGroupsContext)
  const navigate = useNavigate()
  const location = useLocation()

  // list background update
  const backgroundUpdate = () => {
    if (!actionGroupsList.length) {
      getActionGroupsList()
    } else if (!loading && !hasSearched) {
      setUpdatingList(true)
      getActionGroupsList(false)
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    backgroundUpdate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create action dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // handle action group deleted feedback
  const [actionGroupDeletedFeedbackOpen, setActionGroupDeletedFeedbackOpen] =
    useState<boolean>(false)

  useEffect(() => {
    if (location.state && (location.state as any).actionGroupDeleted) {
      setActionGroupDeletedFeedbackOpen(true)
    }
  }, [])

  // load more button loading
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // register analytics event
  useEffect(() => {
    registerAnalyticsEvent({ type: "page_view", name: "action_groups_list" })
  }, [])

  return (
    <ListContainer style={{ position: "relative" }}>
      {updatingList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 143,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <ActionGroupsListTopBar
        loading={loading}
        textFieldValue={searchTitle}
        textFieldPlaceholder="Search Series"
        addButtonLabel="Add Series"
        addButtonDisabled={countriesControllerLoading}
        textFieldOnChange={(e) => {
          setSearchTitle(e.target.value)
          if (e.target.value.length === 0 && hasSearched) {
            setUpdatingList(true)

            if (
              searchCanBecomeHabit === null &&
              !searchLang &&
              !searchCategory &&
              searchFeatured === null
            ) {
              getActionGroupsList(false)
            } else {
              searchActionGroupsList()
            }
          }
        }}
        textFieldOnKeyDown={(e) => {
          if (e.key === "Enter" && searchTitle.length > 2) {
            searchActionGroupsList()
          }
        }}
        cancelButtonOnClick={() => {
          setSearchTitle("")
          if (hasSearched) {
            setUpdatingList(true)

            if (
              searchCanBecomeHabit === null &&
              !searchLang &&
              !searchCategory &&
              searchFeatured === null
            ) {
              getActionGroupsList(false)
            } else {
              searchActionGroupsList()
            }
          }
        }}
        searchButtonOnClick={() => {
          searchActionGroupsList()
        }}
        addButtonOnClick={handleDialogChange}
      />
      {loading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
          headingItems={[
            "Image",
            "Title & ID",
            "Translations",
            "Habit",
            "Featured",
            "Category",
            "Badge",
            "Updated",
          ]}
          nextToken={actionGroupsListNextToken}
          loadingMoreItems={loadMoreLoading}
          setLoadingMoreItems={setLoadMoreLoading}
          loadMore={
            !searchTitle.length &&
            searchCanBecomeHabit === null &&
            !searchLang &&
            !searchCategory &&
            searchFeatured === null
              ? loadMoreActionGroups
              : async () => {
                  const result = await searchActionGroupsList(false, true)
                  return result
                }
          }
          tableBody={actionGroupsList.map((item: ActionGroup) => (
            <TableRow
              key={item.id}
              style={{ cursor: "pointer" }}
              hover
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
              onClick={() => {
                if (cmdPressed) {
                  window.open(`${window.location.href}/${item.id}`)
                } else {
                  setAnimation(false)
                  setTimeout(() => {
                    navigate(`/series/${item.id}`)
                  }, 250)
                }
              }}
            >
              <TableCell>
                <div
                  style={{
                    width: 42,
                    height: 42,
                    borderRadius: 4,
                    backgroundImage: `url(${item.image ?? noImagePlaceholder})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </TableCell>
              <TitleTableCell
                title={
                  item.document.items.filter(
                    (documentItem) => documentItem.isDefault
                  )[0].title
                }
                id={item.id}
              />
              <TableCell>
                <TranslationsStack documentItems={item.document.items} />
              </TableCell>
              <TableCell>
                <Chip
                  size="small"
                  label={item.canBecomeHabit ? "Yes" : "No"}
                  color={item.canBecomeHabit ? "success" : "warning"}
                />
              </TableCell>
              <TableCell>
                <Chip
                  size="small"
                  label={item.featured ? "Yes" : "No"}
                  color={item.featured ? "success" : "warning"}
                />
              </TableCell>
              <TableCell>
                <Chip
                  size="small"
                  label={item.category.name}
                  style={{
                    backgroundColor: item.category.backColor,
                    color: item.category.foreColor,
                  }}
                />
              </TableCell>
              <TableCell>
                {item.badge ? (
                  <div
                    style={{
                      width: 42,
                      height: 42,
                      borderRadius: 4,
                      backgroundImage: `url(${item.badge.image})`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                ) : (
                  "No badge"
                )}
              </TableCell>
              <TableCell style={{ minWidth: 110 }}>
                <Typography variant="caption">
                  {calculateTimeElapsed(item.updatedAt)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        />
      )}
      <CreateActionGroupDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
        topBarText="Add Series"
      />
      <Snackbar
        open={actionGroupDeletedFeedbackOpen}
        onClose={() => {
          setActionGroupDeletedFeedbackOpen(false)
        }}
        autoHideDuration={3000}
      >
        <Alert severity="success">Series deleted</Alert>
      </Snackbar>
    </ListContainer>
  )
}

export default ActionGroupsList
