import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material"
import { DialogTransition } from "../../services/utilities/utility"
import { CloseRounded } from "@mui/icons-material"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { IEmailTemplate } from "easy-email-editor"
import MjmlEditor from "./mjmlEditor"

const RichContentEditDialog = ({
  open,
  setOpen,
  template,
  dataJson,
  onEditorSubmit,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  template: IEmailTemplate
  dataJson: JSON
  onEditorSubmit?: (values: IEmailTemplate) => void
}) => {
  // editor submit button ref
  const submitButtonRef = useRef<HTMLButtonElement>(null)

  // delay to make editor appear, this is done to avoid dialog animation lag
  const [showEditor, setShowEditor] = useState<boolean>(false)

  useEffect(() => {
    if (!open) {
      setShowEditor(false)
    } else {
      setShowEditor(true)
    }
  }, [open])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setDiscardDialogOpen(true)
      }}
      TransitionComponent={DialogTransition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setDiscardDialogOpen(true)
            }}
            aria-label="close"
          >
            <CloseRounded />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Rich content editor
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => {
              if (submitButtonRef.current) {
                submitButtonRef.current.click()
                setOpen(false)
              }
            }}
          >
            Confirm
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent className="center" style={{ paddingInline: 0 }}>
        <div style={{ width: "100%" }}>
          {showEditor && (
            <MjmlEditor
              template={template}
              dataJson={dataJson}
              onSubmit={onEditorSubmit}
              height="91.5vh"
              ref={submitButtonRef}
            />
          )}
        </div>
      </DialogContent>
      {/* discard dialog */}
      <Dialog
        open={discardDialogOpen}
        onClose={() => {
          setDiscardDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDiscardDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setDiscardDialogOpen(false)
              setOpen(false)
            }}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  )
}

export default RichContentEditDialog
