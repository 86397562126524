import {
  Button,
  CardContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { MainContext } from "../../controllers/main"
import EmbedSlice from "../../models/bodySlices/embedSlice"
import ImageSlice from "../../models/bodySlices/imageSlice"
import QuoteSlice from "../../models/bodySlices/quoteSlice"
import TextSlice from "../../models/bodySlices/textSlice"
import BlockEditor from "../blockEditor/blockEditor"
import { BodySliceType } from "../../services/config/enum"
import {
  deepCopy,
  isValidURL,
  stripHtml,
  testYtURL,
} from "../../services/utilities/utility"
import { TeamsContext } from "../../controllers/teams"
import Team from "../../models/team"
import ImagePicker from "../global/imagePicker"

const TeamTranslationsEdit = ({ itemIndex }: { itemIndex: number }) => {
  const { showPadding } = useContext(MainContext)
  const {
    currentTeam,
    setCurrentTeam,
    editMode,
    translationsErrors,
    setTranslationsErrors,
    copyDetailsFromDefault,
    copyBodyFromDefault,
  } = useContext(TeamsContext)

  // current item
  const currentItem = currentTeam.document.items[itemIndex]

  // errors managment
  const [titleError, setTitleError] = useState<boolean>(false) // error for title
  const [bodyError, setBodyError] = useState<boolean>(false) // error for body

  // update translations errors on errors change
  useEffect(() => {
    if (titleError || bodyError) {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = true
    } else {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = false
    }

    setTranslationsErrors([...translationsErrors])
  }, [titleError, bodyError])

  // update errors on currentItem change
  useEffect(() => {
    if (currentItem.title.length < 3 || currentItem.title.length > 60) {
      setTitleError(true)
    } else {
      setTitleError(false)
    }

    let errorInBody = false
    if (currentItem.body) {
      currentItem.body.forEach((bodyItem) => {
        if (
          (bodyItem.sliceType === BodySliceType.BodyTextSlice &&
            !stripHtml(bodyItem.text).length) ||
          (bodyItem.sliceType === BodySliceType.BodyQuoteSlice &&
            !bodyItem.quoteText.length) ||
          (bodyItem.sliceType === BodySliceType.BodyImageSlice &&
            (!bodyItem.imgUrl.length || !isValidURL(bodyItem.imgUrl))) ||
          (bodyItem.sliceType === BodySliceType.BodyEmbedSlice &&
            (!bodyItem.embedUrl.length || !testYtURL(bodyItem.embedUrl)))
        ) {
          errorInBody = true
        }
      })
    }
    if (errorInBody) {
      setBodyError(true)
    } else {
      setBodyError(false)
    }
  }, [currentItem])

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" style={{ height: 28 }}>
          <Typography variant="h6" className="card-title">
            Details
          </Typography>
          <Stack
            alignItems="center"
            justifyContent="flex-end"
            style={{ width: "100%" }}
            direction="row"
            spacing={2}
          >
            <FormGroup style={{ marginRight: -16 }}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editMode}
                    checked={currentItem.isDefault}
                    onChange={(e) => {
                      const currentTeamCopy: Team = deepCopy(currentTeam)
                      currentTeamCopy.document.items[itemIndex].isDefault =
                        e.target.checked
                      if (e.target.checked) {
                        currentTeamCopy.document.items.forEach(
                          (item, index) => {
                            if (index !== itemIndex) {
                              item.isDefault = false
                            }
                          }
                        )
                      }
                      setCurrentTeam(currentTeamCopy)
                    }}
                  />
                }
                label="Default"
              />
            </FormGroup>
            <Divider orientation="vertical" flexItem />
            <Button
              size="small"
              variant="outlined"
              style={{ height: 26, fontSize: 12 }}
              onClick={() => {
                copyDetailsFromDefault(itemIndex)
              }}
              disabled={
                currentItem.isDefault ||
                !editMode ||
                !currentTeam.document.items.filter((item) => item.isDefault)
                  .length
              }
            >
              Copy from default
            </Button>
          </Stack>
        </Stack>
        <TextField
          fullWidth
          size="small"
          label="Title"
          value={currentItem.title}
          onChange={(e) => {
            const currentTeamCopy: Team = deepCopy(currentTeam)
            currentTeamCopy.document.items[itemIndex].title = e.target.value
            setCurrentTeam(currentTeamCopy)

            // errors check
            if (e.target.value.length < 3 || e.target.value.length > 60) {
              setTitleError(true)
            } else {
              setTitleError(false)
            }
          }}
          disabled={!editMode}
          error={titleError}
          helperText={
            currentItem.title.length < 3
              ? "Minimum length is 3 characters"
              : currentItem.title.length > 60
              ? "Maximum length is 60 characters"
              : ""
          }
          onBlur={() => {
            if (currentTeam.document.items[itemIndex].title) {
              const currentTeamCopy: Team = deepCopy(currentTeam)
              currentTeamCopy.document.items[itemIndex].title =
                currentTeamCopy.document.items[itemIndex].title.trim()
              setCurrentTeam(currentTeamCopy)
            }
          }}
        />
        <Stack direction="row" spacing={2} style={{ width: "100%" }}>
          <ImagePicker
            title="Logo"
            pickedImage={currentItem.logo}
            setPickedImage={(selectedImage: {
              url: string
              id: string
              description?: string
            }) => {
              const currentTeamCopy: Team = deepCopy(currentTeam)
              currentTeamCopy.document.items[itemIndex].logo = selectedImage.url
              setCurrentTeam(currentTeamCopy)
            }}
            filters={{ category: "thumbnail" }}
            disableCategoryFilter
            height={190}
            width="100%"
            placeholderHeight={120}
            placeholderWidth="35%"
            disabled={!editMode}
          />
          <ImagePicker
            title="Cover"
            pickedImage={currentItem.cover}
            setPickedImage={(selectedImage: {
              url: string
              id: string
              description?: string
            }) => {
              const currentTeamCopy: Team = deepCopy(currentTeam)
              currentTeamCopy.document.items[itemIndex].cover =
                selectedImage.url
              setCurrentTeam(currentTeamCopy)
            }}
            filters={{ category: "other" }}
            disableCategoryFilter
            height={190}
            width="100%"
            placeholderHeight={100}
            placeholderWidth="80%"
            disabled={!editMode}
          />
        </Stack>
        <Stack direction="row" style={{ height: 28 }}>
          <Typography
            variant="h6"
            className="card-title"
            style={{ whiteSpace: "nowrap" }}
          >
            Home details
          </Typography>
          <Stack
            alignItems="center"
            justifyContent="flex-end"
            style={{ width: "100%" }}
            direction="row"
          >
            <Button
              size="small"
              variant="outlined"
              style={{ height: 26, fontSize: 12 }}
              onClick={() => {
                copyDetailsFromDefault(itemIndex)
              }}
              disabled={
                currentItem.isDefault ||
                !editMode ||
                !currentTeam.document.items.filter((item) => item.isDefault)
                  .length
              }
            >
              Copy from default
            </Button>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            size="small"
            label="Title"
            value={
              currentItem.home && currentItem.home.title
                ? currentItem.home.title
                : ""
            }
            onChange={(e) => {
              const currentTeamCopy: Team = deepCopy(currentTeam)
              if (currentTeamCopy.document.items[itemIndex].home) {
                currentTeamCopy.document.items[itemIndex].home.title =
                  e.target.value
              } else {
                currentTeamCopy.document.items[itemIndex].home = {
                  title: e.target.value,
                  subtitle: "",
                  image: "",
                }
              }
              setCurrentTeam(currentTeamCopy)
            }}
            disabled={!editMode}
            onBlur={() => {
              if (
                currentTeam.document.items[itemIndex].home &&
                currentTeam.document.items[itemIndex].home.title
              ) {
                const currentTeamCopy: Team = deepCopy(currentTeam)
                currentTeamCopy.document.items[itemIndex].home.title =
                  currentTeamCopy.document.items[itemIndex].home.title.trim()
                setCurrentTeam(currentTeamCopy)
              }
            }}
          />
          <TextField
            fullWidth
            size="small"
            label="Subtitle"
            value={
              currentItem.home && currentItem.home.subtitle
                ? currentItem.home.subtitle
                : ""
            }
            onChange={(e) => {
              const currentTeamCopy: Team = deepCopy(currentTeam)
              if (currentTeamCopy.document.items[itemIndex].home) {
                currentTeamCopy.document.items[itemIndex].home.subtitle =
                  e.target.value
              } else {
                currentTeamCopy.document.items[itemIndex].home = {
                  title: "",
                  subtitle: e.target.value,
                  image: "",
                }
              }
              setCurrentTeam(currentTeamCopy)
            }}
            disabled={!editMode}
            onBlur={() => {
              if (
                currentTeam.document.items[itemIndex].home &&
                currentTeam.document.items[itemIndex].home.subtitle
              ) {
                const currentTeamCopy: Team = deepCopy(currentTeam)
                currentTeamCopy.document.items[itemIndex].home.subtitle =
                  currentTeamCopy.document.items[itemIndex].home.subtitle.trim()
                setCurrentTeam(currentTeamCopy)
              }
            }}
          />
        </Stack>
        <ImagePicker
          title="Image"
          pickedImage={
            currentItem.home && currentItem.home.image
              ? currentItem.home.image
              : ""
          }
          setPickedImage={(selectedImage: {
            url: string
            id: string
            description?: string
          }) => {
            const currentTeamCopy: Team = deepCopy(currentTeam)
            if (currentTeamCopy.document.items[itemIndex].home) {
              currentTeamCopy.document.items[itemIndex].home.image =
                selectedImage.url
            } else {
              currentTeamCopy.document.items[itemIndex].home = {
                title: "",
                subtitle: "",
                image: selectedImage.url,
              }
            }
            setCurrentTeam(currentTeamCopy)
          }}
          filters={{ category: "thumbnail" }}
          disableCategoryFilter
          height={190}
          width="100%"
          placeholderHeight={120}
          placeholderWidth="35%"
          disabled={!editMode}
        />
        <Stack spacing={2}>
          <Stack direction="row" style={{ height: 28 }}>
            <Typography variant="h6" className="card-title">
              Body
            </Typography>
            <Stack
              alignItems="center"
              justifyContent="flex-end"
              style={{ width: "100%" }}
              direction="row"
              spacing={2}
            >
              <Button
                size="small"
                variant="outlined"
                style={{ height: 26, fontSize: 12 }}
                onClick={() => {
                  copyBodyFromDefault(itemIndex)
                }}
                disabled={
                  currentItem.isDefault ||
                  !editMode ||
                  !currentTeam.document.items.filter((item) => item.isDefault)
                    .length
                }
              >
                Copy from default
              </Button>
            </Stack>
          </Stack>
          <BlockEditor
            body={currentItem.body ?? []}
            setBody={(
              body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
            ) => {
              const currentTeamCopy: Team = deepCopy(currentTeam)
              currentTeamCopy.document.items[itemIndex].body = body
              setCurrentTeam(currentTeamCopy)
            }}
            editMode={editMode}
          />
        </Stack>
      </Stack>
    </CardContent>
  )
}

export default TeamTranslationsEdit
