import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import { TagsContext } from "../../../controllers/tags"
import { CardContent, Stack, TextField, Typography } from "@mui/material"
import ImagePicker from "../../global/imagePicker"

const SdgGeneralEdit = ({
  errors,
}: {
  errors: { type: string; error: boolean }[]
}) => {
  const { showPadding } = useContext(MainContext)
  const { currentSdg, setCurrentSdg, editMode } = useContext(TagsContext)

  // errors
  const nameError = errors.filter((item) => item.type === "name")[0].error

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Text
        </Typography>
        <TextField
          label="Name"
          value={currentSdg.name}
          onChange={(e) => {
            currentSdg.name = e.target.value
            setCurrentSdg({ ...currentSdg })
          }}
          size="small"
          onBlur={() => {
            currentSdg.name = currentSdg.name.trim()
            setCurrentSdg({ ...currentSdg })
          }}
          disabled={!editMode}
          error={nameError}
          helperText={nameError ? "This field is required" : ""}
        />
        <Typography variant="h6" className="card-title">
          Image
        </Typography>
        <ImagePicker
          title="Image"
          pickedImage={currentSdg.image}
          setPickedImage={(selectedImage: {
            url: string
            id: string
            description?: string
          }) => {
            currentSdg.image = selectedImage.url
            setCurrentSdg({ ...currentSdg })
          }}
          filters={{ category: "thumbnail" }}
          disableCategoryFilter
          height={200}
        />
      </Stack>
    </CardContent>
  )
}

export default SdgGeneralEdit
