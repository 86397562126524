import { LoadingButton } from "@mui/lab"
import {
  Button,
  CardActions,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Dialog,
  MenuItem,
  Select,
  AppBar,
  Toolbar,
  IconButton,
  Autocomplete,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  capitalizeFirstCharacter,
  DialogTransition,
  isValidURL,
  lowercaseFirstCharacter,
  renderOption,
  renderTags,
  stripHtml,
  testYtURL,
} from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import { useNavigate } from "react-router-dom"
import { MainContext } from "../../controllers/main"
import ImagePicker from "../global/imagePicker"
import {
  BodySliceType,
  ChallengeType,
  ChallengeVersionType,
  TeamAccessType,
} from "../../services/config/enum"
import { LanguagesContext } from "../../controllers/languages"
import EmbedSlice from "../../models/bodySlices/embedSlice"
import ImageSlice from "../../models/bodySlices/imageSlice"
import QuoteSlice from "../../models/bodySlices/quoteSlice"
import TextSlice from "../../models/bodySlices/textSlice"
import BlockEditor from "../blockEditor/blockEditor"
import { ChallengesContext } from "../../controllers/challenges"
import slugify from "react-slugify"
import { CloseRounded } from "@mui/icons-material"
import TeamsList from "../../views/team/teamsList"
import ISODatePicker from "../global/isoDatePicker"
import { MetricsContext } from "../../controllers/metrics"
import Team from "../../models/team"
import { TeamsContext } from "../../controllers/teams"
import { AutocompleteOption } from "../../services/config/interfaces"
import ISOTimePicker from "../global/isoTimePicker"
import Challenge from "../../models/challenge"
import BlockEditorPrizes from "../blockEditorPrizes/blockEditorPrizes"
import { mobilityMetrics } from "../../services/config/constants"

const CreateChallengeDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
  topBarText,
  preSelectedTeam,
  currentTeamChallengesList,
  setCurrentTeamChallengesList,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
  topBarText: string
  preSelectedTeam?: Team
  currentTeamChallengesList?: Challenge[]
  setCurrentTeamChallengesList?: Dispatch<SetStateAction<Challenge[]>>
}) => {
  const { setAnimation } = useContext(MainContext)
  const {
    loading,
    setLoading,
    createChallengeParent,
    createChallengeDocument,
  } = useContext(ChallengesContext)
  const { languages, languagesForEpisodeTabs } = useContext(LanguagesContext)
  const { resetTeamsFilters } = useContext(TeamsContext)
  const { metricsLoading, metricsList } = useContext(MetricsContext)
  const navigate = useNavigate()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // configuration
  const [name, setName] = useState<string>("")
  const [uid, setUid] = useState<string>("")
  const [type, setType] = useState<ChallengeType | null>(
    preSelectedTeam ? ChallengeType.reserved : null
  )
  const [targetAmount, setTargetAmount] = useState<number | null>(1)
  const [personalTargetAmount, setPersonalTargetAmount] = useState<
    number | null
  >(null)
  const [personalTargetPoints, setPersonalTargetPoints] = useState<
    number | null
  >(300)
  const [metric, setMetric] = useState<AutocompleteOption | null>(null)
  const [team, setTeam] = useState<Team | null>(
    preSelectedTeam ? preSelectedTeam : null
  )
  const [leaderboardGroupType, setLeaderboardGroupType] = useState<
    "average" | "sum"
  >("average")
  const [leaderboardMinMembers, setLeaderboardMinMembers] = useState<number>(10)
  const [privacyPolicy, setPrivacyPolicy] = useState<string | null>(null)
  const [privacyPolicyError, setPrivacyPolicyError] = useState<boolean>(false)

  useEffect(() => {
    if (dialogOpen) {
      setType(preSelectedTeam ? ChallengeType.reserved : null)
      setTeam(preSelectedTeam ? preSelectedTeam : null)
    }
  }, [dialogOpen])

  // duration
  const [startsAt, setStartsAt] = useState<string>("")
  const [endsAt, setEndsAt] = useState<string>("")
  const [startsAtError, setStartsAtError] = useState<boolean>(false)
  const [endsAtError, setEndsAtError] = useState<boolean>(false)

  // document
  const [defaultTranslation, setDefaultTranslation] = useState<string>("")
  const [title, setTitle] = useState<string>("")
  const [sponsor, setSponsor] = useState<string>("")
  const [subtitle, setSubtitle] = useState<string>("")
  const [image, setImage] = useState<string>("")
  const [body, setBody] = useState<
    (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
  >([])
  const [prizes, setPrizes] = useState<ImageSlice[]>([])

  // change team dialog
  const [changeTeamDialogOpen, setChangeTeamDialogOpen] =
    useState<boolean>(false)
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)

  // check errors in body and prizes
  const [bodyError, setBodyError] = useState<boolean>(false)
  const [prizesError, setPrizesError] = useState<boolean>(false)

  useEffect(() => {
    let errorInBody = false
    body.forEach((bodyItem) => {
      if (
        (bodyItem.sliceType === BodySliceType.BodyTextSlice &&
          !stripHtml(bodyItem.text).length) ||
        (bodyItem.sliceType === BodySliceType.BodyQuoteSlice &&
          !bodyItem.quoteText.length) ||
        (bodyItem.sliceType === BodySliceType.BodyImageSlice &&
          (!bodyItem.imgUrl.length || !isValidURL(bodyItem.imgUrl))) ||
        (bodyItem.sliceType === BodySliceType.BodyEmbedSlice &&
          (!bodyItem.embedUrl.length || !testYtURL(bodyItem.embedUrl)))
      ) {
        errorInBody = true
      }
    })
    if (errorInBody) {
      setBodyError(true)
    } else {
      setBodyError(false)
    }

    let errorInPrizes = false
    prizes.forEach((prize) => {
      if (
        prize.sliceType === BodySliceType.BodyImageSlice &&
        (!prize.imgUrl.length || !isValidURL(prize.imgUrl))
      ) {
        errorInPrizes = true
      }
    })
    if (errorInPrizes) {
      setPrizesError(true)
    } else {
      setPrizesError(false)
    }
  }, [body, prizes])

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  // slides
  const slides = [
    {
      label: "Configuration",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Configuration
          </Typography>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Name"
              fullWidth
              size="small"
              value={name}
              onChange={(e) => {
                setName(e.target.value)
                setUid(slugify(e.target.value))
              }}
              onBlur={() => {
                if (name) {
                  setName((current) => current.trim())
                }
              }}
            />
            <TextField
              label="UID"
              fullWidth
              size="small"
              value={uid}
              InputProps={{ readOnly: true }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <Autocomplete
              loading={metricsLoading}
              fullWidth
              blurOnSelect
              size="small"
              value={metric}
              disablePortal
              isOptionEqualToValue={(
                option: AutocompleteOption,
                value: AutocompleteOption
              ) => option.id === value.id}
              options={metricsList
                .filter(
                  (item) =>
                    item.versionType === "new" || item.versionType === "both"
                )
                .map((item) => {
                  return {
                    id: item.id,
                    label: capitalizeFirstCharacter(item.name),
                  }
                })}
              renderInput={(params) => <TextField {...params} label="Metric" />}
              onChange={(e: any, newValue: AutocompleteOption | null) => {
                setMetric(newValue)
              }}
              renderOption={renderOption}
              renderTags={renderTags}
            />
            <TextField
              fullWidth
              size="small"
              type="number"
              label="Target"
              value={targetAmount ?? ""}
              onChange={(e) => {
                if (e.target.value.length) {
                  if (
                    e.target.value.includes(".") ||
                    e.target.value.includes(",")
                  ) {
                    setTargetAmount(parseFloat(e.target.value))
                  } else {
                    setTargetAmount(parseInt(e.target.value))
                  }
                } else {
                  setTargetAmount(null)
                }
              }}
              error={
                targetAmount === null ||
                (targetAmount !== null && targetAmount < 1)
              }
              helperText={
                targetAmount === null ||
                (targetAmount !== null && targetAmount < 1)
                  ? "Invalid value"
                  : ""
              }
            />
            {metric && mobilityMetrics.includes(metric.id) ? (
              <TextField
                fullWidth
                size="small"
                type="number"
                label="Personal target"
                value={personalTargetAmount ?? ""}
                onChange={(e) => {
                  if (e.target.value.length) {
                    if (
                      e.target.value.includes(".") ||
                      e.target.value.includes(",")
                    ) {
                      setPersonalTargetAmount(parseFloat(e.target.value))
                    } else {
                      setPersonalTargetAmount(parseInt(e.target.value))
                    }
                  } else {
                    setPersonalTargetAmount(null)
                  }
                }}
                error={
                  personalTargetAmount === null ||
                  (personalTargetAmount !== null && personalTargetAmount <= 0)
                }
                helperText={
                  personalTargetAmount === null
                    ? "This field is required"
                    : personalTargetAmount !== null && personalTargetAmount <= 0
                    ? "Invalid value"
                    : ""
                }
              />
            ) : null}
            {metric && mobilityMetrics.includes(metric.id) ? (
              <TextField
                fullWidth
                size="small"
                type="number"
                label="Personal target points"
                value={personalTargetPoints ?? ""}
                onChange={(e) => {
                  if (e.target.value.length) {
                    if (
                      e.target.value.includes(".") ||
                      e.target.value.includes(",")
                    ) {
                      setPersonalTargetPoints(parseFloat(e.target.value))
                    } else {
                      setPersonalTargetPoints(parseInt(e.target.value))
                    }
                  } else {
                    setPersonalTargetPoints(null)
                  }
                }}
                error={
                  personalTargetPoints === null ||
                  (personalTargetPoints !== null && personalTargetPoints < 1)
                }
                helperText={
                  personalTargetPoints === null
                    ? "This field is required"
                    : personalTargetPoints !== null && personalTargetPoints < 1
                    ? "Invalid value"
                    : ""
                }
              />
            ) : null}
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="type-select">Type</InputLabel>
              <Select
                labelId="type-select"
                label="Type"
                value={type ?? ""}
                onChange={(e) => {
                  setType(e.target.value as ChallengeType)
                }}
              >
                {(
                  Object.keys(ChallengeType) as Array<
                    keyof typeof ChallengeType
                  >
                ).map((key) =>
                  (key === "featured" && preSelectedTeam) ||
                  (key === "reservedFeatured" &&
                    preSelectedTeam &&
                    preSelectedTeam.accessType ===
                      TeamAccessType.activationCode) ? null : (
                    <MenuItem key={key} value={key}>
                      {key === "reservedFeatured"
                        ? "Reserved & Featured"
                        : capitalizeFirstCharacter(key)}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            {type && type !== ChallengeType.featured && !preSelectedTeam ? (
              <TextField
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <Button
                      sx={{ paddingInline: 3 }}
                      onClick={() => {
                        setChangeTeamDialogOpen(true)
                      }}
                    >
                      {team ? "Change" : "Select"}
                    </Button>
                  ),
                }}
                fullWidth
                label="Team"
                size="small"
                value={team ? team.name : ""}
                error={
                  type &&
                  type === ChallengeType.reservedFeatured &&
                  team &&
                  team.accessType === TeamAccessType.activationCode
                }
                helperText={
                  type &&
                  type === ChallengeType.reservedFeatured &&
                  team &&
                  team.accessType === TeamAccessType.activationCode
                    ? "The Team must not be private for the current Challenge type"
                    : ""
                }
              />
            ) : null}
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="leaderboard-group-type-select">
                Groups leaderboard type
              </InputLabel>
              <Select
                labelId="leaderboard-group-type-select"
                label="Groups leaderboard type"
                value={leaderboardGroupType}
                onChange={(e) => {
                  setLeaderboardGroupType(e.target.value as "average" | "sum")
                }}
              >
                <MenuItem value="sum">Sum</MenuItem>
                <MenuItem value="average">Average</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              size="small"
              type="number"
              label="Leaderboard minimum members"
              value={leaderboardMinMembers ?? ""}
              onChange={(e) => {
                if (e.target.value.length) {
                  if (
                    e.target.value.includes(".") ||
                    e.target.value.includes(",")
                  ) {
                    setLeaderboardMinMembers(parseFloat(e.target.value))
                  } else {
                    setLeaderboardMinMembers(parseInt(e.target.value))
                  }
                } else {
                  setLeaderboardMinMembers(null)
                }
              }}
              error={
                leaderboardMinMembers === null ||
                (leaderboardMinMembers !== null && leaderboardMinMembers < 1)
              }
              helperText={
                leaderboardMinMembers === null ||
                (leaderboardMinMembers !== null && leaderboardMinMembers < 1)
                  ? "Invalid value"
                  : ""
              }
            />
            <TextField
              fullWidth
              size="small"
              label="Privacy policy (URL)"
              value={privacyPolicy ?? ""}
              onChange={(e) => {
                setPrivacyPolicy(e.target.value ? e.target.value : null)

                // errors check
                if (e.target.value && !isValidURL(e.target.value)) {
                  setPrivacyPolicyError(true)
                } else {
                  setPrivacyPolicyError(false)
                }
              }}
              error={privacyPolicyError}
              helperText={privacyPolicyError ? "Invalid URL" : ""}
            />
          </Stack>
        </Stack>
      ),
    },
    {
      label: "Duration",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Duration
          </Typography>
          <Stack direction="row" spacing={2}>
            {dialogOpen && (
              <ISODatePicker
                label="Start date"
                date={startsAt}
                setDate={(newDate: string) => {
                  if (startsAt) {
                    setStartsAt(
                      newDate
                        ? newDate.split("T")[0] + "T" + startsAt.split("T")[1]
                        : newDate
                    )
                  } else {
                    setStartsAt(newDate)
                  }
                }}
                error={startsAt && startsAtError}
                hour={12}
              />
            )}
            <ISOTimePicker
              label="Start time"
              time={startsAt}
              setTime={(newDate: string) => {
                setStartsAt(newDate)
              }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            {dialogOpen && (
              <ISODatePicker
                label="End date"
                date={endsAt}
                setDate={(newDate: string) => {
                  if (endsAt) {
                    setEndsAt(
                      newDate
                        ? newDate.split("T")[0] + "T" + endsAt.split("T")[1]
                        : newDate
                    )
                  } else {
                    setEndsAt(newDate)
                  }
                }}
                minDate={startsAt}
                error={endsAt && endsAtError}
                hour={12}
              />
            )}
            <ISOTimePicker
              label="End time"
              time={endsAt}
              setTime={(newDate: string) => {
                setEndsAt(newDate)
              }}
            />
          </Stack>
        </Stack>
      ),
    },
    {
      label: "Language",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Default language
          </Typography>
          <FormControl fullWidth size="small">
            <InputLabel id="language-select">Default language</InputLabel>
            <Select
              labelId="language-select"
              label="Default language"
              value={defaultTranslation}
              onChange={(e) => {
                setDefaultTranslation(e.target.value)
              }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {lang.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      ),
    },
    {
      label: "Details",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Details in{" "}
            {defaultTranslation.length
              ? languagesForEpisodeTabs.filter(
                  (item) => item.value === defaultTranslation
                )[0].label
              : ""}
          </Typography>
          <TextField
            size="small"
            label="Title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
            error={title.length < 3 || title.length > 120}
            helperText={
              title.length < 3
                ? "Minimum length is 3 characters"
                : title.length > 120
                ? "Maximum length is 120 characters"
                : ""
            }
            onBlur={() => {
              if (title) {
                setTitle((current) => current.trim())
              }
            }}
          />
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              size="small"
              label="Subtitle"
              value={subtitle}
              onChange={(e) => {
                setSubtitle(e.target.value)
              }}
              onBlur={() => {
                if (subtitle) {
                  setSubtitle((current) => current.trim())
                }
              }}
            />
            <TextField
              fullWidth
              size="small"
              label="Collective prize"
              value={sponsor}
              onChange={(e) => {
                setSponsor(e.target.value)
              }}
              onBlur={() => {
                if (sponsor) {
                  setSponsor((current) => current.trim())
                }
              }}
            />
          </Stack>
          <ImagePicker
            title="Image"
            pickedImage={image}
            setPickedImage={(selectedImage: {
              url: string
              id: string
              description?: string
            }) => {
              setImage(selectedImage.url)
            }}
            height={140}
            filters={{ category: "challenge" }}
            disableCategoryFilter
          />
        </Stack>
      ),
    },
    {
      label: "Body",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Body in{" "}
            {defaultTranslation.length
              ? languagesForEpisodeTabs.filter(
                  (item) => item.value === defaultTranslation
                )[0].label
              : ""}
          </Typography>
          <div
            style={{
              height: 260,
              maxHeight: 260,
              overflowY: "scroll",
            }}
          >
            <BlockEditor
              body={body}
              setBody={(
                body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
              ) => {
                setBody([...body])
              }}
            />
          </div>
        </Stack>
      ),
    },
    {
      label: "Prizes",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Prizes in{" "}
            {defaultTranslation.length
              ? languagesForEpisodeTabs.filter(
                  (item) => item.value === defaultTranslation
                )[0].label
              : ""}
          </Typography>
          <div
            style={{
              height: 260,
              maxHeight: 260,
              overflowY: "scroll",
            }}
          >
            <BlockEditorPrizes
              prizes={prizes}
              setPrizes={(prizes: ImageSlice[]) => {
                setPrizes([...prizes])
              }}
            />
          </div>
        </Stack>
      ),
    },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setName("")
        setUid("")
        setType(null)
        setTargetAmount(1)
        setPersonalTargetAmount(null)
        setPersonalTargetPoints(300)
        setMetric(null)
        setTeam(null)
        setLeaderboardGroupType("average")
        setLeaderboardMinMembers(10)
        setPrivacyPolicy(null)
        setPrivacyPolicyError(false)
        setStartsAt("")
        setEndsAt("")
        setStartsAtError(false)
        setEndsAtError(false)
        setDefaultTranslation("")
        setTitle("")
        setSponsor("")
        setSubtitle("")
        setImage("")
        setBody([])
        setPrizes([])
        setCurrentSlide(0)
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (name || metric || type) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [name, metric, type])

  // create challenge
  const createNewChallenge = async () => {
    setLoading(true)

    const input: {
      endsAt: string
      metricId: string
      name: string
      startsAt: string
      targetAmount: number
      leaderboardGroupType: "average" | "sum"
      leaderboardMinMembers: number
      type: ChallengeType
      uid: string
      teamId?: string
      personalTargetAmount?: number
      personalTargetPoints?: number
      privacyPolicy?: string
      versionType?: ChallengeVersionType
    } = {
      endsAt: endsAt,
      metricId: metric.id,
      name: name,
      startsAt: startsAt,
      targetAmount: targetAmount,
      leaderboardGroupType: leaderboardGroupType,
      leaderboardMinMembers: leaderboardMinMembers,
      type: type,
      uid: uid,
      versionType: ChallengeVersionType.pointsLeaderboardChallenge,
    }

    if (team) {
      input.teamId = team.id
    }
    if (mobilityMetrics.includes(metric.id)) {
      input.personalTargetAmount = personalTargetAmount
      input.personalTargetPoints = personalTargetPoints
    }
    if (privacyPolicy) {
      input.privacyPolicy = privacyPolicy
    }

    const result = await createChallengeParent(input)

    if (typeof result !== "boolean") {
      const newChallenge = result

      const newBody = []
      body.forEach((bodyItem: any) => {
        const { sliceType, ...rest } = bodyItem
        newBody.push({
          [lowercaseFirstCharacter(bodyItem.sliceType)]: {
            ...rest,
          },
        })
      })

      const newPrizes = []
      prizes.forEach((prize: any) => {
        const { sliceType, ...rest } = prize
        newPrizes.push({
          [lowercaseFirstCharacter(prize.sliceType)]: {
            ...rest,
          },
        })
      })

      const documentInput: {
        parentId: string
        type: string
        challengeDocumentItems: {
          lang: string
          title: string
          image: string
          default: boolean
          body: object[]
          prizes: object[]
          sponsor?: string
          subtitle?: string
        }[]
      } = {
        parentId: newChallenge.id,
        type: "Challenge",
        challengeDocumentItems: [
          {
            lang: defaultTranslation,
            title: title,
            image: image,
            default: true,
            body: newBody,
            prizes: newPrizes,
          },
        ],
      }

      if (sponsor) {
        documentInput.challengeDocumentItems[0].sponsor = sponsor
      }
      if (subtitle) {
        documentInput.challengeDocumentItems[0].subtitle = subtitle
      }

      const documentResult = await createChallengeDocument(documentInput)

      if (typeof documentResult !== "boolean") {
        if (preSelectedTeam) {
          newChallenge.document = documentResult
          currentTeamChallengesList.push(newChallenge)
          setCurrentTeamChallengesList([...currentTeamChallengesList])
          setDialogOpen(false)
        } else {
          setDialogOpen(false)
          setAnimation(false)
          setTimeout(() => {
            navigate(`/challenges/${newChallenge.id}`)
          }, 250)
        }
      } else {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText={topBarText}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            {topBarText}
          </Typography>
          <Stepper
            activeStep={currentSlide}
            style={{
              paddingTop: 24,
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 8,
              maxWidth: "100%",
              overflow: "overlay",
            }}
          >
            {slides.map((slide) => {
              return (
                <Step key={slide.label}>
                  <StepLabel>{slide.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (!slides[currentSlide - 1]) {
                    if (showDiscardDialog) {
                      setDiscardDialogOpen(true)
                    } else {
                      handleDialogChange(e)
                    }
                  } else {
                    setCurrentSlide(currentSlide - 1)
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  (currentSlide === 0 && !name) ||
                  (currentSlide === 0 && !type) ||
                  (currentSlide === 0 && privacyPolicyError) ||
                  (currentSlide === 0 && targetAmount === null) ||
                  (currentSlide === 0 &&
                    metric &&
                    mobilityMetrics.includes(metric.id) &&
                    personalTargetAmount === null) ||
                  (currentSlide === 0 &&
                    metric &&
                    mobilityMetrics.includes(metric.id) &&
                    personalTargetAmount <= 0) ||
                  (currentSlide === 0 &&
                    metric &&
                    mobilityMetrics.includes(metric.id) &&
                    personalTargetPoints === null) ||
                  (currentSlide === 0 &&
                    metric &&
                    mobilityMetrics.includes(metric.id) &&
                    personalTargetPoints < 1) ||
                  (currentSlide === 0 &&
                    targetAmount !== null &&
                    targetAmount < 1) ||
                  (currentSlide === 0 && leaderboardMinMembers === null) ||
                  (currentSlide === 0 &&
                    leaderboardMinMembers !== null &&
                    leaderboardMinMembers < 1) ||
                  (currentSlide === 0 && !metric) ||
                  (currentSlide === 0 &&
                    type !== ChallengeType.featured &&
                    !team) ||
                  (currentSlide === 0 &&
                    type === ChallengeType.reservedFeatured &&
                    team &&
                    team.accessType === TeamAccessType.activationCode) ||
                  (currentSlide === 1 && !startsAt) ||
                  (currentSlide === 1 && !endsAt) ||
                  (currentSlide === 1 && startsAtError) ||
                  (currentSlide === 1 && endsAtError) ||
                  (currentSlide === 2 && !defaultTranslation) ||
                  (currentSlide === 3 && !title) ||
                  (currentSlide === 3 &&
                    (title.length < 3 || title.length > 120)) ||
                  (currentSlide === 4 && bodyError) ||
                  (currentSlide === 5 && prizesError)
                }
                onMouseDown={
                  !slides[currentSlide + 1]
                    ? createNewChallenge
                    : () => {
                        setCurrentSlide(currentSlide + 1)
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Save" : "Next"}
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* change team dialog */}
      <Dialog
        fullScreen
        open={changeTeamDialogOpen}
        onClose={() => {
          setChangeTeamDialogOpen(false)
          setSelectedTeam(null)
          resetTeamsFilters()
        }}
        TransitionComponent={DialogTransition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Team
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                setTeam(selectedTeam)

                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              disabled={!selectedTeam}
            >
              Select
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ backgroundColor: "#f5f5f5" }}>
          <TeamsList
            forDialog
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
          />
        </Paper>
      </Dialog>
      <LoadingBackdrop open={loading} />
    </Dialog>
  )
}

export default CreateChallengeDialog
