import { Dispatch, SetStateAction } from "react"
import Episode from "../../models/episode"
import EpisodeSlide from "../../models/episodeSlide"
import EpisodeSlideQuiz from "../../models/episodeSlideQuiz"
import { MediaSize } from "../config/enum"
import { deepCopy, generateRandomString } from "./utility"

export const createSlide = (slideType: string, titleForTitleSlide?: string) => {
  let slide: EpisodeSlide

  switch (slideType) {
    case "Title":
      slide = {
        id: "temp-id-" + generateRandomString(5),
        slideType: "TitleSlide",
        textColor: "#FFFFFE",
        background: "",
        decor: null,
        tagBox: {
          tagBoxBackColor: "#22214B",
          tagBoxTextColor: "#FFFFFE",
        },
        box: {
          boxShape: "squared",
          boxBackColor: "#22214B",
        },
        title: titleForTitleSlide,
        description: "",
        image: null,
      }
      return slide
    case "Full Text":
      slide = {
        id: "temp-id-" + generateRandomString(5),
        slideType: "FullTextSlide",
        textColor: "#FFFFFE",
        background: "",
        decor: null,
        box: {
          boxShape: "squared",
          boxBackColor: "#22214B",
        },
        text: "<p>Text</p>",
      }
      return slide
    case "Text Image":
      slide = {
        id: "temp-id-" + generateRandomString(5),
        slideType: "TextImageSlide",
        textColor: "#FFFFFE",
        background: "",
        decor: null,
        box: {
          boxBackColor: "#22214B",
          boxShape: "squared",
        },
        text: "<p>Text</p>",
        image: {
          type: null,
          url: "",
          description: null,
        },
      }
      return slide
    case "Quote":
      slide = {
        id: "temp-id-" + generateRandomString(5),
        slideType: "QuoteSlide",
        textColor: "#FFFFFE",
        background: "",
        decor: null,
        box: {
          boxBackColor: "#22214B",
          boxShape: "squared",
        },
        text: "<p>Text</p>",
        image: {
          type: null,
          url: "",
          description: null,
        },
        author: "Author",
        authorTitle: "Author title",
      }
      return slide
    case "Embed":
      slide = {
        id: "temp-id-" + generateRandomString(5),
        slideType: "EmbedSlide",
        textColor: "#22214B",
        background: "",
        text: "<p>Text</p>",
        captionTitle: "Caption title",
        caption: "Caption",
        embedUrl: "",
      }
      return slide
    case "Full Image":
      slide = {
        id: "temp-id-" + generateRandomString(5),
        slideType: "FullImageSlide",
        textColor: "#22214B",
        background: "",
        captionTitle: "Caption title",
        caption: "Caption",
        image: {
          type: null,
          url: "",
          description: null,
        },
      }
      return slide
    case "Portrait":
      slide = {
        id: "temp-id-" + generateRandomString(5),
        slideType: "PortraitSlide",
        textColor: "#FFFFFE",
        text: "<p>Text</p>",
        box: {
          boxBackColor: "#22214B",
          boxShape: "squared",
        },
        background: "",
        image: {
          url: "",
          description: null,
          type: null,
        },
      }
      return slide
  }
}

export const createQuiz = () => {
  let slide: EpisodeSlideQuiz = {
    id: "temp-id-" + generateRandomString(5),
    slideType: "QuizSlide",
    background: "",
    decor: null,
    box: {
      boxBackColor: "#22214B",
      boxShape: "squared",
    },
    textColor: "#FFFFFE",
    quizType: "quiz",
    question: "Question",
    opt1: {
      text: "First answer",
      image: null,
    },
    opt2: {
      text: "Second answer",
      image: null,
    },
    opt3: {
      text: "Third answer",
      image: null,
    },
    opt4: {
      text: "",
      image: null,
    },
    correctAnswer: "opt1",
  }

  return slide
}

export const cleanEpisodesList = (data: any, dataProperty: string) => {
  let dataCopy = deepCopy(data)

  for (let i = 0; i < dataCopy[dataProperty].translations.length; i++) {
    dataCopy[dataProperty].translations[i].lang = dataCopy[
      dataProperty
    ].translations[i].lang.includes("_")
      ? dataCopy[dataProperty].translations[i].lang.slice(0, 5)
      : dataCopy[dataProperty].translations[i].lang.slice(0, 2)
    for (
      let j = 0;
      j < dataCopy[dataProperty].translations[i].slides.length;
      j++
    ) {
      for (let key in dataCopy[dataProperty].translations[i].slides[j]) {
        if (key.includes("Text")) {
          dataCopy[dataProperty].translations[i].slides[j].text = dataCopy[
            dataProperty
          ].translations[i].slides[j][key]
            .replaceAll("<b>", "<strong>")
            .replaceAll("</b>", "</strong>")
          delete dataCopy[dataProperty].translations[i].slides[j][key]
        }
        if (key.includes("Caption") && !key.includes("CaptionTitle")) {
          dataCopy[dataProperty].translations[i].slides[j].caption =
            dataCopy[dataProperty].translations[i].slides[j][key]
          delete dataCopy[dataProperty].translations[i].slides[j][key]
        }
        if (key.includes("CaptionTitle")) {
          dataCopy[dataProperty].translations[i].slides[j].captionTitle =
            dataCopy[dataProperty].translations[i].slides[j][key]
          delete dataCopy[dataProperty].translations[i].slides[j][key]
        }
        if (key.includes("Image")) {
          dataCopy[dataProperty].translations[i].slides[j].image =
            dataCopy[dataProperty].translations[i].slides[j][key]
          delete dataCopy[dataProperty].translations[i].slides[j][key]
        }
        if (key === "text") {
          dataCopy[dataProperty].translations[i].slides[j].text = dataCopy[
            dataProperty
          ].translations[i].slides[j][key]
            .replaceAll("<b>", "<strong>")
            .replaceAll("</b>", "</strong>")
        }
      }
    }
  }

  return dataCopy
}

export const onSlideTitleTextFieldChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  e: any,
  slideLanguage: string,
  slide: EpisodeSlide,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        title: e.target.value,
        lang: slideLanguage,
        slides: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            title: e.target.value,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onSlideTextColorTextFieldChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  newColor: string,
  slideLanguage: string,
  slide: EpisodeSlide,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        slides: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            textColor: newColor,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onSlideTextColorAndBoxBackColorChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  newColor: string,
  slideLanguage: string,
  slide: EpisodeSlide,
  index: number,
  boxBackColor: string
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        slides: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            textColor: newColor,
            box: boxBackColor
              ? {
                  boxShape: slide.box ? slide.box.boxShape : "squared",
                  boxBackColor: boxBackColor,
                }
              : null,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const checkTextColorOnBoxBackColorChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  e: any,
  language: string,
  slide: EpisodeSlide,
  index: number
) => {
  if (
    (e.target.value === "#22214B" &&
      (slide.textColor === "#22214B" ||
        slide.textColor === "#22214B" ||
        slide.textColor === "#22214B")) ||
    (e.target.value === "#22214B" &&
      (slide.textColor === "#22214B" ||
        slide.textColor === "#22214B" ||
        slide.textColor === "#22214B")) ||
    (e.target.value === "#22214B" &&
      (slide.textColor === "#22214B" ||
        slide.textColor === "#22214B" ||
        slide.textColor === "#22214B"))
  ) {
    onSlideTextColorAndBoxBackColorChange(
      currentEpisode,
      setCurrentEpisode,
      "#FFFFFE",
      language,
      slide,
      index,
      e.target.value
    )
  } else if (
    e.target.value === "#FFFFFE" &&
    slide.textColor !== "#22214B" &&
    slide.textColor !== "#22214B" &&
    slide.textColor !== "#22214B"
  ) {
    onSlideTextColorAndBoxBackColorChange(
      currentEpisode,
      setCurrentEpisode,
      "#22214B",
      language,
      slide,
      index,
      e.target.value
    )
  } else if (
    e.target.value === "none" &&
    slide.textColor !== "#22214B" &&
    slide.textColor !== "#22214B" &&
    slide.textColor !== "#22214B"
  ) {
    onSlideTextColorAndBoxBackColorChange(
      currentEpisode,
      setCurrentEpisode,
      "#22214B",
      language,
      slide,
      index,
      null
    )
  }
}

export const onSlideBoxShapeSelectChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  e: any,
  slideLanguage: string,
  slide: EpisodeSlide,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        slides: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            box: {
              ...slide.box,
              boxBackColor: slide.box.boxBackColor,
              boxShape: e ? e.target.value : null,
            },
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onSlideBoxBackColorTextFieldChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  newColor: string,
  slideLanguage: string,
  slide: EpisodeSlide,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        slides: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            box: newColor
              ? {
                  ...slide.box,
                  boxBackColor: newColor,
                  boxShape: slide.box ? slide.box.boxShape : "squared",
                }
              : null,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onSlideTagBoxBackColorTextFieldChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  newColor: string,
  slideLanguage: string,
  slide: EpisodeSlide,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        slides: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            tagBox:
              slide.tagBox && slide.tagBox.tagBoxText
                ? {
                    tagBoxTextColor:
                      slide.tagBox && slide.tagBox.tagBoxTextColor
                        ? slide.tagBox.tagBoxTextColor
                        : "#FFFFFE",
                    tagBoxBackColor: newColor,
                    tagBoxText: slide.tagBox.tagBoxText,
                  }
                : {
                    tagBoxTextColor:
                      slide.tagBox && slide.tagBox.tagBoxTextColor
                        ? slide.tagBox.tagBoxTextColor
                        : "#FFFFFE",
                    tagBoxBackColor: newColor,
                  },
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onSlideTagBoxTextColorTextFieldChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  newColor: string,
  slideLanguage: string,
  slide: EpisodeSlide,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        slides: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            tagBox:
              slide.tagBox && slide.tagBox.tagBoxText
                ? {
                    tagBoxTextColor: newColor,
                    tagBoxBackColor:
                      slide.tagBox && slide.tagBox.tagBoxBackColor
                        ? slide.tagBox.tagBoxBackColor
                        : "#22214B",
                    tagBoxText: slide.tagBox.tagBoxText,
                  }
                : {
                    tagBoxTextColor: newColor,
                    tagBoxBackColor:
                      slide.tagBox && slide.tagBox.tagBoxBackColor
                        ? slide.tagBox.tagBoxBackColor
                        : "#22214B",
                  },
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onSlideDescriptionTextFieldChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  e: any,
  slideLanguage: string,
  slide: EpisodeSlide,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        slides: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            description: e.target.value,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onSlideAuthorTextFieldChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  e: any,
  slideLanguage: string,
  slide: EpisodeSlide,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        slides: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            author: e.target.value,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onSlideAuthorTitleTextFieldChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  e: any,
  slideLanguage: string,
  slide: EpisodeSlide,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        slides: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            authorTitle: e.target.value,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onSlideCaptionTitleTextFieldChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  e: any,
  slideLanguage: string,
  slide: EpisodeSlide,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        slides: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            captionTitle: e.target.value,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onSlideCaptionTextFieldChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  e: any,
  slideLanguage: string,
  slide: EpisodeSlide,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        slides: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            caption: e.target.value,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onSlideBackgroundChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  selectedImage: { url: string; id: string; description?: string },
  slideLanguage: string,
  slide: EpisodeSlide,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        slides: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            background: selectedImage.url,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onSlideDecorChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  selectedImage: { url: string; id: string; description?: string },
  slideLanguage: string,
  slide: EpisodeSlide,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        slides: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            decor: selectedImage.url.length ? selectedImage.url : null,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onSlideImageChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  selectedImage: {
    url: string
    id: string
    description?: string
    type?: MediaSize
  },
  slideLanguage: string,
  slide: EpisodeSlide,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        slides: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            image: {
              ...slide.image,
              url: selectedImage.url,
              description: selectedImage.description
                ? selectedImage.description
                : null,
              type: selectedImage.type ? selectedImage.type : null,
            },
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onSlideTextEditorChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  newText: string,
  slideLanguage: string,
  slide: EpisodeSlide,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        slides: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            text: newText,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onSlideEmbedURLTextFieldChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  e: any,
  slideLanguage: string,
  slide: EpisodeSlide,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        slides: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            embedUrl: e.target.value,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .slides.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onQuizTextColorTextFieldChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  newColor: string,
  slideLanguage: string,
  slide: EpisodeSlideQuiz,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        quiz: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            textColor: newColor,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onQuizTextColorAndBoxBackColorChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  newColor: string,
  slideLanguage: string,
  slide: EpisodeSlideQuiz,
  index: number,
  boxBackColor: string
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        quiz: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            textColor: newColor,
            box: boxBackColor
              ? {
                  boxShape: slide.box ? slide.box.boxShape : "squared",
                  boxBackColor: boxBackColor,
                }
              : null,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const checkTextColorOnBoxBackColorChangeQuiz = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  e: any,
  language: string,
  slide: EpisodeSlideQuiz,
  index: number
) => {
  if (
    (e.target.value === "#22214B" &&
      (slide.textColor === "#22214B" ||
        slide.textColor === "#22214B" ||
        slide.textColor === "#22214B")) ||
    (e.target.value === "#22214B" &&
      (slide.textColor === "#22214B" ||
        slide.textColor === "#22214B" ||
        slide.textColor === "#22214B")) ||
    (e.target.value === "#22214B" &&
      (slide.textColor === "#22214B" ||
        slide.textColor === "#22214B" ||
        slide.textColor === "#22214B"))
  ) {
    onQuizTextColorAndBoxBackColorChange(
      currentEpisode,
      setCurrentEpisode,
      "#FFFFFE",
      language,
      slide,
      index,
      e.target.value
    )
  } else if (
    e.target.value === "#FFFFFE" &&
    slide.textColor !== "#22214B" &&
    slide.textColor !== "#22214B" &&
    slide.textColor !== "#22214B"
  ) {
    onQuizTextColorAndBoxBackColorChange(
      currentEpisode,
      setCurrentEpisode,
      "#22214B",
      language,
      slide,
      index,
      e.target.value
    )
  } else if (
    e.target.value === "none" &&
    slide.textColor !== "#22214B" &&
    slide.textColor !== "#22214B" &&
    slide.textColor !== "#22214B"
  ) {
    onQuizTextColorAndBoxBackColorChange(
      currentEpisode,
      setCurrentEpisode,
      "#22214B",
      language,
      slide,
      index,
      null
    )
  }
}

export const onQuizQuestionTextFieldChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  value: any,
  slideLanguage: string,
  slide: EpisodeSlideQuiz,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        quiz: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            question: value,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onQuizAnswerTextFieldChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  e: any,
  slideLanguage: string,
  slide: EpisodeSlideQuiz,
  index: number,
  option: string
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        quiz: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            [option]: {
              text: e.target.value,
              image: slide[option].image,
              __typename: "QuizOption",
            },
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onQuizCorrectAnswerSelectChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  e: any,
  slideLanguage: string,
  slide: EpisodeSlideQuiz,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        quiz: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            correctAnswer: e.target.value,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onQuizQuizTypeSelectChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  e: any,
  slideLanguage: string,
  slide: EpisodeSlideQuiz,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        quiz: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            quizType: e.target.value,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onQuizBackgroundChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  selectedImage: { url: string; id: string; description?: string },
  slideLanguage: string,
  slide: EpisodeSlideQuiz,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        quiz: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            background: selectedImage.url,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onQuizBoxBackColorChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  newColor: string,
  slideLanguage: string,
  slide: EpisodeSlideQuiz,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        quiz: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            box: newColor
              ? {
                  ...slide.box,
                  boxBackColor: newColor,
                  boxShape: slide.box ? slide.box.boxShape : "squared",
                }
              : null,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onQuizDecorChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  selectedImage: { url: string; id: string; description?: string },
  slideLanguage: string,
  slide: EpisodeSlideQuiz,
  index: number
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        quiz: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            decor: selectedImage.url.length ? selectedImage.url : null,
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}

export const onQuizAnswerImageChange = (
  currentEpisode: Episode,
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>,
  selectedImage: { url: string; id: string; description?: string },
  slideLanguage: string,
  slide: EpisodeSlideQuiz,
  index: number,
  option: string
) => {
  setCurrentEpisode({
    ...currentEpisode,
    translations: [
      ...currentEpisode.translations.filter(
        (translation) => translation.lang !== slideLanguage
      ),
      {
        ...currentEpisode.translations.filter(
          (translation) => translation.lang === slideLanguage
        )[0],
        lang: slideLanguage,
        quiz: [
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(0, index)
            .filter((filterSlide) => filterSlide.id !== slide.id),
          {
            ...slide,
            [option]: {
              text: slide[option].text,
              image: selectedImage.url,
            },
          },
          ...currentEpisode.translations
            .filter((translation) => translation.lang === slideLanguage)[0]
            .quiz.slice(index + 1)
            .filter((filterSlide) => filterSlide.id !== slide.id),
        ],
      },
    ],
  })
}
