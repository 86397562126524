import { CloseRounded } from "@mui/icons-material"
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import { MediaContext } from "../../controllers/media"
import { MediaSize } from "../../services/config/enum"
import { DialogTransition } from "../../services/utilities/utility"
import MediaList from "../../views/mediaLibrary/mediaList"

const PickImageDialog = ({
  open,
  setOpen,
  selectedImage,
  setSelectedImage,
  setPickedImage,
  filters,
  disableCategoryFilter = false,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  selectedImage: {
    id: string
    url: string
    description?: string
    type?: MediaSize
  } | null
  setSelectedImage: Dispatch<
    SetStateAction<{
      id: string
      url: string
      description?: string
    } | null>
  >
  setPickedImage: (selectedImage: {
    url: string
    id: string
    description?: string
    type?: MediaSize
  }) => void
  filters?: { category?: string; size?: MediaSize[] }
  disableCategoryFilter?: boolean
}) => {
  const { setSearchCategory, setSearchSize, setSearchValue } =
    useContext(MediaContext)

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setSearchCategory(null)
        setSearchSize(null)
        setSearchValue("")
        setOpen(false)
        setSelectedImage(null)
      }}
      TransitionComponent={DialogTransition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setSearchCategory(null)
              setSearchSize(null)
              setSearchValue("")
              setOpen(false)
              setSelectedImage(null)
            }}
            aria-label="close"
          >
            <CloseRounded />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Select image
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => {
              setSearchCategory(null)
              setSearchSize(null)
              setSearchValue("")
              setOpen(false)
              setPickedImage(selectedImage)
              setSelectedImage(null)
            }}
            disabled={!selectedImage}
          >
            Select
          </Button>
        </Toolbar>
      </AppBar>
      <Paper style={{ backgroundColor: "#f5f5f5" }}>
        <MediaList
          forDialog
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          forDialogFilters={filters}
          disableCategoryFilter={disableCategoryFilter}
        />
      </Paper>
    </Dialog>
  )
}

export default PickImageDialog
