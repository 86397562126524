import { Cancel, Search } from "@mui/icons-material"
import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material"
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useContext,
  useEffect,
  useState,
} from "react"
import { EpisodesContext } from "../../controllers/episodes"
import { LanguagesContext } from "../../controllers/languages"
import { MainContext } from "../../controllers/main"
import Sdg from "../../models/sdg"
import SdgTarget from "../../models/sdgTarget"
import Topic from "../../models/topic"
import { TagsContext } from "../../controllers/tags"
import {
  capitalizeFirstCharacter,
  enumAsArray,
  renderOption,
  renderTags,
} from "../../services/utilities/utility"
import { Esg } from "../../services/config/enum"

interface AutocompleteOption {
  label: string
  id: string
}

const EpisodesListTopBar = ({
  loading,
  textFieldValue,
  textFieldPlaceholder,
  addButtonLabel,
  addButtonOnClick,
  addFromJsonButtonOnClick,
  textFieldOnChange,
  textFieldOnKeyDown,
  cancelButtonOnClick,
  searchButtonOnClick,
  forDialog = false,
  forDialogLanguage,
}: {
  loading: boolean
  textFieldValue: string
  textFieldPlaceholder: string
  addButtonLabel: string
  textFieldOnChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  textFieldOnKeyDown: KeyboardEventHandler<HTMLDivElement>
  cancelButtonOnClick: MouseEventHandler<HTMLDivElement>
  searchButtonOnClick: MouseEventHandler<HTMLButtonElement>
  addButtonOnClick: MouseEventHandler<HTMLButtonElement>
  addFromJsonButtonOnClick: MouseEventHandler<HTMLButtonElement>
  forDialog?: boolean
  forDialogLanguage?: string
}) => {
  const { showAdvancedOption } = useContext(MainContext)
  const { languagesForEpisodeTabs } = useContext(LanguagesContext)
  const {
    searchLanguage,
    setSearchLanguage,
    searchTopic,
    setSearchTopic,
    searchSdg,
    setSearchSdg,
    searchSdgTarget,
    setSearchSdgTarget,
    searchCategory,
    setSearchCategory,
    searchEsg,
    setSearchEsg,
    searchDoNotRecommend,
    setSearchDoNotRecommend,
    searchType,
    setSearchType,
    searchEpisodes,
    searchStatus,
    setSearchStatus,
    getEpisodesList,
    updatingList,
    setUpdatingList,
  } = useContext(EpisodesContext)
  const {
    categoriesLoading,
    categoriesList,
    topicsLoading,
    topicsList,
    sdgsLoading,
    sdgsList,
    sdgTargetsLoading,
    sdgTargetsList,
  } = useContext(TagsContext)

  // search when filters update
  const [firstRender, setFirstRender] = useState<boolean>(true)

  useEffect(() => {
    if (!firstRender) {
      if (
        !textFieldValue.length &&
        !searchLanguage &&
        !searchTopic &&
        !searchSdg &&
        !searchSdgTarget &&
        !searchCategory &&
        !searchEsg &&
        !searchDoNotRecommend &&
        !searchType
      ) {
        setUpdatingList(true)
        if (searchStatus === "active") {
          getEpisodesList(false)
        } else {
          getEpisodesList(false, true)
        }
      } else {
        if (searchStatus === "active") {
          searchEpisodes({
            title: textFieldValue,
            lang: searchLanguage,
            topic: searchTopic,
            sdg: searchSdg,
            sdgTarget: searchSdgTarget,
            category: searchCategory,
            esg: searchEsg,
            doNotRecommend: searchDoNotRecommend,
            type: searchType,
          })
        } else {
          searchEpisodes({
            title: textFieldValue,
            lang: searchLanguage,
            topic: searchTopic,
            sdg: searchSdg,
            sdgTarget: searchSdgTarget,
            category: searchCategory,
            esg: searchEsg,
            doNotRecommend: searchDoNotRecommend,
            type: searchType,
            archived: true,
          })
        }
      }
    } else {
      setFirstRender(false)
    }
  }, [
    searchLanguage,
    searchTopic,
    searchSdg,
    searchSdgTarget,
    searchCategory,
    searchEsg,
    searchDoNotRecommend,
    searchType,
  ])

  // set language filter if forDialog is true
  useEffect(() => {
    if (forDialog && forDialogLanguage) {
      let currentLanguage = languagesForEpisodeTabs
        .slice(1)
        .filter((item) => item.value === forDialogLanguage)[0]

      setSearchLanguage({
        id: currentLanguage.value,
        label: currentLanguage.label,
      })
    }
  }, [])

  return (
    <Stack
      style={{
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 4,
      }}
      spacing={1}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          disabled={loading || updatingList}
          size="small"
          fullWidth
          id="outlined-basic"
          placeholder={textFieldPlaceholder}
          variant="outlined"
          value={textFieldValue}
          onChange={textFieldOnChange}
          onKeyDown={textFieldOnKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: textFieldValue.length > 0 && (
              <InputAdornment position="start">
                <div
                  style={{
                    marginTop: 6,
                    marginRight: -6,
                    cursor: "pointer",
                  }}
                  onClick={loading ? () => {} : cancelButtonOnClick}
                >
                  <Cancel style={{ width: 18 }} />
                </div>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="outlined"
          disabled={textFieldValue.length < 3 || loading || updatingList}
          onClick={searchButtonOnClick}
          style={{ height: 40, minWidth: 74 }}
        >
          Search
        </Button>
        {!forDialog && <Divider orientation="vertical" flexItem />}
        {!forDialog && showAdvancedOption === "yes" ? (
          <Button
            variant="contained"
            disabled={loading}
            style={{
              whiteSpace: "nowrap",
              minWidth: "max-content",
              height: 40,
            }}
            onClick={addFromJsonButtonOnClick}
          >
            Add from JSON
          </Button>
        ) : null}
        {!forDialog && (
          <Button
            variant="contained"
            disabled={loading}
            style={{
              whiteSpace: "nowrap",
              minWidth: "max-content",
              height: 40,
            }}
            onClick={addButtonOnClick}
          >
            {showAdvancedOption === "yes" ? "Add manually" : addButtonLabel}
          </Button>
        )}
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        style={{ overflowX: "scroll", height: 50 }}
        className="filters-stack"
      >
        <FormControl
          fullWidth
          size="small"
          disabled={loading || updatingList || forDialog}
          style={{ minWidth: 110 }}
        >
          <InputLabel id="status-select">Status</InputLabel>
          <Select
            labelId="status-select"
            label="Status"
            value={searchStatus}
            onChange={(e) => {
              setSearchStatus(e.target.value)

              setUpdatingList(true)
              if (
                !textFieldValue.length &&
                !searchLanguage &&
                !searchTopic &&
                !searchSdg &&
                !searchSdgTarget &&
                !searchCategory &&
                !searchEsg &&
                !searchDoNotRecommend &&
                !searchType
              ) {
                if (e.target.value === "active") {
                  getEpisodesList(false)
                } else {
                  getEpisodesList(false, true)
                }
              } else {
                if (e.target.value === "active") {
                  searchEpisodes({
                    title: textFieldValue,
                    lang: searchLanguage,
                    topic: searchTopic,
                    sdg: searchSdg,
                    sdgTarget: searchSdgTarget,
                    category: searchCategory,
                    esg: searchEsg,
                    doNotRecommend: searchDoNotRecommend,
                    type: searchType,
                  })
                } else {
                  searchEpisodes({
                    title: textFieldValue,
                    lang: searchLanguage,
                    topic: searchTopic,
                    sdg: searchSdg,
                    sdgTarget: searchSdgTarget,
                    category: searchCategory,
                    esg: searchEsg,
                    doNotRecommend: searchDoNotRecommend,
                    type: searchType,
                    archived: true,
                  })
                }
              }
            }}
          >
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="archived">Archived</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          size="small"
          disabled={loading || updatingList}
          style={{ minWidth: 110 }}
        >
          <InputLabel id="type-select">Type</InputLabel>
          <Select
            labelId="type-select"
            label="Type"
            value={searchType ? searchType.id : "all"}
            onChange={(e) => {
              if (e.target.value === "all") {
                setSearchType(null)
              } else {
                setSearchType({
                  id: e.target.value,
                  label: e.target.value,
                })
              }
            }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="Standard">Standard</MenuItem>
            <MenuItem value="Custom">Custom</MenuItem>
          </Select>
        </FormControl>
        <Autocomplete
          style={{ minWidth: 110 }}
          disabled={loading || updatingList || forDialog}
          fullWidth
          size="small"
          value={searchLanguage ?? { label: "All", id: null }}
          disablePortal
          isOptionEqualToValue={(
            option: AutocompleteOption,
            value: AutocompleteOption
          ) => option.id === value.id}
          options={[
            { label: "All", id: null },
            ...languagesForEpisodeTabs
              .slice(1)
              .map((item: { value: string; label: string }) => {
                return { label: item.label, id: item.value }
              }),
          ]}
          renderInput={(params) => (
            <TextField {...params} label="Translations" />
          )}
          onChange={(e: any, newValue: AutocompleteOption | null) => {
            if (newValue && newValue.id !== null) {
              setSearchLanguage(newValue)
            } else {
              setSearchLanguage(null)
            }
          }}
          disableClearable={!searchLanguage}
          renderOption={renderOption}
          renderTags={renderTags}
        />
        <Autocomplete
          style={{ minWidth: 110 }}
          disabled={loading || updatingList}
          fullWidth
          loading={categoriesLoading}
          size="small"
          value={searchCategory ?? { label: "All", id: null }}
          disablePortal
          isOptionEqualToValue={(
            option: AutocompleteOption,
            value: AutocompleteOption
          ) => option.id === value.id}
          options={
            categoriesList.length
              ? [
                  { label: "All", id: null },
                  ...categoriesList.map((category) => {
                    return { label: category.name, id: category.id }
                  }),
                ]
              : []
          }
          renderInput={(params) => <TextField {...params} label="Category" />}
          onChange={(e: any, newValue: AutocompleteOption | null) => {
            if (newValue && newValue.id !== null) {
              setSearchCategory(newValue)
            } else {
              setSearchCategory(null)
            }
          }}
          disableClearable={!searchCategory}
          renderOption={renderOption}
          renderTags={renderTags}
        />
        <FormControl
          fullWidth
          size="small"
          disabled={loading || updatingList}
          style={{ minWidth: 110 }}
        >
          <InputLabel id="recommend-select">Recommend</InputLabel>
          <Select
            labelId="recommend-select"
            label="Recommend"
            value={searchDoNotRecommend ? searchDoNotRecommend.id : "all"}
            onChange={(e) => {
              if (e.target.value === "all") {
                setSearchDoNotRecommend(null)
              } else {
                setSearchDoNotRecommend({
                  id: e.target.value,
                  label: e.target.value,
                })
              }
            }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </Select>
        </FormControl>
        <Autocomplete
          style={{ minWidth: 110 }}
          disabled={loading || updatingList}
          fullWidth
          loading={topicsLoading}
          size="small"
          value={searchTopic ?? { label: "All", id: null }}
          disablePortal
          isOptionEqualToValue={(
            option: AutocompleteOption,
            value: AutocompleteOption
          ) => option.id === value.id}
          options={
            topicsList.length
              ? [
                  { label: "All", id: null },
                  ...topicsList.map((topic: Topic) => {
                    return { label: topic.name, id: topic.id }
                  }),
                ]
              : []
          }
          renderInput={(params) => <TextField {...params} label="Topic" />}
          onChange={(e: any, newValue: AutocompleteOption | null) => {
            if (newValue && newValue.id !== null) {
              setSearchTopic(newValue)
            } else {
              setSearchTopic(null)
            }
          }}
          disableClearable={!searchTopic}
          renderOption={renderOption}
          renderTags={renderTags}
        />
        <Autocomplete
          style={{ minWidth: 110 }}
          disabled={loading || updatingList}
          fullWidth
          size="small"
          value={searchEsg ?? { label: "All", id: null }}
          disablePortal
          isOptionEqualToValue={(
            option: AutocompleteOption,
            value: AutocompleteOption
          ) => option.id === value.id}
          options={[
            { label: "All", id: null },
            ...enumAsArray(Esg).map((esg: string) => {
              return { label: capitalizeFirstCharacter(esg), id: esg }
            }),
          ]}
          renderInput={(params) => <TextField {...params} label="ESG" />}
          onChange={(e: any, newValue: AutocompleteOption | null) => {
            if (newValue && newValue.id !== null) {
              setSearchEsg(newValue)
            } else {
              setSearchEsg(null)
            }
          }}
          disableClearable={!searchEsg}
          renderOption={renderOption}
          renderTags={renderTags}
        />
        <Autocomplete
          style={{ minWidth: 110 }}
          disabled={loading || updatingList}
          fullWidth
          loading={sdgsLoading}
          size="small"
          value={searchSdg ?? { label: "All", id: null }}
          disablePortal
          isOptionEqualToValue={(
            option: AutocompleteOption,
            value: AutocompleteOption
          ) => option.id === value.id}
          options={
            sdgsList.length
              ? [
                  { label: "All", id: null },
                  ...sdgsList.map((sdg: Sdg) => {
                    return { label: sdg.name, id: sdg.id }
                  }),
                ]
              : []
          }
          renderInput={(params) => <TextField {...params} label="SDG" />}
          onChange={(e: any, newValue: AutocompleteOption | null) => {
            if (newValue && newValue.id !== null) {
              setSearchSdg(newValue)
            } else {
              setSearchSdg(null)
            }
          }}
          disableClearable={!searchSdg}
          renderOption={renderOption}
          renderTags={renderTags}
        />
        <Autocomplete
          style={{ minWidth: 110 }}
          disabled={loading || updatingList}
          fullWidth
          loading={sdgTargetsLoading}
          size="small"
          value={searchSdgTarget ?? { label: "All", id: null }}
          disablePortal
          isOptionEqualToValue={(
            option: AutocompleteOption,
            value: AutocompleteOption
          ) => option.id === value.id}
          options={
            sdgTargetsList.length
              ? [
                  { label: "All", id: null },
                  ...sdgTargetsList.map((sdgTarget: SdgTarget) => {
                    return { label: sdgTarget.id.slice(4), id: sdgTarget.id }
                  }),
                ]
              : []
          }
          renderInput={(params) => <TextField {...params} label="SDG target" />}
          onChange={(e: any, newValue: AutocompleteOption | null) => {
            if (newValue && newValue.id !== null) {
              setSearchSdgTarget(newValue)
            } else {
              setSearchSdgTarget(null)
            }
          }}
          disableClearable={!searchSdgTarget}
          renderOption={renderOption}
          renderTags={renderTags}
        />
      </Stack>
    </Stack>
  )
}

export default EpisodesListTopBar
