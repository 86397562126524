import { KeyboardArrowUpRounded } from "@mui/icons-material"
import {
  Button,
  Fab,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Zoom,
} from "@mui/material"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"

const ListTable = ({
  headingItems,
  tableBody,
  height = "calc(100vh - 200px)",
  nextToken,
  loadingMoreItems,
  setLoadingMoreItems,
  loadMore,
  scrollToTopButtonDisabled = false,
}: {
  headingItems: string[]
  tableBody: any
  height?: string
  nextToken?: string
  loadingMoreItems?: boolean
  setLoadingMoreItems?: Dispatch<SetStateAction<boolean>>
  loadMore?: () => Promise<boolean>
  scrollToTopButtonDisabled?: boolean
}) => {
  // scroll listener
  const tableElement = useRef<HTMLInputElement>(null)
  const [showScrollToTop, setShowScrollToTop] = useState<boolean>(false)

  // local loading
  const loading = useRef<boolean>(false)

  const scrollListener = async () => {
    const tableRef = tableElement.current

    if (tableRef.scrollTop > 200) {
      setShowScrollToTop(true)
    } else {
      setShowScrollToTop(false)
    }

    if (
      tableRef.scrollTop >=
        tableRef.scrollHeight - tableRef.clientHeight * 1.5 &&
      nextToken &&
      !loading.current
    ) {
      loading.current = true
      setLoadingMoreItems(true)
      const noErrors = await loadMore()
      if (noErrors) {
        loading.current = false
        setLoadingMoreItems(false)
      }
    }
  }

  useEffect(() => {
    const tableRef = tableElement.current
    tableRef.addEventListener("scroll", scrollListener)
    return () => {
      tableRef.removeEventListener("scroll", scrollListener)
    }
  }, [scrollListener])

  return (
    <div style={{ position: "relative" }}>
      <TableContainer sx={{ height: height }} ref={tableElement}>
        <Table sx={{ minWidth: 650 }} stickyHeader size="small">
          <TableHead>
            <TableRow>
              {headingItems.map((item: string, index) => (
                <TableCell key={index}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{tableBody}</TableBody>
        </Table>
        {nextToken ? (
          <Button
            variant="soft"
            disabled={loadingMoreItems}
            onClick={async () => {
              loading.current = true
              setLoadingMoreItems(true)
              const noErrors = await loadMore()
              if (noErrors) {
                loading.current = false
                setLoadingMoreItems(false)
              }
            }}
            style={{
              width: "calc(100% - 32px)",
              marginLeft: 16,
              marginTop: 6,
              marginBottom: 6,
            }}
          >
            Load more
          </Button>
        ) : null}
      </TableContainer>
      {loadingMoreItems && (
        <LinearProgress
          style={{ position: "absolute", bottom: 0, width: "100%" }}
        />
      )}
      <Zoom in={showScrollToTop && !scrollToTopButtonDisabled} unmountOnExit>
        <Fab
          size="small"
          aria-label="scroll to top"
          color="primary"
          style={{ position: "absolute", bottom: 14, right: 20 }}
          onClick={() => {
            const tableRef = tableElement.current
            tableRef.scrollTo({ top: 0, behavior: "smooth" })
          }}
        >
          <KeyboardArrowUpRounded />
        </Fab>
      </Zoom>
    </div>
  )
}

export default ListTable
