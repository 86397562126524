import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react"
import {
  deepCopy,
  logger,
  lowercaseFirstCharacter,
  Status,
} from "../services/utilities/utility"
import { MainContext } from "./main"
import { useLazyQuery, useMutation } from "@apollo/client"
import { leagueGet, listLeagues } from "../services/graphql/queries"
import { LeagueType } from "../services/config/enum"
import Document from "../models/document"
import League from "../models/league"
import LeaguesDocument from "../models/leaguesDocument"
import { upsertDocumentLeague } from "../services/graphql/mutations"

interface LeaguesContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  leaguesList: League[]
  getLeaguesList: (withLoading?: boolean) => void
  hasSearched: boolean
  getCurrentLeague: (leagueId: string) => Promise<boolean>
  currentLeague: League | null
  setCurrentLeague: Dispatch<SetStateAction<League | null>>
  preChangesCurrentLeague: League | null
  doneChanges: boolean
  cancelChanges: () => void
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  leaguesListNextToken: string | null
  loadMoreLeagues: () => Promise<boolean>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  addTranslation: (translationToAdd: string) => void
  hasError: boolean
  translationsErrors: { lang: string; hasErrors: boolean }[]
  setTranslationsErrors: Dispatch<
    SetStateAction<{ lang: string; hasErrors: boolean }[]>
  >
  copyDetailsFromDefault: (itemToCopyToIndex: number) => void
  copyBodyFromDefault: (itemToCopyToIndex: number) => void
  upsertLeagueDocument: () => Promise<boolean>
}

const LeaguesContext = createContext<LeaguesContextInterface>({
  loading: true,
  setLoading: () => {},
  leaguesList: [],
  getLeaguesList: () => {},
  hasSearched: false,
  getCurrentLeague: async () => true,
  currentLeague: null,
  setCurrentLeague: () => {},
  preChangesCurrentLeague: null,
  doneChanges: false,
  cancelChanges: () => {},
  updatingList: false,
  setUpdatingList: () => {},
  leaguesListNextToken: null,
  loadMoreLeagues: async () => true,
  editMode: true,
  setEditMode: () => {},
  addTranslation: () => {},
  hasError: false,
  translationsErrors: [],
  setTranslationsErrors: () => {},
  copyDetailsFromDefault: () => {},
  copyBodyFromDefault: () => {},
  upsertLeagueDocument: async () => true,
})

const LeaguesController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // states
  const [loading, setLoading] = useState<boolean>(true) // loading
  const [leaguesList, setLeaguesList] = useState<League[]>([]) // all leagues list
  const [leaguesListNextToken, setLeaguesListNextToken] = useState<
    string | null
  >(null) // leagues list next token
  const [hasSearched, setHasSearched] = useState<boolean>(false) // if user has searched or not
  const [updatingList, setUpdatingList] = useState<boolean>(false) // if list is updating or not
  const [currentLeague, setCurrentLeague] = useState<League | null>(null) // current league details
  const [preChangesCurrentLeague, setPreChangesCurrentLeague] =
    useState<League | null>(null) // fetched current league details
  const [doneChanges, setDoneChanges] = useState<boolean>(false) // if user has done changes to league details
  const [editMode, setEditMode] = useState<boolean>(true) // if is edit mode or not

  // errors for league edit
  const [translationsErrors, setTranslationsErrors] = useState<
    { lang: string; hasErrors: boolean }[]
  >([]) // errors array for translations
  const hasError: boolean =
    translationsErrors.filter((item) => item.hasErrors).length !== 0 // if there are errors or not

  // reset all errors
  const resetErrors = () => {
    const newTranslationsErrors: { lang: string; hasErrors: boolean }[] = []
    currentLeague.document.items.forEach((item) => {
      newTranslationsErrors.push({ lang: item.lang, hasErrors: false })
    })
    setTranslationsErrors(newTranslationsErrors)
  }

  // queries
  const [listLeaguesQuery] = useLazyQuery(listLeagues)
  const [leagueGetQuery] = useLazyQuery(leagueGet)

  // mutations
  const [upsertDocumentLeagueMutation] = useMutation(upsertDocumentLeague)

  // get leagues list
  const getLeaguesList = useCallback(
    async (withLoading: boolean = true) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter((item) => item.name === "Leagues")[0].loading = true
        setPanelStatus([...panelStatus])
      }

      try {
        logger(Status.Api, "QUERY leaguesList", listLeagues)
        const { data } = await listLeaguesQuery({
          variables: {
            input: {
              type: LeagueType.TierLeague,
              limit: Math.round(window.innerHeight / 74) + 10,
            },
          },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "leagues list", data.leaguesList.items)

        setLeaguesList(data.leaguesList.items)
        setLeaguesListNextToken(data.leaguesList.nextToken)

        setHasSearched(false)
        setLoading(false)
        setUpdatingList(false)

        panelStatus.filter((item) => item.name === "Leagues")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "Leagues")[0].loading = false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `leaguesList`, e.message)
        }
        setLoading(false)
        setUpdatingList(false)

        panelStatus.filter((item) => item.name === "Leagues")[0].status =
          "error"
        panelStatus.filter((item) => item.name === "Leagues")[0].loading = false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // load more leagues
  const loadMoreLeagues = async () => {
    try {
      logger(Status.Api, "QUERY leaguesList", listLeagues)
      const { data } = await listLeaguesQuery({
        variables: {
          input: {
            type: LeagueType.TierLeague,
            limit: Math.round(window.innerHeight / 74) + 10,
            nextToken: leaguesListNextToken,
          },
        },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "leagues list", [
        ...leaguesList,
        ...data.leaguesList.items,
      ])

      setLeaguesList([...leaguesList, ...data.leaguesList.items])
      setLeaguesListNextToken(data.leaguesList.nextToken)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `leaguesList`, e.message)
      }
      return false
    }
  }

  // get league details
  const getCurrentLeague = useCallback(
    async (leagueId: string) => {
      try {
        logger(Status.Api, "QUERY leagueGet", leagueGet)
        const { data } = await leagueGetQuery({
          variables: { input: { id: leagueId } },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, `league ${leagueId}`, data.leagueGet)

        setCurrentLeague(data.leagueGet)
        setPreChangesCurrentLeague(deepCopy(data.leagueGet))
        data.leagueGet.document.items.forEach((item: Document) => {
          translationsErrors.push({ lang: item.lang, hasErrors: false })
        })
        setTranslationsErrors([...translationsErrors])

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `leagueGet`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // upsert league document
  const upsertLeagueDocument = async () => {
    try {
      // parse data for inpout
      const currentLeagueCopy = deepCopy(currentLeague)
      currentLeagueCopy.document.items.forEach((item: any) => {
        const newBody = []
        if (item.body) {
          item.body.forEach((bodyItem: any) => {
            const { sliceType, __typename, ...rest } = bodyItem
            newBody.push({
              [lowercaseFirstCharacter(bodyItem.sliceType)]: {
                ...rest,
              },
            })
          })
        }
        item.body = newBody

        item.default = item.isDefault
        delete item.isDefault
        delete item.type
        delete item.updatedAt
        delete item.parentId
        delete item.__typename
      })

      const input = {
        leagueDocumentItems: currentLeagueCopy.document.items,
        parentId: currentLeagueCopy.document.parentId,
        type: "League",
      }

      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentLeague)
      const { data } = await upsertDocumentLeagueMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `league ${data.documentUpsert.parentId} document upserted`,
        data.documentUpsert
      )

      setCurrentLeague((current) => {
        return {
          ...current,
          document: deepCopy(data.documentUpsert),
        }
      })
      setPreChangesCurrentLeague((current) => {
        return {
          ...current,
          document: deepCopy(data.documentUpsert),
        }
      })

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // add translation
  const addTranslation = (translationToAdd: string) => {
    const documentToAdd: LeaguesDocument = {
      body: [],
      isDefault: false,
      lang: translationToAdd,
      title: "Title",
      type: "League",
      logo: currentLeague.document.items.find((item) => item.isDefault).logo,
    }

    translationsErrors.push({
      lang: translationToAdd,
      hasErrors: false,
    })
    setTranslationsErrors([...translationsErrors])

    currentLeague.document.items.push(documentToAdd)
    setCurrentLeague({ ...currentLeague })
  }

  // copy details from default translation
  const copyDetailsFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentLeague.document.items.filter(
      (item) => item.isDefault
    )[0]

    currentLeague.document.items[itemToCopyToIndex].title = defaultItem.title
    currentLeague.document.items[itemToCopyToIndex].logo = defaultItem.logo

    setCurrentLeague({ ...currentLeague })
  }

  // copy body from default translation
  const copyBodyFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentLeague.document.items.filter(
      (item) => item.isDefault
    )[0]

    currentLeague.document.items[itemToCopyToIndex].body = defaultItem.body

    setCurrentLeague({ ...currentLeague })
  }

  // check if user has done changes to details
  useEffect(() => {
    if (currentLeague && preChangesCurrentLeague) {
      const currentLeagueCopy: League = deepCopy(currentLeague)
      const preChangesCurrentLeagueCopy: League = deepCopy(
        preChangesCurrentLeague
      )

      if (
        JSON.stringify(currentLeagueCopy) ===
        JSON.stringify(preChangesCurrentLeagueCopy)
      ) {
        setDoneChanges(false)
      } else {
        setDoneChanges(true)
      }
    }
  }, [currentLeague, preChangesCurrentLeague])

  // cancel details changes
  const cancelChanges = () => {
    setCurrentLeague(deepCopy(preChangesCurrentLeague))
    resetErrors()
  }

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter((item) => item.name === "Leagues")[0].updateFunction =
      getLeaguesList
  }, [])

  return (
    <LeaguesContext.Provider
      value={{
        loading,
        setLoading,
        leaguesList,
        getLeaguesList,
        hasSearched,
        getCurrentLeague,
        currentLeague,
        setCurrentLeague,
        preChangesCurrentLeague,
        doneChanges,
        cancelChanges,
        updatingList,
        setUpdatingList,
        leaguesListNextToken,
        loadMoreLeagues,
        editMode,
        setEditMode,
        addTranslation,
        hasError,
        translationsErrors,
        setTranslationsErrors,
        copyDetailsFromDefault,
        copyBodyFromDefault,
        upsertLeagueDocument,
      }}
    >
      {children}
    </LeaguesContext.Provider>
  )
}

export { LeaguesController, LeaguesContext }
