import { Editor } from "react-draft-wysiwyg"
import { EditorState, ContentState, convertToRaw, RichUtils } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import "../../styles/htmlEditor.scss"
import SlideConstraints from "../../models/slideConstraints"
import Episode from "../../models/episode"
import { onQuizQuestionTextFieldChange } from "../../services/utilities/episodeUtility"
import EpisodeSlideQuiz from "../../models/episodeSlideQuiz"
import { Button } from "@mui/material"

const HtmlEditorQuiz = ({
  slide,
  constraints,
  currentEpisode,
  setCurrentEpisode,
  slideLanguage,
  index,
  error,
  setError,
  errorType,
  setErrorType,
  changesCancelled,
  disabled = false,
}: {
  slide: EpisodeSlideQuiz
  constraints: SlideConstraints
  currentEpisode: Episode
  setCurrentEpisode: Dispatch<SetStateAction<Episode>>
  slideLanguage: string
  index: number
  error: boolean
  setError: Dispatch<SetStateAction<boolean>>
  errorType: string
  setErrorType: Dispatch<SetStateAction<string>>
  changesCancelled: boolean
  disabled?: boolean
}) => {
  // placeholder
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(false)

  // has focus
  const [hasFocus, setHasFocus] = useState<boolean>(false)

  // editor state
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        htmlToDraft(slide.question ?? "").contentBlocks,
        htmlToDraft(slide.question ?? "").entityMap
      )
    )
  )

  useEffect(() => {
    let currentContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    )
      .replaceAll("\n", "")
      .replaceAll("&nbsp;", "")

    onQuizQuestionTextFieldChange(
      currentEpisode,
      setCurrentEpisode,
      currentContent,
      slideLanguage,
      slide,
      index
    )

    if (currentContent.replace(/(<([^>]+)>)/gi, "").length) {
      setShowPlaceholder(true)
    }

    if (
      currentContent.replace(/(<([^>]+)>)/gi, "").length >
      constraints.text.maxSize
    ) {
      setError(true)
      setErrorType(
        "Maximum length is " + constraints.text.maxSize + " characters"
      )
    } else if (
      currentContent.replace(/(<([^>]+)>)/gi, "").length <
      constraints.text.minSize
    ) {
      setError(true)
      setErrorType(
        "Minimum length is " + constraints.text.minSize + " characters"
      )
    } else {
      setError(false)
      setErrorType("")
    }
  }, [editorState])

  // cancel changes
  useEffect(() => {
    if (changesCancelled) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(slide.question ?? "").contentBlocks,
            htmlToDraft(slide.question ?? "").entityMap
          )
        )
      )
    }
  }, [changesCancelled])

  // show html
  const [showHtml, setShowHtml] = useState<boolean>(false)

  return (
    <div
      style={{ position: "relative", display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          position: "absolute",
          top: showPlaceholder ? -10 : 49,
          left: 9,
          color: error
            ? "#d3302f"
            : disabled
            ? "#9e9e9f"
            : hasFocus
            ? "#000000"
            : "#939EAA",
          fontSize: showPlaceholder ? 12 : "1rem",
          backgroundColor: "white",
          paddingLeft: 5,
          paddingRight: 5,
          height: 12,
          transition: "180ms",
        }}
      >
        Text
      </div>
      <Button
        style={{ position: "absolute", top: 12, right: 11, height: 32 }}
        variant="outlined"
        onClick={() => {
          setShowHtml(!showHtml)
        }}
      >
        {showHtml ? "Show Editor" : "Show HTML"}
      </Button>
      {showHtml ? (
        <div
          style={{
            height: "auto",
            paddingLeft: 11,
            paddingRight: 11,
            paddingTop: 59,
            paddingBottom: 16,
            cursor: "default",
          }}
          className={
            disabled
              ? "wrapper-disabled"
              : error && hasFocus
              ? "wrapper-error-focus-prismic"
              : error
              ? "wrapper-error-prismic"
              : hasFocus
              ? "wrapper-focus"
              : "wrapper"
          }
        >
          {draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        </div>
      ) : (
        <Editor
          readOnly={disabled}
          onFocus={() => {
            setShowPlaceholder(true)
            setHasFocus(true)
          }}
          onBlur={() => {
            setHasFocus(false)

            if (
              !draftToHtml(convertToRaw(editorState.getCurrentContent()))
                .replaceAll("\n", "")
                .replace(/(<([^>]+)>)/gi, "").length
            ) {
              setShowPlaceholder(false)
            }
          }}
          editorClassName={disabled ? "editor-disabled" : "editor"}
          toolbarClassName={disabled ? "toolbar-disabled" : "toolbar"}
          wrapperClassName={
            disabled
              ? "wrapper-disabled"
              : error && hasFocus
              ? "wrapper-error-focus-prismic"
              : error
              ? "wrapper-error-prismic"
              : hasFocus
              ? "wrapper-focus"
              : "wrapper"
          }
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbar={{
            options: ["inline", "history"],
            inline: {
              options: [
                "bold",
                "italic",
                "underline",
                "superscript",
                "subscript",
              ],
            },
          }}
          handlePastedText={() => false}
          handleKeyCommand={(command) => {
            if (command === "split-block") {
              const newEditorState = RichUtils.insertSoftNewline(editorState)
              setEditorState(newEditorState)

              return "handled"
            }

            return "not-handled"
          }}
        />
      )}
      {error && (
        <p
          style={{
            color: "#d3302f",
            fontSize: "0.75rem",
            lineHeight: 1.66,
            marginLeft: 14,
            marginTop: 4,
          }}
        >
          {errorType}
        </p>
      )}
    </div>
  )
}

export default HtmlEditorQuiz
