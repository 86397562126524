import { EpisodeSpecialType, Esg } from "../services/config/enum"
import Category from "./category"
import EpisodeTranslation from "./episodeTranslation"
import Sdg from "./sdg"
import SdgTarget from "./sdgTarget"
import Topic from "./topic"

class Episode {
  createdAt: string
  id: string
  lang: string
  title: string
  hasSibling: boolean
  type: string
  stage: "PUBLISHED" | "DRAFT" | "ARCHIVED"
  status: "ACTIVE" | "ARCHIVED"
  topics: { primary: boolean; topic: Topic }[]
  sdgs: { primary: boolean; sdg: Sdg }[]
  sdgTargets: { primary: boolean; sdgTarget: SdgTarget }[]
  text: string
  image: string
  translations: EpisodeTranslation[]
  updatedAt: string
  points: number
  doNotRecommend?: boolean
  recommendStartsAt?: string
  recommendEndsAt?: string
  publishedLangs?: { lang: string; title: string }[]
  draftedLangs?: { lang: string; title: string }[]
  category?: Category
  esg?: Esg
  specialType?: EpisodeSpecialType

  constructor(
    createdAt?: string,
    id?: string,
    lang?: string,
    title?: string,
    hasSibling?: boolean,
    type?: string,
    stage?: "PUBLISHED" | "DRAFT" | "ARCHIVED",
    status?: "ACTIVE" | "ARCHIVED",
    topics?: { primary: boolean; topic: Topic }[],
    sdgs?: { primary: boolean; sdg: Sdg }[],
    sdgTargets?: { primary: boolean; sdgTarget: SdgTarget }[],
    text?: string,
    image?: string,
    translations?: EpisodeTranslation[],
    updatedAt?: string,
    points?: number,
    doNotRecommend?: boolean,
    recommendStartsAt?: string,
    recommendEndsAt?: string,
    publishedLangs?: { lang: string; title: string }[],
    draftedLangs?: { lang: string; title: string }[],
    category?: Category,
    esg?: Esg,
    specialType?: EpisodeSpecialType
  ) {
    this.createdAt = createdAt
    this.id = id
    this.lang = lang
    this.title = title
    this.hasSibling = hasSibling
    this.type = type
    this.stage = stage
    this.status = status
    this.topics = topics
    this.sdgs = sdgs
    this.sdgTargets = sdgTargets
    this.text = text
    this.image = image
    this.translations = translations
    this.updatedAt = updatedAt
    this.points = points
    this.doNotRecommend = doNotRecommend
    this.recommendStartsAt = recommendStartsAt
    this.recommendEndsAt = recommendEndsAt
    this.publishedLangs = publishedLangs
    this.draftedLangs = draftedLangs
    this.category = category
    this.esg = esg
    this.specialType = specialType
  }
}

export default Episode
