import {
  Alert,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  LinearProgress,
  Snackbar,
  TableCell,
  TableRow,
  Typography,
  Zoom,
} from "@mui/material"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ListContainer from "../../components/global/listContainer"
import ListTable from "../../components/global/listTable"
import TableSpinner from "../../components/global/tableSpinner"
import { MainContext } from "../../controllers/main"
import {
  calculateTimeElapsed,
  capitalizeFirstCharacter,
  registerAnalyticsEvent,
} from "../../services/utilities/utility"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import { JourneysContext } from "../../controllers/journeys"
import Journey from "../../models/journey"
import CreateJourneyDialog from "../../components/journey/createJourneyDialog"
import JourneysListTopBar from "../../components/journey/journeysListTopBar"
import TitleTableCell from "../../components/global/titleTableCell"
import TranslationsStack from "../../components/global/translationsStack"
import "../../styles/journeysList.scss"
import { FileDownloadRounded } from "@mui/icons-material"
import ExportJourneysDialog from "../../components/journey/exportJourneysDialog"
import PointsBadge from "../../components/action/pointsBadge"

const JourneysList = ({
  forDialog = false,
  forDialogType,
  forDialogTypeDisabled = false,
  selectedJourneys = [],
  setSelectedJourneys,
  journeysToFilter = [],
}: {
  forDialog?: boolean
  forDialogType?: ("Free" | "Business" | "Custom")[]
  forDialogTypeDisabled?: boolean
  selectedJourneys?: Journey[]
  setSelectedJourneys?: Dispatch<SetStateAction<Journey[]>>
  journeysToFilter?: Journey[]
}) => {
  const { setAnimation, showPadding, cmdPressed } = useContext(MainContext)
  const {
    loading,
    journeysList,
    journeysListNextToken,
    getJourneysList,
    updatingList,
    setUpdatingList,
    searchValue,
    setSearchValue,
    hasSearched,
    searchLanguage,
    searchTopic,
    searchSdg,
    searchSdgTarget,
    searchCategory,
    searchEsg,
    searchStatus,
    searchType,
    searchJourneys,
    loadMoreJourneys,
  } = useContext(JourneysContext)
  const navigate = useNavigate()
  const { state }: { state: any } = useLocation()

  // list background update
  const backgroundUpdate = useCallback(() => {
    if (!loading && !hasSearched) {
      setUpdatingList(true)
      getJourneysList(false)
    }
  }, [getJourneysList]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!journeysList.length) {
      getJourneysList()
    }
    if (!state) {
      backgroundUpdate()
    }
  }, [backgroundUpdate])

  // handle create journey dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // load more button loading
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // archived snackbar
  const [archivedSnackbarOpen, setArchivedSnackbarOpen] = useState<boolean>(
    state ? true : false
  )

  // register analytics event
  useEffect(() => {
    registerAnalyticsEvent({ type: "page_view", name: "journeys_list" })
  }, [])

  // selected journeys to export for translation
  const [selectedJourneysForExport, setSelectedJourneysForExport] = useState<
    { journey: Journey; sourceLang?: string; langs: string[] }[]
  >([])

  // maximum selected journeys alert
  const [maximumJourneysAlertOpen, setMaximumJourneysAlertOpen] =
    useState<boolean>(false)

  // export journeys dialog
  const [exportDialogOpen, setExportDialogOpen] = useState<boolean>(false)

  const handleExportDialogChange = () => {
    setExportDialogOpen((current) => !current)
  }

  return (
    <ListContainer style={{ position: "relative" }}>
      {updatingList && (
        <LinearProgress
          style={{ position: "absolute", top: 198, width: "100%", zIndex: 10 }}
        />
      )}
      <JourneysListTopBar
        forDialog={forDialog}
        forDialogType={forDialogType}
        forDialogTypeDisabled={forDialogTypeDisabled}
        loading={loading || loadMoreLoading}
        textFieldValue={searchValue}
        textFieldPlaceholder="Search Journeys"
        addButtonLabel="Add Journey"
        textFieldOnChange={(e) => {
          setSearchValue(e.target.value)
          if (e.target.value.length === 0 && hasSearched) {
            setUpdatingList(true)

            if (
              !searchLanguage &&
              !searchTopic &&
              !searchSdg &&
              !searchSdgTarget &&
              !searchCategory &&
              !searchEsg &&
              searchType.includes("Free") &&
              searchType.includes("Business") &&
              !searchType.includes("Custom")
            ) {
              if (searchStatus === "active") {
                getJourneysList(false)
              } else {
                getJourneysList(false, true)
              }
            } else {
              if (searchStatus === "active") {
                searchJourneys({
                  title: "",
                  lang: searchLanguage,
                  topic: searchTopic,
                  sdg: searchSdg,
                  sdgTarget: searchSdgTarget,
                  category: searchCategory,
                  esg: searchEsg,
                  type: searchType,
                })
              } else {
                searchJourneys({
                  title: "",
                  lang: searchLanguage,
                  topic: searchTopic,
                  sdg: searchSdg,
                  sdgTarget: searchSdgTarget,
                  category: searchCategory,
                  esg: searchEsg,
                  type: searchType,
                  archived: true,
                })
              }
            }
          }
        }}
        textFieldOnKeyDown={(e) => {
          if (e.key === "Enter" && searchValue.length > 2) {
            if (searchStatus === "active") {
              searchJourneys({
                title: searchValue,
                lang: searchLanguage,
                topic: searchTopic,
                sdg: searchSdg,
                sdgTarget: searchSdgTarget,
                category: searchCategory,
                esg: searchEsg,
                type: searchType,
              })
            } else {
              searchJourneys({
                title: searchValue,
                lang: searchLanguage,
                topic: searchTopic,
                sdg: searchSdg,
                sdgTarget: searchSdgTarget,
                category: searchCategory,
                esg: searchEsg,
                type: searchType,
                archived: true,
              })
            }
          }
        }}
        cancelButtonOnClick={() => {
          setSearchValue("")
          if (hasSearched) {
            setUpdatingList(true)

            if (
              !searchLanguage &&
              !searchTopic &&
              !searchSdg &&
              !searchSdgTarget &&
              !searchCategory &&
              !searchEsg &&
              searchType.includes("Free") &&
              searchType.includes("Business") &&
              !searchType.includes("Custom")
            ) {
              if (searchStatus === "active") {
                getJourneysList(false)
              } else {
                getJourneysList(false, true)
              }
            } else {
              if (searchStatus === "active") {
                searchJourneys({
                  title: "",
                  lang: searchLanguage,
                  topic: searchTopic,
                  sdg: searchSdg,
                  sdgTarget: searchSdgTarget,
                  category: searchCategory,
                  esg: searchEsg,
                  type: searchType,
                })
              } else {
                searchJourneys({
                  title: "",
                  lang: searchLanguage,
                  topic: searchTopic,
                  sdg: searchSdg,
                  sdgTarget: searchSdgTarget,
                  category: searchCategory,
                  esg: searchEsg,
                  type: searchType,
                  archived: true,
                })
              }
            }
          }
        }}
        searchButtonOnClick={() => {
          if (searchStatus === "active") {
            searchJourneys({
              title: searchValue,
              lang: searchLanguage,
              topic: searchTopic,
              sdg: searchSdg,
              sdgTarget: searchSdgTarget,
              category: searchCategory,
              esg: searchEsg,
              type: searchType,
            })
          } else {
            searchJourneys({
              title: searchValue,
              lang: searchLanguage,
              topic: searchTopic,
              sdg: searchSdg,
              sdgTarget: searchSdgTarget,
              category: searchCategory,
              esg: searchEsg,
              type: searchType,
              archived: true,
            })
          }
        }}
        addButtonOnClick={handleDialogChange}
      />
      {loading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 262px)"
              : "calc(100vh - 230px)"
          }
        />
      ) : (
        <div style={{ position: "relative" }} id="journeys-list-main-container">
          <ListTable
            height={
              showPadding === "yes"
                ? "calc(100vh - 262px)"
                : "calc(100vh - 230px)"
            }
            nextToken={journeysListNextToken}
            loadingMoreItems={loadMoreLoading}
            setLoadingMoreItems={setLoadMoreLoading}
            loadMore={
              searchStatus === "active"
                ? loadMoreJourneys
                : async () => {
                    const result = await loadMoreJourneys(true)
                    return result
                  }
            }
            scrollToTopButtonDisabled={selectedJourneysForExport.length > 0}
            headingItems={
              forDialog
                ? [
                    "Stage",
                    "Thumbnail",
                    "Title & ID",
                    "Type",
                    "Translations",
                    "Category",
                    "Points",
                    "Topic",
                    "ESG",
                    "Updated",
                  ]
                : searchStatus === "archived"
                ? [
                    "",
                    "Stage",
                    "Thumbnail",
                    "Title & ID",
                    "Translations",
                    "Category",
                    "Points",
                    "Topic",
                    "ESG",
                    "Updated",
                  ]
                : [
                    "",
                    "Stage",
                    "Thumbnail",
                    "Title & ID",
                    "Type",
                    "Translations",
                    "Category",
                    "Points",
                    "Topic",
                    "ESG",
                    "Updated",
                  ]
            }
            tableBody={journeysList
              .filter((item: Journey) => {
                if (
                  !journeysToFilter.filter((item2) => item2.id === item.id)[0]
                ) {
                  return item
                }
              })
              .map((item: Journey) => (
                <TableRow
                  hover={item.stage && item.stage !== "ARCHIVED"}
                  style={{
                    cursor:
                      item.stage && item.stage !== "ARCHIVED"
                        ? "pointer"
                        : "default",
                    opacity: !item.stage ? 0.5 : 1,
                    backgroundColor: selectedJourneys.filter(
                      (selectedJourney: Journey) =>
                        selectedJourney.id === item.id
                    )[0]
                      ? "lightgray"
                      : null,
                  }}
                  key={item.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  onClick={() => {
                    if (forDialog) {
                      if (
                        !selectedJourneys.filter(
                          (selectedJourney: Journey) =>
                            selectedJourney.id === item.id
                        )[0]
                      ) {
                        selectedJourneys.push(item)
                        setSelectedJourneys([...selectedJourneys])
                      } else {
                        let indexToRemove = selectedJourneys.findIndex(
                          (selectedJourney: Journey) =>
                            selectedJourney.id === item.id
                        )
                        selectedJourneys.splice(indexToRemove, 1)
                        setSelectedJourneys([...selectedJourneys])
                      }
                    } else {
                      if (searchStatus === "active" && item.stage) {
                        if (cmdPressed) {
                          window.open(`${window.location.href}/${item.id}`)
                        } else {
                          setAnimation(false)
                          setTimeout(() => {
                            navigate(`/journeys/${item.id}`)
                          }, 250)
                        }
                      }
                    }
                  }}
                >
                  {!forDialog && (
                    <TableCell
                      style={{ padding: 0, transition: "100ms" }}
                      className={
                        selectedJourneysForExport.length
                          ? null
                          : "journeys-list-checkbox"
                      }
                    >
                      <Checkbox
                        id={"checkbox" + item.id}
                        checked={
                          selectedJourneysForExport.filter(
                            (item2) => item2.journey.id === item.id
                          )[0]
                            ? true
                            : false
                        }
                        onClick={(e) => {
                          e.stopPropagation()
                          if (
                            !selectedJourneysForExport.filter(
                              (item2) => item2.journey.id === item.id
                            ).length &&
                            selectedJourneysForExport.length > 4
                          ) {
                            setMaximumJourneysAlertOpen(true)
                            return
                          }
                          if (
                            selectedJourneysForExport.filter(
                              (item2) => item2.journey.id === item.id
                            )[0]
                          ) {
                            const indexToRemove =
                              selectedJourneysForExport.findIndex(
                                (item2) => item2.journey.id === item.id
                              )
                            selectedJourneysForExport.splice(indexToRemove, 1)
                            // remove animation
                            if (selectedJourneysForExport.length === 0) {
                              const animationDivs =
                                document.getElementsByClassName("animation-div")
                              for (let i = 0; i < animationDivs.length; i++) {
                                animationDivs[i].remove()
                              }
                            }
                          } else {
                            selectedJourneysForExport.push({
                              journey: item,
                              sourceLang: item.lang,
                              langs: [],
                            })
                            // animation
                            if (selectedJourneysForExport.length === 1) {
                              const checkbox = document.getElementById(
                                "checkbox" + item.id
                              )
                              const container = document.getElementById(
                                "journeys-list-main-container"
                              )
                              const divToAppend = document.createElement("div")
                              divToAppend.className = "animation-div"
                              divToAppend.style.opacity = "0"
                              divToAppend.style.top =
                                checkbox.getBoundingClientRect().top + "px"
                              divToAppend.style.left =
                                checkbox.getBoundingClientRect().left + "px"
                              container.appendChild(divToAppend)
                              setTimeout(() => {
                                const fab =
                                  document.getElementById("fab-export")
                                divToAppend.style.top =
                                  fab.getBoundingClientRect().top - 15 + "px"
                                divToAppend.style.left =
                                  fab.getBoundingClientRect().left - 15 + "px"
                                divToAppend.style.opacity = "1"
                              }, 50)
                              setTimeout(() => {
                                divToAppend.remove()
                              }, 650)
                            }
                          }
                          setSelectedJourneysForExport([
                            ...selectedJourneysForExport,
                          ])
                        }}
                        style={{
                          marginRight: -10,
                          transition: "100ms",
                        }}
                      />
                    </TableCell>
                  )}
                  <TableCell style={{ position: "relative" }}>
                    {item.stage === "PUBLISHED" || item.hasSibling ? (
                      <Chip
                        size="small"
                        label="Live"
                        color="success"
                        style={{ position: "relative", zIndex: 1 }}
                      />
                    ) : item.stage === "ARCHIVED" ? (
                      <Chip
                        size="small"
                        label="Archived"
                        style={{ position: "relative", zIndex: 1 }}
                      />
                    ) : (
                      <Chip
                        size="small"
                        label="Draft"
                        color="warning"
                        style={{ position: "relative", zIndex: 1 }}
                      />
                    )}
                    {item.hasSibling ? (
                      <Chip
                        size="small"
                        label="Draft"
                        color="warning"
                        style={{ position: "absolute", left: 18 }}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        width: 42,
                        height: 42,
                        borderRadius: 4,
                        backgroundImage: `url(${
                          item.image ?? noImagePlaceholder
                        })`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    />
                  </TableCell>
                  <TitleTableCell title={item.title} id={item.id} />
                  {searchStatus === "active" ? (
                    <TableCell>
                      {item.type === "Free" ? (
                        <Chip
                          size="small"
                          style={{ backgroundColor: "#dee3fc" }}
                          label={item.type}
                        />
                      ) : item.type === "Business" ? (
                        <Chip
                          size="small"
                          style={{ backgroundColor: "#adb4de" }}
                          label={item.type}
                        />
                      ) : (
                        <Chip
                          size="small"
                          style={{ backgroundColor: "#8d98e0" }}
                          label="None"
                        />
                      )}
                    </TableCell>
                  ) : null}
                  <TableCell>
                    <TranslationsStack publishedLangs={item.publishedLangs} />
                  </TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      label={item.category.name}
                      style={{
                        backgroundColor: item.category.backColor,
                        color: item.category.foreColor,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <PointsBadge points={item.points} />
                  </TableCell>
                  <TableCell>
                    {item.topics &&
                    item.topics.filter((item) => item.primary)[0] ? (
                      <Chip
                        size="small"
                        label={
                          item.topics.filter((item) => item.primary)[0].topic
                            .name
                        }
                      />
                    ) : (
                      <Typography
                        style={{
                          fontSize: "0.8125rem",
                          color: "rgba(0, 0, 0, 0.3)",
                        }}
                      >
                        none
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {item.esg ? (
                      <Chip
                        size="small"
                        label={capitalizeFirstCharacter(item.esg)}
                      />
                    ) : (
                      <Typography
                        style={{
                          fontSize: "0.8125rem",
                          color: "rgba(0, 0, 0, 0.3)",
                        }}
                      >
                        none
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }}>
                    <Typography variant="caption">
                      {calculateTimeElapsed(item.updatedAt)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          />
          <Zoom
            in={selectedJourneysForExport.length > 0}
            unmountOnExit
            style={{
              transitionDelay:
                selectedJourneysForExport.length > 0 ? "350ms" : "0ms",
            }}
          >
            <Fab
              id="fab-export"
              size="small"
              aria-label="export"
              color="primary"
              style={{
                position: "absolute",
                bottom: 9,
                right: 15,
                width: 50,
                height: 50,
              }}
              onClick={handleExportDialogChange}
            >
              <FileDownloadRounded />
              <div
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 10,
                  backgroundColor: "#465094",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  style={{
                    color: "white",
                    fontWeight: 700,
                    fontSize: 11,
                  }}
                >
                  {selectedJourneysForExport.length === 0
                    ? 1
                    : selectedJourneysForExport.length}
                </Typography>
              </div>
            </Fab>
          </Zoom>
          <Checkbox
            indeterminate
            style={{
              position: "absolute",
              top: -2,
              left: 0,
              zIndex: 5,
              opacity: selectedJourneysForExport.length ? 1 : 0,
              transition: "150ms",
            }}
            onClick={() => {
              setSelectedJourneysForExport([])
            }}
          />
        </div>
      )}
      <CreateJourneyDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
        topBarText="Add Journey"
      />
      {/* archived snackbar */}
      <Snackbar
        open={archivedSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => {
          setArchivedSnackbarOpen(false)
        }}
      >
        <Alert severity="success">
          Journey archived, list will be updated soon
        </Alert>
      </Snackbar>
      {/* maximum selected journeys alert */}
      <Dialog
        open={maximumJourneysAlertOpen}
        onClose={() => {
          setMaximumJourneysAlertOpen(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Maximum number reached
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            A maximum of 5 Journeys at a time can be exported
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setMaximumJourneysAlertOpen(false)
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* export journeys dialog */}
      <ExportJourneysDialog
        dialogOpen={exportDialogOpen}
        handleDialogChange={handleExportDialogChange}
        selectedJourneysForExport={selectedJourneysForExport}
        setSelectedJourneysForExport={setSelectedJourneysForExport}
      />
    </ListContainer>
  )
}

export default JourneysList
