import { useLazyQuery } from "@apollo/client"
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { listLanguages } from "../services/graphql/queries"
import { logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"
import { isoLanguages } from "../services/config/isoLanguages"
import { flags } from "../services/config/flags"

interface LanguagesContextInterface {
  languages: string[]
  languagesForEpisodeTabs: { value: string; label: string; flag?: string }[]
  loadingLanguages: boolean
}

const LanguagesContext = createContext<LanguagesContextInterface>({
  languages: [],
  languagesForEpisodeTabs: [{ value: "general", label: "General" }],
  loadingLanguages: true,
})

const LanguagesController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage } = useContext(MainContext)

  // states
  const [languages, setLanguages] = useState<string[]>([]) // list of available languages
  const [languagesForEpisodeTabs, setLanguagesForEpisodeTabs] = useState<
    { value: string; label: string; flag?: string }[]
  >([{ value: "general", label: "General" }])
  const [loadingLanguages, setLoadingLanguages] = useState<boolean>(true)

  // queries
  const [listLanguagesQuery] = useLazyQuery(listLanguages)

  // get languages list
  const getLanguagesList = useCallback(async () => {
    try {
      logger(Status.Api, "QUERY listLanguages", listLanguages)
      const { data } = await listLanguagesQuery()
      logger(Status.Info, `languages list`, data.__type.enumValues)
      let listToSet: string[] = data.__type.enumValues.map(
        (item: any) => item.name
      )

      setLanguages(listToSet)
      for (let i = 0; i < listToSet.length; i++) {
        languagesForEpisodeTabs.push({
          value: listToSet[i],
          label: isoLanguages[listToSet[i]].name,
          flag: flags.filter((item) => item.language === listToSet[i])[0]
            ? flags.filter((item) => item.language === listToSet[i])[0].flag
            : null,
        })
      }
      setLanguagesForEpisodeTabs([...languagesForEpisodeTabs])

      setLoadingLanguages(false)
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `listLanguages`, e.message)
      }
    }
  }, [setError, setErrorMessage])

  // initial fetch
  useEffect(() => {
    getLanguagesList()
  }, [getLanguagesList])

  return (
    <LanguagesContext.Provider
      value={{
        languages,
        languagesForEpisodeTabs,
        loadingLanguages,
      }}
    >
      {children}
    </LanguagesContext.Provider>
  )
}

export { LanguagesController, LanguagesContext }
