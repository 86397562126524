import { Stack, TableCell, Typography } from "@mui/material"
import CopyToClipboardButton from "./copyToClipboardButton"

const TitleTableCell = ({
  title,
  id,
  otherField,
  titleMaxWidth = 300,
  size = "normal",
}: {
  title: string
  id: string
  otherField?: string
  titleMaxWidth?: number
  size?: "normal" | "small"
}) => {
  return (
    <TableCell>
      <Stack sx={{ gap: -5 }}>
        <Typography
          style={{
            fontSize: 15,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: titleMaxWidth,
          }}
        >
          {title}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          style={{ marginLeft: -3, marginTop: size === "normal" ? 0 : -3 }}
        >
          <CopyToClipboardButton
            textToCopy={id}
            size={size === "normal" ? "small" : "smaller"}
          />
          <Typography
            variant="caption"
            style={{
              fontSize: size === "normal" ? 10 : 9,
              color: "gray",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {id}
          </Typography>
        </Stack>
        {otherField ? (
          <Stack
            direction="row"
            alignItems="center"
            style={{ marginLeft: -3, marginTop: size === "normal" ? 0 : -3 }}
          >
            <CopyToClipboardButton
              textToCopy={otherField}
              size={size === "normal" ? "small" : "smaller"}
            />
            <Typography
              variant="caption"
              style={{
                fontSize: size === "normal" ? 10 : 9,
                color: "gray",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {otherField}
            </Typography>
          </Stack>
        ) : null}
      </Stack>
    </TableCell>
  )
}

export default TitleTableCell
