import { Typography } from "@mui/material"
import { useContext } from "react"
import { NewsContext } from "../../controllers/news"

const NewsCardPreview = () => {
  const { currentNews } = useContext(NewsContext)

  return (
    <div
      style={{
        width: 335,
        height: 154,
        borderRadius: 16,
        boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.25)",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div
        style={{
          width: 170,
          height: 154,
          borderRadius: 76.5,
          position: "absolute",
          left: 223,
          backgroundColor: "greenyellow",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            height: 154,
            width: 112,
            backgroundColor: "red",
            backgroundImage: `url(${
              currentNews.document.items.filter((item) => item.isDefault)[0]
                .image
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </div>
      <Typography
        style={{
          fontSize: 12,
          position: "absolute",
          top: 15,
          left: 18,
          fontWeight: 300,
        }}
      >
        {currentNews.document.items.filter((item) => item.isDefault)[0]
          .tagline ?? "Discovery"}
      </Typography>
      <div
        style={{
          position: "absolute",
          left: 18,
          top: 70,
          width: 190,
          height: 57,
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <Typography style={{ fontSize: 16, lineHeight: "19px" }}>
          {currentNews.document.items.filter((item) => item.isDefault)[0].title}
        </Typography>
      </div>
    </div>
  )
}

export default NewsCardPreview
