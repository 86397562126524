import {
  Chip,
  LinearProgress,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import ListTable from "../../global/listTable"
import TableSpinner from "../../global/tableSpinner"
import { MainContext } from "../../../controllers/main"
import ListTopBar from "../../global/listTopBar"
import { TagsContext } from "../../../controllers/tags"
import { calculateTimeElapsed } from "../../../services/utilities/utility"
import Category from "../../../models/category"
import CreateCategoryDialog from "./createCategoryDialog"
import { useNavigate } from "react-router-dom"
import TitleTableCellCategory from "../general/titleTableCellCategory"
import ListFilters from "../../global/listFilters"
import { AutocompleteOption } from "../../../services/config/interfaces"
import TranslationsStack from "../../global/translationsStack"

const CategoriesListTab = () => {
  const { showPadding, setAnimation } = useContext(MainContext)
  const {
    categoriesLoading,
    updatingCategoriesList,
    categoriesList,
    getCategoriesList,
    categoriesFilters,
    setCategoriesFilters,
  } = useContext(TagsContext)
  const navigate = useNavigate()

  // list background update
  const backgroundUpdate = () => {
    if (!categoriesList.length) {
      getCategoriesList()
    } else {
      getCategoriesList(false)
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    backgroundUpdate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create action dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // text field value for search
  const [textFieldValue, setTextFieldValue] = useState<string>("")

  return (
    <div style={{ position: "relative" }}>
      {updatingCategoriesList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 136,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <ListTopBar
        loading={categoriesLoading}
        textFieldValue={textFieldValue}
        textFieldDisabled={updatingCategoriesList}
        textFieldPlaceholder="Search Categories"
        addButtonLabel="Add Category"
        textFieldOnChange={(e) => {
          setTextFieldValue(e.target.value)
        }}
        textFieldOnKeyDown={(e) => {
          if (e.key === "Enter") {
            categoriesFilters.name = textFieldValue
            setCategoriesFilters({ ...categoriesFilters })
          }
        }}
        cancelButtonOnClick={() => {
          setTextFieldValue("")
          categoriesFilters.name = ""
          setCategoriesFilters({ ...categoriesFilters })
        }}
        searchButtonOnClick={() => {
          categoriesFilters.name = textFieldValue
          setCategoriesFilters({ ...categoriesFilters })
        }}
        addButtonOnClick={handleDialogChange}
      />
      <ListFilters
        disabled={updatingCategoriesList}
        filters={[
          {
            type: "language",
            value: categoriesFilters.lang,
            setValue: (newValue: AutocompleteOption | null) => {
              categoriesFilters.lang = newValue
              setCategoriesFilters({ ...categoriesFilters })
            },
          },
          {
            type: "mainCategory",
            value: categoriesFilters.mainCategory,
            setValue: (newValue: AutocompleteOption | null) => {
              categoriesFilters.mainCategory = newValue
              setCategoriesFilters({ ...categoriesFilters })
            },
          },
        ]}
        style={{ paddingInline: 10 }}
      />
      {categoriesLoading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 248px)"
              : "calc(100vh - 115px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 248px)"
              : "calc(100vh - 115px)"
          }
          headingItems={[
            "Title & ID",
            "Code",
            "Translations",
            "Main Cat.",
            "Updated",
          ]}
          tableBody={categoriesList.map((item: Category) => (
            <TableRow
              key={item.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
              hover
              onClick={() => {
                setAnimation(false)
                setTimeout(() => {
                  navigate(`/tags/category/${item.id}`)
                }, 250)
              }}
            >
              <TitleTableCellCategory
                title={item.name}
                id={item.id}
                backTagColor={item.backTagColor}
                foreColor={item.foreColor}
              />
              <TableCell>
                <Typography>{item.code}</Typography>
              </TableCell>
              <TableCell>
                <TranslationsStack translations={item.translations} />
              </TableCell>
              <TableCell>
                {item.mainCategory ? (
                  <Chip
                    label={item.mainCategory.name}
                    style={{
                      backgroundColor: item.mainCategory.backTagColor,
                      color: item.mainCategory.foreColor,
                      maxWidth: 120,
                    }}
                    size="small"
                  />
                ) : (
                  <Chip
                    label="none"
                    size="small"
                    style={{
                      opacity: 0.3,
                      backgroundColor: "rgba(0, 0, 0, 0)",
                    }}
                  />
                )}
              </TableCell>
              <TableCell style={{ minWidth: 120 }}>
                <Typography variant="caption">
                  {calculateTimeElapsed(item.updatedAt)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        />
      )}
      <CreateCategoryDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
      />
    </div>
  )
}

export default CategoriesListTab
