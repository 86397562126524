import {
  Chip,
  LinearProgress,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"
import ListContainer from "../../components/global/listContainer"
import ListTable from "../../components/global/listTable"
import TableSpinner from "../../components/global/tableSpinner"
import { MainContext } from "../../controllers/main"
import {
  calculateTimeElapsed,
  registerAnalyticsEvent,
} from "../../services/utilities/utility"
import { CountriesContext } from "../../controllers/countries"
import Badge from "../../models/badge"
import { BadgesContext } from "../../controllers/badges"
import BadgesListTopBar from "../../components/badge/badgesListTopBar"
import CreateBadgeDialog from "../../components/badge/createBadgeDialog"
import TitleTableCell from "../../components/global/titleTableCell"
import TranslationsStack from "../../components/global/translationsStack"

interface AutocompleteOption {
  label: string
  id: string
}

const BadgesList = ({
  forDialog = false,
  badgesToFilter = [],
  selectedBadge,
  setSelectedBadge,
  categoryFilter,
}: {
  forDialog?: boolean
  badgesToFilter?: Badge[]
  selectedBadge?: Badge | null
  setSelectedBadge?: Dispatch<SetStateAction<Badge | null>>
  categoryFilter?: AutocompleteOption
}) => {
  const { setAnimation, showPadding, cmdPressed } = useContext(MainContext)
  const { loading: countriesControllerLoading } = useContext(CountriesContext)
  const {
    loading,
    setLoading,
    updatingList,
    setUpdatingList,
    badgesList,
    setBadgesList,
    getBadgesList,
    badgesListNextToken,
    loadMoreBadges,
    hasSearched,
    searchLang,
    searchCategory,
    setSearchCategory,
    searchTitle,
    setSearchTitle,
    searchBadgesList,
  } = useContext(BadgesContext)
  const navigate = useNavigate()

  // list background update
  const backgroundUpdate = () => {
    if (!badgesList.length) {
      getBadgesList()
    } else if (!loading && !hasSearched) {
      setUpdatingList(true)
      getBadgesList(false)
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!categoryFilter) {
      backgroundUpdate()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create action dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // set filter if present
  useEffect(() => {
    if (categoryFilter) {
      setBadgesList([])
      setSearchCategory(categoryFilter)
      setLoading(false)
    }
  }, [])

  // load more button loading
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // register analytics event
  useEffect(() => {
    registerAnalyticsEvent({ type: "page_view", name: "badges_list" })
  }, [])

  return (
    <ListContainer style={{ position: "relative" }}>
      {updatingList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 143,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <BadgesListTopBar
        forDialog={forDialog}
        loading={loading}
        textFieldValue={searchTitle}
        textFieldPlaceholder="Search Badges"
        addButtonLabel="Add Badge"
        addButtonDisabled={countriesControllerLoading}
        textFieldOnChange={(e) => {
          setSearchTitle(e.target.value)
          if (e.target.value.length === 0 && hasSearched) {
            setUpdatingList(true)

            if (!searchLang && !searchCategory) {
              getBadgesList(false)
            } else {
              searchBadgesList()
            }
          }
        }}
        textFieldOnKeyDown={(e) => {
          if (e.key === "Enter" && searchTitle.length > 2) {
            searchBadgesList()
          }
        }}
        cancelButtonOnClick={() => {
          setSearchTitle("")
          if (hasSearched) {
            setUpdatingList(true)

            if (!searchLang && !searchCategory) {
              getBadgesList(false)
            } else {
              searchBadgesList()
            }
          }
        }}
        searchButtonOnClick={() => {
          searchBadgesList()
        }}
        addButtonOnClick={handleDialogChange}
      />
      {loading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
          headingItems={[
            "Image",
            "Title & ID",
            "Translations",
            "Category",
            "Updated",
          ]}
          nextToken={badgesListNextToken}
          loadingMoreItems={loadMoreLoading}
          setLoadingMoreItems={setLoadMoreLoading}
          loadMore={
            !searchTitle.length && !searchLang && !searchCategory
              ? loadMoreBadges
              : async () => {
                  const result = await searchBadgesList(false, true)
                  return result
                }
          }
          tableBody={badgesList
            .filter((item) => {
              if (!badgesToFilter.filter((item2) => item2.id === item.id)[0]) {
                return item
              }
            })
            .map((item: Badge) => (
              <TableRow
                key={item.id}
                style={{
                  cursor: "pointer",
                  backgroundColor:
                    selectedBadge && selectedBadge.id === item.id
                      ? "lightgray"
                      : null,
                }}
                hover
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
                onClick={() => {
                  if (!forDialog) {
                    if (cmdPressed) {
                      window.open(`${window.location.href}/${item.id}`)
                    } else {
                      setAnimation(false)
                      setTimeout(() => {
                        navigate(`/badges/${item.id}`)
                      }, 250)
                    }
                  } else {
                    if (selectedBadge && selectedBadge.id === item.id) {
                      setSelectedBadge(null)
                    } else {
                      setSelectedBadge(item)
                    }
                  }
                }}
              >
                <TableCell>
                  <div
                    style={{
                      width: 42,
                      height: 42,
                      borderRadius: 4,
                      backgroundImage: `url(${
                        item.image ?? noImagePlaceholder
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                </TableCell>
                <TitleTableCell
                  title={
                    item.document.items.filter(
                      (documentItem) => documentItem.isDefault
                    )[0].title
                  }
                  id={item.id}
                />
                <TableCell>
                  <TranslationsStack documentItems={item.document.items} />
                </TableCell>
                <TableCell>
                  {item.category ? (
                    <Chip
                      size="small"
                      label={item.category.name}
                      style={{
                        backgroundColor: item.category.backColor,
                        color: item.category.foreColor,
                      }}
                    />
                  ) : (
                    <Chip size="small" label="none" color="default" />
                  )}
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    {calculateTimeElapsed(item.updatedAt)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        />
      )}
      {!forDialog ? (
        <CreateBadgeDialog
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          handleDialogChange={handleDialogChange}
          topBarText="Add Badge"
        />
      ) : null}
    </ListContainer>
  )
}

export default BadgesList
