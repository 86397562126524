import {
  Autocomplete,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useContext, useState } from "react"
import { JourneysContext } from "../../controllers/journeys"
import { LanguagesContext } from "../../controllers/languages"
import { MainContext } from "../../controllers/main"
import { TeamJourneysRelationsContext } from "../../controllers/teamJourneysRelations"
import Journey from "../../models/journey"
import Sdg from "../../models/sdg"
import SdgTarget from "../../models/sdgTarget"
import Topic from "../../models/topic"
import { Esg } from "../../services/config/enum"
import {
  capitalizeFirstCharacter,
  deepCopy,
  enumAsArray,
  renderOption,
  renderTags,
} from "../../services/utilities/utility"
import CantPerformActionDialog from "../global/cantPerformActionDialog"
import ImagePicker from "../global/imagePicker"
import { TagsContext } from "../../controllers/tags"
import ClearableSelect from "../global/clearableSelect"

interface AutocompleteOption {
  label: string
  id: string
}

const JourneyGeneralEdit = () => {
  const { showPadding, panelStatus } = useContext(MainContext)
  const { languages } = useContext(LanguagesContext)
  const {
    categoriesLoading,
    categoriesList,
    topicsList,
    sdgsList,
    sdgTargetsList,
  } = useContext(TagsContext)
  const { currentFreeJourneysList } = useContext(TeamJourneysRelationsContext)
  const { editMode, currentJourney, setCurrentJourney } =
    useContext(JourneysContext)

  // secondary topics error if trying to add more than 3 or less than 1
  const [secondaryTopicsError, setSecondaryTopicsError] =
    useState<boolean>(false)

  // sdgs error if trying to add more than 3 or less than 1
  const [secondarySdgsError, setSecondarySdgsError] = useState<boolean>(false)

  // cannot edit type alert dialog
  const [cannotEditTypeAlertOpen, setCannotEditTypeAlertOpen] =
    useState<boolean>(false)

  return (
    <div
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 243px)" : "calc(100vh - 227px)",
        position: "relative",
      }}
    >
      <div
        style={{
          height:
            showPadding === "yes"
              ? "calc(100vh - 243px)"
              : "calc(100vh - 227px)",
          overflow: "scroll",
        }}
      >
        <CardContent style={{ position: "relative", overflowX: "hidden" }}>
          <Stack spacing={2} style={{ marginTop: -6 }}>
            {/* required fields */}
            <Stack spacing={2}>
              <Typography variant="h6" className="card-title">
                Master language
              </Typography>
              <FormControl
                fullWidth
                size="small"
                disabled={!editMode}
                error={
                  currentJourney.episodes
                    .map((item) => item.episode.publishedLangs)
                    .filter((item) => {
                      for (let i = 0; i < item.length; i++) {
                        if (item[i].lang === currentJourney.lang) {
                          return item
                        }
                      }
                    }).length !== currentJourney.episodes.length
                }
              >
                <InputLabel id="language-select">Master language</InputLabel>
                <Select
                  labelId="language-select"
                  label="Master language"
                  value={currentJourney?.lang}
                  onChange={(e) => {
                    setCurrentJourney({
                      ...currentJourney!,
                      title: currentJourney.publishedLangs.find(
                        (item) => item.lang === e.target.value
                      )
                        ? currentJourney.publishedLangs.find(
                            (item) => item.lang === e.target.value
                          ).title
                        : "",
                      lang: e.target.value,
                    })
                  }}
                >
                  {languages.map((lang) => (
                    <MenuItem key={lang} value={lang}>
                      {lang.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
                {currentJourney.episodes
                  .map((item) => item.episode.publishedLangs)
                  .filter((item) => {
                    for (let i = 0; i < item.length; i++) {
                      if (item[i].lang === currentJourney.lang) {
                        return item
                      }
                    }
                  }).length !== currentJourney.episodes.length && (
                  <FormHelperText error>
                    There are one or more episodes without{" "}
                    {currentJourney.lang.toUpperCase()} translation published
                  </FormHelperText>
                )}
              </FormControl>
              <Typography variant="h6" className="card-title">
                Text
              </Typography>
              <TextField
                disabled={!editMode}
                value={currentJourney.title ?? ""}
                fullWidth
                label={`Title (${currentJourney.lang.toUpperCase()})`}
                variant="outlined"
                size="small"
                onChange={(e) => {
                  if (
                    currentJourney.publishedLangs.filter(
                      (item) => item.lang === currentJourney.lang
                    )[0]
                  ) {
                    let currentJourneyCopy: Journey = deepCopy(currentJourney)
                    currentJourneyCopy.title = e.target.value
                    currentJourneyCopy.publishedLangs.filter(
                      (item) => item.lang === currentJourney.lang
                    )[0].title = e.target.value
                    setCurrentJourney(currentJourneyCopy)
                  } else {
                    setCurrentJourney({
                      ...currentJourney!,
                      title: e.target.value,
                    })
                  }
                }}
                error={
                  (currentJourney.title && currentJourney.title.length < 3) ||
                  !currentJourney.title
                }
                helperText={
                  (currentJourney.title && currentJourney.title.length < 3) ||
                  !currentJourney.title
                    ? "Minimum length is 3 characters"
                    : null
                }
                onBlur={() => {
                  if (currentJourney.title) {
                    if (
                      currentJourney.publishedLangs.filter(
                        (item) => item.lang === currentJourney.lang
                      )[0]
                    ) {
                      let currentJourneyCopy: Journey = deepCopy(currentJourney)
                      currentJourneyCopy.title = currentJourneyCopy.title.trim()
                      currentJourneyCopy.publishedLangs.filter(
                        (item) => item.lang === currentJourney.lang
                      )[0].title = currentJourneyCopy.title.trim()
                      setCurrentJourney(currentJourneyCopy)
                    } else {
                      setCurrentJourney({
                        ...currentJourney!,
                        title: currentJourney.title.trim(),
                      })
                    }
                  }
                }}
              />
              <TextField
                disabled={!editMode}
                value={currentJourney.text ?? ""}
                fullWidth
                label={`Description (${currentJourney.lang.toUpperCase()})`}
                variant="outlined"
                size="small"
                multiline
                rows={2}
                onChange={(e) => {
                  setCurrentJourney({
                    ...currentJourney!,
                    text: e.target.value,
                  })
                }}
                error={
                  (currentJourney.text && currentJourney.text.length < 3) ||
                  !currentJourney.text
                }
                helperText={
                  (currentJourney.text && currentJourney.text.length < 3) ||
                  !currentJourney.text
                    ? "Minimum length is 3 characters"
                    : null
                }
                onBlur={() => {
                  if (currentJourney.text) {
                    setCurrentJourney({
                      ...currentJourney!,
                      text: currentJourney.text.trim(),
                    })
                  }
                }}
              />
            </Stack>
            {/* type */}
            <Stack spacing={2}>
              <Typography variant="h6" className="card-title">
                Type
              </Typography>
              <FormControl
                fullWidth
                size="small"
                disabled={
                  !editMode ||
                  panelStatus.filter((item) => item.name === "Free release")[0]
                    .status !== "success"
                }
              >
                <InputLabel id="type-select">Type</InputLabel>
                <Select
                  labelId="type-select"
                  label="Type"
                  value={currentJourney.type}
                  onChange={(e) => {
                    if (
                      currentFreeJourneysList.filter(
                        (item) => item.id === currentJourney.id
                      ).length
                    ) {
                      setCannotEditTypeAlertOpen(true)
                    } else {
                      setCurrentJourney({
                        ...currentJourney,
                        type: e.target.value,
                      })
                    }
                  }}
                >
                  <MenuItem value="Free">Free</MenuItem>
                  <MenuItem value="Business">Business</MenuItem>
                  <MenuItem value="Custom">Custom</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            {/* category */}
            <Stack spacing={2}>
              <Typography variant="h6" className="card-title">
                Category
              </Typography>
              <Autocomplete
                disabled={!editMode}
                loading={categoriesLoading}
                fullWidth
                blurOnSelect
                size="small"
                value={
                  currentJourney.category
                    ? {
                        id: currentJourney.category.id,
                        label: currentJourney.category.name,
                      }
                    : null
                }
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={categoriesList.map((item) => {
                  return {
                    id: item.id,
                    label: item.name,
                  }
                })}
                renderInput={(params) => (
                  <TextField {...params} label="Category" />
                )}
                onChange={(e: any, newValue: AutocompleteOption | null) => {
                  let currentJourneyCopy = deepCopy(currentJourney)
                  if (newValue) {
                    currentJourneyCopy.category = {
                      id: categoriesList.filter(
                        (item) => item.id === newValue.id
                      )[0].id,
                      name: categoriesList.filter(
                        (item) => item.id === newValue.id
                      )[0].name,
                    }
                  } else {
                    currentJourneyCopy.category = null
                  }
                  setCurrentJourney(currentJourneyCopy)
                }}
                renderOption={renderOption}
                renderTags={renderTags}
              />
              <ClearableSelect
                label="ESG"
                value={currentJourney.esg ?? ""}
                onChange={(newValue) => {
                  let currentJourneyCopy = deepCopy(currentJourney)
                  currentJourneyCopy.esg = newValue as Esg
                  setCurrentJourney(currentJourneyCopy)
                }}
              >
                {enumAsArray(Esg).map((key: string) => (
                  <MenuItem key={key} value={key}>
                    {capitalizeFirstCharacter(key)}
                  </MenuItem>
                ))}
              </ClearableSelect>
            </Stack>
            {/* topics */}
            <Stack spacing={2}>
              <Typography variant="h6" className="card-title">
                Topics
              </Typography>
              <Autocomplete
                disabled={!editMode}
                getOptionDisabled={(option: AutocompleteOption) => {
                  if (
                    currentJourney.topics.filter(
                      (topic) => topic.topic.id === option.id
                    )[0] &&
                    !currentJourney.topics.filter(
                      (topic) => topic.topic.id === option.id
                    )[0].primary
                  ) {
                    return true
                  } else {
                    return false
                  }
                }}
                fullWidth
                size="small"
                value={
                  currentJourney.topics.filter((item) => item.primary)[0]
                    ? {
                        label: currentJourney.topics.filter(
                          (item) => item.primary
                        )[0].topic.name,
                        id: currentJourney.topics.filter(
                          (item) => item.primary
                        )[0].topic.id,
                      }
                    : null
                }
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={
                  topicsList.length
                    ? topicsList.map((topic: Topic) => {
                        return { label: topic.name, id: topic.id }
                      })
                    : []
                }
                renderInput={(params) => (
                  <TextField {...params} label="Primary topic" />
                )}
                onChange={(e: any, newValue: AutocompleteOption | null) => {
                  if (newValue) {
                    setCurrentJourney({
                      ...currentJourney!,
                      topics: [
                        {
                          primary: true,
                          topic: {
                            id: newValue.id,
                            name: newValue.label,
                          },
                        },
                        ...currentJourney.topics.filter(
                          (topic) => !topic.primary
                        ),
                      ],
                    })
                  }
                }}
                renderOption={renderOption}
                renderTags={renderTags}
              />
              <Autocomplete
                disabled={!editMode}
                getOptionDisabled={(option: AutocompleteOption) => {
                  if (
                    currentJourney.topics.filter(
                      (topic) => topic.topic.id === option.id
                    )[0] &&
                    currentJourney.topics.filter(
                      (topic) => topic.topic.id === option.id
                    )[0].primary
                  ) {
                    return true
                  } else {
                    return false
                  }
                }}
                multiple
                fullWidth
                onBlur={() => {
                  setSecondaryTopicsError(false)
                }}
                size="small"
                value={currentJourney.topics
                  .filter((topic) => !topic.primary)
                  .map((topic) => {
                    return {
                      label: topic.topic.name,
                      id: topic.topic.id,
                    }
                  })}
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={
                  topicsList.length
                    ? topicsList.map((topic: Topic) => {
                        return { label: topic.name, id: topic.id }
                      })
                    : []
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={secondaryTopicsError}
                    helperText={
                      secondaryTopicsError
                        ? "The maximum number of secondary topics is 3"
                        : null
                    }
                    label="Secondary topics"
                  />
                )}
                onChange={(e: any, newValues: AutocompleteOption[]) => {
                  if (newValues.length < 4) {
                    setCurrentJourney({
                      ...currentJourney!,
                      topics: [
                        ...currentJourney.topics.filter(
                          (topic) => topic.primary
                        ),
                        ...newValues.map((topic) => {
                          return {
                            primary: false,
                            topic: {
                              id: topic.id,
                              name: topic.label,
                            },
                          }
                        }),
                      ],
                    })
                    setSecondaryTopicsError(false)
                  } else {
                    setSecondaryTopicsError(true)
                  }
                }}
                renderOption={renderOption}
                renderTags={renderTags}
              />
            </Stack>
            {/* sdgs */}
            <Stack spacing={2}>
              <Typography variant="h6" className="card-title">
                SDGs
              </Typography>
              <Autocomplete
                disabled={!editMode}
                getOptionDisabled={(option: AutocompleteOption) => {
                  if (
                    currentJourney.sdgs.filter(
                      (sdg) => sdg.sdg.id === option.id
                    )[0] &&
                    !currentJourney.sdgs.filter(
                      (sdg) => sdg.sdg.id === option.id
                    )[0].primary
                  ) {
                    return true
                  } else {
                    return false
                  }
                }}
                fullWidth
                size="small"
                value={
                  currentJourney.sdgs.filter((item) => item.primary)[0]
                    ? {
                        label:
                          (currentJourney.sdgs
                            .filter((item) => item.primary)[0]
                            .sdg.id.charAt(4) === "0"
                            ? currentJourney.sdgs
                                .filter((item) => item.primary)[0]
                                .sdg.id.slice(5) + ". "
                            : currentJourney.sdgs
                                .filter((item) => item.primary)[0]
                                .sdg.id.slice(4) + ". ") +
                          currentJourney.sdgs.filter((item) => item.primary)[0]
                            .sdg.name,
                        id: currentJourney.sdgs.filter(
                          (item) => item.primary
                        )[0].sdg.id,
                      }
                    : null
                }
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={
                  sdgsList.length
                    ? sdgsList.map((sdg: Sdg) => {
                        let idForLabel =
                          sdg.id.charAt(4) === "0"
                            ? sdg.id.slice(5) + ". "
                            : sdg.id.slice(4) + ". "

                        return { label: idForLabel + sdg.name, id: sdg.id }
                      })
                    : []
                }
                renderInput={(params) => (
                  <TextField {...params} label="Primary SDG" />
                )}
                onChange={(e: any, newValue: AutocompleteOption | null) => {
                  if (newValue) {
                    setCurrentJourney({
                      ...currentJourney!,
                      sdgs: [
                        {
                          primary: true,
                          sdg: {
                            id: newValue.id,
                            name: newValue.label.slice(
                              newValue.label.indexOf(".") + 2
                            ),
                          },
                        },
                        ...currentJourney.sdgs.filter((sdg) => !sdg.primary),
                      ],
                    })
                  }
                }}
                renderOption={renderOption}
                renderTags={renderTags}
              />
              <Autocomplete
                disabled={!editMode}
                getOptionDisabled={(option: AutocompleteOption) => {
                  if (
                    currentJourney.sdgs.filter(
                      (sdg) => sdg.sdg.id === option.id
                    )[0] &&
                    currentJourney.sdgs.filter(
                      (sdg) => sdg.sdg.id === option.id
                    )[0].primary
                  ) {
                    return true
                  } else {
                    return false
                  }
                }}
                multiple
                fullWidth
                onBlur={() => {
                  setSecondarySdgsError(false)
                }}
                size="small"
                value={currentJourney.sdgs
                  .filter((sdg) => !sdg.primary)
                  .map((sdg) => {
                    let idForLabel =
                      sdg.sdg.id.charAt(4) === "0"
                        ? sdg.sdg.id.slice(5) + ". "
                        : sdg.sdg.id.slice(4) + ". "

                    return {
                      label: idForLabel + sdg.sdg.name,
                      id: sdg.sdg.id,
                    }
                  })}
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={
                  sdgsList.length
                    ? sdgsList.map((sdg: Sdg) => {
                        let idForLabel =
                          sdg.id.charAt(4) === "0"
                            ? sdg.id.slice(5) + ". "
                            : sdg.id.slice(4) + ". "

                        return { label: idForLabel + sdg.name, id: sdg.id }
                      })
                    : []
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={secondarySdgsError}
                    helperText={
                      secondarySdgsError
                        ? "The maximum number of secondary SDGs is 3"
                        : null
                    }
                    label="Secondary SDGs"
                  />
                )}
                onChange={(e: any, newValues: AutocompleteOption[]) => {
                  if (newValues.length < 4) {
                    setCurrentJourney({
                      ...currentJourney!,
                      sdgs: [
                        ...currentJourney.sdgs.filter((sdg) => sdg.primary),
                        ...newValues.map((sdg) => {
                          return {
                            primary: false,
                            sdg: {
                              id: sdg.id,
                              name: sdg.label.slice(sdg.label.indexOf(".") + 2),
                            },
                          }
                        }),
                      ],
                    })
                    setSecondarySdgsError(false)
                  } else {
                    setSecondarySdgsError(true)
                  }
                }}
                renderOption={renderOption}
                renderTags={renderTags}
              />
            </Stack>
            {/* sdg targets */}
            <Stack spacing={2}>
              <Typography variant="h6" className="card-title">
                SDG targets
              </Typography>
              <Autocomplete
                disabled={!editMode}
                getOptionDisabled={(option: AutocompleteOption) => {
                  if (
                    currentJourney.sdgTargets.filter(
                      (sdgTarget) => sdgTarget.sdgTarget.id === option.id
                    )[0] &&
                    !currentJourney.sdgTargets.filter(
                      (sdgTarget) => sdgTarget.sdgTarget.id === option.id
                    )[0].primary
                  ) {
                    return true
                  } else {
                    return false
                  }
                }}
                fullWidth
                size="small"
                value={
                  currentJourney.sdgTargets.filter((item) => item.primary)[0]
                    ? {
                        label: currentJourney.sdgTargets
                          .filter((item) => item.primary)[0]
                          .sdgTarget.id.slice(4),
                        id: currentJourney.sdgTargets.filter(
                          (item) => item.primary
                        )[0].sdgTarget.id,
                      }
                    : null
                }
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={
                  sdgTargetsList.length
                    ? sdgTargetsList.map((sdgTarget: SdgTarget) => {
                        return {
                          label: sdgTarget.id.slice(4),
                          id: sdgTarget.id,
                        }
                      })
                    : []
                }
                renderInput={(params) => (
                  <TextField {...params} label="Primary SDG target" />
                )}
                onChange={(e: any, newValue: AutocompleteOption | null) => {
                  if (newValue) {
                    setCurrentJourney({
                      ...currentJourney!,
                      sdgTargets: [
                        {
                          primary: true,
                          sdgTarget: {
                            id: newValue.id,
                          },
                        },
                        ...currentJourney.sdgTargets.filter(
                          (sdgTarget) => !sdgTarget.primary
                        ),
                      ],
                    })
                  }
                }}
                renderOption={renderOption}
                renderTags={renderTags}
              />
              <Autocomplete
                disabled={!editMode}
                getOptionDisabled={(option: AutocompleteOption) => {
                  if (
                    currentJourney.sdgTargets.filter(
                      (sdgTarget) => sdgTarget.sdgTarget.id === option.id
                    )[0] &&
                    currentJourney.sdgTargets.filter(
                      (sdgTarget) => sdgTarget.sdgTarget.id === option.id
                    )[0].primary
                  ) {
                    return true
                  } else {
                    return false
                  }
                }}
                multiple
                fullWidth
                onBlur={() => {
                  setSecondarySdgsError(false)
                }}
                size="small"
                value={currentJourney.sdgTargets
                  .filter((sdgTarget) => !sdgTarget.primary)
                  .map((sdgTarget) => {
                    return {
                      label: sdgTarget.sdgTarget.id.slice(4),
                      id: sdgTarget.sdgTarget.id,
                    }
                  })}
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={
                  sdgTargetsList.length
                    ? sdgTargetsList.map((sdgTarget: SdgTarget) => {
                        return {
                          label: sdgTarget.id.slice(4),
                          id: sdgTarget.id,
                        }
                      })
                    : []
                }
                renderInput={(params) => (
                  <TextField {...params} label="Secondary SDG targets" />
                )}
                onChange={(e: any, newValues: AutocompleteOption[]) => {
                  setCurrentJourney({
                    ...currentJourney!,
                    sdgTargets: [
                      ...currentJourney.sdgTargets.filter(
                        (sdgTarget) => sdgTarget.primary
                      ),
                      ...newValues.map((sdgTarget) => {
                        return {
                          primary: false,
                          sdgTarget: {
                            id: sdgTarget.id,
                          },
                        }
                      }),
                    ],
                  })
                }}
                renderOption={renderOption}
                renderTags={renderTags}
              />
            </Stack>
            {/* thumbnail */}
            <Stack spacing={2}>
              <Typography variant="h6" className="card-title">
                Thumbnail
              </Typography>
              <ImagePicker
                filters={{
                  category: "thumbnail",
                }}
                disableCategoryFilter
                disabled={!editMode}
                title="Thumbnail"
                pickedImage={currentJourney.image ?? ""}
                setPickedImage={(selectedImage: {
                  url: string
                  id: string
                  description?: string
                }) => {
                  setCurrentJourney({
                    ...currentJourney!,
                    image: selectedImage.url.length ? selectedImage.url : null,
                  })
                }}
                height={200}
              />
            </Stack>
          </Stack>
        </CardContent>
      </div>
      {/* cannot edit type alert dialog */}
      <CantPerformActionDialog
        open={cannotEditTypeAlertOpen}
        setOpen={setCannotEditTypeAlertOpen}
        content={`You can't set the type to "Business" because this Journey is already released as free`}
      />
    </div>
  )
}

export default JourneyGeneralEdit
