import {
  DeleteRounded,
  DragHandleRounded,
  ErrorRounded,
  ExpandMoreRounded,
} from "@mui/icons-material"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import { Draggable } from "react-beautiful-dnd"
import { EpisodesContext } from "../../controllers/episodes"
import QuizSlide from "../episodeSlideType/quizSlide"

const QuizStack = ({
  slideLanguage,
  handleChange,
  expanded,
  setRemoveSlideDialogOpen,
  setSlideIdToRemove,
  translationChangesCancelled,
}: {
  slideLanguage: string
  handleChange: Function
  expanded: number | false
  setRemoveSlideDialogOpen: Dispatch<SetStateAction<boolean>>
  setSlideIdToRemove: Dispatch<SetStateAction<string>>
  translationChangesCancelled: boolean
}) => {
  const { currentEpisode, slidesErrors, slideConstraintsList, editMode } =
    useContext(EpisodesContext)

  return (
    <>
      {currentEpisode.translations
        .filter((item) => item.lang === slideLanguage)[0]
        .quiz.map((item, index) => (
          // draggable item (slide)
          <Draggable
            key={item.id.toString()}
            draggableId={item.id.toString()}
            index={index}
          >
            {(provided) => (
              <Accordion
                variant="outlined"
                TransitionProps={{
                  unmountOnExit: true,
                }}
                expanded={expanded === index}
                onChange={handleChange(index)}
                id={"accordion-" + index}
                {...provided.draggableProps}
                ref={provided.innerRef}
                sx={{
                  borderRadius: 1,
                  boxShadow:
                    "0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)",
                }}
              >
                {/* part visible when accordion is not expanded */}
                <AccordionSummary
                  expandIcon={<ExpandMoreRounded />}
                  id={item.id}
                  style={{ overflow: "hidden" }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    style={{
                      marginLeft: expanded !== false || !editMode ? -47 : 0,
                      transition: "200ms",
                    }}
                  >
                    <div
                      style={{
                        width: 30,
                        height: 26,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        opacity: expanded !== false || !editMode ? 0 : 1,
                        transition: "100ms",
                        cursor: expanded !== false ? "default" : "grab",
                      }}
                      {...provided.dragHandleProps}
                    >
                      <DragHandleRounded />
                    </div>
                    <Typography className="card-title" variant="h6">
                      Quiz{" "}
                      <span
                        className="slide-number"
                        style={{
                          transition: "150ms",
                        }}
                      >
                        {index + 1}
                      </span>
                    </Typography>
                    {/* icon to indicate that there is an error in slide */}
                    <Stack
                      direction="row"
                      alignItems="flex-end"
                      style={{
                        transition: "160ms",
                        opacity: slidesErrors
                          .filter((item) => item.lang === slideLanguage)[0]
                          .slides.filter(
                            (slide) => slide.slideId === item.id
                          )[0].error
                          ? 1
                          : 0,
                      }}
                    >
                      {slidesErrors
                        .filter((item) => item.lang === slideLanguage)[0]
                        .slides.filter((slide) => slide.slideId === item.id)[0]
                        .error ? (
                        <Tooltip title="Errors in this slide">
                          <ErrorRounded color="error" />
                        </Tooltip>
                      ) : (
                        <ErrorRounded color="error" />
                      )}
                    </Stack>
                    <IconButton
                      style={{
                        position: "absolute",
                        right: 50,
                        opacity: editMode ? 1 : 0,
                        transition: "100ms",
                      }}
                      onClick={
                        editMode
                          ? (e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setRemoveSlideDialogOpen(true)
                              setSlideIdToRemove(item.id)
                            }
                          : null
                      }
                      color="error"
                    >
                      <DeleteRounded style={{ width: 22 }} />
                    </IconButton>
                  </Stack>
                </AccordionSummary>
                {/* part visible when accordion is expanded */}
                <AccordionDetails
                  style={{
                    marginTop: -14,
                    paddingBottom: 24,
                  }}
                >
                  <QuizSlide
                    slide={item}
                    language={slideLanguage}
                    index={index}
                    constraints={
                      slideConstraintsList.filter(
                        (filterItem) => filterItem.type === "EmbedSlide"
                      )[0]
                    }
                    changesCancelled={translationChangesCancelled}
                  />
                </AccordionDetails>
              </Accordion>
            )}
          </Draggable>
        ))}
    </>
  )
}

export default QuizStack
