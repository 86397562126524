import { Esg } from "../services/config/enum"
import Category from "./category"
import Episode from "./episode"
import Sdg from "./sdg"
import SdgTarget from "./sdgTarget"
import Topic from "./topic"

class Journey {
  id: string
  lang: string
  status: "ACTIVE" | "ARCHIVED"
  stage: "PUBLISHED" | "DRAFT" | "ARCHIVED"
  title: string
  text: string
  image: string
  type: string
  points: number
  topics: { primary: boolean; topic: Topic }[]
  sdgs: { primary: boolean; sdg: Sdg }[]
  sdgTargets: { primary: boolean; sdgTarget: SdgTarget }[]
  episodes: { sorting: number; episode: Episode }[]
  publishedLangs?: { lang: string; title: string }[]
  hasSibling: boolean
  createdAt: string
  updatedAt: string
  category?: Category
  esg?: Esg

  constructor(
    id?: string,
    lang?: string,
    status?: "ACTIVE" | "ARCHIVED",
    stage?: "PUBLISHED" | "DRAFT" | "ARCHIVED",
    title?: string,
    text?: string,
    image?: string,
    type?: string,
    points?: number,
    topics?: { primary: boolean; topic: Topic }[],
    sdgs?: { primary: boolean; sdg: Sdg }[],
    sdgTargets?: { primary: boolean; sdgTarget: SdgTarget }[],
    episodes?: { sorting: number; episode: Episode }[],
    publishedLangs?: { lang: string; title: string }[],
    hasSibling?: boolean,
    createdAt?: string,
    updatedAt?: string,
    category?: Category,
    esg?: Esg
  ) {
    this.createdAt = createdAt
    this.id = id
    this.lang = lang
    this.title = title
    this.hasSibling = hasSibling
    this.stage = stage
    this.status = status
    this.topics = topics
    this.sdgs = sdgs
    this.sdgTargets = sdgTargets
    this.episodes = episodes
    this.text = text
    this.image = image
    this.type = type
    this.points = points
    this.updatedAt = updatedAt
    this.publishedLangs = publishedLangs
    this.category = category
    this.esg = esg
  }
}

export default Journey
