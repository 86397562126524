import {
  Button,
  CardContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { ActionGroupsContext } from "../../controllers/actionGroups"
import { MainContext } from "../../controllers/main"
import ActionGroup from "../../models/actionGroup"
import EmbedSlice from "../../models/bodySlices/embedSlice"
import ImageSlice from "../../models/bodySlices/imageSlice"
import QuoteSlice from "../../models/bodySlices/quoteSlice"
import TextSlice from "../../models/bodySlices/textSlice"
import { BodySliceType } from "../../services/config/enum"
import {
  deepCopy,
  isValidURL,
  stripHtml,
  testYtURL,
} from "../../services/utilities/utility"
import BlockEditor from "../blockEditor/blockEditor"

const ActionGroupTranslationsEdit = ({ itemIndex }: { itemIndex: number }) => {
  const { showPadding } = useContext(MainContext)
  const {
    currentActionGroup,
    setCurrentActionGroup,
    editMode,
    translationsErrors,
    setTranslationsErrors,
    copyDetailsFromDefault,
    copyBodyFromDefault,
  } = useContext(ActionGroupsContext)

  // current item
  const currentItem = currentActionGroup.document.items[itemIndex]

  // errors managment
  const [titleError, setTitleError] = useState<boolean>(false) // error for title
  const [bodyError, setBodyError] = useState<boolean>(false) // error for body

  // update translations errors on errors change
  useEffect(() => {
    if (titleError || bodyError) {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = true
    } else {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = false
    }

    setTranslationsErrors([...translationsErrors])
  }, [titleError, bodyError])

  // update errors on currentItem change
  useEffect(() => {
    if (currentItem.title.length < 3 || currentItem.title.length > 60) {
      setTitleError(true)
    } else {
      setTitleError(false)
    }

    let errorInBody = false
    currentItem.body.forEach((bodyItem) => {
      if (
        (bodyItem.sliceType === BodySliceType.BodyTextSlice &&
          !stripHtml(bodyItem.text).length) ||
        (bodyItem.sliceType === BodySliceType.BodyQuoteSlice &&
          !bodyItem.quoteText.length) ||
        (bodyItem.sliceType === BodySliceType.BodyImageSlice &&
          (!bodyItem.imgUrl.length || !isValidURL(bodyItem.imgUrl))) ||
        (bodyItem.sliceType === BodySliceType.BodyEmbedSlice &&
          (!bodyItem.embedUrl.length || !testYtURL(bodyItem.embedUrl)))
      ) {
        errorInBody = true
      }
    })
    if (errorInBody) {
      setBodyError(true)
    } else {
      setBodyError(false)
    }
  }, [currentItem])

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" style={{ height: 28 }}>
          <Typography variant="h6" className="card-title">
            Details
          </Typography>
          <Stack
            alignItems="center"
            justifyContent="flex-end"
            style={{ width: "100%" }}
            direction="row"
            spacing={2}
          >
            <FormGroup style={{ marginRight: -16 }}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editMode}
                    checked={currentItem.isDefault}
                    onChange={(e) => {
                      const currentActionGroupCopy: ActionGroup =
                        deepCopy(currentActionGroup)
                      currentActionGroupCopy.document.items[
                        itemIndex
                      ].isDefault = e.target.checked
                      if (e.target.checked) {
                        currentActionGroupCopy.document.items.forEach(
                          (item, index) => {
                            if (index !== itemIndex) {
                              item.isDefault = false
                            }
                          }
                        )
                      }
                      setCurrentActionGroup(currentActionGroupCopy)
                    }}
                  />
                }
                label="Default"
              />
            </FormGroup>
            <Divider orientation="vertical" flexItem />
            <Button
              size="small"
              variant="outlined"
              style={{ height: 26, fontSize: 12 }}
              onClick={() => {
                copyDetailsFromDefault(itemIndex)
              }}
              disabled={
                currentItem.isDefault ||
                !editMode ||
                !currentActionGroup.document.items.filter(
                  (item) => item.isDefault
                ).length
              }
            >
              Copy from default
            </Button>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            size="small"
            label="Title"
            value={currentItem.title}
            onChange={(e) => {
              const currentActionGroupCopy: ActionGroup =
                deepCopy(currentActionGroup)
              currentActionGroupCopy.document.items[itemIndex].title =
                e.target.value
              setCurrentActionGroup(currentActionGroupCopy)

              // errors check
              if (e.target.value.length < 3 || e.target.value.length > 60) {
                setTitleError(true)
              } else {
                setTitleError(false)
              }
            }}
            disabled={!editMode}
            error={titleError}
            helperText={
              currentItem.title.length < 3
                ? "Minimum length is 3 characters"
                : currentItem.title.length > 60
                ? "Maximum length is 60 characters"
                : ""
            }
            onBlur={() => {
              if (currentActionGroup.document.items[itemIndex].title) {
                const currentActionGroupCopy: ActionGroup =
                  deepCopy(currentActionGroup)
                currentActionGroupCopy.document.items[itemIndex].title =
                  currentActionGroupCopy.document.items[itemIndex].title.trim()
                setCurrentActionGroup(currentActionGroupCopy)
              }
            }}
          />
          <TextField
            disabled={!editMode}
            fullWidth
            size="small"
            label="Benchmark"
            value={currentItem.benchmark ?? ""}
            onChange={(e) => {
              const currentActionGroupCopy: ActionGroup =
                deepCopy(currentActionGroup)
              if (e.target.value) {
                currentActionGroupCopy.document.items[itemIndex].benchmark =
                  e.target.value
              } else {
                currentActionGroupCopy.document.items[itemIndex].benchmark =
                  null
              }
              setCurrentActionGroup(currentActionGroupCopy)
            }}
            placeholder="Un volo andata e ritorno da Milano a Parigi"
            onBlur={() => {
              if (currentActionGroup.document.items[itemIndex].benchmark) {
                const currentActionGroupCopy: ActionGroup =
                  deepCopy(currentActionGroup)
                currentActionGroupCopy.document.items[itemIndex].benchmark =
                  currentActionGroupCopy.document.items[
                    itemIndex
                  ].benchmark.trim()
                setCurrentActionGroup(currentActionGroupCopy)
              }
            }}
          />
        </Stack>
        <Stack spacing={2}>
          <Stack direction="row" style={{ height: 28 }}>
            <Typography variant="h6" className="card-title">
              Body
            </Typography>
            <Stack
              alignItems="center"
              justifyContent="flex-end"
              style={{ width: "100%" }}
              direction="row"
              spacing={2}
            >
              <Button
                size="small"
                variant="outlined"
                style={{ height: 26, fontSize: 12 }}
                onClick={() => {
                  copyBodyFromDefault(itemIndex)
                }}
                disabled={
                  currentItem.isDefault ||
                  !editMode ||
                  !currentActionGroup.document.items.filter(
                    (item) => item.isDefault
                  ).length
                }
              >
                Copy from default
              </Button>
            </Stack>
          </Stack>
          <BlockEditor
            body={currentItem.body}
            setBody={(
              body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
            ) => {
              const currentActionGroupCopy: ActionGroup =
                deepCopy(currentActionGroup)
              currentActionGroupCopy.document.items[itemIndex].body = body
              setCurrentActionGroup(currentActionGroupCopy)
            }}
            editMode={editMode}
          />
        </Stack>
      </Stack>
    </CardContent>
  )
}

export default ActionGroupTranslationsEdit
