import { MouseEvent, useState } from "react"
import { AddRounded, ArrowForwardIosRounded } from "@mui/icons-material"
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps,
  Button,
  InputAdornment,
  Popover,
  Typography,
  AccordionSummaryProps,
  ButtonBase,
  Stack,
  Tooltip,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { capitalizeFirstCharacter } from "../../services/utilities/utility"

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosRounded sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}))

const AddEnrichersAdornment = ({
  value,
  onChange,
  dataJson,
}: {
  value: string
  onChange: (newValue: string) => void
  dataJson: JSON
}) => {
  // popover
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setExpanded(-1)
  }

  const open = Boolean(anchorEl)

  // accordions
  const [expanded, setExpanded] = useState<number>(-1)

  return dataJson && Object.keys(dataJson).length ? (
    <InputAdornment position="end">
      <Tooltip title="Add variable">
        <Button
          variant="outlined"
          style={{ width: 24, minWidth: 0, height: 24, padding: 0 }}
          onClick={handleClick}
        >
          <AddRounded
            style={{
              transform: open ? "rotate(135deg)" : "rotate(0deg)",
              transition: "200ms",
            }}
          />
        </Button>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {Object.keys(dataJson).map((key, index) => (
          <Accordion
            key={key}
            expanded={expanded === index}
            onChange={() => {
              setExpanded((current) => (current === index ? -1 : index))
            }}
          >
            <AccordionSummary>
              <Typography>{capitalizeFirstCharacter(key)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack style={{ width: "100%" }}>
                {Object.keys(dataJson[key]).map((subKey) => (
                  <ButtonBase
                    key={subKey}
                    style={{
                      width: "100%",
                      height: 48,
                      justifyContent: "flex-start",
                      paddingInline: 16,
                    }}
                    onClick={() => {
                      if (value && value.charAt(value.length - 1) !== " ") {
                        onChange(`${value} {{${key}.${subKey}}}`)
                      } else {
                        onChange(`{{${key}.${subKey}}}`)
                      }
                      handleClose()
                    }}
                  >
                    {subKey === "sub"
                      ? "SUB"
                      : capitalizeFirstCharacter(subKey.replace("_", " "))}
                  </ButtonBase>
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))}
      </Popover>
    </InputAdornment>
  ) : null
}

export default AddEnrichersAdornment
