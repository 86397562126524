import { Backdrop, CircularProgress } from "@mui/material"
import { CSSProperties } from "react"

const LoadingBackdrop = ({
  open,
  style,
}: {
  open: boolean
  style?: CSSProperties
}) => {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: 999999999, ...style }} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default LoadingBackdrop
