import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material"
import { useContext, useState } from "react"
import "../../styles/imagePicker.scss"
import { BadgesContext } from "../../controllers/badges"
import Badge from "../../models/badge"
import { CloseRounded } from "@mui/icons-material"
import BadgesList from "../../views/badge/badgesList"
import { DialogTransition } from "../../services/utilities/utility"
import CopyToClipboardButton from "./copyToClipboardButton"

interface AutocompleteOption {
  label: string
  id: string
}

const BadgePicker = ({
  title,
  pickedBadge,
  setPickedBadge,
  width,
  disabled = false,
  height = 96,
  removeButton = false,
  error = false,
  categoryFilter,
}: {
  title: string
  pickedBadge: Badge | null
  setPickedBadge: (selectedBadge: Badge) => void
  width?: string
  disabled?: boolean
  height?: number
  removeButton?: boolean
  error?: boolean
  categoryFilter?: AutocompleteOption
}) => {
  const { resetFilters } = useContext(BadgesContext)

  // pick image dialog open
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  // selected image from media list
  const [selectedBadge, setSelectedBadge] = useState<Badge | null>(null)

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: width ? width : null,
      }}
    >
      <div
        className={
          disabled
            ? "image-picker-container-disabled"
            : error
            ? "image-picker-container-error-prismic"
            : "image-picker-container"
        }
        style={{
          borderRadius: 4,
          height: height,
          color: "#666666",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          padding: 15,
          width: "100%",
        }}
        onClick={() => {
          if (!disabled && !dialogOpen) {
            setDialogOpen(true)
          }
        }}
      >
        <div
          style={{
            position: "absolute",
            top: -10,
            left: 8,
            color: error
              ? "#d3302f"
              : disabled
              ? "#9e9e9f"
              : "rgba(0, 0, 0, 0.6)",
            fontSize: 12,
            backgroundColor: "white",
            paddingLeft: 5,
            paddingRight: 5,
            cursor: "default",
          }}
        >
          {title}
        </div>
        {!pickedBadge ? (
          <div
            style={{
              height: height - 18,
              width: "28%",
              border: disabled
                ? "1px dashed #c4c4c4"
                : error
                ? "1px dashed " + "#d3302f"
                : "1px dashed #c4c4c4",
              borderRadius: 5,
            }}
          />
        ) : (
          <Stack
            style={{
              height: height - 18,
              width: "42%",
            }}
            alignItems="center"
          >
            <img
              style={{
                maxHeight: "calc(100% - 36px)",
                opacity: disabled ? 0.8 : 1,
                transition: "150ms",
                objectFit: "contain",
              }}
              src={pickedBadge.image}
            />
            <Typography
              style={{
                maxWidth: 200,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: 14,
              }}
            >
              {
                pickedBadge.document.items.filter((item) => item.isDefault)[0]
                  .title
              }
            </Typography>
            <Stack direction="row">
              <Stack
                direction="row"
                alignItems="center"
                style={{ marginLeft: -3 }}
              >
                <CopyToClipboardButton textToCopy={pickedBadge.id} />
                <Typography
                  variant="caption"
                  style={{
                    fontSize: 10,
                    color: "gray",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {pickedBadge.id}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}
        <Stack style={{ position: "absolute", right: 26, bottom: 0 }}>
          {pickedBadge ? (
            removeButton ? (
              <Stack>
                <Button
                  disabled={disabled}
                  onClick={(e: any) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setPickedBadge(null)
                  }}
                >
                  Remove
                </Button>
                <Button
                  disabled={disabled}
                  onClick={() => {
                    setDialogOpen(true)
                  }}
                >
                  Change
                </Button>
              </Stack>
            ) : (
              <Button
                disabled={disabled}
                onClick={() => {
                  setDialogOpen(true)
                }}
              >
                Change
              </Button>
            )
          ) : (
            <Button
              disabled={disabled}
              onClick={() => {
                setDialogOpen(true)
              }}
            >
              Select
            </Button>
          )}
        </Stack>
        {/* select bagde dialog */}
        <Dialog
          fullScreen
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false)
            setSelectedBadge(null)
            resetFilters()
          }}
          TransitionComponent={DialogTransition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setDialogOpen(false)
                  setSelectedBadge(null)
                  resetFilters()
                }}
                aria-label="close"
              >
                <CloseRounded />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Select Badge
              </Typography>
              <Button
                autoFocus
                color="inherit"
                onClick={() => {
                  setPickedBadge(selectedBadge)

                  setDialogOpen(false)
                  setSelectedBadge(null)
                  resetFilters()
                }}
                disabled={!selectedBadge}
              >
                Select
              </Button>
            </Toolbar>
          </AppBar>
          <Paper style={{ backgroundColor: "#f5f5f5" }}>
            <BadgesList
              forDialog
              selectedBadge={selectedBadge}
              setSelectedBadge={setSelectedBadge}
              badgesToFilter={pickedBadge ? [pickedBadge] : []}
              categoryFilter={categoryFilter}
            />
          </Paper>
        </Dialog>
      </div>
      {error && (
        <p
          style={{
            color: "#d3302f",
            fontSize: "0.75rem",
            lineHeight: 1.66,
            marginLeft: 14,
            marginTop: 4,
          }}
        >
          You must add an image
        </p>
      )}
    </div>
  )
}

export default BadgePicker
