import { LoadingButton } from "@mui/lab"
import {
  Button,
  CardActions,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Dialog,
  MenuItem,
  Select,
  Autocomplete,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  DialogTransition,
  isValidURL,
  lowercaseFirstCharacter,
  renderOption,
  renderTags,
  stripHtml,
  testYtURL,
} from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import { useNavigate } from "react-router-dom"
import { MainContext } from "../../controllers/main"
import ImagePicker from "../global/imagePicker"
import { BadgeType, BodySliceType } from "../../services/config/enum"
import { LanguagesContext } from "../../controllers/languages"
import EmbedSlice from "../../models/bodySlices/embedSlice"
import ImageSlice from "../../models/bodySlices/imageSlice"
import QuoteSlice from "../../models/bodySlices/quoteSlice"
import TextSlice from "../../models/bodySlices/textSlice"
import { BadgesContext } from "../../controllers/badges"
import { TagsContext } from "../../controllers/tags"

interface AutocompleteOption {
  id: string
  label: string
}

const CreateBadgeDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
  topBarText,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
  topBarText: string
}) => {
  const { setAnimation } = useContext(MainContext)
  const { categoriesList, categoriesLoading } = useContext(TagsContext)
  const { loading, setLoading, createBadge, createBadgeDocument } =
    useContext(BadgesContext)
  const { languages, languagesForEpisodeTabs } = useContext(LanguagesContext)
  const navigate = useNavigate()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // configuration
  const [badgeType, setBadgeType] = useState<BadgeType>(BadgeType.ActionGroup)
  const [category, setCategory] = useState<AutocompleteOption | null>(null)

  // image
  const [image, setImage] = useState<string>("")

  // document
  const [defaultTranslation, setDefaultTranslation] = useState<string>("")
  const [title, setTitle] = useState<string>("")
  const [body, setBody] = useState<
    (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
  >([])

  // check errors in body
  const [bodyError, setBodyError] = useState<boolean>(false)

  useEffect(() => {
    let errorInBody = false
    body.forEach((bodyItem) => {
      if (
        (bodyItem.sliceType === BodySliceType.BodyTextSlice &&
          !stripHtml(bodyItem.text).length) ||
        (bodyItem.sliceType === BodySliceType.BodyQuoteSlice &&
          !bodyItem.quoteText.length) ||
        (bodyItem.sliceType === BodySliceType.BodyImageSlice &&
          (!bodyItem.imgUrl.length || !isValidURL(bodyItem.imgUrl))) ||
        (bodyItem.sliceType === BodySliceType.BodyEmbedSlice &&
          (!bodyItem.embedUrl.length || !testYtURL(bodyItem.embedUrl)))
      ) {
        errorInBody = true
      }
    })
    if (errorInBody) {
      setBodyError(true)
    } else {
      setBodyError(false)
    }
  }, [body])

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  // slides
  const slides = [
    {
      label: "Configuration",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Configuration
          </Typography>
          <FormControl fullWidth size="small">
            <InputLabel id="repeatable-select">Badge type</InputLabel>
            <Select
              labelId="repeatable-select"
              label="Badge type"
              value={badgeType}
              onChange={(e) => {
                setBadgeType(e.target.value as BadgeType)
              }}
            >
              {(Object.keys(BadgeType) as Array<keyof typeof BadgeType>).map(
                (key) => (
                  <MenuItem key={key} value={key}>
                    {key === "ActionGroup" ? "Series" : key}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <Autocomplete
            loading={categoriesLoading}
            fullWidth
            blurOnSelect
            size="small"
            value={category}
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={categoriesList.map((item) => {
              return {
                id: item.id,
                label: item.name,
              }
            })}
            renderInput={(params) => <TextField {...params} label="Category" />}
            onChange={(e: any, newValue: AutocompleteOption | null) => {
              setCategory(newValue)
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
        </Stack>
      ),
    },
    {
      label: "Image",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Image
          </Typography>
          <ImagePicker
            title="Image"
            pickedImage={image}
            setPickedImage={(selectedImage: {
              url: string
              id: string
              description?: string
            }) => {
              setImage(selectedImage.url)
            }}
            filters={{ category: "actionGroupBadge" }}
            disableCategoryFilter
            height={200}
          />
        </Stack>
      ),
    },
    {
      label: "Language",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Default language
          </Typography>
          <FormControl fullWidth size="small">
            <InputLabel id="language-select">Default language</InputLabel>
            <Select
              labelId="language-select"
              label="Default language"
              value={defaultTranslation}
              onChange={(e) => {
                setDefaultTranslation(e.target.value)
              }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {lang.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      ),
    },
    {
      label: "Details",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Details in{" "}
            {defaultTranslation.length
              ? languagesForEpisodeTabs.filter(
                  (item) => item.value === defaultTranslation
                )[0].label
              : ""}
          </Typography>
          <TextField
            size="small"
            label="Title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
            onBlur={() => {
              if (title) {
                setTitle((current) => current.trim())
              }
            }}
          />
        </Stack>
      ),
    },
    // {
    //   label: "Body",
    //   component: (
    //     <Stack spacing={2}>
    //       <Typography variant="h6" className="card-title">
    //         Body in{" "}
    //         {defaultTranslation.length
    //           ? languagesForEpisodeTabs.filter(
    //               (item) => item.value === defaultTranslation
    //             )[0].label
    //           : ""}
    //       </Typography>
    //       <div
    //         style={{
    //           height: 260,
    //           maxHeight: 260,
    //           overflowY: "scroll",
    //         }}
    //       >
    //         <BlockEditor
    //           body={body}
    //           setBody={(
    //             body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
    //           ) => {
    //             setBody([...body])
    //           }}
    //         />
    //       </div>
    //     </Stack>
    //   ),
    // },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setBadgeType(BadgeType.ActionGroup)
        setCategory(null)
        setImage("")
        setDefaultTranslation("")
        setTitle("")
        setBody([])
        setCurrentSlide(0)
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (image) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [image])

  // create badge
  const createNewBadge = async () => {
    setLoading(true)

    const input: {
      image: string
      badgeType: BadgeType
      category?: {
        id: string
      }
    } = {
      image: image,
      badgeType: badgeType,
    }

    if (category) {
      input.category = { id: category.id }
    }

    const result = await createBadge(input)

    if (result !== false) {
      const newBadgeId = result as string

      let bodyToSet = []
      const newBody = []
      body.forEach((bodyItem: any) => {
        const { sliceType, ...rest } = bodyItem
        newBody.push({
          [lowercaseFirstCharacter(bodyItem.sliceType)]: {
            ...rest,
          },
        })
      })
      bodyToSet = newBody

      const documentInput: {
        parentId: string
        type: string
        badgeDocumentItems: {
          lang: string
          title: string
          default: boolean
          body: object[]
        }[]
      } = {
        parentId: newBadgeId,
        type: "Badge",
        badgeDocumentItems: [
          {
            lang: defaultTranslation,
            title: title,
            default: true,
            body: bodyToSet,
          },
        ],
      }

      await createBadgeDocument(documentInput)

      setDialogOpen(false)
      setAnimation(false)
      setTimeout(() => {
        navigate(`/badges/${newBadgeId}`)
      }, 250)
    } else {
      setLoading(false)
    }
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText={topBarText}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            {topBarText}
          </Typography>
          <Stepper
            activeStep={currentSlide}
            style={{
              paddingTop: 24,
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 8,
              maxWidth: "100%",
              overflow: "overlay",
            }}
          >
            {slides.map((slide) => {
              return (
                <Step key={slide.label}>
                  <StepLabel>{slide.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (!slides[currentSlide - 1]) {
                    if (showDiscardDialog) {
                      setDiscardDialogOpen(true)
                    } else {
                      handleDialogChange(e)
                    }
                  } else {
                    setCurrentSlide(currentSlide - 1)
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  (currentSlide === 1 && !image) ||
                  (currentSlide === 2 && !defaultTranslation) ||
                  (currentSlide === 3 && !title) ||
                  (currentSlide === 4 && bodyError)
                }
                onMouseDown={
                  !slides[currentSlide + 1]
                    ? () => {
                        createNewBadge()
                      }
                    : () => {
                        setCurrentSlide(currentSlide + 1)
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Save" : "Next"}
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={loading} />
    </Dialog>
  )
}

export default CreateBadgeDialog
