import { AddCircleRounded } from "@mui/icons-material"
import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useContext, useState } from "react"
import { JourneysContext } from "../../controllers/journeys"
import { LanguagesContext } from "../../controllers/languages"
import { MainContext } from "../../controllers/main"
import Journey from "../../models/journey"
import { deepCopy } from "../../services/utilities/utility"

const JourneyLanguagesEdit = () => {
  const { showPadding } = useContext(MainContext)
  const { languages } = useContext(LanguagesContext)
  const { editMode, currentJourney, setCurrentJourney, addPublishedLang } =
    useContext(JourneysContext)

  // add episode panel
  const [addLanguagePanelOpen, setAddLanguagePanelOpen] =
    useState<boolean>(false)

  // can't add published language dialog
  const [langBeingAdded, setLangBeingAdded] = useState<string>("")
  const [addPublishedLangDialogOpen, setAddPublishedLangDialogOpen] =
    useState<boolean>(false)

  return (
    <div
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 243px)" : "calc(100vh - 227px)",
        position: "relative",
      }}
    >
      <div
        style={{
          height:
            showPadding === "yes"
              ? "calc(100vh - 243px)"
              : "calc(100vh - 227px)",
          overflow: "scroll",
        }}
      >
        <CardContent style={{ position: "relative", overflowX: "hidden" }}>
          <Stack spacing={2} style={{ marginTop: -6 }}>
            <Typography variant="h6" className="card-title">
              Titles
            </Typography>
            {currentJourney.publishedLangs.map((publishedLang) => (
              <Stack key={publishedLang.lang} direction="row" spacing={1}>
                <TextField
                  fullWidth
                  value={publishedLang.title}
                  disabled={!editMode}
                  size="small"
                  label={`Title (${publishedLang.lang.toUpperCase()})`}
                  onChange={(e: any) => {
                    let currentJourneyCopy: Journey = deepCopy(currentJourney)
                    if (currentJourney.lang === publishedLang.lang) {
                      currentJourneyCopy.title = e.target.value
                      currentJourneyCopy.publishedLangs.filter(
                        (item) => item.lang === publishedLang.lang
                      )[0].title = e.target.value
                    } else {
                      currentJourneyCopy.publishedLangs.filter(
                        (item) => item.lang === publishedLang.lang
                      )[0].title = e.target.value
                    }
                    setCurrentJourney(currentJourneyCopy)
                  }}
                  error={publishedLang.title.length < 3}
                  helperText={
                    publishedLang.title.length < 3
                      ? "Minimum length is 3 characters"
                      : ""
                  }
                  onBlur={() => {
                    if (publishedLang.title) {
                      let currentJourneyCopy: Journey = deepCopy(currentJourney)
                      if (currentJourney.lang === publishedLang.lang) {
                        currentJourneyCopy.title =
                          currentJourneyCopy.title.trim()
                        currentJourneyCopy.publishedLangs.filter(
                          (item) => item.lang === publishedLang.lang
                        )[0].title = currentJourneyCopy.title.trim()
                      } else {
                        currentJourneyCopy.publishedLangs.filter(
                          (item) => item.lang === publishedLang.lang
                        )[0].title = currentJourneyCopy.publishedLangs
                          .filter((item) => item.lang === publishedLang.lang)[0]
                          .title.trim()
                      }
                      setCurrentJourney(currentJourneyCopy)
                    }
                  }}
                />
                <Button
                  size="small"
                  variant="outlined"
                  disabled={!editMode}
                  onClick={() => {
                    let indexToRemove = currentJourney.publishedLangs.findIndex(
                      (item) => item.lang === publishedLang.lang
                    )
                    currentJourney.publishedLangs.splice(indexToRemove, 1)
                    setCurrentJourney({ ...currentJourney })
                  }}
                  style={{ height: 40, maxHeight: 40 }}
                >
                  <AddCircleRounded
                    fontSize="small"
                    style={{
                      transform: "rotate(45deg)",
                    }}
                  />
                </Button>
              </Stack>
            ))}
            <Stack direction="row" spacing={3}>
              <Button
                disabled={!editMode}
                startIcon={
                  <AddCircleRounded
                    style={{
                      transform: addLanguagePanelOpen ? "rotate(45deg)" : null,
                      transition: "180ms",
                      marginLeft: addLanguagePanelOpen ? 12 : 0,
                    }}
                  />
                }
                style={{
                  maxHeight: 40,
                  minHeight: 40,
                  minWidth: addLanguagePanelOpen ? 50 : "100%",
                  transition: "250ms",
                  marginRight: 1,
                }}
                variant="outlined"
                onClick={() => {
                  setAddLanguagePanelOpen(!addLanguagePanelOpen)
                }}
              >
                {addLanguagePanelOpen ? "" : "Add title"}
              </Button>
              <Stack direction="row" spacing={2} style={{ width: "100%" }}>
                {languages
                  .filter((lang: string) => {
                    if (
                      !currentJourney.publishedLangs.filter(
                        (item) => item.lang === lang
                      )[0]
                    ) {
                      return lang
                    }
                  })
                  .map((lang: string) => (
                    <Button
                      key={lang}
                      variant="outlined"
                      fullWidth
                      onClick={() => {
                        if (
                          currentJourney.episodes
                            .map((item) => item.episode.publishedLangs)
                            .filter((item) => {
                              for (let i = 0; i < item.length; i++) {
                                if (item[i].lang === lang) {
                                  return item
                                }
                              }
                            }).length !== currentJourney.episodes.length
                        ) {
                          setLangBeingAdded(lang)
                          setAddPublishedLangDialogOpen(true)
                          return
                        }

                        addPublishedLang(lang)
                        setAddLanguagePanelOpen(false)
                      }}
                      style={{
                        maxHeight: 40,
                        minHeight: 40,
                      }}
                    >
                      {lang.toUpperCase()}
                    </Button>
                  ))}
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </div>
      {/* can't add published language dialog */}
      <Dialog
        open={addPublishedLangDialogOpen}
        onClose={() => {
          setAddPublishedLangDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-cant-add-published-lang"
        aria-describedby="alert-dialog-description-cant-add-published-lang"
      >
        <DialogTitle id="alert-dialog-title-cant-add-published-lang">
          Title for {langBeingAdded.toUpperCase()} language cannot be added
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-cant-add-published-lang">
            There are one or more episodes without{" "}
            {langBeingAdded.toUpperCase()} translation published
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAddPublishedLangDialogOpen(false)
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default JourneyLanguagesEdit
