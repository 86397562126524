import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import { Dispatch, SetStateAction } from "react"
import { DateTime } from "luxon"
import "../../styles/isoDatePicker.scss"

const ISOTimePicker = ({
  label,
  time,
  setTime,
  error,
  disabled = false,
  helperText,
}: {
  label?: string
  time: string
  setTime: Dispatch<SetStateAction<string>> | ((newValue: string) => void)
  error?: boolean
  disabled?: boolean
  helperText?: string
}) => {
  return (
    <TimePicker
      label={label}
      value={DateTime.fromISO(time)}
      onChange={(newValue) => {
        setTime(newValue ? newValue.toUTC().toISO() : "")
      }}
      slotProps={{
        textField: {
          error: error ? true : false,
          helperText: error ? "Invalid time" : helperText,
        },
      }}
      disabled={disabled}
      className="date-picker"
    />
  )
}

export default ISOTimePicker
