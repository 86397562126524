import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import { TagsContext } from "../../../controllers/tags"
import { CardContent, Stack, TextField, Typography } from "@mui/material"

const SdgTargetGeneralEdit = ({
  errors,
}: {
  errors: { type: string; error: boolean }[]
}) => {
  const { showPadding } = useContext(MainContext)
  const { currentSdgTarget, setCurrentSdgTarget, editMode } =
    useContext(TagsContext)

  // errors
  const codeError = errors.filter((item) => item.type === "code")[0].error

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Text
        </Typography>
        <TextField
          label="Code"
          value={currentSdgTarget.code}
          onChange={(e) => {
            currentSdgTarget.code = e.target.value
            setCurrentSdgTarget({ ...currentSdgTarget })
          }}
          size="small"
          placeholder="17.4"
          onBlur={() => {
            currentSdgTarget.code = currentSdgTarget.code.trim()
            setCurrentSdgTarget({ ...currentSdgTarget })
          }}
          disabled={!editMode}
          error={codeError}
          helperText={codeError ? "This field is required" : ""}
        />
      </Stack>
    </CardContent>
  )
}

export default SdgTargetGeneralEdit
