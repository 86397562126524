import {
  Alert,
  Chip,
  LinearProgress,
  Snackbar,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ListContainer from "../../components/global/listContainer"
import TableSpinner from "../../components/global/tableSpinner"
import { MainContext } from "../../controllers/main"
import {
  calculateTimeElapsed,
  registerAnalyticsEvent,
} from "../../services/utilities/utility"
import News from "../../models/news"
import { parseStringDate } from "../../services/utilities/utility"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import { CurrentNewsContext } from "../../controllers/currentNews"
import { DragHandleRounded } from "@mui/icons-material"
import { Draggable, DropResult } from "react-beautiful-dnd"
import ListTableDragDrop from "../../components/global/listTableDragDrop"
import CurrentNewsListTopBar from "../../components/news/currentNewsListTopBar"
import LoadingBackdrop from "../../components/global/loadingBackdrop"
import TitleTableCell from "../../components/global/titleTableCell"
import TranslationsStack from "../../components/global/translationsStack"
import PointsBadge from "../../components/action/pointsBadge"

const CurrentNewsList = () => {
  const { setAnimation, showPadding } = useContext(MainContext)
  const {
    loading,
    updatingList,
    setUpdatingList,
    currentNewsList,
    setCurrentNewsList,
    getCurrentNewsList,
    doneChanges,
    cancelChanges,
    saveButtonLoading,
    sortNewsList,
  } = useContext(CurrentNewsContext)
  const navigate = useNavigate()
  const location = useLocation()

  // list background update
  const backgroundUpdate = useCallback(() => {
    if (!loading) {
      setUpdatingList(true)
      getCurrentNewsList(false)
    }
  }, [getCurrentNewsList]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!currentNewsList.length) {
      getCurrentNewsList()
    }
    backgroundUpdate()
  }, [backgroundUpdate])

  // handle news deleted feedback
  const [newsDeletedFeedbackOpen, setNewsDeletedFeedbackOpen] =
    useState<boolean>(false)

  useEffect(() => {
    if (location.state && (location.state as any).newsDeleted) {
      setNewsDeletedFeedbackOpen(true)
    }
  }, [])

  // drag and drop
  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result

    if (destination && source) {
      const itemToMove = currentNewsList[source.index]

      currentNewsList.splice(source.index, 1)
      currentNewsList.splice(destination.index, 0, itemToMove)
      setCurrentNewsList([...currentNewsList])
    }
  }

  // register analytics event
  useEffect(() => {
    registerAnalyticsEvent({ type: "page_view", name: "current_news_list" })
  }, [])

  return (
    <ListContainer style={{ position: "relative" }}>
      {updatingList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 85,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <CurrentNewsListTopBar
        loading={loading}
        textFieldValue=""
        textFieldPlaceholder="Search News"
        textFieldDisabled
        textFieldOnChange={() => {}}
        textFieldOnKeyDown={() => {}}
        cancelButtonOnClick={() => {}}
        searchButtonOnClick={() => {}}
        doneChanges={doneChanges}
        undoButtonOnClick={cancelChanges}
        saveButtonLoading={saveButtonLoading}
        saveButtonOnClick={sortNewsList}
      />
      {loading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 150px)"
              : "calc(100vh - 118px)"
          }
        />
      ) : (
        <ListTableDragDrop
          height={
            showPadding === "yes"
              ? "calc(100vh - 150px)"
              : "calc(100vh - 118px)"
          }
          headingItems={[
            "",
            "Image",
            "Title & ID",
            "Translations",
            "Starts at",
            "Ends at",
            "Points",
            "Updated",
          ]}
          onDragEnd={onDragEnd}
          tableBody={currentNewsList.map((item: News, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided) => (
                <TableRow
                  key={item.id}
                  style={{
                    cursor: "pointer",
                  }}
                  hover
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  onClick={() => {
                    setAnimation(false)
                    setTimeout(() => {
                      navigate(`/currentnews/${item.id}`)
                    }, 250)
                  }}
                  {...provided.draggableProps}
                  ref={provided.innerRef}
                >
                  <TableCell {...provided.dragHandleProps}>
                    <DragHandleRounded style={{ marginTop: 7 }} />
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        width: 42,
                        height: 42,
                        borderRadius: 4,
                        backgroundImage: `url(${
                          item.document.items.filter(
                            (documentItem) => documentItem.isDefault
                          )[0].image ?? noImagePlaceholder
                        })`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    />
                  </TableCell>
                  <TitleTableCell
                    title={
                      item.document.items.filter(
                        (documentItem) => documentItem.isDefault
                      )[0].title
                    }
                    id={item.id}
                  />
                  <TableCell>
                    <TranslationsStack documentItems={item.document.items} />
                  </TableCell>
                  <TableCell style={{ minWidth: 110 }}>
                    <Typography variant="caption">
                      {parseStringDate(item.startsAt, false)}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ minWidth: 110 }}>
                    <Typography variant="caption">
                      {parseStringDate(item.endsAt, false)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {item.points !== null ? (
                      <PointsBadge points={item.points as number} />
                    ) : (
                      <Chip label="none" color="default" size="small" />
                    )}
                  </TableCell>
                  <TableCell style={{ minWidth: 110 }}>
                    <Typography variant="caption">
                      {calculateTimeElapsed(item.updatedAt)}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </Draggable>
          ))}
        />
      )}
      <LoadingBackdrop open={saveButtonLoading} />
      <Snackbar
        open={newsDeletedFeedbackOpen}
        onClose={() => {
          setNewsDeletedFeedbackOpen(false)
        }}
        autoHideDuration={3000}
      >
        <Alert severity="success">News deleted</Alert>
      </Snackbar>
    </ListContainer>
  )
}

export default CurrentNewsList
