import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grow,
  IconButton,
  List,
  ListItem,
  Stack,
  Tab,
  Tabs,
} from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import EditContainer from "../../components/global/editContainer"
import EditHeading from "../../components/global/editHeading"
import PageSpinner from "../../components/global/pageSpinner"
import { MainContext } from "../../controllers/main"
import "../../styles/channelEdit.scss"
import { deepCopy } from "../../services/utilities/utility"
import { LanguagesContext } from "../../controllers/languages"
import { AddCircleRounded } from "@mui/icons-material"
import LoadingBackdrop from "../../components/global/loadingBackdrop"
import { TagsContext } from "../../controllers/tags"
import MainCategoryGeneralEdit from "../../components/tags/mainCategories/mainCategoryGeneralEdit"
import MainCategoryTranslationsEdit from "../../components/tags/mainCategories/mainCategoryTranslationsEdit"

const MainCategoryEdit = () => {
  const { animation, setAnimation, setChangesSaved, showPadding } =
    useContext(MainContext)
  const { languages, languagesForEpisodeTabs } = useContext(LanguagesContext)
  const {
    loading: controllerLoading,
    setLoading: setControllerLoading,
    editMode,
    setEditMode,
    doneChangesMainCategory,
    cancelChangesMainCategory,
    currentMainCategory,
    preChangesCurrentMainCategory,
    getCurrentMainCategory,
    addTranslationToMainCategory,
    upsertMainCategory,
  } = useContext(TagsContext)
  const { mainCategoryId } = useParams()
  const navigate = useNavigate()

  // prevent tab close
  useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault()
      console.log("beforeunload event triggered")
      return (event.returnValue = "Are you sure you want to exit?")
    }

    window.addEventListener("beforeunload", handleTabClose)

    return () => {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [])

  // loading
  const [loading, setLoading] = useState<boolean>(true)

  // page error
  const [pageError, setPageError] = useState<boolean>(false)

  // fetch current journey
  const fetchAll = async () => {
    const noErrors = await getCurrentMainCategory(mainCategoryId)
    if (noErrors) {
      setLoading(false)
      setControllerLoading(false)
    } else {
      setPageError(true)
    }
  }

  useEffect(() => {
    fetchAll()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // tabs
  const [selectedTab, setSelectedTab] = useState<number>(0)

  // add translation dialog
  const [addTranslationOpen, setAddTranslationOpen] = useState<boolean>(false)
  const [translationToAdd, setTranslationToAdd] = useState<
    { value: string; label: string; flag?: string } | undefined
  >()

  const handleAddTranslationClose = () => {
    setAddTranslationOpen(false)
    setTimeout(() => {
      setTranslationToAdd(undefined)
    }, 150)
  }

  // errors management
  const [errors, setErrors] = useState<{ type: string; error: boolean }[]>([
    { type: "name", error: false },
    { type: "code", error: false },
    ...languages.map((item) => {
      return {
        type: "name" + item,
        error: false,
      }
    }),
  ])
  const hasErrors =
    errors.filter((item) => item.error).length > 0 ? true : false

  // check for errors
  useEffect(() => {
    if (currentMainCategory) {
      if (currentMainCategory.name.trim().length === 0) {
        errors.filter((item) => item.type === "name")[0].error = true
      } else {
        errors.filter((item) => item.type === "name")[0].error = false
      }
      if (currentMainCategory.code.trim().length === 0) {
        errors.filter((item) => item.type === "code")[0].error = true
      } else {
        errors.filter((item) => item.type === "code")[0].error = false
      }
      for (let i = 0; i < currentMainCategory.translations.length; i++) {
        if (currentMainCategory.translations[i].name.trim().length === 0) {
          errors.filter(
            (item) =>
              item.type === "name" + currentMainCategory.translations[i].lang
          )[0].error = true
        } else {
          errors.filter(
            (item) =>
              item.type === "name" + currentMainCategory.translations[i].lang
          )[0].error = false
        }
      }
      setErrors([...errors])
    }
  }, [currentMainCategory])

  return loading ? (
    <PageSpinner
      showBackButton={pageError}
      backButtonOnClick={() => {
        setAnimation(false)
        navigate("/tags")
      }}
    />
  ) : (
    <EditContainer>
      <EditHeading
        saveButtonLoading={controllerLoading}
        backButtonOnClick={() => {
          setAnimation(false)
          setTimeout(() => {
            navigate("/tags")
          }, 300)
        }}
        title={preChangesCurrentMainCategory.name}
        id={preChangesCurrentMainCategory.id}
        showButtons
        showEditButton
        editModeActive={editMode}
        setEditModeActive={setEditMode}
        saveButtonDisabled={!doneChangesMainCategory || hasErrors}
        undoButtonDisabled={!doneChangesMainCategory}
        doneChanges={doneChangesMainCategory}
        undoButtonOnClick={() => {
          if (selectedTab > preChangesCurrentMainCategory.translations.length) {
            setSelectedTab(preChangesCurrentMainCategory.translations.length)
          }
          cancelChangesMainCategory()
        }}
        saveButtonOnClick={async () => {
          setControllerLoading(true)

          const input: {
            id?: string
            name: string
            code: string
            backColor: string
            backTagColor: string
            foreColor: string
            translations: {
              name: string
              lang: string
              description: string
              cta: string
            }[]
          } = {
            id: currentMainCategory.id,
            name: currentMainCategory.name,
            code: currentMainCategory.code,
            backColor: currentMainCategory.backColor,
            backTagColor: currentMainCategory.backTagColor,
            foreColor: currentMainCategory.foreColor,
            translations: currentMainCategory.translations.map((item) => {
              return {
                name: item.name,
                lang: item.lang,
                description: item.description,
                cta: item.cta,
              }
            }),
          }
          const noErrors = await upsertMainCategory(input)

          if (noErrors) {
            setControllerLoading(false)
            setChangesSaved(true)
          } else {
            setControllerLoading(false)
          }
        }}
      />
      <Grow
        in={animation}
        timeout={300}
        style={{
          margin: showPadding === "yes" ? 16 : 0,
          marginTop: 0,
        }}
      >
        <Stack>
          {/* languages tabs */}
          <Stack direction="row" justifyContent="flex-start">
            <Card
              variant="outlined"
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: showPadding === "yes" ? null : 0,
                borderBottom: "none",
                borderLeft: showPadding === "yes" ? null : "none",
              }}
            >
              <Stack direction="row" justifyContent="flex-start">
                <Tabs
                  value={selectedTab}
                  onChange={(e: any, newValue: number) => {
                    setSelectedTab(newValue)
                  }}
                  aria-label="languages tabs"
                >
                  <Tab label="General" style={{ minWidth: 180 }} />
                  {currentMainCategory.translations.map((item) => (
                    <Tab
                      key={item.lang}
                      style={{ minWidth: 180 }}
                      label={
                        <Stack direction="row" spacing={1} alignItems="center">
                          <span>
                            {
                              languagesForEpisodeTabs.filter(
                                (arrayItem) => arrayItem.value === item.lang
                              )[0].flag
                            }
                          </span>
                          <span>
                            {
                              languagesForEpisodeTabs.filter(
                                (arrayItem) => arrayItem.value === item.lang
                              )[0].label
                            }
                          </span>
                        </Stack>
                      }
                    />
                  ))}
                </Tabs>
                <Divider orientation="vertical" variant="middle" flexItem />
                <div
                  style={{
                    minWidth: 70,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    disabled={!editMode}
                    aria-label="add language button"
                    component="span"
                    style={{ width: 48, height: 48 }}
                    onClick={() => {
                      setAddTranslationOpen(true)
                    }}
                  >
                    <AddCircleRounded />
                  </IconButton>
                </div>
              </Stack>
            </Card>
          </Stack>
          <Card style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
            {!selectedTab ? (
              <MainCategoryGeneralEdit errors={errors} />
            ) : (
              <MainCategoryTranslationsEdit
                itemIndex={selectedTab - 1}
                errors={errors}
              />
            )}
          </Card>
        </Stack>
      </Grow>
      <LoadingBackdrop open={controllerLoading} />
      {/* add translation dialog */}
      <Dialog onClose={handleAddTranslationClose} open={addTranslationOpen}>
        <DialogTitle>Add translation</DialogTitle>
        {translationToAdd ? (
          <DialogContent>
            <DialogContentText id="alert-dialog-description-add-translation">
              Are you sure you want to add{" "}
              <b>{translationToAdd.label.toUpperCase()}</b>?
            </DialogContentText>
          </DialogContent>
        ) : languagesForEpisodeTabs.filter((item) => {
            if (
              item.value !== "general" &&
              !currentMainCategory.translations.filter(
                (translationItem) => translationItem.lang === item.value
              )[0]
            ) {
              return item
            }
          }).length ? (
          <List sx={{ pt: 0, minWidth: 300 }}>
            {languagesForEpisodeTabs
              .filter((item) => {
                if (
                  item.value !== "general" &&
                  !currentMainCategory.translations.filter(
                    (translationItem) => translationItem.lang === item.value
                  )[0]
                ) {
                  return item
                }
              })
              .map((item) => (
                <ListItem
                  button
                  onClick={() => {
                    setTranslationToAdd(item)
                  }}
                  key={item.value}
                >
                  <Stack direction="row" spacing={1}>
                    <span>{item.flag}</span>
                    <span>{item.label.toUpperCase()}</span>
                  </Stack>
                </ListItem>
              ))}
          </List>
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description-add-translation">
              You have already added all languages available
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleAddTranslationClose}>Cancel</Button>
          {translationToAdd && (
            <Button
              onClick={() => {
                addTranslationToMainCategory(translationToAdd.value)
                handleAddTranslationClose()
                setSelectedTab(currentMainCategory.translations.length)
              }}
            >
              Add {translationToAdd.label}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </EditContainer>
  )
}

export default MainCategoryEdit
