import { useNavigate, useParams } from "react-router-dom"
import EditContainer from "../../components/global/editContainer"
import EditHeading from "../../components/global/editHeading"
import { useContext, useEffect, useState } from "react"
import { MainContext } from "../../controllers/main"
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grow,
  IconButton,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material"
import { TeamsContext } from "../../controllers/teams"
import PageSpinner from "../../components/global/pageSpinner"
import ListTable from "../../components/global/listTable"
import CopyToClipboardButton from "../../components/global/copyToClipboardButton"
import { DeleteForeverRounded } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { ActivationCodesContext } from "../../controllers/activationCodes"

const TeamActivationCodesList = () => {
  const { animation, setAnimation, showPadding } = useContext(MainContext)
  const {
    setLoading: setControllerLoading,
    currentTeam,
    setCurrentTeam,
    getCurrentTeam,
  } = useContext(TeamsContext)
  const { deleteActivationCode } = useContext(ActivationCodesContext)
  const { teamId } = useParams()
  const navigate = useNavigate()

  // loading
  const [loading, setLoading] = useState<boolean>(true)

  // page error
  const [pageError, setPageError] = useState<boolean>(false)

  // fetch current team
  const fetchAll = async () => {
    if (!currentTeam || (currentTeam && currentTeam.id !== teamId)) {
      const noErrors = await getCurrentTeam(teamId)
      if (noErrors) {
        setLoading(false)
        setControllerLoading(false)
      } else {
        setPageError(true)
      }
    } else {
      setLoading(false)
      setControllerLoading(false)
    }
  }

  useEffect(() => {
    fetchAll()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // delete activation code
  const [codeToDelete, setCodeToDelete] = useState<string | null>(null)
  const [deletingCode, setDeletingCode] = useState<boolean>(false)
  const [deleteCodeDialogOpen, setDeleteCodeDialogOpen] =
    useState<boolean>(false)

  const handleDeleteCodeDialogChange = () => {
    setDeleteCodeDialogOpen((current) => !current)
  }

  return loading ? (
    <PageSpinner
      showBackButton={pageError}
      backButtonOnClick={() => {
        setAnimation(false)
        const path = window.location.pathname
        navigate(path.slice(0, path.indexOf("/activationcodes")))
      }}
    />
  ) : (
    <EditContainer>
      <EditHeading
        backButtonOnClick={() => {
          setAnimation(false)
          setTimeout(() => {
            const path = window.location.pathname
            navigate(path.slice(0, path.indexOf("/activationcodes")))
          }, 300)
        }}
        title={currentTeam.name + " - Activation codes"}
      />
      <Grow
        in={animation}
        timeout={300}
        style={{
          margin: showPadding === "yes" ? 16 : 0,
          marginTop: 0,
        }}
      >
        <Card style={{ position: "relative" }}>
          <ListTable
            height={
              showPadding === "yes"
                ? "calc(100vh - 148px)"
                : "calc(100vh - 115px)"
            }
            headingItems={["Code", ""]}
            tableBody={currentTeam.accessCodes
              .filter((item) => item.groupId === "group_default")
              .map((item) => (
                <TableRow key={item.code}>
                  <TableCell style={{ width: "100%" }}>{item.code}</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={2}>
                      <CopyToClipboardButton
                        size="bigger"
                        textToCopy={item.code}
                      />
                      <IconButton
                        sx={{
                          width: 40,
                          height: 40,
                        }}
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation()
                          setCodeToDelete(item.code)
                          handleDeleteCodeDialogChange()
                        }}
                      >
                        <DeleteForeverRounded style={{ width: 24 }} />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
          />
        </Card>
      </Grow>
      {/* delete activation code dialog */}
      <Dialog
        open={deleteCodeDialogOpen}
        onClose={deletingCode ? null : handleDeleteCodeDialogChange}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to permanently delete this activation code?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The activation code{" "}
            <span style={{ fontWeight: 600 }}>{codeToDelete}</span> will be
            permanently deleted and it will no longer be able to be used to
            access the current Team
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteCodeDialogChange}
            disabled={deletingCode}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={deletingCode}
            onClick={async () => {
              setDeletingCode(true)

              const noErrors = await deleteActivationCode(codeToDelete)
              if (noErrors) {
                const indexToRemove = currentTeam.accessCodes.findIndex(
                  (item) => item.code === codeToDelete
                )
                currentTeam.accessCodes.splice(indexToRemove, 1)
                setCurrentTeam({ ...currentTeam })

                setCodeToDelete(null)
                handleDeleteCodeDialogChange()
              }

              setDeletingCode(false)
            }}
            autoFocus
            color="error"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </EditContainer>
  )
}

export default TeamActivationCodesList
