import { Cancel, Search } from "@mui/icons-material"
import {
  Button,
  Divider,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material"
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import ListFilters from "../global/listFilters"
import { TeamsContext } from "../../controllers/teams"
import {
  capitalizeFirstCharacter,
  enumAsArray,
  splitCamelCase,
} from "../../services/utilities/utility"
import {
  TeamAccessType,
  TeamOrganizationType,
  TeamStage,
  TeamStakeholderType,
} from "../../services/config/enum"
import { LoadingButton } from "@mui/lab"
import { CSVLink } from "react-csv"

const TeamsListTopBar = ({
  loading,
  textFieldValue,
  textFieldPlaceholder,
  addButtonLabel,
  addButtonOnClick,
  textFieldOnChange,
  textFieldOnKeyDown,
  cancelButtonOnClick,
  searchButtonOnClick,
  forDialog = false,
  addButtonDisabled = false,
  filtersDisabled = false,
}: {
  loading: boolean
  textFieldValue: string
  textFieldPlaceholder: string
  addButtonLabel: string
  textFieldOnChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  textFieldOnKeyDown: KeyboardEventHandler<HTMLDivElement>
  cancelButtonOnClick: MouseEventHandler<HTMLDivElement>
  searchButtonOnClick: MouseEventHandler<HTMLButtonElement>
  addButtonOnClick: MouseEventHandler<HTMLButtonElement>
  forDialog?: boolean
  addButtonDisabled?: boolean
  filtersDisabled?: boolean
}) => {
  const {
    searchLang,
    setSearchLang,
    searchStakeholderType,
    setSearchStakeholderType,
    searchAccessType,
    setSearchAccessType,
    searchIsPrimary,
    setSearchIsPrimary,
    searchOrganizationType,
    setSearchOrganizationType,
    searchStage,
    setSearchStage,
    updatingList,
    setUpdatingList,
    getTeamsList,
    searchTeamsList,
    getAllTeams,
  } = useContext(TeamsContext)

  // search when filters update
  const [firstRender, setFirstRender] = useState<boolean>(true)

  useEffect(() => {
    if (!firstRender) {
      if (
        !searchLang &&
        !searchStakeholderType &&
        !searchAccessType &&
        !searchIsPrimary &&
        !searchOrganizationType &&
        !searchStage &&
        !textFieldValue.length
      ) {
        setUpdatingList(true)
        getTeamsList(false)
      } else {
        searchTeamsList()
      }
    } else {
      setFirstRender(false)
    }
  }, [
    searchLang,
    searchStakeholderType,
    searchAccessType,
    searchIsPrimary,
    searchOrganizationType,
    searchStage,
  ])

  // csv download button
  const csvButtonRef = useRef(null)
  const [csvData, setCsvData] = useState<string[][]>([])
  const [csvLoading, setCsvLoading] = useState<boolean>(false)

  useEffect(() => {
    if (csvData.length && !csvLoading) {
      csvButtonRef.current.link.click()
    }
  }, [csvData])

  // export button on click
  const exportOnClick = async () => {
    setCsvLoading(true)
    setCsvData([])

    const data = await getAllTeams()

    setCsvData(data)
    setCsvLoading(false)
  }

  return (
    <Stack
      style={{
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 4,
      }}
      spacing={1}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          disabled={loading || updatingList}
          size="small"
          fullWidth
          id="outlined-basic"
          placeholder={textFieldPlaceholder}
          variant="outlined"
          value={textFieldValue}
          onChange={textFieldOnChange}
          onKeyDown={textFieldOnKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: textFieldValue.length > 0 && (
              <InputAdornment position="start">
                <div
                  style={{
                    marginTop: 6,
                    marginRight: -6,
                    cursor: "pointer",
                  }}
                  onClick={loading ? () => {} : cancelButtonOnClick}
                >
                  <Cancel style={{ width: 18 }} />
                </div>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="outlined"
          disabled={textFieldValue.length < 3 || loading || updatingList}
          onClick={searchButtonOnClick}
          style={{ height: 40, minWidth: 74 }}
        >
          Search
        </Button>
        {!forDialog && <Divider orientation="vertical" flexItem />}
        {!forDialog && (
          <div>
            <LoadingButton
              variant="contained"
              disabled={loading || addButtonDisabled}
              loading={csvLoading}
              aria-haspopup="true"
              style={{
                whiteSpace: "nowrap",
                minWidth: "max-content",
                height: 40,
              }}
              onClick={exportOnClick}
            >
              Export
            </LoadingButton>
            <CSVLink
              data={csvData}
              ref={csvButtonRef}
              filename={`teams-list-${new Date()
                .toISOString()
                .slice(0, 10)}.csv`}
              style={{
                textDecoration: "none",
              }}
            />
          </div>
        )}
        {!forDialog && (
          <Button
            variant="contained"
            disabled={loading || addButtonDisabled}
            style={{
              whiteSpace: "nowrap",
              minWidth: "max-content",
              height: 40,
            }}
            onClick={addButtonOnClick}
          >
            {addButtonLabel}
          </Button>
        )}
      </Stack>
      <ListFilters
        disabled={loading || updatingList || filtersDisabled}
        filters={[
          {
            type: "boolean",
            value: searchIsPrimary,
            setValue: setSearchIsPrimary,
            label: "Primary",
          },
          { type: "language", value: searchLang, setValue: setSearchLang },
          {
            type: "singular",
            value: searchStage,
            setValue: setSearchStage,
            label: "Stage",
            options: enumAsArray(TeamStage).map((item) => {
              return {
                id: item as string,
                label: capitalizeFirstCharacter(splitCamelCase(item as string)),
              }
            }),
          },
          {
            type: "singular",
            value: searchAccessType,
            setValue: setSearchAccessType,
            label: "Access",
            options: enumAsArray(TeamAccessType).map((item) => {
              return {
                id: item as string,
                label:
                  item === "sso"
                    ? "SSO"
                    : item === "activationCode"
                    ? "Code"
                    : capitalizeFirstCharacter(item as string),
              }
            }),
          },
          {
            type: "singular",
            value: searchStakeholderType,
            setValue: setSearchStakeholderType,
            label: "Stakeholder",
            options: enumAsArray(TeamStakeholderType).map((item) => {
              return {
                id: item as string,
                label: capitalizeFirstCharacter(item as string),
              }
            }),
          },
          {
            type: "singular",
            value: searchOrganizationType,
            setValue: setSearchOrganizationType,
            label: "Organization",
            options: enumAsArray(TeamOrganizationType).map((item) => {
              return {
                id: item as string,
                label: capitalizeFirstCharacter(item as string),
              }
            }),
          },
        ]}
      />
    </Stack>
  )
}

export default TeamsListTopBar
