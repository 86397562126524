import { AddCircleRounded, DeleteRounded } from "@mui/icons-material"
import {
  Autocomplete,
  Button,
  CardContent,
  Chip,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  // FormControl,
  // InputLabel,
  // MenuItem,
  // Select,
} from "@mui/material"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { ActionsContext } from "../../controllers/actions"
import { TagsContext } from "../../controllers/tags"
import { CountriesContext } from "../../controllers/countries"
import { MainContext } from "../../controllers/main"
import Action from "../../models/action"
import Sdg from "../../models/sdg"
import SdgTarget from "../../models/sdgTarget"
import {
  copyTextToClipboard,
  deepCopy,
  renderOption,
  renderTags,
} from "../../services/utilities/utility"
import ImagePicker from "../global/imagePicker"
import DataCard from "../global/dataCard"
import {
  AutocompleteOption,
  LocationAutocompleteOption,
} from "../../services/config/interfaces"

const ActionGeneralEdit = ({
  setInfoDialogOpen,
  setGlobalPreviewOpen,
  setFeedbackOpen,
}: {
  setInfoDialogOpen: Dispatch<SetStateAction<boolean>>
  setGlobalPreviewOpen: Dispatch<SetStateAction<boolean>>
  setFeedbackOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { showPadding, showAdvancedOption } = useContext(MainContext)
  const {
    currentAction,
    setCurrentAction,
    editMode,
    handleError,
    setHandleError,
    timesPerDayError,
    setTimesPerDayError,
    savingMetricsError,
    setSavingMetricsError,
    categoryError,
    setCategoryError,
  } = useContext(ActionsContext)
  const { categoriesLoading, categoriesList, sdgsList, sdgTargetsList } =
    useContext(TagsContext)
  const {
    regionsList,
    subRegionsList,
    countriesList,
    loading: countriesControllerLoading,
  } = useContext(CountriesContext)

  // tags errors
  const [secondarySdgsError, setSecondarySdgsError] = useState<boolean>(false) // error for action secondary sdgs
  const [secondarySdgTargetsError, setSecondarySdgTargetsError] =
    useState<boolean>(false) // error for action secondary sdg targets

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Data
        </Typography>
        <Stack direction="row" gap={1}>
          <DataCard
            title="Info"
            htmlContent={
              <Stack direction="row" spacing={1}>
                <div style={{ fontSize: "0.9rem", fontWeight: 300 }}>
                  Type ▸
                </div>
                {currentAction.teamId === "team_default" ? (
                  <Chip
                    size="small"
                    label="Standard"
                    style={{ fontWeight: 600 }}
                  />
                ) : (
                  <Chip
                    size="small"
                    label="Autolog"
                    style={{
                      backgroundColor: "#F1F7FE",
                      color: "#479EF6",
                      fontWeight: 600,
                    }}
                  />
                )}
              </Stack>
            }
          />
          <DataCard
            title="Appears in"
            description="Series in which this Action appears."
            buttonLabel="View"
            buttonOnClick={() => {
              setInfoDialogOpen(true)
            }}
          />
          <DataCard
            title="Preview"
            description="Preview of how this Action appears inside the app."
            buttonLabel="Open"
            buttonDisabled={!currentAction.category}
            buttonOnClick={() => {
              setGlobalPreviewOpen(true)
            }}
          />
          {showAdvancedOption === "yes" ? (
            <DataCard
              title="Copy JSON"
              description="Copy JSON string of the current Action."
              buttonLabel="Copy"
              buttonOnClick={() => {
                copyTextToClipboard(JSON.stringify(currentAction))
                setFeedbackOpen(true)
              }}
            />
          ) : null}
        </Stack>
        <Typography variant="h6" className="card-title">
          Configuration
        </Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            size="small"
            label="Handle"
            disabled={!editMode}
            value={currentAction.handle}
            onChange={(e) => {
              const currentActionCopy: Action = deepCopy(currentAction)
              currentActionCopy.handle = e.target.value
              setCurrentAction(currentActionCopy)

              // check for errors
              if (!e.target.value.length) {
                setHandleError(true)
              } else {
                setHandleError(false)
              }
            }}
            error={handleError}
            helperText={handleError ? "This field is required" : ""}
            onBlur={() => {
              if (currentAction.handle) {
                currentAction.handle = currentAction.handle.trim()
                setCurrentAction({ ...currentAction })
              }
            }}
          />
          {/* <FormControl fullWidth size="small" disabled={!editMode}>
            <InputLabel id="repeatable-select">Repeatable</InputLabel>
            <Select
              labelId="repeatable-select"
              label="Repeatable"
              value={currentAction.isRepeatable ? "yes" : "no"}
              onChange={(e) => {
                const currentActionCopy: Action = deepCopy(currentAction)
                if (e.target.value === "yes") {
                  currentActionCopy.isRepeatable = true
                } else {
                  currentActionCopy.isRepeatable = false
                }
                setCurrentAction(currentActionCopy)
              }}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl> */}
          <TextField
            fullWidth
            size="small"
            type="number"
            label="Times per day"
            disabled={!editMode}
            value={currentAction.timesPerDay ?? ""}
            onChange={(e) => {
              const currentActionCopy: Action = deepCopy(currentAction)
              if (e.target.value.length) {
                if (
                  e.target.value.includes(".") ||
                  e.target.value.includes(",")
                ) {
                  currentActionCopy.timesPerDay = parseFloat(e.target.value)
                } else {
                  currentActionCopy.timesPerDay = parseInt(e.target.value)
                }
              } else {
                currentActionCopy.timesPerDay = null
              }
              setCurrentAction(currentActionCopy)

              // check for errors
              if (
                currentActionCopy.timesPerDay === null ||
                (currentActionCopy.timesPerDay !== null &&
                  currentActionCopy.timesPerDay < 1) ||
                (currentActionCopy.timesPerDay !== null &&
                  currentActionCopy.timesPerDay.toString().includes("."))
              ) {
                setTimesPerDayError(true)
              } else {
                setTimesPerDayError(false)
              }
            }}
            error={timesPerDayError}
            helperText={timesPerDayError ? "Invalid value" : ""}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Autocomplete
            disabled={!editMode || currentAction.actionGroups.length > 0}
            loading={categoriesLoading}
            fullWidth
            blurOnSelect
            size="small"
            value={
              currentAction.category
                ? {
                    id: currentAction.category.id,
                    label: currentAction.category.name,
                  }
                : null
            }
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={categoriesList.map((item) => {
              return {
                id: item.id,
                label: item.name,
              }
            })}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Category"
                error={categoryError}
                helperText={
                  categoryError
                    ? "This field is required"
                    : currentAction.actionGroups.length
                    ? "Remove this Action from every Series to edit"
                    : ""
                }
                FormHelperTextProps={
                  categoryError
                    ? null
                    : {
                        style: {
                          margin: 0,
                          marginBottom: -25,
                        },
                      }
                }
              />
            )}
            onChange={(e: any, newValue: AutocompleteOption | null) => {
              if (newValue) {
                currentAction.category = categoriesList.filter(
                  (item) => item.id === newValue.id
                )[0]
                setCategoryError(false)
              } else {
                currentAction.category = null
                setCategoryError(true)
              }
              setCurrentAction({ ...currentAction })
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
          <FormControl fullWidth size="small" disabled={!editMode}>
            <InputLabel id="Featured">Featured</InputLabel>
            <Select
              labelId="Featured"
              label="Featured"
              value={currentAction.featured ? "yes" : "no"}
              onChange={(e) => {
                if (e.target.value === "yes") {
                  currentAction.featured = true
                } else {
                  currentAction.featured = false
                }
                setCurrentAction({ ...currentAction })
              }}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Typography variant="h6" className="card-title">
          Savings
        </Typography>
        {currentAction.savingMetrics.map((item, index) => (
          <Stack direction="row" key={index} spacing={1.5}>
            <Divider orientation="vertical" flexItem />
            <Stack spacing={2} style={{ width: "calc(100% - 13px)" }}>
              <div
                style={{
                  height: 16,
                  position: "relative",
                  marginBottom: 3,
                }}
              >
                <Typography
                  variant="overline"
                  style={{
                    height: 16,
                    lineHeight: "16px",
                  }}
                >
                  {item.location.name === "defaultLocation"
                    ? "Default"
                    : item.location.name}
                </Typography>
                {index !== 0 ? (
                  <Button
                    style={{
                      height: 24,
                      position: "absolute",
                      right: 0,
                      top: 1,
                    }}
                    onClick={() => {
                      currentAction.savingMetrics.splice(index, 1)
                      savingMetricsError.splice(index, 1)
                      setCurrentAction({ ...currentAction })
                      setSavingMetricsError([...savingMetricsError])
                    }}
                    disabled={!editMode}
                    color="error"
                  >
                    <DeleteRounded
                      style={{
                        fontSize: 18,
                      }}
                    />
                  </Button>
                ) : null}
              </div>
              <Stack direction="row" spacing={2}>
                <Autocomplete
                  loading={countriesControllerLoading}
                  disabled={!index || !editMode}
                  fullWidth
                  blurOnSelect
                  size="small"
                  value={item.location}
                  disablePortal
                  isOptionEqualToValue={(
                    option: LocationAutocompleteOption,
                    value: LocationAutocompleteOption
                  ) => option.id === value.id}
                  groupBy={(option: LocationAutocompleteOption) => {
                    if (option.__typename !== "DefaultLocation") {
                      return option.__typename
                    }
                  }}
                  options={[
                    {
                      id: "defaultLocation",
                      name: "Default",
                      __typename: "DefaultLocation",
                    },
                    ...regionsList.map((item) => {
                      return {
                        id: item.id,
                        name: item.name,
                        __typename: "Region",
                      }
                    }),
                    ...subRegionsList.map((item) => {
                      return {
                        id: item.id,
                        name: item.name,
                        __typename: "SubRegion",
                      }
                    }),
                    ...countriesList.map((item) => {
                      return {
                        id: item.id,
                        name: item.name,
                        __typename: "Country",
                      }
                    }),
                  ]}
                  getOptionLabel={(option: LocationAutocompleteOption) => {
                    if (option.name === "defaultLocation") {
                      return "Default"
                    } else {
                      return option.name
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Location"
                      error={
                        currentAction.savingMetrics.filter(
                          (item2) => item2.location.id === item.location.id
                        ).length > 1
                      }
                      helperText={
                        currentAction.savingMetrics.filter(
                          (item2) => item2.location.id === item.location.id
                        ).length > 1
                          ? "There cannot be multiple savings for the same location"
                          : ""
                      }
                      FormHelperTextProps={{
                        style: {
                          margin: 0,
                          marginBottom: -10,
                        },
                      }}
                    />
                  )}
                  onChange={(
                    e: any,
                    newValue: LocationAutocompleteOption | null
                  ) => {
                    if (newValue) {
                      currentAction.savingMetrics[index].location = newValue
                    } else {
                      currentAction.savingMetrics[index].location = {
                        id: "defaultLocation",
                        name: "Default",
                        __typename: "DefaultLocation",
                      }
                    }
                    setCurrentAction({ ...currentAction })

                    // check for errors
                    if (
                      currentAction.savingMetrics.filter(
                        (item2) => item2.location.id === item.location.id
                      ).length > 1
                    ) {
                      savingMetricsError[index].locationError = true
                    } else {
                      savingMetricsError[index].locationError = false
                    }
                    setSavingMetricsError([...savingMetricsError])
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    )
                  }}
                />
                {/* <TextField
                  fullWidth
                  disabled={!editMode}
                  size="small"
                  type="number"
                  label="Actions"
                  value={item.metrics.action ?? ""}
                  onChange={(e) => {
                    if (e.target.value.length) {
                      if (
                        e.target.value.includes(".") ||
                        e.target.value.includes(",")
                      ) {
                        currentAction.savingMetrics[index].metrics.action =
                          parseFloat(e.target.value)
                        setCurrentAction({ ...currentAction })
                      } else {
                        currentAction.savingMetrics[index].metrics.action =
                          parseInt(e.target.value)
                        setCurrentAction({ ...currentAction })
                      }
                    } else {
                      currentAction.savingMetrics[index].metrics.action = null
                      setCurrentAction({ ...currentAction })
                    }

                    // check for errors
                    if (
                      item.metrics.action === null ||
                      (item.metrics.action !== null &&
                        item.metrics.action < 1) ||
                      (item.metrics.action !== null &&
                        item.metrics.action.toString().includes("."))
                    ) {
                      savingMetricsError[index].actionsError = true
                    } else {
                      savingMetricsError[index].actionsError = false
                    }
                    setSavingMetricsError([...savingMetricsError])
                  }}
                  error={
                    item.metrics.action === null ||
                    (item.metrics.action !== null && item.metrics.action < 0) ||
                    (item.metrics.action !== null &&
                      item.metrics.action.toString().includes("."))
                  }
                  helperText={
                    item.metrics.action === null ||
                    (item.metrics.action !== null && item.metrics.action < 0) ||
                    (item.metrics.action !== null &&
                      item.metrics.action.toString().includes("."))
                      ? "Invalid value"
                      : ""
                  }
                /> */}
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  disabled={!editMode}
                  fullWidth
                  size="small"
                  type="number"
                  label="Co2 saving"
                  value={item.metrics.co2Saving ?? ""}
                  onChange={(e) => {
                    if (e.target.value.length) {
                      if (
                        e.target.value.includes(".") ||
                        e.target.value.includes(",")
                      ) {
                        currentAction.savingMetrics[index].metrics.co2Saving =
                          parseFloat(e.target.value)
                        setCurrentAction({ ...currentAction })
                      } else {
                        currentAction.savingMetrics[index].metrics.co2Saving =
                          parseInt(e.target.value)
                        setCurrentAction({ ...currentAction })
                      }
                    } else {
                      currentAction.savingMetrics[index].metrics.co2Saving =
                        null
                      setCurrentAction({ ...currentAction })
                    }

                    // check for errors
                    if (
                      item.metrics.co2Saving === null ||
                      (item.metrics.co2Saving !== null &&
                        item.metrics.co2Saving < 0)
                    ) {
                      savingMetricsError[index].co2Error = true
                    } else {
                      savingMetricsError[index].co2Error = false
                    }
                    setSavingMetricsError([...savingMetricsError])
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ marginLeft: 23 }}>
                        kg
                      </InputAdornment>
                    ),
                  }}
                  error={
                    item.metrics.co2Saving === null ||
                    (item.metrics.co2Saving !== null &&
                      item.metrics.co2Saving < 0)
                  }
                  helperText={
                    item.metrics.co2Saving === null ||
                    (item.metrics.co2Saving !== null &&
                      item.metrics.co2Saving < 0)
                      ? "Invalid value"
                      : ""
                  }
                />
                <TextField
                  disabled={!editMode}
                  fullWidth
                  size="small"
                  type="number"
                  label="Energy saving"
                  value={item.metrics.energySaving ?? ""}
                  onChange={(e) => {
                    if (e.target.value.length) {
                      if (
                        e.target.value.includes(".") ||
                        e.target.value.includes(",")
                      ) {
                        currentAction.savingMetrics[
                          index
                        ].metrics.energySaving = parseFloat(e.target.value)
                        setCurrentAction({ ...currentAction })
                      } else {
                        currentAction.savingMetrics[
                          index
                        ].metrics.energySaving = parseInt(e.target.value)
                        setCurrentAction({ ...currentAction })
                      }
                    } else {
                      currentAction.savingMetrics[index].metrics.energySaving =
                        null
                      setCurrentAction({ ...currentAction })
                    }

                    // check for errors
                    if (
                      item.metrics.energySaving === null ||
                      (item.metrics.energySaving !== null &&
                        item.metrics.energySaving < 0)
                    ) {
                      savingMetricsError[index].energyError = true
                    } else {
                      savingMetricsError[index].energyError = false
                    }
                    setSavingMetricsError([...savingMetricsError])
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kWh</InputAdornment>
                    ),
                  }}
                  error={
                    item.metrics.energySaving === null ||
                    (item.metrics.energySaving !== null &&
                      item.metrics.energySaving < 0)
                  }
                  helperText={
                    item.metrics.energySaving === null ||
                    (item.metrics.energySaving !== null &&
                      item.metrics.energySaving < 0)
                      ? "Invalid value"
                      : ""
                  }
                />
                <TextField
                  disabled={!editMode}
                  fullWidth
                  size="small"
                  type="number"
                  label="Water saving"
                  value={item.metrics.waterSaving ?? ""}
                  onChange={(e) => {
                    if (e.target.value.length) {
                      if (
                        e.target.value.includes(".") ||
                        e.target.value.includes(",")
                      ) {
                        currentAction.savingMetrics[index].metrics.waterSaving =
                          parseFloat(e.target.value)
                        setCurrentAction({ ...currentAction })
                      } else {
                        currentAction.savingMetrics[index].metrics.waterSaving =
                          parseInt(e.target.value)
                        setCurrentAction({ ...currentAction })
                      }
                    } else {
                      currentAction.savingMetrics[index].metrics.waterSaving =
                        null
                      setCurrentAction({ ...currentAction })
                    }

                    // check for errors
                    if (
                      item.metrics.waterSaving === null ||
                      (item.metrics.waterSaving !== null &&
                        item.metrics.waterSaving < 0)
                    ) {
                      savingMetricsError[index].waterError = true
                    } else {
                      savingMetricsError[index].waterError = false
                    }
                    setSavingMetricsError([...savingMetricsError])
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ marginLeft: 35 }}>
                        l
                      </InputAdornment>
                    ),
                  }}
                  error={
                    item.metrics.waterSaving === null ||
                    (item.metrics.waterSaving !== null &&
                      item.metrics.waterSaving < 0)
                  }
                  helperText={
                    item.metrics.waterSaving === null ||
                    (item.metrics.waterSaving !== null &&
                      item.metrics.waterSaving < 0)
                      ? "Invalid value"
                      : ""
                  }
                />
              </Stack>
            </Stack>
          </Stack>
        ))}
        <Button
          disabled={!editMode}
          variant="outlined"
          startIcon={<AddCircleRounded />}
          onClick={() => {
            currentAction.savingMetrics.push({
              metrics: {
                action: 1,
                activity: 1,
                co2Saving: 0,
                energySaving: 0,
                waterSaving: 0,
              },
              location: {
                id: "defaultLocation",
                name: "Default",
                __typename: "DefaultLocation",
              },
            })
            savingMetricsError.push({
              locationError: true,
              actionsError: false,
              co2Error: false,
              waterError: false,
              energyError: false,
            })
            setCurrentAction({ ...currentAction })
            setSavingMetricsError([...savingMetricsError])
          }}
        >
          Add savings
        </Button>
        <Typography variant="h6" className="card-title">
          Image
        </Typography>
        <ImagePicker
          title="Image"
          pickedImage={currentAction.image ?? ""}
          setPickedImage={(selectedImage: {
            url: string
            id: string
            description?: string
          }) => {
            currentAction.image = selectedImage.url
            setCurrentAction({ ...currentAction })
          }}
          filters={{ category: "action" }}
          disableCategoryFilter
          height={200}
          disabled={!editMode}
        />
        <Typography variant="h6" className="card-title">
          SDGs and SDG targets
        </Typography>
        <Stack direction="row" spacing={2}>
          <Autocomplete
            disabled={!editMode}
            getOptionDisabled={(option: AutocompleteOption) => {
              if (
                currentAction.sdgs.filter(
                  (sdg) => sdg.sdg.id === option.id
                )[0] &&
                !currentAction.sdgs.filter((sdg) => sdg.sdg.id === option.id)[0]
                  .primary
              ) {
                return true
              } else {
                return false
              }
            }}
            fullWidth
            size="small"
            value={
              currentAction.sdgs.filter((item) => item.primary)[0]
                ? {
                    label:
                      (currentAction.sdgs
                        .filter((item) => item.primary)[0]
                        .sdg.id.charAt(4) === "0"
                        ? currentAction.sdgs
                            .filter((item) => item.primary)[0]
                            .sdg.id.slice(5) + ". "
                        : currentAction.sdgs
                            .filter((item) => item.primary)[0]
                            .sdg.id.slice(4) + ". ") +
                      currentAction.sdgs.filter((item) => item.primary)[0].sdg
                        .name,
                    id: currentAction.sdgs.filter((item) => item.primary)[0].sdg
                      .id,
                  }
                : null
            }
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              sdgsList.length
                ? sdgsList.map((sdg: Sdg) => {
                    let idForLabel =
                      sdg.id.charAt(4) === "0"
                        ? sdg.id.slice(5) + ". "
                        : sdg.id.slice(4) + ". "

                    return { label: idForLabel + sdg.name, id: sdg.id }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField {...params} label="Primary SDG" />
            )}
            onChange={(e: any, newValue: AutocompleteOption | null) => {
              if (newValue) {
                setCurrentAction({
                  ...currentAction!,
                  sdgs: [
                    {
                      primary: true,
                      sdg: {
                        id: newValue.id,
                        name: newValue.label.slice(
                          newValue.label.indexOf(".") + 2
                        ),
                      },
                    },
                    ...currentAction.sdgs.filter((sdg) => !sdg.primary),
                  ],
                })
              } else {
                if (currentAction.sdgs.filter((sdg) => sdg.primary)[0]) {
                  const indexToRemove = currentAction.sdgs.findIndex(
                    (sdg) => sdg.primary
                  )
                  currentAction.sdgs.splice(indexToRemove, 1)
                  setCurrentAction({ ...currentAction })
                }
              }
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
          <Autocomplete
            disabled={!editMode}
            getOptionDisabled={(option: AutocompleteOption) => {
              if (
                currentAction.sdgs.filter(
                  (sdg) => sdg.sdg.id === option.id
                )[0] &&
                currentAction.sdgs.filter((sdg) => sdg.sdg.id === option.id)[0]
                  .primary
              ) {
                return true
              } else {
                return false
              }
            }}
            multiple
            fullWidth
            onBlur={() => {
              setSecondarySdgsError(false)
            }}
            size="small"
            value={currentAction.sdgs
              .filter((sdg) => !sdg.primary)
              .map((sdg) => {
                let idForLabel =
                  sdg.sdg.id.charAt(4) === "0"
                    ? sdg.sdg.id.slice(5) + ". "
                    : sdg.sdg.id.slice(4) + ". "

                return {
                  label: idForLabel + sdg.sdg.name,
                  id: sdg.sdg.id,
                }
              })}
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              sdgsList.length
                ? sdgsList.map((sdg: Sdg) => {
                    let idForLabel =
                      sdg.id.charAt(4) === "0"
                        ? sdg.id.slice(5) + ". "
                        : sdg.id.slice(4) + ". "

                    return { label: idForLabel + sdg.name, id: sdg.id }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField
                {...params}
                error={secondarySdgsError}
                helperText={
                  secondarySdgsError
                    ? "The maximum number of secondary SDGs is 3"
                    : null
                }
                label="Secondary SDGs"
              />
            )}
            onChange={(e: any, newValues: AutocompleteOption[]) => {
              if (newValues.length < 4) {
                setCurrentAction({
                  ...currentAction!,
                  sdgs: [
                    ...currentAction.sdgs.filter((sdg) => sdg.primary),
                    ...newValues.map((sdg) => {
                      return {
                        primary: false,
                        sdg: {
                          id: sdg.id,
                          name: sdg.label.slice(sdg.label.indexOf(".") + 2),
                        },
                      }
                    }),
                  ],
                })
                setSecondarySdgsError(false)
              } else {
                setSecondarySdgsError(true)
              }
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Autocomplete
            disabled={!editMode}
            getOptionDisabled={(option: AutocompleteOption) => {
              if (
                currentAction.sdgTargets.filter(
                  (sdgTarget) => sdgTarget.sdgTarget.id === option.id
                )[0] &&
                !currentAction.sdgTargets.filter(
                  (sdgTarget) => sdgTarget.sdgTarget.id === option.id
                )[0].primary
              ) {
                return true
              } else {
                return false
              }
            }}
            fullWidth
            size="small"
            value={
              currentAction.sdgTargets.filter((item) => item.primary)[0]
                ? {
                    label: currentAction.sdgTargets
                      .filter((item) => item.primary)[0]
                      .sdgTarget.id.slice(4),
                    id: currentAction.sdgTargets.filter(
                      (item) => item.primary
                    )[0].sdgTarget.id,
                  }
                : null
            }
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              sdgTargetsList.length
                ? sdgTargetsList.map((sdgTarget: SdgTarget) => {
                    return {
                      label: sdgTarget.id.slice(4),
                      id: sdgTarget.id,
                    }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField {...params} label="Primary SDG target" />
            )}
            onChange={(e: any, newValue: AutocompleteOption | null) => {
              if (newValue) {
                setCurrentAction({
                  ...currentAction!,
                  sdgTargets: [
                    {
                      primary: true,
                      sdgTarget: {
                        id: newValue.id,
                      },
                    },
                    ...currentAction.sdgTargets.filter(
                      (sdgTarget) => !sdgTarget.primary
                    ),
                  ],
                })
              } else {
                if (
                  currentAction.sdgTargets.filter(
                    (sdgTarget) => sdgTarget.primary
                  )[0]
                ) {
                  const indexToRemove = currentAction.sdgTargets.findIndex(
                    (sdgTarget) => sdgTarget.primary
                  )
                  currentAction.sdgTargets.splice(indexToRemove, 1)
                  setCurrentAction({ ...currentAction })
                }
              }
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
          <Autocomplete
            disabled={!editMode}
            getOptionDisabled={(option: AutocompleteOption) => {
              if (
                currentAction.sdgTargets.filter(
                  (sdgTarget) => sdgTarget.sdgTarget.id === option.id
                )[0] &&
                currentAction.sdgTargets.filter(
                  (sdgTarget) => sdgTarget.sdgTarget.id === option.id
                )[0].primary
              ) {
                return true
              } else {
                return false
              }
            }}
            multiple
            fullWidth
            onBlur={() => {
              setSecondarySdgTargetsError(false)
            }}
            size="small"
            value={currentAction.sdgTargets
              .filter((sdgTarget) => !sdgTarget.primary)
              .map((sdgTarget) => {
                return {
                  label: sdgTarget.sdgTarget.id.slice(4),
                  id: sdgTarget.sdgTarget.id,
                }
              })}
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              sdgTargetsList.length
                ? sdgTargetsList.map((sdgTarget: SdgTarget) => {
                    return {
                      label: sdgTarget.id.slice(4),
                      id: sdgTarget.id,
                    }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField
                {...params}
                error={secondarySdgTargetsError}
                helperText={
                  secondarySdgTargetsError
                    ? "The maximum number of secondary SDG targets is 3"
                    : null
                }
                label="Secondary SDG targets"
              />
            )}
            onChange={(e: any, newValues: AutocompleteOption[]) => {
              if (newValues.length < 4) {
                setCurrentAction({
                  ...currentAction!,
                  sdgTargets: [
                    ...currentAction.sdgTargets.filter(
                      (sdgTarget) => sdgTarget.primary
                    ),
                    ...newValues.map((sdgTarget) => {
                      return {
                        primary: false,
                        sdgTarget: {
                          id: sdgTarget.id,
                        },
                      }
                    }),
                  ],
                })
                setSecondarySdgTargetsError(false)
              } else {
                setSecondarySdgTargetsError(true)
              }
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
        </Stack>
      </Stack>
    </CardContent>
  )
}

export default ActionGeneralEdit
