import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import { TagsContext } from "../../../controllers/tags"
import { CardContent, Stack, TextField, Typography } from "@mui/material"
import { HexColorInput, HexColorPicker } from "react-colorful"
import "../../../styles/categoryGeneralEdit.scss"

const MainCategoryGeneralEdit = ({
  errors,
}: {
  errors: { type: string; error: boolean }[]
}) => {
  const { showPadding } = useContext(MainContext)
  const { currentMainCategory, setCurrentMainCategory, editMode } =
    useContext(TagsContext)

  // errors
  const nameError = errors.filter((item) => item.type === "name")[0].error
  const codeError = errors.filter((item) => item.type === "code")[0].error

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Text
        </Typography>
        <TextField
          label="Name"
          value={currentMainCategory.name}
          onChange={(e) => {
            currentMainCategory.name = e.target.value
            setCurrentMainCategory({ ...currentMainCategory })
          }}
          size="small"
          onBlur={() => {
            currentMainCategory.name = currentMainCategory.name.trim()
            setCurrentMainCategory({ ...currentMainCategory })
          }}
          disabled={!editMode}
          error={nameError}
          helperText={nameError ? "This field is required" : ""}
        />
        {/* <TextField
          label="Code"
          value={currentMainCategory.code}
          onChange={(e) => {
            currentMainCategory.code = e.target.value.toUpperCase()
            setCurrentMainCategory({ ...currentMainCategory })
          }}
          size="small"
          onBlur={() => {
            currentMainCategory.code = currentMainCategory.code.trim()
            setCurrentMainCategory({ ...currentMainCategory })
          }}
          placeholder="SHO"
          disabled={!editMode}
          error={codeError}
          helperText={codeError ? "This field is required" : ""}
        /> */}
        <Typography variant="h6" className="card-title">
          Colors
        </Typography>
        <Stack direction="row" spacing={3}>
          <Stack spacing={2} style={{ width: "33.3%" }}>
            <Typography>Back color</Typography>
            <HexColorPicker
              className="color-picker"
              color={currentMainCategory.backColor}
              onChange={(newColor) => {
                currentMainCategory.backColor = newColor.toUpperCase()
                setCurrentMainCategory({ ...currentMainCategory })
              }}
              style={{
                pointerEvents: !editMode ? "none" : "auto",
                opacity: !editMode ? 0.5 : 1,
                transition: "120ms",
              }}
            />
            <HexColorInput
              className={editMode ? "color-input" : "color-input-disabled"}
              color={currentMainCategory.backColor}
              onChange={(newColor) => {
                currentMainCategory.backColor = newColor.toUpperCase()
                setCurrentMainCategory({ ...currentMainCategory })
              }}
              prefixed
              disabled={!editMode}
            />
          </Stack>
          <Stack spacing={2} style={{ width: "33.3%" }}>
            <Typography>Back tag color</Typography>
            <HexColorPicker
              className="color-picker"
              color={currentMainCategory.backTagColor}
              onChange={(newColor) => {
                currentMainCategory.backTagColor = newColor.toUpperCase()
                setCurrentMainCategory({ ...currentMainCategory })
              }}
              style={{
                pointerEvents: !editMode ? "none" : "auto",
                opacity: !editMode ? 0.5 : 1,
                transition: "120ms",
              }}
            />
            <HexColorInput
              className={editMode ? "color-input" : "color-input-disabled"}
              color={currentMainCategory.backTagColor}
              onChange={(newColor) => {
                currentMainCategory.backTagColor = newColor.toUpperCase()
                setCurrentMainCategory({ ...currentMainCategory })
              }}
              prefixed
              disabled={!editMode}
            />
          </Stack>
          <Stack spacing={2} style={{ width: "33.3%" }}>
            <Typography>Fore color</Typography>
            <HexColorPicker
              className="color-picker"
              color={currentMainCategory.foreColor}
              onChange={(newColor) => {
                currentMainCategory.foreColor = newColor.toUpperCase()
                setCurrentMainCategory({ ...currentMainCategory })
              }}
              style={{
                pointerEvents: !editMode ? "none" : "auto",
                opacity: !editMode ? 0.5 : 1,
                transition: "120ms",
              }}
            />
            <HexColorInput
              className={editMode ? "color-input" : "color-input-disabled"}
              color={currentMainCategory.foreColor}
              onChange={(newColor) => {
                currentMainCategory.foreColor = newColor.toUpperCase()
                setCurrentMainCategory({ ...currentMainCategory })
              }}
              prefixed
              disabled={!editMode}
            />
          </Stack>
        </Stack>
      </Stack>
    </CardContent>
  )
}

export default MainCategoryGeneralEdit
