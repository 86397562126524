import { CSSProperties, ReactNode, useContext } from "react"
import { MainContext } from "../../controllers/main"
import { Fade, Paper } from "@mui/material"

const ListContainer = ({
  children,
  style,
}: {
  children: ReactNode
  style?: CSSProperties
}) => {
  const { animation, showPadding } = useContext(MainContext)

  return (
    <Fade in={animation} timeout={350}>
      <Paper
        sx={{
          width: showPadding === "yes" ? "calc(100% - 32px)" : "100%",
          overflow: "hidden",
          margin: showPadding === "yes" ? "16px" : 0,
        }}
        style={{ ...style }}
      >
        {children}
      </Paper>
    </Fade>
  )
}

export default ListContainer
