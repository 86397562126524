import {
  ArrowRightAltRounded,
  ContentCopyRounded,
  DeleteRounded,
  DragHandleRounded,
  ErrorRounded,
  ExpandMoreRounded,
  LanguageRounded,
} from "@mui/icons-material"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import { Draggable } from "react-beautiful-dnd"
import { EpisodesContext } from "../../controllers/episodes"
import EpisodeSlide from "../../models/episodeSlide"
import EmbedSlide from "../episodeSlideType/embedSlide"
import FullImageSlide from "../episodeSlideType/fullImageSlide"
import FullTextSlide from "../episodeSlideType/fullTextSlide"
import PortraitSlide from "../episodeSlideType/portraitSlide"
import QuoteSlide from "../episodeSlideType/quoteSlide"
import TextImageSlide from "../episodeSlideType/textImageSlide"
import TitleSlide from "../episodeSlideType/titleSlide"

const SlidesStack = ({
  slideLanguage,
  handleChange,
  expanded,
  setRemoveSlideDialogOpen,
  setSlideIdToRemove,
  translationChangesCancelled,
  setDuplicateDialogOpen,
  setSlideToDuplicate,
  setDuplicateToTranslationDialogOpen,
}: {
  slideLanguage: string
  handleChange: Function
  expanded: number | false
  setRemoveSlideDialogOpen: Dispatch<SetStateAction<boolean>>
  setSlideIdToRemove: Dispatch<SetStateAction<string>>
  translationChangesCancelled: boolean
  setDuplicateDialogOpen: Dispatch<SetStateAction<boolean>>
  setSlideToDuplicate: Dispatch<SetStateAction<EpisodeSlide>>
  setDuplicateToTranslationDialogOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { currentEpisode, slidesErrors, slideConstraintsList, editMode } =
    useContext(EpisodesContext)

  return (
    <>
      {currentEpisode.translations
        .filter((item) => item.lang === slideLanguage)[0]
        .slides.map((item, index) => (
          // draggable item (slide)
          <Draggable
            key={item.id.toString()}
            draggableId={item.id.toString()}
            index={index}
          >
            {(provided) => (
              <Accordion
                variant="outlined"
                TransitionProps={{
                  unmountOnExit: true,
                }}
                expanded={expanded === index}
                onChange={handleChange(index)}
                id={"accordion-" + index}
                {...provided.draggableProps}
                ref={provided.innerRef}
                sx={{
                  borderRadius: 1,
                  boxShadow:
                    "0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)",
                }}
              >
                {/* part visible when accordion is not expanded */}
                <AccordionSummary
                  expandIcon={<ExpandMoreRounded />}
                  id={item.id}
                  style={{ overflow: "hidden" }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    style={{
                      marginLeft: expanded !== false || !editMode ? -47 : 0,
                      transition: "200ms",
                    }}
                  >
                    <div
                      style={{
                        width: 30,
                        height: 26,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        opacity: expanded !== false || !editMode ? 0 : 1,
                        transition: "100ms",
                        cursor: expanded !== false ? "default" : "grab",
                      }}
                      {...provided.dragHandleProps}
                    >
                      <DragHandleRounded />
                    </div>
                    <Typography className="card-title" variant="h6">
                      Slide{" "}
                      <span
                        className="slide-number"
                        style={{
                          transition: "150ms",
                        }}
                      >
                        {index + 1}
                      </span>
                    </Typography>
                    <Typography variant="caption">
                      {item.slideType.replace(/([A-Z])/g, " $1").trim()}
                    </Typography>
                    {/* icon to indicate that there is an error in slide */}
                    <Stack
                      direction="row"
                      alignItems="flex-end"
                      style={{
                        transition: "160ms",
                        opacity: slidesErrors
                          .filter((item) => item.lang === slideLanguage)[0]
                          .slides.filter(
                            (slide) => slide.slideId === item.id
                          )[0].error
                          ? 1
                          : 0,
                      }}
                    >
                      {slidesErrors
                        .filter((item) => item.lang === slideLanguage)[0]
                        .slides.filter((slide) => slide.slideId === item.id)[0]
                        .error ? (
                        <Tooltip title="Errors in this slide">
                          <ErrorRounded color="error" />
                        </Tooltip>
                      ) : (
                        <ErrorRounded color="error" />
                      )}
                    </Stack>
                    {item.slideType !== "TitleSlide" && (
                      <IconButton
                        style={{
                          position: "absolute",
                          right: 140,
                          opacity: editMode ? 1 : 0,
                          transition: "100ms",
                        }}
                        onClick={
                          editMode
                            ? (e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setSlideToDuplicate(item)
                                setDuplicateDialogOpen(true)
                              }
                            : null
                        }
                      >
                        <ContentCopyRounded style={{ width: 22 }} />
                      </IconButton>
                    )}
                    <IconButton
                      style={{
                        position: "absolute",
                        right: 95,
                        opacity: editMode ? 1 : 0,
                        transition: "100ms",
                      }}
                      onClick={
                        editMode
                          ? (e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setSlideToDuplicate(item)
                              setDuplicateToTranslationDialogOpen(true)
                            }
                          : null
                      }
                    >
                      <ContentCopyRounded style={{ width: 22 }} />
                      <LanguageRounded
                        style={{
                          width: 11,
                          position: "absolute",
                          color: "#757575",
                          top: -2,
                          left: 4,
                        }}
                      />
                    </IconButton>
                    <IconButton
                      style={{
                        position: "absolute",
                        right: 50,
                        opacity: editMode ? 1 : 0,
                        transition: "100ms",
                      }}
                      onClick={
                        editMode
                          ? (e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setRemoveSlideDialogOpen(true)
                              setSlideIdToRemove(item.id)
                            }
                          : null
                      }
                      color="error"
                    >
                      <DeleteRounded style={{ width: 22 }} />
                    </IconButton>
                  </Stack>
                </AccordionSummary>
                {/* part visible when accordion is expanded */}
                <AccordionDetails
                  style={{
                    marginTop: -14,
                    paddingBottom: 24,
                  }}
                >
                  {item.slideType === "TitleSlide" ? (
                    <TitleSlide
                      slide={item}
                      language={slideLanguage}
                      index={index}
                      constraints={
                        slideConstraintsList.filter(
                          (filterItem) => item.slideType === filterItem.type
                        )[0]
                      }
                      changesCancelled={translationChangesCancelled}
                    />
                  ) : item.slideType === "FullTextSlide" ? (
                    <FullTextSlide
                      slide={item}
                      language={slideLanguage}
                      index={index}
                      constraints={
                        slideConstraintsList.filter(
                          (filterItem) => item.slideType === filterItem.type
                        )[0]
                      }
                      changesCancelled={translationChangesCancelled}
                    />
                  ) : item.slideType === "TextImageSlide" ? (
                    <TextImageSlide
                      slide={item}
                      language={slideLanguage}
                      index={index}
                      constraints={
                        slideConstraintsList.filter(
                          (filterItem) => item.slideType === filterItem.type
                        )[0]
                      }
                      changesCancelled={translationChangesCancelled}
                    />
                  ) : item.slideType === "PortraitSlide" ? (
                    <PortraitSlide
                      slide={item}
                      language={slideLanguage}
                      index={index}
                      constraints={
                        slideConstraintsList.filter(
                          (filterItem) => item.slideType === filterItem.type
                        )[0]
                      }
                      changesCancelled={translationChangesCancelled}
                    />
                  ) : item.slideType === "QuoteSlide" ? (
                    <QuoteSlide
                      slide={item}
                      language={slideLanguage}
                      index={index}
                      constraints={
                        slideConstraintsList.filter(
                          (filterItem) => item.slideType === filterItem.type
                        )[0]
                      }
                      changesCancelled={translationChangesCancelled}
                    />
                  ) : item.slideType === "FullImageSlide" ? (
                    <FullImageSlide
                      slide={item}
                      language={slideLanguage}
                      index={index}
                      constraints={
                        slideConstraintsList.filter(
                          (filterItem) => item.slideType === filterItem.type
                        )[0]
                      }
                      changesCancelled={translationChangesCancelled}
                    />
                  ) : item.slideType === "EmbedSlide" ? (
                    <EmbedSlide
                      slide={item}
                      language={slideLanguage}
                      index={index}
                      constraints={
                        slideConstraintsList.filter(
                          (filterItem) => item.slideType === filterItem.type
                        )[0]
                      }
                      changesCancelled={translationChangesCancelled}
                    />
                  ) : null}
                </AccordionDetails>
              </Accordion>
            )}
          </Draggable>
        ))}
    </>
  )
}

export default SlidesStack
