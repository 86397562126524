import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import Team from "../../models/team"
import { DialogTransition } from "../../services/utilities/utility"
import { TeamsContext } from "../../controllers/teams"
import { CloseRounded } from "@mui/icons-material"
import TeamsList from "../../views/team/teamsList"

const ChangeTeamDialog = ({
  open,
  setOpen,
  selectedTeam,
  setSelectedTeam,
  setTeam,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  selectedTeam: Team
  setSelectedTeam: Dispatch<SetStateAction<Team>>
  setTeam: Dispatch<SetStateAction<Team>>
}) => {
  const { resetTeamsFilters } = useContext(TeamsContext)

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false)
        setSelectedTeam(null)
        resetTeamsFilters()
      }}
      TransitionComponent={DialogTransition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setOpen(false)
              setSelectedTeam(null)
              resetTeamsFilters()
            }}
            aria-label="close"
          >
            <CloseRounded />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Select Team
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => {
              setTeam(selectedTeam)

              setOpen(false)
              setSelectedTeam(null)
              resetTeamsFilters()
            }}
            disabled={!selectedTeam}
          >
            Select
          </Button>
        </Toolbar>
      </AppBar>
      <Paper style={{ backgroundColor: "#f5f5f5" }}>
        <TeamsList
          forDialog
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
        />
      </Paper>
    </Dialog>
  )
}

export default ChangeTeamDialog
