import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import CloseIcon from "@mui/icons-material/Close"
import { MouseEventHandler } from "react"

const DialogTopBar = ({
  handleDialogChange,
  topBarText,
}: {
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
  topBarText: string
}) => {
  return (
    <AppBar sx={{ position: "relative" }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleDialogChange}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
          {topBarText}
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export default DialogTopBar
