import { ChallengeType, ChallengeVersionType } from "../services/config/enum"
import ChallengesDocument from "./challengesDocument"
import Metric from "./metric"
import Team from "./team"

class Challenge {
  id?: string
  oldId?: number
  uid?: string
  startsAt?: string
  endsAt?: string
  currentAmount?: number
  targetAmount?: number
  name?: string
  type?: ChallengeType
  updatedAt?: string
  sorting?: number
  document?: { items: ChallengesDocument[]; parentId: string }
  metric?: Metric
  team?: Team
  leaderboardGroupType?: "average" | "sum"
  leaderboardMinMembers?: number
  userCount?: number
  personalTargetAmount?: number
  personalTargetPoints?: number
  privacyPolicy?: string
  versionType?: ChallengeVersionType

  constructor(
    id?: string,
    oldId?: number,
    uid?: string,
    startsAt?: string,
    endsAt?: string,
    currentAmount?: number,
    targetAmount?: number,
    name?: string,
    type?: ChallengeType,
    updatedAt?: string,
    sorting?: number,
    document?: { items: ChallengesDocument[]; parentId: string },
    metric?: Metric,
    team?: Team,
    leaderboardGroupType?: "average" | "sum",
    leaderboardMinMembers?: number,
    userCount?: number,
    personalTargetAmount?: number,
    personalTargetPoints?: number,
    privacyPolicy?: string,
    versionType?: ChallengeVersionType
  ) {
    this.id = id
    this.oldId = oldId
    this.uid = uid
    this.startsAt = startsAt
    this.endsAt = endsAt
    this.currentAmount = currentAmount
    this.targetAmount = targetAmount
    this.name = name
    this.type = type
    this.updatedAt = updatedAt
    this.sorting = sorting
    this.document = document
    this.metric = metric
    this.team = team
    this.leaderboardGroupType = leaderboardGroupType
    this.leaderboardMinMembers = leaderboardMinMembers
    this.userCount = userCount
    this.personalTargetAmount = personalTargetAmount
    this.personalTargetPoints = personalTargetPoints
    this.privacyPolicy = privacyPolicy
    this.versionType = versionType
  }
}

export default Challenge
