import { useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import News from "../models/news"
import { sortNews } from "../services/graphql/mutations"
import { allCurrentNewsList } from "../services/graphql/queries"
import { deepCopy, logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"

interface CurrentNewsContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  currentNewsList: News[]
  setCurrentNewsList: Dispatch<SetStateAction<News[]>>
  getCurrentNewsList: (withLoading?: boolean) => void
  doneChanges: boolean
  cancelChanges: () => void
  saveButtonLoading: boolean
  sortNewsList: () => void
}

const CurrentNewsContext = createContext<CurrentNewsContextInterface>({
  loading: true,
  setLoading: () => {},
  updatingList: false,
  setUpdatingList: () => {},
  currentNewsList: [],
  setCurrentNewsList: () => {},
  getCurrentNewsList: () => {},
  doneChanges: false,
  cancelChanges: () => {},
  saveButtonLoading: false,
  sortNewsList: () => {},
})

const CurrentNewsController = ({ children }: { children: ReactNode }) => {
  const {
    setError,
    setErrorMessage,
    panelStatus,
    setPanelStatus,
    setChangesSaved,
  } = useContext(MainContext)

  // states
  const [loading, setLoading] = useState<boolean>(true) // loading
  const [updatingList, setUpdatingList] = useState<boolean>(false) // to show loader
  const [currentNewsList, setCurrentNewsList] = useState<News[]>([]) // list of all current news
  const [preChangesCurrentNewsList, setPreChangesCurrentNewsList] = useState<
    News[]
  >([]) // list of all fetched current news
  const [doneChanges, setDoneChanges] = useState<boolean>(false) // if user has done changes
  const [saveButtonLoading, setSaveButtonLoading] = useState(false) // loading for save changes button

  // queries
  const [currentNewsListQuery] = useLazyQuery(allCurrentNewsList)

  // mutations
  const [sortNewsMutation] = useMutation(sortNews)

  // get news list
  const getCurrentNewsList = useCallback(
    async (withLoading: boolean = true) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter((item) => item.name === "Current News")[0].loading =
          true
        setPanelStatus([...panelStatus])
      }

      try {
        logger(Status.Api, "QUERY newsLiveList", allCurrentNewsList)
        const { data } = await currentNewsListQuery({
          variables: {
            input: { teamId: "team_default" },
          },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "live news list", data.newsLiveList.items)

        setCurrentNewsList(data.newsLiveList.items)
        setPreChangesCurrentNewsList(deepCopy(data.newsLiveList.items))

        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Current News")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "Current News")[0].loading =
          false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `newsLiveList`, e.message)
        }
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Current News")[0].status =
          "error"
        panelStatus.filter((item) => item.name === "Current News")[0].loading =
          false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // sort news
  const sortNewsList = async () => {
    setSaveButtonLoading(true)

    try {
      const input: {
        sortedList: {
          id: string
          sort: number
        }[]
        teamId: string
      } = {
        sortedList: currentNewsList.map((item, index) => {
          return {
            id: item.id,
            sort: index + 1,
          }
        }),
        teamId: "team_default",
      }

      logger(Status.Api, "MUTATION newsSort", sortNews)
      const { data } = await sortNewsMutation({
        variables: { input: input },
      })
      logger(Status.Info, "sorted live news list", data.newsSort.items)

      setCurrentNewsList(data.newsSort.items)
      setPreChangesCurrentNewsList(deepCopy(data.newsSort.items))

      setSaveButtonLoading(false)
      setChangesSaved(true)
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `newsSort`, e.message)
      }
      setSaveButtonLoading(false)
    }
  }

  // cancel changes
  const cancelChanges = () => {
    setCurrentNewsList(deepCopy(preChangesCurrentNewsList))
  }

  // check if user has done changes
  useEffect(() => {
    if (
      JSON.stringify(currentNewsList) !==
      JSON.stringify(preChangesCurrentNewsList)
    ) {
      setDoneChanges(true)
    } else {
      setDoneChanges(false)
    }
  }, [currentNewsList])

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter(
      (item) => item.name === "Current News"
    )[0].updateFunction = getCurrentNewsList
  }, [])

  return (
    <CurrentNewsContext.Provider
      value={{
        loading,
        setLoading,
        updatingList,
        setUpdatingList,
        currentNewsList,
        setCurrentNewsList,
        getCurrentNewsList,
        doneChanges,
        cancelChanges,
        saveButtonLoading,
        sortNewsList,
      }}
    >
      {children}
    </CurrentNewsContext.Provider>
  )
}

export { CurrentNewsController, CurrentNewsContext }
