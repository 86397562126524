import { Chip, Stack, Typography } from "@mui/material"
import { ReactNode } from "react"

const PrimaryBadge = ({
  count,
  icon,
}: {
  count: number | string
  icon?: ReactNode
}) => {
  return (
    <Chip
      size="small"
      label={
        <Stack direction="row" alignItems="center" spacing={1}>
          {icon ? <div style={{ marginBottom: -3 }}>{icon}</div> : null}
          <Typography style={{ fontSize: 13, fontWeight: 600 }}>
            {count}
          </Typography>
        </Stack>
      }
      sx={{ backgroundColor: "#F0F7FF", color: "#00A0FD" }}
    />
  )
}

export default PrimaryBadge
