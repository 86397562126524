import ReactDOM from "react-dom/client"
import "./styles/App.scss"
import "./styles/fonts.scss"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "scroll-behavior-polyfill"
import "react-device-frameset/styles/marvel-devices.min.css"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals(console.info)
