import { Stack } from "@mui/material"
import { Dispatch, SetStateAction, useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import { Textfit } from "react-textfit"
import "../../styles/imageDropzone.scss"
import tableIcon from "../../assets/icons/table-icon.png"

const FileDropzone = ({
  title,
  droppedFile,
  setDroppedFile,
  height = "120px",
  disabled = false,
  disabledText,
}: {
  title: string
  droppedFile: {
    name: string
    size: number
    type: string
    dataUrl: string
  } | null
  setDroppedFile: Dispatch<
    SetStateAction<{
      name: string
      size: number
      type: string
      dataUrl: string
    } | null>
  >
  height?: string
  disabled?: boolean
  disabledText?: string
}) => {
  // dropped file error
  const [error, setError] = useState<boolean>(false)
  const [errorType, setErrorType] = useState<string>("")

  // read file dropped in the drop zone
  const readFile = (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener("load", () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  // on file dropped
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setError(false)
    setDroppedFile(null)

    if (!acceptedFiles[0]) {
      setError(true)
      setErrorType("Invalid file")
      return
    }

    if (acceptedFiles[0].name.includes(" ")) {
      setError(true)
      setErrorType("File name cannot contain spaces")
      return
    }

    const dataUrl = await readFile(acceptedFiles[0])

    setDroppedFile({
      name: acceptedFiles[0].name,
      size: acceptedFiles[0].size,
      type: acceptedFiles[0].type,
      dataUrl: dataUrl as string,
    })
  }, [])

  // dropzone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.ms-excel": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "application/vnd.apple.numbers": [],
      "application/x-iwork-numbers-sffnumbers": [],
      "text/csv": [],
    },
    maxFiles: 1,
  })

  return disabled ? (
    <div
      className="image-dropzone-container-disabled"
      style={{
        borderRadius: 4,
        height: height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        padding: 15,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: -10,
          left: 8,
          color: "#9e9e9f",
          fontSize: 12,
          backgroundColor: "white",
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        {title}
      </div>
      <p style={{ textAlign: "center" }}>
        {disabledText && disabledText.length
          ? disabledText
          : "Drag and drop a file here or click to select"}
      </p>
    </div>
  ) : (
    <div
      {...getRootProps()}
      className={
        error
          ? "image-dropzone-container-error-prismic"
          : "image-dropzone-container"
      }
      style={{
        borderRadius: 4,
        height: height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        padding: 15,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: -10,
          left: 8,
          color: error ? "#d3302f" : "rgba(0, 0, 0, 0.6)",
          fontSize: 12,
          backgroundColor: "white",
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        {title}
      </div>
      <input {...getInputProps()} />
      {!droppedFile ? (
        isDragActive ? (
          <p style={{ textAlign: "center" }}>Drop the file here...</p>
        ) : (
          <p style={{ textAlign: "center" }}>
            Drag and drop a file here or click to select
          </p>
        )
      ) : isDragActive ? (
        <p style={{ textAlign: "center" }}>Drop the file here...</p>
      ) : (
        <Stack spacing={3} alignItems="center">
          <img
            src={tableIcon}
            style={{
              width: 36,
              height: 36,
              objectFit: "contain",
            }}
          />
          <p
            style={{
              textAlign: "center",
              color: error ? "#d3302f" : "black",
              fontSize: 13,
              marginBottom: -6,
            }}
          >
            {droppedFile.name.length > 50
              ? droppedFile.name.slice(0, 50) + "..."
              : droppedFile.name}{" "}
            |{" "}
            {droppedFile.size / 1000 > 999
              ? Math.round((droppedFile.size / 1000000 + Number.EPSILON) * 10) /
                  10 +
                "mb"
              : Math.round(droppedFile.size / 1000 + Number.EPSILON) + "kb "}
          </p>
        </Stack>
      )}
      <Textfit
        style={{
          color: "#d3302f",
          position: "absolute",
          bottom: -25,
          left: 8,
          opacity: error ? 1 : 0,
          width: "calc(100% - 7px)",
          height: 20,
          overflow: "scroll",
        }}
        max={12}
        min={9}
        mode="single"
      >
        {errorType}
      </Textfit>
    </div>
  )
}

export default FileDropzone
