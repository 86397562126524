import { LoadingButton } from "@mui/lab"
import {
  Button,
  CardActions,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Dialog,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { DialogTransition, enumAsArray } from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import { useNavigate } from "react-router-dom"
import { MainContext } from "../../controllers/main"
import { ActivationCodesContext } from "../../controllers/activationCodes"
import {
  ActivationCodePolicy,
  ActivationCodeType,
} from "../../services/config/enum"
import Team from "../../models/team"
import Challenge from "../../models/challenge"
import NftCatalog from "../../models/nftCatalog"
import ActivationCode from "../../models/activationCode"
import ChangeTeamDialog from "../teams/changeTeamDialog"
import ChangeChallengeDialog from "../challenge/changeChallengeDialog"
import ChangeNftCatalogDialog from "../nft/changeNftCatalogDialog"
import TeamGroup from "../../models/teamGroup"

const CreateActivationCodeDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
}) => {
  const { setAnimation } = useContext(MainContext)
  const { loading, setLoading, createActivationCode } = useContext(
    ActivationCodesContext
  )
  const navigate = useNavigate()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // configuration
  const [type, setType] = useState<ActivationCodeType>(
    ActivationCodeType.TeamActivationCode
  )
  const [policy, setPolicy] = useState<ActivationCodePolicy>(
    ActivationCodePolicy.IterationUnlimited
  )
  const [maxIteration, setMaxIteration] = useState<number | null>(1)

  // team / challenge / nft catalog
  const [team, setTeam] = useState<Team | null>(null)
  const [group, setGroup] = useState<TeamGroup | null>(null)
  const [challenge, setChallenge] = useState<Challenge | null>(null)
  const [nftCatalog, setNftCatalog] = useState<NftCatalog | null>(null)

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  // slides
  const slides = [
    {
      label: "Configuration",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Configuration
          </Typography>
          <FormControl fullWidth size="small">
            <InputLabel id="type">Type</InputLabel>
            <Select
              labelId="type"
              label="Type"
              value={type}
              onChange={(e) => {
                setType(e.target.value as ActivationCodeType)
                setTeam(null)
                setGroup(null)
                setChallenge(null)
                setNftCatalog(null)
              }}
            >
              {enumAsArray(ActivationCodeType)
                .filter(
                  (key) =>
                    key !== ActivationCodeType.PlatformActivationCode &&
                    key !== ActivationCodeType.WrapperActivationCode
                )
                .map((key) => (
                  <MenuItem key={key as string} value={key as string}>
                    {key === ActivationCodeType.TeamActivationCode
                      ? "Team / Group"
                      : (key as string).slice(
                          0,
                          (key as string).indexOf("ActivationCode")
                        )}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Stack direction="row" gap={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="policy">Policy</InputLabel>
              <Select
                labelId="policy"
                label="Policy"
                value={policy}
                onChange={(e) => {
                  setPolicy(e.target.value as ActivationCodePolicy)
                  setMaxIteration(1)
                }}
              >
                {enumAsArray(ActivationCodePolicy).map((key) => (
                  <MenuItem key={key as string} value={key as string}>
                    {key === ActivationCodePolicy.IterationUnlimited
                      ? "Unlimited iterations"
                      : "Limited iterations"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {policy === ActivationCodePolicy.IterationLimited && (
              <TextField
                fullWidth
                label="Maximum iterations"
                type="number"
                size="small"
                value={maxIteration ?? ""}
                onChange={(e) => {
                  if (e.target.value) {
                    setMaxIteration(parseInt(e.target.value))
                  } else {
                    setMaxIteration(null)
                  }
                }}
                error={!maxIteration}
                helperText={!maxIteration ? "This field is required" : ""}
              />
            )}
          </Stack>
        </Stack>
      ),
    },
    {
      label:
        type === ActivationCodeType.TeamActivationCode
          ? "Team / Group"
          : type.slice(0, type.indexOf("ActivationCode")),
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            {type === ActivationCodeType.TeamActivationCode
              ? "Team / Group"
              : type.slice(0, type.indexOf("ActivationCode"))}
          </Typography>
          {type === ActivationCodeType.TeamActivationCode ? (
            <Stack gap={2}>
              <TextField
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <Button
                      sx={{ paddingInline: 3 }}
                      onClick={() => {
                        setChangeTeamDialogOpen(true)
                        setGroup(null)
                      }}
                    >
                      {team ? "Change" : "Select"}
                    </Button>
                  ),
                }}
                fullWidth
                label="Team"
                size="small"
                value={team ? team.name : ""}
              />
              {team && team.groups && team.groups.length ? (
                <FormControl fullWidth size="small">
                  <InputLabel id="group">Group</InputLabel>
                  <Select
                    labelId="group"
                    label="Group (optional)"
                    value={group ? group.groupId : null}
                    onChange={(e) => {
                      setGroup(
                        team.groups.find(
                          (group) => group.groupId === e.target.value
                        )
                      )
                    }}
                  >
                    {team.groups.map((group) => (
                      <MenuItem key={group.groupId} value={group.groupId}>
                        {group.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
            </Stack>
          ) : type === ActivationCodeType.ChallengeActivationCode ? (
            <TextField
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <Button
                    sx={{ paddingInline: 3 }}
                    onClick={() => {
                      setChangeChallengeDialogOpen(true)
                    }}
                  >
                    {challenge ? "Change" : "Select"}
                  </Button>
                ),
              }}
              fullWidth
              label="Challenge"
              size="small"
              value={challenge ? challenge.name : ""}
            />
          ) : (
            <TextField
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <Button
                    sx={{ paddingInline: 3 }}
                    onClick={() => {
                      setChangeNftCatalogDialogOpen(true)
                    }}
                  >
                    {nftCatalog ? "Change" : "Select"}
                  </Button>
                ),
              }}
              fullWidth
              label="NFT Catalog"
              size="small"
              value={nftCatalog ? nftCatalog.name : ""}
            />
          )}
        </Stack>
      ),
    },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setType(ActivationCodeType.TeamActivationCode)
        setPolicy(ActivationCodePolicy.IterationUnlimited)
        setMaxIteration(1)
        setTeam(null)
        setGroup(null)
        setChallenge(null)
        setNftCatalog(null)
        setCurrentSlide(0)
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (type || policy) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [type, policy])

  // create activation code
  const createNewActivationCode = async () => {
    setLoading(true)

    const result = await createActivationCode(
      type,
      policy,
      policy === ActivationCodePolicy.IterationLimited ? maxIteration : null,
      type === ActivationCodeType.TeamActivationCode
        ? { teamId: team.id, groupId: group ? group.groupId : null }
        : null,
      type === ActivationCodeType.ChallengeActivationCode
        ? { challengeId: challenge.id }
        : null,
      type === ActivationCodeType.NftActivationCode
        ? { nftCatalogId: nftCatalog.id }
        : null
    )

    if (result !== false) {
      const newActivationCode = result as ActivationCode

      setDialogOpen(false)
      setAnimation(false)

      setTimeout(() => {
        navigate(`/activationcodes/${newActivationCode.code}`)
      }, 250)
    } else {
      setLoading(false)
    }
  }

  // change team dialog
  const [changeTeamDialogOpen, setChangeTeamDialogOpen] =
    useState<boolean>(false)
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)

  // change challenge dialog
  const [changeChallengeDialogOpen, setChangeChallengeDialogOpen] =
    useState<boolean>(false)
  const [selectedChallenge, setSelectedChallenge] = useState<Challenge | null>(
    null
  )

  // change nft catalog dialog
  const [changeNftCatalogDialogOpen, setChangeNftCatalogDialogOpen] =
    useState<boolean>(false)
  const [selectedNftCatalog, setSelectedNftCatalog] =
    useState<NftCatalog | null>(null)

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText="Add NFT catalog"
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            Add Activation Code
          </Typography>
          <Stepper
            activeStep={currentSlide}
            style={{
              paddingTop: 24,
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 8,
              maxWidth: "100%",
              overflow: "overlay",
            }}
          >
            {slides.map((slide) => {
              return (
                <Step key={slide.label}>
                  <StepLabel>{slide.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (!slides[currentSlide - 1]) {
                    if (showDiscardDialog) {
                      setDiscardDialogOpen(true)
                    } else {
                      handleDialogChange(e)
                    }
                  } else {
                    setCurrentSlide(currentSlide - 1)
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  (currentSlide === 0 &&
                    policy === ActivationCodePolicy.IterationLimited &&
                    !maxIteration) ||
                  (currentSlide === 1 &&
                    type === ActivationCodeType.TeamActivationCode &&
                    !team) ||
                  (currentSlide === 1 &&
                    type === ActivationCodeType.ChallengeActivationCode &&
                    !challenge) ||
                  (currentSlide === 1 &&
                    type === ActivationCodeType.NftActivationCode &&
                    !nftCatalog)
                }
                onMouseDown={
                  !slides[currentSlide + 1]
                    ? () => {
                        createNewActivationCode()
                      }
                    : () => {
                        setCurrentSlide(currentSlide + 1)
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Save" : "Next"}
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-policy"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-policy">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* change team dialog */}
      <ChangeTeamDialog
        open={changeTeamDialogOpen}
        setOpen={setChangeTeamDialogOpen}
        selectedTeam={selectedTeam}
        setSelectedTeam={setSelectedTeam}
        setTeam={setTeam}
      />
      {/* change challenge dialog */}
      <ChangeChallengeDialog
        open={changeChallengeDialogOpen}
        setOpen={setChangeChallengeDialogOpen}
        selectedChallenge={selectedChallenge}
        setSelectedChallenge={setSelectedChallenge}
        setChallenge={setChallenge}
      />
      {/* change nft catalog dialog */}
      <ChangeNftCatalogDialog
        open={changeNftCatalogDialogOpen}
        setOpen={setChangeNftCatalogDialogOpen}
        selectedNftCatalog={selectedNftCatalog}
        setSelectedNftCatalog={setSelectedNftCatalog}
        setNftCatalog={setNftCatalog}
      />
      <LoadingBackdrop open={loading} />
    </Dialog>
  )
}

export default CreateActivationCodeDialog
