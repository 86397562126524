import { LoadingButton } from "@mui/lab"
import {
  Button,
  CardActions,
  CardContent,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Dialog,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
} from "@mui/material"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { DialogTransition } from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import Journey from "../../models/journey"
import { LanguagesContext } from "../../controllers/languages"
import { TranslationsContext } from "../../controllers/translations"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"

const ExportJourneysDialog = ({
  dialogOpen,
  handleDialogChange,
  selectedJourneysForExport,
  setSelectedJourneysForExport,
}: {
  dialogOpen: boolean
  handleDialogChange: () => void
  selectedJourneysForExport: {
    journey: Journey
    sourceLang?: string
    langs: string[]
  }[]
  setSelectedJourneysForExport: Dispatch<
    SetStateAction<
      {
        journey: Journey
        sourceLang?: string
        langs?: string[]
      }[]
    >
  >
}) => {
  const { languages } = useContext(LanguagesContext)
  const { exportJourneysTranslations } = useContext(TranslationsContext)

  // loading
  const [exporting, setExporting] = useState<boolean>(false)

  // options
  const [exportOption, setExportOption] = useState<number>(0)

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  // slides
  const slides = [
    {
      label: "Languages",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Languages
          </Typography>
          <Stack spacing={2} style={{ maxHeight: 250, overflowY: "scroll" }}>
            {selectedJourneysForExport.map((item) => (
              <Stack key={item.journey.id} spacing={1}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <img
                    src={item.journey.image ?? noImagePlaceholder}
                    style={{ width: 35, height: 35, borderRadius: 4 }}
                  />
                  <Typography>{item.journey.title}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <FormControl size="small" style={{ width: 220 }}>
                    <InputLabel id="Source language">
                      Source language
                    </InputLabel>
                    <Select
                      labelId="Source language"
                      label="Source language"
                      value={item.sourceLang ?? ""}
                      onChange={(e) => {
                        item.sourceLang = e.target.value
                        item.langs = []
                        setSelectedJourneysForExport([
                          ...selectedJourneysForExport,
                        ])
                      }}
                    >
                      {item.journey.publishedLangs.map((publishedLang) => (
                        <MenuItem
                          value={publishedLang.lang}
                          key={publishedLang.lang}
                        >
                          {publishedLang.lang.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Autocomplete
                    disabled={!item.sourceLang}
                    fullWidth
                    getOptionDisabled={(option: string) =>
                      option === item.sourceLang.toUpperCase()
                    }
                    multiple
                    size="small"
                    value={item.langs}
                    disablePortal
                    options={languages.map((lang) => lang.toUpperCase())}
                    renderInput={(params) => (
                      <TextField {...params} label="Target langauges" />
                    )}
                    onChange={(e: any, newValues: string[]) => {
                      item.langs = newValues
                      setSelectedJourneysForExport([
                        ...selectedJourneysForExport,
                      ])
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    renderTags={(tagValue, getTagProps) => {
                      return tagValue.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          size="small"
                          key={option}
                          label={option}
                        />
                      ))
                    }}
                  />
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      ),
    },
    {
      label: "Options",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Options
          </Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              name="radio-buttons-group"
              value={exportOption}
              onChange={(e, newOption) => {
                setExportOption(parseInt(newOption))
              }}
            >
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={
                  <Typography style={{ fontSize: "0.875rem" }}>
                    Format text as HTML (e.g. Hello <strong>world</strong>)
                  </Typography>
                }
              />
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="Leave HTML tags as text (e.g. Hello <strong>world</strong>)"
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="Remove all HTML tags (e.g. Hello world)"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      ),
    },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setExportOption(0)
        setCurrentSlide(0)
      }, 100)
    }
  }, [dialogOpen])

  // export journeys
  const exportJourneys = async () => {
    setExporting(true)
    await exportJourneysTranslations({
      journeys: selectedJourneysForExport.map((item) => {
        return {
          journeyId: item.journey.id,
          sourceLang: item.sourceLang,
          langs: item.langs.map((lang) => lang.toLowerCase()),
        }
      }),
      formatHtml: exportOption === 0 ? true : false,
      stripHtml: exportOption === 2 ? true : false,
    })
    setExporting(false)
    setSelectedJourneysForExport([])
    handleDialogChange()
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={handleDialogChange}
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={handleDialogChange}
        topBarText="Export Journeys"
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            Export Journeys
          </Typography>
          <Stepper
            activeStep={currentSlide}
            style={{
              paddingTop: 24,
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 8,
              maxWidth: "100%",
              overflow: "overlay",
            }}
          >
            {slides.map((slide) => {
              return (
                <Step key={slide.label}>
                  <StepLabel>{slide.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={() => {
                  if (!slides[currentSlide - 1]) {
                    handleDialogChange()
                  } else {
                    setCurrentSlide(currentSlide - 1)
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  currentSlide === 0 &&
                  selectedJourneysForExport &&
                  selectedJourneysForExport.length &&
                  selectedJourneysForExport.filter(
                    (item) => !item.sourceLang || !item.langs.length
                  ).length > 0
                    ? true
                    : false
                }
                onMouseDown={
                  !slides[currentSlide + 1]
                    ? exportJourneys
                    : () => {
                        setCurrentSlide(currentSlide + 1)
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Export" : "Next"}
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <LoadingBackdrop open={exporting} />
    </Dialog>
  )
}

export default ExportJourneysDialog
