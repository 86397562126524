import { Stack } from "@mui/material"
import { useState } from "react"
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd"
import EmbedSlice from "../../models/bodySlices/embedSlice"
import ImageSlice from "../../models/bodySlices/imageSlice"
import QuoteSlice from "../../models/bodySlices/quoteSlice"
import TextSlice from "../../models/bodySlices/textSlice"
import { BodySliceType } from "../../services/config/enum"
import AddBlockPanel from "./common/addBlockPanel"
import EmbedSliceComponent from "./slices/embedSlice"
import ImageSliceComponent from "./slices/imageSlice"
import QuoteSliceComponent from "./slices/quoteSlice"
import TextSliceComponent from "./slices/textSlice"

const BlockEditor = ({
  body,
  setBody,
  editMode = true,
}: {
  body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
  setBody: (body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]) => void
  editMode?: boolean
}) => {
  // add slice panel
  const [addSlicePanelOpen, setAddSlicePanelOpen] = useState<boolean>(false)

  // add slice
  const addSlice = (type: BodySliceType) => {
    switch (type) {
      case BodySliceType.BodyTextSlice:
        const newTextSlice: TextSlice = {
          sliceType: BodySliceType.BodyTextSlice,
          text: "",
        }
        body.push(newTextSlice)
        setBody(body)
        break
      case BodySliceType.BodyQuoteSlice:
        const newQuoteSlice: QuoteSlice = {
          sliceType: BodySliceType.BodyQuoteSlice,
          quoteText: "",
          author: null,
          authorTitle: null,
        }
        body.push(newQuoteSlice)
        setBody(body)
        break
      case BodySliceType.BodyImageSlice:
        const newImageSlice: ImageSlice = {
          sliceType: BodySliceType.BodyImageSlice,
          imgUrl: "",
          imgCaption: null,
          imgCaptionTitle: null,
        }
        body.push(newImageSlice)
        setBody(body)
        break
      case BodySliceType.BodyEmbedSlice:
        const newEmbedSlice: EmbedSlice = {
          sliceType: BodySliceType.BodyEmbedSlice,
          embedUrl: "",
          embedText: null,
          embedCaption: null,
          embedCaptionTitle: null,
        }
        body.push(newEmbedSlice)
        setBody(body)
        break
    }
  }

  // remove slice
  const removeSlice = (sliceToRemoveIndex: number) => {
    body.splice(sliceToRemoveIndex, 1)
    setBody(body)
  }

  // drag and drop
  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result

    if (destination && source) {
      const itemToMove = body[source.index]

      body.splice(source.index, 1)
      body.splice(destination.index, 0, itemToMove)
      setBody(body)
    }
  }

  return (
    <Stack spacing={body.length ? 3 : 0} style={{ overflowX: "hidden" }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="column-1">
          {(provided) => (
            <Stack
              spacing={3}
              style={{ overflowX: "hidden", paddingTop: 2, paddingBottom: 2 }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <div
                style={{
                  width: "100%",
                  height: 0,
                  marginTop: -24,
                }}
              />
              {body.map((item, index) => {
                switch (item.sliceType) {
                  case BodySliceType.BodyTextSlice:
                    return (
                      <Draggable
                        key={index}
                        draggableId={index.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                          >
                            <TextSliceComponent
                              item={item as TextSlice}
                              body={body}
                              setBody={setBody}
                              editMode={editMode}
                              sliceIndex={index}
                              removeSlice={removeSlice}
                              provided={provided}
                            />
                          </div>
                        )}
                      </Draggable>
                    )
                  case BodySliceType.BodyQuoteSlice:
                    return (
                      <Draggable
                        key={index}
                        draggableId={index.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                          >
                            <QuoteSliceComponent
                              item={item as QuoteSlice}
                              body={body}
                              setBody={setBody}
                              editMode={editMode}
                              sliceIndex={index}
                              removeSlice={removeSlice}
                              provided={provided}
                            />
                          </div>
                        )}
                      </Draggable>
                    )
                  case BodySliceType.BodyImageSlice:
                    return (
                      <Draggable
                        key={index}
                        draggableId={index.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                          >
                            <ImageSliceComponent
                              item={item as ImageSlice}
                              body={body}
                              setBody={setBody}
                              editMode={editMode}
                              sliceIndex={index}
                              removeSlice={removeSlice}
                              provided={provided}
                            />
                          </div>
                        )}
                      </Draggable>
                    )
                  case BodySliceType.BodyEmbedSlice:
                    return (
                      <Draggable
                        key={index}
                        draggableId={index.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                          >
                            <EmbedSliceComponent
                              item={item as EmbedSlice}
                              body={body}
                              setBody={setBody}
                              editMode={editMode}
                              sliceIndex={index}
                              removeSlice={removeSlice}
                              provided={provided}
                            />
                          </div>
                        )}
                      </Draggable>
                    )
                  default:
                    return null
                }
              })}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
      <AddBlockPanel
        open={addSlicePanelOpen}
        setOpen={setAddSlicePanelOpen}
        editMode={editMode}
        addSlice={addSlice}
      />
    </Stack>
  )
}

export default BlockEditor
