import { AddCircleRounded, CloseRounded } from "@mui/icons-material"
import {
  AppBar,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { EpisodesContext } from "../../controllers/episodes"
import { JourneysContext } from "../../controllers/journeys"
import { MainContext } from "../../controllers/main"
import Episode from "../../models/episode"
import Journey from "../../models/journey"
import { deepCopy, DialogTransition } from "../../services/utilities/utility"
import EpisodesList from "../../views/episode/episodesList"
import EpisodesStack from "./episodesStack"

const JourneyEpisodesEdit = ({
  setSidebarOpen,
}: {
  setSidebarOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { showPadding } = useContext(MainContext)
  const {
    setSearchValue,
    setSearchLanguage,
    setSearchTopic,
    setSearchSdg,
    setSearchSdgTarget,
    setEpisodesList,
  } = useContext(EpisodesContext)
  const { currentJourney, editMode, setCurrentJourney, removeEpisode } =
    useContext(JourneysContext)

  // drag and drop
  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result

    if (destination.index === source.index) {
      return
    }

    let currentJourneyCopy: Journey = deepCopy(currentJourney)
    let episodes = currentJourneyCopy.episodes
    let sourceItem = episodes[source.index]

    let indexToDelete = episodes.findIndex(
      (item) => item.episode.id === draggableId
    )
    episodes.splice(indexToDelete, 1)
    episodes.splice(destination.index, 0, sourceItem)

    setCurrentJourney({ ...currentJourneyCopy })
  }

  // add episode dialog
  const [episodesToFilter, setEpisodesToFilter] = useState<Episode[]>([])
  const [addEpisodesDialogOpen, setAddEpisodesDialogOpen] =
    useState<boolean>(false)
  const [selectedEpisodes, setSelectedEpisodes] = useState<Episode[]>([])

  // remove episode dialog
  const [removeEpisodeDialogOpen, setRemoveEpisodeDialogOpen] =
    useState<boolean>(false)
  const [episodeIdToRemove, setEpisodeIdToRemove] = useState<string>("")

  // can't add episodes dialog
  const [cantAddEpisodeDialogOpen, setCantAddEpisodeDialogOpen] =
    useState<boolean>(false)

  // too many episodes dialog
  const [tooManyEpisodesDialogOpen, setTooManyEpisodesDialogOpen] =
    useState<boolean>(false)

  // reset episodes list filters
  const resetEpisodesFilters = () => {
    setSearchValue("")
    setSearchLanguage(null)
    setSearchTopic(null)
    setSearchSdg(null)
    setSearchSdgTarget(null)
  }

  return (
    <Stack spacing={0}>
      {/* translations edit */}
      <div
        style={{
          height:
            showPadding === "yes"
              ? "calc(100vh - 243px)"
              : "calc(100vh - 227px)",
          position: "relative",
        }}
      >
        <div
          style={{
            height:
              showPadding === "yes"
                ? "calc(100vh - 243px)"
                : "calc(100vh - 227px)",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
          id="edit-content-container"
        >
          <CardContent style={{ position: "relative" }}>
            {/* main translations edit stack */}
            <Stack spacing={2}>
              {/* drag and drop slides */}
              <Stack spacing={2}>
                {/* drag and drop context */}
                <DragDropContext
                  onDragEnd={onDragEnd}
                  onDragStart={() => {
                    setSidebarOpen(false)
                  }}
                >
                  {/* droppable (the column) */}
                  <Droppable droppableId="column-1">
                    {(provided) => (
                      <Stack
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <EpisodesStack
                          setRemoveEpisodeDialogOpen={
                            setRemoveEpisodeDialogOpen
                          }
                          setEpisodeIdToRemove={setEpisodeIdToRemove}
                        />
                        {provided.placeholder}
                      </Stack>
                    )}
                  </Droppable>
                </DragDropContext>
              </Stack>
              {currentJourney.episodes.length && <Divider />}
              <Stack direction="row" spacing={2}>
                <Button
                  disabled={!editMode}
                  startIcon={<AddCircleRounded />}
                  style={{
                    maxHeight: 40,
                    minHeight: 40,
                    minWidth: "100%",
                    marginRight: 1,
                  }}
                  variant="outlined"
                  onClick={() => {
                    setEpisodesList([])
                    setEpisodesToFilter(
                      currentJourney.episodes.map((item) => item.episode)
                    )
                    setAddEpisodesDialogOpen(true)
                  }}
                >
                  Add Episodes
                </Button>
              </Stack>
            </Stack>
          </CardContent>
        </div>
      </div>
      {/* add episodes dialog */}
      <Dialog
        fullScreen
        open={addEpisodesDialogOpen}
        onClose={() => {
          setAddEpisodesDialogOpen(false)
          setSelectedEpisodes([])
          resetEpisodesFilters()
        }}
        TransitionComponent={DialogTransition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setAddEpisodesDialogOpen(false)
                setSelectedEpisodes([])
                resetEpisodesFilters()
              }}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Episodes
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                // check if selected episodes are compatible with journey published langs
                for (let i = 0; i < selectedEpisodes.length; i++) {
                  if (
                    currentJourney.publishedLangs.filter((item) => {
                      if (
                        !selectedEpisodes[i].publishedLangs.filter(
                          (item2) => item2.lang === item.lang
                        )[0]
                      ) {
                        return item
                      }
                    })[0] ||
                    (selectedEpisodes[i].stage === "DRAFT" &&
                      !selectedEpisodes[i].hasSibling)
                  ) {
                    setAddEpisodesDialogOpen(false)
                    setSelectedEpisodes([])
                    resetEpisodesFilters()
                    setTimeout(() => {
                      setCantAddEpisodeDialogOpen(true)
                    }, 150)
                    return
                  }
                }

                // check if number of episodes is less than 20
                if (
                  selectedEpisodes.length + currentJourney.episodes.length >
                  20
                ) {
                  setTooManyEpisodesDialogOpen(true)
                  return
                }

                let startingSorting = Math.max(
                  ...currentJourney.episodes.map((item) => item.sorting)
                )
                for (let i = 0; i < selectedEpisodes.length; i++) {
                  currentJourney.episodes.push({
                    sorting: startingSorting + i + 1,
                    episode: selectedEpisodes[i],
                  })
                }
                setCurrentJourney({ ...currentJourney })

                setAddEpisodesDialogOpen(false)
                setSelectedEpisodes([])
                resetEpisodesFilters()
              }}
              disabled={!selectedEpisodes.length}
            >
              Select
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ backgroundColor: "#f5f5f5" }}>
          <EpisodesList
            forDialog
            forDialogLanguage={currentJourney.lang}
            episodesToFilter={episodesToFilter}
            selectedEpisodes={selectedEpisodes}
            setSelectedEpisodes={setSelectedEpisodes}
          />
        </Paper>
      </Dialog>
      {/* remove episode dialog */}
      <Dialog
        open={removeEpisodeDialogOpen}
        onClose={() => {
          setRemoveEpisodeDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-remove-episode"
        aria-describedby="alert-dialog-description-remove-episode"
      >
        <DialogTitle id="alert-dialog-title-remove-episode">
          {currentJourney.episodes.length > 1
            ? "Are you sure you want to remove this episode?"
            : "You can't remove all episodes"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-remove-episode">
            {currentJourney.episodes.length > 1
              ? "You will be able to restore it undoing the changes"
              : "There must be at least one episode"}
          </DialogContentText>
        </DialogContent>
        {currentJourney.episodes.length > 1 ? (
          <DialogActions>
            <Button
              onClick={() => {
                setRemoveEpisodeDialogOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setRemoveEpisodeDialogOpen(false)
                removeEpisode(episodeIdToRemove)
              }}
              autoFocus
            >
              Remove episode
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              onClick={() => {
                setRemoveEpisodeDialogOpen(false)
              }}
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        )}
      </Dialog>
      {/* can't add episodes dialog */}
      <Dialog
        open={cantAddEpisodeDialogOpen}
        onClose={() => {
          setCantAddEpisodeDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-cant-add-episode"
        aria-describedby="alert-dialog-description-cant-add-episode"
      >
        <DialogTitle id="alert-dialog-title-cant-add-episode">
          These Episodes cannot be added
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-cant-add-episode">
            There are one or more episodes without necessary translations or
            master episode published
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCantAddEpisodeDialogOpen(false)
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* too many episodes dialog */}
      <Dialog
        open={tooManyEpisodesDialogOpen}
        onClose={() => {
          setTooManyEpisodesDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-too-many-episodes"
        aria-describedby="alert-dialog-description-too-many-episodes"
      >
        <DialogTitle id="alert-dialog-title-too-many-episodes">
          These Episodes cannot be added
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-too-many-episodes">
            There cannot be more than 20 Episodes in a Journey
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setTooManyEpisodesDialogOpen(false)
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default JourneyEpisodesEdit
