import {
  Card,
  CardContent,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { DeleteRounded } from "@mui/icons-material"
import NftAttribute from "../../models/nftAttribute"

const AttributeCardCreate = ({
  index,
  attribute,
  attributes,
  setAttributes,
}: {
  index: number
  attribute: NftAttribute
  attributes: NftAttribute[]
  setAttributes: Dispatch<SetStateAction<NftAttribute[]>>
}) => {
  // errors
  const [error, setError] = useState<boolean>(false)

  // check for errors
  useEffect(() => {
    if (!attribute.trait_type || !attribute.value) {
      setError(true)
    } else {
      setError(false)
    }
  }, [attribute.trait_type, attribute.value])

  return (
    <Card
      variant="outlined"
      sx={{
        boxShadow: "none",
        borderTopLeftRadius: !index ? undefined : 0,
        borderTopRightRadius: !index ? undefined : 0,
        borderBottomLeftRadius: index === attributes.length - 1 ? undefined : 0,
        borderBottomRightRadius:
          index === attributes.length - 1 ? undefined : 0,
        borderTop: index === 0 ? undefined : "none",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <CardContent
        style={{
          paddingTop: 12,
          paddingBottom: 12,
          transition: "150ms",
        }}
      >
        <Stack gap={1.5}>
          <Stack
            direction="row"
            alignItems="center"
            style={{ position: "relative" }}
            gap={1}
          >
            <Typography
              variant="h6"
              color={error ? "error" : "default"}
              style={{
                fontSize: "1rem",
                height: 24.88,
              }}
            >
              Attribute {index + 1}
            </Typography>
            {error ? (
              <Typography
                variant="overline"
                color="error"
                style={{ fontSize: "0.5rem", marginTop: 5 }}
              >
                (error)
              </Typography>
            ) : null}
            <IconButton
              size="small"
              color="error"
              style={{
                width: 40,
                height: 40,
                position: "absolute",
                right: -11,
              }}
              onClick={() => {
                attributes.splice(index, 1)
                setAttributes([...attributes])
              }}
            >
              <DeleteRounded
                style={{
                  width: 22,
                }}
              />
            </IconButton>
          </Stack>
          <Stack direction="row" gap={2}>
            <TextField
              fullWidth
              size="small"
              label="Trait type"
              value={attribute.trait_type}
              onChange={(e) => {
                attribute.trait_type = e.target.value
                setAttributes([...attributes])
              }}
              error={error}
            />
            <TextField
              fullWidth
              size="small"
              label="Value"
              value={attribute.value}
              onChange={(e) => {
                attribute.value = e.target.value
                setAttributes([...attributes])
              }}
              error={error}
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default AttributeCardCreate
