import {
  Autocomplete,
  CardContent,
  Chip,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useContext, useMemo } from "react"
import { MainContext } from "../../controllers/main"
import { NotificationsContext } from "../../controllers/notifications"
import {
  capitalizeFirstCharacter,
  renderOption,
  renderTags,
} from "../../services/utilities/utility"
import { AutocompleteOption } from "../../services/config/interfaces"
import { mergeSchemas } from "@aworld/utils/jsonSchema"
import JsonForm from "./jsonForm"

const TemplateGeneralEdit = () => {
  const { showPadding } = useContext(MainContext)
  const {
    currentTemplate,
    setCurrentTemplate,
    editMode,
    handleError,
    setHandleError,
    setFormErrors,
    enrichersList,
  } = useContext(NotificationsContext)

  // form
  const mergedSchemas = useMemo(() => {
    if (
      currentTemplate.requiredEnricherIds &&
      currentTemplate.requiredEnricherIds.length &&
      enrichersList.length
    ) {
      return mergeSchemas(
        currentTemplate.requiredEnricherIds.map(
          (item) => enrichersList.find(({ id }) => id === item).exportSchema
        )
      )
    }

    return {}
  }, [currentTemplate.requiredEnricherIds, enrichersList])

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Configuration
        </Typography>
        <TextField
          fullWidth
          size="small"
          label="Handle"
          disabled={!editMode}
          value={currentTemplate.handle}
          onChange={(e) => {
            setCurrentTemplate({
              ...currentTemplate,
              handle: e.target.value,
            })

            // errors check
            if (!e.target.value) {
              setHandleError(true)
            } else {
              setHandleError(false)
            }
          }}
          error={handleError}
          helperText={handleError ? "This field is required" : ""}
        />
        <Stack direction="row" gap={2}>
          <TextField
            fullWidth
            size="small"
            label="Type"
            disabled={!editMode}
            value={capitalizeFirstCharacter(
              currentTemplate.type
                .toLowerCase()
                .replace("_", "-")
                .replace("app", "App")
            )}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            fullWidth
            size="small"
            label="Format"
            disabled={!editMode}
            value={
              currentTemplate.format === "MJML"
                ? "MJML"
                : capitalizeFirstCharacter(currentTemplate.format.toLowerCase())
            }
            InputProps={{
              readOnly: true,
            }}
          />
        </Stack>
        <Typography variant="h6" className="card-title">
          Variables
        </Typography>
        <Autocomplete
          fullWidth
          size="small"
          disabled={!editMode}
          value={
            !currentTemplate.requiredEnricherIds || !enrichersList.length
              ? []
              : currentTemplate.requiredEnricherIds.map((item) => {
                  const enricher = enrichersList.find(({ id }) => id === item)

                  return {
                    id: enricher.id,
                    label: enricher.name,
                  }
                })
          }
          multiple
          disablePortal
          isOptionEqualToValue={(
            option: AutocompleteOption,
            value: AutocompleteOption
          ) => option.id === value.id}
          options={enrichersList.map((item) => {
            return {
              id: item.id,
              label: item.name,
            }
          })}
          renderInput={(params) => <TextField {...params} label="Variables" />}
          onChange={(e: any, newValues: AutocompleteOption[]) => {
            setCurrentTemplate({
              ...currentTemplate,
              requiredEnricherIds: newValues.map((item) => item.id),
            })
          }}
          renderOption={renderOption}
          renderTags={renderTags}
        />
        {currentTemplate.requiredEnricherIds &&
        currentTemplate.requiredEnricherIds.length ? (
          <Stack spacing={2}>
            <Typography variant="h6" className="card-title">
              Default values form
            </Typography>
            <JsonForm
              disabled={!editMode}
              mergedSchemas={mergedSchemas}
              formData={JSON.parse(currentTemplate.defaultDataJson)}
              setFormData={(newValue) => {
                setCurrentTemplate({
                  ...currentTemplate,
                  defaultDataJson: JSON.stringify(newValue),
                })
              }}
              setFormErrors={setFormErrors}
            />
          </Stack>
        ) : null}
      </Stack>
    </CardContent>
  )
}

export default TemplateGeneralEdit
