import { useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  missionCatalogPublish,
  missionCatalogUpsert,
  upsertDocumentMission,
} from "../services/graphql/mutations"
import {
  missionCatalogsStagedList,
  missionCatalogsSearch,
  missionCatalogStagedGet,
} from "../services/graphql/queries"
import { deepCopy, logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"
import {
  MissionDifficultyLevel,
  MissionSpecialType,
  MissionTimeframe,
  MissionType,
  Stage,
} from "../services/config/enum"
import Document from "../models/document"
import { AutocompleteOption } from "../services/config/interfaces"
import Mission from "../models/mission"

interface MissionsContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  missionsList: Mission[]
  setMissionsList: Dispatch<SetStateAction<Mission[]>>
  getMissionsList: (withLoading?: boolean) => void
  doneChanges: boolean
  cancelChanges: () => void
  currentMission: Mission
  setCurrentMission: Dispatch<SetStateAction<Mission>>
  getCurrentMission: (missionId: string) => Promise<boolean>
  preChangesCurrentMission: Mission
  addTranslation: (translationToAdd: string) => void
  upsertMissionParent: (suspended: boolean) => Promise<boolean>
  hasError: boolean
  translationsErrors: { lang: string; hasErrors: boolean }[]
  setTranslationsErrors: Dispatch<
    SetStateAction<{ lang: string; hasErrors: boolean }[]>
  >
  upsertMissionDocument: (publish?: boolean) => Promise<boolean>
  copyDetailsFromDefault: (itemToCopyToIndex: number) => void
  createMission: (input: {
    handle: string
    projectId?: string
    target: number
    type: MissionType
    timeframe: MissionTimeframe
    difficultyLevel: MissionDifficultyLevel
    specialType?: MissionSpecialType
    teamId?: string
  }) => Promise<string | boolean>
  createMissionDocument: (input: {
    parentId: string
    type: string
    missionCatalogDocumentItems: {
      default: boolean
      lang: string
      title: string
    }[]
  }) => Promise<boolean>
  missionsListNextToken: string | null
  loadMoreMissions: () => Promise<boolean>
  hasSearched: boolean
  setHasSearched: Dispatch<SetStateAction<boolean | null>>
  searchLang: AutocompleteOption | null
  setSearchLang: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchStage: AutocompleteOption | null
  setSearchStage: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchSuspended: boolean | null
  setSearchSuspended: Dispatch<SetStateAction<boolean | null>>
  searchType: AutocompleteOption | null
  setSearchType: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchSpecialType: AutocompleteOption | null
  setSearchSpecialType: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchTimeframe: AutocompleteOption | null
  setSearchTimeframe: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchDifficultyLevel: AutocompleteOption | null
  setSearchDifficultyLevel: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchTitle: string
  setSearchTitle: Dispatch<SetStateAction<string>>
  searchMissionsList: (
    withLoading?: boolean,
    withNextToken?: boolean
  ) => Promise<boolean>
  resetFilters: () => void
  handleError: boolean
  setHandleError: Dispatch<SetStateAction<boolean>>
  targetError: boolean
  setTargetError: Dispatch<SetStateAction<boolean>>
  publishMission: () => Promise<boolean>
}

const MissionsContext = createContext<MissionsContextInterface>({
  loading: true,
  setLoading: () => {},
  updatingList: false,
  setUpdatingList: () => {},
  editMode: false,
  setEditMode: () => {},
  missionsList: [],
  setMissionsList: () => {},
  getMissionsList: () => {},
  doneChanges: false,
  cancelChanges: () => {},
  currentMission: null,
  setCurrentMission: () => {},
  getCurrentMission: async () => true,
  preChangesCurrentMission: null,
  addTranslation: () => {},
  upsertMissionParent: async () => true,
  hasError: false,
  translationsErrors: [],
  setTranslationsErrors: () => {},
  upsertMissionDocument: async () => true,
  copyDetailsFromDefault: () => {},
  createMission: async () => true,
  createMissionDocument: async () => true,
  missionsListNextToken: null,
  loadMoreMissions: async () => true,
  hasSearched: false,
  setHasSearched: () => {},
  searchLang: null,
  setSearchLang: () => {},
  searchStage: null,
  setSearchStage: () => {},
  searchSuspended: null,
  setSearchSuspended: () => {},
  searchType: null,
  setSearchType: () => {},
  searchSpecialType: null,
  setSearchSpecialType: () => {},
  searchTimeframe: null,
  setSearchTimeframe: () => {},
  searchDifficultyLevel: null,
  setSearchDifficultyLevel: () => {},
  searchTitle: "",
  setSearchTitle: () => {},
  searchMissionsList: async () => true,
  resetFilters: () => {},
  handleError: false,
  setHandleError: () => {},
  targetError: false,
  setTargetError: () => {},
  publishMission: async () => true,
})

const MissionsController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // states
  const [loading, setLoading] = useState<boolean>(true) // loading
  const [updatingList, setUpdatingList] = useState<boolean>(false) // to show loader
  const [editMode, setEditMode] = useState<boolean>(true) // if all page should be disabled or not
  const [missionsList, setMissionsList] = useState<Mission[]>([]) // list of all missions
  const [doneChanges, setDoneChanges] = useState<boolean>(false) // if user has done changes
  const [currentMission, setCurrentMission] = useState<Mission>() // current mission
  const [preChangesCurrentMission, setPreChangesCurrentMission] =
    useState<Mission>() // fetched current mission
  const [missionsListNextToken, setMissionsListNextToken] = useState<
    string | null
  >(null) // next token for missions list
  const [hasSearched, setHasSearched] = useState<boolean>(false) // if user has searched or not

  // search states
  const [searchLang, setSearchLang] = useState<AutocompleteOption | null>(null)
  const [searchStage, setSearchStage] = useState<AutocompleteOption | null>(
    null
  )
  const [searchSuspended, setSearchSuspended] = useState<boolean | null>(null)
  const [searchType, setSearchType] = useState<AutocompleteOption | null>(null)
  const [searchSpecialType, setSearchSpecialType] =
    useState<AutocompleteOption | null>(null)
  const [searchTimeframe, setSearchTimeframe] =
    useState<AutocompleteOption | null>(null)
  const [searchDifficultyLevel, setSearchDifficultyLevel] =
    useState<AutocompleteOption | null>(null)
  const [searchTitle, setSearchTitle] = useState<string>("")

  // errors for mission edit
  const [handleError, setHandleError] = useState<boolean>(false)
  const [targetError, setTargetError] = useState<boolean>(false)
  const [translationsErrors, setTranslationsErrors] = useState<
    { lang: string; hasErrors: boolean }[]
  >([]) // errors array for translations
  const hasError: boolean =
    handleError ||
    targetError ||
    translationsErrors.filter((item) => item.hasErrors).length !== 0 // if there are errors or not

  // reset all errors
  const resetErrors = () => {
    setHandleError(false)
    setTargetError(false)
    const newTranslationsErrors: { lang: string; hasErrors: boolean }[] = []
    currentMission.document.items.forEach((item) => {
      newTranslationsErrors.push({ lang: item.lang, hasErrors: false })
    })
    setTranslationsErrors(newTranslationsErrors)
  }

  // queries
  const [missionCatalogsStagedListQuery] = useLazyQuery(
    missionCatalogsStagedList
  )
  const [missionCatalogsSearchQuery] = useLazyQuery(missionCatalogsSearch)
  const [missionCatalogStagedGetQuery] = useLazyQuery(missionCatalogStagedGet)

  // mutations
  const [missionCatalogUpsertMutation] = useMutation(missionCatalogUpsert)
  const [upsertDocumentMissionMutation] = useMutation(upsertDocumentMission)
  const [missionCatalogPublishMutation] = useMutation(missionCatalogPublish)

  // get missions list
  const getMissionsList = useCallback(
    async (withLoading: boolean = true) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter((item) => item.name === "Missions")[0].loading = true
        setPanelStatus([...panelStatus])
      }

      try {
        logger(
          Status.Api,
          "QUERY missionCatalogsStagedList",
          missionCatalogsStagedList
        )
        const { data } = await missionCatalogsStagedListQuery({
          variables: {
            input: {
              limit: Math.round(window.innerHeight / 74) + 10,
            },
          },
          fetchPolicy: "no-cache",
        })
        logger(
          Status.Info,
          "missions list",
          data.missionCatalogsStagedList.items
        )

        setMissionsList(data.missionCatalogsStagedList.items)
        setMissionsListNextToken(data.missionCatalogsStagedList.nextToken)

        setHasSearched(false)
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Missions")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "Missions")[0].loading =
          false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `missionCatalogsStagedList`, e.message)
        }
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Missions")[0].status =
          "error"
        panelStatus.filter((item) => item.name === "Missions")[0].loading =
          false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // load more missions
  const loadMoreMissions = async () => {
    try {
      logger(
        Status.Api,
        "QUERY missionCatalogsStagedList",
        missionCatalogsStagedList
      )
      const { data } = await missionCatalogsStagedListQuery({
        variables: {
          input: {
            limit: Math.round(window.innerHeight / 74) + 10,
            nextToken: missionsListNextToken,
          },
        },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "missions list", [
        ...missionsList,
        ...data.missionCatalogsStagedList.items,
      ])

      setMissionsList([
        ...missionsList,
        ...data.missionCatalogsStagedList.items,
      ])
      setMissionsListNextToken(data.missionCatalogsStagedList.nextToken)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `missionCatalogsStagedList`, e.message)
      }
      return false
    }
  }

  // search missions
  const searchMissionsList = async (
    withLoading = true,
    withNextToken = false
  ) => {
    if (withLoading) {
      setUpdatingList(true)
    }

    try {
      const input: {
        limit: number
        title?: string
        lang?: string
        stage?: string
        suspended?: boolean
        type?: string
        specialType?: string
        timeframe?: string
        difficultyLevel?: string
        nextToken?: string
      } = {
        limit: Math.round(window.innerHeight / 74) + 10,
        title: searchTitle,
        lang: searchLang ? searchLang.id : null,
        stage: searchStage ? searchStage.id : null,
        suspended: searchSuspended,
        type: searchType ? searchType.id : null,
        specialType: searchSpecialType ? searchSpecialType.id : null,
        timeframe: searchTimeframe ? searchTimeframe.id : null,
        difficultyLevel: searchDifficultyLevel
          ? searchDifficultyLevel.id
          : null,
      }

      if (hasSearched && withNextToken) {
        input.nextToken = missionsListNextToken
      }

      logger(Status.Api, "QUERY missionCatalogsSearch", missionCatalogsSearch)
      const { data } = await missionCatalogsSearchQuery({
        variables: {
          input: input,
        },
        fetchPolicy: "no-cache",
      })

      if (input.nextToken) {
        logger(Status.Info, "missions list", [
          ...missionsList,
          ...data.missionCatalogsSearch.items,
        ])

        setMissionsList([...missionsList, ...data.missionCatalogsSearch.items])
        setMissionsListNextToken(data.missionCatalogsSearch.nextToken)
      } else {
        logger(Status.Info, "missions list", data.missionCatalogsSearch.items)

        setMissionsList(data.missionCatalogsSearch.items)
        setMissionsListNextToken(data.missionCatalogsSearch.nextToken)
      }

      setHasSearched(true)
      setUpdatingList(false)
      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `missionCatalogsSearch`, e.message)
      }
      setUpdatingList(false)
      return false
    }
  }

  // get current mission
  const getCurrentMission = useCallback(
    async (missionId: string) => {
      try {
        logger(
          Status.Api,
          "QUERY missionCatalogStagedGet",
          missionCatalogStagedGet
        )
        const { data } = await missionCatalogStagedGetQuery({
          variables: { input: { id: missionId } },
          fetchPolicy: "no-cache",
        })
        logger(
          Status.Info,
          `mission ${missionId}`,
          data.missionCatalogStagedGet
        )

        setCurrentMission(data.missionCatalogStagedGet)
        setPreChangesCurrentMission(deepCopy(data.missionCatalogStagedGet))
        data.missionCatalogStagedGet.document.items.forEach(
          (item: Document) => {
            translationsErrors.push({ lang: item.lang, hasErrors: false })
          }
        )
        setTranslationsErrors([...translationsErrors])

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `missionCatalogStagedGet`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // upsert mission parent
  const upsertMissionParent = async (suspended: boolean) => {
    try {
      const input = {
        id: currentMission.id,
        handle: currentMission.handle,
        image: currentMission.image,
        projectId: currentMission.projectId,
        type: currentMission.type,
        specialType: currentMission.specialType,
        teamId: currentMission.team.id,
        difficultyLevel: currentMission.difficultyLevel,
        timeframe: currentMission.timeframe,
        target: currentMission.target,
        suspended: suspended,
      }

      logger(Status.Api, "MUTATION missionCatalogUpsert", missionCatalogUpsert)
      const { data } = await missionCatalogUpsertMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `mission ${data.missionCatalogUpsert.id} upserted`,
        data.missionCatalogUpsert
      )

      setCurrentMission((current) => {
        return { ...data.missionCatalogUpsert, document: current.document }
      })
      setPreChangesCurrentMission((current) => {
        return {
          ...deepCopy(data.missionCatalogUpsert),
          document: current.document,
        }
      })

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `missionCatalogUpsert`, e.message)
      }
      return false
    }
  }

  // upsert mission document
  const upsertMissionDocument = async (publish = false) => {
    try {
      // parse data for input
      const currentMissionCopy = deepCopy(currentMission)
      currentMissionCopy.document.items.forEach((item: any) => {
        item.default = item.isDefault
        delete item.isDefault
        delete item.type
        delete item.updatedAt
        delete item.parentId
        delete item.__typename
      })

      const input = {
        missionCatalogDocumentItems: currentMissionCopy.document.items,
        parentId: `#id#${currentMissionCopy.id}#stage#${
          publish ? "PUBLISHED" : "DRAFT"
        }`,
        type: "MissionCatalog",
      }

      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentMission)
      const { data } = await upsertDocumentMissionMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `mission ${currentMission.id} document upserted`,
        data.documentUpsert
      )

      setCurrentMission((current) => {
        return { ...current, document: data.documentUpsert }
      })
      setPreChangesCurrentMission((current) => {
        return { ...current, document: deepCopy(data.documentUpsert) }
      })

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // publish mission
  const publishMission = async () => {
    try {
      logger(
        Status.Api,
        "MUTATION missionCatalogPublish",
        missionCatalogPublish
      )
      await Promise.all([
        missionCatalogPublishMutation({
          variables: { input: { id: currentMission.id } },
        }),
        upsertMissionDocument(true),
      ])
      logger(Status.Info, `mission ${currentMission.id} published`)

      // update current mission locally
      setCurrentMission((current) => {
        return { ...current, stage: Stage.PUBLISHED }
      })
      setPreChangesCurrentMission((current) => {
        return { ...current, stage: Stage.PUBLISHED }
      })

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `missionCatalogPublish`, e.message)
      }
      return false
    }
  }

  // create mission
  const createMission = async (input: {
    handle: string
    projectId?: string
    target: number
    type: MissionType
    timeframe: MissionTimeframe
    difficultyLevel: MissionDifficultyLevel
    specialType?: MissionSpecialType
    teamId?: string
  }) => {
    try {
      logger(Status.Api, "MUTATION missionCatalogUpsert", missionCatalogUpsert)
      const { data } = await missionCatalogUpsertMutation({
        variables: { input: input },
      })
      logger(Status.Info, `mission created`, data.missionCatalogUpsert)

      return data.missionCatalogUpsert.id
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `missionCatalogUpsert`, e.message)
      }
      return false
    }
  }

  // create mission document
  const createMissionDocument = async (input: {
    parentId: string
    type: string
    missionCatalogDocumentItems: {
      default: boolean
      lang: string
      title: string
    }[]
  }) => {
    try {
      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentMission)
      const { data } = await upsertDocumentMissionMutation({
        variables: { input: input },
      })
      logger(Status.Info, `document created`, data.documentUpsert)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // add translation
  const addTranslation = (translationToAdd: string) => {
    const documentToAdd = {
      isDefault: false,
      lang: translationToAdd,
      title: "Title",
      type: "MissionCatalog",
    }

    translationsErrors.push({
      lang: translationToAdd,
      hasErrors: false,
    })
    setTranslationsErrors([...translationsErrors])

    currentMission.document.items.push(documentToAdd)
    setCurrentMission({ ...currentMission })
  }

  // copy details from default translation
  const copyDetailsFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = deepCopy(
      currentMission.document.items.filter((item) => item.isDefault)[0]
    )

    currentMission.document.items[itemToCopyToIndex].title = defaultItem.title

    setCurrentMission({ ...currentMission })
  }

  // cancel changes
  const cancelChanges = () => {
    setCurrentMission(deepCopy(preChangesCurrentMission))
    resetErrors()
  }

  // check if user has done changes
  useEffect(() => {
    if (currentMission) {
      const currentMissionCopy = deepCopy(currentMission)
      const preChangesCurrentMissionCopy = deepCopy(preChangesCurrentMission)

      delete currentMissionCopy.team.document
      delete currentMissionCopy.team.__typename
      delete preChangesCurrentMissionCopy.team.document
      delete preChangesCurrentMissionCopy.team.__typename

      if (
        JSON.stringify(currentMissionCopy) !==
        JSON.stringify(preChangesCurrentMissionCopy)
      ) {
        setDoneChanges(true)
      } else {
        setDoneChanges(false)
      }
    }
  }, [currentMission])

  // reset list filters
  const resetFilters = () => {
    setSearchLang(null)
    setSearchStage(null)
    setSearchSuspended(null)
    setSearchType(null)
    setSearchSpecialType(null)
    setSearchTimeframe(null)
    setSearchDifficultyLevel(null)
    setSearchTitle("")
  }

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter((item) => item.name === "Missions")[0].updateFunction =
      getMissionsList
  }, [])

  return (
    <MissionsContext.Provider
      value={{
        loading,
        setLoading,
        updatingList,
        setUpdatingList,
        editMode,
        setEditMode,
        missionsList,
        setMissionsList,
        getMissionsList,
        doneChanges,
        cancelChanges,
        currentMission,
        setCurrentMission,
        getCurrentMission,
        preChangesCurrentMission,
        addTranslation,
        upsertMissionParent,
        hasError,
        translationsErrors,
        setTranslationsErrors,
        upsertMissionDocument,
        copyDetailsFromDefault,
        createMission,
        createMissionDocument,
        missionsListNextToken,
        loadMoreMissions,
        hasSearched,
        setHasSearched,
        searchLang,
        setSearchLang,
        searchStage,
        setSearchStage,
        searchSuspended,
        setSearchSuspended,
        searchType,
        setSearchType,
        searchSpecialType,
        setSearchSpecialType,
        searchTimeframe,
        setSearchTimeframe,
        searchDifficultyLevel,
        setSearchDifficultyLevel,
        searchTitle,
        setSearchTitle,
        searchMissionsList,
        resetFilters,
        handleError,
        setHandleError,
        targetError,
        setTargetError,
        publishMission,
      }}
    >
      {children}
    </MissionsContext.Provider>
  )
}

export { MissionsController, MissionsContext }
