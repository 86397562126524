import {
  Card,
  CardContent,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useContext, useState } from "react"
import { TeamsContext } from "../../controllers/teams"
import TeamGroup from "../../models/teamGroup"
import CopyToClipboardButton from "../global/copyToClipboardButton"
import { LoadingButton } from "@mui/lab"
import { ActivationCodesContext } from "../../controllers/activationCodes"
import { CheckRounded, DeleteRounded, EditRounded } from "@mui/icons-material"
import {
  ActivationCodePolicy,
  ActivationCodeType,
} from "../../services/config/enum"

const GroupCard = ({
  group,
  index,
  groupsListMemo,
}: {
  group: TeamGroup
  index: number
  groupsListMemo: TeamGroup[]
}) => {
  const { currentTeam, setCurrentTeam, editMode } = useContext(TeamsContext)
  const { createActivationCode } = useContext(ActivationCodesContext)

  // states
  const [loading, setLoading] = useState<boolean>(false)
  const [editing, setEditing] = useState<boolean>(false)

  return (
    <Card
      id={group.groupId}
      variant="outlined"
      sx={{
        boxShadow: "none",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius:
          index === groupsListMemo.length - 1 ? undefined : 0,
        borderBottomRightRadius:
          index === groupsListMemo.length - 1 ? undefined : 0,
        borderTop: index === 0 ? undefined : "none",
        transition: "200ms",
      }}
    >
      <CardContent
        style={{
          height: 68,
          paddingTop: 12,
          paddingBottom: 12,
          transition: "150ms",
        }}
      >
        <Stack
          spacing={1}
          style={{ position: "relative" }}
          justifyContent="center"
        >
          {editing ? (
            <TextField
              fullWidth
              size="small"
              value={group.name}
              onChange={(e) => {
                currentTeam.groups.find(
                  (item) => item.groupId === group.groupId
                ).name = e.target.value
                setCurrentTeam({ ...currentTeam })
              }}
              disabled={!editMode}
              style={{
                width: "calc(100% - 400px)",
                marginTop: -5,
              }}
            />
          ) : (
            <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
              {group.name}
            </Typography>
          )}
          {group.groupId.includes("temp") ? null : (
            <Stack
              direction="row"
              alignItems="center"
              style={{ marginTop: 0, marginLeft: -4 }}
            >
              <CopyToClipboardButton textToCopy={group.groupId} />
              <Typography
                variant="caption"
                style={{
                  fontSize: 10,
                  color: "gray",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {group.groupId}
              </Typography>
            </Stack>
          )}
          {group.groupId.includes("temp") ? null : (
            <div
              dangerouslySetInnerHTML={{
                __html: `
        <p style="line-height: 1.3"><span style="font-weight: 500; font-size: 0.9rem">${
          group.userCount
        }</span> <span style="font-weight: 300; font-size: 0.9rem">members</span> | <span style="font-weight: 500; font-size: 0.9rem">${
                  currentTeam.userCount
                    ? Math.round(
                        (group.userCount / currentTeam.userCount) * 100
                      )
                    : 0
                }</span>%</p>`,
              }}
              style={{
                fontWeight: 400,
                fontSize: "0.7rem",
                lineHeight: 1.1,
                marginTop: 8,
                position: "absolute",
                right: 105,
                top: -5,
              }}
            />
          )}
          {!group.groupId.includes("temp") ? (
            <Stack
              direction="row"
              style={{
                height: 21.5,
                position: "absolute",
                right: 105,
                top: 15,
              }}
              alignItems="center"
            >
              <Typography
                style={{
                  fontSize: "0.9rem",
                  fontWeight: 300,
                }}
              >
                {editing &&
                !currentTeam.accessCodes.find(
                  (item) => item.groupId === group.groupId
                )
                  ? "Activation code:"
                  : currentTeam.accessCodes.find(
                      (item) => item.groupId === group.groupId
                    )
                  ? "Activation code:"
                  : "Activation code: none"}
              </Typography>
              {currentTeam.accessCodes.find(
                (item) => item.groupId === group.groupId
              ) ? (
                <Stack direction="row" style={{ height: 21.59 }} spacing={0.5}>
                  <Typography
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: 400,
                      marginLeft: 5,
                    }}
                  >
                    {
                      currentTeam.accessCodes.find(
                        (item) => item.groupId === group.groupId
                      ).code
                    }
                  </Typography>
                  <CopyToClipboardButton
                    textToCopy={
                      currentTeam.accessCodes.find(
                        (item) => item.groupId === group.groupId
                      ).code
                    }
                  />
                </Stack>
              ) : null}
              {editing &&
              !currentTeam.accessCodes.find(
                (item) => item.groupId === group.groupId
              ) ? (
                <LoadingButton
                  style={{ maxHeight: "100%" }}
                  loading={loading}
                  onClick={async () => {
                    setLoading(true)

                    const result = await createActivationCode(
                      ActivationCodeType.TeamActivationCode,
                      ActivationCodePolicy.IterationUnlimited,
                      null,
                      { teamId: currentTeam.id, groupId: group.groupId }
                    )
                    if (typeof result !== "boolean") {
                      currentTeam.accessCodes.push(result)
                      setCurrentTeam({ ...currentTeam })
                    }

                    setLoading(false)
                  }}
                >
                  Create
                </LoadingButton>
              ) : null}
            </Stack>
          ) : null}
          <IconButton
            size="small"
            style={{
              width: 40,
              height: 40,
              position: "absolute",
              right: 40,
              top: -5,
              transition: "150ms",
            }}
            onClick={() => {
              setEditing((current) => !current)
            }}
            disabled={!editMode}
          >
            {editing ? (
              <CheckRounded
                style={{
                  width: 24,
                }}
              />
            ) : (
              <EditRounded
                style={{
                  width: 20,
                }}
              />
            )}
          </IconButton>
          <IconButton
            size="small"
            color="error"
            style={{
              width: 40,
              height: 40,
              position: "absolute",
              right: -11,
              top: -5,
              transition: "150ms",
            }}
            onClick={() => {
              const indexToRemove = currentTeam.groups.findIndex(
                (item) => item.groupId === group.groupId
              )
              currentTeam.groups.splice(indexToRemove, 1)
              setCurrentTeam({ ...currentTeam })
            }}
            disabled={!editMode}
          >
            <DeleteRounded
              style={{
                width: 24,
              }}
            />
          </IconButton>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default GroupCard
