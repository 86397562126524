import { Cancel, Search } from "@mui/icons-material"
import {
  Autocomplete,
  Button,
  Chip,
  Divider,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material"
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useContext,
  useEffect,
  useState,
} from "react"
import { MediaContext } from "../../controllers/media"
import { MediaSize } from "../../services/config/enum"
import {
  capitalizeFirstCharacter,
  renderOption,
  renderTags,
} from "../../services/utilities/utility"

interface AutocompleteOption {
  label: string
  id: string
}

const MediaListTopBar = ({
  loading,
  textFieldValue,
  textFieldPlaceholder,
  addButtonLabel,
  addButtonOnClick,
  textFieldOnChange,
  textFieldOnKeyDown,
  cancelButtonOnClick,
  searchButtonOnClick,
  forDialog = false,
  forDialogFilters,
  disableCategoryFilter = false,
}: {
  loading: boolean
  textFieldValue: string
  textFieldPlaceholder: string
  addButtonLabel: string
  textFieldOnChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  textFieldOnKeyDown: KeyboardEventHandler<HTMLDivElement>
  cancelButtonOnClick: MouseEventHandler<HTMLDivElement>
  searchButtonOnClick: MouseEventHandler<HTMLButtonElement>
  addButtonOnClick: MouseEventHandler<HTMLButtonElement>
  forDialog?: boolean
  forDialogFilters?: { category?: string; size?: MediaSize[] }
  disableCategoryFilter?: boolean
}) => {
  const {
    updatingList,
    setUpdatingList,
    searchSize,
    setSearchSize,
    searchCategory,
    setSearchCategory,
    getMediaList,
    searchMediaList,
    searchMediaTags,
    setSearchMediaTags,
    mediaTagsList,
    mediaTagsLoading,
    mediaCategoriesList,
  } = useContext(MediaContext)

  // search when filters update
  const [firstRender, setFirstRender] = useState<boolean>(true)

  useEffect(() => {
    if (!firstRender) {
      if (
        !textFieldValue.length &&
        !searchSize &&
        !searchCategory &&
        !searchMediaTags.length
      ) {
        setUpdatingList(true)
        getMediaList(false)
      } else {
        searchMediaList({
          title: textFieldValue,
          size: searchSize,
          category: searchCategory,
          mediaTags: searchMediaTags,
        })
      }
    } else {
      setFirstRender(false)
    }
  }, [searchSize, searchCategory, searchMediaTags])

  // set filters if forDialog is true
  useEffect(() => {
    if (forDialog && forDialogFilters) {
      if (forDialogFilters.category) {
        setSearchCategory({
          id: forDialogFilters.category,
          label:
            forDialogFilters.category === "actionGroup"
              ? "Series"
              : forDialogFilters.category === "actionGroupBadge"
              ? "Series Badge"
              : capitalizeFirstCharacter(forDialogFilters.category.toString()),
        })
      }
      if (forDialogFilters.size && forDialogFilters.size.length > 0) {
        let newSizeArray: AutocompleteOption[] = []
        for (let i = 0; i < forDialogFilters.size.length; i++) {
          newSizeArray.push({
            id: forDialogFilters.size[i],
            label: forDialogFilters.size[i].toString().toUpperCase(),
          })
        }
        setSearchSize(newSizeArray)
      }
    }
  }, [])

  return (
    <Stack
      style={{
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 4,
      }}
      spacing={1}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          disabled={loading || updatingList}
          size="small"
          fullWidth
          id="outlined-basic"
          placeholder={textFieldPlaceholder}
          variant="outlined"
          value={textFieldValue}
          onChange={textFieldOnChange}
          onKeyDown={textFieldOnKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: textFieldValue.length > 0 && (
              <InputAdornment position="start">
                <div
                  style={{
                    marginTop: 6,
                    marginRight: -6,
                    cursor: "pointer",
                  }}
                  onClick={loading ? () => {} : cancelButtonOnClick}
                >
                  <Cancel style={{ width: 18 }} />
                </div>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="outlined"
          disabled={textFieldValue.length < 3 || loading || updatingList}
          onClick={searchButtonOnClick}
          style={{ height: 40, minWidth: 74 }}
        >
          Search
        </Button>
        {!forDialog && <Divider orientation="vertical" flexItem />}
        <Button
          variant="contained"
          disabled={loading}
          style={{
            whiteSpace: "nowrap",
            minWidth: "max-content",
            height: 40,
          }}
          onClick={addButtonOnClick}
        >
          {addButtonLabel}
        </Button>
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        style={{ height: 50, overflowX: "scroll" }}
        className="filters-stack"
      >
        <Autocomplete
          style={{ minWidth: 110 }}
          disabled={
            loading ||
            updatingList ||
            searchSize !== null ||
            disableCategoryFilter
          }
          fullWidth
          size="small"
          value={searchCategory ?? { label: "All", id: null }}
          disablePortal
          isOptionEqualToValue={(
            option: AutocompleteOption,
            value: AutocompleteOption
          ) => option.id === value.id}
          options={[
            { label: "All", id: null },
            ...mediaCategoriesList.map((item) => {
              return {
                label:
                  item === "actionGroup"
                    ? "Series"
                    : item === "actionGroupBadge"
                    ? "Series Badge"
                    : capitalizeFirstCharacter(item.toString()),
                id: item,
              }
            }),
          ]}
          renderInput={(params) => <TextField {...params} label="Category" />}
          onChange={(e: any, newValue: AutocompleteOption | null) => {
            if (newValue && newValue.id !== "content") {
              setSearchSize(null)
            }
            if (newValue && newValue.id !== null) {
              setSearchCategory(newValue)
            } else {
              setSearchCategory(null)
            }
          }}
          disableClearable={!searchCategory}
          renderOption={renderOption}
          renderTags={renderTags}
        />
        <Autocomplete
          style={{ minWidth: 110 }}
          disabled={loading || updatingList || forDialog}
          fullWidth
          size="small"
          value={searchSize ? searchSize : []}
          multiple
          disablePortal
          isOptionEqualToValue={(
            option: AutocompleteOption,
            value: AutocompleteOption
          ) => option.id === value.id}
          options={[
            { label: "XS", id: MediaSize.xs },
            { label: "S", id: MediaSize.s },
            { label: "M", id: MediaSize.m },
            { label: "L", id: MediaSize.l },
            { label: "XL", id: MediaSize.xl },
            { label: "XXL", id: MediaSize.xxl },
          ]}
          renderInput={(params) => <TextField {...params} label="Sizes" />}
          onChange={(e: any, newValues: AutocompleteOption[] | null) => {
            if (newValues.length) {
              setSearchSize(newValues)
              setSearchCategory({ label: "Content", id: "content" })
            } else {
              setSearchSize(null)
              setSearchCategory(null)
            }
          }}
          renderOption={renderOption}
          renderTags={renderTags}
        />
        <Autocomplete
          style={{ minWidth: 110 }}
          disabled={loading || updatingList || forDialog}
          fullWidth
          loading={mediaTagsLoading}
          size="small"
          value={searchMediaTags}
          multiple
          disablePortal
          options={mediaTagsList}
          getOptionLabel={(option) => {
            if (option === "1stilllife") {
              return "1. Still Life"
            } else if (option === "2composition") {
              return "2. Composition"
            } else {
              return capitalizeFirstCharacter(option)
            }
          }}
          renderInput={(params) => <TextField {...params} label="Tags" />}
          onChange={(e, newValues: string[]) => {
            setSearchMediaTags(newValues)
          }}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              {option === "1stilllife"
                ? "1. Still life"
                : option === "2composition"
                ? "2. Composition"
                : capitalizeFirstCharacter(option)}
            </li>
          )}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                size="small"
                key={option}
                label={
                  option === "1stilllife"
                    ? "1. Still life"
                    : option === "2composition"
                    ? "2. Composition"
                    : capitalizeFirstCharacter(option)
                }
              />
            ))
          }}
        />
      </Stack>
    </Stack>
  )
}

export default MediaListTopBar
