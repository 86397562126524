import { useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import Episode from "../models/episode"
import { upsertDailyEpisode } from "../services/graphql/mutations"
import { listDailyEpisode } from "../services/graphql/queries"
import {
  dateToStringForQueries,
  deepCopy,
  logger,
  Status,
} from "../services/utilities/utility"
import { MainContext } from "./main"

interface DailyEpisodesContextInterface {
  loading: boolean
  updatingList: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  calculateCurrentDaysList: () => void
  currentMonth: number
  setCurrentMonth: Dispatch<SetStateAction<number>>
  currentYear: number
  setCurrentYear: Dispatch<SetStateAction<number>>
  daysList: Date[]
  dailyEpisodesList: { sk: string; episode: Episode }[]
  addDailyEpisode: (date: string, episodeId: string) => Promise<boolean>
}

const DailyEpisodesContext = createContext<DailyEpisodesContextInterface>({
  loading: true,
  updatingList: false,
  setLoading: () => {},
  editMode: false,
  setEditMode: () => {},
  calculateCurrentDaysList: () => {},
  currentMonth: 0,
  setCurrentMonth: () => {},
  currentYear: 0,
  setCurrentYear: () => {},
  daysList: [],
  dailyEpisodesList: [],
  addDailyEpisode: async () => true,
})

const DailyEpisodesController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  const [loading, setLoading] = useState<boolean>(true) // loading
  const [updatingList, setUpdatingList] = useState<boolean>(false) // to show loader
  const [editMode, setEditMode] = useState<boolean>(true) // if all page should be disabled or not
  const [currentMonth, setCurrentMonth] = useState<number>(
    new Date().getMonth()
  ) // current selected month
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear()
  ) // current selected year
  const [daysList, setDaysList] = useState<Date[]>([]) // all dates of current month
  const [dailyEpisodesList, setDailyEpisodesList] = useState<
    { sk: string; episode: Episode }[]
  >([]) // list of all daily episodes for current month
  const [getListTimeoutId, setGetListTimeoutId] = useState<any>() // timeout for list get
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true) // check if it's first render or not

  // queries
  const [listDailyEpisodeQuery] = useLazyQuery(listDailyEpisode)

  // mutations
  const [upsertDailyEpisodeMutation] = useMutation(upsertDailyEpisode)

  // get daily episodes list
  const getDailyEpisodesList = useCallback(
    async (from: string, to: string) => {
      panelStatus.filter((item) => item.name === "Daily Episodes")[0].loading =
        true
      setPanelStatus([...panelStatus])

      try {
        logger(Status.Api, "QUERY listDailyEpisode", listDailyEpisode)
        const { data } = await listDailyEpisodeQuery({
          variables: { from: from, to: to },
        })
        logger(
          Status.Info,
          `daily episodes from ${from} to ${to}`,
          data.listDailyEpisode.items
        )

        setDailyEpisodesList(data.listDailyEpisode.items)

        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Daily Episodes")[0].status =
          "success"
        panelStatus.filter(
          (item) => item.name === "Daily Episodes"
        )[0].loading = false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `listDailyEpisode`, e.message)
        }
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Daily Episodes")[0].status =
          "error"
        panelStatus.filter(
          (item) => item.name === "Daily Episodes"
        )[0].loading = false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // calculate current days list
  const calculateCurrentDaysList = () => {
    setUpdatingList(true)

    let firstDay = new Date(currentYear, currentMonth, 1)
    let firstDayForQuery = new Date(currentYear, currentMonth, 1)
    let lastDay = new Date(currentYear, currentMonth + 1, 0)

    let daysListToSet = [new Date(firstDay)]
    while (firstDay.toLocaleDateString() !== lastDay.toLocaleDateString()) {
      firstDay.setDate(firstDay.getDate() + 1)
      daysListToSet.push(new Date(firstDay))
    }

    setDaysList(daysListToSet)

    if (getListTimeoutId) {
      clearTimeout(getListTimeoutId)
    }

    if (isFirstRender) {
      setIsFirstRender(false)
      getDailyEpisodesList(
        dateToStringForQueries(firstDayForQuery),
        dateToStringForQueries(lastDay)
      )
    } else {
      let id = setTimeout(() => {
        getDailyEpisodesList(
          dateToStringForQueries(firstDayForQuery),
          dateToStringForQueries(lastDay)
        )
      }, 1000)
      setGetListTimeoutId(id)
    }
  }

  // upsert daily episode
  const addDailyEpisode = async (date: string, episodeId: string) => {
    try {
      logger(Status.Api, "MUTATION upsertDailyEpisode", upsertDailyEpisode)
      const { data } = await upsertDailyEpisodeMutation({
        variables: { input: { date: date, episodeId: episodeId } },
      })
      logger(
        Status.Info,
        `daily episodes ${episodeId} upserted`,
        data.upsertDailyEpisode
      )

      let dailyEpisodesListCopy = deepCopy(dailyEpisodesList)
      dailyEpisodesListCopy = dailyEpisodesListCopy.filter(
        (item: any) => item.sk !== date
      )
      dailyEpisodesListCopy.push(data.upsertDailyEpisode)
      setDailyEpisodesList(dailyEpisodesListCopy)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `upsertDailyEpisode`, e.message)
      }
      return false
    }
  }

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter(
      (item) => item.name === "Daily Episodes"
    )[0].updateFunction = calculateCurrentDaysList
  }, [])

  // recalculate days list when currentMonth changes
  useEffect(() => {
    if (!isFirstRender) {
      calculateCurrentDaysList()
    }
  }, [currentMonth])

  return (
    <DailyEpisodesContext.Provider
      value={{
        loading,
        updatingList,
        setLoading,
        editMode,
        setEditMode,
        calculateCurrentDaysList,
        currentMonth,
        setCurrentMonth,
        currentYear,
        setCurrentYear,
        daysList,
        dailyEpisodesList,
        addDailyEpisode,
      }}
    >
      {children}
    </DailyEpisodesContext.Provider>
  )
}

export { DailyEpisodesController, DailyEpisodesContext }
