import { Amplify } from "aws-amplify"
import { logger, Status } from "../utilities/utility"
import {
  fetchAuthSession,
  signIn as amplifySignIn,
  confirmSignIn,
  signOut as amplifySignOut,
  fetchUserAttributes,
  getCurrentUser,
} from "aws-amplify/auth"

// amplify configuration
const environment = {
  Cognito: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolClientId: process.env.REACT_APP_USER_WEB_CLIENT_ID,
    scope: ["solar/partners.admin"],
  },
}

// configure amplify
const configureAmplify = () => {
  Amplify.configure({
    Auth: environment,
  })
}

// send code to user via email
const signIn = async (email: string) => {
  try {
    await amplifySignIn({
      username: email,
      options: {
        authFlowType: "CUSTOM_WITHOUT_SRP",
      },
    })

    return true
  } catch (e) {
    console.error(e)
    throw e
  }
}

// check code that user has entered
const answerCustomChallenge = async (code: string) => {
  await confirmSignIn({ challengeResponse: code })

  try {
    const session = await fetchAuthSession()
    logger(Status.Info, "session", session)

    return session
  } catch (e) {
    logger(Status.Warning, "apparently the user did not enter the right code")
    throw e
  }
}

// signout user and invalid token
const signOut = async () => {
  try {
    await amplifySignOut()
    return true
  } catch (e) {
    console.error(e)
    return "error"
  }
}

const checkAuth = async () => {
  await getCurrentUser()
}

const getUser = async () => {
  const currentUser = await fetchUserAttributes()
  return currentUser
}

export {
  configureAmplify,
  signIn,
  answerCustomChallenge,
  signOut,
  getUser,
  checkAuth,
}
