import { LoadingButton } from "@mui/lab"
import {
  Button,
  CardActions,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Stack,
  Typography,
  Dialog,
  TextField,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { DialogTransition } from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import { useParams } from "react-router-dom"
import ISODatePicker from "../global/isoDatePicker"
import { TeamsContext } from "../../controllers/teams"

const CreateContractDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
}) => {
  const { teamId } = useParams()
  const { createTeamContract } = useContext(TeamsContext)

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // loading
  const [loading, setLoading] = useState<boolean>(false)

  // configuration
  const [startDate, setStartDate] = useState<string>("")
  const [endDate, setEndDate] = useState<string>("")
  const [sellingPrice, setSellingPrice] = useState<number | null>(null)
  const [targetMembers, setTargetMembers] = useState<number | null>(null)

  // check for errors
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
      setError(true)
    } else {
      setError(false)
    }
  }, [startDate, endDate])

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  // slides
  const slides = [
    {
      label: "Configuration",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Configuration
          </Typography>
          <Stack direction="row" gap={2}>
            <ISODatePicker
              label="Start date"
              date={startDate}
              setDate={setStartDate}
              utc={false}
            />
            <ISODatePicker
              label="End date"
              date={endDate}
              setDate={setEndDate}
              error={error}
              utc={false}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <TextField
              fullWidth
              size="small"
              label="Selling price"
              type="number"
              value={sellingPrice ?? ""}
              onChange={(e) => {
                if (e.target.value) {
                  setSellingPrice(parseFloat(e.target.value))
                } else {
                  setSellingPrice(null)
                }
              }}
              error={sellingPrice !== null && sellingPrice < 0}
              helperText={
                sellingPrice !== null && sellingPrice < 0 ? "Invalid value" : ""
              }
            />
            <TextField
              fullWidth
              size="small"
              label="Target members"
              type="number"
              value={targetMembers ?? ""}
              onChange={(e) => {
                if (e.target.value) {
                  setTargetMembers(parseFloat(e.target.value))
                } else {
                  setTargetMembers(null)
                }
              }}
              error={targetMembers !== null && targetMembers < 0}
              helperText={
                targetMembers !== null && targetMembers < 0
                  ? "Invalid value"
                  : ""
              }
            />
          </Stack>
        </Stack>
      ),
    },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setStartDate("")
        setEndDate("")
        setSellingPrice(null)
        setTargetMembers(null)
        setError(false)
        setCurrentSlide(0)
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (
      startDate ||
      endDate ||
      sellingPrice !== null ||
      targetMembers !== null
    ) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [startDate, endDate, sellingPrice, targetMembers])

  // create contract
  const createNewContract = async () => {
    setLoading(true)

    const noErrors = await createTeamContract(
      teamId,
      startDate.split("T")[0],
      endDate.split("T")[0],
      sellingPrice,
      targetMembers
    )

    setLoading(false)
    if (noErrors) {
      setDialogOpen(false)
    }
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText="Create contract"
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            Create contract
          </Typography>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (!slides[currentSlide - 1]) {
                    if (showDiscardDialog) {
                      setDiscardDialogOpen(true)
                    } else {
                      handleDialogChange(e)
                    }
                  } else {
                    setCurrentSlide(currentSlide - 1)
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  (currentSlide === 0 && !startDate) ||
                  (currentSlide === 0 && !endDate) ||
                  error
                }
                onMouseDown={
                  !slides[currentSlide + 1]
                    ? createNewContract
                    : () => {
                        setCurrentSlide(currentSlide + 1)
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Save" : "Next"}
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={loading} />
    </Dialog>
  )
}

export default CreateContractDialog
