import { Chip, Stack, TableCell, TableRow, Typography } from "@mui/material"
import Action from "../../models/action"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import { useNavigate } from "react-router-dom"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import TitleTableCell from "../global/titleTableCell"
import { ActionsContext } from "../../controllers/actions"
import TranslationsStack from "../global/translationsStack"
import { calculateTimeElapsed } from "../../services/utilities/utility"
import ChipSelect from "../global/chipSelect"

const ActionsListRow = ({
  item,
  selectedActions = [],
  setSelectedActions,
  forDialog,
}: {
  item: Action
  selectedActions?: Action[]
  setSelectedActions?: Dispatch<SetStateAction<Action[]>>
  forDialog?: boolean
}) => {
  const navigate = useNavigate()
  const { cmdPressed, setAnimation } = useContext(MainContext)
  const { searchLang, upsertActionParent } = useContext(ActionsContext)

  // row loading
  const [loading, setLoading] = useState<boolean>(false)

  // chip popover open
  const [chipOpen, setChipOpen] = useState<boolean>(false)

  return (
    <TableRow
      key={item.id}
      style={{
        cursor: loading ? "default" : "pointer",
        backgroundColor: selectedActions.filter(
          (action: Action) => action.id === item.id
        )[0]
          ? "lightgray"
          : null,
        opacity: loading ? 0.4 : 1,
      }}
      hover={!loading}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
      onClick={() => {
        if (!loading && !chipOpen) {
          if (!forDialog) {
            if (cmdPressed) {
              window.open(`${window.location.href}/${item.id}`)
            } else {
              setAnimation(false)
              setTimeout(() => {
                navigate(`/actions/${item.id}`)
              }, 250)
            }
          } else {
            if (
              selectedActions.filter(
                (action: Action) => action.id === item.id
              )[0]
            ) {
              let indexToRemove = selectedActions.findIndex(
                (action: Action) => action.id === item.id
              )
              selectedActions.splice(indexToRemove, 1)
            } else {
              selectedActions.push(item)
            }
            setSelectedActions([...selectedActions])
          }
        }
      }}
    >
      <TableCell>
        <div
          style={{
            width: 42,
            height: 42,
            borderRadius: 4,
            backgroundImage: `url(${item.image ?? noImagePlaceholder})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </TableCell>
      <TitleTableCell
        title={
          searchLang &&
          item.document.items.find(
            (documentItem) => documentItem.lang === searchLang.id
          )
            ? item.document.items.find(
                (documentItem) => documentItem.lang === searchLang.id
              ).title
            : item.document.items.find(
                (documentItem) => documentItem.lang === "it"
              )
            ? item.document.items.find(
                (documentItem) => documentItem.lang === "it"
              ).title
            : item.document.items.find((documentItem) => documentItem.isDefault)
                .title
        }
        id={item.id}
      />
      <TableCell>
        <TranslationsStack documentItems={item.document.items} />
      </TableCell>
      <TableCell>
        {item.teamId === "team_default" ? (
          <Chip size="small" label="Standard" />
        ) : (
          <Chip
            size="small"
            label="Autolog"
            style={{
              backgroundColor: "#F1F7FE",
              color: "#479EF6",
              fontWeight: 600,
            }}
          />
        )}
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1}>
          <Chip
            color={
              item.savingMetrics[0].metrics.co2Saving ? "success" : "default"
            }
            label="💨"
            size="small"
          />
          <Chip
            color={
              item.savingMetrics[0].metrics.energySaving ? "success" : "default"
            }
            label="⚡"
            size="small"
          />
          <Chip
            color={
              item.savingMetrics[0].metrics.waterSaving ? "success" : "default"
            }
            label="💧"
            size="small"
          />
        </Stack>
      </TableCell>
      <TableCell>
        <ChipSelect
          label={item.featured ? "Yes" : "No"}
          color={item.featured ? "success" : "warning"}
          options={[
            { id: true, label: "Yes" },
            { id: false, label: "No" },
          ]}
          selectedOption={item.featured}
          setOpen={setChipOpen}
          onOptionClick={async (option) => {
            await upsertActionParent({
              id: item.id,
              handle: item.handle,
              image: item.image,
              featured: option as boolean,
              timesPerDay: item.timesPerDay,
              isRepeatable: item.isRepeatable,
              savingMetrics: item.savingMetrics,
              category: item.category,
            })
            return true
          }}
          disabled={loading}
          setLoading={setLoading}
        />
      </TableCell>
      <TableCell>
        <Chip
          size="small"
          label={item.category.name}
          style={{
            backgroundColor: item.category.backColor,
            color: item.category.foreColor,
          }}
        />
      </TableCell>
      <TableCell style={{ minWidth: 115 }}>
        <Typography variant="caption">
          {calculateTimeElapsed(item.updatedAt)}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default ActionsListRow
