import { useContext, useEffect, useState } from "react"
import { EpisodesContext } from "../../controllers/episodes"
import SlideConstraints from "../../models/slideConstraints"
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import {
  onQuizAnswerTextFieldChange,
  onQuizCorrectAnswerSelectChange,
  onQuizTextColorTextFieldChange,
  onQuizBackgroundChange,
  onQuizBoxBackColorChange,
  onQuizDecorChange,
  onQuizAnswerImageChange,
  checkTextColorOnBoxBackColorChangeQuiz,
} from "../../services/utilities/episodeUtility"
import {
  capitalizeFirstCharacter,
  deepCopy,
} from "../../services/utilities/utility"
import BigPreview from "../episode/bigPreview"
import ImagePicker from "../global/imagePicker"
import EpisodeSlideQuiz from "../../models/episodeSlideQuiz"
import { MediaSize } from "../../services/config/enum"
import HtmlEditorQuiz from "../episode/htmlEditorQuiz"
import {
  boxBackColors,
  textColors,
} from "../../services/config/colorsForSlides"
import { Slide } from "@aworld/app-preview"

const QuizSlide = ({
  slide,
  language,
  index,
  constraints,
  changesCancelled,
}: {
  slide: EpisodeSlideQuiz
  language: string
  index: number
  constraints: SlideConstraints
  changesCancelled: boolean
}) => {
  const {
    currentEpisode,
    setCurrentEpisode,
    slidesErrors,
    setSlidesErrors,
    editMode,
  } = useContext(EpisodesContext)

  // errors
  const [questionError, setQuestionError] = useState<boolean>(false)
  const [questionErrorType, setQuestionErrorType] = useState<string>("")
  const [answer1Error, setAnswer1Error] = useState<boolean>(false)
  const [answer2Error, setAnswer2Error] = useState<boolean>(false)
  const [answer3Error, setAnswer3Error] = useState<boolean>(false)
  const [correctAnswerError, setCorrectAnswerError] = useState<boolean>(false)
  const [image1Error, setImage1Error] = useState<boolean>(false)
  const [image2Error, setImage2Error] = useState<boolean>(false)
  const [image3Error, setImage3Error] = useState<boolean>(false)
  const [image4Error, setImage4Error] = useState<boolean>(false)

  // check errors
  const [isFirstRender, setisFirstRender] = useState<boolean>(true)

  useEffect(() => {
    if (isFirstRender) {
      setisFirstRender(false)

      // first errors check
      if (
        slide.question.replace(/(<([^>]+)>)/gi, "").length >
        constraints.text.maxSize
      ) {
        setQuestionError(true)
        setQuestionErrorType(
          "Maximum length is " + constraints.text.maxSize + " characters"
        )
      }
      if (
        slide.question.replace(/(<([^>]+)>)/gi, "").length <
        constraints.text.minSize
      ) {
        setQuestionError(true)
        setQuestionErrorType(
          "Minimum length is " + constraints.text.minSize + " characters"
        )
      }
      if (!slide.opt1.text.length) {
        setAnswer1Error(true)
      }
      if (!slide.opt2.text.length) {
        setAnswer2Error(true)
      }
      if (!slide[slide.correctAnswer].text.length) {
        setCorrectAnswerError(true)
      }
      if (slide.opt4 && slide.opt4.text.length && !slide.opt1.image) {
        setImage1Error(true)
      }
      if (slide.opt4 && slide.opt4.text.length && !slide.opt2.image) {
        setImage2Error(true)
      }
      if (slide.opt4 && slide.opt4.text.length && !slide.opt3.image) {
        setImage3Error(true)
      }
      if (slide.opt4 && slide.opt4.text.length && !slide.opt4.image) {
        setImage4Error(true)
      }

      return
    }

    let newSlidesErrors: {
      lang: string
      slides: { slideId: string; error: boolean }[]
    }[] = deepCopy(slidesErrors)

    if (
      !questionError &&
      !answer1Error &&
      !answer2Error &&
      !answer3Error &&
      !correctAnswerError &&
      !image1Error &&
      !image2Error &&
      !image3Error &&
      !image4Error
    ) {
      newSlidesErrors
        .filter((item) => item.lang === language)[0]
        .slides.filter((item) => item.slideId === slide.id)[0].error = false
      setSlidesErrors(newSlidesErrors)
    } else {
      newSlidesErrors
        .filter((item) => item.lang === language)[0]
        .slides.filter((item) => item.slideId === slide.id)[0].error = true
      setSlidesErrors(newSlidesErrors)
    }
  }, [
    questionError,
    answer1Error,
    answer2Error,
    answer3Error,
    correctAnswerError,
    image1Error,
    image2Error,
    image3Error,
    image4Error,
  ])

  // changes cancelled
  useEffect(() => {
    if (changesCancelled) {
      setQuestionError(false)
      setQuestionErrorType("")
      setAnswer1Error(false)
      setAnswer2Error(false)
      setAnswer3Error(false)
      setCorrectAnswerError(false)
      setImage1Error(false)
      setImage2Error(false)
      setImage3Error(false)
      setImage4Error(false)
    }
  }, [changesCancelled])

  // big preview
  const [isBigPreviewOpen, setIsBigPreviewOpen] = useState<boolean>(false)

  // check errors on correct answers
  useEffect(() => {
    if (!slide[slide.correctAnswer].text.length) {
      setCorrectAnswerError(true)
    } else {
      setCorrectAnswerError(false)
    }
  }, [slide.opt1, slide.opt2, slide.opt3, slide.opt4, slide.correctAnswer])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8}>
        <Stack spacing={2}>
          <Typography variant="h6" className="card-subtitle">
            Text
          </Typography>
          <HtmlEditorQuiz
            disabled={!editMode}
            slide={slide}
            constraints={constraints}
            currentEpisode={currentEpisode}
            setCurrentEpisode={setCurrentEpisode}
            slideLanguage={language}
            index={index}
            error={questionError}
            setError={setQuestionError}
            errorType={questionErrorType}
            setErrorType={setQuestionErrorType}
            changesCancelled={changesCancelled}
          />
          <Stack direction="row" spacing={2}>
            <TextField
              disabled={!editMode}
              value={slide.opt1 && slide.opt1.text ? slide.opt1.text : ""}
              fullWidth
              label="Answer 1"
              variant="outlined"
              size="small"
              error={answer1Error}
              helperText={
                answer1Error ? "There must be at least 2 answers" : null
              }
              onChange={(e: any) => {
                onQuizAnswerTextFieldChange(
                  currentEpisode,
                  setCurrentEpisode,
                  e,
                  language,
                  slide,
                  index,
                  "opt1"
                )

                if (!e.target.value.length) {
                  setAnswer1Error(true)
                } else {
                  setAnswer1Error(false)
                }
              }}
            />
            <TextField
              disabled={!editMode}
              value={slide.opt2 && slide.opt2.text ? slide.opt2.text : ""}
              fullWidth
              label="Answer 2"
              variant="outlined"
              size="small"
              error={answer2Error}
              helperText={
                answer2Error ? "There must be at least 2 answers" : null
              }
              onChange={(e: any) => {
                onQuizAnswerTextFieldChange(
                  currentEpisode,
                  setCurrentEpisode,
                  e,
                  language,
                  slide,
                  index,
                  "opt2"
                )

                if (!e.target.value.length) {
                  setAnswer2Error(true)
                } else {
                  setAnswer2Error(false)
                }
              }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              disabled={
                !editMode || !slide.opt1.text.length || !slide.opt2.text.length
              }
              value={slide.opt3 && slide.opt3.text ? slide.opt3.text : ""}
              fullWidth
              label="Answer 3"
              variant="outlined"
              size="small"
              error={answer3Error}
              helperText={
                answer3Error ? "There must be at least 2 answers" : null
              }
              onChange={(e: any) => {
                onQuizAnswerTextFieldChange(
                  currentEpisode,
                  setCurrentEpisode,
                  e,
                  language,
                  slide,
                  index,
                  "opt3"
                )
              }}
            />
            <TextField
              disabled={
                !editMode ||
                !slide.opt1.text.length ||
                !slide.opt2.text.length ||
                !slide.opt3.text.length
              }
              value={slide.opt4 && slide.opt4.text ? slide.opt4.text : ""}
              fullWidth
              label="Answer 4"
              variant="outlined"
              size="small"
              onChange={(e: any) => {
                onQuizAnswerTextFieldChange(
                  currentEpisode,
                  setCurrentEpisode,
                  e,
                  language,
                  slide,
                  index,
                  "opt4"
                )
                if (e.target.value.length > 0) {
                  if (!slide.opt1.image) {
                    setImage1Error(true)
                  }
                  if (!slide.opt2.image) {
                    setImage2Error(true)
                  }
                  if (!slide.opt3.image) {
                    setImage3Error(true)
                  }
                  if (!slide.opt4.image) {
                    setImage4Error(true)
                  }
                } else {
                  setImage1Error(false)
                  setImage2Error(false)
                  setImage3Error(false)
                  setImage4Error(false)
                }
              }}
            />
          </Stack>
          <FormControl fullWidth disabled={!editMode}>
            <InputLabel id="text-color-label-quizSlide" size="small">
              Text color
            </InputLabel>
            <Select
              size="small"
              labelId="text-color-label-quizSlide"
              value={slide.textColor ? slide.textColor : ""}
              label="Text color"
              onChange={(e: any) => {
                onQuizTextColorTextFieldChange(
                  currentEpisode,
                  setCurrentEpisode,
                  e.target.value,
                  language,
                  slide,
                  index
                )
              }}
            >
              {textColors.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="h6" className="card-subtitle">
            Quiz
          </Typography>
          <Stack direction="row" spacing={2}>
            <FormControl
              fullWidth
              disabled={!editMode || slide.quizType === "poll"}
            >
              <InputLabel
                id="correct-answer-label-quizSlide"
                size="small"
                error={correctAnswerError}
              >
                Correct answer
              </InputLabel>
              <Select
                size="small"
                labelId="correct-answer-label-quizSlide"
                value={slide.correctAnswer}
                label="Correct answer"
                error={correctAnswerError}
                onChange={(e: any) => {
                  onQuizCorrectAnswerSelectChange(
                    currentEpisode,
                    setCurrentEpisode,
                    e,
                    language,
                    slide,
                    index
                  )
                }}
              >
                <MenuItem value="opt1">1</MenuItem>
                <MenuItem value="opt2">2</MenuItem>
                <MenuItem value="opt3">3</MenuItem>
                <MenuItem value="opt4">4</MenuItem>
              </Select>
              {correctAnswerError && (
                <FormHelperText error>
                  Answer {slide.correctAnswer.slice(3)} doesn't exist
                </FormHelperText>
              )}
            </FormControl>
          </Stack>
          <Typography variant="h6" className="card-subtitle">
            Background
          </Typography>
          <Stack direction="row" spacing={2}>
            <ImagePicker
              filters={{
                category: "background",
              }}
              disabled={!editMode}
              title="Background"
              pickedImage={slide.background ? slide.background : ""}
              setPickedImage={(selectedImage: {
                url: string
                id: string
                description?: string
              }) => {
                onQuizBackgroundChange(
                  currentEpisode,
                  setCurrentEpisode,
                  selectedImage,
                  language,
                  slide,
                  index
                )
              }}
              width="50%"
            />
            <Stack spacing={2} style={{ width: "50%" }}>
              <FormControl fullWidth disabled>
                <InputLabel id="box-shape-label-quizSlide" size="small">
                  Box shape
                </InputLabel>
                <Select
                  size="small"
                  labelId="box-shape-label-quizSlide"
                  value="squared"
                  label="Box shape"
                >
                  {constraints.box.boxShape.map((item) => (
                    <MenuItem value={item} key={item}>
                      {capitalizeFirstCharacter(item)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth disabled={!editMode}>
                <InputLabel id="box-back-color-label-quizSlide" size="small">
                  Box back color
                </InputLabel>
                <Select
                  size="small"
                  labelId="box-back-color-label-quizSlide"
                  value={
                    slide.box && slide.box.boxBackColor
                      ? slide.box.boxBackColor
                      : "none"
                  }
                  label="Box back color"
                  onChange={(e: any) => {
                    if (e.target.value === "none") {
                      onQuizBoxBackColorChange(
                        currentEpisode,
                        setCurrentEpisode,
                        null,
                        language,
                        slide,
                        index
                      )
                    } else {
                      onQuizBoxBackColorChange(
                        currentEpisode,
                        setCurrentEpisode,
                        e.target.value,
                        language,
                        slide,
                        index
                      )
                    }

                    checkTextColorOnBoxBackColorChangeQuiz(
                      currentEpisode,
                      setCurrentEpisode,
                      e,
                      language,
                      slide,
                      index
                    )
                  }}
                >
                  <MenuItem value="none">None</MenuItem>
                  {boxBackColors.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
          <Typography variant="h6" className="card-subtitle">
            Images
          </Typography>
          <ImagePicker
            filters={{
              category: "decor",
            }}
            disabled={!editMode}
            title="Decor"
            removeButton
            pickedImage={slide.decor ? slide.decor : ""}
            setPickedImage={(selectedImage: {
              url: string
              id: string
              description?: string
            }) => {
              onQuizDecorChange(
                currentEpisode,
                setCurrentEpisode,
                selectedImage,
                language,
                slide,
                index
              )
            }}
            width="100%"
          />
          <Stack direction="row" spacing={2}>
            <ImagePicker
              error={image1Error}
              filters={{
                category: "content",
                size: [
                  MediaSize.xs,
                  MediaSize.s,
                  MediaSize.m,
                  MediaSize.l,
                  MediaSize.xl,
                ],
              }}
              disabled={!editMode || !slide.opt4.text.length}
              title="Answer 1 image"
              pickedImage={
                slide.opt1 && slide.opt1.image ? slide.opt1.image : ""
              }
              setPickedImage={(selectedImage: {
                url: string
                id: string
                description?: string
              }) => {
                onQuizAnswerImageChange(
                  currentEpisode,
                  setCurrentEpisode,
                  selectedImage,
                  language,
                  slide,
                  index,
                  "opt1"
                )
                setImage1Error(false)
              }}
              width="50%"
            />
            <ImagePicker
              error={image2Error}
              filters={{
                category: "content",
                size: [
                  MediaSize.xs,
                  MediaSize.s,
                  MediaSize.m,
                  MediaSize.l,
                  MediaSize.xl,
                ],
              }}
              disabled={!editMode || !slide.opt4.text.length}
              title="Answer 2 image"
              pickedImage={
                slide.opt2 && slide.opt2.image ? slide.opt2.image : ""
              }
              setPickedImage={(selectedImage: {
                url: string
                id: string
                description?: string
              }) => {
                onQuizAnswerImageChange(
                  currentEpisode,
                  setCurrentEpisode,
                  selectedImage,
                  language,
                  slide,
                  index,
                  "opt2"
                )
                setImage2Error(false)
              }}
              width="50%"
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <ImagePicker
              error={image3Error}
              filters={{
                category: "content",
                size: [
                  MediaSize.xs,
                  MediaSize.s,
                  MediaSize.m,
                  MediaSize.l,
                  MediaSize.xl,
                ],
              }}
              disabled={!editMode || !slide.opt4.text.length}
              title="Answer 3 image"
              pickedImage={
                slide.opt3 && slide.opt3.image ? slide.opt3.image : ""
              }
              setPickedImage={(selectedImage: {
                url: string
                id: string
                description?: string
              }) => {
                onQuizAnswerImageChange(
                  currentEpisode,
                  setCurrentEpisode,
                  selectedImage,
                  language,
                  slide,
                  index,
                  "opt3"
                )
                setImage3Error(false)
              }}
              width="50%"
            />
            <ImagePicker
              error={image4Error}
              filters={{
                category: "content",
                size: [
                  MediaSize.xs,
                  MediaSize.s,
                  MediaSize.m,
                  MediaSize.l,
                  MediaSize.xl,
                ],
              }}
              disabled={!editMode || !slide.opt4.text.length}
              title="Answer 4 image"
              pickedImage={
                slide.opt4 && slide.opt4.image ? slide.opt4.image : ""
              }
              setPickedImage={(selectedImage: {
                url: string
                id: string
                description?: string
              }) => {
                onQuizAnswerImageChange(
                  currentEpisode,
                  setCurrentEpisode,
                  selectedImage,
                  language,
                  slide,
                  index,
                  "opt4"
                )
                setImage4Error(false)
              }}
              width="50%"
            />
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Stack spacing={2}>
          <Typography variant="h6" className="card-subtitle">
            Preview
          </Typography>
          <div
            style={{
              zoom: 0.6,
              marginTop: 30,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              maxHeight: "100%",
              cursor: "pointer",
              opacity: isBigPreviewOpen ? 0 : 1,
              transition: "200ms",
            }}
            onClick={() => {
              setIsBigPreviewOpen(true)
            }}
          >
            <Slide
              slide={slide}
              constraints={constraints}
              className="preview"
              primaryTopicName={
                currentEpisode.topics.find((topic) => topic.primary)
                  ? currentEpisode.topics.find((topic) => topic.primary).topic
                      .name
                  : null
              }
              episodeTitle={
                currentEpisode.translations.find(
                  (item) => item.lang === language
                ).title ?? null
              }
              numberOfSlides={
                currentEpisode.translations.find(
                  (item) => item.lang === language
                ).slides.length
              }
              numberOfQuiz={
                currentEpisode.translations.find(
                  (item) => item.lang === language
                ).quiz.length
              }
              currentSlide={
                currentEpisode.translations.find(
                  (item) => item.lang === language
                ).slides.length + index
              }
            />
          </div>
        </Stack>
      </Grid>
      <BigPreview
        isBigPreviewOpen={isBigPreviewOpen}
        setIsBigPreviewOpen={setIsBigPreviewOpen}
      >
        <div
          style={{
            zoom: window.innerHeight / 950,
            marginTop: 70,
          }}
        >
          <Slide
            slide={slide}
            constraints={constraints}
            className="preview"
            primaryTopicName={
              currentEpisode.topics.find((topic) => topic.primary)
                ? currentEpisode.topics.find((topic) => topic.primary).topic
                    .name
                : null
            }
            episodeTitle={
              currentEpisode.translations.find((item) => item.lang === language)
                .title ?? null
            }
            numberOfSlides={
              currentEpisode.translations.find((item) => item.lang === language)
                .slides.length
            }
            numberOfQuiz={
              currentEpisode.translations.find((item) => item.lang === language)
                .quiz.length
            }
            currentSlide={
              currentEpisode.translations.find((item) => item.lang === language)
                .slides.length + index
            }
          />
        </div>
      </BigPreview>
    </Grid>
  )
}

export default QuizSlide
