import { LoadingButton } from "@mui/lab"
import {
  Button,
  CardActions,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
  Dialog,
  TextField,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"
import { MainContext } from "../../controllers/main"
import {
  DialogTransition,
  lowercaseFirstCharacter,
} from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import Action from "../../models/action"
import { ActionsContext } from "../../controllers/actions"

const CreateActionJSONDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
  topBarText,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
  topBarText: string
}) => {
  const { setAnimation } = useContext(MainContext)
  const { createAction: createNewAction, createActionDocument } =
    useContext(ActionsContext)
  const navigate = useNavigate()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // action json
  const [actionJson, setActionJson] = useState<string>("")

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setActionJson("")
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (actionJson.length) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [actionJson])

  // loading
  const [loading, setLoading] = useState<boolean>(false)

  // create action
  const createAction = async () => {
    setLoading(true)

    const action: Action = JSON.parse(actionJson)

    const input: {
      handle: string
      isRepeatable: boolean
      image: string
      category: {
        id: string
      }
      savingMetrics: {
        metrics: {
          action: number
          activity: number
          co2Saving: number
          energySaving: number
          waterSaving: number
        }
        location: {
          id: string
          type: "Country" | "Region" | "SubRegion" | "DefaultLocation"
        }
      }[]
      sdgs: { primary: boolean; id: string }[]
      sdgTargets: { primary: boolean; id: string }[]
      timesPerDay: number
      featured: boolean
      teamId: string
    } = {
      handle: action.handle,
      isRepeatable: action.isRepeatable,
      image: action.image,
      category: { id: action.category.id },
      savingMetrics: action.savingMetrics.map((item) => {
        return {
          metrics: {
            action: item.metrics.action,
            activity: item.metrics.activity,
            co2Saving: item.metrics.co2Saving,
            energySaving: item.metrics.energySaving,
            waterSaving: item.metrics.waterSaving,
          },
          location: {
            id: item.location.id,
            type: item.location.__typename,
          },
        }
      }),
      sdgs: action.sdgs.map((item) => {
        return {
          primary: item.primary,
          id: item.sdg.id,
        }
      }),
      sdgTargets: action.sdgTargets.map((item) => {
        return {
          primary: item.primary,
          id: item.sdgTarget.id,
        }
      }),
      timesPerDay: action.timesPerDay,
      featured: action.featured,
      teamId: action.teamId,
    }

    const createdAction = await createNewAction(input)

    if (typeof createdAction !== "boolean") {
      await createActionDocument({
        parentId: createdAction.document.parentId,
        type: "Action",
        actionDocumentItems: action.document.items.map((item) => {
          return {
            body: item.body.map((bodyItem) => {
              delete (bodyItem as any).__typename
              const sliceType = bodyItem.sliceType
              delete bodyItem.sliceType
              return {
                [lowercaseFirstCharacter(sliceType)]: {
                  ...bodyItem,
                },
              }
            }),
            default: item.isDefault,
            lang: item.lang,
            title: item.title,
            assumptions: item.assumptions,
          }
        }),
      })

      setDialogOpen(false)
      setLoading(false)

      setAnimation(false)
      setTimeout(() => {
        navigate(`/actions/${createdAction.id}`, {
          state: { enableEditing: true },
        })
      }, 250)
    } else {
      setLoading(false)
    }
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText={topBarText}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            {topBarText}
          </Typography>
          <TextField
            label="Action JSON"
            multiline
            rows={14}
            style={{ margin: 16, width: "calc(100% - 32px)" }}
            value={actionJson}
            onChange={(e) => {
              setActionJson(e.target.value)
            }}
          />
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (showDiscardDialog) {
                    setDiscardDialogOpen(true)
                  } else {
                    handleDialogChange(e)
                  }
                }}
              >
                Close
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={!actionJson.length}
                onClick={() => {
                  createAction()
                }}
              >
                Add
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={loading} />
    </Dialog>
  )
}

export default CreateActionJSONDialog
