import { Stack } from "@mui/material"
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd"
import ImageSlice from "../../models/bodySlices/imageSlice"
import { BodySliceType } from "../../services/config/enum"
import AddBlockPanel from "./common/addBlockPanel"
import ImageSliceComponent from "./slices/imageSlice"

const BlockEditorPrizes = ({
  prizes,
  setPrizes,
  editMode = true,
}: {
  prizes: ImageSlice[]
  setPrizes: (prizes: ImageSlice[]) => void
  editMode?: boolean
}) => {
  // add prize
  const addPrize = () => {
    const newImageSlice: ImageSlice = {
      sliceType: BodySliceType.BodyImageSlice,
      imgUrl: "",
      imgCaption: null,
      imgCaptionTitle: null,
    }
    prizes.push(newImageSlice)
    setPrizes(prizes)
  }

  // remove prize
  const removePrize = (sliceToRemoveIndex: number) => {
    prizes.splice(sliceToRemoveIndex, 1)
    setPrizes(prizes)
  }

  // drag and drop
  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result

    if (destination && source) {
      const itemToMove = prizes[source.index]

      prizes.splice(source.index, 1)
      prizes.splice(destination.index, 0, itemToMove)
      setPrizes(prizes)
    }
  }

  return (
    <Stack spacing={prizes.length ? 3 : 0} style={{ overflowX: "hidden" }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="column-1">
          {(provided) => (
            <Stack
              spacing={3}
              style={{ overflowX: "hidden", paddingTop: 2, paddingBottom: 2 }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <div
                style={{
                  width: "100%",
                  height: 0,
                  marginTop: -24,
                }}
              />
              {prizes.map((item, index) => {
                return (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div {...provided.draggableProps} ref={provided.innerRef}>
                        <ImageSliceComponent
                          item={item as ImageSlice}
                          prizes={prizes}
                          setPrizes={setPrizes}
                          editMode={editMode}
                          sliceIndex={index}
                          removePrize={removePrize}
                          provided={provided}
                          withCaptionTitleError
                          withCaptionError
                        />
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
      <AddBlockPanel editMode={editMode} addPrize={addPrize} />
    </Stack>
  )
}

export default BlockEditorPrizes
