import {
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { TeamsContext } from "../../controllers/teams"
import TeamContract from "../../models/teamContract"
import { parseStringDate } from "../../services/utilities/utility"
import {
  CheckRounded,
  CloseRounded,
  DeleteRounded,
  EditRounded,
} from "@mui/icons-material"
import ISODatePicker from "../global/isoDatePicker"
import { LoadingButton } from "@mui/lab"

const TeamContractCard = ({
  index,
  item,
  current,
  list,
}: {
  index: number
  item: TeamContract
  current: boolean
  list: TeamContract[]
}) => {
  const {
    editMode,
    updateTeamContract,
    deleteTeamContract,
    activateTeamContract,
  } = useContext(TeamsContext)

  // states
  const [loading, setLoading] = useState<boolean>(false)
  const [editing, setEditing] = useState<boolean>(false)
  const [currentStartDate, setCurrentStartDate] = useState<string>(
    item.startDate
  )
  const [currentEndDate, setCurrentEndDate] = useState<string>(item.endDate)
  const [currentSellingPrice, setCurrentSellingPrice] = useState<number | null>(
    item.sellingPrice
  )
  const [currentTargetMembers, setCurrentTargetMembers] = useState<
    number | null
  >(item.targetMembers)

  // reset dates and error when user cancels edit
  useEffect(() => {
    if (!editing) {
      setCurrentStartDate(item.startDate)
      setCurrentEndDate(item.endDate)
      setCurrentSellingPrice(item.sellingPrice)
      setCurrentTargetMembers(item.targetMembers)
      setError(false)
    }
  }, [editing])

  // check for errors
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    if (
      (currentStartDate &&
        currentEndDate &&
        new Date(currentStartDate) > new Date(currentEndDate)) ||
      !currentStartDate ||
      !currentEndDate
    ) {
      setError(true)
    } else {
      setError(false)
    }
  }, [currentStartDate, currentEndDate])

  // delete contract dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)

  return (
    <Card
      variant="outlined"
      sx={{
        boxShadow: "none",
        borderTopLeftRadius: !index ? undefined : 0,
        borderTopRightRadius: !index ? undefined : 0,
        borderBottomLeftRadius: index === list.length - 1 ? undefined : 0,
        borderBottomRightRadius: index === list.length - 1 ? undefined : 0,
        borderTop: index === 0 ? undefined : "none",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <CardContent
        style={{
          height: 68,
          paddingTop: 12,
          paddingBottom: 12,
          transition: "150ms",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          style={{
            height: "100%",
            position: "relative",
          }}
        >
          {editing ? (
            <Stack style={{ width: 145 }}>
              <ISODatePicker
                label="Start date"
                date={currentStartDate}
                setDate={setCurrentStartDate}
                withHelperText={false}
                error={!currentStartDate}
                disabled={!editMode || loading}
                utc={false}
              />
            </Stack>
          ) : (
            <Stack style={{ width: 145 }}>
              <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
                Start date
              </Typography>
              <Typography style={{ fontSize: "0.75rem" }}>
                {item.startDate ? parseStringDate(item.startDate) : "none"}
              </Typography>
            </Stack>
          )}
          {editing ? (
            <Stack style={{ width: 145 }}>
              <ISODatePicker
                label="End date"
                date={currentEndDate}
                setDate={setCurrentEndDate}
                withHelperText={false}
                error={
                  (currentStartDate &&
                    currentEndDate &&
                    new Date(currentStartDate) > new Date(currentEndDate)) ||
                  !currentEndDate
                }
                disabled={!editMode || loading}
                utc={false}
              />
            </Stack>
          ) : (
            <Stack style={{ width: 145 }}>
              <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
                End date
              </Typography>
              <Typography style={{ fontSize: "0.75rem" }}>
                {item.endDate ? parseStringDate(item.endDate) : "none"}
              </Typography>
            </Stack>
          )}
          {!editing && (
            <Stack style={{ paddingRight: 32 }}>
              <Typography sx={{ fontWeight: 600, fontSize: "0.85rem" }}>
                Selling price ▸{" "}
                <span
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: 400,
                    opacity: item.sellingPrice ? 1 : 0.5,
                  }}
                >
                  {item.sellingPrice || item.sellingPrice === 0
                    ? item.sellingPrice + "€"
                    : "not set"}
                </span>
              </Typography>
              <Typography sx={{ fontWeight: 600, fontSize: "0.85rem" }}>
                Target members ▸{" "}
                <span
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: 400,
                    opacity: item.targetMembers ? 1 : 0.5,
                  }}
                >
                  {item.targetMembers || item.targetMembers === 0
                    ? item.targetMembers
                    : "not set"}
                </span>
              </Typography>
            </Stack>
          )}
          {editing && (
            <Stack style={{ width: 110 }}>
              <TextField
                size="small"
                disabled={!editMode || loading}
                label="Selling price"
                type="number"
                value={currentSellingPrice ?? ""}
                onChange={(e) => {
                  if (e.target.value) {
                    setCurrentSellingPrice(parseFloat(e.target.value))
                  } else {
                    setCurrentSellingPrice(null)
                  }
                }}
                error={currentSellingPrice !== null && currentSellingPrice < 0}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            </Stack>
          )}
          {editing && (
            <Stack style={{ width: 110 }}>
              <TextField
                size="small"
                disabled={!editMode || loading}
                label="Target members"
                type="number"
                value={currentTargetMembers ?? ""}
                onChange={(e) => {
                  if (e.target.value) {
                    setCurrentTargetMembers(parseFloat(e.target.value))
                  } else {
                    setCurrentTargetMembers(null)
                  }
                }}
                error={
                  currentTargetMembers !== null && currentTargetMembers < 0
                }
              />
            </Stack>
          )}
          {editing && !current ? (
            <Button
              variant="contained"
              size="small"
              disabled={loading || !editMode}
              onClick={async () => {
                setLoading(true)

                await activateTeamContract(item.id)

                setLoading(false)
                setEditing(false)
              }}
            >
              Set as current
            </Button>
          ) : current ? (
            <Chip size="small" label="Current" color="success" />
          ) : null}
          {/* cancel button */}
          {editing && (
            <IconButton
              size="small"
              style={{
                width: 40,
                height: 40,
                position: "absolute",
                right: 91,
                transition: "150ms",
              }}
              onClick={() => {
                setEditing(false)
              }}
              disabled={!editMode || loading}
            >
              <CloseRounded
                style={{
                  width: 24,
                }}
              />
            </IconButton>
          )}
          {/* edit and save button */}
          <IconButton
            size="small"
            style={{
              width: 40,
              height: 40,
              position: "absolute",
              right: 40,
              transition: "150ms",
            }}
            onClick={async () => {
              if (editing) {
                setLoading(true)

                await updateTeamContract(
                  item.id,
                  currentStartDate.split("T")[0],
                  currentEndDate.split("T")[0],
                  currentSellingPrice,
                  currentTargetMembers
                )

                setLoading(false)
                setEditing(false)
              } else {
                setEditing(true)
              }
            }}
            disabled={
              !editMode ||
              error ||
              loading ||
              (currentSellingPrice !== null && currentSellingPrice < 0) ||
              (currentTargetMembers !== null && currentTargetMembers < 0)
            }
          >
            {editing ? (
              <CheckRounded
                style={{
                  width: 24,
                }}
              />
            ) : (
              <EditRounded
                style={{
                  width: 20,
                }}
              />
            )}
          </IconButton>
          {/* delete contract button */}
          <IconButton
            size="small"
            color="error"
            style={{
              width: 40,
              height: 40,
              position: "absolute",
              right: -11,
            }}
            onClick={() => {
              setDeleteDialogOpen(true)
            }}
            disabled={!editMode || loading}
          >
            <DeleteRounded
              style={{
                width: 24,
              }}
            />
          </IconButton>
        </Stack>
      </CardContent>
      {loading && (
        <LinearProgress
          style={{ width: "100%", position: "absolute", bottom: 0 }}
        />
      )}
      {/* delete contract dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={
          !loading
            ? () => {
                setDeleteDialogOpen(false)
              }
            : null
        }
        aria-labelledby="alert-dialog-title-translation"
        aria-describedby="alert-dialog-description-translation"
      >
        <DialogTitle id="alert-dialog-title-translation">
          Are you sure you want to delete this contract?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-translation">
            The action is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={() => {
              setDeleteDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            color="error"
            onClick={async () => {
              setLoading(true)

              await deleteTeamContract(item.id)

              setLoading(false)
              setDeleteDialogOpen(false)
            }}
            autoFocus
            loading={loading}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

export default TeamContractCard
