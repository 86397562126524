import { CardContent, Stack, TextField, Typography } from "@mui/material"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import { TagsContext } from "../../../controllers/tags"

const MainCategoryTranslationsEdit = ({
  itemIndex,
  errors,
}: {
  itemIndex: number
  errors: { type: string; error: boolean }[]
}) => {
  const { showPadding } = useContext(MainContext)
  const { currentMainCategory, setCurrentMainCategory, editMode } =
    useContext(TagsContext)

  // current item
  const currentItem = currentMainCategory.translations[itemIndex]

  // errors
  const nameError = errors.filter(
    (item) =>
      item.type === "name" + currentMainCategory.translations[itemIndex].lang
  )[0].error

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Details
        </Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            size="small"
            label="Name"
            value={currentItem.name}
            onChange={(e) => {
              currentMainCategory.translations[itemIndex].name = e.target.value
              setCurrentMainCategory({ ...currentMainCategory })
            }}
            onBlur={(e) => {
              currentMainCategory.translations[itemIndex].name =
                currentMainCategory.translations[itemIndex].name.trim()
              setCurrentMainCategory({ ...currentMainCategory })
            }}
            disabled={!editMode}
            error={nameError}
            helperText={nameError ? "This field is required" : ""}
          />
          <TextField
            fullWidth
            size="small"
            label="CTA"
            value={currentItem.cta ?? ""}
            onChange={(e) => {
              currentMainCategory.translations[itemIndex].cta = e.target.value
              setCurrentMainCategory({ ...currentMainCategory })
            }}
            onBlur={(e) => {
              if (currentMainCategory.translations[itemIndex].cta) {
                currentMainCategory.translations[itemIndex].cta =
                  currentMainCategory.translations[itemIndex].cta.trim()
                setCurrentMainCategory({ ...currentMainCategory })
              }
            }}
            disabled={!editMode}
          />
        </Stack>
        <TextField
          fullWidth
          size="small"
          label="Description"
          value={currentItem.description ?? ""}
          multiline
          minRows={2}
          onChange={(e) => {
            currentMainCategory.translations[itemIndex].description =
              e.target.value
            setCurrentMainCategory({ ...currentMainCategory })
          }}
          onBlur={(e) => {
            if (currentMainCategory.translations[itemIndex].description) {
              currentMainCategory.translations[itemIndex].description =
                currentMainCategory.translations[itemIndex].description.trim()
              setCurrentMainCategory({ ...currentMainCategory })
            }
          }}
          disabled={!editMode}
        />
      </Stack>
    </CardContent>
  )
}

export default MainCategoryTranslationsEdit
