import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import { DialogTransition } from "../../services/utilities/utility"
import { CloseRounded } from "@mui/icons-material"
import { ChallengesContext } from "../../controllers/challenges"
import Challenge from "../../models/challenge"
import ChallengesList from "../../views/challenge/challengesList"

const ChangeChallengeDialog = ({
  open,
  setOpen,
  selectedChallenge,
  setSelectedChallenge,
  setChallenge,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  selectedChallenge: Challenge
  setSelectedChallenge: Dispatch<SetStateAction<Challenge>>
  setChallenge: Dispatch<SetStateAction<Challenge>>
}) => {
  const { resetFilters } = useContext(ChallengesContext)

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false)
        setSelectedChallenge(null)
        resetFilters()
      }}
      TransitionComponent={DialogTransition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setOpen(false)
              setSelectedChallenge(null)
              resetFilters()
            }}
            aria-label="close"
          >
            <CloseRounded />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Select Challenge
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => {
              setChallenge(selectedChallenge)

              setOpen(false)
              setSelectedChallenge(null)
              resetFilters()
            }}
            disabled={!selectedChallenge}
          >
            Select
          </Button>
        </Toolbar>
      </AppBar>
      <Paper style={{ backgroundColor: "#f5f5f5" }}>
        <ChallengesList
          forDialog
          selectedChallenge={selectedChallenge}
          setSelectedChallenge={setSelectedChallenge}
        />
      </Paper>
    </Dialog>
  )
}

export default ChangeChallengeDialog
