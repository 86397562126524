import {
  Button,
  CardContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { MainContext } from "../../controllers/main"
import { deepCopy } from "../../services/utilities/utility"
import { MissionsContext } from "../../controllers/missions"
import Mission from "../../models/mission"

const MissionTranslationsEdit = ({ itemIndex }: { itemIndex: number }) => {
  const { showPadding } = useContext(MainContext)
  const {
    currentMission,
    setCurrentMission,
    editMode,
    translationsErrors,
    setTranslationsErrors,
    copyDetailsFromDefault,
  } = useContext(MissionsContext)

  // current item
  const currentItem = currentMission.document.items[itemIndex]

  // errors managment
  const [titleError, setTitleError] = useState<boolean>(false) // error for title

  // update translations errors on errors change
  useEffect(() => {
    if (titleError) {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = true
    } else {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = false
    }

    setTranslationsErrors([...translationsErrors])
  }, [titleError])

  // update errors on currentItem change
  useEffect(() => {
    if (currentItem.title.length < 3) {
      setTitleError(true)
    } else {
      setTitleError(false)
    }
  }, [currentItem])

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" style={{ height: 28 }}>
          <Typography variant="h6" className="card-title">
            Details
          </Typography>
          <Stack
            alignItems="center"
            justifyContent="flex-end"
            style={{ width: "100%" }}
            direction="row"
            spacing={2}
          >
            <FormGroup style={{ marginRight: -16 }}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editMode}
                    checked={currentItem.isDefault}
                    onChange={(e) => {
                      const currentMissionCopy: Mission =
                        deepCopy(currentMission)
                      currentMissionCopy.document.items[itemIndex].isDefault =
                        e.target.checked
                      if (e.target.checked) {
                        currentMissionCopy.document.items.forEach(
                          (item, index) => {
                            if (index !== itemIndex) {
                              item.isDefault = false
                            }
                          }
                        )
                      }
                      setCurrentMission(currentMissionCopy)
                    }}
                  />
                }
                label="Default"
              />
            </FormGroup>
            <Divider orientation="vertical" flexItem />
            <Button
              size="small"
              variant="outlined"
              style={{ height: 26, fontSize: 12 }}
              onClick={() => {
                copyDetailsFromDefault(itemIndex)
              }}
              disabled={
                currentItem.isDefault ||
                !editMode ||
                !currentMission.document.items.filter((item) => item.isDefault)
                  .length
              }
            >
              Copy from default
            </Button>
          </Stack>
        </Stack>
        <TextField
          fullWidth
          size="small"
          label="Title"
          value={currentItem.title}
          onChange={(e) => {
            const currentMissionCopy: Mission = deepCopy(currentMission)
            currentMissionCopy.document.items[itemIndex].title = e.target.value
            setCurrentMission(currentMissionCopy)

            // errors check
            if (e.target.value.length < 3) {
              setTitleError(true)
            } else {
              setTitleError(false)
            }
          }}
          disabled={!editMode}
          error={titleError}
          helperText={titleError ? "Minimum length is 3 characters" : ""}
          onBlur={() => {
            if (currentMission.document.items[itemIndex].title) {
              const currentMissionCopy: Mission = deepCopy(currentMission)
              currentMissionCopy.document.items[itemIndex].title =
                currentMissionCopy.document.items[itemIndex].title.trim()
              setCurrentMission(currentMissionCopy)
            }
          }}
        />
      </Stack>
    </CardContent>
  )
}

export default MissionTranslationsEdit
