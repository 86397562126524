import {
  LinearProgress,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import ListContainer from "../../components/global/listContainer"
import ListTopBar from "../../components/global/listTopBar"
import ListTable from "../../components/global/listTable"
import TableSpinner from "../../components/global/tableSpinner"
import { MainContext } from "../../controllers/main"
import { ChannelsContext } from "../../controllers/channels"
import Channel from "../../models/channel"
import {
  calculateTimeElapsed,
  capitalizeFirstCharacter,
  registerAnalyticsEvent,
} from "../../services/utilities/utility"
import CreateChannelDialog from "../../components/channel/createChannelDialog"
import TitleTableCell from "../../components/global/titleTableCell"
import CopyToClipboardButton from "../../components/global/copyToClipboardButton"

const ChannelsList = () => {
  const { setAnimation, showPadding, cmdPressed } = useContext(MainContext)
  const {
    loading,
    updatingList,
    setUpdatingList,
    channelsList,
    getChannelsList,
  } = useContext(ChannelsContext)
  const navigate = useNavigate()

  // list background update
  const backgroundUpdate = useCallback(() => {
    if (!loading) {
      setUpdatingList(true)
      getChannelsList(false)
    }
  }, [getChannelsList]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!channelsList.length) {
      getChannelsList()
    }
    backgroundUpdate()
  }, [backgroundUpdate])

  // handle create channel dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // register analytics event
  useEffect(() => {
    registerAnalyticsEvent({ type: "page_view", name: "channels_list" })
  }, [])

  // local search by title
  const [currentSearchTitle, setCurrentSearchTitle] = useState<string>("")
  const [searchTitle, setSearchTitle] = useState<string>("")

  const channelsListMemo = useMemo(() => {
    return channelsList.filter((item) =>
      item.projectId.toLowerCase().includes(currentSearchTitle.toLowerCase())
    )
  }, [currentSearchTitle, channelsList])

  return (
    <ListContainer style={{ position: "relative" }}>
      {updatingList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 85,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <ListTopBar
        loading={loading}
        textFieldValue={searchTitle}
        textFieldPlaceholder="Search Channels"
        addButtonLabel="Add Channel"
        textFieldOnChange={(e) => {
          setSearchTitle(e.target.value)

          if (!e.target.value.length) {
            setCurrentSearchTitle("")
            setSearchTitle("")
          }
        }}
        textFieldOnKeyDown={(e) => {
          if (e.key === "Enter") {
            setCurrentSearchTitle(searchTitle)
          }
        }}
        cancelButtonOnClick={() => {
          setCurrentSearchTitle("")
          setSearchTitle("")
        }}
        searchButtonOnClick={() => {
          setCurrentSearchTitle(searchTitle)
        }}
        addButtonOnClick={handleDialogChange}
        searchButtonDisabledLength={1}
      />
      {loading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 150px)"
              : "calc(100vh - 118px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 150px)"
              : "calc(100vh - 118px)"
          }
          headingItems={["Title & ID", "Team ID", "Created", "Updated"]}
          tableBody={channelsListMemo.map((item: Channel) => (
            <TableRow
              key={item.id}
              style={{ cursor: "pointer" }}
              hover
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
              onClick={() => {
                if (cmdPressed) {
                  window.open(`${window.location.href}/${item.id}`)
                } else {
                  setAnimation(false)
                  setTimeout(() => {
                    navigate(`/channels/${item.id}`)
                  }, 250)
                }
              }}
            >
              <TitleTableCell
                title={capitalizeFirstCharacter(
                  item.projectId.includes("_")
                    ? item.projectId.slice(0, item.projectId.indexOf("_"))
                    : item.projectId
                )}
                id={item.id}
              />
              <TableCell>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <CopyToClipboardButton textToCopy={item.teamId} size="big" />
                  <Typography
                    variant="caption"
                    style={{
                      fontSize: 12,
                      // color: "gray",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.teamId}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {calculateTimeElapsed(item.createdAt)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {calculateTimeElapsed(item.updatedAt)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        />
      )}
      <CreateChannelDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
        topBarText="Add Channel"
      />
    </ListContainer>
  )
}

export default ChannelsList
