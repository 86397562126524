import { LoadingButton } from "@mui/lab"
import {
  Button,
  CardActions,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Dialog,
  MenuItem,
  Select,
  Autocomplete,
  Grid,
  ButtonBase,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import {
  DialogTransition,
  capitalizeFirstCharacter,
  enumAsArray,
  renderOption,
  renderTags,
} from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import { useNavigate } from "react-router-dom"
import { MainContext } from "../../controllers/main"
import { LanguagesContext } from "../../controllers/languages"
import { NotificationsContext } from "../../controllers/notifications"
import {
  NotificationTemplateFormat,
  NotificationTemplateType,
} from "../../services/config/enum"
import { AutocompleteOption } from "../../services/config/interfaces"
import { mergeSchemas } from "@aworld/utils/jsonSchema"
import MjmlTemplate from "../../models/mjmlTemplate"
import { mjmlTemplates } from "../../services/data/notificationTemplates"
import JsonForm from "./jsonForm"
import AddEnrichersAdornment from "./addEnrichersAdornment"

const CreateTemplateDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
}) => {
  const { setAnimation } = useContext(MainContext)
  const {
    loading,
    enrichersList,
    setLoading,
    createTemplate,
    createTemplateDocument,
  } = useContext(NotificationsContext)
  const { languages, languagesForEpisodeTabs } = useContext(LanguagesContext)
  const navigate = useNavigate()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // configuration
  const [handle, setHandle] = useState<string>("")
  const [type, setType] = useState<NotificationTemplateType>(
    NotificationTemplateType.EMAIL
  )
  const [format, setFormat] = useState<NotificationTemplateFormat>(
    NotificationTemplateFormat.SIMPLE
  )
  const [defaultTranslation, setDefaultTranslation] = useState<string>("")

  // enrichers
  const [enrichers, setEnrichers] = useState<AutocompleteOption[]>([])

  // form
  const mergedSchemas = useMemo(() => {
    if (enrichers.length && enrichersList.length) {
      return mergeSchemas(
        enrichers.map(
          (item) => enrichersList.find(({ id }) => id === item.id).exportSchema
        )
      )
    }

    return []
  }, [enrichers, enrichersList])
  const [formData, setFormData] = useState<any>(null)
  const [formErrors, setFormErrors] = useState<any[]>([])

  // document
  const [title, setTitle] = useState<string>("")
  const [subtitle, setSubtitle] = useState<string>("")
  const [content, setContent] = useState<string>("")

  // mjml template
  const [template, setTemplate] = useState<MjmlTemplate | null>(null)

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  // slides
  const slides = [
    {
      label: "Configuration",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Configuration
          </Typography>
          <TextField
            fullWidth
            size="small"
            label="Handle"
            value={handle}
            onChange={(e) => {
              setHandle(e.target.value)
            }}
          />
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="type-select">Type</InputLabel>
              <Select
                labelId="type-select"
                label="Type"
                value={type}
                onChange={(e) => {
                  setType(e.target.value as NotificationTemplateType)
                  setFormat(NotificationTemplateFormat.SIMPLE)
                }}
              >
                {enumAsArray(NotificationTemplateType).map((key) => (
                  <MenuItem key={key as string} value={key as string}>
                    {capitalizeFirstCharacter(
                      (key as string)
                        .toLowerCase()
                        .replace("_", "-")
                        .replace("app", "App")
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="format-select">Format</InputLabel>
              <Select
                labelId="format-select"
                label="Format"
                value={format}
                onChange={(e) => {
                  setFormat(e.target.value as NotificationTemplateFormat)
                }}
              >
                {enumAsArray(NotificationTemplateFormat).map((key) => (
                  <MenuItem
                    disabled={
                      type !== NotificationTemplateType.EMAIL && key === "MJML"
                    }
                    key={key as string}
                    value={key as string}
                  >
                    {key === "MJML"
                      ? "MJML"
                      : capitalizeFirstCharacter((key as string).toLowerCase())}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <FormControl fullWidth size="small">
            <InputLabel id="language-select">Default language</InputLabel>
            <Select
              labelId="language-select"
              label="Default language"
              value={defaultTranslation}
              onChange={(e) => {
                setDefaultTranslation(e.target.value)
              }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {lang.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      ),
    },
    {
      label: "Variables",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Variables
          </Typography>
          <Autocomplete
            fullWidth
            size="small"
            value={enrichers}
            multiple
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={enrichersList.map((item) => {
              return {
                id: item.id,
                label: item.name,
              }
            })}
            renderInput={(params) => (
              <TextField {...params} label="Variables" />
            )}
            onChange={(e: any, newValues: AutocompleteOption[]) => {
              setEnrichers(newValues)
              setFormData(null)
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
        </Stack>
      ),
    },
    {
      label: "Form",
      component: (
        <Stack spacing={2} style={{ maxHeight: 290, overflowY: "scroll" }}>
          <Typography variant="h6" className="card-title">
            Default values form
          </Typography>
          {enrichers.length ? (
            <JsonForm
              mergedSchemas={mergedSchemas}
              formData={formData}
              setFormData={setFormData}
              setFormErrors={setFormErrors}
            />
          ) : (
            <Typography>
              You did not select any variable, so you don't have to enter the
              default values.
            </Typography>
          )}
        </Stack>
      ),
    },
    {
      label: "Details",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Details in{" "}
            {defaultTranslation.length
              ? languagesForEpisodeTabs.filter(
                  (item) => item.value === defaultTranslation
                )[0].label
              : ""}
          </Typography>
          <Stack direction="row" gap={2}>
            <TextField
              fullWidth
              size="small"
              label="Title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
              onBlur={() => {
                if (title) {
                  setTitle((current) => current.trim())
                }
              }}
              InputProps={{
                endAdornment: (
                  <AddEnrichersAdornment
                    value={title}
                    onChange={(newValue) => {
                      setTitle(newValue)
                    }}
                    dataJson={formData}
                  />
                ),
              }}
            />
            <TextField
              fullWidth
              size="small"
              label="Subtitle (optional)"
              value={subtitle}
              onChange={(e) => {
                setSubtitle(e.target.value)
              }}
              onBlur={() => {
                if (subtitle) {
                  setSubtitle((current) => current.trim())
                }
              }}
              InputProps={{
                endAdornment: (
                  <AddEnrichersAdornment
                    value={subtitle}
                    onChange={(newValue) => {
                      setSubtitle(newValue)
                    }}
                    dataJson={formData}
                  />
                ),
              }}
            />
          </Stack>
          <TextField
            size="small"
            label="Content"
            value={content}
            onChange={(e) => {
              setContent(e.target.value)
            }}
            onBlur={() => {
              if (content) {
                setContent((current) => current.trim())
              }
            }}
            multiline
            minRows={2}
            maxRows={6}
            InputProps={{
              endAdornment: (
                <AddEnrichersAdornment
                  value={content}
                  onChange={(newValue) => {
                    setContent(newValue)
                  }}
                  dataJson={formData}
                />
              ),
            }}
          />
        </Stack>
      ),
    },
    {
      label: "Model",
      component: (
        <Stack spacing={2} style={{ maxHeight: 290, overflowY: "scroll" }}>
          <Typography variant="h6" className="card-title">
            Model
          </Typography>
          {format === NotificationTemplateFormat.MJML ? (
            <Grid container columns={4} gap={2}>
              {mjmlTemplates.map((item) => (
                <Grid key={item.id} item xs={1}>
                  <ButtonBase
                    style={{
                      borderRadius: 10,
                      overflow: "hidden",
                      border:
                        template && template.id === item.id
                          ? "1.5px solid black"
                          : "1.5px solid #E5E8EB",
                      paddingBottom: 16,
                      transition: "200ms",
                    }}
                    onClick={() => {
                      setTemplate(item)
                    }}
                  >
                    <Stack
                      gap={2}
                      justifyContent="flex-start"
                      style={{ width: "100%" }}
                    >
                      <img
                        src={item.image}
                        style={{
                          width: "100%",
                          height: 150,
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                      />
                      <Typography
                        variant="overline"
                        style={{ paddingInline: 16, textAlign: "left" }}
                      >
                        {item.label}
                      </Typography>
                    </Stack>
                  </ButtonBase>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>
              You selected the simple format, so you cannot add rich content.
            </Typography>
          )}
        </Stack>
      ),
    },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setHandle("")
        setType(NotificationTemplateType.EMAIL)
        setFormat(NotificationTemplateFormat.SIMPLE)
        setDefaultTranslation("")
        setEnrichers([])
        setFormData(null)
        setFormErrors([])
        setTitle("")
        setSubtitle("")
        setContent("")
        setTemplate(null)
        setCurrentSlide(0)
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (handle) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [handle])

  // create template
  const createNewTemplate = async () => {
    setLoading(true)
    const input: {
      handle: string
      format: NotificationTemplateFormat
      type: NotificationTemplateType
      defaultDataJson?: string
      requiredEnricherIds?: string[]
    } = {
      handle: handle,
      format: format,
      type: type,
      requiredEnricherIds: enrichers.map((item) => item.id),
    }

    if (formData) {
      input.defaultDataJson = JSON.stringify(formData)
    }

    const result = await createTemplate(input)

    if (result !== false) {
      const newTemplateId = result as string

      const documentInput: {
        parentId: string
        type: string
        notificationTemplateDocumentItems: {
          default: boolean
          lang: string
          title: string
          subtitle?: string
          contentBody: string
          richContentBody?: string
        }[]
      } = {
        parentId: `#id#${newTemplateId}#stage#DRAFT`,
        type: "NotificationTemplate",
        notificationTemplateDocumentItems: [
          {
            default: true,
            lang: defaultTranslation,
            title: title,
            subtitle: subtitle,
            contentBody: content,
          },
        ],
      }

      if (template) {
        documentInput.notificationTemplateDocumentItems[0].richContentBody =
          JSON.stringify(template.schema)
      }

      await createTemplateDocument(documentInput)

      setDialogOpen(false)
      setAnimation(false)
      setTimeout(() => {
        navigate(`/templates/${newTemplateId}`)
      }, 250)
    } else {
      setLoading(false)
    }
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText="Add Template"
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            Add Template
          </Typography>
          <Stepper
            activeStep={currentSlide}
            style={{
              paddingTop: 24,
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 8,
              maxWidth: "100%",
              overflow: "overlay",
            }}
          >
            {slides.map((slide) => {
              return (
                <Step key={slide.label}>
                  <StepLabel>{slide.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (!slides[currentSlide - 1]) {
                    if (showDiscardDialog) {
                      setDiscardDialogOpen(true)
                    } else {
                      handleDialogChange(e)
                    }
                  } else {
                    setCurrentSlide((current) => (current -= 1))
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  (currentSlide === 0 && !handle) ||
                  (currentSlide === 0 && !defaultTranslation) ||
                  (currentSlide === 2 && formErrors.length > 0) ||
                  (currentSlide === 3 && !title) ||
                  (currentSlide === 3 && !content) ||
                  (currentSlide === 4 &&
                    format === NotificationTemplateFormat.MJML &&
                    !template)
                }
                onMouseDown={
                  !slides[currentSlide + 1]
                    ? () => {
                        createNewTemplate()
                      }
                    : () => {
                        setCurrentSlide((current) => (current += 1))
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Save" : "Next"}
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={loading} />
    </Dialog>
  )
}

export default CreateTemplateDialog
