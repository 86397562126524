import {
  Button,
  CardContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { MainContext } from "../../controllers/main"
import { deepCopy } from "../../services/utilities/utility"
import { NotificationsContext } from "../../controllers/notifications"
import NotificationTemplate from "../../models/notificationTemplate"
import AddEnrichersAdornment from "./addEnrichersAdornment"
import { IEmailTemplate } from "easy-email-editor"
import RichContentEditDialog from "./richContentEditDialog"
import { NotificationTemplateFormat } from "../../services/config/enum"
import Handlebars from "handlebars"
import {
  EditRounded,
  LaptopRounded,
  SmartphoneRounded,
  TabletMacRounded,
} from "@mui/icons-material"
import { JsonToMjml } from "easy-email-core"
import mjml2html from "mjml-browser"

const TemplateTranslationsEdit = ({
  itemIndex,
  previewMode,
  setPreviewMode,
  previewWidth,
  setPreviewWidth,
}: {
  itemIndex: number
  previewMode: "mobile" | "tablet" | "desktop"
  setPreviewMode: Dispatch<SetStateAction<"mobile" | "tablet" | "desktop">>
  previewWidth: number
  setPreviewWidth: Dispatch<SetStateAction<number>>
}) => {
  const { showPadding } = useContext(MainContext)
  const {
    currentTemplate,
    setCurrentTemplate,
    editMode,
    translationsErrors,
    setTranslationsErrors,
    copyDetailsFromDefault,
  } = useContext(NotificationsContext)

  // current item
  const currentItem = currentTemplate.document.items[itemIndex]

  // errors managment
  const [titleError, setTitleError] = useState<boolean>(false) // error for title
  const [contentBodyError, setContentBodyError] = useState<boolean>(false) // error for content body

  // update translations errors on errors change
  useEffect(() => {
    if (titleError || contentBodyError) {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = true
    } else {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = false
    }

    setTranslationsErrors([...translationsErrors])
  }, [titleError, contentBodyError])

  // update errors on currentItem change
  useEffect(() => {
    if (!currentItem.title) {
      setTitleError(true)
    } else {
      setTitleError(false)
    }

    if (!currentItem.contentBody) {
      setContentBodyError(true)
    } else {
      setContentBodyError(false)
    }
  }, [currentItem])

  // rich content preview
  const richContentBodyHtml = useMemo(() => {
    if (currentItem.richContentBody) {
      const parsedBody = JSON.parse(currentItem.richContentBody)
      const mjml = JsonToMjml({
        mode: "production",
        data: parsedBody.content,
        context: parsedBody.content,
      })
      const html = mjml2html(mjml).html
      const template = Handlebars.compile(html, { strict: true })
      return template(JSON.parse(currentTemplate.defaultDataJson))
    }

    return ""
  }, [currentItem.richContentBody])

  // rich content edit
  const [richContentDialogOpen, setRichContentDialogOpen] =
    useState<boolean>(false)
  const richContentBody = useMemo(
    () => JSON.parse(currentItem.richContentBody) as IEmailTemplate,
    [currentItem.richContentBody]
  )

  // observe preview div width
  const previewTransitionTimeoutId = useRef<any>()

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries: any[]) => {
      const containerWidth = Math.round(entries[0].contentRect.width + 2)
      setPreviewWidth(containerWidth)

      if (containerWidth <= 430) {
        setPreviewMode("mobile")
      } else if (containerWidth <= 820) {
        setPreviewMode("tablet")
      } else {
        setPreviewMode("desktop")
      }
    })

    // add resize observer to preview container
    const container = document.getElementById("rich-content-preview-container")
    resizeObserver.observe(container)

    // resize container to last width
    if (previewWidth) {
      container.style.width = `${previewWidth}px`
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" style={{ height: 28 }}>
          <Typography variant="h6" className="card-title">
            Details
          </Typography>
          <Stack
            alignItems="center"
            justifyContent="flex-end"
            style={{ width: "100%" }}
            direction="row"
            spacing={2}
          >
            <FormGroup style={{ marginRight: -16 }}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editMode}
                    checked={currentItem.isDefault}
                    onChange={(e) => {
                      const currentTemplateCopy: NotificationTemplate =
                        deepCopy(currentTemplate)
                      currentTemplateCopy.document.items[itemIndex].isDefault =
                        e.target.checked
                      if (e.target.checked) {
                        currentTemplateCopy.document.items.forEach(
                          (item, index) => {
                            if (index !== itemIndex) {
                              item.isDefault = false
                            }
                          }
                        )
                      }
                      setCurrentTemplate(currentTemplateCopy)
                    }}
                  />
                }
                label="Default"
              />
            </FormGroup>
            <Divider orientation="vertical" flexItem />
            <Button
              size="small"
              variant="outlined"
              style={{ height: 26, fontSize: 12 }}
              onClick={() => {
                copyDetailsFromDefault(itemIndex)
              }}
              disabled={
                currentItem.isDefault ||
                !editMode ||
                !currentTemplate.document.items.filter((item) => item.isDefault)
                  .length
              }
            >
              Copy from default
            </Button>
          </Stack>
        </Stack>
        <Stack direction="row" gap={2}>
          <TextField
            fullWidth
            size="small"
            label="Title"
            value={currentItem.title}
            onChange={(e) => {
              const currentTemplateCopy: NotificationTemplate =
                deepCopy(currentTemplate)
              currentTemplateCopy.document.items[itemIndex].title =
                e.target.value
              setCurrentTemplate(currentTemplateCopy)

              // errors check
              if (!e.target.value) {
                setTitleError(true)
              } else {
                setTitleError(false)
              }
            }}
            disabled={!editMode}
            error={titleError}
            helperText={titleError ? "This field is required" : ""}
            onBlur={() => {
              if (currentTemplate.document.items[itemIndex].title) {
                const currentTemplateCopy: NotificationTemplate =
                  deepCopy(currentTemplate)
                currentTemplateCopy.document.items[itemIndex].title =
                  currentTemplateCopy.document.items[itemIndex].title.trim()
                setCurrentTemplate(currentTemplateCopy)
              }
            }}
            InputProps={{
              endAdornment: (
                <AddEnrichersAdornment
                  value={currentItem.title}
                  onChange={(newValue) => {
                    const currentTemplateCopy: NotificationTemplate =
                      deepCopy(currentTemplate)
                    currentTemplateCopy.document.items[itemIndex].title =
                      newValue
                    setCurrentTemplate(currentTemplateCopy)
                  }}
                  dataJson={JSON.parse(currentTemplate.defaultDataJson)}
                />
              ),
            }}
          />
          <TextField
            fullWidth
            size="small"
            label="Subtitle (optional)"
            value={currentItem.subtitle ?? ""}
            onChange={(e) => {
              const currentTemplateCopy: NotificationTemplate =
                deepCopy(currentTemplate)
              currentTemplateCopy.document.items[itemIndex].subtitle =
                e.target.value
              setCurrentTemplate(currentTemplateCopy)
            }}
            disabled={!editMode}
            onBlur={() => {
              if (currentTemplate.document.items[itemIndex].subtitle) {
                const currentTemplateCopy: NotificationTemplate =
                  deepCopy(currentTemplate)
                currentTemplateCopy.document.items[itemIndex].subtitle =
                  currentTemplateCopy.document.items[itemIndex].subtitle.trim()
                setCurrentTemplate(currentTemplateCopy)
              }
            }}
            InputProps={{
              endAdornment: (
                <AddEnrichersAdornment
                  value={currentItem.subtitle}
                  onChange={(newValue) => {
                    const currentTemplateCopy: NotificationTemplate =
                      deepCopy(currentTemplate)
                    currentTemplateCopy.document.items[itemIndex].subtitle =
                      newValue
                    setCurrentTemplate(currentTemplateCopy)
                  }}
                  dataJson={JSON.parse(currentTemplate.defaultDataJson)}
                />
              ),
            }}
          />
        </Stack>
        <TextField
          fullWidth
          size="small"
          label="Content"
          multiline
          minRows={2}
          value={currentItem.contentBody}
          onChange={(e) => {
            const currentTemplateCopy: NotificationTemplate =
              deepCopy(currentTemplate)
            currentTemplateCopy.document.items[itemIndex].contentBody =
              e.target.value
            setCurrentTemplate(currentTemplateCopy)

            // errors check
            if (!e.target.value) {
              setContentBodyError(true)
            } else {
              setContentBodyError(false)
            }
          }}
          disabled={!editMode}
          error={contentBodyError}
          helperText={contentBodyError ? "This field is required" : ""}
          onBlur={() => {
            if (currentTemplate.document.items[itemIndex].contentBody) {
              const currentTemplateCopy: NotificationTemplate =
                deepCopy(currentTemplate)
              currentTemplateCopy.document.items[itemIndex].contentBody =
                currentTemplateCopy.document.items[itemIndex].contentBody.trim()
              setCurrentTemplate(currentTemplateCopy)
            }
          }}
          InputProps={{
            endAdornment: (
              <AddEnrichersAdornment
                value={currentItem.contentBody}
                onChange={(newValue) => {
                  const currentTemplateCopy: NotificationTemplate =
                    deepCopy(currentTemplate)
                  currentTemplateCopy.document.items[itemIndex].contentBody =
                    newValue
                  setCurrentTemplate(currentTemplateCopy)
                }}
                dataJson={JSON.parse(currentTemplate.defaultDataJson)}
              />
            ),
          }}
        />
        {/* rich content */}
        {currentTemplate.format === NotificationTemplateFormat.MJML && (
          <Stack gap={2}>
            <Stack
              direction="row"
              alignItems="center"
              style={{ position: "relative" }}
            >
              <Typography variant="h6" className="card-title">
                Rich content
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                gap={2}
                style={{ position: "absolute", right: 0 }}
              >
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: "0.7rem",
                  }}
                >
                  {previewWidth}px
                </Typography>
                <ToggleButtonGroup
                  value={previewMode}
                  exclusive
                  color="primary"
                  onChange={(e, newValue) => {
                    console.log(newValue)
                    const container = document.getElementById(
                      "rich-content-preview-container"
                    )
                    if (newValue) {
                      container.style.transition =
                        "400ms cubic-bezier(.47,1.5,.41,.8)"
                      if (newValue === "mobile") {
                        container.style.width = "375px"
                      } else if (newValue === "tablet") {
                        container.style.width = "820px"
                      } else {
                        container.style.width = "100%"
                      }
                      if (previewTransitionTimeoutId.current) {
                        clearTimeout(previewTransitionTimeoutId.current)
                      }
                      previewTransitionTimeoutId.current = setTimeout(() => {
                        container.style.transition = "none"
                      }, 400)
                    }
                  }}
                  style={{
                    height: 30,
                    border: "1px solid rgba(82, 98, 186, 0.5)",
                  }}
                >
                  <ToggleButton
                    value="mobile"
                    color="primary"
                    style={{
                      height: 20.5,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  >
                    <SmartphoneRounded style={{ fontSize: 16 }} />
                  </ToggleButton>
                  <ToggleButton
                    value="tablet"
                    color="primary"
                    style={{
                      height: 20.5,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  >
                    <TabletMacRounded style={{ fontSize: 16 }} />
                  </ToggleButton>
                  <ToggleButton
                    value="desktop"
                    color="primary"
                    style={{
                      height: 20.5,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  >
                    <LaptopRounded style={{ fontSize: 16 }} />
                  </ToggleButton>
                </ToggleButtonGroup>
                <Button
                  variant="outlined"
                  disabled={!editMode}
                  style={{ height: 30 }}
                  onClick={() => {
                    setRichContentDialogOpen(true)
                  }}
                  startIcon={<EditRounded style={{ fontSize: 16 }} />}
                >
                  Edit
                </Button>
              </Stack>
            </Stack>
            <Stack alignItems="center">
              <div
                id="rich-content-preview-container"
                style={{
                  width: "100%",
                  minWidth: 375,
                  maxWidth: "100%",
                  borderRadius: 8,
                  border: "1px solid #DCE0E4",
                  position: "relative",
                  resize: "horizontal",
                  overflow: "auto",
                }}
                dangerouslySetInnerHTML={{ __html: richContentBodyHtml }}
              />
            </Stack>
          </Stack>
        )}
      </Stack>
      {/* dialogs */}
      <RichContentEditDialog
        open={richContentDialogOpen}
        setOpen={setRichContentDialogOpen}
        template={richContentBody}
        dataJson={JSON.parse(currentTemplate.defaultDataJson)}
        onEditorSubmit={(values) => {
          console.log(values.content)
          const currentTemplateCopy: NotificationTemplate =
            deepCopy(currentTemplate)
          currentTemplateCopy.document.items[itemIndex].richContentBody =
            JSON.stringify({ content: values.content })
          setCurrentTemplate(currentTemplateCopy)
        }}
      />
    </CardContent>
  )
}

export default TemplateTranslationsEdit
