import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grow,
  IconButton,
  List,
  ListItem,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import EditContainer from "../../components/global/editContainer"
import PageSpinner from "../../components/global/pageSpinner"
import { MainContext } from "../../controllers/main"
import {
  capitalizeFirstCharacter,
  deepCopy,
} from "../../services/utilities/utility"
import { LanguagesContext } from "../../controllers/languages"
import { AddCircleRounded } from "@mui/icons-material"
import LoadingBackdrop from "../../components/global/loadingBackdrop"
import CantPerformActionDialog from "../../components/global/cantPerformActionDialog"
import EditHeadingWithStages from "../../components/global/editHeadingWithStages"
import {
  NotificationTemplateValidationStatus,
  Stage,
} from "../../services/config/enum"
import { NotificationsContext } from "../../controllers/notifications"
import TemplateGeneralEdit from "../../components/notification/templateGeneralEdit"
import TemplateTranslationsEdit from "../../components/notification/templateTranslationsEdit"

const TemplateEdit = () => {
  const { animation, setAnimation, setChangesSaved, showPadding } =
    useContext(MainContext)
  const { languages, languagesForEpisodeTabs } = useContext(LanguagesContext)
  const {
    loading: controllerLoading,
    setLoading: setControllerLoading,
    editMode,
    setEditMode,
    doneChanges,
    cancelChanges,
    currentTemplate,
    getCurrentTemplate,
    preChangesCurrentTemplate,
    addTranslation,
    hasError,
    upsertTemplateParent,
    upsertTemplateDocument,
    publishTemplate,
    validateTemplate,
  } = useContext(NotificationsContext)
  const { templateId } = useParams()
  const navigate = useNavigate()

  // prevent tab close
  useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault()
      console.log("beforeunload event triggered")
      return (event.returnValue = "Are you sure you want to exit?")
    }

    window.addEventListener("beforeunload", handleTabClose)

    return () => {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [])

  // loading
  const [loading, setLoading] = useState<boolean>(true)

  // page error
  const [pageError, setPageError] = useState<boolean>(false)

  // fetch current template
  const fetchAll = async () => {
    const noErrors = await getCurrentTemplate(templateId)
    if (noErrors) {
      setLoading(false)
      setControllerLoading(false)
    } else {
      setPageError(true)
    }
  }

  useEffect(() => {
    fetchAll()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // tabs
  const [selectedTab, setSelectedTab] = useState<number>(0)

  // add translation dialog
  const [addTranslationOpen, setAddTranslationOpen] = useState<boolean>(false)
  const [translationToAdd, setTranslationToAdd] = useState<
    { value: string; label: string; flag?: string } | undefined
  >()

  const handleAddTranslationClose = () => {
    setAddTranslationOpen(false)
    setTimeout(() => {
      setTranslationToAdd(undefined)
    }, 150)
  }

  // no default translation alert dialog
  const [
    noDefaultTranslationAlertDialogOpen,
    setNoDefaultTranslationAlertDialogOpen,
  ] = useState<boolean>(false)

  // rich content preview
  const [previewMode, setPreviewMode] = useState<
    "mobile" | "tablet" | "desktop"
  >("desktop")
  const [previewWidth, setPreviewWidth] = useState<number>(0)

  // validation error info dialog
  const [infoDialogOpen, setInfoDialogOpen] = useState<boolean>(false)
  const handleInfoDialogClose = useCallback(() => setInfoDialogOpen(false), [])

  return loading ? (
    <PageSpinner
      showBackButton={pageError}
      backButtonOnClick={() => {
        setAnimation(false)
        navigate("/templates")
      }}
    />
  ) : (
    <EditContainer>
      <EditHeadingWithStages
        saveButtonLoading={controllerLoading}
        backButtonOnClick={() => {
          setAnimation(false)
          setTimeout(() => {
            navigate("/templates")
          }, 300)
        }}
        title={
          preChangesCurrentTemplate.document.items.filter(
            (documentItem) => documentItem.isDefault
          )[0].title
        }
        id={preChangesCurrentTemplate.id}
        editModeActive={editMode}
        setEditModeActive={setEditMode}
        saveButtonDisabled={
          hasError ||
          (currentTemplate.stage === Stage.PUBLISHED && !doneChanges)
        }
        undoButtonDisabled={!doneChanges}
        doneChanges={doneChanges}
        undoButtonOnClick={() => {
          if (selectedTab > preChangesCurrentTemplate.document.items.length) {
            setSelectedTab(preChangesCurrentTemplate.document.items.length)
          }
          cancelChanges()
        }}
        saveButtonOnClick={async () => {
          if (!doneChanges && currentTemplate.stage === Stage.DRAFT) {
            setControllerLoading(true)

            if (
              currentTemplate.validationStatus ===
                NotificationTemplateValidationStatus.NOT_CHECKED ||
              currentTemplate.validationStatus ===
                NotificationTemplateValidationStatus.INVALID
            ) {
              await validateTemplate()

              setControllerLoading(false)

              return
            }

            const noErrors = await publishTemplate()

            if (noErrors) {
              setControllerLoading(false)
              setChangesSaved(true)
            } else {
              setControllerLoading(false)
            }

            return
          }

          if (
            currentTemplate.document.items.filter((item) => item.isDefault)
              .length
          ) {
            setControllerLoading(true)

            let noErrors = await upsertTemplateParent()

            if (noErrors) {
              noErrors = await upsertTemplateDocument()
              if (noErrors) {
                setControllerLoading(false)
                setChangesSaved(true)
              } else {
                setControllerLoading(false)
              }
            } else {
              setControllerLoading(false)
            }
          } else {
            setNoDefaultTranslationAlertDialogOpen(true)
          }
        }}
        stage={doneChanges ? Stage.EDITED : currentTemplate.stage}
        showValidationStatus
        validationStatus={currentTemplate.validationStatus}
        validationInfoOnClick={() => {
          setInfoDialogOpen(true)
        }}
      />
      <Grow
        in={animation}
        timeout={300}
        style={{
          margin: showPadding === "yes" ? 16 : 0,
          marginTop: 0,
        }}
      >
        <Stack>
          {/* languages tabs */}
          <Stack direction="row" justifyContent="flex-start">
            <Card
              variant="outlined"
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: showPadding === "yes" ? null : 0,
                borderBottom: "none",
                borderLeft: showPadding === "yes" ? null : "none",
              }}
            >
              <Stack direction="row" justifyContent="flex-start">
                <Tabs
                  value={selectedTab}
                  onChange={(e: any, newValue: number) => {
                    setSelectedTab(newValue)
                  }}
                  aria-label="languages tabs"
                >
                  <Tab label="General" style={{ minWidth: 180 }} />
                  {currentTemplate.document.items.map((item) => (
                    <Tab
                      key={item.lang}
                      style={{ minWidth: 180 }}
                      label={
                        <Stack direction="row" spacing={1} alignItems="center">
                          <span>
                            {
                              languagesForEpisodeTabs.filter(
                                (arrayItem) => arrayItem.value === item.lang
                              )[0].flag
                            }
                          </span>
                          {currentTemplate.document.items.filter(
                            (documentItem) => documentItem.lang === item.lang
                          )[0].isDefault ? (
                            <Stack
                              direction="row"
                              spacing={0.5}
                              alignItems="center"
                            >
                              <span>
                                {
                                  languagesForEpisodeTabs.filter(
                                    (arrayItem) => arrayItem.value === item.lang
                                  )[0].label
                                }
                              </span>
                              <span style={{ fontSize: 10, marginTop: 2 }}>
                                (Default)
                              </span>
                            </Stack>
                          ) : (
                            <span>
                              {
                                languagesForEpisodeTabs.filter(
                                  (arrayItem) => arrayItem.value === item.lang
                                )[0].label
                              }
                            </span>
                          )}
                        </Stack>
                      }
                    />
                  ))}
                </Tabs>
                <Divider orientation="vertical" variant="middle" flexItem />
                <div
                  style={{
                    minWidth: 70,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    disabled={!editMode}
                    aria-label="add language button"
                    component="span"
                    style={{ width: 48, height: 48 }}
                    onClick={() => {
                      setAddTranslationOpen(true)
                    }}
                  >
                    <AddCircleRounded />
                  </IconButton>
                </div>
              </Stack>
            </Card>
          </Stack>
          <Card style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
            {!selectedTab ? (
              <TemplateGeneralEdit />
            ) : (
              <TemplateTranslationsEdit
                itemIndex={selectedTab - 1}
                previewMode={previewMode}
                setPreviewMode={setPreviewMode}
                previewWidth={previewWidth}
                setPreviewWidth={setPreviewWidth}
              />
            )}
          </Card>
        </Stack>
      </Grow>
      <LoadingBackdrop open={controllerLoading} />
      {/* add translation dialog */}
      <Dialog onClose={handleAddTranslationClose} open={addTranslationOpen}>
        <DialogTitle>Add translation</DialogTitle>
        {translationToAdd ? (
          <DialogContent>
            <DialogContentText id="alert-dialog-description-add-translation">
              Are you sure you want to add{" "}
              <b>{translationToAdd.label.toUpperCase()}</b>?
            </DialogContentText>
          </DialogContent>
        ) : languagesForEpisodeTabs.filter((item) => {
            if (
              item.value !== "general" &&
              !currentTemplate.document.items.filter(
                (translationItem) => translationItem.lang === item.value
              )[0]
            ) {
              return item
            }
          }).length ? (
          <List sx={{ pt: 0, minWidth: 300 }}>
            {languagesForEpisodeTabs
              .filter((item) => {
                if (
                  item.value !== "general" &&
                  !currentTemplate.document.items.filter(
                    (translationItem) => translationItem.lang === item.value
                  )[0]
                ) {
                  return item
                }
              })
              .map((item) => (
                <ListItem
                  button
                  onClick={() => {
                    setTranslationToAdd(item)
                  }}
                  key={item.value}
                >
                  <Stack direction="row" spacing={1}>
                    <span>{item.flag}</span>
                    <span>{item.label.toUpperCase()}</span>
                  </Stack>
                </ListItem>
              ))}
          </List>
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description-add-translation">
              You have already added all languages available
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleAddTranslationClose}>Cancel</Button>
          {translationToAdd && (
            <Button
              onClick={() => {
                addTranslation(translationToAdd.value)
                handleAddTranslationClose()
                setSelectedTab(currentTemplate.document.items.length)
              }}
            >
              Add {translationToAdd.label}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {/* no default translation alert dialog */}
      <CantPerformActionDialog
        open={noDefaultTranslationAlertDialogOpen}
        setOpen={setNoDefaultTranslationAlertDialogOpen}
        content="There must be a default translation"
      />
      {/* validation error info dialog */}
      <Dialog open={infoDialogOpen} onClose={handleInfoDialogClose}>
        <DialogTitle>Validation errors</DialogTitle>
        <DialogContent style={{ minWidth: 375 }}>
          <Stack gap={3}>
            {currentTemplate.invalidLangs.map((item) => (
              <Stack key={item.lang} gap={2}>
                <Typography variant="overline" style={{ fontSize: "1rem" }}>
                  {
                    languagesForEpisodeTabs.find(
                      (lang) => lang.value === item.lang
                    ).label
                  }
                </Typography>
                {item.validation
                  .filter((validationItem) => validationItem.errors.length)
                  .map((validationItem) => (
                    <Stack key={validationItem.field} gap={1}>
                      <Typography
                        style={{
                          fontWeight: 600,
                          fontSize: "0.95rem",
                          paddingLeft: 8,
                        }}
                      >
                        {capitalizeFirstCharacter(
                          validationItem.field
                            .replace(/([a-z])([A-Z])/g, "$1 $2")
                            .replace("Body", "")
                        )}
                      </Typography>
                      {validationItem.errors.map((error, index) => (
                        <Typography
                          key={index}
                          style={{ fontSize: "0.85rem", paddingLeft: 16 }}
                        >
                          {error}
                        </Typography>
                      ))}
                    </Stack>
                  ))}
              </Stack>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInfoDialogClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </EditContainer>
  )
}

export default TemplateEdit
