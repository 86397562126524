import { LinearProgress, TableCell, TableRow, Typography } from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import ListTable from "../../global/listTable"
import TableSpinner from "../../global/tableSpinner"
import { MainContext } from "../../../controllers/main"
import ListTopBar from "../../global/listTopBar"
import { TagsContext } from "../../../controllers/tags"
import { calculateTimeElapsed } from "../../../services/utilities/utility"
import Category from "../../../models/category"
import CreateMainCategoryDialog from "./createMainCategoryDialog"
import { useNavigate } from "react-router-dom"
import TitleTableCellCategory from "../general/titleTableCellCategory"
import ListFilters from "../../global/listFilters"
import { AutocompleteOption } from "../../../services/config/interfaces"
import TranslationsStack from "../../global/translationsStack"

const MainCategoriesListTab = () => {
  const { showPadding, setAnimation } = useContext(MainContext)
  const {
    mainCategoriesLoading,
    updatingMainCategoriesList,
    mainCategoriesList,
    getMainCategoriesList,
    mainCategoriesFilters,
    setMainCategoriesFilters,
  } = useContext(TagsContext)
  const navigate = useNavigate()

  // list background update
  const backgroundUpdate = () => {
    if (!mainCategoriesList.length) {
      getMainCategoriesList()
    } else {
      getMainCategoriesList(false)
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    backgroundUpdate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create action dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // text field value for search
  const [textFieldValue, setTextFieldValue] = useState<string>("")

  return (
    <div style={{ position: "relative" }}>
      {updatingMainCategoriesList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 136,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <ListTopBar
        loading={mainCategoriesLoading}
        textFieldValue={textFieldValue}
        textFieldDisabled={updatingMainCategoriesList}
        textFieldPlaceholder="Search Main Categories"
        addButtonLabel="Add Main Category"
        textFieldOnChange={(e) => {
          setTextFieldValue(e.target.value)
        }}
        textFieldOnKeyDown={(e) => {
          if (e.key === "Enter") {
            mainCategoriesFilters.name = textFieldValue
            setMainCategoriesFilters({ ...mainCategoriesFilters })
          }
        }}
        cancelButtonOnClick={() => {
          setTextFieldValue("")
          mainCategoriesFilters.name = ""
          setMainCategoriesFilters({ ...mainCategoriesFilters })
        }}
        searchButtonOnClick={() => {
          mainCategoriesFilters.name = textFieldValue
          setMainCategoriesFilters({ ...mainCategoriesFilters })
        }}
        addButtonOnClick={handleDialogChange}
      />
      <ListFilters
        disabled={updatingMainCategoriesList}
        filters={[
          {
            type: "language",
            value: mainCategoriesFilters.lang,
            setValue: (newValue: AutocompleteOption) => {
              mainCategoriesFilters.lang = newValue
              setMainCategoriesFilters({ ...mainCategoriesFilters })
            },
          },
        ]}
        style={{ paddingInline: 10 }}
      />
      {mainCategoriesLoading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 248px)"
              : "calc(100vh - 115px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 248px)"
              : "calc(100vh - 115px)"
          }
          headingItems={["Title & ID", "Code", "Translations", "Updated"]}
          tableBody={mainCategoriesList.map((item: Category) => (
            <TableRow
              key={item.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
              hover
              onClick={() => {
                setAnimation(false)
                setTimeout(() => {
                  navigate(`/tags/maincategory/${item.id}`)
                }, 250)
              }}
            >
              <TitleTableCellCategory
                title={item.name}
                id={item.id}
                backTagColor={item.backTagColor}
                foreColor={item.foreColor}
              />
              <TableCell>
                <Typography>{item.code}</Typography>
              </TableCell>
              <TableCell>
                <TranslationsStack translations={item.translations} />
              </TableCell>
              <TableCell style={{ minWidth: 120 }}>
                <Typography variant="caption">
                  {calculateTimeElapsed(item.updatedAt)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        />
      )}
      <CreateMainCategoryDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
      />
    </div>
  )
}

export default MainCategoriesListTab
