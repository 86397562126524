import ActionGroup from "./actionGroup"
import ActionSavingMetrics from "./actionSavingMetrics"
import ActionsDocument from "./actionsDocument"
import Category from "./category"
import Sdg from "./sdg"
import SdgTarget from "./sdgTarget"

class Action {
  id?: string
  updatedAt?: string
  handle?: string
  isRepeatable?: boolean
  image?: string
  timesPerDay?: number
  points?: number
  savingMetrics?: ActionSavingMetrics[]
  sdgs?: {
    primary: boolean
    sdg: Sdg
  }[]
  sdgTargets?: {
    primary: boolean
    sdgTarget: SdgTarget
  }[]
  document?: { items: ActionsDocument[]; parentId: string }
  actionGroups?: { actionGroup: ActionGroup }[]
  category?: Category
  featured?: boolean
  teamId?: string

  constructor(
    id?: string,
    updatedAt?: string,
    handle?: string,
    isRepeatable?: boolean,
    image?: string,
    timesPerDay?: number,
    points?: number,
    savingMetrics?: {
      metrics: {
        action: number
        activity: number
        co2Saving: number
        energySaving: number
        waterSaving: number
      }
      location: {
        id: string
        name: string
        __typename: "Country" | "Region" | "SubRegion" | "DefaultLocation"
      }
    }[],
    sdgs?: {
      primary: boolean
      sdg: Sdg
    }[],
    sdgTargets?: {
      primary: boolean
      sdgTarget: SdgTarget
    }[],
    document?: { items: ActionsDocument[]; parentId: string },
    actionGroups?: { actionGroup: ActionGroup }[],
    category?: Category,
    featured?: boolean,
    teamId?: string
  ) {
    this.id = id
    this.updatedAt = updatedAt
    this.handle = handle
    this.isRepeatable = isRepeatable
    this.image = image
    this.timesPerDay = timesPerDay
    this.points = points
    this.savingMetrics = savingMetrics
    this.sdgs = sdgs
    this.sdgTargets = sdgTargets
    this.document = document
    this.actionGroups = actionGroups
    this.category = category
    this.featured = featured
    this.teamId = teamId
  }
}

export default Action
