import {
  ArrowForwardIosRounded,
  ContentCopyRounded,
  OpenInFullRounded,
} from "@mui/icons-material"
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material"
import { ReactNode } from "react"

const DataCard = ({
  title,
  subtitle,
  description,
  htmlDescription,
  htmlContent,
  buttonLabel = "View",
  buttonDisabled = false,
  buttonOnClick,
}: {
  title: string
  subtitle?: string
  description?: string
  htmlDescription?: string
  htmlContent?: ReactNode
  buttonLabel?: "View" | "Open" | "Copy"
  buttonDisabled?: boolean
  buttonOnClick?: () => void
}) => {
  return (
    <Card
      style={{
        width: "100%",
        position: "relative",
        minHeight: 150,
        paddingBottom: 20,
      }}
    >
      <CardContent>
        <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
          {title}
        </Typography>
        {subtitle ? (
          <Typography sx={{ fontWeight: 300, mt: -1, fontSize: "0.95rem" }}>
            {subtitle}
          </Typography>
        ) : null}
        {description ? (
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "0.85rem",
              color: "#939EAA",
              lineHeight: 1.1,
              mt: 1,
            }}
          >
            {description}
          </Typography>
        ) : null}
        {htmlDescription ? (
          <div
            dangerouslySetInnerHTML={{ __html: htmlDescription }}
            style={{
              fontWeight: 400,
              fontSize: "0.85rem",
              lineHeight: 1.1,
              marginTop: 8,
            }}
          />
        ) : null}
        {htmlContent ? <div style={{ marginTop: 8 }}>{htmlContent}</div> : null}
      </CardContent>
      {buttonLabel && buttonOnClick ? (
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-start",
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
        >
          <Button
            size="small"
            endIcon={
              buttonLabel === "View" ? (
                <ArrowForwardIosRounded style={{ fontSize: 12 }} />
              ) : buttonLabel === "Open" ? (
                <OpenInFullRounded style={{ fontSize: 12 }} />
              ) : (
                <ContentCopyRounded style={{ fontSize: 12 }} />
              )
            }
            onClick={buttonOnClick}
            disabled={buttonDisabled}
          >
            {buttonLabel}
          </Button>
        </CardActions>
      ) : null}
    </Card>
  )
}

export default DataCard
