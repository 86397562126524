import { LinearProgress, TableCell, TableRow, Typography } from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import ListTable from "../../global/listTable"
import TableSpinner from "../../global/tableSpinner"
import { MainContext } from "../../../controllers/main"
import ListTopBar from "../../global/listTopBar"
import { TagsContext } from "../../../controllers/tags"
import Sdg from "../../../models/sdg"
import TitleTableCell from "../../global/titleTableCell"
import noImagePlaceholder from "../../../assets/images/no-image-placeholder.jpeg"
import { calculateTimeElapsed } from "../../../services/utilities/utility"
import CreateSdgDialog from "./createSdgDialog"
import { useNavigate } from "react-router-dom"
import ListFilters from "../../global/listFilters"
import { AutocompleteOption } from "../../../services/config/interfaces"
import TranslationsStack from "../../global/translationsStack"

const SdgsListTab = () => {
  const { showPadding, setAnimation } = useContext(MainContext)
  const {
    sdgsLoading,
    updatingSdgsList,
    sdgsList,
    getSdgsList,
    sdgsFilters,
    setSdgsFilters,
  } = useContext(TagsContext)
  const navigate = useNavigate()

  // list background update
  const backgroundUpdate = () => {
    if (!sdgsList.length) {
      getSdgsList()
    } else {
      getSdgsList(false)
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    backgroundUpdate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create action dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // text field value for search
  const [textFieldValue, setTextFieldValue] = useState<string>("")

  return (
    <div style={{ position: "relative" }}>
      {updatingSdgsList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 136,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <ListTopBar
        loading={sdgsLoading}
        textFieldValue={textFieldValue}
        textFieldDisabled={updatingSdgsList}
        textFieldPlaceholder="Search SDGs"
        addButtonLabel="Add SDG"
        textFieldOnChange={(e) => {
          setTextFieldValue(e.target.value)
        }}
        textFieldOnKeyDown={(e) => {
          if (e.key === "Enter") {
            sdgsFilters.name = textFieldValue
            setSdgsFilters({ ...sdgsFilters })
          }
        }}
        cancelButtonOnClick={() => {
          setTextFieldValue("")
          sdgsFilters.name = ""
          setSdgsFilters({ ...sdgsFilters })
        }}
        searchButtonOnClick={() => {
          sdgsFilters.name = textFieldValue
          setSdgsFilters({ ...sdgsFilters })
        }}
        addButtonOnClick={handleDialogChange}
      />
      <ListFilters
        disabled={updatingSdgsList}
        filters={[
          {
            type: "language",
            value: sdgsFilters.lang,
            setValue: (newValue: AutocompleteOption) => {
              sdgsFilters.lang = newValue
              setSdgsFilters({ ...sdgsFilters })
            },
          },
        ]}
        style={{ paddingInline: 10 }}
      />
      {sdgsLoading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 248px)"
              : "calc(100vh - 115px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 248px)"
              : "calc(100vh - 115px)"
          }
          headingItems={["Image", "Title & ID", "Translations", "Updated"]}
          tableBody={sdgsList.map((item: Sdg) => (
            <TableRow
              key={item.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
              hover
              onClick={() => {
                setAnimation(false)
                setTimeout(() => {
                  navigate(`/tags/sdg/${encodeURIComponent(item.id)}`)
                }, 250)
              }}
            >
              <TableCell>
                <div
                  style={{
                    width: 42,
                    height: 42,
                    borderRadius: 4,
                    backgroundImage: `url(${item.image ?? noImagePlaceholder})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </TableCell>
              <TitleTableCell title={item.name} id={item.id} />
              <TableCell>
                <TranslationsStack translations={item.translations} />
              </TableCell>
              <TableCell style={{ minWidth: 120 }}>
                <Typography variant="caption">
                  {calculateTimeElapsed(item.updatedAt)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        />
      )}
      <CreateSdgDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
      />
    </div>
  )
}

export default SdgsListTab
