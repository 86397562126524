import { Editor } from "react-draft-wysiwyg"
import { EditorState, ContentState, convertToRaw, RichUtils } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import "../../styles/htmlEditor.scss"

const HtmlEditorGlobal = ({
  text,
  setText,
  max,
  min,
  error,
  setError,
  errorType,
  setErrorType,
  label = "Text",
  options = ["inline", "history"],
  inlineOptions = ["bold", "italic", "underline", "superscript", "subscript"],
}: {
  text: string
  setText: Dispatch<SetStateAction<string>>
  max?: number
  min?: number
  error?: boolean
  setError?: Dispatch<SetStateAction<boolean>>
  errorType?: string
  setErrorType?: Dispatch<SetStateAction<string>>
  label?: string
  options?: string[]
  inlineOptions?: string[]
}) => {
  // placeholder
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(false)

  // has focus
  const [hasFocus, setHasFocus] = useState<boolean>(false)

  // editor state
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        htmlToDraft(text ?? "").contentBlocks,
        htmlToDraft(text ?? "").entityMap
      )
    )
  )

  useEffect(() => {
    let currentContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    )
      .replaceAll("\n", "")
      .replaceAll("&nbsp;", "")

    setText(currentContent)

    if (currentContent.replace(/(<([^>]+)>)/gi, "").length) {
      setShowPlaceholder(true)
    }

    if (setError && setErrorType) {
      if (currentContent.replace(/(<([^>]+)>)/gi, "").length > max) {
        setError(true)
        setErrorType("Maximum length is " + max + " characters")
      } else if (
        currentContent.replace(/(<([^>]+)>)/gi, "").length &&
        currentContent.replace(/(<([^>]+)>)/gi, "").length < min
      ) {
        setError(true)
        setErrorType("Minimum length is " + min + " characters")
      } else {
        setError(false)
        setErrorType("")
      }
    }
  }, [editorState])

  return (
    <div
      style={{ position: "relative", display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          position: "absolute",
          top: showPlaceholder ? -10 : 49,
          left: 9,
          color: error ? "#d3302f" : hasFocus ? "#000000" : "#939EAA",
          fontSize: showPlaceholder ? 12 : "1rem",
          backgroundColor: "white",
          paddingLeft: 5,
          paddingRight: 5,
          height: 12,
          transition: "180ms",
        }}
      >
        {label}
      </div>
      <Editor
        onFocus={() => {
          setShowPlaceholder(true)
          setHasFocus(true)
        }}
        onBlur={() => {
          setHasFocus(false)

          if (
            !draftToHtml(convertToRaw(editorState.getCurrentContent()))
              .replaceAll("\n", "")
              .replace(/(<([^>]+)>)/gi, "").length
          ) {
            setShowPlaceholder(false)
          }
        }}
        editorClassName={"editor"}
        toolbarClassName={"toolbar"}
        wrapperClassName={
          error && hasFocus
            ? "wrapper-error-focus-prismic"
            : error
            ? "wrapper-error-prismic"
            : hasFocus
            ? "wrapper-focus"
            : "wrapper"
        }
        editorState={editorState}
        onEditorStateChange={setEditorState}
        toolbar={{
          options: options,
          inline: {
            options: inlineOptions,
          },
        }}
        // stripPastedStyles
        handlePastedText={() => false}
        handleKeyCommand={(command) => {
          if (command === "split-block") {
            const newEditorState = RichUtils.insertSoftNewline(editorState)
            setEditorState(newEditorState)

            return "handled"
          }

          return "not-handled"
        }}
      />
      {error && (
        <p
          style={{
            color: "#d3302f",
            fontSize: "0.75rem",
            lineHeight: 1.66,
            marginLeft: 14,
            marginTop: 4,
          }}
        >
          {errorType}
        </p>
      )}
    </div>
  )
}

export default HtmlEditorGlobal
