import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import PageSpinner from "../../components/global/pageSpinner"
import EditContainer from "../../components/global/editContainer"
import EditHeading from "../../components/global/editHeading"
import { MainContext } from "../../controllers/main"
import { CircularProgress, Grow } from "@mui/material"
import { TeamsContext } from "../../controllers/teams"

const TeamReport = () => {
  const { animation, setAnimation, showPadding } = useContext(MainContext)
  const { preChangesCurrentTeam, getCurrentTeam } = useContext(TeamsContext)
  const navigate = useNavigate()
  const { teamId } = useParams()

  // loading
  const [loading, setLoading] = useState<boolean>(true)

  // page error
  const [pageError, setPageError] = useState<boolean>(false)

  // fetch team if missing
  const fetchTeam = async () => {
    if (!preChangesCurrentTeam) {
      const noErrors = await getCurrentTeam(teamId)
      if (noErrors) {
        setLoading(false)
      } else {
        setPageError(true)
      }
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchTeam()
  }, [])

  // iframe loading
  const [iframeLoading, setIframeLoading] = useState<boolean>(true)

  return loading ? (
    <PageSpinner
      showBackButton={pageError}
      backButtonOnClick={() => {
        navigate(
          window.location.pathname.slice(
            0,
            window.location.pathname.indexOf("/report")
          )
        )
      }}
    />
  ) : (
    <EditContainer>
      <EditHeading
        backButtonOnClick={() => {
          setAnimation(false)
          setTimeout(() => {
            navigate(
              window.location.pathname.slice(
                0,
                window.location.pathname.indexOf("/report")
              )
            )
          }, 300)
        }}
        title={preChangesCurrentTeam.name + " / Report"}
        id={preChangesCurrentTeam.id}
      />
      <Grow in={animation} timeout={350}>
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <iframe
            style={{
              width: "calc(100% - 280px)",
              height:
                showPadding === "yes"
                  ? "calc(100vh - 152px)"
                  : "calc(100vh - 134px)",
              overflow: "hidden",
              margin: showPadding === "yes" ? "16px" : 0,
              marginTop: 0,
              border: "none",
              borderRadius: 4,
            }}
            onLoad={() => {
              setIframeLoading(false)
            }}
            src={
              "https://lookerstudio.google.com/embed/reporting/acbb79d5-8c70-404c-8dca-ef1e17679e69/page/ctQsC?params=" +
              encodeURI(
                JSON.stringify({
                  CountryCounter: teamId,
                  DailyTeamIncrement: teamId,
                  DayOfWeek: teamId,
                  GeographicDistributionTeam: teamId,
                  HourLogsDistribution: teamId,
                  SDGHabits: teamId,
                  TeamActionsLogged: teamId,
                  TeamJoins: teamId,
                  TotalMetrics: teamId,
                  DailySavings: teamId,
                })
              )
            }
          />
          {iframeLoading && (
            <CircularProgress style={{ position: "absolute" }} />
          )}
        </div>
      </Grow>
    </EditContainer>
  )
}

export default TeamReport
