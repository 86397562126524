import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import { Dispatch, SetStateAction } from "react"

const CantPerformActionDialog = ({
  open,
  setOpen,
  content,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  content: string
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <DialogTitle>This action can't be performed</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => {
            setOpen(false)
          }}
          autoFocus
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CantPerformActionDialog
