import {
  Card,
  CardContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import ProductPrice from "../../models/productPrice"
import { DeleteRounded } from "@mui/icons-material"
import { MarketplaceContext } from "../../controllers/marketplace"

const PriceCardCreate = ({
  index,
  price,
  prices,
  setPrices,
}: {
  index: number
  price: ProductPrice
  prices: ProductPrice[]
  setPrices: Dispatch<SetStateAction<ProductPrice[]>>
}) => {
  const { currenciesList } = useContext(MarketplaceContext)

  // errors
  const [amountError, setAmountError] = useState<boolean>(false)
  const [currencyError, setCurrencyError] = useState<boolean>(false)

  // check for errors
  useEffect(() => {
    // amount error
    if (!price.amount || (price.amount && price.amount < 1)) {
      setAmountError(true)
    } else {
      setAmountError(false)
    }

    // currency error
    const sameCurrencyPriceIndex = prices.findIndex(
      (item) => item.currency === price.currency
    )
    if (sameCurrencyPriceIndex >= 0 && sameCurrencyPriceIndex < index) {
      setCurrencyError(true)
    } else {
      setCurrencyError(false)
    }
  }, [price.amount, price.currency, prices])

  return (
    <Card
      variant="outlined"
      sx={{
        boxShadow: "none",
        borderTopLeftRadius: !index ? undefined : 0,
        borderTopRightRadius: !index ? undefined : 0,
        borderBottomLeftRadius: index === prices.length - 1 ? undefined : 0,
        borderBottomRightRadius: index === prices.length - 1 ? undefined : 0,
        borderTop: index === 0 ? undefined : "none",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <CardContent
        style={{
          paddingTop: 12,
          paddingBottom: 12,
          transition: "150ms",
        }}
      >
        <Stack gap={1.5}>
          <Stack
            direction="row"
            alignItems="center"
            style={{ position: "relative" }}
            gap={1}
          >
            <Typography
              variant="h6"
              color={amountError || currencyError ? "error" : "default"}
              style={{
                fontSize: "1rem",
              }}
            >
              {currenciesList.length
                ? currenciesList.find((item) => item.id === price.currency).name
                : price.currency.toUpperCase()}
            </Typography>
            {amountError || currencyError ? (
              <Typography
                variant="overline"
                color="error"
                style={{ fontSize: "0.5rem", marginTop: 5 }}
              >
                (error)
              </Typography>
            ) : null}
            <IconButton
              size="small"
              color="error"
              style={{
                width: 40,
                height: 40,
                position: "absolute",
                right: -11,
              }}
              onClick={() => {
                prices.splice(index, 1)
                setPrices([...prices])
              }}
            >
              <DeleteRounded
                style={{
                  width: 22,
                }}
              />
            </IconButton>
          </Stack>
          <Stack direction="row" gap={2}>
            <TextField
              fullWidth
              size="small"
              label="Amount"
              value={price.amount ?? ""}
              onChange={(e) => {
                if (e.target.value && !Number.isNaN(parseInt(e.target.value))) {
                  price.amount = parseInt(e.target.value)
                } else {
                  price.amount = null
                }

                setPrices([...prices])
              }}
              error={amountError}
            />
            <FormControl fullWidth size="small" error={currencyError}>
              <InputLabel id="currency">Currency</InputLabel>
              <Select
                labelId="currency"
                label="Currency"
                value={price.currency}
                onChange={(e) => {
                  price.currency = e.target.value

                  setPrices([...prices])
                }}
              >
                {currenciesList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default PriceCardCreate
