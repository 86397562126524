import {
  Chip,
  LinearProgress,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import ListTable from "../../global/listTable"
import TableSpinner from "../../global/tableSpinner"
import { MainContext } from "../../../controllers/main"
import ListTopBar from "../../global/listTopBar"
import { TagsContext } from "../../../controllers/tags"
import TitleTableCell from "../../global/titleTableCell"
import { calculateTimeElapsed } from "../../../services/utilities/utility"
import { useNavigate } from "react-router-dom"
import Topic from "../../../models/topic"
import CreateTopicDialog from "./createTopicDialog"
import ListFilters from "../../global/listFilters"
import { AutocompleteOption } from "../../../services/config/interfaces"
import TranslationsStack from "../../global/translationsStack"

const TopicsListTab = () => {
  const { showPadding, setAnimation } = useContext(MainContext)
  const {
    topicsLoading,
    updatingTopicsList,
    topicsList,
    getTopicsList,
    topicsFilters,
    setTopicsFilters,
  } = useContext(TagsContext)
  const navigate = useNavigate()

  // list background update
  const backgroundUpdate = () => {
    if (!topicsList.length) {
      getTopicsList()
    } else {
      getTopicsList(false)
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    backgroundUpdate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create action dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // text field value for search
  const [textFieldValue, setTextFieldValue] = useState<string>("")

  return (
    <div style={{ position: "relative" }}>
      {updatingTopicsList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 136,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <ListTopBar
        loading={topicsLoading}
        textFieldValue={textFieldValue}
        textFieldDisabled={updatingTopicsList}
        textFieldPlaceholder="Search Topics"
        addButtonLabel="Add Topic"
        textFieldOnChange={(e) => {
          setTextFieldValue(e.target.value)
        }}
        textFieldOnKeyDown={(e) => {
          if (e.key === "Enter") {
            topicsFilters.name = textFieldValue
            setTopicsFilters({ ...topicsFilters })
          }
        }}
        cancelButtonOnClick={() => {
          setTextFieldValue("")
          topicsFilters.name = ""
          setTopicsFilters({ ...topicsFilters })
        }}
        searchButtonOnClick={() => {
          topicsFilters.name = textFieldValue
          setTopicsFilters({ ...topicsFilters })
        }}
        addButtonOnClick={handleDialogChange}
      />
      <ListFilters
        disabled={updatingTopicsList}
        filters={[
          {
            type: "language",
            value: topicsFilters.lang,
            setValue: (newValue: AutocompleteOption) => {
              topicsFilters.lang = newValue
              setTopicsFilters({ ...topicsFilters })
            },
          },
          {
            type: "mainTopic",
            value: topicsFilters.mainTopic,
            setValue: (newValue: AutocompleteOption | null) => {
              topicsFilters.mainTopic = newValue
              setTopicsFilters({ ...topicsFilters })
            },
          },
        ]}
        style={{ paddingInline: 10 }}
      />
      {topicsLoading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 248px)"
              : "calc(100vh - 115px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 248px)"
              : "calc(100vh - 115px)"
          }
          headingItems={[
            "Title & ID",
            "Code",
            "Translations",
            "Main Topic",
            "Updated",
          ]}
          tableBody={topicsList.map((item: Topic) => (
            <TableRow
              key={item.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
              hover
              onClick={() => {
                setAnimation(false)
                setTimeout(() => {
                  navigate(`/tags/topic/${item.id}`)
                }, 250)
              }}
            >
              <TitleTableCell title={item.name} id={item.id} />
              <TableCell>
                <Typography>{item.code}</Typography>
              </TableCell>
              <TableCell>
                <TranslationsStack translations={item.translations} />
              </TableCell>
              <TableCell>
                {item.mainTopic ? (
                  <Tooltip title={item.mainTopic.name}>
                    <Chip
                      label={item.mainTopic.name}
                      style={{
                        maxWidth: 120,
                        backgroundColor: "#DFE3FA",
                      }}
                      size="small"
                    />
                  </Tooltip>
                ) : (
                  <Chip
                    label="none"
                    size="small"
                    style={{
                      opacity: 0.3,
                      backgroundColor: "rgba(0, 0, 0, 0)",
                    }}
                  />
                )}
              </TableCell>
              <TableCell style={{ minWidth: 120 }}>
                <Typography variant="caption">
                  {calculateTimeElapsed(item.updatedAt)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        />
      )}
      <CreateTopicDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
      />
    </div>
  )
}

export default TopicsListTab
