import {
  AddCircleRounded,
  CloseRounded,
  DeleteRounded,
} from "@mui/icons-material"
import {
  AppBar,
  Autocomplete,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { ActionGroupsContext } from "../../controllers/actionGroups"
import { MainContext } from "../../controllers/main"
import ActionGroup from "../../models/actionGroup"
import {
  deepCopy,
  DialogTransition,
  renderOption,
  renderTags,
} from "../../services/utilities/utility"
import ImagePicker from "../global/imagePicker"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import Action from "../../models/action"
import ActionsList from "../../views/action/actionsList"
import { ActionsContext } from "../../controllers/actions"
import BadgePicker from "../global/badgePicker"
import Badge from "../../models/badge"
import "../../styles/actionGroupGeneralEdit.scss"
import { TagsContext } from "../../controllers/tags"
import Sdg from "../../models/sdg"
import SdgTarget from "../../models/sdgTarget"
import { FootprintQuestionsContext } from "../../controllers/footprintQuestions"
import TranslationsStack from "../global/translationsStack"
import DataCard from "../global/dataCard"

interface AutocompleteOption {
  label: string
  id: string
}

const ActionGroupGeneralEdit = ({
  setGlobalPreviewOpen,
}: {
  setGlobalPreviewOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { showPadding } = useContext(MainContext)
  const { resetFilters, setActionsList } = useContext(ActionsContext)
  const { footprintQuestionsLoading, footprintQuestionsList } = useContext(
    FootprintQuestionsContext
  )
  const {
    currentActionGroup,
    setCurrentActionGroup,
    editMode,
    handleError,
    setHandleError,
    maxIterationsError,
    setMaxIterationsError,
    categoryError,
    setCategoryError,
    secondarySdgsError,
    setSecondarySdgsError,
    secondarySdgTargetsError,
    setSecondarySdgTargetsError,
    footprintPredictionError,
    setFootprintPredictionError,
  } = useContext(ActionGroupsContext)
  const { categoriesLoading, categoriesList, sdgsList, sdgTargetsList } =
    useContext(TagsContext)

  // actions selection
  const [selectedActions, setSelectedActions] = useState<Action[]>([])
  const [actionsToFilter, setActionsToFilter] = useState<Action[]>([])
  const [addActionsDialogOpen, setAddActionsDialogOpen] =
    useState<boolean>(false)

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Data
        </Typography>
        <Stack direction="row" gap={1}>
          <DataCard
            title="Preview"
            description="Preview of how this Series appears inside the app."
            buttonLabel="Open"
            buttonDisabled={!currentActionGroup.category}
            buttonOnClick={() => {
              setGlobalPreviewOpen(true)
            }}
          />
        </Stack>
        <Typography variant="h6" className="card-title">
          Configuration
        </Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            size="small"
            label="Handle"
            disabled={!editMode}
            value={currentActionGroup.handle}
            onChange={(e) => {
              const currentActionGroupCopy: ActionGroup =
                deepCopy(currentActionGroup)
              currentActionGroupCopy.handle = e.target.value
              setCurrentActionGroup(currentActionGroupCopy)

              // check for errors
              if (!e.target.value.length) {
                setHandleError(true)
              } else {
                setHandleError(false)
              }
            }}
            error={handleError}
            helperText={handleError ? "This field is required" : ""}
            onBlur={() => {
              if (currentActionGroup.handle) {
                currentActionGroup.handle = currentActionGroup.handle.trim()
                setCurrentActionGroup({ ...currentActionGroup })
              }
            }}
          />
          <TextField
            fullWidth
            size="small"
            type="number"
            label="Maximum iterations"
            disabled={!editMode}
            value={currentActionGroup.maxIterations ?? ""}
            onChange={(e) => {
              const currentActionGroupCopy: ActionGroup =
                deepCopy(currentActionGroup)
              if (e.target.value.length) {
                if (
                  e.target.value.includes(".") ||
                  e.target.value.includes(",")
                ) {
                  currentActionGroupCopy.maxIterations = parseFloat(
                    e.target.value
                  )
                } else {
                  currentActionGroupCopy.maxIterations = parseInt(
                    e.target.value
                  )
                }
              } else {
                currentActionGroupCopy.maxIterations = null
              }
              setCurrentActionGroup(currentActionGroupCopy)

              // check for errors
              if (
                currentActionGroupCopy.maxIterations === null ||
                (currentActionGroupCopy.maxIterations !== null &&
                  currentActionGroupCopy.maxIterations < 1) ||
                (currentActionGroupCopy.maxIterations !== null &&
                  currentActionGroupCopy.maxIterations.toString().includes("."))
              ) {
                setMaxIterationsError(true)
              } else {
                setMaxIterationsError(false)
              }
            }}
            error={maxIterationsError}
            helperText={maxIterationsError ? "Invalid value" : ""}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth size="small" disabled={!editMode}>
            <InputLabel id="repeatable-select">Can become habit</InputLabel>
            <Select
              labelId="repeatable-select"
              label="Can become habit"
              value={currentActionGroup.canBecomeHabit ? "yes" : "no"}
              onChange={(e) => {
                const currentActionGroupCopy: ActionGroup =
                  deepCopy(currentActionGroup)
                if (e.target.value === "yes") {
                  currentActionGroupCopy.canBecomeHabit = true
                } else {
                  currentActionGroupCopy.canBecomeHabit = false
                }
                setCurrentActionGroup(currentActionGroupCopy)
              }}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small" disabled={!editMode}>
            <InputLabel id="featured-select">Featured</InputLabel>
            <Select
              labelId="featured-select"
              label="Featured"
              value={currentActionGroup.featured ? "yes" : "no"}
              onChange={(e) => {
                const currentActionGroupCopy: ActionGroup =
                  deepCopy(currentActionGroup)
                if (e.target.value === "yes") {
                  currentActionGroupCopy.featured = true
                } else {
                  currentActionGroupCopy.featured = false
                }
                setCurrentActionGroup(currentActionGroupCopy)
              }}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            size="small"
            type="number"
            label="Footprint prediction"
            disabled={!editMode}
            value={currentActionGroup.footprintPrediction ?? ""}
            onChange={(e) => {
              const currentActionGroupCopy: ActionGroup =
                deepCopy(currentActionGroup)
              if (e.target.value.length) {
                if (
                  e.target.value.includes(".") ||
                  e.target.value.includes(",")
                ) {
                  currentActionGroupCopy.footprintPrediction = parseFloat(
                    e.target.value
                  )
                  if (parseFloat(e.target.value) > 0) {
                    setFootprintPredictionError(true)
                  } else {
                    setFootprintPredictionError(false)
                  }
                } else {
                  currentActionGroupCopy.footprintPrediction = parseInt(
                    e.target.value
                  )
                  if (parseInt(e.target.value) > 0) {
                    setFootprintPredictionError(true)
                  } else {
                    setFootprintPredictionError(false)
                  }
                }
              } else {
                currentActionGroupCopy.footprintPrediction = null
                setFootprintPredictionError(true)
              }
              setCurrentActionGroup(currentActionGroupCopy)
            }}
            error={footprintPredictionError}
            helperText={footprintPredictionError ? "Invalid value" : ""}
            FormHelperTextProps={{
              style: {
                margin: 0,
                marginBottom: -25,
              },
            }}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Autocomplete
            disabled={!editMode || currentActionGroup.actions.length > 0}
            loading={categoriesLoading}
            fullWidth
            blurOnSelect
            size="small"
            value={
              currentActionGroup.category
                ? {
                    id: currentActionGroup.category.id,
                    label: currentActionGroup.category.name,
                  }
                : null
            }
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={categoriesList.map((item) => {
              return {
                id: item.id,
                label: item.name,
              }
            })}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Category"
                error={categoryError}
                helperText={
                  categoryError
                    ? "This field is required"
                    : currentActionGroup.actions.length
                    ? "Remove every Action from this Series to edit"
                    : ""
                }
                FormHelperTextProps={
                  categoryError
                    ? null
                    : {
                        style: {
                          margin: 0,
                          marginBottom: -25,
                        },
                      }
                }
              />
            )}
            onChange={(e: any, newValue: AutocompleteOption | null) => {
              if (newValue) {
                currentActionGroup.category = categoriesList.filter(
                  (item) => item.id === newValue.id
                )[0]
                setCategoryError(false)
              } else {
                currentActionGroup.category = null
                setCategoryError(true)
              }
              currentActionGroup.badge = null
              setCurrentActionGroup({ ...currentActionGroup })
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
          <Autocomplete
            disabled={!editMode}
            fullWidth
            size="small"
            loading={footprintQuestionsLoading}
            value={
              currentActionGroup.footprint
                ? {
                    id: currentActionGroup.footprint.questionId,
                    label: footprintQuestionsList.filter(
                      (item) =>
                        item.sk === currentActionGroup.footprint.questionId
                    )[0].description,
                  }
                : null
            }
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              footprintQuestionsList.length
                ? footprintQuestionsList.map((item) => {
                    return {
                      id: item.sk,
                      label: item.description,
                    }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField {...params} label="Footprint question" />
            )}
            renderOption={(props, option) => (
              <span
                {...props}
                key={option.id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography style={{ fontSize: 14 }}>{option.label}</Typography>
                <Typography style={{ fontSize: 11 }}>{option.id}</Typography>
              </span>
            )}
            onChange={(e: any, newValue: AutocompleteOption | null) => {
              if (newValue) {
                setCurrentActionGroup({
                  ...currentActionGroup!,
                  footprint: {
                    questionId: newValue.id,
                  },
                })
              } else {
                setCurrentActionGroup({
                  ...currentActionGroup!,
                  footprint: null,
                })
              }
            }}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Stack spacing={2} style={{ width: "50%" }}>
            <Typography variant="h6" className="card-title">
              Image
            </Typography>
            <ImagePicker
              title="Image"
              pickedImage={currentActionGroup.image ?? ""}
              setPickedImage={(selectedImage: {
                url: string
                id: string
                description?: string
              }) => {
                currentActionGroup.image = selectedImage.url
                setCurrentActionGroup({ ...currentActionGroup })
              }}
              filters={{ category: "action" }}
              disableCategoryFilter
              height={200}
              disabled={!editMode}
              width="100%"
            />
          </Stack>
          <Stack spacing={2} style={{ width: "50%" }}>
            <Typography variant="h6" className="card-title">
              Badge
            </Typography>
            <BadgePicker
              title="Badge"
              pickedBadge={currentActionGroup.badge ?? null}
              setPickedBadge={(selectedBadge: Badge) => {
                currentActionGroup.badge = selectedBadge
                setCurrentActionGroup({ ...currentActionGroup })
              }}
              height={200}
              disabled={!editMode}
              width="100%"
              categoryFilter={
                currentActionGroup.category
                  ? {
                      id: currentActionGroup.category.id,
                      label: currentActionGroup.category.name,
                    }
                  : null
              }
            />
          </Stack>
        </Stack>
        <Typography variant="h6" className="card-title">
          SDGs and SDG targets
        </Typography>
        <Stack direction="row" spacing={2}>
          <Autocomplete
            disabled={!editMode}
            getOptionDisabled={(option: AutocompleteOption) => {
              if (
                currentActionGroup.sdgs.filter(
                  (sdg) => sdg.sdg.id === option.id
                )[0] &&
                !currentActionGroup.sdgs.filter(
                  (sdg) => sdg.sdg.id === option.id
                )[0].primary
              ) {
                return true
              } else {
                return false
              }
            }}
            fullWidth
            size="small"
            value={
              currentActionGroup.sdgs.filter((item) => item.primary)[0]
                ? {
                    label:
                      (currentActionGroup.sdgs
                        .filter((item) => item.primary)[0]
                        .sdg.id.charAt(4) === "0"
                        ? currentActionGroup.sdgs
                            .filter((item) => item.primary)[0]
                            .sdg.id.slice(5) + ". "
                        : currentActionGroup.sdgs
                            .filter((item) => item.primary)[0]
                            .sdg.id.slice(4) + ". ") +
                      currentActionGroup.sdgs.filter((item) => item.primary)[0]
                        .sdg.name,
                    id: currentActionGroup.sdgs.filter(
                      (item) => item.primary
                    )[0].sdg.id,
                  }
                : null
            }
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              sdgsList.length
                ? sdgsList.map((sdg: Sdg) => {
                    let idForLabel =
                      sdg.id.charAt(4) === "0"
                        ? sdg.id.slice(5) + ". "
                        : sdg.id.slice(4) + ". "

                    return { label: idForLabel + sdg.name, id: sdg.id }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField {...params} label="Primary SDG" />
            )}
            onChange={(e: any, newValue: AutocompleteOption | null) => {
              if (newValue) {
                setCurrentActionGroup({
                  ...currentActionGroup!,
                  sdgs: [
                    {
                      primary: true,
                      sdg: {
                        id: newValue.id,
                        name: newValue.label.slice(
                          newValue.label.indexOf(".") + 2
                        ),
                      },
                    },
                    ...currentActionGroup.sdgs.filter((sdg) => !sdg.primary),
                  ],
                })
              } else {
                if (currentActionGroup.sdgs.filter((sdg) => sdg.primary)[0]) {
                  const indexToRemove = currentActionGroup.sdgs.findIndex(
                    (sdg) => sdg.primary
                  )
                  currentActionGroup.sdgs.splice(indexToRemove, 1)
                  setCurrentActionGroup({ ...currentActionGroup })
                }
              }
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
          <Autocomplete
            disabled={!editMode}
            getOptionDisabled={(option: AutocompleteOption) => {
              if (
                currentActionGroup.sdgs.filter(
                  (sdg) => sdg.sdg.id === option.id
                )[0] &&
                currentActionGroup.sdgs.filter(
                  (sdg) => sdg.sdg.id === option.id
                )[0].primary
              ) {
                return true
              } else {
                return false
              }
            }}
            multiple
            fullWidth
            onBlur={() => {
              setSecondarySdgsError(false)
            }}
            size="small"
            value={currentActionGroup.sdgs
              .filter((sdg) => !sdg.primary)
              .map((sdg) => {
                let idForLabel =
                  sdg.sdg.id.charAt(4) === "0"
                    ? sdg.sdg.id.slice(5) + ". "
                    : sdg.sdg.id.slice(4) + ". "

                return {
                  label: idForLabel + sdg.sdg.name,
                  id: sdg.sdg.id,
                }
              })}
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              sdgsList.length
                ? sdgsList.map((sdg: Sdg) => {
                    let idForLabel =
                      sdg.id.charAt(4) === "0"
                        ? sdg.id.slice(5) + ". "
                        : sdg.id.slice(4) + ". "

                    return { label: idForLabel + sdg.name, id: sdg.id }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField
                {...params}
                error={secondarySdgsError}
                helperText={
                  secondarySdgsError
                    ? "The maximum number of secondary SDGs is 3"
                    : null
                }
                label="Secondary SDGs"
              />
            )}
            onChange={(e: any, newValues: AutocompleteOption[]) => {
              if (newValues.length < 4) {
                setCurrentActionGroup({
                  ...currentActionGroup!,
                  sdgs: [
                    ...currentActionGroup.sdgs.filter((sdg) => sdg.primary),
                    ...newValues.map((sdg) => {
                      return {
                        primary: false,
                        sdg: {
                          id: sdg.id,
                          name: sdg.label.slice(sdg.label.indexOf(".") + 2),
                        },
                      }
                    }),
                  ],
                })
                setSecondarySdgsError(false)
              } else {
                setSecondarySdgsError(true)
              }
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Autocomplete
            disabled={!editMode}
            getOptionDisabled={(option: AutocompleteOption) => {
              if (
                currentActionGroup.sdgTargets.filter(
                  (sdgTarget) => sdgTarget.sdgTarget.id === option.id
                )[0] &&
                !currentActionGroup.sdgTargets.filter(
                  (sdgTarget) => sdgTarget.sdgTarget.id === option.id
                )[0].primary
              ) {
                return true
              } else {
                return false
              }
            }}
            fullWidth
            size="small"
            value={
              currentActionGroup.sdgTargets.filter((item) => item.primary)[0]
                ? {
                    label: currentActionGroup.sdgTargets
                      .filter((item) => item.primary)[0]
                      .sdgTarget.id.slice(4),
                    id: currentActionGroup.sdgTargets.filter(
                      (item) => item.primary
                    )[0].sdgTarget.id,
                  }
                : null
            }
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              sdgTargetsList.length
                ? sdgTargetsList.map((sdgTarget: SdgTarget) => {
                    return {
                      label: sdgTarget.id.slice(4),
                      id: sdgTarget.id,
                    }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField {...params} label="Primary SDG target" />
            )}
            onChange={(e: any, newValue: AutocompleteOption | null) => {
              if (newValue) {
                setCurrentActionGroup({
                  ...currentActionGroup!,
                  sdgTargets: [
                    {
                      primary: true,
                      sdgTarget: {
                        id: newValue.id,
                      },
                    },
                    ...currentActionGroup.sdgTargets.filter(
                      (sdgTarget) => !sdgTarget.primary
                    ),
                  ],
                })
              } else {
                if (
                  currentActionGroup.sdgTargets.filter(
                    (sdgTarget) => sdgTarget.primary
                  )[0]
                ) {
                  const indexToRemove = currentActionGroup.sdgTargets.findIndex(
                    (sdgTarget) => sdgTarget.primary
                  )
                  currentActionGroup.sdgTargets.splice(indexToRemove, 1)
                  setCurrentActionGroup({ ...currentActionGroup })
                }
              }
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
          <Autocomplete
            disabled={!editMode}
            getOptionDisabled={(option: AutocompleteOption) => {
              if (
                currentActionGroup.sdgTargets.filter(
                  (sdgTarget) => sdgTarget.sdgTarget.id === option.id
                )[0] &&
                currentActionGroup.sdgTargets.filter(
                  (sdgTarget) => sdgTarget.sdgTarget.id === option.id
                )[0].primary
              ) {
                return true
              } else {
                return false
              }
            }}
            multiple
            fullWidth
            onBlur={() => {
              setSecondarySdgTargetsError(false)
            }}
            size="small"
            value={currentActionGroup.sdgTargets
              .filter((sdgTarget) => !sdgTarget.primary)
              .map((sdgTarget) => {
                return {
                  label: sdgTarget.sdgTarget.id.slice(4),
                  id: sdgTarget.sdgTarget.id,
                }
              })}
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              sdgTargetsList.length
                ? sdgTargetsList.map((sdgTarget: SdgTarget) => {
                    return {
                      label: sdgTarget.id.slice(4),
                      id: sdgTarget.id,
                    }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField
                {...params}
                error={secondarySdgTargetsError}
                helperText={
                  secondarySdgTargetsError
                    ? "The maximum number of secondary SDG targets is 3"
                    : null
                }
                label="Secondary SDG targets"
              />
            )}
            onChange={(e: any, newValues: AutocompleteOption[]) => {
              if (newValues.length < 4) {
                setCurrentActionGroup({
                  ...currentActionGroup!,
                  sdgTargets: [
                    ...currentActionGroup.sdgTargets.filter(
                      (sdgTarget) => sdgTarget.primary
                    ),
                    ...newValues.map((sdgTarget) => {
                      return {
                        primary: false,
                        sdgTarget: {
                          id: sdgTarget.id,
                        },
                      }
                    }),
                  ],
                })
                setSecondarySdgTargetsError(false)
              } else {
                setSecondarySdgTargetsError(true)
              }
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
        </Stack>
        <Typography variant="h6" className="card-title">
          Actions
        </Typography>
        <Stack>
          {currentActionGroup.actions.map((item, index) => (
            <Card
              variant="outlined"
              key={item.action.id}
              onClick={() => {
                window.open(`/actions/${item.action.id}`)
              }}
              sx={{
                boxShadow: "none",
                borderTopLeftRadius: index === 0 ? undefined : 0,
                borderTopRightRadius: index === 0 ? undefined : 0,
                borderBottomLeftRadius:
                  index === currentActionGroup.actions.length - 1
                    ? undefined
                    : 0,
                borderBottomRightRadius:
                  index === currentActionGroup.actions.length - 1
                    ? undefined
                    : 0,
                borderTop: index === 0 ? undefined : "none",
              }}
            >
              <CardContent
                className="action-card"
                style={{
                  overflow: "hidden",
                  height: 65,
                  paddingTop: 0,
                  paddingBottom: 0,
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <Stack direction="row" alignItems="center" spacing={5}>
                  <div
                    style={{
                      width: 42,
                      height: 42,
                      borderRadius: 4,
                      backgroundImage: `url(${
                        item.action.image ?? noImagePlaceholder
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                  <Tooltip
                    title={
                      item.action.document.items.filter(
                        (documentItem) => documentItem.isDefault
                      )[0].title
                    }
                  >
                    <Typography
                      style={{
                        maxWidth: 210,
                        width: 210,
                        minWidth: 44,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        cursor: "default",
                      }}
                    >
                      {
                        item.action.document.items.filter(
                          (documentItem) => documentItem.isDefault
                        )[0].title
                      }
                    </Typography>
                  </Tooltip>
                  <TranslationsStack
                    documentItems={item.action.document.items}
                  />
                  <Chip
                    size="small"
                    label={
                      item.action.isRepeatable ? "Repeatable" : "Not repeatable"
                    }
                    color={item.action.isRepeatable ? "success" : "warning"}
                  />
                  <Chip
                    size="small"
                    label={item.action.category.name}
                    style={{
                      backgroundColor: item.action.category.backColor,
                      color: item.action.category.foreColor,
                    }}
                  />
                </Stack>
                <IconButton
                  style={{
                    position: "absolute",
                    right: 18,
                    transition: "100ms",
                    zIndex: 1,
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    currentActionGroup.actions.splice(index, 1)
                    setCurrentActionGroup({ ...currentActionGroup })
                  }}
                  disabled={!editMode}
                  color="error"
                >
                  <DeleteRounded
                    style={{
                      width: 22,
                    }}
                  />
                </IconButton>
              </CardContent>
            </Card>
          ))}
        </Stack>
        <Button
          disabled={!editMode || !currentActionGroup.category}
          startIcon={<AddCircleRounded />}
          style={{
            maxHeight: 40,
            minHeight: 40,
            minWidth: "100%",
            marginRight: 1,
          }}
          variant="outlined"
          onClick={() => {
            setActionsToFilter(
              currentActionGroup.actions.map((item) => item.action)
            )
            setActionsList([])
            setAddActionsDialogOpen(true)
          }}
        >
          Add Actions
        </Button>
      </Stack>
      {/* add actions dialog */}
      <Dialog
        fullScreen
        open={addActionsDialogOpen}
        onClose={() => {
          setAddActionsDialogOpen(false)
          setSelectedActions([])
          resetFilters()
        }}
        TransitionComponent={DialogTransition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setAddActionsDialogOpen(false)
                setSelectedActions([])
                resetFilters()
              }}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Actions
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                setCurrentActionGroup({
                  ...currentActionGroup,
                  actions: [
                    ...currentActionGroup.actions,
                    ...selectedActions.map((item) => {
                      return {
                        action: item,
                      }
                    }),
                  ],
                })
                setAddActionsDialogOpen(false)
                setSelectedActions([])
                resetFilters()
              }}
              disabled={!selectedActions.length}
            >
              Select
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ backgroundColor: "#f5f5f5" }}>
          {currentActionGroup.category ? (
            <ActionsList
              forDialog
              actionsToFilter={actionsToFilter}
              selectedActions={selectedActions}
              setSelectedActions={setSelectedActions}
              categoryFilter={{
                id: currentActionGroup.category.id,
                label: currentActionGroup.category.name,
              }}
              alreadyAssignedFilter
            />
          ) : null}
        </Paper>
      </Dialog>
    </CardContent>
  )
}

export default ActionGroupGeneralEdit
