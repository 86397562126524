import { useContext, useEffect, useState } from "react"
import EditContainer from "../../components/global/editContainer"
import EditHeading from "../../components/global/editHeading"
import { MainContext } from "../../controllers/main"
import { useNavigate, useParams } from "react-router-dom"
import { ChallengesContext } from "../../controllers/challenges"
import PageSpinner from "../../components/global/pageSpinner"
import ListTable from "../../components/global/listTable"
import {
  FormControl,
  Grow,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import LeaderboardListContainer from "../../components/challenge/leaderboardListContainer"

const ChallengeLeaderboardGroups = () => {
  const { animation, setAnimation, showPadding } = useContext(MainContext)
  const {
    preChangesCurrentChallenge,
    getCurrentChallenge,
    currentGroupsLeaderboard,
    currentGroupsLeaderboardNextToken,
    getGroupsLeaderboard,
    getAllLeaderboard,
  } = useContext(ChallengesContext)
  const navigate = useNavigate()
  const { challengeId } = useParams()

  // loading
  const [loading, setLoading] = useState<boolean>(true)
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // page error
  const [pageError, setPageError] = useState<boolean>(false)

  // fetch leaderboard
  const fetchAll = async () => {
    if (!preChangesCurrentChallenge.id) {
      const noErrors = await Promise.all([
        getGroupsLeaderboard(challengeId, leaderboardType, false),
        getCurrentChallenge(challengeId),
      ])
      if (noErrors[0] && noErrors[1]) {
        setLoading(false)
      } else {
        setPageError(true)
      }
    } else {
      const noErrors = await getGroupsLeaderboard(
        challengeId,
        leaderboardType,
        false
      )
      if (noErrors) {
        setLoading(false)
      } else {
        setPageError(true)
      }
    }
  }

  useEffect(() => {
    fetchAll()
  }, [])

  // leaderboard type (sum or average, default as currentChallenge.leaderboardGroupType)
  const [leaderboardType, setLeaderboardType] = useState<"sum" | "average">(
    preChangesCurrentChallenge.id
      ? preChangesCurrentChallenge.leaderboardGroupType
      : "sum"
  )

  // refetch leaderboard when leaderboard type changes
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  useEffect(() => {
    const fetch = async () => {
      setLoadMoreLoading(true)

      const noErrors = await getGroupsLeaderboard(
        challengeId,
        leaderboardType,
        false
      )
      if (noErrors) {
        setLoadMoreLoading(false)
      } else {
        setPageError(true)
      }
    }

    if (isFirstRender) {
      setIsFirstRender(false)
    } else {
      fetch()
    }
  }, [leaderboardType])

  return loading ? (
    <PageSpinner
      showBackButton={pageError}
      backButtonOnClick={() => {
        navigate(
          window.location.pathname.slice(
            0,
            window.location.pathname.indexOf("/leaderboard")
          )
        )
      }}
    />
  ) : (
    <EditContainer>
      <EditHeading
        backButtonOnClick={() => {
          setAnimation(false)
          setTimeout(() => {
            navigate(
              window.location.pathname.slice(
                0,
                window.location.pathname.indexOf("/leaderboard")
              )
            )
          }, 300)
        }}
        title={preChangesCurrentChallenge.name + " / Groups leaderboard"}
        id={preChangesCurrentChallenge.id}
        showCsvButton
        csvButtonOnClick={async () => {
          const reult = await getAllLeaderboard(
            challengeId,
            false,
            "groups",
            null,
            leaderboardType
          )
          return reult
        }}
        csvFileName={`${preChangesCurrentChallenge.name} groups leaderboard.csv`}
      />
      <Grow in={animation} timeout={350}>
        <div style={{ paddingInline: showPadding === "yes" ? 16 : 0 }}>
          <FormControl fullWidth size="small" disabled={loadMoreLoading}>
            <InputLabel id="leaderboard-type-select">
              Leaderboard type
            </InputLabel>
            <Select
              labelId="leaderboard-type-select"
              label="Leaderboard type"
              value={leaderboardType}
              onChange={(e) => {
                setLeaderboardType(e.target.value as "sum" | "average")
              }}
            >
              <MenuItem value="sum">Sum</MenuItem>
              <MenuItem value="average">Average</MenuItem>
            </Select>
          </FormControl>
        </div>
      </Grow>
      <LeaderboardListContainer>
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 206px)"
              : "calc(100vh - 188px)"
          }
          headingItems={["Rank", "Image", "Name & ID", "Score"]}
          nextToken={currentGroupsLeaderboardNextToken}
          loadingMoreItems={loadMoreLoading}
          setLoadingMoreItems={setLoadMoreLoading}
          loadMore={async () => {
            const result = await getGroupsLeaderboard(
              challengeId,
              leaderboardType
            )
            return result
          }}
          tableBody={currentGroupsLeaderboard.map((item, index) => (
            <TableRow
              key={index}
              hover
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
              onClick={() => {
                setAnimation(false)
                setTimeout(() => {
                  navigate(window.location.pathname + "/" + item.groupId)
                }, 250)
              }}
            >
              <TableCell style={{ width: 36, minWidth: 36 }}>
                <Typography>{index + 1}</Typography>
              </TableCell>
              <TableCell style={{ width: 36, minWidth: 36 }}>
                <div
                  style={{
                    width: 36,
                    height: 36,
                    borderRadius: 20,
                    backgroundImage: `url(https://cdn.aworld.io/users/default/profile.jpg)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </TableCell>
              <TableCell style={{ width: "100%" }}>
                <Stack>
                  <Typography style={{ fontSize: 15 }}>
                    {preChangesCurrentChallenge.team &&
                    preChangesCurrentChallenge.team.groups.filter(
                      (group) => group.groupId === item.groupId
                    )[0]
                      ? preChangesCurrentChallenge.team.groups.filter(
                          (group) => group.groupId === item.groupId
                        )[0].name
                      : "N.A."}
                  </Typography>
                  <Typography variant="caption">{item.groupId}</Typography>
                </Stack>
              </TableCell>
              <TableCell style={{ minWidth: 100 }}>
                <Typography style={{ fontWeight: 500 }}>
                  {Math.round(
                    ((item.metricAverage
                      ? item.metricAverage
                      : item.metricSum) +
                      Number.EPSILON) *
                      10
                  ) / 10}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        />
      </LeaderboardListContainer>
    </EditContainer>
  )
}

export default ChallengeLeaderboardGroups
