import { useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import Journey from "../models/journey"
import { upsertTeamJourney } from "../services/graphql/mutations"
import { teamJourneys } from "../services/graphql/queries"
import { deepCopy, logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"
import TeamJourney from "../models/teamJourney"

interface TeamJourneysRelationsContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  upsertTeamJourneysRelation: (input: {
    teamId: string
    journeyIds: string[]
  }) => Promise<boolean>
  getFreeJourneys: () => Promise<boolean>
  currentFreeJourneysList: Journey[]
  setCurrentFreeJourneysList: Dispatch<SetStateAction<Journey[]>>
  doneChanges: boolean
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  cancelChanges: () => void
}

const TeamJourneysRelationsContext =
  createContext<TeamJourneysRelationsContextInterface>({
    loading: false,
    setLoading: () => {},
    upsertTeamJourneysRelation: async () => true,
    getFreeJourneys: async () => true,
    currentFreeJourneysList: [],
    setCurrentFreeJourneysList: () => {},
    doneChanges: false,
    editMode: false,
    setEditMode: () => {},
    cancelChanges: () => {},
  })

const TeamJourneysRelationsController = ({
  children,
}: {
  children: ReactNode
}) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // states
  const [loading, setLoading] = useState<boolean>(false) // loading
  const [currentFreeJourneysList, setCurrentFreeJourneysList] = useState<
    Journey[]
  >([]) // current free journeys relation details
  const [
    preChangesCurrentFreeJourneysList,
    setPreChangesCurrentFreeJourneysList,
  ] = useState<Journey[]>([]) // current free journeys relation details
  const [doneChanges, setDoneChanges] = useState<boolean>(false) // if user has done changes to details free
  const [editMode, setEditMode] = useState<boolean>(true) // if all page should be disabled or not for free journeys

  // queries
  const [teamJourneysQuery] = useLazyQuery(teamJourneys)

  // mutations
  const [upsertTeamJourneyMutation] = useMutation(upsertTeamJourney)

  // get free journeys
  const getFreeJourneys = useCallback(async () => {
    panelStatus.filter((item) => item.name === "Free release")[0].loading = true
    setPanelStatus([...panelStatus])

    try {
      logger(Status.Api, "QUERY teamJourneys", teamJourneys)
      const { data } = await teamJourneysQuery({
        variables: { teamId: "team_default" },
        fetchPolicy: "no-cache",
      })
      let dataToSet = deepCopy(data.teamJourneys.items)
      dataToSet = dataToSet.map((item: TeamJourney) => item.journey)
      logger(Status.Info, "free journeys", dataToSet)

      setCurrentFreeJourneysList(deepCopy(dataToSet))
      setPreChangesCurrentFreeJourneysList(deepCopy(dataToSet))

      panelStatus.filter((item) => item.name === "Free release")[0].status =
        "success"
      panelStatus.filter((item) => item.name === "Free release")[0].loading =
        false
      setPanelStatus([...panelStatus])

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `teamJourneys`, e.message)
      }
      panelStatus.filter((item) => item.name === "Free release")[0].status =
        "error"
      panelStatus.filter((item) => item.name === "Free release")[0].loading =
        false
      setPanelStatus([...panelStatus])

      return false
    }
  }, [setError, setErrorMessage])

  // upsert team journeys relation
  const upsertTeamJourneysRelation = async (input: {
    teamId: string
    journeyIds: string[]
  }) => {
    try {
      logger(Status.Api, "MUTATION upsertTeamJourney", upsertTeamJourney)
      await upsertTeamJourneyMutation({
        variables: { input: input },
      })
      logger(Status.Info, `relation on team ${input.teamId} upserted`)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `upsertTeamJourney`, e.message)
      }

      return false
    }
  }

  // check if user has done changes
  useEffect(() => {
    if (
      JSON.stringify(currentFreeJourneysList) ===
      JSON.stringify(preChangesCurrentFreeJourneysList)
    ) {
      setDoneChanges(false)
    } else {
      setDoneChanges(true)
    }
  }, [currentFreeJourneysList])

  // cancel changes
  const cancelChanges = () => {
    setCurrentFreeJourneysList(deepCopy(preChangesCurrentFreeJourneysList))
  }

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter(
      (item) => item.name === "Free release"
    )[0].updateFunction = getFreeJourneys
  }, [])

  return (
    <TeamJourneysRelationsContext.Provider
      value={{
        loading,
        setLoading,
        upsertTeamJourneysRelation,
        getFreeJourneys,
        currentFreeJourneysList,
        setCurrentFreeJourneysList,
        doneChanges,
        editMode,
        setEditMode,
        cancelChanges,
      }}
    >
      {children}
    </TeamJourneysRelationsContext.Provider>
  )
}

export { TeamJourneysRelationsController, TeamJourneysRelationsContext }
