import { ExpandMoreRounded } from "@mui/icons-material"
import {
  ButtonBase,
  Chip,
  LinearProgress,
  Popover,
  Stack,
  Typography,
} from "@mui/material"
import { CSSProperties, Dispatch, SetStateAction, useState } from "react"
import "../../styles/chipSelect.scss"

const ChipSelect = ({
  label,
  options,
  selectedOption,
  setOpen,
  onOptionClick,
  color,
  disabled,
  setLoading,
  style,
}: {
  label: string
  options: { id: string | boolean; label: string }[]
  selectedOption: string | boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onOptionClick: (option: string | boolean) => Promise<boolean>
  color?:
    | "success"
    | "info"
    | "warning"
    | "error"
    | "default"
    | "primary"
    | "secondary"
  disabled?: boolean
  setLoading?: Dispatch<SetStateAction<boolean>>
  style?: CSSProperties
}) => {
  // popover logic
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const popoverOpen = Boolean(anchorEl)

  // local loading
  const [localLoading, setLocalLoading] = useState<boolean>(false)

  return (
    <>
      <ButtonBase
        disableRipple
        disabled={disabled}
        onClick={handleClick}
        style={{
          position: "relative",
          overflow: "hidden",
          borderRadius: 16,
        }}
      >
        <Chip
          className="chip-select"
          label={
            <Stack direction="row" alignItems="center" gap={0.2}>
              <span>{label}</span>
              <ExpandMoreRounded
                style={{
                  fontSize: 16,
                  transform: popoverOpen ? "rotate(-180deg)" : "rotate(0deg)",
                  transition: "200ms",
                }}
              />
            </Stack>
          }
          color={color}
          size="small"
          style={style}
        />
        {localLoading && (
          <LinearProgress
            style={{ width: "100%", position: "absolute", bottom: 0 }}
          />
        )}
      </ButtonBase>
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{ paper: "popover-paper" }}
      >
        <Stack
          style={{
            width: 180,
          }}
        >
          {options.map((option, index) => (
            <ButtonBase
              key={index}
              className={
                selectedOption === option.id ? "" : "chip-select-option"
              }
              style={{
                width: "100%",
                height: 36,
                paddingLeft: 16,
                justifyContent: "flex-start",
                transition: "200ms",
                backgroundColor:
                  selectedOption === option.id ? "#EAECF0" : "white",
              }}
              onClick={async (e) => {
                e.stopPropagation()
                handleClose()

                if (selectedOption !== option.id) {
                  setLoading(true)
                  setLocalLoading(true)

                  await onOptionClick(option.id)

                  setLoading(false)
                  setLocalLoading(false)
                }
              }}
              aria-label={option.label}
            >
              <Typography fontSize={14}>{option.label}</Typography>
            </ButtonBase>
          ))}
        </Stack>
      </Popover>
    </>
  )
}

export default ChipSelect
