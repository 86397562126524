import { CloseRounded } from "@mui/icons-material"
import { FormControl, IconButton, InputLabel, Select } from "@mui/material"
import { ReactNode } from "react"

const ClearableSelect = ({
  label,
  value,
  onChange,
  children,
}: {
  label: string
  value: string
  onChange: (newValue: string) => void
  children: ReactNode
}) => {
  return (
    <FormControl fullWidth size="small">
      <InputLabel id="esg-select">{label}</InputLabel>
      <Select
        onMouseEnter={() => {
          if (value) {
            const button = document.getElementById(
              "close-button-clearable-select"
            )
            button.style.opacity = "1"
          }
        }}
        onMouseLeave={() => {
          const button = document.getElementById(
            "close-button-clearable-select"
          )
          button.style.opacity = "0"
        }}
        labelId="esg-select"
        label={label}
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        size="small"
        endAdornment={
          <IconButton
            id="close-button-clearable-select"
            style={{ marginRight: 19.5, width: 26, height: 26, opacity: 0 }}
            onClick={() => {
              onChange(null)
            }}
          >
            <CloseRounded
              style={{
                fontSize: 20,
                color: "#667380",
              }}
            />
          </IconButton>
        }
      >
        {children}
      </Select>
    </FormControl>
  )
}

export default ClearableSelect
