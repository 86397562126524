import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import {
  TableVirtuoso,
  TableComponents,
  TableVirtuosoHandle,
} from "react-virtuoso"
import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react"
import TableSpinner from "./tableSpinner"
import { Fab, LinearProgress, Zoom } from "@mui/material"
import { MainContext } from "../../controllers/main"
import { useNavigate } from "react-router-dom"
import { KeyboardArrowUpRounded } from "@mui/icons-material"

export interface ColumnData {
  dataKey: string
  label: string
  width?: number
}

const ListTableVirtualized = ({
  columns,
  rows,
  height,
  loading,
  loadingMore,
  setLoadingMore,
  updating,
  loadMore,
  nextToken,
  rowCustomOnClick,
  rowOnClickDisabled,
}: {
  columns: ColumnData[]
  rows: any[]
  height: string
  loading?: boolean
  loadingMore?: boolean
  setLoadingMore?: Dispatch<SetStateAction<boolean>>
  updating?: boolean
  loadMore?: () => Promise<boolean>
  nextToken?: string
  rowCustomOnClick?: (item: any) => void
  rowOnClickDisabled?: boolean
}) => {
  const navigate = useNavigate()
  const { cmdPressed, setAnimation } = useContext(MainContext)

  // TableVirtuoso refs
  const virtuosoRef = useRef<TableVirtuosoHandle>(null)
  const virtuosoScrollerRef = useRef<HTMLElement | Window>(null)

  // table components
  const VirtuosoTableComponents: TableComponents = useMemo(() => {
    return {
      Scroller: forwardRef<HTMLDivElement>((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref} />
      )),
      Table: (props) => (
        <Table
          {...props}
          size="small"
          sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
        />
      ),
      TableHead,
      TableRow: ({ item: _item, ...props }) => (
        <TableRow
          {...props}
          hover={!rowOnClickDisabled}
          style={{
            cursor: rowOnClickDisabled ? "default" : "pointer",
          }}
          onClick={() => {
            if (!rowOnClickDisabled) {
              const currentItem = (_item as any).item

              if (rowCustomOnClick) {
                rowCustomOnClick(currentItem)
              } else {
                if (cmdPressed) {
                  window.open(`${window.location.href}/${currentItem.id}`)
                } else {
                  setAnimation(false)
                  setTimeout(() => {
                    navigate(`/activationcodes/${currentItem.id}`)
                  }, 250)
                }
              }
            }
          }}
        />
      ),
      TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
        <TableBody {...props} ref={ref} />
      )),
    }
  }, [])

  // header
  const fixedHeaderContent = () => {
    return (
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            style={{ width: column.width }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    )
  }

  // row
  const rowContent = (_index: number, row: any) => {
    return (
      <>
        {columns.map((column) => (
          <TableCell key={column.dataKey}>{row[column.dataKey]}</TableCell>
        ))}
      </>
    )
  }

  // local loading
  const localLoading = useRef<boolean>(false)

  // scroll to top fab
  const [showScrollToTopFab, setShowScrollToTopFab] = useState<boolean>(false)

  return loading ? (
    <TableSpinner height={height} />
  ) : (
    <Paper style={{ height: height, width: "100%", position: "relative" }}>
      {updating && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 32,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <TableVirtuoso
        ref={virtuosoRef}
        scrollerRef={(ref) => {
          virtuosoScrollerRef.current = ref
        }}
        data={rows}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
        endReached={async () => {
          if (
            nextToken &&
            setLoadingMore &&
            loadMore &&
            !localLoading.current
          ) {
            setLoadingMore(true)
            localLoading.current = true

            await loadMore()

            setLoadingMore(false)
            localLoading.current = false
          }
        }}
        increaseViewportBy={500}
        overscan={300}
        onScroll={() => {
          if ((virtuosoScrollerRef.current as HTMLElement).scrollTop > 300) {
            setShowScrollToTopFab(true)
          } else {
            setShowScrollToTopFab(false)
          }
        }}
      />
      {loadingMore && (
        <LinearProgress
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <Zoom in={showScrollToTopFab} unmountOnExit>
        <Fab
          size="small"
          aria-label="scroll to top"
          color="primary"
          style={{ position: "absolute", bottom: 14, right: 20 }}
          onClick={() => {
            virtuosoRef.current.scrollToIndex({
              index: 0,
              behavior:
                (virtuosoScrollerRef.current as HTMLElement).scrollTop > 3000
                  ? "auto"
                  : "smooth",
            })
          }}
        >
          <KeyboardArrowUpRounded />
        </Fab>
      </Zoom>
    </Paper>
  )
}

export default ListTableVirtualized
