import {
  AddCircleRounded,
  CloseRounded,
  ContentCopyRounded,
  FormatQuoteOutlined,
  HistoryRounded,
  ImageOutlined,
  OndemandVideoOutlined,
  PortraitOutlined,
  QuizRounded,
  TextSnippetOutlined,
  TitleOutlined,
  UndoRounded,
  VerticalSplitOutlined,
} from "@mui/icons-material"
import {
  Alert,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { useParams } from "react-router-dom"
import { EpisodesContext } from "../../controllers/episodes"
import Episode from "../../models/episode"
import EpisodeSlide from "../../models/episodeSlide"
import EpisodeSlideQuiz from "../../models/episodeSlideQuiz"
import EpisodeTranslation from "../../models/episodeTranslation"
import {
  createQuiz,
  createSlide,
} from "../../services/utilities/episodeUtility"
import {
  calculateTimeElapsed,
  deepCopy,
} from "../../services/utilities/utility"
import QuizStack from "./quizStack"
import SlidesStack from "./slidesStack"
import "../../styles/episodeTranslationsEdit.scss"
import { LanguagesContext } from "../../controllers/languages"
import { MainContext } from "../../controllers/main"
import CantPerformActionDialog from "../global/cantPerformActionDialog"
import { TagsContext } from "../../controllers/tags"

const EpisodeTranslationsEdit = ({
  slideLanguage,
  setSlideLanguage,
  expanded,
  setExpanded,
  handleChange,
  setStageToSee,
  setTranslationStageDialogOpen,
  sidebarOpen,
  setSidebarOpen,
  setSelectedTab,
  fetchAll,
}: {
  slideLanguage: string
  setSlideLanguage: Dispatch<SetStateAction<string>>
  expanded: number | false
  setExpanded: Dispatch<SetStateAction<number | false>>
  handleChange: Function
  setStageToSee: Dispatch<SetStateAction<string>>
  setTranslationStageDialogOpen: Dispatch<SetStateAction<boolean>>
  sidebarOpen: boolean
  setSidebarOpen: Dispatch<SetStateAction<boolean>>
  setSelectedTab: Dispatch<SetStateAction<number>>
  fetchAll: () => void
}) => {
  const { showPadding } = useContext(MainContext)
  const { languagesForEpisodeTabs } = useContext(LanguagesContext)
  const {
    currentEpisode,
    currentEpisodeStaged,
    currentEpisodeLive,
    setCurrentEpisode,
    slidesErrors,
    doneChangesTranslations,
    changeTranslationStage,
    cancelTranslationChanges,
    removeTranslationFromCurrentEpisode,
    removeSlide,
    removeQuiz,
    publishTranslation,
    setLoading,
    updateTranslation,
    editMode,
    addSlide,
    addQuiz,
    duplicateSlide,
    slideConstraintsList,
    copyToAnotherTranslation,
    duplicateSlideAnotherTranslation,
  } = useContext(EpisodesContext)
  const { topicsList, sdgsList } = useContext(TagsContext)
  const { episodeId } = useParams()

  // translations changes cancelled
  const [translationChangesCancelled, setTranslationChangesCancelled] =
    useState<boolean>(false)

  useEffect(() => {
    if (translationChangesCancelled) {
      setTranslationChangesCancelled(false)
    }
  }, [translationChangesCancelled])

  // drag and drop
  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result

    if (destination.index === source.index) {
      return
    }

    let numbers = Array.from(
      document.getElementsByClassName(
        "slide-number"
      ) as HTMLCollectionOf<HTMLElement>
    )
    numbers.forEach((item) => {
      item.style.opacity = "0.5"
    })

    let currentEpisodeCopy: Episode = deepCopy(currentEpisode)
    let slides = currentEpisodeCopy.translations.filter(
      (item) => item.lang === slideLanguage
    )[0].slides
    let sourceItem = slides[source.index]

    let indexToDelete = slides.findIndex((item) => item.id === draggableId)
    slides.splice(indexToDelete, 1)
    slides.splice(destination.index, 0, sourceItem)

    setCurrentEpisode({ ...currentEpisodeCopy })

    setTimeout(() => {
      numbers.forEach((item) => {
        item.style.opacity = "1"
      })
    }, 50)
  }

  // add slide panel
  const [addSlidePanelOpen, setAddSlidePanelOpen] = useState<boolean>(false)

  useEffect(() => {
    setAddSlidePanelOpen(false)
  }, [slideLanguage])

  useEffect(() => {
    if (!editMode) {
      setAddSlidePanelOpen(false)
    }
  }, [editMode])

  // add slide
  const onAddButtonClick = (type: string, titleForTitleSlide?: string) => {
    if (type === "Quiz") {
      let slideToAdd: EpisodeSlideQuiz = createQuiz()
      addQuiz(slideLanguage, slideToAdd)
      setExpanded(
        currentEpisode.translations.filter(
          (item) => item.lang === slideLanguage
        )[0].quiz.length
      )
    } else {
      let slideToAdd: EpisodeSlide = titleForTitleSlide
        ? createSlide(type, titleForTitleSlide)
        : createSlide(type)
      addSlide(slideLanguage, slideToAdd)
      setExpanded(
        currentEpisode.translations.filter(
          (item) => item.lang === slideLanguage
        )[0].slides.length
      )
    }
    setAddSlidePanelOpen(false)
  }

  // undo changes dialog
  const [undoDialogOpen, setUndoDialogOpen] = useState<boolean>(false)

  // remove slide and quiz dialog
  const [removeSlideDialogOpen, setRemoveSlideDialogOpen] =
    useState<boolean>(false)
  const [removeQuizSlideDialogOpen, setRemoveQuizSlideDialogOpen] =
    useState<boolean>(false)
  const [slideIdToRemove, setSlideIdToRemove] = useState<string>("")

  // publish alert dialog
  const [publishAlertDialogOpen, setPublishAlertDialogOpen] =
    useState<boolean>(false)

  // add quiz alert dialog
  const [addQuizAlertDialogOpen, setAddQuizAlertDialogOpen] =
    useState<boolean>(false)

  // duplicate slide dialog
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState<boolean>(false)
  const [slideToDuplicate, setSlideToDuplicate] = useState<EpisodeSlide>()

  // duplicate slide to another translation dialog
  const [
    duplicateToTranslationDialogOpen,
    setDuplicateToTranslationDialogOpen,
  ] = useState<boolean>(false)
  const [duplicateSnackbarOpen, setDuplicateSnackbarOpen] =
    useState<boolean>(false)
  const [cantDublicateDialogOpen, setCantDublicateDialogOpen] =
    useState<boolean>(false)

  // slides/quiz switch
  const [selectedSwitchPage, setSelectedSwitchPage] = useState<number>(0)

  useEffect(() => {
    setAddSlidePanelOpen(false)
    setExpanded(false)
  }, [selectedSwitchPage])

  // edit content container
  useEffect(() => {
    let container = document.getElementById("edit-content-container")
    container.addEventListener("scroll", () => {
      if (container.scrollTop > 40) {
        ;(
          document.getElementsByClassName(
            "switch-visualizing"
          )[0] as HTMLElement
        ).style.opacity = "1"
      } else {
        ;(
          document.getElementsByClassName(
            "switch-visualizing"
          )[0] as HTMLElement
        ).style.opacity = "0"
      }
    })
  }, [])

  // copy to another translation dialog
  const [copyTranslationDialogOpen, setCopyTranslationDialogOpen] =
    useState<boolean>(false)
  const [translationToCopyTo, setTranslationToCopyTo] = useState<{
    value: string
    label: string
    flag?: string
  } | null>(null)

  return (
    <Stack
      spacing={0}
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 199px)" : "calc(100vh - 183px)",
        paddingBottom: 25,
      }}
    >
      {currentEpisode.translations.filter(
        (item) => item.lang === slideLanguage
      )[0] && (
        <div
          style={{
            marginTop: 0,
            width: "100%",
            minHeight: 36,
            backgroundColor:
              slidesErrors
                .filter((item) => item.lang === slideLanguage)[0]
                .slides.filter((item) => item.error === true).length > 0 ||
              currentEpisode.translations.filter(
                (item) => item.lang === slideLanguage
              )[0].title.length < 3 ||
              currentEpisode.translations.filter(
                (item) => item.lang === slideLanguage
              )[0].title.length >
                slideConstraintsList.filter(
                  (item) => item.type === "TitleSlide"
                )[0].title.maxSize
                ? "#d3302f"
                : doneChangesTranslations.filter(
                    (item) => item.lang === slideLanguage
                  )[0].doneChanges
                ? "#6cb3e6"
                : currentEpisode.translations.filter(
                    (item) => item.lang === slideLanguage
                  )[0].stage === "PUBLISHED"
                ? "#3bb97a"
                : currentEpisode.translations.filter(
                    (item) => item.lang === slideLanguage
                  )[0].stage === "JUSTADDED"
                ? "#a8a7a7"
                : "#f7b043",
            color: "white",
            paddingLeft: 15,
            transition: "150ms",
            paddingTop: 5,
            position: "relative",
          }}
        >
          <Typography
            variant="overline"
            className="switch-visualizing"
            style={{
              fontWeight: 500,
              position: "absolute",
              marginLeft: "auto",
              marginRight: "auto",
              left: 0,
              right: 0,
              top: 9,
              textAlign: "center",
              opacity: 0,
              transition: "150ms",
            }}
          >
            {selectedSwitchPage === 0 ? "Slides" : "Quiz"} section
          </Typography>
          <Typography variant="overline" style={{ fontWeight: 500 }}>
            {doneChangesTranslations.filter(
              (item) => item.lang === slideLanguage
            )[0].doneChanges
              ? "Edited"
              : currentEpisode.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0].stage === "PUBLISHED"
              ? "Live"
              : currentEpisode.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0].stage === "JUSTADDED"
              ? "Just added"
              : "Draft"}
          </Typography>
          {slidesErrors
            .filter((item) => item.lang === slideLanguage)[0]
            .slides.filter((item) => item.error === true).length > 0 ||
          currentEpisode.translations.filter(
            (item) => item.lang === slideLanguage
          )[0].title.length < 3 ||
          currentEpisode.translations.filter(
            (item) => item.lang === slideLanguage
          )[0].title.length >
            slideConstraintsList.filter((item) => item.type === "TitleSlide")[0]
              .title.maxSize ? (
            <Typography variant="overline" style={{ fontWeight: 500 }}>
              {" - errors"}
            </Typography>
          ) : null}
          <Button
            style={{
              height: 30,
              position: "absolute",
              top: 3,
              right: 144,
              transition: "150ms",
              color: "white",
              opacity:
                !doneChangesTranslations.filter(
                  (item) => item.lang === slideLanguage
                )[0].doneChanges &&
                currentEpisode.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0].stage !== "JUSTADDED"
                  ? 1
                  : 0,
            }}
            disabled={
              !editMode ||
              doneChangesTranslations.filter(
                (item) => item.lang === slideLanguage
              )[0].doneChanges ||
              currentEpisode.translations.filter(
                (item) => item.lang === slideLanguage
              )[0].stage === "JUSTADDED"
            }
            onClick={() => {
              setCopyTranslationDialogOpen(true)
            }}
          >
            <ContentCopyRounded
              style={{
                color: "white",
                transition: "150ms",
                fontSize: 22,
                opacity: !editMode ? 0.3 : 1,
              }}
            />
          </Button>
          <Button
            style={{
              height: 30,
              position: "absolute",
              top: 3,
              right: 144,
              transition: "150ms",
              opacity: doneChangesTranslations.filter(
                (item) => item.lang === slideLanguage
              )[0].doneChanges
                ? 1
                : 0,
            }}
            disabled={
              !doneChangesTranslations.filter(
                (item) => item.lang === slideLanguage
              )[0].doneChanges || !editMode
            }
            onClick={() => {
              setUndoDialogOpen(true)
            }}
          >
            <UndoRounded
              style={{
                color: "white",
                opacity:
                  !doneChangesTranslations.filter(
                    (item) => item.lang === slideLanguage
                  )[0].doneChanges || !editMode
                    ? 0.3
                    : 1,
                transition: "150ms",
              }}
            />
          </Button>
          <Button
            size="small"
            style={{
              position: "absolute",
              color: "white",
              opacity:
                !currentEpisode.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0] ||
                (currentEpisode.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0] &&
                  currentEpisode.translations.filter(
                    (item) => item.lang === slideLanguage
                  )[0].stage === "PUBLISHED" &&
                  !doneChangesTranslations.filter(
                    (item) => item.lang === slideLanguage
                  )[0].doneChanges) ||
                slidesErrors
                  .filter((item) => item.lang === slideLanguage)[0]
                  .slides.filter((item) => item.error === true).length > 0 ||
                !editMode ||
                (!currentEpisode.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0].slides.length &&
                  currentEpisode.translations.filter(
                    (item) => item.lang === slideLanguage
                  )[0].stage !== "JUSTADDED") ||
                currentEpisode.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0].title.length < 3 ||
                currentEpisode.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0].title.length >
                  slideConstraintsList.filter(
                    (item) => item.type === "TitleSlide"
                  )[0].title.maxSize
                  ? 0.3
                  : 1,
              top: 3,
              height: 30,
              right: 64,
              width: 80,
              transition: "150ms",
            }}
            disabled={
              !currentEpisode.translations.filter(
                (item) => item.lang === slideLanguage
              )[0] ||
              (currentEpisode.translations.filter(
                (item) => item.lang === slideLanguage
              )[0] &&
                currentEpisode.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0].stage === "PUBLISHED" &&
                !doneChangesTranslations.filter(
                  (item) => item.lang === slideLanguage
                )[0].doneChanges) ||
              slidesErrors
                .filter((item) => item.lang === slideLanguage)[0]
                .slides.filter((item) => item.error === true).length > 0 ||
              !editMode ||
              (!currentEpisode.translations.filter(
                (item) => item.lang === slideLanguage
              )[0].slides.length &&
                currentEpisode.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0].stage !== "JUSTADDED") ||
              currentEpisode.translations.filter(
                (item) => item.lang === slideLanguage
              )[0].title.length < 3 ||
              currentEpisode.translations.filter(
                (item) => item.lang === slideLanguage
              )[0].title.length >
                slideConstraintsList.filter(
                  (item) => item.type === "TitleSlide"
                )[0].title.maxSize
            }
            onClick={async () => {
              if (
                currentEpisode.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0].stage === "JUSTADDED" &&
                !doneChangesTranslations.filter(
                  (item) => item.lang === slideLanguage
                )[0].doneChanges
              ) {
                setSelectedTab(0)
                setSlideLanguage(currentEpisode.lang)
                removeTranslationFromCurrentEpisode(slideLanguage)
              }

              if (
                currentEpisode.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0] &&
                currentEpisode.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0].stage === "DRAFT" &&
                !doneChangesTranslations.filter(
                  (item) => item.lang === slideLanguage
                )[0].doneChanges
              ) {
                if (
                  !currentEpisode.translations.filter(
                    (item) => item.lang === slideLanguage
                  )[0].quiz.length
                ) {
                  setAddQuizAlertDialogOpen(true)
                  return
                }

                if (!currentEpisodeLive) {
                  setPublishAlertDialogOpen(true)
                  return
                }

                setLoading(true)
                const noErrors = await publishTranslation(
                  episodeId,
                  slideLanguage
                )
                if (noErrors) {
                  fetchAll()
                } else {
                  setLoading(false)
                }
              }

              if (
                doneChangesTranslations.filter(
                  (item) => item.lang === slideLanguage
                )[0].doneChanges
              ) {
                setLoading(true)
                let currentTranslation: EpisodeTranslation =
                  currentEpisode.translations.filter(
                    (item) => item.lang === slideLanguage
                  )[0]
                const noErrors = await updateTranslation(
                  currentTranslation.id,
                  episodeId,
                  slideLanguage,
                  currentTranslation.title,
                  currentTranslation.slides,
                  currentTranslation.quiz
                )
                if (noErrors) {
                  setTimeout(() => {
                    fetchAll()
                  }, 800)
                } else {
                  setLoading(false)
                }
              }
            }}
          >
            {currentEpisode.translations.filter(
              (item) => item.lang === slideLanguage
            )[0] &&
            currentEpisode.translations.filter(
              (item) => item.lang === slideLanguage
            )[0].stage === "DRAFT" &&
            !doneChangesTranslations.filter(
              (item) => item.lang === slideLanguage
            )[0].doneChanges
              ? "Publish"
              : doneChangesTranslations.filter(
                  (item) => item.lang === slideLanguage
                )[0].doneChanges
              ? "Save"
              : currentEpisode.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0].stage === "JUSTADDED" &&
                !doneChangesTranslations.filter(
                  (item) => item.lang === slideLanguage
                )[0].doneChanges
              ? "Remove"
              : "Save"}
          </Button>
          <Button
            style={{
              height: 30,
              position: "absolute",
              top: 3,
              right: 0,
              transition: "100ms",
            }}
            onClick={() => {
              setSidebarOpen(!sidebarOpen)
            }}
          >
            {sidebarOpen ? (
              <CloseRounded
                style={{
                  color: "white",
                }}
              />
            ) : (
              <HistoryRounded
                style={{
                  color: "white",
                }}
              />
            )}
          </Button>
        </div>
      )}
      {/* translations edit */}
      <div style={{ position: "relative", height: "100%" }}>
        <Card
          style={{
            position: "absolute",
            top: 0,
            right: sidebarOpen ? 0 : -245,
            height: "200%",
            width: 240,
            zIndex: 10,
            transition: "200ms",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeft: "1px solid #e0e0e0",
          }}
        >
          <CardContent>
            <Stack spacing={2}>
              {doneChangesTranslations.filter(
                (item) => item.lang === slideLanguage
              )[0].doneChanges && (
                <div
                  style={{
                    minHeight: 50,
                    backgroundColor: "#f9f9fb",
                    display: "flex",
                    alignItems: "center",
                    borderLeft:
                      slidesErrors
                        .filter((item) => item.lang === slideLanguage)[0]
                        .slides.filter((item) => item.error === true).length > 0
                        ? "4px solid " + "#d3302f"
                        : "4px solid #6cb3e6",
                    paddingLeft: 15,
                    paddingRight: 15,
                    borderRadius: "0 3px 3px 0",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography variant="overline" style={{ lineHeight: 1 }}>
                      {slidesErrors
                        .filter((item) => item.lang === slideLanguage)[0]
                        .slides.filter((item) => item.error === true).length > 0
                        ? "Edited - errors"
                        : "Edited"}
                    </Typography>
                    <Typography variant="caption" style={{ lineHeight: 1 }}>
                      unsaved changes
                    </Typography>
                  </Stack>
                </div>
              )}
              {currentEpisodeStaged.translations.filter(
                (item) => item.lang === slideLanguage
              )[0].stage === "DRAFT" && (
                <div
                  style={{
                    minHeight: 50,
                    backgroundColor:
                      currentEpisode.translations.filter(
                        (item) => item.lang === slideLanguage
                      )[0].stage === "DRAFT" &&
                      !doneChangesTranslations.filter(
                        (item) => item.lang === slideLanguage
                      )[0].doneChanges
                        ? "#f9f9fb"
                        : "white",
                    display: "flex",
                    alignItems: "center",
                    borderLeft: "4px solid " + "#f7b043",
                    paddingLeft: 15,
                    paddingRight: 15,
                    borderRadius: "0 3px 3px 0",
                    boxShadow:
                      currentEpisode.translations.filter(
                        (item) => item.lang === slideLanguage
                      )[0].stage === "PUBLISHED" ||
                      doneChangesTranslations.filter(
                        (item) => item.lang === slideLanguage
                      )[0].doneChanges
                        ? "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)"
                        : null,
                    cursor:
                      currentEpisode.translations.filter(
                        (item) => item.lang === slideLanguage
                      )[0].stage === "PUBLISHED" ||
                      doneChangesTranslations.filter(
                        (item) => item.lang === slideLanguage
                      )[0].doneChanges
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() => {
                    if (
                      doneChangesTranslations.filter(
                        (item) => item.lang === slideLanguage
                      )[0].doneChanges
                    ) {
                      setStageToSee("DRAFT")
                      setTranslationStageDialogOpen(true)
                    } else {
                      changeTranslationStage(slideLanguage, "DRAFT")
                    }
                  }}
                >
                  <Stack spacing={1}>
                    <Typography variant="overline" style={{ lineHeight: 1 }}>
                      Draft
                    </Typography>
                    <Typography variant="caption" style={{ lineHeight: 1 }}>
                      updated{" "}
                      {calculateTimeElapsed(
                        currentEpisodeStaged.translations.filter(
                          (item) => item.lang === slideLanguage
                        )[0].updatedAt
                      )}
                    </Typography>
                  </Stack>
                </div>
              )}
              {(currentEpisodeLive &&
                currentEpisodeLive.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0] &&
                currentEpisodeLive.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0].stage === "PUBLISHED") ||
              (currentEpisodeStaged &&
                currentEpisodeStaged.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0] &&
                currentEpisodeStaged.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0].stage === "PUBLISHED") ? (
                <div
                  style={{
                    minHeight: 50,
                    backgroundColor:
                      currentEpisode.translations.filter(
                        (item) => item.lang === slideLanguage
                      )[0].stage === "PUBLISHED" &&
                      !doneChangesTranslations.filter(
                        (item) => item.lang === slideLanguage
                      )[0].doneChanges
                        ? "#f9f9fb"
                        : "white",
                    display: "flex",
                    alignItems: "center",
                    borderLeft: "4px solid " + "#3bb97a",
                    paddingLeft: 15,
                    paddingRight: 15,
                    borderRadius: "0 3px 3px 0",
                    boxShadow:
                      currentEpisode.translations.filter(
                        (item) => item.lang === slideLanguage
                      )[0].stage === "DRAFT" ||
                      doneChangesTranslations.filter(
                        (item) => item.lang === slideLanguage
                      )[0].doneChanges
                        ? "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)"
                        : null,
                    cursor:
                      currentEpisode.translations.filter(
                        (item) => item.lang === slideLanguage
                      )[0].stage === "DRAFT" ||
                      doneChangesTranslations.filter(
                        (item) => item.lang === slideLanguage
                      )[0].doneChanges
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() => {
                    if (
                      doneChangesTranslations.filter(
                        (item) => item.lang === slideLanguage
                      )[0].doneChanges
                    ) {
                      setStageToSee("PUBLISHED")
                      setTranslationStageDialogOpen(true)
                    } else {
                      changeTranslationStage(slideLanguage, "PUBLISHED")
                    }
                  }}
                >
                  <Stack spacing={1}>
                    <Typography variant="overline" style={{ lineHeight: 1 }}>
                      Live
                    </Typography>
                    <Typography variant="caption" style={{ lineHeight: 1 }}>
                      updated{" "}
                      {calculateTimeElapsed(
                        currentEpisodeLive
                          ? currentEpisodeLive.translations.filter(
                              (item) => item.lang === slideLanguage
                            )[0].updatedAt
                          : currentEpisodeStaged.translations.filter(
                              (item) => item.lang === slideLanguage
                            )[0].updatedAt
                      )}
                    </Typography>
                  </Stack>
                </div>
              ) : null}
              {currentEpisode.translations.filter(
                (item) => item.lang === slideLanguage
              )[0].stage === "JUSTADDED" &&
              !doneChangesTranslations.filter(
                (item) => item.lang === slideLanguage
              )[0].doneChanges ? (
                <div
                  style={{
                    minHeight: 50,
                    backgroundColor: "#f9f9fb",
                    display: "flex",
                    alignItems: "center",
                    borderLeft: "4px solid #808080",
                    paddingLeft: 15,
                    paddingRight: 15,
                    borderRadius: "0 3px 3px 0",
                  }}
                >
                  <Typography variant="overline" style={{ lineHeight: 1 }}>
                    Just added
                  </Typography>
                </div>
              ) : null}
            </Stack>
          </CardContent>
        </Card>
        <div
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            height: "100%",
          }}
          id="edit-content-container"
        >
          <CardContent style={{ position: "relative" }}>
            {/* main translations edit stack */}
            <Stack spacing={2}>
              {/* select between slides and quiz */}
              <div
                style={{
                  width: "100%",
                  height: 24,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    height: 24,
                    width: 180,
                    borderRadius: 16,
                    border: "1px solid #bdbdbd",
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      height: 24,
                      width: "50%",
                      fontSize: 11,
                      position: "relative",
                      zIndex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      color: selectedSwitchPage === 0 ? "white" : null,
                      transition: "200ms",
                    }}
                    onClick={() => {
                      setSelectedSwitchPage(0)
                    }}
                  >
                    <Typography
                      variant="overline"
                      style={{
                        fontWeight: 500,
                      }}
                    >
                      Slides
                    </Typography>
                  </div>
                  <div
                    style={{
                      height: 24,
                      width: "50%",
                      fontSize: 11,
                      position: "relative",
                      zIndex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      color: selectedSwitchPage === 1 ? "white" : null,
                      transition: "200ms",
                    }}
                    onClick={() => {
                      setSelectedSwitchPage(1)
                    }}
                  >
                    <Typography
                      variant="overline"
                      style={{
                        fontWeight: 500,
                      }}
                    >
                      Quiz
                    </Typography>
                  </div>
                  <div
                    style={{
                      height: 22,
                      width: 90,
                      borderRadius: 16,
                      backgroundColor: "#5262ba",
                      position: "absolute",
                      left: selectedSwitchPage === 0 ? 0 : 90,
                      top: 0,
                      transition: "250ms",
                    }}
                  />
                </div>
              </div>
              {/* drag and drop slides */}
              <span id="translation-title-container" style={{ width: "100%" }}>
                <TextField
                  fullWidth
                  size="small"
                  value={
                    currentEpisode.translations.filter(
                      (item) => item.lang === slideLanguage
                    )[0].title
                  }
                  onChange={(e) => {
                    let currentEpisodeCopy: Episode = deepCopy(currentEpisode)
                    currentEpisodeCopy.translations.filter(
                      (item) => item.lang === slideLanguage
                    )[0].title = e.target.value
                    setCurrentEpisode(currentEpisodeCopy)
                  }}
                  label={`Title (${slideLanguage.toUpperCase()})`}
                  disabled={
                    currentEpisode.translations
                      .filter((item) => item.lang === slideLanguage)[0]
                      .slides.filter(
                        (item) => item.slideType === "TitleSlide"
                      )[0]
                      ? true
                      : false
                  }
                  error={
                    currentEpisode.translations.filter(
                      (item) => item.lang === slideLanguage
                    )[0].title.length < 3 ||
                    currentEpisode.translations.filter(
                      (item) => item.lang === slideLanguage
                    )[0].title.length >
                      slideConstraintsList.filter(
                        (item) => item.type === "TitleSlide"
                      )[0].title.maxSize
                  }
                  helperText={
                    currentEpisode.translations
                      .filter((item) => item.lang === slideLanguage)[0]
                      .slides.filter(
                        (item) => item.slideType === "TitleSlide"
                      )[0]
                      ? "Edit Title Slide's title to modify this field"
                      : currentEpisode.translations.filter(
                          (item) => item.lang === slideLanguage
                        )[0].title.length < 3
                      ? "Minimum length is 3 characters"
                      : currentEpisode.translations.filter(
                          (item) => item.lang === slideLanguage
                        )[0].title.length >
                        slideConstraintsList.filter(
                          (item) => item.type === "TitleSlide"
                        )[0].title.maxSize
                      ? `Maximum length is ${
                          slideConstraintsList.filter(
                            (item) => item.type === "TitleSlide"
                          )[0].title.maxSize
                        } characters`
                      : null
                  }
                  style={{
                    marginBottom:
                      currentEpisode.translations
                        .filter((item) => item.lang === slideLanguage)[0]
                        .slides.filter(
                          (item) => item.slideType === "TitleSlide"
                        )[0] ||
                      currentEpisode.translations.filter(
                        (item) => item.lang === slideLanguage
                      )[0].title.length < 3
                        ? -18
                        : 0,
                  }}
                  onBlur={() => {
                    if (
                      currentEpisode.translations.filter(
                        (item) => item.lang === slideLanguage
                      )[0].title
                    ) {
                      let currentEpisodeCopy: Episode = deepCopy(currentEpisode)
                      currentEpisodeCopy.translations.filter(
                        (item) => item.lang === slideLanguage
                      )[0].title = currentEpisodeCopy.translations
                        .filter((item) => item.lang === slideLanguage)[0]
                        .title.trim()
                      setCurrentEpisode(currentEpisodeCopy)
                    }
                  }}
                />
              </span>
              <Divider />
              {currentEpisode.translations.filter(
                (item) => item.lang === slideLanguage
              )[0] &&
              topicsList.length &&
              sdgsList.length ? (
                <Stack spacing={2}>
                  {/* drag and drop context */}
                  <DragDropContext
                    onDragEnd={onDragEnd}
                    onDragStart={() => {
                      setSidebarOpen(false)
                    }}
                  >
                    {/* droppable (the column) */}
                    <Droppable droppableId="column-1">
                      {(provided) => (
                        <Stack
                          spacing={2}
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: 0,
                              marginTop: -16,
                            }}
                          />
                          {selectedSwitchPage === 0 ? (
                            <SlidesStack
                              slideLanguage={slideLanguage}
                              handleChange={handleChange}
                              expanded={expanded}
                              setRemoveSlideDialogOpen={
                                setRemoveSlideDialogOpen
                              }
                              setSlideIdToRemove={setSlideIdToRemove}
                              translationChangesCancelled={
                                translationChangesCancelled
                              }
                              setDuplicateDialogOpen={setDuplicateDialogOpen}
                              setSlideToDuplicate={setSlideToDuplicate}
                              setDuplicateToTranslationDialogOpen={
                                setDuplicateToTranslationDialogOpen
                              }
                            />
                          ) : (
                            <QuizStack
                              slideLanguage={slideLanguage}
                              handleChange={handleChange}
                              expanded={expanded}
                              setRemoveSlideDialogOpen={
                                setRemoveQuizSlideDialogOpen
                              }
                              setSlideIdToRemove={setSlideIdToRemove}
                              translationChangesCancelled={
                                translationChangesCancelled
                              }
                            />
                          )}
                          {provided.placeholder}
                        </Stack>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Stack>
              ) : null}
              {selectedSwitchPage === 0 &&
              currentEpisode.translations.filter(
                (item) => item.lang === slideLanguage
              )[0] &&
              currentEpisode.translations.filter(
                (item) => item.lang === slideLanguage
              )[0].slides.length ? (
                <Divider />
              ) : selectedSwitchPage === 1 &&
                currentEpisode.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0] &&
                currentEpisode.translations.filter(
                  (item) => item.lang === slideLanguage
                )[0].quiz.length ? (
                <Divider />
              ) : null}
              <Stack
                direction="row"
                spacing={2}
                style={{
                  marginTop:
                    selectedSwitchPage === 0 &&
                    currentEpisode.translations.filter(
                      (item) => item.lang === slideLanguage
                    )[0] &&
                    currentEpisode.translations.filter(
                      (item) => item.lang === slideLanguage
                    )[0].slides.length
                      ? null
                      : selectedSwitchPage === 1 &&
                        currentEpisode.translations.filter(
                          (item) => item.lang === slideLanguage
                        )[0] &&
                        currentEpisode.translations.filter(
                          (item) => item.lang === slideLanguage
                        )[0].quiz.length
                      ? null
                      : 0,
                }}
              >
                <Button
                  disabled={!editMode}
                  startIcon={
                    <AddCircleRounded
                      style={{
                        transform: addSlidePanelOpen ? "rotate(45deg)" : null,
                        transition: "180ms",
                        marginLeft: addSlidePanelOpen ? 12 : 0,
                      }}
                    />
                  }
                  style={{
                    maxHeight: 40,
                    minHeight: 40,
                    minWidth: addSlidePanelOpen ? 50 : "100%",
                    transition: "250ms",
                    marginRight: addSlidePanelOpen ? 1 : 10,
                  }}
                  variant="outlined"
                  onClick={() => {
                    if (!addSlidePanelOpen) {
                      setSidebarOpen(false)
                    }

                    setAddSlidePanelOpen(!addSlidePanelOpen)
                  }}
                >
                  {addSlidePanelOpen ? "" : "Add slide"}
                </Button>
                {selectedSwitchPage === 0 &&
                !currentEpisode.translations
                  .filter((item) => item.lang === slideLanguage)[0]
                  .slides.filter(
                    (item) => item.slideType === "TitleSlide"
                  )[0] ? (
                  <Tooltip title="Title">
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => {
                        onAddButtonClick(
                          "Title",
                          currentEpisode.translations.filter(
                            (item) => item.lang === slideLanguage
                          )[0].title
                        )
                      }}
                      style={{
                        maxHeight: 40,
                        minHeight: 40,
                      }}
                    >
                      <TitleOutlined />
                    </Button>
                  </Tooltip>
                ) : null}
                {selectedSwitchPage === 0 && (
                  <Tooltip title="Full Text">
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => {
                        onAddButtonClick("Full Text")
                      }}
                      style={{
                        maxHeight: 40,
                        minHeight: 40,
                      }}
                    >
                      <TextSnippetOutlined />
                    </Button>
                  </Tooltip>
                )}
                {selectedSwitchPage === 0 && (
                  <Tooltip title="Text Image">
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => {
                        onAddButtonClick("Text Image")
                      }}
                      style={{
                        maxHeight: 40,
                        minHeight: 40,
                      }}
                    >
                      <VerticalSplitOutlined />
                    </Button>
                  </Tooltip>
                )}
                {selectedSwitchPage === 0 && (
                  <Tooltip title="Quote">
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => {
                        onAddButtonClick("Quote")
                      }}
                      style={{
                        maxHeight: 40,
                        minHeight: 40,
                      }}
                    >
                      <FormatQuoteOutlined />
                    </Button>
                  </Tooltip>
                )}
                {selectedSwitchPage === 0 && (
                  <Tooltip title="Embed">
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => {
                        onAddButtonClick("Embed")
                      }}
                      style={{
                        maxHeight: 40,
                        minHeight: 40,
                      }}
                    >
                      <OndemandVideoOutlined />
                    </Button>
                  </Tooltip>
                )}
                {selectedSwitchPage === 0 && (
                  <Tooltip title="Full Image">
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => {
                        onAddButtonClick("Full Image")
                      }}
                      style={{
                        maxHeight: 40,
                        minHeight: 40,
                      }}
                    >
                      <ImageOutlined />
                    </Button>
                  </Tooltip>
                )}
                {selectedSwitchPage === 0 && (
                  <Tooltip title="Portrait">
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => {
                        onAddButtonClick("Portrait")
                      }}
                      style={{
                        maxHeight: 40,
                        minHeight: 40,
                      }}
                    >
                      <PortraitOutlined />
                    </Button>
                  </Tooltip>
                )}
                {selectedSwitchPage === 1 && (
                  <Tooltip title="Quiz">
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => {
                        onAddButtonClick("Quiz")
                      }}
                      style={{
                        maxHeight: 40,
                        minHeight: 40,
                      }}
                    >
                      <QuizRounded />
                    </Button>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
          </CardContent>
        </div>
      </div>
      {/* undo changes dialog */}
      <Dialog
        open={undoDialogOpen}
        onClose={() => {
          setUndoDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-translation"
        aria-describedby="alert-dialog-description-translation"
      >
        <DialogTitle id="alert-dialog-title-translation">
          Are you sure you want to undo changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-translation">
            All changes will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setUndoDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              cancelTranslationChanges(slideLanguage)
              setTranslationChangesCancelled(true)
              setUndoDialogOpen(false)
            }}
            autoFocus
          >
            Undo changes
          </Button>
        </DialogActions>
      </Dialog>
      {/* remove slide dialog */}
      <Dialog
        open={removeSlideDialogOpen}
        onClose={() => {
          setRemoveSlideDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-remove-slide"
        aria-describedby="alert-dialog-description-remove-slide"
      >
        <DialogTitle id="alert-dialog-title-remove-slide">
          {currentEpisode.translations.filter(
            (item) => item.lang === slideLanguage
          )[0].slides.length > 1
            ? "Are you sure you want to remove this slide?"
            : "You can't remove all slides"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-remove-slide">
            {currentEpisode.translations.filter(
              (item) => item.lang === slideLanguage
            )[0].slides.length > 1
              ? "You will be able to restore it undoing the changes"
              : "There must be at least one slide per translation"}
          </DialogContentText>
        </DialogContent>
        {currentEpisode.translations.filter(
          (item) => item.lang === slideLanguage
        )[0].slides.length > 1 ? (
          <DialogActions>
            <Button
              onClick={() => {
                setRemoveSlideDialogOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setRemoveSlideDialogOpen(false)
                removeSlide(slideLanguage, slideIdToRemove)
              }}
              autoFocus
            >
              Remove slide
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              onClick={() => {
                setRemoveSlideDialogOpen(false)
              }}
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        )}
      </Dialog>
      {/* remove quiz dialog */}
      <Dialog
        open={removeQuizSlideDialogOpen}
        onClose={() => {
          setRemoveQuizSlideDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-remove-quiz-slide"
        aria-describedby="alert-dialog-description-remove-quiz-slide"
      >
        <DialogTitle id="alert-dialog-title-remove-quiz-slide">
          Are you sure you want to remove this quiz?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-remove-quiz-slide">
            You will be able to restore it undoing the changes
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setRemoveQuizSlideDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setRemoveQuizSlideDialogOpen(false)
              removeQuiz(slideLanguage, slideIdToRemove)
            }}
            autoFocus
          >
            Remove quiz
          </Button>
        </DialogActions>
      </Dialog>
      {/* publish alert dialog */}
      <Dialog
        open={publishAlertDialogOpen}
        onClose={() => {
          setPublishAlertDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-publishalert"
        aria-describedby="alert-dialog-description-publishalert"
      >
        <DialogTitle id="alert-dialog-title-publishalert">
          Attention
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-publishalert">
            You can publish this translation, but as long as the master episode
            has not a live version, it will not be visible in AWorld app.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPublishAlertDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              setPublishAlertDialogOpen(false)
              setLoading(true)
              const noErrors = await publishTranslation(
                episodeId,
                slideLanguage
              )
              if (noErrors) {
                fetchAll()
              } else {
                setLoading(false)
              }
            }}
            autoFocus
          >
            Publish
          </Button>
        </DialogActions>
      </Dialog>
      {/* add quiz alert dialog */}
      <CantPerformActionDialog
        open={addQuizAlertDialogOpen}
        setOpen={setAddQuizAlertDialogOpen}
        content="You must add at least one quiz in order to publish this translation"
      />
      {/* duplicate slide dialog */}
      <Dialog
        open={duplicateDialogOpen}
        onClose={() => {
          setDuplicateDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-duplicateslide"
        aria-describedby="alert-dialog-description-duplicateslide"
      >
        <DialogTitle id="alert-dialog-title-duplicateslide">
          Duplicate Slide
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-duplicateslide">
            Are you sure you want to duplicate this{" "}
            {slideToDuplicate
              ? slideToDuplicate.slideType.replace(/([A-Z])/g, " $1").trim()
              : ""}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDuplicateDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              duplicateSlide(slideLanguage, slideToDuplicate)
              setDuplicateSnackbarOpen(true)
              setDuplicateDialogOpen(false)
            }}
            autoFocus
          >
            Duplicate
          </Button>
        </DialogActions>
      </Dialog>
      {/* duplicate slide to another translation dialog */}
      <Dialog
        open={duplicateToTranslationDialogOpen}
        onClose={() => {
          setDuplicateToTranslationDialogOpen(false)
          setTimeout(() => {
            setTranslationToCopyTo(null)
          }, 150)
        }}
        aria-labelledby="alert-dialog-title-duplicateslide-to-another-translation"
        aria-describedby="alert-dialog-description-duplicateslide-to-another-translation"
      >
        <DialogTitle id="alert-dialog-title-duplicateslide-to-another-translation">
          Duplicate Slide to another translation
        </DialogTitle>
        {translationToCopyTo ? (
          <DialogContent>
            <DialogContentText id="alert-dialog-description-copy-translation">
              Are you sure you want to duplicate this Slide into{" "}
              <b>{translationToCopyTo.label.toUpperCase()}</b> translation?
            </DialogContentText>
          </DialogContent>
        ) : languagesForEpisodeTabs.filter((item) => {
            if (
              item.value !== "general" &&
              currentEpisode.translations.filter(
                (translation) => translation.lang === item.value
              )[0] &&
              item.value !== slideLanguage
            ) {
              return item
            }
          }).length ? (
          <List sx={{ pt: 0, minWidth: 300 }}>
            {languagesForEpisodeTabs
              .filter((item) => {
                if (
                  item.value !== "general" &&
                  currentEpisode.translations.filter(
                    (translation) => translation.lang === item.value
                  )[0] &&
                  item.value !== slideLanguage
                ) {
                  return item
                }
              })
              .map((item) => (
                <ListItem
                  button
                  onClick={() => {
                    setTranslationToCopyTo(item)
                  }}
                  key={item.value}
                >
                  <Stack direction="row" spacing={1}>
                    <span>{item.flag}</span>
                    <span>{item.label.toUpperCase()}</span>
                  </Stack>
                </ListItem>
              ))}
          </List>
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description-copy-translation">
              There are no other translations to duplicate this one into
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setDuplicateToTranslationDialogOpen(false)
              setTimeout(() => {
                setTranslationToCopyTo(null)
              }, 150)
            }}
          >
            Cancel
          </Button>
          {translationToCopyTo && (
            <Button
              onClick={async () => {
                // check if there is already a title slide in translation to copy to
                if (
                  slideToDuplicate.slideType === "TitleSlide" &&
                  currentEpisode.translations
                    .filter(
                      (item) => item.lang === translationToCopyTo.value
                    )[0]
                    .slides.filter((slide) => slide.slideType === "TitleSlide")
                    .length
                ) {
                  setDuplicateToTranslationDialogOpen(false)
                  setTimeout(() => {
                    setTranslationToCopyTo(null)
                    setCantDublicateDialogOpen(true)
                  }, 150)
                  return
                }

                duplicateSlideAnotherTranslation(
                  translationToCopyTo.value,
                  slideToDuplicate
                )
                setSelectedTab(
                  languagesForEpisodeTabs
                    .filter((item) => {
                      for (
                        let i = 0;
                        i < currentEpisode.translations.length;
                        i++
                      ) {
                        if (
                          currentEpisode.translations[i].lang === item.value
                        ) {
                          return item
                        }
                      }
                    })
                    .findIndex(
                      (item) => item.value === translationToCopyTo.value
                    ) + 1
                )
                setSlideLanguage(translationToCopyTo.value)

                setDuplicateToTranslationDialogOpen(false)
                setDuplicateSnackbarOpen(true)
                setTimeout(() => {
                  setTranslationToCopyTo(null)
                }, 150)
              }}
              autoFocus
            >
              Duplicate
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {/* copy to another translation dialog */}
      <Dialog
        onClose={() => {
          setCopyTranslationDialogOpen(false)
          setTranslationToCopyTo(null)
        }}
        open={copyTranslationDialogOpen}
      >
        <DialogTitle>Copy to another translation</DialogTitle>
        {translationToCopyTo ? (
          <DialogContent>
            <DialogContentText id="alert-dialog-description-copy-translation">
              Are you sure you want to copy this translation into{" "}
              <b>{translationToCopyTo.label.toUpperCase()}</b>?
            </DialogContentText>
          </DialogContent>
        ) : languagesForEpisodeTabs.filter((item) => {
            if (
              item.value !== "general" &&
              currentEpisode.translations.filter(
                (translation) => translation.lang === item.value
              )[0] &&
              item.value !== slideLanguage
            ) {
              return item
            }
          }).length ? (
          <List sx={{ pt: 0, minWidth: 300 }}>
            {languagesForEpisodeTabs
              .filter((item) => {
                if (
                  item.value !== "general" &&
                  currentEpisode.translations.filter(
                    (translation) => translation.lang === item.value
                  )[0] &&
                  item.value !== slideLanguage
                ) {
                  return item
                }
              })
              .map((item) => (
                <ListItem
                  button
                  onClick={() => {
                    setTranslationToCopyTo(item)
                  }}
                  key={item.value}
                >
                  <Stack direction="row" spacing={1}>
                    <span>{item.flag}</span>
                    <span>{item.label.toUpperCase()}</span>
                  </Stack>
                </ListItem>
              ))}
          </List>
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description-copy-translation">
              There are no other translations to copy this one into
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setCopyTranslationDialogOpen(false)
              setTimeout(() => {
                setTranslationToCopyTo(null)
              }, 150)
            }}
          >
            Cancel
          </Button>
          {translationToCopyTo && (
            <Button
              onClick={() => {
                copyToAnotherTranslation(
                  slideLanguage,
                  translationToCopyTo.value
                )
                setCopyTranslationDialogOpen(false)
                setSelectedTab(
                  languagesForEpisodeTabs
                    .filter((item) => {
                      for (
                        let i = 0;
                        i < currentEpisode.translations.length;
                        i++
                      ) {
                        if (
                          currentEpisode.translations[i].lang === item.value
                        ) {
                          return item
                        }
                      }
                    })
                    .findIndex(
                      (item) => item.value === translationToCopyTo.value
                    ) + 1
                )
                setSlideLanguage(translationToCopyTo.value)
                setTimeout(() => {
                  setTranslationToCopyTo(null)
                }, 150)
              }}
            >
              Copy
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {/* cannot duplicate slide to another translation dialog */}
      <CantPerformActionDialog
        open={cantDublicateDialogOpen}
        setOpen={setCantDublicateDialogOpen}
        content="The translation you are trying to duplicate this Slide into already has a Title Slide"
      />
      {/* slide duplicated to another translation snackbar */}
      <Snackbar
        open={duplicateSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => {
          setDuplicateSnackbarOpen(false)
        }}
      >
        <Alert
          onClose={() => {
            setDuplicateSnackbarOpen(false)
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Slide duplicated!
        </Alert>
      </Snackbar>
    </Stack>
  )
}

export default EpisodeTranslationsEdit
