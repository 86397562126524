import { Chip, Stack, TableCell, Typography } from "@mui/material"
import CopyToClipboardButton from "../../global/copyToClipboardButton"

const TitleTableCellCategory = ({
  title,
  id,
  backTagColor,
  foreColor,
  titleMaxWidth = 120,
}: {
  title: string
  id: string
  backTagColor: string
  foreColor: string
  titleMaxWidth?: number
}) => {
  return (
    <TableCell>
      <Stack sx={{ gap: -5 }}>
        <div style={{ display: "inline-block", height: 22.5 }}>
          <Chip
            label={title}
            style={{
              backgroundColor: backTagColor,
              color: foreColor,
              maxWidth: titleMaxWidth,
              height: 22.5,
            }}
            size="small"
          />
        </div>
        <Stack direction="row" alignItems="center" style={{ marginLeft: -3 }}>
          <CopyToClipboardButton textToCopy={id} />
          <Typography
            variant="caption"
            style={{
              fontSize: 10,
              color: "gray",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {id}
          </Typography>
        </Stack>
      </Stack>
    </TableCell>
  )
}

export default TitleTableCellCategory
