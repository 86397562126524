import { AddCircleRounded } from "@mui/icons-material"
import { Button } from "@mui/material"
import { BodySliceType } from "../../../services/config/enum"

const AddBlockPanel = ({
  editMode,
  addPrize,
}: {
  editMode: boolean
  addPrize: (type: BodySliceType) => void
}) => {
  return (
    <Button
      disabled={!editMode}
      startIcon={<AddCircleRounded />}
      style={{
        maxHeight: 40,
        minHeight: 40,
        minWidth: "100%",
        transition: "250ms",
        marginRight: 1,
      }}
      variant="outlined"
      onClick={() => {
        addPrize(BodySliceType.BodyImageSlice)
      }}
    >
      Add prize
    </Button>
  )
}

export default AddBlockPanel
