import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import { TagsContext } from "../../../controllers/tags"
import {
  Autocomplete,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { renderOption, renderTags } from "../../../services/utilities/utility"

interface AutocompleteOption {
  id: string
  label: string
}

const TopicGeneralEdit = ({
  errors,
}: {
  errors: { type: string; error: boolean }[]
}) => {
  const { showPadding } = useContext(MainContext)
  const {
    currentTopic,
    setCurrentTopic,
    editMode,
    mainTopicsLoading,
    mainTopicsList,
  } = useContext(TagsContext)

  // errors
  const nameError = errors.filter((item) => item.type === "name")[0].error
  const codeError = errors.filter((item) => item.type === "code")[0].error
  const mainTopicError = errors.filter((item) => item.type === "mainTopic")[0]
    .error

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Text
        </Typography>
        <TextField
          label="Name"
          value={currentTopic.name}
          onChange={(e) => {
            currentTopic.name = e.target.value
            setCurrentTopic({ ...currentTopic })
          }}
          size="small"
          onBlur={() => {
            currentTopic.name = currentTopic.name.trim()
            setCurrentTopic({ ...currentTopic })
          }}
          disabled={!editMode}
          error={nameError}
          helperText={nameError ? "This field is required" : ""}
        />
        {/* <TextField
          label="Code"
          value={currentTopic.code}
          onChange={(e) => {
            currentTopic.code = e.target.value.toUpperCase()
            setCurrentTopic({ ...currentTopic })
          }}
          size="small"
          onBlur={() => {
            currentTopic.code = currentTopic.code.trim()
            setCurrentTopic({ ...currentTopic })
          }}
          placeholder="SHO"
          disabled={!editMode}
          error={codeError}
          helperText={codeError ? "This field is required" : ""}
        /> */}
        <Typography variant="h6" className="card-title">
          Main Topic
        </Typography>
        <Autocomplete
          loading={mainTopicsLoading}
          fullWidth
          blurOnSelect
          size="small"
          value={
            currentTopic.mainTopic
              ? {
                  id: currentTopic.mainTopic.id,
                  label: currentTopic.mainTopic.name,
                }
              : null
          }
          disablePortal
          isOptionEqualToValue={(
            option: AutocompleteOption,
            value: AutocompleteOption
          ) => option.id === value.id}
          options={mainTopicsList.map((item) => {
            return {
              id: item.id,
              label: item.name,
            }
          })}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Main Topic"
              error={mainTopicError}
              helperText={mainTopicError ? "This field is required" : ""}
            />
          )}
          onChange={(e: any, newValue: AutocompleteOption | null) => {
            if (newValue) {
              currentTopic.mainTopic.id = newValue.id
              currentTopic.mainTopic.name = newValue.label
            } else {
              currentTopic.mainTopic = null
            }
            setCurrentTopic({ ...currentTopic })
          }}
          renderOption={renderOption}
          renderTags={renderTags}
        />
      </Stack>
    </CardContent>
  )
}

export default TopicGeneralEdit
