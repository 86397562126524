import { Tab, Tabs } from "@mui/material"
import { useContext } from "react"
import { TagsContext } from "../../controllers/tags"
import ListContainer from "../../components/global/listContainer"
import SdgsListTab from "../../components/tags/sdgs/sdgsListTab"
import SdgTargetsListTab from "../../components/tags/sdgTargets/sdgTargetsListTab"
import CategoriesListTab from "../../components/tags/categories/categoriesListTab"
import MainCategoriesListTab from "../../components/tags/mainCategories/mainCategoriesListTab"
import TopicsListTab from "../../components/tags/topics/topicsListTab"

const TagsList = () => {
  const { currentTab, setCurrentTab } = useContext(TagsContext)

  return (
    <ListContainer>
      <Tabs
        value={currentTab}
        onChange={(e, newIndex) => {
          setCurrentTab(newIndex)
        }}
        variant="fullWidth"
      >
        <Tab label="Categories" />
        <Tab label="Main Categories" />
        <Tab label="SDGs" />
        <Tab label="SDG Targets" />
        <Tab label="Topics" />
      </Tabs>
      {currentTab === 0 ? (
        <CategoriesListTab />
      ) : currentTab === 1 ? (
        <MainCategoriesListTab />
      ) : currentTab === 2 ? (
        <SdgsListTab />
      ) : currentTab === 3 ? (
        <SdgTargetsListTab />
      ) : (
        <TopicsListTab />
      )}
    </ListContainer>
  )
}

export default TagsList
