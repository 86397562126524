import { useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react"
import Episode from "../models/episode"
import EpisodeTranslation from "../models/episodeTranslation"
import Sdg from "../models/sdg"
import SlideConstraints from "../models/slideConstraints"
import Topic from "../models/topic"
import { cleanEpisodesList } from "../services/utilities/episodeUtility"
import {
  archiveEpisode,
  createEpisode,
  publishEpisode,
  publishEpisodeTranslation,
  syncNotionEpisode,
  unArchiveEpisode,
  updateEpisode,
  upsertEpisodeTranslation,
} from "../services/graphql/mutations"
import {
  stagedEpisode,
  stagedEpisodes,
  slideConstraints,
  episode,
  archivedEpisodes,
  episodeJourneys,
} from "../services/graphql/queries"
import {
  deepCopy,
  generateRandomString,
  logger,
  lowercaseFirstCharacter,
  Status,
} from "../services/utilities/utility"
import { MainContext } from "./main"
import EpisodeSlide from "../models/episodeSlide"
import { useMockDataForEpisode } from "../services/config/constants"
import {
  liveEpisodeMockData,
  stagedEpisodeMockData,
} from "../services/mockData/episodeMockData"
import SdgTarget from "../models/sdgTarget"
import EpisodeSlideQuiz from "../models/episodeSlideQuiz"
import { LanguagesContext } from "./languages"
import Journey from "../models/journey"

interface AutocompleteOption {
  label: string
  id: string
}

interface EpisodesContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  episodesList: Episode[]
  setEpisodesList: Dispatch<SetStateAction<Episode[]>>
  getEpisodesList: (withLoading?: boolean, archived?: boolean) => void
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
  hasSearched: boolean
  getEpisodeDetails: (id: string) => Promise<boolean>
  currentEpisode?: Episode
  setCurrentEpisode: Dispatch<SetStateAction<Episode | undefined>>
  preChangesCurrentEpisode?: Episode
  doneChanges: boolean
  cancelChanges: () => void
  createNewEpisode: (
    input: object,
    disableLoading?: boolean
  ) => Promise<Episode>
  updateEpisodeParent: (
    input: object,
    withTimeout?: boolean
  ) => Promise<Episode>
  slideConstraintsList: SlideConstraints[]
  getSlideConstraints: () => Promise<boolean>
  slidesErrors: {
    lang: string
    slides: { slideId: string; error: boolean }[]
  }[]
  setSlidesErrors: Dispatch<
    SetStateAction<
      { lang: string; slides: { slideId: string; error: boolean }[] }[]
    >
  >
  doneChangesTranslations: { lang: string; doneChanges: boolean }[]
  cancelTranslationChanges: (lang: string) => void
  changeParentStage: (toStage: string) => void
  changeTranslationStage: (lang: string, toStage: string) => void
  currentEpisodeStaged: Episode
  currentEpisodeLive: Episode
  addTranslationToCurrentEpisode: (lang: string) => void
  removeTranslationFromCurrentEpisode: (lang: string) => void
  addSlide: (lang: string, newSlide: EpisodeSlide) => void
  addQuiz: (lang: string, newSlide: EpisodeSlideQuiz) => void
  removeSlide: (lang: string, slideId: string) => void
  removeQuiz: (lang: string, slideId: string) => void
  publishEpisodeParent: (episodeId: string) => Promise<boolean>
  archiveEpisodeParent: (episodeId: string) => Promise<boolean>
  unArchiveEpisodeParent: (episodeId: string) => Promise<boolean>
  publishTranslation: (episodeId: string, lang: string) => Promise<boolean>
  updateTranslation: (
    id: string,
    episodeId: string,
    lang: string,
    title: string,
    slides: EpisodeSlide[],
    quiz: EpisodeSlideQuiz[],
    deleteIds?: boolean
  ) => Promise<boolean>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  episodesListNextToken: string | null
  loadMoreEpisodes: (archived?: boolean) => Promise<boolean>
  searchEpisodes: ({
    title,
    lang,
    topic,
    sdg,
    sdgTarget,
    category,
    esg,
    doNotRecommend,
    type,
    archived,
  }: {
    title?: string
    lang?: AutocompleteOption | null
    topic?: AutocompleteOption | null
    sdg?: AutocompleteOption | null
    sdgTarget?: AutocompleteOption | null
    category?: AutocompleteOption | null
    esg?: AutocompleteOption | null
    doNotRecommend?: AutocompleteOption | null
    type?: AutocompleteOption | null
    archived?: boolean
  }) => void
  searchTopic: AutocompleteOption | null
  setSearchTopic: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchLanguage: AutocompleteOption | null
  setSearchLanguage: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchSdg: AutocompleteOption | null
  setSearchSdg: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchSdgTarget: AutocompleteOption | null
  setSearchSdgTarget: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchCategory: AutocompleteOption | null
  setSearchCategory: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchEsg: AutocompleteOption | null
  setSearchEsg: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchDoNotRecommend: AutocompleteOption | null
  setSearchDoNotRecommend: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchType: AutocompleteOption | null
  setSearchType: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchStatus: "active" | "archived"
  setSearchStatus: Dispatch<SetStateAction<string>>
  setHasSearched: Dispatch<SetStateAction<boolean>>
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  duplicateSlide: (language: string, slideToAdd: EpisodeSlide) => void
  copyToAnotherTranslation: (currentLang: string, langToCopyTo: string) => void
  getEpisodeJourneys: (id: string) => Promise<boolean>
  currentEpisodeJourneysList: Journey[]
  duplicateSlideAnotherTranslation: (
    translation: string,
    slideToAdd: EpisodeSlide
  ) => void
  duplicateEpisode: () => Promise<boolean | Episode>
  syncEpisode: (episodeId: string) => Promise<boolean>
}

const EpisodesContext = createContext<EpisodesContextInterface>({
  loading: true,
  setLoading: () => {},
  episodesList: [],
  setEpisodesList: () => {},
  getEpisodesList: () => {},
  searchValue: "",
  setSearchValue: () => {},
  hasSearched: false,
  getEpisodeDetails: async () => true,
  currentEpisode: undefined,
  setCurrentEpisode: () => {},
  preChangesCurrentEpisode: undefined,
  doneChanges: false,
  cancelChanges: () => {},
  createNewEpisode: async () => new Episode(),
  updateEpisodeParent: async () => new Episode(),
  slideConstraintsList: [],
  getSlideConstraints: async () => true,
  slidesErrors: [],
  setSlidesErrors: () => {},
  doneChangesTranslations: [],
  cancelTranslationChanges: () => {},
  changeParentStage: () => {},
  changeTranslationStage: () => {},
  currentEpisodeStaged: undefined,
  currentEpisodeLive: undefined,
  addTranslationToCurrentEpisode: () => {},
  removeTranslationFromCurrentEpisode: () => {},
  addSlide: () => {},
  addQuiz: () => {},
  removeSlide: () => {},
  removeQuiz: () => {},
  publishEpisodeParent: async () => true,
  archiveEpisodeParent: async () => true,
  unArchiveEpisodeParent: async () => true,
  publishTranslation: async () => true,
  updateTranslation: async () => true,
  editMode: false,
  setEditMode: () => {},
  episodesListNextToken: null,
  loadMoreEpisodes: async () => true,
  searchEpisodes: () => {},
  searchTopic: null,
  setSearchTopic: () => {},
  searchLanguage: null,
  setSearchLanguage: () => {},
  searchSdg: null,
  setSearchSdg: () => {},
  searchSdgTarget: null,
  setSearchSdgTarget: () => {},
  searchCategory: null,
  setSearchCategory: () => {},
  searchEsg: null,
  setSearchEsg: () => {},
  searchDoNotRecommend: null,
  setSearchDoNotRecommend: () => {},
  searchType: null,
  setSearchType: () => {},
  searchStatus: "active",
  setSearchStatus: () => {},
  setHasSearched: () => {},
  updatingList: false,
  setUpdatingList: () => {},
  duplicateSlide: () => {},
  copyToAnotherTranslation: () => {},
  getEpisodeJourneys: async () => true,
  currentEpisodeJourneysList: [],
  duplicateSlideAnotherTranslation: () => {},
  duplicateEpisode: async () => true,
  syncEpisode: async () => true,
})

const EpisodesController = ({ children }: { children: ReactNode }) => {
  const {
    setError,
    setChangesSaved,
    setErrorMessage,
    panelStatus,
    setPanelStatus,
  } = useContext(MainContext)
  const { languages } = useContext(LanguagesContext)

  const [loading, setLoading] = useState<boolean>(true) // loading
  const [episodesList, setEpisodesList] = useState<Episode[]>([]) // all episodes list
  const [episodesListNextToken, setEpisodesListNextToken] = useState<
    string | null
  >(null) // next token for episodes list
  const [searchValue, setSearchValue] = useState<string>("") // search input value
  const [searchLanguage, setSearchLanguage] =
    useState<AutocompleteOption | null>(null) // search language filter
  const [searchTopic, setSearchTopic] = useState<AutocompleteOption | null>(
    null
  ) // search topic filter
  const [searchSdg, setSearchSdg] = useState<AutocompleteOption | null>(null) // search sdg filter
  const [searchSdgTarget, setSearchSdgTarget] =
    useState<AutocompleteOption | null>(null) // search sdg target filter
  const [searchCategory, setSearchCategory] =
    useState<AutocompleteOption | null>(null) // search category filter
  const [searchEsg, setSearchEsg] = useState<AutocompleteOption | null>(null) // search esg filter
  const [searchDoNotRecommend, setSearchDoNotRecommend] =
    useState<AutocompleteOption | null>(null) // search do not recommend filter
  const [searchType, setSearchType] = useState<AutocompleteOption | null>(null) // search type filter
  const [searchStatus, setSearchStatus] = useState<"active" | "archived">(
    "active"
  ) // search status filter
  const [hasSearched, setHasSearched] = useState<boolean>(false) // if user has searched or not
  const [currentEpisode, setCurrentEpisode] = useState<Episode>() // current episode details
  const [currentEpisodeStaged, setCurrentEpisodeStaged] = useState<Episode>() // current episode most updated
  const [currentEpisodeLive, setCurrentEpisodeLive] = useState<Episode>() // current episode published
  const [preChangesCurrentEpisode, setPreChangesCurrentEpisode] =
    useState<Episode>() // fetched current episode details
  const [doneChanges, setDoneChanges] = useState<boolean>(false) // if user has done changes to episode details
  const [doneChangesTranslations, setDoneChangesTranslations] = useState<
    { lang: string; doneChanges: boolean }[]
  >([
    ...languages.map((item) => {
      return {
        lang: item,
        doneChanges: false,
      }
    }),
  ]) // if user has done changes to translations
  const [slideConstraintsList, setSlideConstraintsList] = useState<
    SlideConstraints[]
  >([]) // constraints for all slide types
  const [slidesErrors, setSlidesErrors] = useState<
    { lang: string; slides: { slideId: string; error: boolean }[] }[]
  >([]) // to check if in every slide there is an error or not
  const [editMode, setEditMode] = useState<boolean>(true) // if all episode details page should be disabled or not
  const [updatingList, setUpdatingList] = useState<boolean>(false) // indicates a list update
  const [currentEpisodeJourneysList, setCurrentEpisodeJourneysList] = useState<
    Journey[]
  >([]) // list of episode journeys

  // queries
  const [stagedEpisodesQuery] = useLazyQuery(stagedEpisodes)
  const [archivedEpisodesQuery] = useLazyQuery(archivedEpisodes)
  const [getStagedEpisodeQuery] = useLazyQuery(stagedEpisode)
  const [getEpisodeQuery] = useLazyQuery(episode)
  const [slideConstraintsQuery] = useLazyQuery(slideConstraints)
  const [episodeJourneysQuery] = useLazyQuery(episodeJourneys)

  // mutations
  const [createEpisodeMutation] = useMutation(createEpisode)
  const [updateEpisodeMutation] = useMutation(updateEpisode)
  const [publishEpisodeMutation] = useMutation(publishEpisode)
  const [archiveEpisodeMutation] = useMutation(archiveEpisode)
  const [unArchiveEpisodeMutation] = useMutation(unArchiveEpisode)
  const [publishEpisodeTranslationMutation] = useMutation(
    publishEpisodeTranslation
  )
  const [upsertEpisodeTranslationMutation] = useMutation(
    upsertEpisodeTranslation
  )
  const [syncNotionEpisodeMutation] = useMutation(syncNotionEpisode)

  // get all episodes list
  const getEpisodesList = useCallback(
    async (withLoading: boolean = true, archived: boolean = false) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter((item) => item.name === "Episodes")[0].loading = true
        setPanelStatus([...panelStatus])
      }

      try {
        if (!archived) {
          logger(Status.Api, "QUERY stagedEpisodes", stagedEpisodes)
          const { data } = await stagedEpisodesQuery({
            variables: { limit: Math.round(window.innerHeight / 74) + 10 },
            fetchPolicy: "no-cache",
          })
          setEpisodesListNextToken(data.stagedEpisodes.nextToken)
          logger(Status.Info, "episodes list", data.stagedEpisodes.items)

          setEpisodesList(data.stagedEpisodes.items)
        } else {
          logger(Status.Api, "QUERY archivedEpisodes", archivedEpisodes)
          const { data } = await archivedEpisodesQuery({
            variables: { limit: Math.round(window.innerHeight / 74) + 10 },
            fetchPolicy: "no-cache",
          })
          setEpisodesListNextToken(data.archivedEpisodes.nextToken)
          logger(
            Status.Info,
            "archived episodes list",
            data.archivedEpisodes.items
          )

          setEpisodesList(data.archivedEpisodes.items)
        }

        setHasSearched(false)
        setUpdatingList(false)
        if (withLoading) {
          setLoading(false)
        }
        panelStatus.filter((item) => item.name === "Episodes")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "Episodes")[0].loading =
          false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          if (!archived) {
            logger(Status.Error, `stagedEpisodes`, e.message)
          } else {
            logger(Status.Error, `archivedEpisodes`, e.message)
          }
        }
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Episodes")[0].status =
          "error"
        panelStatus.filter((item) => item.name === "Episodes")[0].loading =
          false
        setPanelStatus([...panelStatus])
        if (withLoading) {
          setLoading(false)
        }
      }
    },
    [setError, setErrorMessage]
  )

  // load more episodes and add them in episodes list
  const loadMoreEpisodes = async (archived: boolean = false) => {
    try {
      if (!archived) {
        logger(Status.Api, "QUERY stagedEpisodes", stagedEpisodes)
        const { data } = await stagedEpisodesQuery({
          variables: {
            limit: Math.round(window.innerHeight / 74) + 10,
            title: searchValue ? searchValue : null,
            lang: searchLanguage ? searchLanguage.id : null,
            topic: searchTopic ? searchTopic.id : null,
            sdg: searchSdg ? searchSdg.id : null,
            sdgTarget: searchSdgTarget ? searchSdgTarget.id : null,
            category: searchCategory ? searchCategory.id : null,
            esg: searchEsg ? searchEsg.id : null,
            doNotRecommend: searchDoNotRecommend
              ? searchDoNotRecommend.id === "yes"
                ? false
                : true
              : null,
            type: searchType ? searchType.id : null,
            nextToken: episodesListNextToken,
          },
          fetchPolicy: "no-cache",
        })
        setEpisodesListNextToken(data.stagedEpisodes.nextToken)
        let dataToAdd = JSON.parse(JSON.stringify(data.stagedEpisodes.items))
        dataToAdd.map((item: any) => {
          delete item.createdAtT
          delete item.updatedAtT
          return item
        })
        logger(Status.Info, "episodes list", [...episodesList, ...dataToAdd])

        setEpisodesList([...episodesList, ...dataToAdd])
      } else {
        logger(Status.Api, "QUERY archivedEpisodes", archivedEpisodes)
        const { data } = await archivedEpisodesQuery({
          variables: {
            limit: Math.round(window.innerHeight / 74) + 10,
            title: searchValue,
            lang: searchLanguage ? searchLanguage.id : null,
            topic: searchTopic ? searchTopic.id : null,
            sdg: searchSdg ? searchSdg.id : null,
            sdgTarget: searchSdgTarget ? searchSdgTarget.id : null,
            category: searchCategory ? searchCategory.id : null,
            esg: searchEsg ? searchEsg.id : null,
            doNotRecommend: searchDoNotRecommend
              ? searchDoNotRecommend.id === "yes"
                ? false
                : true
              : null,
            type: searchType ? searchType.id : null,
            nextToken: episodesListNextToken,
          },
          fetchPolicy: "no-cache",
        })
        setEpisodesListNextToken(data.archivedEpisodes.nextToken)
        let dataToAdd = JSON.parse(JSON.stringify(data.archivedEpisodes.items))
        dataToAdd.map((item: any) => {
          delete item.createdAtT
          delete item.updatedAtT
          return item
        })
        logger(Status.Info, "archived episodes list", [
          ...episodesList,
          ...dataToAdd,
        ])

        setEpisodesList([...episodesList, ...dataToAdd])
      }

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        if (!archived) {
          logger(Status.Error, `stagedEpisodes`, e.message)
        } else {
          logger(Status.Error, `archivedEpisodes`, e.message)
        }
      }
      return false
    }
  }

  // search episodes by title and/or filters
  const searchEpisodes = useCallback(
    async ({
      title,
      lang,
      topic,
      sdg,
      sdgTarget,
      category,
      esg,
      doNotRecommend,
      type,
      archived = false,
    }: {
      title?: string
      lang?: AutocompleteOption | null
      topic?: AutocompleteOption | null
      sdg?: AutocompleteOption | null
      sdgTarget?: AutocompleteOption | null
      category?: AutocompleteOption | null
      esg?: AutocompleteOption | null
      doNotRecommend?: AutocompleteOption | null
      type?: AutocompleteOption | null
      archived?: boolean
    }) => {
      setUpdatingList(true)

      try {
        if (!archived) {
          logger(Status.Api, "QUERY stagedEpisodes", stagedEpisodes)
          const { data } = await stagedEpisodesQuery({
            variables: {
              limit: Math.round(window.innerHeight / 74) + 10,
              title: title,
              lang: lang ? lang.id : null,
              topic: topic ? topic.id : null,
              sdg: sdg ? sdg.id : null,
              sdgTarget: sdgTarget ? sdgTarget.id : null,
              category: category ? category.id : null,
              esg: esg ? esg.id : null,
              doNotRecommend: doNotRecommend
                ? doNotRecommend.id === "yes"
                  ? false
                  : true
                : null,
              type: type ? type.id : null,
            },
            fetchPolicy: "no-cache",
          })
          setEpisodesListNextToken(data.stagedEpisodes.nextToken)
          logger(Status.Info, "searched episodes", data.stagedEpisodes.items)

          setEpisodesList(data.stagedEpisodes.items)
        } else {
          logger(Status.Api, "QUERY archivedEpisodes", archivedEpisodes)
          const { data } = await archivedEpisodesQuery({
            variables: {
              limit: Math.round(window.innerHeight / 74) + 10,
              title: title,
              lang: lang ? lang.id : null,
              topic: topic ? topic.id : null,
              sdg: sdg ? sdg.id : null,
              sdgTarget: sdgTarget ? sdgTarget.id : null,
              category: category ? category.id : null,
              esg: esg ? esg.id : null,
            },
            fetchPolicy: "no-cache",
          })
          setEpisodesListNextToken(data.archivedEpisodes.nextToken)
          logger(
            Status.Info,
            "searched archived episodes",
            data.archivedEpisodes.items
          )

          setEpisodesList(data.archivedEpisodes.items)
        }

        setHasSearched(true)
        setUpdatingList(false)
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          if (!archived) {
            logger(Status.Error, `stagedEpisodes`, e.message)
          } else {
            logger(Status.Error, `archivedEpisodes`, e.message)
          }
          setUpdatingList(false)
        }
      }
    },
    [setError, setErrorMessage]
  )

  // get episode details
  const getEpisodeDetails = useCallback(
    async (id: string) => {
      try {
        logger(Status.Api, "QUERY stagedEpisode " + id, stagedEpisode)
        logger(Status.Api, "QUERY episode " + id, episode)
        let result = await Promise.all([
          getStagedEpisodeQuery({
            variables: { stagedEpisodeId: id },
            fetchPolicy: "no-cache",
          }),
          getEpisodeQuery({
            variables: { episodeId: id, lang: "all" },
            fetchPolicy: "no-cache",
          }),
        ])
        if (useMockDataForEpisode) {
          result = [stagedEpisodeMockData, liveEpisodeMockData] as any
        }

        const dataToSetStaged = cleanEpisodesList(
          result[0].data,
          "stagedEpisode"
        )
        logger(
          Status.Info,
          `staged episode ${id}`,
          dataToSetStaged.stagedEpisode
        )

        if (result[1].data && result[1].data.episode) {
          const dataToSetLive = cleanEpisodesList(result[1].data, "episode")
          logger(Status.Info, `live episode ${id}`, dataToSetLive.episode)

          setCurrentEpisodeLive(dataToSetLive.episode)
        } else {
          setCurrentEpisodeLive(null)
        }

        setCurrentEpisode(deepCopy(dataToSetStaged.stagedEpisode))
        setPreChangesCurrentEpisode(deepCopy(dataToSetStaged.stagedEpisode))

        setCurrentEpisodeStaged(deepCopy(dataToSetStaged.stagedEpisode))

        let errorsArray = []
        for (
          let i = 0;
          i < dataToSetStaged.stagedEpisode.translations.length;
          i++
        ) {
          let currentTranslations: EpisodeTranslation =
            dataToSetStaged.stagedEpisode.translations[i]
          let objectToPush = { lang: currentTranslations.lang, slides: [] }
          for (let j = 0; j < currentTranslations.slides.length; j++) {
            objectToPush.slides.push({
              slideId: currentTranslations.slides[j].id,
              error: false,
            })
          }
          for (let j = 0; j < currentTranslations.quiz.length; j++) {
            objectToPush.slides.push({
              slideId: currentTranslations.quiz[j].id,
              error: false,
            })
          }
          errorsArray.push(objectToPush)
        }
        setSlidesErrors(errorsArray)

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(
            e.message.includes("Unexpected token")
              ? "Malformed data"
              : e.message
          )
          logger(Status.Error, `episode`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // get episode journeys
  const getEpisodeJourneys = async (id: string) => {
    try {
      logger(Status.Api, "QUERY episodeJourneys " + id, episodeJourneys)
      const { data } = await episodeJourneysQuery({
        variables: { episodeJourneysId: id },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, `episode ${id} journeys`, data.episodeJourneys.items)

      setCurrentEpisodeJourneysList(data.episodeJourneys.items)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `episodeJourneys`, e.message)
      }
      return false
    }
  }

  // create parent episode
  const createNewEpisode = async (input: object, disableLoading?: boolean) => {
    setLoading(true)

    try {
      logger(Status.Api, "MUTATION createEpisode", createEpisode)
      const result = await createEpisodeMutation({
        variables: { input: input },
      })
      logger(Status.Info, `episode created`, result.data.createEpisode)

      let episodeToUnshift = result.data.createEpisode
      episodesList.unshift(episodeToUnshift)
      setEpisodesList([...episodesList])

      if (!disableLoading) {
        setLoading(false)
      }

      return episodeToUnshift
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `createEpisode`, e.message)
      }

      setLoading(false)
    }
  }

  const updateEpisodeParent = async (
    input: object,
    withTimeout: boolean = true
  ) => {
    setLoading(true)

    try {
      logger(Status.Api, "MUTATION updateEpisode", updateEpisode)
      const { data } = await updateEpisodeMutation({
        variables: { input: input },
      })
      const dataToSet = cleanEpisodesList(data, "updateEpisode")
      logger(Status.Info, `episode updated`, dataToSet.updateEpisode)

      if (withTimeout) {
        setTimeout(() => {
          setLoading(false)
          setCurrentEpisode(deepCopy(dataToSet.updateEpisode))
          setPreChangesCurrentEpisode(deepCopy(dataToSet.updateEpisode))
          setCurrentEpisodeStaged(deepCopy(dataToSet.updateEpisode))
          setChangesSaved(true)
        }, 800)
      }

      return dataToSet.updateEpisode
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `updateEpisode`, e.message)
      }

      setLoading(false)
    }
  }

  // get slide constraints
  const getSlideConstraints = useCallback(async () => {
    try {
      logger(Status.Api, "QUERY getSlideConstraints", slideConstraints)
      const { data } = await slideConstraintsQuery()
      let constraintsToSet: SlideConstraints[] = []
      for (let key in data.getSlideConstraints) {
        if (key !== "__typename") {
          constraintsToSet.push({
            type: key,
            ...data.getSlideConstraints[key],
          })
        }
      }
      logger(Status.Info, `slide constraints`, constraintsToSet)

      setSlideConstraintsList(constraintsToSet)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `getSlideConstraints`, e.message)
      }
    }
  }, [setError, setErrorMessage])

  // check if user has done changes to episode details
  useEffect(() => {
    if (preChangesCurrentEpisode && currentEpisode) {
      let preChangesCurrentEpisodeCopy: Episode = JSON.parse(
        JSON.stringify(preChangesCurrentEpisode)
      )
      let currentEpisodeCopy: Episode = JSON.parse(
        JSON.stringify(currentEpisode)
      )
      delete preChangesCurrentEpisodeCopy.stage
      delete currentEpisodeCopy.stage

      preChangesCurrentEpisodeCopy.topics =
        preChangesCurrentEpisodeCopy.topics.map((item) => {
          let topic: Topic = {
            id: item.topic.id,
            name: item.topic.name,
          }

          return {
            primary: item.primary,
            topic: topic,
          }
        })
      if (preChangesCurrentEpisodeCopy.sdgs) {
        preChangesCurrentEpisodeCopy.sdgs =
          preChangesCurrentEpisodeCopy.sdgs.map((item) => {
            let sdg: Sdg = {
              id: item.sdg.id,
              name: item.sdg.name,
            }

            return {
              primary: item.primary,
              sdg: sdg,
            }
          })
      }
      if (preChangesCurrentEpisodeCopy.sdgTargets) {
        preChangesCurrentEpisodeCopy.sdgTargets =
          preChangesCurrentEpisodeCopy.sdgTargets.map((item) => {
            let sdgTarget: SdgTarget = {
              id: item.sdgTarget.id,
            }

            return {
              primary: item.primary,
              sdgTarget: sdgTarget,
            }
          })
      }
      currentEpisodeCopy.topics = currentEpisodeCopy.topics.map((item) => {
        let topic: Topic = {
          id: item.topic.id,
          name: item.topic.name,
        }

        return {
          primary: item.primary,
          topic: topic,
        }
      })
      if (currentEpisodeCopy.sdgs) {
        currentEpisodeCopy.sdgs = currentEpisodeCopy.sdgs.map((item) => {
          let sdg: Sdg = {
            id: item.sdg.id,
            name: item.sdg.name,
          }

          return {
            primary: item.primary,
            sdg: sdg,
          }
        })
      }
      if (currentEpisodeCopy.sdgTargets) {
        currentEpisodeCopy.sdgTargets = currentEpisodeCopy.sdgTargets.map(
          (item) => {
            let sdgTarget: SdgTarget = {
              id: item.sdgTarget.id,
            }

            return {
              primary: item.primary,
              sdgTarget: sdgTarget,
            }
          }
        )
      }
      delete (currentEpisodeCopy.category as any).__typename
      delete (preChangesCurrentEpisodeCopy.category as any).__typename
      delete currentEpisodeCopy.translations
      delete preChangesCurrentEpisodeCopy.translations

      if (
        JSON.stringify(currentEpisodeCopy) ===
        JSON.stringify(preChangesCurrentEpisodeCopy)
      ) {
        setDoneChanges(false)
      } else {
        setDoneChanges(true)
      }
    }
  }, [currentEpisode, preChangesCurrentEpisode])

  // check if user has done changes to translations
  useEffect(() => {
    let newDoneChangesTranslations: {
      lang: string
      doneChanges: boolean
    }[] = deepCopy(doneChangesTranslations)

    for (let i = 0; i < languages.length; i++) {
      if (
        currentEpisode &&
        currentEpisode.translations.filter(
          (item) => item.lang === languages[i]
        )[0]
      ) {
        if (
          JSON.stringify(
            currentEpisode.translations.filter(
              (item) => item.lang === languages[i]
            )[0].slides
          ) ===
            JSON.stringify(
              preChangesCurrentEpisode.translations.filter(
                (item) => item.lang === languages[i]
              )[0].slides
            ) &&
          JSON.stringify(
            currentEpisode.translations.filter(
              (item) => item.lang === languages[i]
            )[0].quiz
          ) ===
            JSON.stringify(
              preChangesCurrentEpisode.translations.filter(
                (item) => item.lang === languages[i]
              )[0].quiz
            ) &&
          JSON.stringify(
            currentEpisode.translations.filter(
              (item) => item.lang === languages[i]
            )[0].title
          ) ===
            JSON.stringify(
              preChangesCurrentEpisode.translations.filter(
                (item) => item.lang === languages[i]
              )[0].title
            )
        ) {
          newDoneChangesTranslations.filter(
            (item) => item.lang === languages[i]
          )[0].doneChanges = false
        } else {
          newDoneChangesTranslations.filter(
            (item) => item.lang === languages[i]
          )[0].doneChanges = true
        }
      }
    }

    setDoneChangesTranslations(newDoneChangesTranslations)
  }, [currentEpisode, preChangesCurrentEpisode])

  // cancel episode details changes
  const cancelChanges = () => {
    let currentEpisodeCopy: Episode = deepCopy(currentEpisode)
    currentEpisodeCopy = deepCopy(preChangesCurrentEpisode)
    currentEpisodeCopy.translations = currentEpisode.translations

    setCurrentEpisode(currentEpisodeCopy)
  }

  // cancel episode translation changes
  const cancelTranslationChanges = (lang: string) => {
    let currentEpisodeCopy: Episode = deepCopy(currentEpisode)

    currentEpisodeCopy.translations.filter(
      (item) => item.lang === lang
    )[0].slides = deepCopy(
      preChangesCurrentEpisode.translations.filter(
        (item) => item.lang === lang
      )[0].slides
    )
    currentEpisodeCopy.translations.filter(
      (item) => item.lang === lang
    )[0].quiz = deepCopy(
      preChangesCurrentEpisode.translations.filter(
        (item) => item.lang === lang
      )[0].quiz
    )
    currentEpisodeCopy.translations.filter(
      (item) => item.lang === lang
    )[0].title = deepCopy(
      preChangesCurrentEpisode.translations.filter(
        (item) => item.lang === lang
      )[0].title
    )

    setCurrentEpisode(currentEpisodeCopy)

    let errorsArray = []
    for (let i = 0; i < currentEpisodeCopy.translations.length; i++) {
      let currentTranslations: EpisodeTranslation =
        currentEpisodeCopy.translations[i]
      let objectToPush = { lang: currentTranslations.lang, slides: [] }
      for (let j = 0; j < currentTranslations.slides.length; j++) {
        objectToPush.slides.push({
          slideId: currentTranslations.slides[j].id,
          error: false,
        })
      }
      for (let j = 0; j < currentTranslations.quiz.length; j++) {
        objectToPush.slides.push({
          slideId: currentTranslations.quiz[j].id,
          error: false,
        })
      }
      errorsArray.push(objectToPush)
    }

    setSlidesErrors(errorsArray)
  }

  // change parent stage
  const changeParentStage = (toStage: string) => {
    let currentEpisodeCopy: Episode = deepCopy(currentEpisode)
    let preChangesCurrentEpisodeCopy: Episode = deepCopy(
      preChangesCurrentEpisode
    )

    if (toStage === "PUBLISHED") {
      currentEpisodeCopy = deepCopy(currentEpisodeLive)
      preChangesCurrentEpisodeCopy = deepCopy(currentEpisodeLive)
      currentEpisodeCopy.translations = deepCopy(currentEpisode.translations)
      preChangesCurrentEpisodeCopy.translations = deepCopy(
        currentEpisode.translations
      )
      setCurrentEpisode(currentEpisodeCopy)
      setPreChangesCurrentEpisode(preChangesCurrentEpisodeCopy)
    }
    if (toStage === "DRAFT") {
      currentEpisodeCopy = deepCopy(currentEpisodeStaged)
      preChangesCurrentEpisodeCopy = deepCopy(currentEpisodeStaged)
      currentEpisodeCopy.translations = deepCopy(currentEpisode.translations)
      preChangesCurrentEpisodeCopy.translations = deepCopy(
        currentEpisode.translations
      )
      setCurrentEpisode(currentEpisodeCopy)
      setPreChangesCurrentEpisode(preChangesCurrentEpisodeCopy)
    }
  }

  // change translation stage
  const changeTranslationStage = (lang: string, toStage: string) => {
    let currentEpisodeCopy: Episode = deepCopy(currentEpisode)
    let preChangesCurrentEpisodeCopy: Episode = deepCopy(
      preChangesCurrentEpisode
    )
    let translationIndex = currentEpisodeCopy.translations.findIndex(
      (item) => item.lang === lang
    )
    let preChangesTranslationIndex =
      preChangesCurrentEpisodeCopy.translations.findIndex(
        (item) => item.lang === lang
      )

    if (toStage === "PUBLISHED") {
      currentEpisodeCopy.translations[translationIndex] = deepCopy(
        currentEpisodeLive.translations.filter((item) => item.lang === lang)[0]
      )
      preChangesCurrentEpisodeCopy.translations[preChangesTranslationIndex] =
        deepCopy(
          currentEpisodeLive.translations.filter(
            (item) => item.lang === lang
          )[0]
        )

      let slidesErrorsCopy: {
        lang: string
        slides: { slideId: string; error: boolean }[]
      }[] = deepCopy(slidesErrors)
      slidesErrorsCopy.filter((item) => item.lang === lang)[0].slides = []
      currentEpisodeCopy.translations
        .filter((item) => item.lang === lang)[0]
        .slides.forEach((element) => {
          slidesErrorsCopy
            .filter((item) => item.lang === lang)[0]
            .slides.push({
              slideId: element.id,
              error: false,
            })
        })
      currentEpisodeCopy.translations
        .filter((item) => item.lang === lang)[0]
        .quiz.forEach((element) => {
          slidesErrorsCopy
            .filter((item) => item.lang === lang)[0]
            .slides.push({
              slideId: element.id,
              error: false,
            })
        })
      setSlidesErrors(slidesErrorsCopy)

      setCurrentEpisode(currentEpisodeCopy)
      setPreChangesCurrentEpisode(preChangesCurrentEpisodeCopy)
    }
    if (toStage === "DRAFT") {
      currentEpisodeCopy.translations[translationIndex] = deepCopy(
        currentEpisodeStaged.translations.filter(
          (item) => item.lang === lang
        )[0]
      )
      preChangesCurrentEpisodeCopy.translations[preChangesTranslationIndex] =
        deepCopy(
          currentEpisodeStaged.translations.filter(
            (item) => item.lang === lang
          )[0]
        )

      let slidesErrorsCopy: {
        lang: string
        slides: { slideId: string; error: boolean }[]
      }[] = deepCopy(slidesErrors)
      slidesErrorsCopy.filter((item) => item.lang === lang)[0].slides = []
      currentEpisodeCopy.translations
        .filter((item) => item.lang === lang)[0]
        .slides.forEach((element) => {
          slidesErrorsCopy
            .filter((item) => item.lang === lang)[0]
            .slides.push({
              slideId: element.id,
              error: false,
            })
        })
      currentEpisodeCopy.translations
        .filter((item) => item.lang === lang)[0]
        .quiz.forEach((element) => {
          slidesErrorsCopy
            .filter((item) => item.lang === lang)[0]
            .slides.push({
              slideId: element.id,
              error: false,
            })
        })
      setSlidesErrors(slidesErrorsCopy)

      setCurrentEpisode(currentEpisodeCopy)
      setPreChangesCurrentEpisode(preChangesCurrentEpisodeCopy)
    }
  }

  // add translation
  const addTranslationToCurrentEpisode = (lang: string) => {
    let currentEpisodeCopy: Episode = deepCopy(currentEpisode)
    let currentEpisodeStagedCopy: Episode = deepCopy(currentEpisodeStaged)
    let preChangesCurrentEpisodeCopy: Episode = deepCopy(
      preChangesCurrentEpisode
    )

    currentEpisodeCopy.translations.push({
      lang: lang,
      stage: "JUSTADDED",
      slides: [],
      quiz: [],
      title: lang.toUpperCase() + " Title",
    })
    currentEpisodeStagedCopy.translations.push({
      lang: lang,
      stage: "JUSTADDED",
      slides: [],
      quiz: [],
      title: lang.toUpperCase() + " Title",
    })
    preChangesCurrentEpisodeCopy.translations.push({
      lang: lang,
      stage: "JUSTADDED",
      slides: [],
      quiz: [],
      title: lang.toUpperCase() + " Title",
    })

    let slidesErrorsCopy: {
      lang: string
      slides: { slideId: string; error: boolean }[]
    }[] = deepCopy(slidesErrors)
    slidesErrorsCopy.push({ lang: lang, slides: [] })
    setSlidesErrors(slidesErrorsCopy)

    setCurrentEpisode(currentEpisodeCopy)
    setCurrentEpisodeStaged(currentEpisodeStagedCopy)
    setPreChangesCurrentEpisode(preChangesCurrentEpisodeCopy)
  }

  // remove translation
  const removeTranslationFromCurrentEpisode = (lang: string) => {
    let currentEpisodeCopy: Episode = deepCopy(currentEpisode)
    let currentEpisodeStagedCopy: Episode = deepCopy(currentEpisodeStaged)
    let preChangesCurrentEpisodeCopy: Episode = deepCopy(
      preChangesCurrentEpisode
    )

    let indexToRemove = currentEpisodeCopy.translations.findIndex(
      (item) => item.lang === lang
    )
    currentEpisodeCopy.translations.splice(indexToRemove, 1)
    currentEpisodeStagedCopy.translations.splice(indexToRemove, 1)
    preChangesCurrentEpisodeCopy.translations.splice(indexToRemove, 1)

    if (currentEpisodeLive) {
      let currentEpisodeLiveCopy: Episode = deepCopy(currentEpisodeLive)
      currentEpisodeLiveCopy.translations.splice(indexToRemove, 1)

      setCurrentEpisodeLive(currentEpisodeLiveCopy)
    }

    let slidesErrorsCopy: {
      lang: string
      slides: { slideId: string; error: boolean }[]
    }[] = deepCopy(slidesErrors)
    indexToRemove = slidesErrorsCopy.findIndex((item) => item.lang === lang)
    slidesErrorsCopy.splice(indexToRemove, 1)
    setSlidesErrors(slidesErrorsCopy)

    setCurrentEpisode(currentEpisodeCopy)
    setCurrentEpisodeStaged(currentEpisodeStagedCopy)
    setPreChangesCurrentEpisode(preChangesCurrentEpisodeCopy)
  }

  // add slide
  const addSlide = (lang: string, newSlide: EpisodeSlide) => {
    let currentEpisodeCopy: Episode = deepCopy(currentEpisode)

    let indexToAdd = currentEpisodeCopy.translations.findIndex(
      (item) => item.lang === lang
    )
    currentEpisodeCopy.translations[indexToAdd].slides.push(newSlide)

    let slidesErrorsCopy: {
      lang: string
      slides: { slideId: string; error: boolean }[]
    }[] = deepCopy(slidesErrors)
    indexToAdd = slidesErrorsCopy.findIndex((item) => item.lang === lang)
    slidesErrorsCopy[indexToAdd].slides.push({
      slideId: newSlide.id,
      error: false,
    })
    setSlidesErrors(slidesErrorsCopy)

    setCurrentEpisode(currentEpisodeCopy)
  }

  // add quiz
  const addQuiz = (lang: string, newSlide: EpisodeSlideQuiz) => {
    let currentEpisodeCopy: Episode = deepCopy(currentEpisode)

    let indexToAdd = currentEpisodeCopy.translations.findIndex(
      (item) => item.lang === lang
    )
    currentEpisodeCopy.translations[indexToAdd].quiz.push(newSlide)

    let slidesErrorsCopy: {
      lang: string
      slides: { slideId: string; error: boolean }[]
    }[] = deepCopy(slidesErrors)
    indexToAdd = slidesErrorsCopy.findIndex((item) => item.lang === lang)
    slidesErrorsCopy[indexToAdd].slides.push({
      slideId: newSlide.id,
      error: false,
    })
    setSlidesErrors(slidesErrorsCopy)

    setCurrentEpisode(currentEpisodeCopy)
  }

  // remove slide
  const removeSlide = (lang: string, slideId: string) => {
    let currentEpisodeCopy: Episode = deepCopy(currentEpisode)

    let langIndex = currentEpisodeCopy.translations.findIndex(
      (item) => item.lang === lang
    )
    let indexToRemove = currentEpisodeCopy.translations[
      langIndex
    ].slides.findIndex((item) => item.id === slideId)

    currentEpisodeCopy.translations[langIndex].slides.splice(indexToRemove, 1)

    let slidesErrorsCopy: {
      lang: string
      slides: { slideId: string; error: boolean }[]
    }[] = deepCopy(slidesErrors)
    langIndex = slidesErrorsCopy.findIndex((item) => item.lang === lang)
    indexToRemove = slidesErrorsCopy[langIndex].slides.findIndex(
      (item) => item.slideId === slideId
    )
    slidesErrorsCopy[langIndex].slides.splice(indexToRemove, 1)
    setSlidesErrors(slidesErrorsCopy)

    setCurrentEpisode(currentEpisodeCopy)
  }

  // remove quiz
  const removeQuiz = (lang: string, slideId: string) => {
    let currentEpisodeCopy: Episode = deepCopy(currentEpisode)

    let langIndex = currentEpisodeCopy.translations.findIndex(
      (item) => item.lang === lang
    )
    let indexToRemove = currentEpisodeCopy.translations[
      langIndex
    ].quiz.findIndex((item) => item.id === slideId)

    currentEpisodeCopy.translations[langIndex].quiz.splice(indexToRemove, 1)

    let slidesErrorsCopy: {
      lang: string
      slides: { slideId: string; error: boolean }[]
    }[] = deepCopy(slidesErrors)
    langIndex = slidesErrorsCopy.findIndex((item) => item.lang === lang)
    indexToRemove = slidesErrorsCopy[langIndex].slides.findIndex(
      (item) => item.slideId === slideId
    )
    slidesErrorsCopy[langIndex].slides.splice(indexToRemove, 1)
    setSlidesErrors(slidesErrorsCopy)

    setCurrentEpisode(currentEpisodeCopy)
  }

  // publish episode
  const publishEpisodeParent = useCallback(
    async (episodeId: string) => {
      try {
        logger(Status.Api, "MUTATION publishEpisode", publishEpisode)
        const { data } = await publishEpisodeMutation({
          variables: { input: { id: episodeId } },
        })
        logger(
          Status.Info,
          `episode ${episodeId} published`,
          data.publishEpisode.published
        )

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `publishEpisode`, e.message)
        }

        return false
      }
    },
    [setError, setErrorMessage]
  )

  // archive episode
  const archiveEpisodeParent = async (episodeId: string) => {
    try {
      logger(Status.Api, "MUTATION archiveEpisode", archiveEpisode)
      const { data } = await archiveEpisodeMutation({
        variables: { input: { id: episodeId } },
      })
      logger(
        Status.Info,
        `episode ${episodeId} archived`,
        data.archiveEpisode.archived.toString()
      )

      let episodesListCopy: Episode[] = deepCopy(episodesList)
      if (episodesListCopy.some((item) => item.id === episodeId)) {
        episodesListCopy.find((item) => item.id === episodeId).stage = null
      }
      setEpisodesList(episodesListCopy)

      setUpdatingList(true)

      setTimeout(() => {
        if (searchStatus === "active") {
          getEpisodesList(false)
        } else {
          getEpisodesList(false, true)
        }
      }, 4000)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `archiveEpisode`, e.message)
      }

      return false
    }
  }

  // unarchive episode
  const unArchiveEpisodeParent = async (episodeId: string) => {
    try {
      logger(Status.Api, "MUTATION unArchiveEpisode", unArchiveEpisode)
      const { data } = await unArchiveEpisodeMutation({
        variables: { input: { id: episodeId } },
      })
      logger(
        Status.Info,
        `episode ${episodeId} archived`,
        data.unArchiveEpisode.archived.toString()
      )

      let episodesListCopy: Episode[] = deepCopy(episodesList)
      episodesListCopy.filter((item) => item.id === episodeId)[0].stage = null
      setEpisodesList(episodesListCopy)

      setUpdatingList(true)

      setTimeout(() => {
        if (searchStatus === "active") {
          getEpisodesList(false)
        } else {
          getEpisodesList(false, true)
        }
      }, 4000)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `unArchiveEpisode`, e.message)
      }

      return false
    }
  }

  // publish episode translation
  const publishTranslation = useCallback(
    async (episodeId: string, lang: string) => {
      try {
        logger(
          Status.Api,
          "MUTATION publishEpisodeTranslation",
          publishEpisodeTranslation
        )
        const { data } = await publishEpisodeTranslationMutation({
          variables: { input: { parentId: episodeId, lang: lang } },
        })
        logger(
          Status.Info,
          `${lang} translation published`,
          data.publishEpisodeTranslation.published
        )

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `publishEpisodeTranslation`, e.message)
        }
      }
    },
    [setError, setErrorMessage]
  )

  // update episode translation
  const updateTranslation = useCallback(
    async (
      id: string,
      episodeId: string,
      lang: string,
      title: string,
      slides: EpisodeSlide[],
      quiz: EpisodeSlideQuiz[],
      deleteIds?: boolean
    ) => {
      let slidesCopy = deepCopy(slides)
      slidesCopy.forEach((slide: any, index: number) => {
        delete slide.__typename
        if (slide.id.includes("temp-id") || deleteIds) {
          delete slide.id
        }
        if (slide.image) {
          delete slide.image.__typename
        }
        if (slide.box) {
          delete slide.box.__typename
        }
        if (slide.tagBox) {
          delete slide.tagBox.__typename
        }

        if (slide.title) {
          slide.title = slide.title.trim()
        }
        if (slide.description) {
          slide.description = slide.description.trim()
        }
        if (slide.text) {
          slide.text = slide.text.trim()
        }
        if (slide.author) {
          slide.author = slide.author.trim()
        }
        if (slide.authorTitle) {
          slide.authorTitle = slide.authorTitle.trim()
        }
        if (slide.caption) {
          slide.caption = slide.caption.trim()
        }
        if (slide.captionTitle) {
          slide.captionTitle = slide.captionTitle.trim()
        }
        if (slide.embedUrl) {
          slide.embedUrl = slide.embedUrl.trim()
        }

        let slideType = lowercaseFirstCharacter(slide.slideType)
        slidesCopy[index] = {
          [slideType]: {
            ...slide,
          },
        }

        delete slidesCopy[index][slideType].slideType
      })

      let quizCopy = deepCopy(quiz)
      quizCopy.forEach((slide: any, index: number) => {
        delete slide.__typename
        if (slide.box && slide.box.__typename) {
          delete slide.box.__typename
        }
        if (slide.opt1 && slide.opt1.__typename) {
          delete slide.opt1.__typename
        }
        if (slide.opt2 && slide.opt2.__typename) {
          delete slide.opt2.__typename
        }
        if (slide.opt3 && slide.opt3.__typename) {
          delete slide.opt3.__typename
        }
        if (slide.opt4 && slide.opt4.__typename) {
          delete slide.opt4.__typename
        }
        if (slide.id.includes("temp-id")) {
          delete slide.id
        }

        quizCopy[index] = {
          quizSlide: {
            ...slide,
          },
        }

        delete quizCopy[index].quizSlide.slideType
      })

      try {
        logger(
          Status.Api,
          "MUTATION upsertEpisodeTranslation",
          upsertEpisodeTranslation
        )
        const { data } = await upsertEpisodeTranslationMutation({
          variables: {
            input: {
              id: id,
              parentId: episodeId,
              lang: deleteIds
                ? lang
                : currentEpisode.translations.filter(
                    (item) => item.lang === lang
                  )[0].stage === "DRAFT"
                ? lang + "#DRAFT"
                : lang,
              title: title.trim(),
              slides: slidesCopy,
              quiz: quizCopy,
            },
          },
        })
        logger(
          Status.Info,
          `${lang} translation upserted`,
          data.upsertEpisodeTranslation.id
        )

        if (
          lang === currentEpisode.lang &&
          slides.filter((item) => item.slideType === "TitleSlide").length &&
          slides.filter((item) => item.slideType === "TitleSlide")[0].title !==
            currentEpisode.title
        ) {
          await updateEpisodeParent(
            {
              id: episodeId,
              title: slides.filter((item) => item.slideType === "TitleSlide")[0]
                .title,
              topics: currentEpisode.topics.map((item) => {
                return {
                  primary: item.primary,
                  id: item.topic.id,
                }
              }),
              sdgs: currentEpisode.sdgs.map((item) => {
                return {
                  primary: item.primary,
                  id: item.sdg.id,
                }
              }),
              sdgTargets: currentEpisode.sdgTargets.map((item) => {
                return {
                  primary: item.primary,
                  id: item.sdgTarget.id,
                }
              }),
            },
            false
          )
        }

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `upsertEpisodeTranslation`, e.message)
        }
      }
    },
    [setError, setErrorMessage, currentEpisode]
  )

  // duplicate slide
  const duplicateSlide = (language: string, slideToAdd: EpisodeSlide) => {
    let currentEpisodeCopy: Episode = deepCopy(currentEpisode)

    const slideToAddCopy: EpisodeSlide = deepCopy(slideToAdd)

    let indexToAdd = currentEpisodeCopy.translations
      .filter((item) => item.lang === language)[0]
      .slides.findIndex((item) => item.id === slideToAddCopy.id)
    slideToAddCopy.id = "temp-id-" + generateRandomString(5)
    currentEpisodeCopy.translations
      .filter((item) => item.lang === language)[0]
      .slides.splice(indexToAdd, 0, slideToAddCopy)

    let slidesErrorsCopy: {
      lang: string
      slides: { slideId: string; error: boolean }[]
    }[] = deepCopy(slidesErrors)
    indexToAdd = slidesErrorsCopy.findIndex((item) => item.lang === language)
    slidesErrorsCopy[indexToAdd].slides.push({
      slideId: slideToAddCopy.id,
      error: false,
    })
    setSlidesErrors(slidesErrorsCopy)

    setCurrentEpisode(currentEpisodeCopy)
  }

  // duplicate slide into another translation
  const duplicateSlideAnotherTranslation = (
    translation: string,
    slideToAdd: EpisodeSlide
  ) => {
    let currentEpisodeCopy: Episode = deepCopy(currentEpisode)

    const slideToAddCopy: EpisodeSlide = deepCopy(slideToAdd)

    slideToAddCopy.id = "temp-id-" + generateRandomString(5)
    currentEpisodeCopy.translations
      .filter((item) => item.lang === translation)[0]
      .slides.push(slideToAddCopy)

    let slidesErrorsCopy: {
      lang: string
      slides: { slideId: string; error: boolean }[]
    }[] = deepCopy(slidesErrors)
    const indexToAdd = slidesErrorsCopy.findIndex(
      (item) => item.lang === translation
    )
    slidesErrorsCopy[indexToAdd].slides.push({
      slideId: slideToAddCopy.id,
      error: false,
    })
    setSlidesErrors(slidesErrorsCopy)

    setCurrentEpisode(currentEpisodeCopy)
  }

  // copy to another translation
  const copyToAnotherTranslation = (
    currentLang: string,
    langToCopyTo: string
  ) => {
    let currentEpisodeCopy: Episode = deepCopy(currentEpisode)

    let itemToCopy: EpisodeTranslation = deepCopy(
      currentEpisode.translations.filter((item) => item.lang === currentLang)[0]
    )
    if (
      currentEpisode.translations.filter(
        (item) => item.lang === langToCopyTo
      )[0].id
    ) {
      itemToCopy.id = currentEpisode.translations.filter(
        (item) => item.lang === langToCopyTo
      )[0].id
    } else {
      delete itemToCopy.id
    }
    itemToCopy.stage = currentEpisode.translations.filter(
      (item) => item.lang === langToCopyTo
    )[0].stage
    itemToCopy.lang = langToCopyTo
    for (let i = 0; i < itemToCopy.slides.length; i++) {
      itemToCopy.slides[i].id = "temp-id-" + generateRandomString(5)
    }
    for (let i = 0; i < itemToCopy.quiz.length; i++) {
      itemToCopy.quiz[i].id = "temp-id-" + generateRandomString(5)
    }

    let indexToReplace = currentEpisodeCopy.translations.findIndex(
      (item) => item.lang === langToCopyTo
    )
    currentEpisodeCopy.translations.splice(indexToReplace, 1, itemToCopy)

    let slidesErrorsCopy: {
      lang: string
      slides: {
        slideId: string
        error: boolean
      }[]
    }[] = deepCopy(slidesErrors)
    let indexToRemove = slidesErrorsCopy.findIndex(
      (item) => item.lang === langToCopyTo
    )
    slidesErrorsCopy.splice(indexToRemove, 1)
    slidesErrorsCopy.push({
      lang: langToCopyTo,
      slides: [],
    })
    for (let i = 0; i < itemToCopy.slides.length; i++) {
      slidesErrorsCopy[slidesErrorsCopy.length - 1].slides.push({
        slideId: itemToCopy.slides[i].id,
        error: false,
      })
    }
    for (let i = 0; i < itemToCopy.quiz.length; i++) {
      slidesErrorsCopy[slidesErrorsCopy.length - 1].slides.push({
        slideId: itemToCopy.quiz[i].id,
        error: false,
      })
    }

    setCurrentEpisode(currentEpisodeCopy)
    setSlidesErrors(slidesErrorsCopy)
  }

  // duplicate episode
  const duplicateEpisode = async () => {
    setLoading(true)

    try {
      // create episode parent
      let input: {
        lang: string
        title: string
        text: string
        image: string
        topics: { id: string; primary: boolean }[]
        sdgs: { id: string; primary: boolean }[]
        sdgTargets: { id: string; primary: boolean }[]
        category: { id: string }
        defaultTranslation: { title: string; slides: []; quiz: [] }
        doNotRecommend: boolean
        type: string
        esg?: string
      } = {
        lang: currentEpisodeLive.lang,
        title: currentEpisodeLive.title,
        text: currentEpisodeLive.text,
        image: currentEpisodeLive.image,
        topics: currentEpisodeLive.topics.map((item) => {
          return {
            id: item.topic.id,
            primary: item.primary,
          }
        }),
        sdgs: currentEpisodeLive.sdgs.map((item) => {
          return {
            id: item.sdg.id,
            primary: item.primary,
          }
        }),
        sdgTargets: currentEpisodeLive.sdgTargets.map((item) => {
          return {
            id: item.sdgTarget.id,
            primary: item.primary,
          }
        }),
        category: { id: currentEpisodeLive.category.id },
        defaultTranslation: {
          title: currentEpisodeLive.translations.find(
            (item) => item.lang === currentEpisodeLive.lang
          ).title,
          slides: [],
          quiz: [],
        },
        doNotRecommend: currentEpisodeLive.doNotRecommend,
        type: "Custom",
        esg: currentEpisodeLive.esg,
      }

      logger(Status.Api, "MUTATION createEpisode", createEpisode)
      const result = await createEpisodeMutation({
        variables: { input: input },
      })
      logger(Status.Info, `episode created`, result.data.createEpisode)

      // create episode translations
      let calls = []
      currentEpisodeLive.translations.forEach((translation) => {
        let slidesCopy = deepCopy(translation.slides)
        slidesCopy.forEach((slide: any, index: number) => {
          delete slide.__typename
          delete slide.id
          if (slide.image) {
            delete slide.image.__typename
          }
          if (slide.box) {
            delete slide.box.__typename
          }
          if (slide.tagBox) {
            delete slide.tagBox.__typename
          }

          if (slide.title) {
            slide.title = slide.title.trim()
          }
          if (slide.description) {
            slide.description = slide.description.trim()
          }
          if (slide.text) {
            slide.text = slide.text.trim()
          }
          if (slide.author) {
            slide.author = slide.author.trim()
          }
          if (slide.authorTitle) {
            slide.authorTitle = slide.authorTitle.trim()
          }
          if (slide.caption) {
            slide.caption = slide.caption.trim()
          }
          if (slide.captionTitle) {
            slide.captionTitle = slide.captionTitle.trim()
          }
          if (slide.embedUrl) {
            slide.embedUrl = slide.embedUrl.trim()
          }

          let slideType = lowercaseFirstCharacter(slide.slideType)
          slidesCopy[index] = {
            [slideType]: {
              ...slide,
            },
          }

          delete slidesCopy[index][slideType].slideType
        })

        let quizCopy = deepCopy(translation.quiz)
        quizCopy.forEach((slide: any, index: number) => {
          delete slide.__typename
          if (slide.box && slide.box.__typename) {
            delete slide.box.__typename
          }
          if (slide.opt1 && slide.opt1.__typename) {
            delete slide.opt1.__typename
          }
          if (slide.opt2 && slide.opt2.__typename) {
            delete slide.opt2.__typename
          }
          if (slide.opt3 && slide.opt3.__typename) {
            delete slide.opt3.__typename
          }
          if (slide.opt4 && slide.opt4.__typename) {
            delete slide.opt4.__typename
          }
          if (slide.id.includes("temp-id")) {
            delete slide.id
          }

          quizCopy[index] = {
            quizSlide: {
              ...slide,
            },
          }

          delete quizCopy[index].quizSlide.slideType
        })

        logger(
          Status.Api,
          "MUTATION upsertEpisodeTranslation",
          upsertEpisodeTranslation
        )
        calls.push(
          upsertEpisodeTranslationMutation({
            variables: {
              input: {
                parentId: result.data.createEpisode.id,
                lang: translation.lang,
                title: translation.title.trim(),
                slides: slidesCopy,
                quiz: quizCopy,
              },
            },
          })
        )
      })

      await Promise.all(calls)

      setLoading(false)
      return result.data.createEpisode
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `createEpisode`, e.message)
      }

      setLoading(false)
      return false
    }
  }

  // sync episode on notion
  const syncEpisode = async (episodeId: string) => {
    try {
      logger(Status.Api, "MUTATION syncNotionEpisode", syncNotionEpisode)
      const { data } = await syncNotionEpisodeMutation({
        variables: { input: { id: episodeId } },
      })
      logger(
        Status.Info,
        `episode ${episodeId} synced`,
        data.syncNotionEpisode.synced
      )

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `syncNotionEpisode`, e.message)
      }

      return false
    }
  }

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter((item) => item.name === "Episodes")[0].updateFunction =
      getEpisodesList
  }, [])

  return (
    <EpisodesContext.Provider
      value={{
        loading,
        setLoading,
        episodesList,
        setEpisodesList,
        getEpisodesList,
        searchValue,
        setSearchValue,
        hasSearched,
        getEpisodeDetails,
        currentEpisode,
        setCurrentEpisode,
        preChangesCurrentEpisode,
        doneChanges,
        cancelChanges,
        createNewEpisode,
        updateEpisodeParent,
        slideConstraintsList,
        getSlideConstraints,
        slidesErrors,
        setSlidesErrors,
        doneChangesTranslations,
        cancelTranslationChanges,
        changeParentStage,
        changeTranslationStage,
        currentEpisodeStaged,
        currentEpisodeLive,
        addTranslationToCurrentEpisode,
        removeTranslationFromCurrentEpisode,
        addSlide,
        addQuiz,
        removeSlide,
        removeQuiz,
        publishEpisodeParent,
        archiveEpisodeParent,
        unArchiveEpisodeParent,
        publishTranslation,
        updateTranslation,
        editMode,
        setEditMode,
        episodesListNextToken,
        loadMoreEpisodes,
        searchEpisodes,
        searchTopic,
        setSearchTopic,
        searchLanguage,
        setSearchLanguage,
        searchSdg,
        setSearchSdg,
        searchSdgTarget,
        setSearchSdgTarget,
        searchCategory,
        setSearchCategory,
        searchEsg,
        setSearchEsg,
        searchDoNotRecommend,
        setSearchDoNotRecommend,
        searchType,
        setSearchType,
        searchStatus,
        setSearchStatus,
        setHasSearched,
        updatingList,
        setUpdatingList,
        duplicateSlide,
        copyToAnotherTranslation,
        getEpisodeJourneys,
        currentEpisodeJourneysList,
        duplicateSlideAnotherTranslation,
        duplicateEpisode,
        syncEpisode,
      }}
    >
      {children}
    </EpisodesContext.Provider>
  )
}

export { EpisodesController, EpisodesContext }
