import { CSSProperties, ReactNode } from "react"

const EditContainer = ({
  children,
  style,
}: {
  children: ReactNode
  style?: CSSProperties
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
        paddingBottom: 0,
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export default EditContainer
