import { useContext, useState } from "react"
import { LanguagesContext } from "../../controllers/languages"
import { Chip, IconButton, Stack } from "@mui/material"
import { ChevronRightRounded } from "@mui/icons-material"

const TranslationsStackMultiple = ({
  publishedLangs,
  draftedLangs,
}: {
  publishedLangs: {
    lang: string
    title: string
  }[]
  draftedLangs: {
    lang: string
    title: string
  }[]
}) => {
  const { languages } = useContext(LanguagesContext)

  // expanded to show all translations or not
  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <div
      style={{
        width: 124,
        height: 42.5,
        position: "relative",
        display: "flex",
        alignItems: "center",
        zIndex: 1,
      }}
    >
      <Stack
        style={{
          width: expanded
            ? languages.length * 37 + (languages.length - 1) * 8 + 42
            : 94,
          height: 36,
          backgroundColor: expanded ? "white" : "rgba(0, 0, 0, 0)",
          position: "absolute",
          left: expanded ? -60 : 0,
          transition: "180ms",
          borderRadius: 16,
          overflow: "hidden",
          boxShadow: expanded ? "0 6px 14px 0 #c4c4c4" : "none",
          paddingLeft: 6,
          paddingRight: 36,
        }}
        direction="row"
        spacing={1}
        alignItems="center"
        onClick={(e) => {
          e.stopPropagation()
          setExpanded((current) => !current)
        }}
      >
        {languages.map((lang: string) => (
          <div style={{ position: "relative" }} key={lang}>
            {publishedLangs.find((item) => item.lang === lang) &&
            draftedLangs.find((item) => item.lang === lang) ? (
              <Chip
                color="warning"
                size="small"
                style={{
                  width: 37,
                  minWidth: 37,
                  position: "absolute",
                  right: -5.5,
                  top: 0.5,
                }}
              />
            ) : null}
            <Chip
              color={
                publishedLangs.find((item) => item.lang === lang)
                  ? "success"
                  : draftedLangs.find((item) => item.lang === lang)
                  ? "warning"
                  : "default"
              }
              label={
                lang.includes("_")
                  ? lang.slice(lang.indexOf("_") + 1).toUpperCase()
                  : lang.toUpperCase()
              }
              size="small"
              style={{ width: 37, minWidth: 37, position: "relative" }}
            />
          </div>
        ))}
        {/* {languages
          .filter(
            (lang) =>
              publishedLangs.find((item) => item.lang === lang) &&
              draftedLangs.find((item) => item.lang === lang)
          )
          .map((lang: string) => (
            <Chip
              key={lang}
              color="warning"
              label={
                lang.includes("_")
                  ? lang.slice(lang.indexOf("_") + 1).toUpperCase()
                  : lang.toUpperCase()
              }
              size="small"
              style={{ width: 37, minWidth: 37 }}
            />
          ))} */}
      </Stack>
      <IconButton
        style={{
          height: 24,
          width: 24,
          position: "absolute",
          right: expanded
            ? -(languages.length * 37 + (languages.length - 1) * 8) + 148
            : 6,
          transition: "180ms",
        }}
        onClick={(e) => {
          e.stopPropagation()
          setExpanded((current) => !current)
        }}
      >
        <ChevronRightRounded
          style={{
            transform: expanded ? "rotate(180deg)" : "none",
            transition: "180ms",
          }}
        />
      </IconButton>
    </div>
  )
}

export default TranslationsStackMultiple
