import { useNavigate, useParams } from "react-router-dom"
import EditContainer from "../../components/global/editContainer"
import EditHeading from "../../components/global/editHeading"
import { useContext, useEffect, useMemo, useState } from "react"
import { MainContext } from "../../controllers/main"
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  InputAdornment,
  LinearProgress,
  Snackbar,
  SpeedDial,
  SpeedDialAction,
  Stack,
  TextField,
  Typography,
  Zoom,
} from "@mui/material"
import { TeamsContext } from "../../controllers/teams"
import PageSpinner from "../../components/global/pageSpinner"
import ListTable from "../../components/global/listTable"
import TeamUser from "../../models/teamUser"
import MembersListTopBar from "../../components/teams/membersListTopBar"
import MembersListRow from "./membersListRow"
import LoadingBackdrop from "../../components/global/loadingBackdrop"
import { DialogTransition } from "../../services/utilities/utility"
import "../../styles/membersList.scss"
import User from "../../models/user"
import {
  CancelRounded,
  DeleteRounded,
  GroupAddRounded,
  GroupRemoveRounded,
  SettingsRounded,
} from "@mui/icons-material"

const MembersList = () => {
  const { animation, setAnimation, showPadding } = useContext(MainContext)
  const {
    setLoading: setControllerLoading,
    currentTeam,
    preChangesCurrentTeam,
    getCurrentTeam,
    getTeamUsers,
    usersList,
    usersListNextToken,
    updatingMembersList,
    upsertUsers,
    loadMoreUsers,
    searchGroup,
    setSearchGroup,
    setSearchIsAdmin,
    loadAllUsers,
    removeUsers,
  } = useContext(TeamsContext)
  const { teamId } = useParams()
  const navigate = useNavigate()

  // loading
  const [loading, setLoading] = useState<boolean>(true)

  // page error
  const [pageError, setPageError] = useState<boolean>(false)

  // fetch current team
  const fetchAll = async () => {
    if (!currentTeam || (currentTeam && currentTeam.id !== teamId)) {
      const noErrors = await Promise.all([
        getCurrentTeam(teamId),
        getTeamUsers(teamId, null, null, null),
      ])
      if (noErrors[0] && noErrors[1]) {
        setLoading(false)
        setControllerLoading(false)
      } else {
        setPageError(true)
      }
    } else {
      const noErrors = await getTeamUsers(teamId, null, null, null)
      if (noErrors) {
        setLoading(false)
        setControllerLoading(false)
      } else {
        setPageError(true)
      }
    }
  }

  useEffect(() => {
    setSearchGroup(null)
    setSearchIsAdmin(null)
    fetchAll()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // load more items loading
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // upsert user loading
  const [upsertingUser, setUpsertingUser] = useState<boolean>(false)

  // select group dialog
  const [groupDialogOpen, setGroupDialogOpen] = useState<boolean>(false)
  const [usersToAdd, setUsersToAdd] = useState<User[]>([])

  // add user
  const [addUserDialogOpen, setAddUserDialogOpen] = useState<boolean>(false)
  const [userEmail, setUserEmail] = useState<string>("")
  const [addingUser, setAddingUser] = useState<boolean>(false)

  // remove user dialog
  const [removeUserDialogOpen, setRemoveUserDialogOpen] =
    useState<boolean>(false)
  const [usersToRemove, setUsersToRemove] = useState<User[]>([])

  // add user feedback
  const [addFeedbackOpen, setAddFeedbackOpen] = useState<boolean>(false)

  // remove user feedback
  const [removeFeedbackOpen, setRemoveFeedbackOpen] = useState<boolean>(false)

  // search groups
  const [searchGroupsValue, setSearchGroupsValue] = useState<string>("")

  const groupsListMemo = useMemo(() => {
    if (!preChangesCurrentTeam) {
      return []
    }

    if (searchGroupsValue) {
      return preChangesCurrentTeam.groups.filter((item) =>
        item.name.toLowerCase().includes(searchGroupsValue.toLowerCase())
      )
    }

    return preChangesCurrentTeam.groups
  }, [preChangesCurrentTeam, searchGroupsValue])

  // selected members for bulk actions
  const [selectedMembers, setSelectedMembers] = useState<TeamUser[]>([])

  return loading ? (
    <PageSpinner
      showBackButton={pageError}
      backButtonOnClick={() => {
        setAnimation(false)
        const path = window.location.pathname
        navigate(path.slice(0, path.indexOf("/members")))
      }}
    />
  ) : (
    <EditContainer>
      <EditHeading
        backButtonOnClick={() => {
          setAnimation(false)
          setTimeout(() => {
            const path = window.location.pathname
            navigate(path.slice(0, path.indexOf("/members")))
          }, 300)
        }}
        title={currentTeam.name + " - Members"}
        showCsvButton
        csvButtonOnClick={async (withEmails) => {
          const reult = await loadAllUsers(teamId, withEmails)
          return reult
        }}
        csvFileName={
          searchGroup
            ? `${searchGroup.label} members list.csv`
            : `${preChangesCurrentTeam.name} members list.csv`
        }
        csvButtonType="dropdown"
        showAddButton
        addButtonLabel="Add User"
        addButtonOnClick={() => {
          setAddUserDialogOpen(true)
        }}
      />
      <Grow
        in={animation}
        timeout={300}
        style={{
          margin: showPadding === "yes" ? 16 : 0,
          marginTop: 0,
        }}
      >
        <Card style={{ position: "relative" }}>
          {updatingMembersList && (
            <LinearProgress
              style={{
                position: "absolute",
                top: 144,
                width: "100%",
                zIndex: 10,
              }}
            />
          )}
          <MembersListTopBar loading={loading} />
          <div
            style={{ position: "relative" }}
            id="members-list-main-container"
          >
            <ListTable
              height={
                showPadding === "yes"
                  ? "calc(100vh - 260px)"
                  : "calc(100vh - 227px)"
              }
              scrollToTopButtonDisabled={selectedMembers.length > 0}
              headingItems={
                usersList.find((item) => item.group)
                  ? ["", "Image", "Name & SUB", "Group", "", ""]
                  : ["", "Image", "Name & SUB", "", ""]
              }
              nextToken={usersListNextToken}
              loadingMoreItems={loadMoreLoading}
              setLoadingMoreItems={setLoadMoreLoading}
              loadMore={async () => {
                const result = await loadMoreUsers(teamId)
                return result
              }}
              tableBody={usersList.map((item: TeamUser) => (
                <MembersListRow
                  key={item.user.sub}
                  item={item}
                  setUpsertingUser={setUpsertingUser}
                  setGroupDialogOpen={setGroupDialogOpen}
                  setUsersToAdd={setUsersToAdd}
                  setRemoveUserDialogOpen={setRemoveUserDialogOpen}
                  setUsersToRemove={setUsersToRemove}
                  selectedMembers={selectedMembers}
                  setSelectedMembers={setSelectedMembers}
                />
              ))}
            />
            <Zoom
              in={selectedMembers.length > 0}
              unmountOnExit
              style={{
                transitionDelay: selectedMembers.length > 0 ? "350ms" : "0ms",
              }}
            >
              <div
                style={{
                  width: 50,
                  height: 50,
                  position: "absolute",
                  bottom: 9,
                  right: 15,
                }}
              >
                <SpeedDial
                  id="fab-export"
                  ariaLabel="Users actions"
                  icon={<SettingsRounded />}
                  style={{ width: "100%", height: "100%" }}
                >
                  <SpeedDialAction
                    icon={<DeleteRounded color="error" />}
                    tooltipTitle="Remove"
                    onClick={() => {
                      setUsersToRemove(selectedMembers.map((item) => item.user))
                      setRemoveUserDialogOpen(true)
                    }}
                  />
                  <SpeedDialAction
                    icon={<GroupRemoveRounded />}
                    tooltipTitle="Remove from groups"
                    onClick={async () => {
                      setUpsertingUser(true)
                      await upsertUsers(
                        teamId,
                        selectedMembers.map((item) => {
                          return {
                            sub: item.user.sub,
                            email: null,
                            isAdmin: null,
                            groupId: "none",
                          }
                        })
                      )
                      setUpsertingUser(false)
                      setSelectedMembers([])
                    }}
                  />
                  <SpeedDialAction
                    icon={<GroupAddRounded />}
                    tooltipTitle="Add to group"
                    onClick={() => {
                      setUsersToAdd(selectedMembers.map((item) => item.user))
                      setGroupDialogOpen(true)
                    }}
                  />
                </SpeedDial>
                <div
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    backgroundColor: "#465094",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    boxSizing: "border-box",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1051,
                  }}
                >
                  <Typography
                    style={{
                      color: "white",
                      fontWeight: 700,
                      fontSize: 11,
                    }}
                  >
                    {selectedMembers.length === 0 ? 1 : selectedMembers.length}
                  </Typography>
                </div>
              </div>
            </Zoom>
            <Checkbox
              indeterminate
              style={{
                position: "absolute",
                top: -2,
                left: 0,
                zIndex: 5,
                opacity: selectedMembers.length ? 1 : 0,
                transition: "150ms",
              }}
              onClick={() => {
                setSelectedMembers([])
              }}
            />
          </div>
        </Card>
      </Grow>
      <LoadingBackdrop open={upsertingUser || addingUser} />
      {/* add to group or change group dialog */}
      <Dialog open={groupDialogOpen} TransitionComponent={DialogTransition}>
        <DialogTitle>Add to group</DialogTitle>
        {preChangesCurrentTeam.groups.length ? (
          <Stack style={{ paddingInline: 24 }} spacing={2}>
            <Typography>Select the group to add the user to:</Typography>
            <Card style={{ maxHeight: 300, overflow: "scroll" }}>
              <div
                style={{
                  padding: "12px 24px",
                  borderBottom: "1px solid #E5E8EB",
                }}
              >
                <TextField
                  fullWidth
                  size="small"
                  label="Search Groups"
                  value={searchGroupsValue}
                  onChange={(e) => {
                    setSearchGroupsValue(e.target.value)
                  }}
                  InputProps={{
                    endAdornment: searchGroupsValue.length > 0 && (
                      <InputAdornment position="start">
                        <div
                          style={{
                            marginTop: 6,
                            marginRight: -6,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSearchGroupsValue("")
                          }}
                        >
                          <CancelRounded style={{ width: 18 }} />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
                {!groupsListMemo.length ? (
                  <Typography style={{ marginTop: 16, minWidth: 352 }}>
                    No results for "{searchGroupsValue}"
                  </Typography>
                ) : null}
              </div>
              {groupsListMemo.map((item) => (
                <Stack
                  key={item.groupId}
                  style={{
                    padding: 24,
                    minWidth: 400,
                    borderBottom: "1px solid #E5E8EB",
                  }}
                  className="group-clickable-item"
                  onClick={async () => {
                    setGroupDialogOpen(false)
                    setSearchGroupsValue("")
                    setUpsertingUser(true)
                    await upsertUsers(
                      teamId,
                      usersToAdd.map((user) => {
                        return {
                          sub: user.sub,
                          email: null,
                          isAdmin: null,
                          groupId: item.groupId,
                        }
                      })
                    )
                    setUpsertingUser(false)
                    setUsersToAdd([])
                    setSelectedMembers([])
                  }}
                >
                  <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
                    {item.name}
                  </Typography>
                  <Typography variant="caption" style={{ opacity: 0.6 }}>
                    {item.groupId}
                  </Typography>
                </Stack>
              ))}
            </Card>
          </Stack>
        ) : (
          <DialogContent>
            <Typography>There are no groups in current Team.</Typography>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setUsersToAdd([])
              setSelectedMembers([])
              setGroupDialogOpen(false)
              setSearchGroupsValue("")
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* add user dialog */}
      <Dialog open={addUserDialogOpen} TransitionComponent={DialogTransition}>
        <DialogTitle>Add user</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography>
              Enter the email of the user you want to add to this Team:
            </Typography>
            <TextField
              fullWidth
              size="small"
              label="Email"
              value={userEmail}
              onChange={(e) => {
                setUserEmail(e.target.value)
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={addingUser}
            onClick={() => {
              setAddUserDialogOpen(false)
              setUserEmail("")
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={addingUser || !userEmail.includes("@")}
            onClick={async () => {
              setAddingUser(true)
              const noErrors = await upsertUsers(teamId, [
                {
                  sub: null,
                  email: userEmail,
                  isAdmin: null,
                  groupId: null,
                },
              ])
              setAddingUser(false)

              if (noErrors) {
                setAddUserDialogOpen(false)
                setAddFeedbackOpen(true)
                setUserEmail("")
              }
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {/* remove user dialog */}
      <Dialog
        open={removeUserDialogOpen}
        TransitionComponent={DialogTransition}
      >
        <DialogTitle>Remove users</DialogTitle>
        <DialogContent>
          {usersToRemove ? (
            <div>
              Are you sure you want to remove these users from this Team?
              <br />
              <br />
              <ul style={{ paddingLeft: 15 }}>
                {usersToRemove.map((user) => (
                  <li key={user.sub}>
                    <Stack spacing={-0.5}>
                      <Typography fontWeight={600}>
                        {user.first_name} {user.last_name}
                      </Typography>
                      <Typography variant="caption" fontWeight={300}>
                        {user.sub}
                      </Typography>
                    </Stack>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={upsertingUser}
            onClick={() => {
              setRemoveUserDialogOpen(false)
              setUsersToRemove([])
              setSelectedMembers([])
            }}
          >
            Cancel
          </Button>
          <Button
            color="error"
            disabled={upsertingUser}
            onClick={async () => {
              setUpsertingUser(true)
              const noErrors = await removeUsers(
                teamId,
                usersToRemove.map((user) => user.sub)
              )
              setUpsertingUser(false)

              if (noErrors) {
                setRemoveUserDialogOpen(false)
                setRemoveFeedbackOpen(true)
                setUsersToRemove([])
                setSelectedMembers([])
              }
            }}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      {/* user added feedback */}
      <Snackbar
        open={addFeedbackOpen}
        autoHideDuration={2000}
        onClose={() => {
          setAddFeedbackOpen(false)
        }}
      >
        <Alert severity="success">User added!</Alert>
      </Snackbar>
      {/* user removed feedback */}
      <Snackbar
        open={removeFeedbackOpen}
        autoHideDuration={2000}
        onClose={() => {
          setRemoveFeedbackOpen(false)
        }}
      >
        <Alert severity="success">User removed!</Alert>
      </Snackbar>
    </EditContainer>
  )
}

export default MembersList
