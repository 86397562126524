import { Stack, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { DraggableProvided } from "react-beautiful-dnd"
import EmbedSlice from "../../../models/bodySlices/embedSlice"
import ImageSlice from "../../../models/bodySlices/imageSlice"
import QuoteSlice from "../../../models/bodySlices/quoteSlice"
import TextSlice from "../../../models/bodySlices/textSlice"
import SliceTitle from "../common/sliceTitle"
import SliceWrapper from "../common/sliceWrapper"

const QuoteSliceComponent = ({
  item,
  body,
  setBody,
  editMode,
  sliceIndex,
  removeSlice,
  provided,
}: {
  item: QuoteSlice
  body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
  setBody: (body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]) => void
  editMode: boolean
  sliceIndex: number
  removeSlice: (sliceToRemoveIndex: number) => void
  provided: DraggableProvided
}) => {
  // states
  const [quoteText, setQuoteText] = useState<string>(item.quoteText)
  const [author, setAuthor] = useState<string | null>(item.author)
  const [authorTitle, setAuthorTitle] = useState<string | null>(
    item.authorTitle
  )

  // update states state when body changes
  useEffect(() => {
    if (item.quoteText !== quoteText) {
      setQuoteText(item.quoteText)
    }
    if (item.author !== author) {
      setAuthor(item.author)
    }
    if (item.authorTitle !== authorTitle) {
      setAuthorTitle(item.authorTitle)
    }
  }, [item])

  // update body when states change
  useEffect(() => {
    if (
      item.quoteText !== quoteText ||
      item.author !== author ||
      item.authorTitle !== authorTitle
    ) {
      item.quoteText = quoteText
      item.author = author
      item.authorTitle = authorTitle
      setBody(body)
    }
  }, [quoteText, author, authorTitle])

  // errors
  const [quoteTextError, setQuoteTextError] = useState<boolean>(false)

  useEffect(() => {
    if (!quoteText.length) {
      setQuoteTextError(true)
    } else {
      setQuoteTextError(false)
    }
  }, [quoteText])

  return (
    <SliceWrapper
      editMode={editMode}
      sliceIndex={sliceIndex}
      removeSlice={removeSlice}
      provided={provided}
    >
      <SliceTitle>Quote</SliceTitle>
      <TextField
        size="small"
        label="Quote"
        value={quoteText}
        onChange={(e) => {
          setQuoteText(e.target.value)
        }}
        disabled={!editMode}
        error={quoteTextError}
        helperText={quoteTextError ? "This field is required" : ""}
      />
      <Stack direction="row" spacing={1}>
        <TextField
          fullWidth
          size="small"
          label="Author"
          value={author ?? ""}
          onChange={(e) => {
            if (e.target.value.length) {
              setAuthor(e.target.value)
            } else {
              setAuthor(null)
            }
          }}
          disabled={!editMode}
        />
        <TextField
          fullWidth
          size="small"
          label="Author title"
          value={authorTitle ?? ""}
          onChange={(e) => {
            if (e.target.value.length) {
              setAuthorTitle(e.target.value)
            } else {
              setAuthorTitle(null)
            }
          }}
          disabled={!editMode}
        />
      </Stack>
    </SliceWrapper>
  )
}

export default QuoteSliceComponent
