import {
  Checkbox,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import TeamUser from "../../models/teamUser"
import TitleTableCell from "../../components/global/titleTableCell"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { TeamsContext } from "../../controllers/teams"
import {
  DeleteRounded,
  GroupAddRounded,
  GroupRemoveRounded,
  KeyboardDoubleArrowDownRounded,
  KeyboardDoubleArrowUpRounded,
  MoreVertRounded,
} from "@mui/icons-material"
import { useParams } from "react-router-dom"
import User from "../../models/user"

const MembersListRow = ({
  item,
  setUpsertingUser,
  setGroupDialogOpen,
  setUsersToAdd,
  setRemoveUserDialogOpen,
  setUsersToRemove,
  selectedMembers,
  setSelectedMembers,
}: {
  item: TeamUser
  setUpsertingUser: Dispatch<SetStateAction<boolean>>
  setGroupDialogOpen: Dispatch<SetStateAction<boolean>>
  setUsersToAdd: Dispatch<SetStateAction<User[]>>
  setRemoveUserDialogOpen: Dispatch<SetStateAction<boolean>>
  setUsersToRemove: Dispatch<SetStateAction<User[]>>
  selectedMembers: TeamUser[]
  setSelectedMembers: Dispatch<SetStateAction<TeamUser[]>>
}) => {
  const { usersList, upsertUsers } = useContext(TeamsContext)
  const { teamId } = useParams()

  // more actions menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <TableRow
      key={item.user.sub}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell
        style={{ padding: 0, transition: "100ms" }}
        className={selectedMembers.length ? null : "members-list-checkbox"}
      >
        <Checkbox
          id={"checkbox" + item.user.sub}
          checked={
            selectedMembers.filter(
              (item2) => item2.user.sub === item.user.sub
            )[0]
              ? true
              : false
          }
          onClick={(e) => {
            e.stopPropagation()

            if (
              selectedMembers.filter(
                (item2) => item2.user.sub === item.user.sub
              )[0]
            ) {
              const indexToRemove = selectedMembers.findIndex(
                (item2) => item2.user.sub === item.user.sub
              )
              selectedMembers.splice(indexToRemove, 1)
              // remove animation
              if (selectedMembers.length === 0) {
                const animationDivs =
                  document.getElementsByClassName("animation-div")
                for (let i = 0; i < animationDivs.length; i++) {
                  animationDivs[i].remove()
                }
              }
            } else {
              selectedMembers.push(item)
              // animation
              if (selectedMembers.length === 1) {
                const checkbox = document.getElementById(
                  "checkbox" + item.user.sub
                )
                const container = document.getElementById(
                  "members-list-main-container"
                )
                const divToAppend = document.createElement("div")
                divToAppend.className = "animation-div"
                divToAppend.style.opacity = "0"
                divToAppend.style.top =
                  checkbox.getBoundingClientRect().top + "px"
                divToAppend.style.left =
                  checkbox.getBoundingClientRect().left + "px"
                container.appendChild(divToAppend)
                setTimeout(() => {
                  const fab = document.getElementById("fab-export")
                  divToAppend.style.top =
                    fab.getBoundingClientRect().top - 15 + "px"
                  divToAppend.style.left =
                    fab.getBoundingClientRect().left - 15 + "px"
                  divToAppend.style.opacity = "1"
                }, 50)
                setTimeout(() => {
                  divToAppend.remove()
                }, 650)
              }
            }
            setSelectedMembers([...selectedMembers])
          }}
          style={{
            marginRight: -10,
            transition: "100ms",
          }}
        />
      </TableCell>
      <TableCell style={{ width: 36, minWidth: 36 }}>
        <div
          style={{
            width: 36,
            height: 36,
            borderRadius: 20,
            backgroundImage: `url(http://cdn.aworld.io/users/${item.user.sub}/profile.jpg), url(https://cdn.aworld.io/users/default/profile.jpg)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </TableCell>
      <TitleTableCell
        title={item.user.first_name + " " + item.user.last_name}
        id={item.user.sub}
      />
      {usersList.find((item) => item.group) ? (
        <TableCell>
          {item.group ? (
            <Chip label={item.group.name} size="small" color="primary" />
          ) : null}
        </TableCell>
      ) : null}
      <TableCell>
        {item.isAdmin && <Chip label="Admin" size="small" color="success" />}
      </TableCell>
      <TableCell>
        <IconButton
          aria-label="more"
          id="more-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertRounded />
        </IconButton>
        <Menu
          id="more-menu"
          MenuListProps={{
            "aria-labelledby": "more-menu",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: 206,
            },
          }}
        >
          {item.isAdmin ? (
            <MenuItem
              onClick={async () => {
                setSelectedMembers([])
                handleClose()
                setUpsertingUser(true)
                await upsertUsers(teamId, [
                  {
                    sub: item.user.sub,
                    email: null,
                    isAdmin: false,
                    groupId: null,
                  },
                ])
                setUpsertingUser(false)
              }}
            >
              <Stack direction="row" spacing={1}>
                <KeyboardDoubleArrowDownRounded />
                <Typography>Revoke admin role</Typography>
              </Stack>
            </MenuItem>
          ) : (
            <MenuItem
              onClick={async () => {
                setSelectedMembers([])
                handleClose()
                setUpsertingUser(true)
                await upsertUsers(teamId, [
                  {
                    sub: item.user.sub,
                    email: null,
                    isAdmin: true,
                    groupId: null,
                  },
                ])
                setUpsertingUser(false)
              }}
            >
              <Stack direction="row" spacing={1}>
                <KeyboardDoubleArrowUpRounded />
                <Typography>Make admin</Typography>
              </Stack>
            </MenuItem>
          )}
          <MenuItem
            onClick={async () => {
              setSelectedMembers([])
              setUsersToAdd([item.user])
              handleClose()
              setGroupDialogOpen(true)
            }}
          >
            <Stack direction="row" spacing={1}>
              <GroupAddRounded />
              <Typography>
                {item.group ? "Change group" : "Add to group"}
              </Typography>
            </Stack>
          </MenuItem>
          {item.group ? (
            <MenuItem
              onClick={async () => {
                setSelectedMembers([])
                handleClose()
                setUpsertingUser(true)
                await upsertUsers(teamId, [
                  {
                    sub: item.user.sub,
                    email: null,
                    isAdmin: null,
                    groupId: "none",
                  },
                ])
                setUpsertingUser(false)
              }}
            >
              <Stack direction="row" spacing={1}>
                <GroupRemoveRounded />
                <Typography>Remove from group</Typography>
              </Stack>
            </MenuItem>
          ) : null}
          <MenuItem
            onClick={async () => {
              setSelectedMembers([])
              handleClose()
              setUsersToRemove([item.user])
              setRemoveUserDialogOpen(true)
            }}
          >
            <Stack direction="row" spacing={1}>
              <DeleteRounded
                color="error"
                style={{ marginLeft: -1, marginRight: 1 }}
              />
              <Typography>Remove</Typography>
            </Stack>
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  )
}

export default MembersListRow
