import {
  AddAlarmRounded,
  AutoAwesomeRounded,
  BurstModeRounded,
  CheckRounded,
  CloseRounded,
  CollectionsRounded,
  CropSquareRounded,
  DeleteRounded,
  DoneRounded,
  EditRounded,
  ImageRounded,
  KeyboardArrowUpRounded,
  SpaRounded,
  WallpaperRounded,
} from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Alert,
  Autocomplete,
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  IconButton,
  ImageListItem,
  LinearProgress,
  Snackbar,
  Stack,
  TextField,
  Typography,
  Zoom,
} from "@mui/material"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import ListContainer from "../../components/global/listContainer"
import LoadingBackdrop from "../../components/global/loadingBackdrop"
import TableSpinner from "../../components/global/tableSpinner"
import MediaListTopBar from "../../components/media/mediaListTopBar"
import UploadMediaDialog from "../../components/media/uploadMediaDialog"
import { MediaContext } from "../../controllers/media"
import Media from "../../models/media"
import { MediaSize } from "../../services/config/enum"
import {
  capitalizeFirstCharacter,
  deepCopy,
  registerAnalyticsEvent,
} from "../../services/utilities/utility"
import imagesGrid from "../../assets/images/images-grid.jpg"
import "../../styles/mediaList.scss"
import { MainContext } from "../../controllers/main"
import clocksIcon from "../../assets/icons/clocksDarker.png"
import { mobileWidthBreakpoint } from "../../services/config/constants"

const MediaList = ({
  forDialog = false,
  forDialogFilters,
  selectedImage,
  setSelectedImage,
  disableCategoryFilter = false,
}: {
  forDialog?: boolean
  forDialogFilters?: { category?: string; size?: MediaSize[] }
  selectedImage?: {
    id: string
    url: string
    description?: string
    type?: MediaSize
  } | null
  setSelectedImage?: Dispatch<
    SetStateAction<{
      id: string
      url: string
      description?: string
      type?: MediaSize
    } | null>
  >
  disableCategoryFilter?: boolean
}) => {
  const { showPadding, setChangesSaved, windowWidth } = useContext(MainContext)
  const {
    loading,
    mediaList,
    getMediaList,
    updatingList,
    setUpdatingList,
    hasSearched,
    searchValue,
    setSearchValue,
    searchCategory,
    searchSize,
    searchMediaList,
    loadMoreMedia,
    mediaListNextToken,
    deleteMediaFromList,
    searchMediaTags,
    updateMedia,
    mediaTagsList,
    mediaTagsLoading,
  } = useContext(MediaContext)

  // list background update
  const backgroundUpdate = useCallback(() => {
    if (!loading && !hasSearched && !forDialogFilters) {
      setUpdatingList(true)
      getMediaList(false)
    }
  }, [getMediaList]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!mediaList.length && !forDialog) {
      getMediaList()
    }
    backgroundUpdate()
  }, [backgroundUpdate])

  // handle upload media dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // media uploaded successfully snackbar
  const [mediaUploadedSnackbarOpen, setMediaUploadedSnackbarOpen] =
    useState(false)

  // media deleted successfully snackbar
  const [mediaDeletedSnackbarOpen, setMediaDeletedSnackbarOpen] =
    useState(false)

  // view big image
  const [bigImageViewOpen, setBigImageViewOpen] = useState<boolean>(false)
  const [itemToVisualize, setItemToVisualize] = useState<{
    media: Media
    width?: number
    height?: number
  } | null>(null)
  const [imageLoading, setImageLoading] = useState<boolean>(false)

  const closeBigImage = () => {
    setBigImageViewOpen(false)
    setImageLoading(false)
    setItemToVisualize(null)
    setDeleteMediaAlertOpen(false)
    setEditingTitle(false)
    setNewTitle("")
    setEditingDescription(false)
    setNewDescription("")
    setEditingMediaTags(false)
    setNewMediaTags([])
    setInvalidCharacterError(false)
  }

  // edit media metadata
  const [editLoading, setEditLoading] = useState<boolean>(false)
  const [editingTitle, setEditingTitle] = useState<boolean>(false)
  const [newTitle, setNewTitle] = useState<string>("")
  const [editingDescription, setEditingDescription] = useState<boolean>(false)
  const [newDescription, setNewDescription] = useState<string>("")
  const [editingMediaTags, setEditingMediaTags] = useState<boolean>(false)
  const [newMediaTags, setNewMediaTags] = useState<string[]>([])

  // delete media
  const [deleteMediaAlertOpen, setDeleteMediaAlertOpen] =
    useState<boolean>(false)
  const [deletingMedia, setDeletingMedia] = useState<boolean>(false)
  const [deleteMediaAlertDialogOpen, setDeleteMediaAlertDialogOpen] =
    useState<boolean>(false)

  // invalid character error
  const [invalidCharacterError, setInvalidCharacterError] =
    useState<boolean>(false)

  // load more media when list reaches the bottom
  const [loadingMoreItems, setLoadingMoreItems] = useState<boolean>(false)

  // show scroll to top button
  const [showScrollToTop, setShowScrollToTop] = useState<boolean>(false)

  // register analytics event
  useEffect(() => {
    registerAnalyticsEvent({ type: "page_view", name: "media_list" })
  }, [])

  return (
    <ListContainer style={{ position: "relative" }}>
      {updatingList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 120.5,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: 1,
          backgroundColor: "#e0e0e0",
          top: 121,
        }}
      />
      <MediaListTopBar
        loading={loading}
        textFieldValue={searchValue}
        textFieldPlaceholder="Search Media"
        addButtonLabel="Upload Media"
        textFieldOnChange={(e) => {
          setSearchValue(e.target.value)
          if (e.target.value.length === 0 && hasSearched) {
            setUpdatingList(true)

            if (!searchCategory && !searchSize && !searchMediaTags.length) {
              getMediaList(false)
            } else {
              searchMediaList({
                title: "",
                size: searchSize,
                category: searchCategory,
                mediaTags: searchMediaTags,
              })
            }
          }
        }}
        textFieldOnKeyDown={(e) => {
          if (e.key === "Enter" && searchValue.length > 2) {
            searchMediaList({
              title: searchValue,
              size: searchSize,
              category: searchCategory,
              mediaTags: searchMediaTags,
            })
          }
        }}
        cancelButtonOnClick={() => {
          setSearchValue("")
          if (hasSearched) {
            setUpdatingList(true)

            if (!searchCategory && !searchSize && !searchMediaTags.length) {
              getMediaList(false)
            } else {
              searchMediaList({
                title: "",
                size: searchSize,
                category: searchCategory,
                mediaTags: searchMediaTags,
              })
            }
          }
        }}
        searchButtonOnClick={() => {
          searchMediaList({
            title: searchValue,
            size: searchSize,
            category: searchCategory,
            mediaTags: searchMediaTags,
          })
        }}
        addButtonOnClick={handleDialogChange}
        forDialog={forDialog}
        forDialogFilters={forDialogFilters}
        disableCategoryFilter={disableCategoryFilter}
      />
      {loading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
        />
      ) : (
        <div
          style={{
            height:
              showPadding === "yes"
                ? "calc(100vh - 220px)"
                : "calc(100vh - 187px)",
            marginTop: -4,
            position: "relative",
          }}
        >
          <div
            id="media-list-container"
            style={{
              maxHeight:
                showPadding === "yes"
                  ? "calc(100vh - 220px)"
                  : "calc(100vh - 187px)",
              listStyle: "none",
              overflowY: "auto",
              display: "grid",
              padding: 15,
              gap: 20,
              gridTemplateColumns:
                windowWidth < mobileWidthBreakpoint
                  ? "repeat(2, 1fr)"
                  : "repeat(4, 1fr)",
              marginBlockStart: "1em",
              marginBlockEnd: "1em",
            }}
            onScroll={async () => {
              const container = document.getElementById("media-list-container")

              if (container.scrollTop > 200) {
                setShowScrollToTop(true)
              } else {
                setShowScrollToTop(false)
              }

              if (
                container.scrollTop >=
                  container.scrollHeight - container.clientHeight - 10 &&
                mediaListNextToken &&
                !loadingMoreItems
              ) {
                setLoadingMoreItems(true)
                const noErrors = await loadMoreMedia()
                if (noErrors) {
                  setLoadingMoreItems(false)
                }
              }
            }}
          >
            {mediaList.map((item) => (
              <div
                style={{ maxHeight: 260, height: 260 }}
                key={item.id}
                onClick={() => {
                  if (forDialog) {
                    if (selectedImage && selectedImage.id === item.id) {
                      setSelectedImage(null)
                    } else {
                      setSelectedImage({
                        id: item.id,
                        url: item.url,
                        description: item.description ? item.description : null,
                        type: item.size
                          ? (item.size.toUpperCase() as MediaSize)
                          : null,
                      })
                    }
                  } else {
                    setItemToVisualize({
                      media: item,
                    })
                    setBigImageViewOpen(true)
                    setImageLoading(true)

                    let img = new Image()
                    img.src = item.url
                    img.onload = () => {
                      let width = img.width
                      let height = img.height
                      setItemToVisualize({
                        media: item,
                        width: width,
                        height: height,
                      })
                    }
                  }
                }}
              >
                <ImageListItem
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    backgroundColor: "#fafafa",
                    cursor: "pointer",
                    height: 260,
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${imagesGrid})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "repeat",
                      height: 190,
                      margin: 16,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={item.url}
                      style={{
                        maxWidth: "100%",
                        maxHeight: 190,
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <Typography
                    style={{
                      position: "absolute",
                      left: 16,
                      bottom: 25,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "calc(100% - 32px)",
                    }}
                    variant="body2"
                  >
                    {item.title}
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={1}
                    style={{
                      position: "absolute",
                      left: 16,
                      bottom: 7,
                      fontSize: 12,
                      maxWidth: "calc(100% - 32px)",
                      color: "#575757",
                      overflow: "revert-layer",
                    }}
                  >
                    {item.mediaTags && item.mediaTags.length ? (
                      <Stack
                        direction="row"
                        style={{ height: 17 }}
                        spacing={1}
                        alignItems="center"
                      >
                        {item.mediaTags.slice(0, 2).map((item) => (
                          <Chip
                            key={item}
                            label={
                              item === "1stilllife"
                                ? "1. Still Life"
                                : item === "2composition"
                                ? "2. Composition"
                                : capitalizeFirstCharacter(item)
                            }
                            size="small"
                            style={{
                              height: 17,
                              fontSize: 11,
                              backgroundColor: "#e6e6e6",
                            }}
                          />
                        ))}
                        {item.mediaTags.length > 2 && (
                          <Typography style={{ fontSize: "0.8rem" }}>
                            +{item.mediaTags.slice(2).length}
                          </Typography>
                        )}
                      </Stack>
                    ) : (
                      "No tags"
                    )}
                  </Stack>
                  <Stack
                    style={{
                      position: "absolute",
                      bottom: 58,
                      right: 20,
                      zIndex: 1,
                    }}
                    direction="row"
                    spacing={0.6}
                  >
                    <Chip
                      label={
                        <Stack direction="row" spacing={1} alignItems="center">
                          {item.category === "background" ? (
                            <WallpaperRounded style={{ fontSize: 12 }} />
                          ) : item.category === "decor" ? (
                            <AutoAwesomeRounded style={{ fontSize: 12 }} />
                          ) : item.category === "thumbnail" ? (
                            <CropSquareRounded style={{ fontSize: 12 }} />
                          ) : item.category === "content" ? (
                            <BurstModeRounded style={{ fontSize: 12 }} />
                          ) : item.category === "challenge" ? (
                            <SpaRounded style={{ fontSize: 12 }} />
                          ) : item.category === "action" ? (
                            <AddAlarmRounded style={{ fontSize: 12 }} />
                          ) : item.category === "actionGroup" ? (
                            <img
                              src={clocksIcon}
                              style={{ width: 12, height: 12 }}
                            />
                          ) : item.category === "actionGroupBadge" ? (
                            <AddAlarmRounded style={{ fontSize: 12 }} />
                          ) : item.category === "other" ? (
                            <CollectionsRounded style={{ fontSize: 12 }} />
                          ) : (
                            <ImageRounded style={{ fontSize: 12 }} />
                          )}
                          <div>
                            {capitalizeFirstCharacter(
                              item.category === "actionGroup"
                                ? "Series"
                                : item.category === "actionGroupBadge"
                                ? "Series Badge"
                                : item.category
                            )}
                          </div>
                        </Stack>
                      }
                      size="small"
                      style={{
                        height: 18,
                        fontSize: 11,
                        backgroundColor: "#e6e6e6",
                      }}
                    />
                    {item.size && (
                      <Chip
                        label={item.size.toUpperCase()}
                        size="small"
                        style={{
                          height: 18,
                          fontSize: 11,
                          backgroundColor: "#e6e6e6",
                        }}
                      />
                    )}
                  </Stack>
                  <div
                    className="media-list-overlay"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                      backgroundColor:
                        selectedImage && selectedImage.id === item.id
                          ? "rgba(0, 0, 0, 0.3)"
                          : null,
                      transition: "180ms",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: 36,
                        height: 36,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#5262ba",
                        borderRadius: 18,
                        opacity:
                          selectedImage && selectedImage.id === item.id ? 1 : 0,
                        transition: "180ms",
                      }}
                    >
                      <DoneRounded
                        style={{
                          color: "white",
                        }}
                      />
                    </div>
                  </div>
                </ImageListItem>
              </div>
            ))}
          </div>
          {loadingMoreItems && (
            <LinearProgress
              style={{ width: "100%", position: "absolute", bottom: 0 }}
            />
          )}
          <Zoom in={showScrollToTop} unmountOnExit>
            <Fab
              size="small"
              aria-label="scroll to top"
              color="primary"
              style={{ position: "absolute", bottom: 14, right: 20 }}
              onClick={() => {
                const tableContainer = document.getElementById(
                  "media-list-container"
                )
                tableContainer.scrollTo({ top: 0, behavior: "smooth" })
              }}
            >
              <KeyboardArrowUpRounded />
            </Fab>
          </Zoom>
        </div>
      )}
      <UploadMediaDialog
        dialogOpen={dialogOpen}
        handleDialogChange={handleDialogChange}
        topBarText="Upload Media"
        setMediaUploadedSnackbarOpen={setMediaUploadedSnackbarOpen}
        forDialogCategory={
          forDialogFilters && forDialogFilters.category
            ? forDialogFilters.category
            : null
        }
      />
      {/* media uploaded snackbar */}
      <Snackbar
        open={mediaUploadedSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => {
          setMediaUploadedSnackbarOpen(false)
        }}
      >
        <Alert severity="success">Media uploaded</Alert>
      </Snackbar>
      {/* media deleted snackbar */}
      <Snackbar
        open={mediaDeletedSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => {
          setMediaDeletedSnackbarOpen(false)
        }}
      >
        <Alert severity="success">
          Media deleted, list will be updated soon
        </Alert>
      </Snackbar>
      {/* view big image */}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: bigImageViewOpen ? 9999 : -1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          opacity: bigImageViewOpen ? 1 : 0,
          transition: "200ms",
        }}
        onClick={closeBigImage}
      >
        <div
          style={{
            width: "80%",
            height: "80%",
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            overflow: "hidden",
          }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            style={{
              position: "absolute",
              top: 4,
              right: deleteMediaAlertOpen ? 12 : -306,
              transition: "350ms",
            }}
          >
            <IconButton
              aria-label="delete"
              onClick={
                deleteMediaAlertOpen
                  ? null
                  : () => {
                      setDeleteMediaAlertOpen(true)
                    }
              }
              style={{
                opacity: deleteMediaAlertOpen ? 0 : 1,
                cursor: deleteMediaAlertOpen ? "default" : "pointer",
                transition: "250ms",
              }}
              color="error"
            >
              <DeleteRounded />
            </IconButton>
            <Typography>Delete this media?</Typography>
            <ButtonGroup style={{ marginLeft: 20 }}>
              <Button
                onClick={() => {
                  setDeleteMediaAlertOpen(false)
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={async () => {
                  setDeleteMediaAlertDialogOpen(true)
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
          </Stack>
          <Button
            variant="outlined"
            style={{ position: "absolute", bottom: 12, right: 12 }}
            onClick={closeBigImage}
          >
            Close
          </Button>
          {itemToVisualize && (
            <Stack
              style={{
                position: "absolute",
                top: 12,
                left: 12,
                zIndex: 1,
              }}
              direction="row"
              spacing={0.6}
            >
              <Chip
                label={
                  <Stack direction="row" spacing={1} alignItems="center">
                    {itemToVisualize.media.category === "background" ? (
                      <WallpaperRounded style={{ fontSize: 12 }} />
                    ) : itemToVisualize.media.category === "decor" ? (
                      <AutoAwesomeRounded style={{ fontSize: 12 }} />
                    ) : itemToVisualize.media.category === "thumbnail" ? (
                      <CropSquareRounded style={{ fontSize: 12 }} />
                    ) : itemToVisualize.media.category === "content" ? (
                      <BurstModeRounded style={{ fontSize: 12 }} />
                    ) : itemToVisualize.media.category === "challenge" ? (
                      <SpaRounded style={{ fontSize: 12 }} />
                    ) : itemToVisualize.media.category === "action" ? (
                      <AddAlarmRounded style={{ fontSize: 12 }} />
                    ) : itemToVisualize.media.category === "actionGroup" ? (
                      <img src={clocksIcon} style={{ width: 12, height: 12 }} />
                    ) : itemToVisualize.media.category ===
                      "actionGroupBadge" ? (
                      <AddAlarmRounded style={{ fontSize: 12 }} />
                    ) : itemToVisualize.media.category === "other" ? (
                      <CollectionsRounded style={{ fontSize: 12 }} />
                    ) : (
                      <ImageRounded style={{ fontSize: 12 }} />
                    )}
                    <div>
                      {capitalizeFirstCharacter(itemToVisualize.media.category)}
                    </div>
                  </Stack>
                }
                size="small"
              />
              {itemToVisualize.media.size && (
                <Chip
                  label={itemToVisualize.media.size.toUpperCase()}
                  size="small"
                />
              )}
            </Stack>
          )}
          <Stack
            direction="row"
            style={{
              width: "100%",
              height: "75%",
              paddingLeft: 25,
              paddingRight: 25,
            }}
          >
            <Stack spacing={2} style={{ width: "35%" }}>
              <Stack spacing={0}>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography variant="h6">Title</Typography>
                  {editingTitle ? (
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <IconButton
                        onClick={() => {
                          setEditingTitle(false)
                          setNewTitle("")
                        }}
                      >
                        <CloseRounded style={{ fontSize: 16 }} />
                      </IconButton>
                      <IconButton
                        disabled={!newTitle.length || invalidCharacterError}
                        onClick={async () => {
                          setEditLoading(true)

                          const noErrors = await updateMedia(
                            itemToVisualize.media.id,
                            newTitle,
                            itemToVisualize.media.description,
                            itemToVisualize.media.mediaTags
                          )

                          if (noErrors) {
                            let itemToVisualizeCopy = deepCopy(itemToVisualize)
                            itemToVisualizeCopy.media.title = newTitle
                            setItemToVisualize(itemToVisualizeCopy)
                            setEditingTitle(false)
                            setNewTitle("")
                            setChangesSaved(true)
                          }

                          setEditLoading(false)
                        }}
                      >
                        <CheckRounded style={{ fontSize: 16 }} />
                      </IconButton>
                    </Stack>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setEditingTitle(true)
                        setNewTitle(itemToVisualize.media.title)
                      }}
                    >
                      <EditRounded style={{ fontSize: 16 }} />
                    </IconButton>
                  )}
                </Stack>
                {editingTitle ? (
                  <TextField
                    size="small"
                    style={{
                      height: 24,
                      marginRight: 25,
                      marginBottom: invalidCharacterError ? 20 : 0,
                    }}
                    value={newTitle}
                    onChange={(e) => {
                      setNewTitle(capitalizeFirstCharacter(e.target.value))

                      let regex = /^[a-zA-Z0-9\s-]+$/g
                      if (regex.test(e.target.value)) {
                        setInvalidCharacterError(false)
                      } else {
                        setInvalidCharacterError(true)
                      }
                    }}
                    error={invalidCharacterError}
                    helperText={
                      invalidCharacterError && !newTitle.length
                        ? "Title cannot be empty"
                        : invalidCharacterError
                        ? "One or more invalid characters"
                        : ""
                    }
                    onBlur={() => {
                      if (newTitle) {
                        setNewTitle((current) => current.trim())
                      }
                    }}
                  />
                ) : (
                  <Typography variant="body1">
                    {itemToVisualize ? itemToVisualize.media.title : ""}
                  </Typography>
                )}
              </Stack>
              {itemToVisualize ? (
                <Stack spacing={0}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography variant="h6">Description</Typography>
                    {editingDescription ? (
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <IconButton
                          onClick={() => {
                            setEditingDescription(false)
                            setNewDescription("")
                          }}
                        >
                          <CloseRounded style={{ fontSize: 16 }} />
                        </IconButton>
                        <IconButton
                          onClick={async () => {
                            setEditLoading(true)

                            const noErrors = await updateMedia(
                              itemToVisualize.media.id,
                              itemToVisualize.media.title,
                              newDescription,
                              itemToVisualize.media.mediaTags
                            )

                            if (noErrors) {
                              let itemToVisualizeCopy =
                                deepCopy(itemToVisualize)
                              itemToVisualizeCopy.media.description =
                                newDescription
                              setItemToVisualize(itemToVisualizeCopy)
                              setEditingDescription(false)
                              setNewDescription("")
                              setChangesSaved(true)
                            }

                            setEditLoading(false)
                          }}
                        >
                          <CheckRounded style={{ fontSize: 16 }} />
                        </IconButton>
                      </Stack>
                    ) : (
                      <IconButton
                        onClick={() => {
                          setEditingDescription(true)
                          setNewDescription(itemToVisualize.media.description)
                        }}
                      >
                        <EditRounded style={{ fontSize: 16 }} />
                      </IconButton>
                    )}
                  </Stack>
                  {editingDescription ? (
                    <TextField
                      size="small"
                      style={{ height: 24, marginRight: 25 }}
                      value={newDescription}
                      onChange={(e) => {
                        setNewDescription(
                          capitalizeFirstCharacter(e.target.value)
                        )
                      }}
                      onBlur={() => {
                        if (newDescription) {
                          setNewDescription((current) => current.trim())
                        }
                      }}
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      style={{
                        color:
                          itemToVisualize.media.description &&
                          itemToVisualize.media.description.length
                            ? null
                            : "#999999",
                      }}
                    >
                      {itemToVisualize.media.description &&
                      itemToVisualize.media.description.length
                        ? itemToVisualize.media.description
                        : "No description"}
                    </Typography>
                  )}
                </Stack>
              ) : null}
              {itemToVisualize && (
                <Stack
                  spacing={
                    itemToVisualize.media.mediaTags &&
                    itemToVisualize.media.mediaTags.length
                      ? 0.5
                      : 0
                  }
                >
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography variant="h6">Tags</Typography>
                    {editingMediaTags ? (
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <IconButton
                          onClick={() => {
                            setEditingMediaTags(false)
                            setNewMediaTags([])
                          }}
                        >
                          <CloseRounded style={{ fontSize: 16 }} />
                        </IconButton>
                        <IconButton
                          disabled={!newMediaTags.length}
                          onClick={async () => {
                            setEditLoading(true)

                            const noErrors = await updateMedia(
                              itemToVisualize.media.id,
                              itemToVisualize.media.title,
                              itemToVisualize.media.description,
                              newMediaTags
                            )

                            if (noErrors) {
                              let itemToVisualizeCopy =
                                deepCopy(itemToVisualize)
                              itemToVisualizeCopy.media.mediaTags = newMediaTags
                              setItemToVisualize(itemToVisualizeCopy)
                              setEditingMediaTags(false)
                              setNewMediaTags([])
                              setChangesSaved(true)
                            }

                            setEditLoading(false)
                          }}
                        >
                          <CheckRounded style={{ fontSize: 16 }} />
                        </IconButton>
                      </Stack>
                    ) : (
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          setEditingMediaTags(true)
                          setNewMediaTags(itemToVisualize.media.mediaTags)
                        }}
                      >
                        <EditRounded style={{ fontSize: 16 }} />
                      </IconButton>
                    )}
                  </Stack>
                  {editingMediaTags ? (
                    <Autocomplete
                      disablePortal
                      fullWidth
                      size="small"
                      limitTags={2}
                      multiple
                      value={newMediaTags}
                      onChange={(event, newValues: string[]) => {
                        setNewMediaTags(newValues)
                      }}
                      loading={mediaTagsLoading}
                      getOptionLabel={(option) => {
                        if (option === "1stilllife") {
                          return "1. Still Life"
                        } else if (option === "2composition") {
                          return "2. Composition"
                        } else {
                          return capitalizeFirstCharacter(option)
                        }
                      }}
                      options={mediaTagsList}
                      renderInput={(params) => <TextField {...params} />}
                      style={{ width: "calc(100% - 25px)" }}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option === "1stilllife"
                            ? "1. Still life"
                            : option === "2composition"
                            ? "2. Composition"
                            : capitalizeFirstCharacter(option)}
                        </li>
                      )}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            size="small"
                            key={option}
                            label={
                              option === "1stilllife"
                                ? "1. Still life"
                                : option === "2composition"
                                ? "2. Composition"
                                : capitalizeFirstCharacter(option)
                            }
                          />
                        ))
                      }}
                    />
                  ) : itemToVisualize.media.mediaTags &&
                    itemToVisualize.media.mediaTags.length ? (
                    <Stack direction="row" spacing={1} flexWrap="wrap">
                      {itemToVisualize.media.mediaTags.map((item) => (
                        <Chip
                          key={item}
                          size="small"
                          label={
                            item === "1stilllife"
                              ? "1. Still Life"
                              : item === "2composition"
                              ? "2. Composition"
                              : capitalizeFirstCharacter(item)
                          }
                          style={{ marginBottom: 8 }}
                        />
                      ))}
                    </Stack>
                  ) : (
                    <Typography
                      variant="body1"
                      style={{
                        color: "#999999",
                      }}
                    >
                      No tags
                    </Typography>
                  )}
                </Stack>
              )}
              {itemToVisualize &&
              itemToVisualize.media.category !== "action" &&
              itemToVisualize.media.category !== "actionGroup" &&
              itemToVisualize.media.category !== "actionGroupBadge" ? (
                <Stack
                  spacing={0}
                  style={{ marginTop: editingMediaTags ? 8 : null }}
                >
                  <Typography variant="h6">Dimensions</Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "0.9rem",
                      color:
                        itemToVisualize.width && itemToVisualize.height
                          ? null
                          : "#999999",
                    }}
                  >
                    {itemToVisualize.width && itemToVisualize.height
                      ? itemToVisualize.width +
                        "x" +
                        itemToVisualize.height +
                        " pixels"
                      : "Loading..."}
                  </Typography>
                </Stack>
              ) : null}
            </Stack>
            <div
              style={{
                width: "65%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundImage: `url(${imagesGrid})`,
                backgroundSize: "contain",
                backgroundRepeat: "repeat",
              }}
            >
              <img
                src={itemToVisualize ? itemToVisualize.media.url : null}
                style={{
                  objectFit: "contain",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
                onLoad={() => {
                  setImageLoading(false)
                }}
              />
            </div>
          </Stack>
          {imageLoading && (
            <LinearProgress
              style={{ position: "absolute", width: "100%", top: 0, left: 0 }}
            />
          )}
        </div>
      </div>
      <LoadingBackdrop open={deletingMedia} />
      {/* delete media alert dialog */}
      <Dialog
        open={deleteMediaAlertDialogOpen}
        onClose={() => {
          setDeleteMediaAlertDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-delete-media"
        aria-describedby="alert-dialog-description-delete-media"
        style={{ zIndex: 99999 }}
      >
        <DialogTitle id="alert-dialog-title-delete-media">
          Are you sure you want to delete this media?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-delete-media">
            It will disappear from every slide that includes it
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteMediaAlertDialogOpen(false)
              setDeleteMediaAlertOpen(false)
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={deletingMedia}
            onClick={async () => {
              setDeletingMedia(true)
              setUpdatingList(true)
              const noErrors = await deleteMediaFromList(
                itemToVisualize.media.id
              )
              setDeletingMedia(false)
              if (noErrors) {
                setMediaDeletedSnackbarOpen(true)
              }

              setDeleteMediaAlertDialogOpen(false)
              setBigImageViewOpen(false)
              setImageLoading(false)
              setItemToVisualize(null)
              setDeleteMediaAlertOpen(false)
            }}
            autoFocus
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={editLoading} />
    </ListContainer>
  )
}

export default MediaList
