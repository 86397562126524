import {
  Backdrop,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import { LanguagesContext } from "../../controllers/languages"
import { Action } from "@aworld/app-preview"
import { ActionsContext } from "../../controllers/actions"

const ActionGlobalPreview = ({
  isGlobalPreviewOpen,
  setIsGlobalPreviewOpen,
  currentPreviewLanguage,
  setCurrentPreviewLanguage,
}: {
  isGlobalPreviewOpen: boolean
  setIsGlobalPreviewOpen: Dispatch<SetStateAction<boolean>>
  currentPreviewLanguage: string
  setCurrentPreviewLanguage: Dispatch<SetStateAction<string>>
}) => {
  const { languages } = useContext(LanguagesContext)
  const { currentAction } = useContext(ActionsContext)

  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isGlobalPreviewOpen}
      onClick={() => {
        setIsGlobalPreviewOpen(false)
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {currentAction.document.items.find(
          (item) => item.lang === currentPreviewLanguage
        ) ? (
          <div
            style={{
              marginTop: 85,
            }}
          >
            <Action
              action={currentAction as any}
              lang={currentPreviewLanguage}
              className="preview"
            />
          </div>
        ) : null}
        <Card
          style={{ position: "absolute", top: 10, width: 350, height: 70 }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <CardContent>
            <div
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <FormControl fullWidth size="small">
                <InputLabel id="language-select-global-preview">
                  Language
                </InputLabel>
                <Select
                  labelId="language-select-global-preview"
                  label="Language"
                  value={currentPreviewLanguage}
                  onChange={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    setCurrentPreviewLanguage(e.target.value)
                  }}
                >
                  {languages
                    .filter((lang) => {
                      if (
                        currentAction.document.items.find(
                          (item) => item.lang === lang
                        )
                      ) {
                        return lang
                      }
                    })
                    .map((lang) => (
                      <MenuItem key={lang} value={lang}>
                        {lang.toUpperCase()}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </CardContent>
        </Card>
      </div>
    </Backdrop>
  )
}

export default ActionGlobalPreview
