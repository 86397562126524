import { useLazyQuery } from "@apollo/client"
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  listFootprintQuestions,
  listMetrics,
} from "../services/graphql/queries"
import { logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"
import FootprintQuestion from "../models/footprintQuestion"

interface FootprintQuestionsContextInterface {
  footprintQuestionsLoading: boolean
  footprintQuestionsList: FootprintQuestion[]
}

const FootprintQuestionsContext =
  createContext<FootprintQuestionsContextInterface>({
    footprintQuestionsLoading: true,
    footprintQuestionsList: [],
  })

const FootprintQuestionsController = ({
  children,
}: {
  children: ReactNode
}) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // loadings
  const [footprintQuestionsLoading, setMetricsLoading] = useState<boolean>(true) // loading for footprint questions

  // states
  const [footprintQuestionsList, setMetricsList] = useState<
    FootprintQuestion[]
  >([]) // all footprint questions list

  // queries
  const [listFootprintQuestionsQuery] = useLazyQuery(listFootprintQuestions)

  // get footprint questions list
  const getFootprintQuestionsList = useCallback(async () => {
    panelStatus.filter(
      (item) => item.name === "Footprint Questions"
    )[0].loading = true
    setPanelStatus([...panelStatus])

    try {
      logger(Status.Api, "QUERY footprintQuestionsList", listMetrics)
      const { data } = await listFootprintQuestionsQuery({
        fetchPolicy: "no-cache",
      })
      logger(
        Status.Info,
        "footprint questions list",
        data.footprintQuestionsList.items
      )

      setMetricsList(data.footprintQuestionsList.items)

      setMetricsLoading(false)
      panelStatus.filter(
        (item) => item.name === "Footprint Questions"
      )[0].status = "success"
      panelStatus.filter(
        (item) => item.name === "Footprint Questions"
      )[0].loading = false
      setPanelStatus([...panelStatus])
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `footprintQuestionsList`, e.message)
      }
      setMetricsLoading(false)
      panelStatus.filter(
        (item) => item.name === "Footprint Questions"
      )[0].status = "error"
      panelStatus.filter(
        (item) => item.name === "Footprint Questions"
      )[0].loading = false
      setPanelStatus([...panelStatus])
    }
  }, [setError, setErrorMessage])

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter(
      (item) => item.name === "Footprint Questions"
    )[0].updateFunction = getFootprintQuestionsList
  }, [])

  // initial fetch
  useEffect(() => {
    getFootprintQuestionsList()
  }, [])

  return (
    <FootprintQuestionsContext.Provider
      value={{
        footprintQuestionsLoading,
        footprintQuestionsList,
      }}
    >
      {children}
    </FootprintQuestionsContext.Provider>
  )
}

export { FootprintQuestionsController, FootprintQuestionsContext }
