import { Cancel, Search } from "@mui/icons-material"
import {
  Button,
  Divider,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material"
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { ActionGroupsContext } from "../../controllers/actionGroups"
import ListFilters from "../global/listFilters"
import { LoadingButton } from "@mui/lab"
import { CSVLink } from "react-csv"

const ActionGroupsListTopBar = ({
  loading,
  textFieldValue,
  textFieldPlaceholder,
  addButtonLabel,
  addButtonOnClick,
  textFieldOnChange,
  textFieldOnKeyDown,
  cancelButtonOnClick,
  searchButtonOnClick,
  forDialog = false,
  addButtonDisabled = false,
}: {
  loading: boolean
  textFieldValue: string
  textFieldPlaceholder: string
  addButtonLabel: string
  textFieldOnChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  textFieldOnKeyDown: KeyboardEventHandler<HTMLDivElement>
  cancelButtonOnClick: MouseEventHandler<HTMLDivElement>
  searchButtonOnClick: MouseEventHandler<HTMLButtonElement>
  addButtonOnClick: MouseEventHandler<HTMLButtonElement>
  forDialog?: boolean
  addButtonDisabled?: boolean
}) => {
  const {
    searchCanBecomeHabit,
    setSearchCanBecomeHabit,
    searchLang,
    setSearchLang,
    searchCategory,
    setSearchCategory,
    searchFeatured,
    setSearchFeatured,
    updatingList,
    setUpdatingList,
    getActionGroupsList,
    searchActionGroupsList,
    getAllActionGroupsList,
  } = useContext(ActionGroupsContext)

  // search when filters update
  const [firstRender, setFirstRender] = useState<boolean>(true)

  useEffect(() => {
    if (!firstRender) {
      if (
        searchCanBecomeHabit === null &&
        !searchLang &&
        !searchCategory &&
        searchFeatured === null &&
        !textFieldValue.length
      ) {
        setUpdatingList(true)
        getActionGroupsList(false)
      } else {
        searchActionGroupsList()
      }
    } else {
      setFirstRender(false)
    }
  }, [searchCanBecomeHabit, searchLang, searchCategory, searchFeatured])

  // csv download button
  const csvButtonRef = useRef(null)
  const [csvData, setCsvData] = useState<string[][]>([])
  const [csvLoading, setCsvLoading] = useState<boolean>(false)

  useEffect(() => {
    if (csvData.length && !csvLoading) {
      csvButtonRef.current.link.click()
    }
  }, [csvData])

  return (
    <Stack
      style={{
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 4,
      }}
      spacing={1}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          disabled={loading || updatingList}
          size="small"
          fullWidth
          id="outlined-basic"
          placeholder={textFieldPlaceholder}
          variant="outlined"
          value={textFieldValue}
          onChange={textFieldOnChange}
          onKeyDown={textFieldOnKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: textFieldValue.length > 0 && (
              <InputAdornment position="start">
                <div
                  style={{
                    marginTop: 6,
                    marginRight: -6,
                    cursor: "pointer",
                  }}
                  onClick={loading ? () => {} : cancelButtonOnClick}
                >
                  <Cancel style={{ width: 18 }} />
                </div>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="outlined"
          disabled={textFieldValue.length < 3 || loading || updatingList}
          onClick={searchButtonOnClick}
          style={{ height: 40, minWidth: 74 }}
        >
          Search
        </Button>
        {!forDialog && <Divider orientation="vertical" flexItem />}
        {!forDialog && (
          <div>
            <LoadingButton
              variant="contained"
              disabled={loading || addButtonDisabled}
              loading={csvLoading}
              style={{
                whiteSpace: "nowrap",
                minWidth: "max-content",
                height: 40,
              }}
              onClick={async () => {
                setCsvLoading(true)
                setCsvData([])
                const data = await getAllActionGroupsList()
                setCsvData(data)
                setCsvLoading(false)
              }}
            >
              Export
            </LoadingButton>
            <CSVLink
              data={csvData}
              ref={csvButtonRef}
              filename={`series-list-${new Date()
                .toISOString()
                .slice(0, 10)}.csv`}
              style={{
                textDecoration: "none",
              }}
            />
          </div>
        )}
        {!forDialog && (
          <Button
            variant="contained"
            disabled={loading || addButtonDisabled}
            style={{
              whiteSpace: "nowrap",
              minWidth: "max-content",
              height: 40,
            }}
            onClick={addButtonOnClick}
          >
            {addButtonLabel}
          </Button>
        )}
      </Stack>
      <ListFilters
        disabled={loading || updatingList || forDialog}
        filters={[
          { type: "language", value: searchLang, setValue: setSearchLang },
          {
            type: "boolean",
            value: searchCanBecomeHabit,
            setValue: setSearchCanBecomeHabit,
            label: "Can become habit",
          },
          {
            type: "boolean",
            value: searchFeatured,
            setValue: setSearchFeatured,
            label: "Featured",
          },
          {
            type: "category",
            value: searchCategory,
            setValue: setSearchCategory,
          },
        ]}
      />
    </Stack>
  )
}

export default ActionGroupsListTopBar
