export const liveEpisodeMockData = {
  data: {
    episode: {
      id: "6c0c1300-5fac-430e-97c0-c6cb98523a03",
      lang: "it",
      status: "ACTIVE",
      stage: "PUBLISHED",
      title: "Episodio 01 Pubblicato",
      text: "Testo dell'episodio 01",
      createdAt: "2022-07-04T13:38:31.926Z",
      updatedAt: "2022-07-20T16:27:45.380Z",
      image:
        "https://d1kmq5hop6362i.cloudfront.net/2022/07/titolo-di-test-d922c967d90a",
      topics: [
        {
          primary: true,
          topic: {
            id: "8128981a-44e5-4335-a640-fa9375ef1d81",
            createdAt: "2022-06-07T12:08:43.777Z",
            updatedAt: "2022-06-07T12:08:43.777Z",
            createdAtT: 1654603723777,
            updatedAtT: 1654603723777,
            name: "Agricolture",
            translations: [
              {
                lang: "en",
                name: "Agricolture",
                __typename: "TopicTranslation",
              },
              {
                lang: "it",
                name: "Agricoltura",
                __typename: "TopicTranslation",
              },
              {
                lang: "es",
                name: "Agricolture",
                __typename: "TopicTranslation",
              },
              {
                lang: "fr",
                name: "Agricolture",
                __typename: "TopicTranslation",
              },
              {
                lang: "de",
                name: "Agricolture",
                __typename: "TopicTranslation",
              },
            ],
            __typename: "Topic",
          },
          __typename: "TagRelation",
        },
        {
          primary: false,
          topic: {
            id: "bf560f5d-cec9-4382-a4f0-76f10fa230fc",
            createdAt: "2022-06-07T12:08:43.779Z",
            updatedAt: "2022-06-07T12:08:43.779Z",
            createdAtT: 1654603723779,
            updatedAtT: 1654603723779,
            name: "Circular Economy",
            translations: [
              {
                lang: "en",
                name: "Circular Economy",
                __typename: "TopicTranslation",
              },
              {
                lang: "it",
                name: "Economia circolare",
                __typename: "TopicTranslation",
              },
              {
                lang: "es",
                name: "Circular Economy",
                __typename: "TopicTranslation",
              },
              {
                lang: "fr",
                name: "Circular Economy",
                __typename: "TopicTranslation",
              },
              {
                lang: "de",
                name: "Circular Economy",
                __typename: "TopicTranslation",
              },
            ],
            __typename: "Topic",
          },
          __typename: "TagRelation",
        },
        {
          primary: false,
          topic: {
            id: "9ff6753c-92c4-4d2a-bbd3-89ca6852d459",
            createdAt: "2022-06-07T12:08:43.780Z",
            updatedAt: "2022-06-07T12:08:43.780Z",
            createdAtT: 1654603723780,
            updatedAtT: 1654603723780,
            name: "Climate Action",
            translations: [
              {
                lang: "en",
                name: "Climate Action",
                __typename: "TopicTranslation",
              },
              {
                lang: "it",
                name: "Azione climatica",
                __typename: "TopicTranslation",
              },
              {
                lang: "es",
                name: "Climate Action",
                __typename: "TopicTranslation",
              },
              {
                lang: "fr",
                name: "Climate Action",
                __typename: "TopicTranslation",
              },
              {
                lang: "de",
                name: "Climate Action",
                __typename: "TopicTranslation",
              },
            ],
            __typename: "Topic",
          },
          __typename: "TagRelation",
        },
      ],
      sdgs: [
        {
          primary: true,
          sdg: {
            id: "sdg#01",
            createdAt: "2022-05-26T08:53:27.735Z",
            updatedAt: "2022-05-26T08:53:27.735Z",
            createdAtT: 1653555207735,
            updatedAtT: 1653555207735,
            name: "No Poverty",
            image: "https://solar-cdn.aworld.link/sdgs/card-goal-01.png",
            translations: [
              {
                lang: "en",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-01.png",
                name: "No Poverty",
                __typename: "SdgTranslation",
              },
              {
                lang: "it",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-01.png",
                name: "No Poverty",
                __typename: "SdgTranslation",
              },
              {
                lang: "es",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-01.png",
                name: "No Poverty",
                __typename: "SdgTranslation",
              },
              {
                lang: "fr",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-01.png",
                name: "No Poverty",
                __typename: "SdgTranslation",
              },
              {
                lang: "de",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-01.png",
                name: "No Poverty",
                __typename: "SdgTranslation",
              },
            ],
            __typename: "Sdg",
          },
          __typename: "SdgRelation",
        },
        {
          primary: false,
          sdg: {
            id: "sdg#02",
            createdAt: "2022-05-26T08:53:27.737Z",
            updatedAt: "2022-05-26T08:53:27.737Z",
            createdAtT: 1653555207737,
            updatedAtT: 1653555207737,
            name: "Zero Hunger",
            image: "https://solar-cdn.aworld.link/sdgs/card-goal-02.png",
            translations: [
              {
                lang: "en",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-02.png",
                name: "Zero Hunger",
                __typename: "SdgTranslation",
              },
              {
                lang: "it",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-02.png",
                name: "Zero Hunger",
                __typename: "SdgTranslation",
              },
              {
                lang: "es",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-02.png",
                name: "Zero Hunger",
                __typename: "SdgTranslation",
              },
              {
                lang: "fr",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-02.png",
                name: "Zero Hunger",
                __typename: "SdgTranslation",
              },
              {
                lang: "de",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-02.png",
                name: "Zero Hunger",
                __typename: "SdgTranslation",
              },
            ],
            __typename: "Sdg",
          },
          __typename: "SdgRelation",
        },
      ],
      sdgTargets: [
        {
          primary: true,
          sdgTarget: {
            id: "tar#1.2",
            translations: [
              {
                lang: "it",
                description:
                  "Entro il 2030, ridurre almeno della metà la percentuale di uomini, donne e bambini di ogni età che vivono in povertà in tutte le sue dimensioni in base alle definizioni nazionali.",
                __typename: "SdgTargetTranslation",
              },
            ],
            __typename: "SdgTarget",
          },
          __typename: "SdgTargetRelation",
        },
        {
          primary: false,
          sdgTarget: {
            id: "tar#1.3",
            translations: [
              {
                lang: "it",
                description:
                  "Implementare sistemi a livello nazionale adeguati di protezione sociale e misure per tutti ed entro il 2030 per raggiungere la sostanziale copertura del numero di persone povere e vulnerabili.",
                __typename: "SdgTargetTranslation",
              },
            ],
            __typename: "SdgTarget",
          },
          __typename: "SdgTargetRelation",
        },
        {
          primary: false,
          sdgTarget: {
            id: "tar#1.4",
            translations: [
              {
                lang: "it",
                description:
                  "Entro il 2030, garantire che tutti gli uomini e le donne, in particolare i poveri e i vulnerabili, abbiano uguali diritti alle risorse economiche, così come all'accesso ai servizi di base, alla proprietà e controllo sulla terra e ad altre forme di proprietà, all’eredità, alle risorse naturali, ad appropriate tecnologie e anuovi servizi finanziari, tra cui la microfinanza.",
                __typename: "SdgTargetTranslation",
              },
            ],
            __typename: "SdgTarget",
          },
          __typename: "SdgTargetRelation",
        },
      ],
      translations: [
        {
          id: "6c0c1300-5fac-430e-97c0-c6cb98523a03#en",
          lang: "en",
          stage: "PUBLISHED",
          title: "English title",
          createdAt: "2022-07-04T13:40:40.352Z",
          updatedAt: "2022-07-20T16:27:45.373Z",
          slides: [
            {
              slideType: "TitleSlide",
              textColor: "white",
              background: "https://dummyimage.com/1080x1926/F5F0DA/F5F0DA.png",
              decor: "https://dummyimage.com/100x100/F6E4D9/F6E4D9.png",
              box: {
                boxShape: "squared",
                boxBackColor: "blue",
                __typename: "Box",
              },
              id: "74589494-9270-47e5-83bd-a14b17c1eec9",
              title: "English title",
              description: "Text for the english title",
              image: {
                type: "M",
                url: "https://dummyimage.com/328x260/D0E1E8/D0E1E8.png",
                description: null,
                __typename: "Image",
              },
              __typename: "TitleSlide",
            },
            {
              slideType: "FullTextSlide",
              textColor: "black",
              background: "https://dummyimage.com/1080x1926/F6E4D9/F6E4D9.png",
              decor: "https://dummyimage.com/390x105/F5F0DA/F5F0DA.png",
              box: {
                boxShape: "squared",
                boxBackColor: "white",
                __typename: "Box",
              },
              id: "1be7dd51-2230-4bf8-88f9-d4b75259a28f",
              __typename: "FullTextSlide",
              text: "<p>E se dal <strong>moto ondoso</strong> delle onde si potesse <strong>creare energia</strong>?<br>Non è certo una novità. Le prime tecnologie risalgono a due secoli fa ma colui che seppe avere un approccio moderno al tema fu Yoshio Masuda (1925-2009).</p>",
            },
            {
              slideType: "TextImageSlide",
              textColor: "black",
              background: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              decor: null,
              box: {
                boxBackColor: "white",
                boxShape: "squared",
              },
              id: "60136d99-0b09-404b-b0b8-18dbe144a433",
              text: "<p>Il principio di funzionamento è semplicissimo: appena un’onda o marea salgono all’interno della colonna, l’aria viene spostata e spinta attraverso una turbina.</p>",
              __typename: "TextImageSlide",
              image: {
                type: "S",
                url: "https://dummyimage.com/273x172/D0E1E8/D0E1E8.png",
                description: null,
                __typename: "Image",
              },
            },
            {
              slideType: "QuoteSlide",
              textColor: "black",
              background: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              decor: "https://dummyimage.com/390x105/F5F0DA/F5F0DA.png",
              box: {
                boxBackColor: "white",
                boxShape: "squared",
              },
              id: "60136d99-0b09-404b-b0b8-18dbe144a434",
              text: "<p>Credo che avere la terra e non rovinarla sia la più bella forma d’arte che si possa desiderare.</p>",
              __typename: "QuoteSlide",
              image: {
                url: "https://dummyimage.com/273x172/D0E1E8/D0E1E8.png",
                description: null,
                __typename: "Image",
              },
              author: "Andy Warhol",
              authorTitle: "Artista",
            },
            {
              slideType: "EmbedSlide",
              textColor: "black",
              background: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              id: "60136d99-0b09-404b-b0b8-18dbe144a435",
              text: "<p>Credo che avere la terra e non rovinarla sia la più bella forma</p>",
              captionTitle: "Titolo",
              caption: "Sottotitolo",
              embed: {
                url: "https://www.youtube.com/embed/0zuPnHfJk_8",
                __typename: "Embed",
              },
              __typename: "EmbedSlide",
            },
            {
              slideType: "FullImageSlide",
              textColor: "black",
              background: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              id: "60136d99-0b09-404b-b0b8-18dbe144a436",
              captionTitle: "Titolo",
              caption: "Sottotitolo",
              image: {
                url: "https://dummyimage.com/273x172/D0E1E8/D0E1E8.png",
                description: null,
                __typename: "Image",
              },
              __typename: "FullImageSlide",
            },
            {
              slideType: "PortraitSlide",
              textColor: "black",
              text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>",
              box: {
                boxBackColor: "white",
                boxShape: "squared",
              },
              background: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              id: "60136d99-0b09-404b-b0b8-18dbe144a437",
              image: {
                url: "https://dummyimage.com/273x172/D0E1E8/D0E1E8.png",
                description: null,
                type: "M",
                __typename: "Image",
              },
              __typename: "PortraitSlide",
            },
            {
              slideType: "PortraitSlide",
              textColor: "black",
              text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>",
              box: {
                boxBackColor: "white",
                boxShape: "squared",
              },
              background: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              id: "60136d99-0b09-404b-b0b8-18dbe144a438",
              image: {
                url: "https://dummyimage.com/273x172/D0E1E8/D0E1E8.png",
                description: null,
                type: "M",
                __typename: "Image",
              },
              __typename: "PortraitSlide",
            },
            {
              slideType: "PortraitSlide",
              textColor: "black",
              text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>",
              box: {
                boxBackColor: "white",
                boxShape: "squared",
              },
              background: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              id: "60136d99-0b09-404b-b0b8-18dbe144a439",
              image: {
                url: "https://dummyimage.com/273x172/D0E1E8/D0E1E8.png",
                description: null,
                type: "M",
                __typename: "Image",
              },
              __typename: "PortraitSlide",
            },
          ],
          quiz: [
            {
              id: "1be7dd51-2230-4bf8-88f9-d4b75259a38i",
              slideType: "QuizSlide",
              background: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              decor: "https://dummyimage.com/390x105/F5F0DA/F5F0DA.png",
              box: {
                boxBackColor: "white",
                boxShape: "squared",
              },
              textColor: "black",
              quizType: "quiz",
              question: "This is the question?",
              opt1: {
                text: "First option",
                image: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              },
              opt2: {
                text: "Second option",
                image: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              },
              opt3: {
                text: "Third option",
                image: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              },
              opt4: {
                text: "Fourth option",
                image: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              },
              correctAnswer: "opt3",
            },
          ],
          __typename: "EpisodeTranslation",
        },
        {
          id: "6c0c1300-5fac-430e-97c0-c6cb98523a03#it",
          lang: "it",
          stage: "PUBLISHED",
          title: "Episodio 01 Pubblicato",
          createdAt: "2022-07-04T13:38:31.926Z",
          updatedAt: "2022-07-04T13:38:31.926Z",
          slides: [
            {
              slideType: "TitleSlide",
              textColor: "white",
              background: "https://dummyimage.com/1080x1926/F5F0DA/F5F0DA.png",
              decor: "https://dummyimage.com/100x100/F6E4D9/F6E4D9.png",
              box: {
                boxShape: "squared",
                boxBackColor: "blue",
                __typename: "Box",
              },
              id: "ae29c11d-be61-4be8-aca2-11a31e6ef21b",
              title: "Episodio 01 Pubblicato",
              description: "Creare energia dal moto delle onde.",
              image: {
                type: "M",
                url: "https://dummyimage.com/328x260/D0E1E8/D0E1E8.png",
                description: null,
                __typename: "Image",
              },
              __typename: "TitleSlide",
            },
            {
              slideType: "FullTextSlide",
              textColor: "black",
              background: "https://dummyimage.com/1080x1926/F6E4D9/F6E4D9.png",
              decor: "https://dummyimage.com/390x105/F5F0DA/F5F0DA.png",
              box: {
                boxShape: "squared",
                boxBackColor: "white",
                __typename: "Box",
              },
              id: "e4317cec-e897-45d7-8f36-0b4f7bee5d7d",
              __typename: "FullTextSlide",
              text: "<p>E se dal <strong>moto ondoso</strong> delle onde si potesse <strong>creare energia</strong>?<br>Non è certo una novità. Le prime tecnologie risalgono a due secoli fa ma colui che seppe avere un approccio moderno al tema fu Yoshio Masuda (1925-2009).</p>",
            },
            {
              slideType: "TextImageSlide",
              textColor: "black",
              background: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              decor: null,
              box: null,
              id: "fa32faee-deb6-40be-8566-4b5eae4dfa1a",
              text: "<p>Il principio di funzionamento è semplicissimo: appena un’onda o marea salgono all’interno della colonna, l’aria viene spostata e spinta attraverso una turbina, creando energia elettrica.</p>",
              __typename: "TextImageSlide",
              image: {
                type: "S",
                url: "https://dummyimage.com/273x172/D0E1E8/D0E1E8.png",
                description: null,
                __typename: "Image",
              },
            },
          ],
          quiz: [
            {
              id: "1be7dd51-2230-4bf8-88f9-d4b75259a38u",
              slideType: "QuizSlide",
              background: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              decor: "https://dummyimage.com/390x105/F5F0DA/F5F0DA.png",
              box: {
                boxBackColor: "white",
                boxShape: "squared",
              },
              textColor: "black",
              quizType: "quiz",
              question: "Questa è la domanda",
              opt1: {
                text: "Prima opzione",
                image: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              },
              opt2: {
                text: "Seconda opzione",
                image: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              },
              opt3: {
                text: "Terza opzione",
                image: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              },
              opt4: {
                text: "Quarta opzione",
                image: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              },
              correctAnswer: "opt3",
            },
          ],
          __typename: "EpisodeTranslation",
        },
      ],
      __typename: "Episode",
    },
  },
}

export const stagedEpisodeMockData = {
  data: {
    stagedEpisode: {
      id: "6c0c1300-5fac-430e-97c0-c6cb98523a03",
      lang: "it",
      status: "ACTIVE",
      stage: "DRAFT",
      title: "Nuovo titolo italiano",
      text: "Testo dell'episodio 01",
      createdAt: "2022-07-04T13:45:40.352Z",
      updatedAt: "2022-07-20T16:27:45.380Z",
      image:
        "https://d1kmq5hop6362i.cloudfront.net/2022/07/titolo-di-test-d922c967d90a",
      topics: [
        {
          primary: true,
          topic: {
            id: "8128981a-44e5-4335-a640-fa9375ef1d81",
            createdAt: "2022-06-07T12:08:43.777Z",
            updatedAt: "2022-06-07T12:08:43.777Z",
            createdAtT: 1654603723777,
            updatedAtT: 1654603723777,
            name: "Agricolture",
            translations: [
              {
                lang: "en",
                name: "Agricolture",
                __typename: "TopicTranslation",
              },
              {
                lang: "it",
                name: "Agricoltura",
                __typename: "TopicTranslation",
              },
              {
                lang: "es",
                name: "Agricolture",
                __typename: "TopicTranslation",
              },
              {
                lang: "fr",
                name: "Agricolture",
                __typename: "TopicTranslation",
              },
              {
                lang: "de",
                name: "Agricolture",
                __typename: "TopicTranslation",
              },
            ],
            __typename: "Topic",
          },
          __typename: "TagRelation",
        },
        {
          primary: false,
          topic: {
            id: "bf560f5d-cec9-4382-a4f0-76f10fa230fc",
            createdAt: "2022-06-07T12:08:43.779Z",
            updatedAt: "2022-06-07T12:08:43.779Z",
            createdAtT: 1654603723779,
            updatedAtT: 1654603723779,
            name: "Circular Economy",
            translations: [
              {
                lang: "en",
                name: "Circular Economy",
                __typename: "TopicTranslation",
              },
              {
                lang: "it",
                name: "Economia circolare",
                __typename: "TopicTranslation",
              },
              {
                lang: "es",
                name: "Circular Economy",
                __typename: "TopicTranslation",
              },
              {
                lang: "fr",
                name: "Circular Economy",
                __typename: "TopicTranslation",
              },
              {
                lang: "de",
                name: "Circular Economy",
                __typename: "TopicTranslation",
              },
            ],
            __typename: "Topic",
          },
          __typename: "TagRelation",
        },
        {
          primary: false,
          topic: {
            id: "9ff6753c-92c4-4d2a-bbd3-89ca6852d459",
            createdAt: "2022-06-07T12:08:43.780Z",
            updatedAt: "2022-06-07T12:08:43.780Z",
            createdAtT: 1654603723780,
            updatedAtT: 1654603723780,
            name: "Climate Action",
            translations: [
              {
                lang: "en",
                name: "Climate Action",
                __typename: "TopicTranslation",
              },
              {
                lang: "it",
                name: "Azione climatica",
                __typename: "TopicTranslation",
              },
              {
                lang: "es",
                name: "Climate Action",
                __typename: "TopicTranslation",
              },
              {
                lang: "fr",
                name: "Climate Action",
                __typename: "TopicTranslation",
              },
              {
                lang: "de",
                name: "Climate Action",
                __typename: "TopicTranslation",
              },
            ],
            __typename: "Topic",
          },
          __typename: "TagRelation",
        },
      ],
      sdgs: [
        {
          primary: true,
          sdg: {
            id: "sdg#01",
            createdAt: "2022-05-26T08:53:27.735Z",
            updatedAt: "2022-05-26T08:53:27.735Z",
            createdAtT: 1653555207735,
            updatedAtT: 1653555207735,
            name: "No Poverty",
            image: "https://solar-cdn.aworld.link/sdgs/card-goal-01.png",
            translations: [
              {
                lang: "en",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-01.png",
                name: "No Poverty",
                __typename: "SdgTranslation",
              },
              {
                lang: "it",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-01.png",
                name: "No Poverty",
                __typename: "SdgTranslation",
              },
              {
                lang: "es",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-01.png",
                name: "No Poverty",
                __typename: "SdgTranslation",
              },
              {
                lang: "fr",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-01.png",
                name: "No Poverty",
                __typename: "SdgTranslation",
              },
              {
                lang: "de",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-01.png",
                name: "No Poverty",
                __typename: "SdgTranslation",
              },
            ],
            __typename: "Sdg",
          },
          __typename: "SdgRelation",
        },
        {
          primary: false,
          sdg: {
            id: "sdg#02",
            createdAt: "2022-05-26T08:53:27.737Z",
            updatedAt: "2022-05-26T08:53:27.737Z",
            createdAtT: 1653555207737,
            updatedAtT: 1653555207737,
            name: "Zero Hunger",
            image: "https://solar-cdn.aworld.link/sdgs/card-goal-02.png",
            translations: [
              {
                lang: "en",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-02.png",
                name: "Zero Hunger",
                __typename: "SdgTranslation",
              },
              {
                lang: "it",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-02.png",
                name: "Zero Hunger",
                __typename: "SdgTranslation",
              },
              {
                lang: "es",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-02.png",
                name: "Zero Hunger",
                __typename: "SdgTranslation",
              },
              {
                lang: "fr",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-02.png",
                name: "Zero Hunger",
                __typename: "SdgTranslation",
              },
              {
                lang: "de",
                image: "https://solar-cdn.aworld.link/sdgs/card-goal-02.png",
                name: "Zero Hunger",
                __typename: "SdgTranslation",
              },
            ],
            __typename: "Sdg",
          },
          __typename: "SdgRelation",
        },
      ],
      sdgTargets: [
        {
          primary: true,
          sdgTarget: {
            id: "tar#1.2",
            translations: [
              {
                lang: "it",
                description:
                  "Entro il 2030, ridurre almeno della metà la percentuale di uomini, donne e bambini di ogni età che vivono in povertà in tutte le sue dimensioni in base alle definizioni nazionali.",
                __typename: "SdgTargetTranslation",
              },
            ],
            __typename: "SdgTarget",
          },
          __typename: "SdgTargetRelation",
        },
        {
          primary: false,
          sdgTarget: {
            id: "tar#1.3",
            translations: [
              {
                lang: "it",
                description:
                  "Implementare sistemi a livello nazionale adeguati di protezione sociale e misure per tutti ed entro il 2030 per raggiungere la sostanziale copertura del numero di persone povere e vulnerabili.",
                __typename: "SdgTargetTranslation",
              },
            ],
            __typename: "SdgTarget",
          },
          __typename: "SdgTargetRelation",
        },
        {
          primary: false,
          sdgTarget: {
            id: "tar#1.4",
            translations: [
              {
                lang: "it",
                description:
                  "Entro il 2030, garantire che tutti gli uomini e le donne, in particolare i poveri e i vulnerabili, abbiano uguali diritti alle risorse economiche, così come all'accesso ai servizi di base, alla proprietà e controllo sulla terra e ad altre forme di proprietà, all’eredità, alle risorse naturali, ad appropriate tecnologie e anuovi servizi finanziari, tra cui la microfinanza.",
                __typename: "SdgTargetTranslation",
              },
            ],
            __typename: "SdgTarget",
          },
          __typename: "SdgTargetRelation",
        },
      ],
      translations: [
        {
          id: "6c0c1300-5fac-430e-97c0-c6cb98523a03#en#DRAFT",
          lang: "en",
          stage: "DRAFT",
          title: "English title",
          createdAt: "2022-07-20T16:27:13.230Z",
          updatedAt: "2022-07-20T16:27:13.230Z",
          slides: [
            {
              slideType: "TitleSlide",
              textColor: "white",
              background: "https://dummyimage.com/1080x1926/F5F0DA/F5F0DA.png",
              decor: "https://dummyimage.com/100x100/F6E4D9/F6E4D9.png",
              box: {
                boxShape: "squared",
                boxBackColor: "blue",
                __typename: "Box",
              },
              id: "74589494-9270-47e5-83bd-a14b17c1eec9",
              title: "English title",
              description: "Text for the english title",
              image: {
                type: "M",
                url: "https://dummyimage.com/328x260/D0E1E8/D0E1E8.png",
                description: null,
                __typename: "Image",
              },
              __typename: "TitleSlide",
            },
            {
              slideType: "FullTextSlide",
              textColor: "black",
              background: "https://dummyimage.com/1080x1926/F6E4D9/F6E4D9.png",
              decor: "https://dummyimage.com/390x105/F5F0DA/F5F0DA.png",
              box: {
                boxShape: "squared",
                boxBackColor: "white",
                __typename: "Box",
              },
              id: "1be7dd51-2230-4bf8-88f9-d4b75259a28f",
              __typename: "FullTextSlide",
              text: "<p>E se dal <strong>moto ondoso</strong> delle onde si potesse <strong>creare energia</strong>?<br>Non è certo una novità. Le prime tecnologie risalgono a due secoli fa ma colui che seppe avere un approccio moderno al tema fu Yoshio Masuda (1925-2009).</p>",
            },
            {
              slideType: "TextImageSlide",
              textColor: "black",
              background: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              decor: null,
              box: {
                boxBackColor: "white",
                boxShape: "squared",
              },
              id: "60136d99-0b09-404b-b0b8-18dbe144a433",
              text: "<p>Il principio di funzionamento è semplicissimo: appena un’onda o marea salgono all’interno della colonna, l’aria viene spostata e spinta attraverso una turbina.</p>",
              __typename: "TextImageSlide",
              image: {
                type: "S",
                url: "https://dummyimage.com/273x172/D0E1E8/D0E1E8.png",
                description: null,
                __typename: "Image",
              },
            },
            {
              slideType: "QuoteSlide",
              textColor: "black",
              background: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              decor: "https://dummyimage.com/390x105/F5F0DA/F5F0DA.png",
              box: {
                boxBackColor: "white",
                boxShape: "squared",
              },
              id: "60136d99-0b09-404b-b0b8-18dbe144a434",
              text: "<p>Credo che avere la terra e non rovinarla sia la più bella forma d’arte che si possa desiderare.</p>",
              __typename: "QuoteSlide",
              image: {
                url: "https://dummyimage.com/273x172/D0E1E8/D0E1E8.png",
                description: null,
                __typename: "Image",
              },
              author: "Andy Warhol",
              authorTitle: "Artista",
            },
            {
              slideType: "EmbedSlide",
              textColor: "black",
              background: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              id: "60136d99-0b09-404b-b0b8-18dbe144a435",
              text: "<p>Credo che avere la terra e non rovinarla sia la più bella forma</p>",
              captionTitle: "Titolo",
              caption: "Sottotitolo",
              embed: {
                url: "https://www.youtube.com/embed/0zuPnHfJk_8",
                __typename: "Embed",
              },
              __typename: "EmbedSlide",
            },
            {
              slideType: "FullImageSlide",
              textColor: "black",
              background: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              id: "60136d99-0b09-404b-b0b8-18dbe144a436",
              captionTitle: "Titolo",
              caption: "Sottotitolo",
              image: {
                url: "https://dummyimage.com/273x172/D0E1E8/D0E1E8.png",
                description: null,
                __typename: "Image",
              },
              __typename: "FullImageSlide",
            },
            {
              slideType: "PortraitSlide",
              textColor: "black",
              text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>",
              box: {
                boxBackColor: "white",
                boxShape: "squared",
              },
              background: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              id: "60136d99-0b09-404b-b0b8-18dbe144a437",
              image: {
                url: "https://dummyimage.com/273x172/D0E1E8/D0E1E8.png",
                description: null,
                type: "M",
                __typename: "Image",
              },
              __typename: "PortraitSlide",
            },
            {
              slideType: "PortraitSlide",
              textColor: "black",
              text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>",
              box: {
                boxBackColor: "white",
                boxShape: "squared",
              },
              background: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              id: "60136d99-0b09-404b-b0b8-18dbe144a438",
              image: {
                url: "https://dummyimage.com/273x172/D0E1E8/D0E1E8.png",
                description: null,
                type: "M",
                __typename: "Image",
              },
              __typename: "PortraitSlide",
            },
            {
              slideType: "PortraitSlide",
              textColor: "black",
              text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>",
              box: {
                boxBackColor: "white",
                boxShape: "squared",
              },
              background: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              id: "60136d99-0b09-404b-b0b8-18dbe144a439",
              image: {
                url: "https://dummyimage.com/273x172/D0E1E8/D0E1E8.png",
                description: null,
                type: "M",
                __typename: "Image",
              },
              __typename: "PortraitSlide",
            },
          ],
          quiz: [
            {
              id: "1be7dd51-2230-4bf8-88f9-d4b75259a38i",
              slideType: "QuizSlide",
              background: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              decor: "https://dummyimage.com/390x105/F5F0DA/F5F0DA.png",
              box: {
                boxBackColor: "white",
                boxShape: "squared",
              },
              textColor: "black",
              quizType: "quiz",
              question: "This is the question?",
              opt1: {
                text: "First option",
                image: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              },
              opt2: {
                text: "Second option",
                image: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              },
              opt3: {
                text: "Third option",
                image: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              },
              opt4: {
                text: "Fourth option",
                image: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              },
              correctAnswer: "opt3",
            },
            {
              id: "1be7dd51-2230-4bf8-88f9-d4b75259a38o",
              slideType: "QuizSlide",
              background: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              decor: "https://dummyimage.com/390x105/F5F0DA/F5F0DA.png",
              box: {
                boxBackColor: "white",
                boxShape: "squared",
              },
              textColor: "black",
              quizType: "quiz",
              question: "This is the question second slide?",
              opt1: {
                text: "First option",
                image: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              },
              opt2: {
                text: "Second option",
                image: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              },
              opt3: {
                text: "Third option",
                image: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              },
              opt4: {
                text: "Fourth option",
                image: "https://dummyimage.com/1080x1926/EAF4FD/EAF4FD.png",
              },
              correctAnswer: "opt3",
            },
          ],
          __typename: "EpisodeTranslation",
        },
        {
          id: "6c0c1300-5fac-430e-97c0-c6cb98523a03#it#DRAFT",
          lang: "it",
          stage: "DRAFT",
          title: "nuovo titolo italiano",
          createdAt: "2022-07-04T13:45:40.352Z",
          updatedAt: "2022-07-04T13:45:40.352Z",
          slides: [
            {
              slideType: "TitleSlide",
              textColor: "white",
              background: "https://dummyimage.com/1080x1926/F5F0DA/F5F0DA.png",
              decor: "https://dummyimage.com/100x100/F6E4D9/F6E4D9.png",
              box: {
                boxShape: "squared",
                boxBackColor: "blue",
                __typename: "Box",
              },
              id: "ae29c11d-be61-4be8-aca2-11a31e6ef21b",
              title: "nuovo titolo italiano",
              description: "Creare energia dal moto delle onde.",
              image: {
                type: "M",
                url: "https://dummyimage.com/328x260/D0E1E8/D0E1E8.png",
                description: null,
                __typename: "Image",
              },
              __typename: "TitleSlide",
            },
            {
              slideType: "FullTextSlide",
              textColor: "black",
              background: "https://dummyimage.com/1080x1926/F6E4D9/F6E4D9.png",
              decor: "https://dummyimage.com/390x105/F5F0DA/F5F0DA.png",
              box: {
                boxShape: "squared",
                boxBackColor: "white",
                __typename: "Box",
              },
              id: "e4317cec-e897-45d7-8f36-0b4f7bee5d7d",
              __typename: "FullTextSlide",
              text: "<p>E se dal <strong>moto ondoso</strong> delle onde si potesse <strong>creare energia</strong>?<br>Non è certo una novità. Le prime tecnologie risalgono a due secoli fa ma colui che seppe avere un approccio moderno al tema fu Yoshio Masuda (1925-2009).</p>",
            },
          ],
          quiz: [],
          __typename: "EpisodeTranslation",
        },
      ],
      __typename: "Episode",
    },
  },
}
