import { Chip, Stack, TableCell, TableRow, Typography } from "@mui/material"
import Team from "../../models/team"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import { useNavigate } from "react-router-dom"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import TranslationsStack from "../../components/global/translationsStack"
import {
  calculateTimeElapsed,
  capitalizeFirstCharacter,
  enumAsArray,
} from "../../services/utilities/utility"
import {
  TeamAccessType,
  TeamOrganizationType,
  TeamStage,
  TeamStakeholderType,
} from "../../services/config/enum"
import ChipSelect from "../../components/global/chipSelect"
import { TeamsContext } from "../../controllers/teams"
import PrimaryBadge from "../../components/global/primaryBadge"
import { PersonRounded } from "@mui/icons-material"
import CopyToClipboardButton from "../../components/global/copyToClipboardButton"

const TeamsListRow = ({
  item,
  selectedTeam,
  setSelectedTeam,
  selectedTeams,
  setSelectedTeams,
  forDialog,
}: {
  item: Team
  selectedTeam: Team
  setSelectedTeam: Dispatch<SetStateAction<Team>>
  selectedTeams: Team[]
  setSelectedTeams: Dispatch<SetStateAction<Team[]>>
  forDialog: boolean
}) => {
  const navigate = useNavigate()
  const { cmdPressed, setAnimation } = useContext(MainContext)
  const { upsertTeamParent } = useContext(TeamsContext)

  // row loading
  const [loading, setLoading] = useState<boolean>(false)

  // chip popover open
  const [chipOpen, setChipOpen] = useState<boolean>(false)

  return (
    <TableRow
      style={{
        cursor: loading ? "default" : "pointer",
        backgroundColor:
          (selectedTeam && selectedTeam.id === item.id) ||
          selectedTeams.filter((team: Team) => team.id === item.id)[0]
            ? "lightgray"
            : null,
        opacity: loading ? 0.4 : 1,
      }}
      hover={!loading}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
      onClick={() => {
        if (!loading && !chipOpen) {
          if (!forDialog) {
            if (cmdPressed) {
              window.open(`${window.location.href}/${item.id}`)
            } else {
              setAnimation(false)
              setTimeout(() => {
                navigate(`/teams/${item.id}`)
              }, 250)
            }
          } else if (setSelectedTeam) {
            setSelectedTeam(item)
          } else {
            if (selectedTeams.filter((team: Team) => team.id === item.id)[0]) {
              let indexToRemove = selectedTeams.findIndex(
                (team: Team) => team.id === item.id
              )
              selectedTeams.splice(indexToRemove, 1)
            } else {
              selectedTeams.push(item)
            }
            setSelectedTeams([...selectedTeams])
          }
        }
      }}
    >
      <TableCell>
        <div
          style={{
            width: 42,
            height: 42,
            borderRadius: 4,
            backgroundImage: `url(${
              item.document.items.find((doucmentItem) => doucmentItem.isDefault)
                .logo ?? noImagePlaceholder
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </TableCell>
      <TableCell>
        <Stack sx={{ gap: -5 }}>
          <Stack
            direction="row"
            alignItems="center"
            style={{ height: 22.5 }}
            gap={1}
          >
            <Typography
              style={{
                fontSize: 15,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: 320,
              }}
            >
              {item.name}
            </Typography>
            {item.isHomeFeatured && (
              <Chip
                size="small"
                color="info"
                label="Home"
                style={{
                  backgroundColor: "#5562B4",
                  height: 18,
                  fontSize: 11,
                  fontWeight: 600,
                }}
              />
            )}
          </Stack>
          <Stack direction="row" alignItems="center" style={{ marginLeft: -3 }}>
            <CopyToClipboardButton textToCopy={item.id} size="small" />
            <Typography
              variant="caption"
              style={{
                fontSize: 10,
                color: "gray",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {item.id}
            </Typography>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell>
        <Chip
          label={item.isPrimary ? "✔" : "✗"}
          size="small"
          color={item.isPrimary ? "success" : "default"}
          style={{ width: 28 }}
        />
      </TableCell>
      <TableCell>
        <TranslationsStack documentItems={item.document.items} />
      </TableCell>
      <TableCell>
        <Chip
          size="small"
          label={capitalizeFirstCharacter(item.stage)}
          color={
            item.stage === TeamStage.hidden
              ? "default"
              : item.stage === TeamStage.live
              ? "success"
              : item.stage === TeamStage.backstage
              ? "warning"
              : "info"
          }
        />
      </TableCell>
      <TableCell>
        <Chip
          label={
            item.accessType === TeamAccessType.sso
              ? "SSO"
              : item.accessType === TeamAccessType.activationCode
              ? "Code"
              : "Public"
          }
          size="small"
          color={
            item.accessType === TeamAccessType.sso
              ? "info"
              : item.accessType === TeamAccessType.activationCode
              ? "warning"
              : "success"
          }
        />
      </TableCell>
      <TableCell>
        <ChipSelect
          label={capitalizeFirstCharacter(item.stakeholderType)}
          options={enumAsArray(TeamStakeholderType).map((item) => {
            return {
              id: item as string,
              label: capitalizeFirstCharacter(item as string),
            }
          })}
          selectedOption={item.stakeholderType}
          setOpen={setChipOpen}
          onOptionClick={async (option) => {
            await upsertTeamParent({
              id: item.id,
              stakeholderType: option as TeamStakeholderType,
            })
            return true
          }}
          disabled={loading}
          setLoading={setLoading}
        />
      </TableCell>
      <TableCell>
        <ChipSelect
          label={capitalizeFirstCharacter(
            item.organizationType
              .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
              .toLowerCase()
          )}
          options={enumAsArray(TeamOrganizationType).map((item) => {
            return {
              id: item as string,
              label: capitalizeFirstCharacter(
                (item as string)
                  .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
                  .toLowerCase()
              ),
            }
          })}
          selectedOption={item.organizationType}
          setOpen={setChipOpen}
          onOptionClick={async (option) => {
            await upsertTeamParent({
              id: item.id,
              organizationType: option as TeamOrganizationType,
            })
            return true
          }}
          disabled={loading}
          setLoading={setLoading}
        />
      </TableCell>
      <TableCell>
        <PrimaryBadge
          count={item.userCount}
          icon={<PersonRounded style={{ fontSize: 12 }} />}
        />
      </TableCell>
      <TableCell style={{ minWidth: 115 }}>
        <Typography variant="caption">
          {calculateTimeElapsed(item.updatedAt)}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default TeamsListRow
