import {
  Autocomplete,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useContext } from "react"
import { MainContext } from "../../controllers/main"
import ImagePicker from "../global/imagePicker"
import { BadgesContext } from "../../controllers/badges"
import { TagsContext } from "../../controllers/tags"
import { renderOption, renderTags } from "../../services/utilities/utility"

interface AutocompleteOption {
  label: string
  id: string
}

const BadgeGeneralEdit = () => {
  const { showPadding } = useContext(MainContext)
  const { categoriesList, categoriesLoading } = useContext(TagsContext)
  const { currentBadge, setCurrentBadge, editMode } = useContext(BadgesContext)

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Configuration
        </Typography>
        <Autocomplete
          disabled={!editMode}
          loading={categoriesLoading}
          fullWidth
          blurOnSelect
          size="small"
          value={
            currentBadge.category
              ? {
                  id: currentBadge.category.id,
                  label: currentBadge.category.name,
                }
              : null
          }
          disablePortal
          isOptionEqualToValue={(
            option: AutocompleteOption,
            value: AutocompleteOption
          ) => option.id === value.id}
          options={categoriesList.map((item) => {
            return {
              id: item.id,
              label: item.name,
            }
          })}
          renderInput={(params) => <TextField {...params} label="Category" />}
          onChange={(e: any, newValue: AutocompleteOption | null) => {
            if (newValue) {
              currentBadge.category = categoriesList.filter(
                (item) => item.id === newValue.id
              )[0]
            } else {
              currentBadge.category = null
            }
            setCurrentBadge({ ...currentBadge })
          }}
          renderOption={renderOption}
          renderTags={renderTags}
        />
        <Typography variant="h6" className="card-title">
          Image
        </Typography>
        <ImagePicker
          title="Image"
          pickedImage={currentBadge.image ?? ""}
          setPickedImage={(selectedImage: {
            url: string
            id: string
            description?: string
          }) => {
            currentBadge.image = selectedImage.url
            setCurrentBadge({ ...currentBadge })
          }}
          filters={{ category: "actionGroupBadge" }}
          disableCategoryFilter
          height={200}
          disabled={!editMode}
        />
      </Stack>
    </CardContent>
  )
}

export default BadgeGeneralEdit
