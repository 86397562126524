import { LoadingButton } from "@mui/lab"
import {
  Button,
  CardActions,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Dialog,
  MenuItem,
  Select,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { DialogTransition } from "../../../services/utilities/utility"
import DialogTopBar from "../../global/dialogTopBar"
import LoadingBackdrop from "../../global/loadingBackdrop"
import { useNavigate } from "react-router-dom"
import { MainContext } from "../../../controllers/main"
import ImagePicker from "../../global/imagePicker"
import { LanguagesContext } from "../../../controllers/languages"
import { TagsContext } from "../../../controllers/tags"
import Sdg from "../../../models/sdg"

const CreateSdgDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
}) => {
  const { setAnimation } = useContext(MainContext)
  const { loading, setLoading, sdgsList, upsertSdg } = useContext(TagsContext)
  const { languages, languagesForEpisodeTabs } = useContext(LanguagesContext)
  const navigate = useNavigate()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // text
  const [name, setName] = useState<string>("")

  // image
  const [image, setImage] = useState<string>("")

  // document
  const [defaultTranslation, setDefaultTranslation] = useState<string>("")
  const [translationName, setTranslationName] = useState<string>("")
  const [translationImage, setTranslationImage] = useState<string>("")

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  // slides
  const slides = [
    {
      label: "Text",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Text
          </Typography>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
            size="small"
            onBlur={() => {
              setName(name.trim())
            }}
          />
        </Stack>
      ),
    },
    {
      label: "Image",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Image
          </Typography>
          <ImagePicker
            title="Image"
            pickedImage={image}
            setPickedImage={(selectedImage: {
              url: string
              id: string
              description?: string
            }) => {
              setImage(selectedImage.url)
            }}
            filters={{ category: "thumbnail" }}
            disableCategoryFilter
            height={200}
          />
        </Stack>
      ),
    },
    {
      label: "Language",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Default language
          </Typography>
          <FormControl fullWidth size="small">
            <InputLabel id="language-select">Default language</InputLabel>
            <Select
              labelId="language-select"
              label="Default language"
              value={defaultTranslation}
              onChange={(e) => {
                setDefaultTranslation(e.target.value)
              }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {lang.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      ),
    },
    {
      label: "Details",
      component: (
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h6" className="card-title">
              Details in{" "}
              {defaultTranslation.length
                ? languagesForEpisodeTabs.filter(
                    (item) => item.value === defaultTranslation
                  )[0].label
                : ""}
            </Typography>
            <Button
              size="small"
              variant="outlined"
              style={{ height: 26, fontSize: 12 }}
              onClick={() => {
                setTranslationName(name)
                setTranslationImage(image)
              }}
            >
              Copy from main
            </Button>
          </Stack>
          <TextField
            size="small"
            label="Name"
            value={translationName}
            onChange={(e) => {
              setTranslationName(e.target.value)
            }}
            onBlur={() => {
              if (translationName) {
                setTranslationName((current) => current.trim())
              }
            }}
          />
          <ImagePicker
            title="Image"
            pickedImage={translationImage}
            setPickedImage={(selectedImage: {
              url: string
              id: string
              description?: string
            }) => {
              setTranslationImage(selectedImage.url)
            }}
            filters={{ category: "thumbnail" }}
            disableCategoryFilter
            height={190}
          />
        </Stack>
      ),
    },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setName("")
        setImage("")
        setDefaultTranslation("")
        setTranslationName("")
        setCurrentSlide(0)
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (name) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [name])

  // create sdg
  const createNewSdg = async () => {
    setLoading(true)
    const input: {
      name: string
      image: string
      code: string
      translations: { name: string; image: string; lang: string }[]
    } = {
      name: name,
      image: image,
      code: (parseInt(sdgsList[sdgsList.length - 1].code) + 1).toString(),
      translations: [
        {
          name: translationName,
          image: translationImage,
          lang: defaultTranslation,
        },
      ],
    }

    const result = await upsertSdg(input)

    if (result !== false) {
      const newSdg = result as Sdg

      setDialogOpen(false)
      setAnimation(false)
      setTimeout(() => {
        navigate(`/tags/sdg/${newSdg.id}`)
      }, 250)
    } else {
      setLoading(false)
    }
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText={"Add SDG"}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            Add SDG
          </Typography>
          <Stepper
            activeStep={currentSlide}
            style={{
              paddingTop: 24,
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 8,
              maxWidth: "100%",
              overflow: "overlay",
            }}
          >
            {slides.map((slide) => {
              return (
                <Step key={slide.label}>
                  <StepLabel>{slide.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (!slides[currentSlide - 1]) {
                    if (showDiscardDialog) {
                      setDiscardDialogOpen(true)
                    } else {
                      handleDialogChange(e)
                    }
                  } else {
                    setCurrentSlide(currentSlide - 1)
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  (currentSlide === 0 && name.trim().length === 0) ||
                  (currentSlide === 1 && !image) ||
                  (currentSlide === 2 && !defaultTranslation) ||
                  (currentSlide === 3 && !translationName) ||
                  (currentSlide === 3 && !translationImage)
                }
                onMouseDown={
                  !slides[currentSlide + 1]
                    ? () => {
                        createNewSdg()
                      }
                    : () => {
                        setCurrentSlide(currentSlide + 1)
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Save" : "Next"}
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={loading} />
    </Dialog>
  )
}

export default CreateSdgDialog
