import { useContext, useEffect, useState } from "react"
import { EpisodesContext } from "../../controllers/episodes"
import EpisodeSlide from "../../models/episodeSlide"
import SlideConstraints from "../../models/slideConstraints"
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material"
import {
  checkTextColorOnBoxBackColorChange,
  onSlideBackgroundChange,
  onSlideBoxBackColorTextFieldChange,
  onSlideDecorChange,
  onSlideImageChange,
  onSlideTextColorTextFieldChange,
} from "../../services/utilities/episodeUtility"
import {
  capitalizeFirstCharacter,
  deepCopy,
} from "../../services/utilities/utility"
import HtmlEditor from "../global/htmlEditor"
import BigPreview from "../episode/bigPreview"
import ImagePicker from "../global/imagePicker"
import { MediaSize } from "../../services/config/enum"
import {
  boxBackColors,
  textColors,
} from "../../services/config/colorsForSlides"
import { Slide } from "@aworld/app-preview"

const TextImageSlide = ({
  slide,
  language,
  index,
  constraints,
  changesCancelled,
}: {
  slide: EpisodeSlide
  language: string
  index: number
  constraints: SlideConstraints
  changesCancelled: boolean
}) => {
  const {
    currentEpisode,
    setCurrentEpisode,
    slidesErrors,
    setSlidesErrors,
    editMode,
  } = useContext(EpisodesContext)

  // errors
  const [textError, setTextError] = useState<boolean>(false)
  const [textErrorType, setTextErrorType] = useState<string>("")
  const [imageError, setImageError] = useState<boolean>(false)

  // check errors
  const [isFirstRender, setisFirstRender] = useState<boolean>(true)

  useEffect(() => {
    if (isFirstRender) {
      setisFirstRender(false)

      // first errors check
      if (
        slide.text.replace(/(<([^>]+)>)/gi, "").length >
        constraints.text.maxSize
      ) {
        setTextError(true)
        setTextErrorType(
          "Maximum length is " + constraints.text.maxSize + " characters"
        )
      }
      if (
        slide.text.replace(/(<([^>]+)>)/gi, "").length <
        constraints.text.minSize
      ) {
        setTextError(true)
        setTextErrorType(
          "Minimum length is " + constraints.text.minSize + " characters"
        )
      }
      if (!slide.image.url.length) {
        setImageError(true)
      }

      return
    }

    let newSlidesErrors: {
      lang: string
      slides: { slideId: string; error: boolean }[]
    }[] = deepCopy(slidesErrors)

    if (!textError && !imageError) {
      newSlidesErrors
        .filter((item) => item.lang === language)[0]
        .slides.filter((item) => item.slideId === slide.id)[0].error = false
      setSlidesErrors(newSlidesErrors)
    } else {
      newSlidesErrors
        .filter((item) => item.lang === language)[0]
        .slides.filter((item) => item.slideId === slide.id)[0].error = true
      setSlidesErrors(newSlidesErrors)
    }
  }, [textError, imageError])

  // changes cancelled
  useEffect(() => {
    if (changesCancelled) {
      setTextError(false)
      setTextErrorType("")
      setImageError(false)
    }
  }, [changesCancelled])

  // big preview
  const [isBigPreviewOpen, setIsBigPreviewOpen] = useState<boolean>(false)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8}>
        <Stack spacing={2}>
          <Typography variant="h6" className="card-subtitle">
            Text
          </Typography>
          <HtmlEditor
            disabled={!editMode}
            slide={slide}
            constraints={constraints}
            currentEpisode={currentEpisode}
            setCurrentEpisode={setCurrentEpisode}
            slideLanguage={language}
            index={index}
            error={textError}
            setError={setTextError}
            errorType={textErrorType}
            setErrorType={setTextErrorType}
            changesCancelled={changesCancelled}
          />
          <FormControl fullWidth disabled={!editMode}>
            <InputLabel id="text-color-label-textImageSlide" size="small">
              Text color
            </InputLabel>
            <Select
              size="small"
              labelId="text-color-label-textImageSlide"
              value={slide.textColor ? slide.textColor : ""}
              label="Text color"
              onChange={(e: any) => {
                onSlideTextColorTextFieldChange(
                  currentEpisode,
                  setCurrentEpisode,
                  e.target.value,
                  language,
                  slide,
                  index
                )
              }}
            >
              {textColors.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="h6" className="card-subtitle">
            Background
          </Typography>
          <Stack spacing={2} direction="row">
            <ImagePicker
              filters={{
                category: "background",
              }}
              disabled={!editMode}
              title="Background"
              pickedImage={slide.background ? slide.background : ""}
              setPickedImage={(selectedImage: {
                url: string
                id: string
                description?: string
              }) => {
                onSlideBackgroundChange(
                  currentEpisode,
                  setCurrentEpisode,
                  selectedImage,
                  language,
                  slide,
                  index
                )
              }}
              width="50%"
            />
            <Stack style={{ width: "50%" }} spacing={2}>
              <FormControl fullWidth disabled>
                <InputLabel id="box-shape-label-textImageSlide" size="small">
                  Box shape
                </InputLabel>
                <Select
                  size="small"
                  labelId="box-shape-label-textImageSlide"
                  value="squared"
                  label="Box shape"
                >
                  {constraints.box.boxShape.map((item) => (
                    <MenuItem value={item} key={item}>
                      {capitalizeFirstCharacter(item)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth disabled={!editMode}>
                <InputLabel
                  id="box-back-color-label-textImageSlide"
                  size="small"
                >
                  Box back color
                </InputLabel>
                <Select
                  size="small"
                  labelId="box-back-color-label-textImageSlide"
                  value={
                    slide.box && slide.box.boxBackColor
                      ? slide.box.boxBackColor
                      : "none"
                  }
                  label="Box back color"
                  onChange={(e: any) => {
                    if (e.target.value === "none") {
                      onSlideBoxBackColorTextFieldChange(
                        currentEpisode,
                        setCurrentEpisode,
                        null,
                        language,
                        slide,
                        index
                      )
                    } else {
                      onSlideBoxBackColorTextFieldChange(
                        currentEpisode,
                        setCurrentEpisode,
                        e.target.value,
                        language,
                        slide,
                        index
                      )
                    }

                    checkTextColorOnBoxBackColorChange(
                      currentEpisode,
                      setCurrentEpisode,
                      e,
                      language,
                      slide,
                      index
                    )
                  }}
                >
                  <MenuItem value="none">None</MenuItem>
                  {boxBackColors.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
          <Typography variant="h6" className="card-subtitle">
            Images
          </Typography>
          <Stack direction="row" spacing={2}>
            <ImagePicker
              filters={{
                category: "decor",
              }}
              removeButton
              disabled={!editMode}
              title="Decor"
              pickedImage={slide.decor ? slide.decor : ""}
              setPickedImage={(selectedImage: {
                url: string
                id: string
                description?: string
              }) => {
                onSlideDecorChange(
                  currentEpisode,
                  setCurrentEpisode,
                  selectedImage,
                  language,
                  slide,
                  index
                )
              }}
              width="50%"
            />
            <ImagePicker
              error={imageError}
              filters={{
                category: "content",
                size: [MediaSize.s, MediaSize.m, MediaSize.l, MediaSize.xl],
              }}
              disabled={!editMode}
              title="Image"
              pickedImage={
                slide.image && slide.image.url ? slide.image.url : ""
              }
              setPickedImage={(selectedImage: {
                url: string
                id: string
                description?: string
                type?: MediaSize
              }) => {
                onSlideImageChange(
                  currentEpisode,
                  setCurrentEpisode,
                  selectedImage,
                  language,
                  slide,
                  index
                )
                setImageError(false)
              }}
              width="50%"
            />
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Stack spacing={2}>
          <Typography variant="h6" className="card-subtitle">
            Preview
          </Typography>
          <div
            style={{
              zoom: 0.6,
              marginTop: 30,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              maxHeight: "100%",
              cursor: "pointer",
              opacity: isBigPreviewOpen ? 0 : 1,
              transition: "200ms",
            }}
            onClick={() => {
              setIsBigPreviewOpen(true)
            }}
          >
            <Slide
              slide={slide}
              constraints={constraints}
              className="preview"
              primaryTopicName={
                currentEpisode.topics.find((topic) => topic.primary)
                  ? currentEpisode.topics.find((topic) => topic.primary).topic
                      .name
                  : null
              }
              episodeTitle={
                currentEpisode.translations.find(
                  (item) => item.lang === language
                ).title ?? null
              }
              numberOfSlides={
                currentEpisode.translations.find(
                  (item) => item.lang === language
                ).slides.length
              }
              numberOfQuiz={
                currentEpisode.translations.find(
                  (item) => item.lang === language
                ).quiz.length
              }
              currentSlide={index}
            />
          </div>
        </Stack>
      </Grid>
      <BigPreview
        isBigPreviewOpen={isBigPreviewOpen}
        setIsBigPreviewOpen={setIsBigPreviewOpen}
      >
        <div
          style={{
            zoom: window.innerHeight / 950,
            marginTop: 70,
          }}
        >
          <Slide
            slide={slide}
            constraints={constraints}
            className="preview"
            primaryTopicName={
              currentEpisode.topics.find((topic) => topic.primary)
                ? currentEpisode.topics.find((topic) => topic.primary).topic
                    .name
                : null
            }
            episodeTitle={
              currentEpisode.translations.find((item) => item.lang === language)
                .title ?? null
            }
            numberOfSlides={
              currentEpisode.translations.find((item) => item.lang === language)
                .slides.length
            }
            numberOfQuiz={
              currentEpisode.translations.find((item) => item.lang === language)
                .quiz.length
            }
            currentSlide={index}
          />
        </div>
      </BigPreview>
    </Grid>
  )
}

export default TextImageSlide
