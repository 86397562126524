import { ChevronLeftRounded } from "@mui/icons-material"
import { Button, CircularProgress, Grow } from "@mui/material"

const PageSpinner = ({ showBackButton = false, backButtonOnClick = null }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 120px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      {showBackButton && (
        <Grow in={true} timeout={300} style={{ marginTop: 16, marginLeft: 16 }}>
          <Button
            variant="outlined"
            style={{ position: "absolute", top: 0, left: 0 }}
            onClick={backButtonOnClick}
          >
            <ChevronLeftRounded />
          </Button>
        </Grow>
      )}
      <CircularProgress />
    </div>
  )
}

export default PageSpinner
