import { Card, CardContent, Grow, Stack, Typography } from "@mui/material"
import EditContainer from "../../components/global/editContainer"
import { useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import FileDropzone from "../../components/global/fileDropzone"
import EditHeadingFade from "../../components/global/editHeadingFade"
import { TranslationsContext } from "../../controllers/translations"
import LoadingBackdrop from "../../components/global/loadingBackdrop"
import { JobStatus } from "../../services/config/enum"
import {
  AccessTimeRounded,
  CheckCircleOutlineRounded,
  ErrorOutlineRounded,
  SyncDisabledRounded,
  SyncRounded,
} from "@mui/icons-material"

const ImportEdit = () => {
  const { animation, showPadding } = useContext(MainContext)
  const { importTranslations, currentJobStatus, currentJobError, jobRunning } =
    useContext(TranslationsContext)

  // loading
  const [loading, setLoading] = useState<boolean>(false)

  // dropped file
  const [droppedFile, setDroppedFile] = useState<{
    name: string
    size: number
    type: string
    dataUrl: string
  } | null>(null)

  return (
    <EditContainer>
      <EditHeadingFade
        showBackButton={false}
        title="Import translations from file"
        showImportButton
        importButtonDisabled={jobRunning || !droppedFile}
        importButtonOnClick={async () => {
          setLoading(true)

          console.log("file", droppedFile)
          await importTranslations(
            { contentType: droppedFile.type, fileName: droppedFile.name },
            droppedFile.dataUrl
          )

          setDroppedFile(null)
          setLoading(false)
        }}
      />
      <Grow
        in={animation}
        timeout={300}
        style={{ margin: showPadding === "yes" ? 16 : 0, marginTop: 0 }}
      >
        <Stack spacing={2}>
          <Card>
            <CardContent style={{ height: "100%" }}>
              <Stack spacing={2}>
                <Typography variant="h6" className="card-title">
                  Current import status
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {!currentJobStatus ? (
                    <SyncDisabledRounded style={{ fontSize: 14 }} />
                  ) : currentJobStatus === JobStatus.PENDING ? (
                    <AccessTimeRounded
                      style={{ fontSize: 14, color: "#f7b84a" }}
                    />
                  ) : currentJobStatus === JobStatus.RUNNING ? (
                    <SyncRounded style={{ fontSize: 14, color: "#5562B4" }} />
                  ) : currentJobStatus === JobStatus.COMPLETED ? (
                    <CheckCircleOutlineRounded
                      style={{ fontSize: 14, color: "#34cf77" }}
                    />
                  ) : (
                    <ErrorOutlineRounded
                      style={{ fontSize: 14, color: "#bf1f17" }}
                    />
                  )}
                  <Typography
                    variant="overline"
                    sx={{
                      fontWeight: !currentJobStatus ? 400 : 700,
                      color: !currentJobStatus
                        ? undefined
                        : currentJobStatus === JobStatus.PENDING
                        ? "#f7b84a"
                        : currentJobStatus === JobStatus.RUNNING
                        ? "#5562B4"
                        : currentJobStatus === JobStatus.COMPLETED
                        ? "#34cf77"
                        : "#bf1f17",
                    }}
                  >
                    {!currentJobStatus
                      ? "No import running"
                      : currentJobStatus === JobStatus.ERROR && currentJobError
                      ? "ERROR - " + currentJobError.toUpperCase()
                      : currentJobStatus}
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
          <Card
            style={{
              height:
                showPadding === "yes"
                  ? "calc(100vh - 275px)"
                  : "calc(100vh - 259px)",
            }}
          >
            <CardContent style={{ height: "100%" }}>
              <Stack spacing={2} style={{ height: "100%" }}>
                <Typography variant="h6" className="card-title">
                  File
                </Typography>
                <FileDropzone
                  title="File"
                  droppedFile={droppedFile}
                  setDroppedFile={setDroppedFile}
                  height="100%"
                  disabled={jobRunning}
                />
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Grow>
      <LoadingBackdrop open={loading} />
    </EditContainer>
  )
}

export default ImportEdit
