import {
  ButtonBase,
  Chip,
  LinearProgress,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import ListContainer from "../../components/global/listContainer"
import ListTable from "../../components/global/listTable"
import TableSpinner from "../../components/global/tableSpinner"
import { MainContext } from "../../controllers/main"
import {
  calculateTimeElapsed,
  capitalizeFirstCharacter,
  registerAnalyticsEvent,
} from "../../services/utilities/utility"
import TitleTableCell from "../../components/global/titleTableCell"
import TranslationsStack from "../../components/global/translationsStack"
import ProductsListTopBar from "../../components/marketplace/productsListTopBar"
import { MarketplaceContext } from "../../controllers/marketplace"
import Product from "../../models/product"
import {
  ProductAvailability,
  ProductVisibility,
} from "../../services/config/enum"
import PrimaryBadge from "../../components/global/primaryBadge"
import {
  Inventory2Rounded,
  LocalGroceryStoreRounded,
  OpenInNewRounded,
} from "@mui/icons-material"
import CreateProductDialog from "../../components/marketplace/createProductDialog"

const ProductsList = () => {
  const { setAnimation, showPadding, cmdPressed } = useContext(MainContext)
  const {
    loading,
    productsList,
    productsListNextToken,
    getProductsList,
    searchProductsList,
    loadMoreProducts,
    hasSearched,
    updatingList,
    setUpdatingList,
    searchTitle,
    setSearchTitle,
    searchLang,
    searchStage,
    searchVisibility,
  } = useContext(MarketplaceContext)
  const navigate = useNavigate()

  // list background update
  const backgroundUpdate = () => {
    if (!productsList.length) {
      getProductsList()
    } else if (!loading && !hasSearched) {
      setUpdatingList(true)
      getProductsList(false)
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    backgroundUpdate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create product dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // load more button loading
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // register analytics event
  useEffect(() => {
    registerAnalyticsEvent({ type: "page_view", name: "products_list" })
  }, [])

  return (
    <ListContainer style={{ position: "relative" }}>
      {updatingList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 143,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <ProductsListTopBar
        loading={loading}
        textFieldValue={searchTitle}
        textFieldPlaceholder="Search Products"
        addButtonLabel="Add Product"
        textFieldOnChange={(e) => {
          setSearchTitle(e.target.value)
          if (e.target.value.length === 0 && hasSearched) {
            setUpdatingList(true)

            if (!searchLang && !searchStage && !searchVisibility) {
              getProductsList(false)
            } else {
              searchProductsList()
            }
          }
        }}
        textFieldOnKeyDown={(e) => {
          if (e.key === "Enter" && searchTitle.length > 2) {
            searchProductsList()
          }
        }}
        cancelButtonOnClick={() => {
          setSearchTitle("")
          if (hasSearched) {
            setUpdatingList(true)

            if (!searchLang && !searchStage && !searchVisibility) {
              getProductsList(false)
            } else {
              searchProductsList()
            }
          }
        }}
        searchButtonOnClick={() => {
          searchProductsList()
        }}
        addButtonOnClick={handleDialogChange}
      />
      {loading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
          headingItems={[
            "Stage",
            "Image",
            "Title & ID",
            "Translations",
            "Availability",
            "Visibility",
            "Bought",
            "Inventory",
            "Team",
            "Updated",
          ]}
          nextToken={productsListNextToken}
          loadingMoreItems={loadMoreLoading}
          setLoadingMoreItems={setLoadMoreLoading}
          loadMore={
            !searchTitle.length &&
            !searchLang &&
            !searchStage &&
            !searchVisibility
              ? loadMoreProducts
              : async () => {
                  const result = await searchProductsList(false, true)
                  return result
                }
          }
          tableBody={productsList.map((item: Product) => (
            <TableRow
              key={item.id}
              hover
              style={{
                cursor: "pointer",
              }}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
              onClick={() => {
                if (cmdPressed) {
                  window.open(`${window.location.href}/${item.id}`)
                } else {
                  setAnimation(false)
                  setTimeout(() => {
                    navigate(`/marketplace/${item.id}`)
                  }, 250)
                }
              }}
            >
              <TableCell style={{ position: "relative" }}>
                {item.stage === "PUBLISHED" || item.hasSibling ? (
                  <Chip
                    size="small"
                    label="Live"
                    color="success"
                    style={{ position: "relative", zIndex: 1 }}
                  />
                ) : item.stage === "ARCHIVED" ? (
                  <Chip
                    size="small"
                    label="Archived"
                    style={{ position: "relative", zIndex: 1 }}
                  />
                ) : (
                  <Chip
                    size="small"
                    label="Draft"
                    color="warning"
                    style={{ position: "relative", zIndex: 1 }}
                  />
                )}
                {item.hasSibling ? (
                  <Chip
                    size="small"
                    label="Draft"
                    color="warning"
                    style={{ position: "absolute", left: 18 }}
                  />
                ) : null}
              </TableCell>
              <TableCell>
                <div
                  style={{
                    width: 42,
                    height: 42,
                    borderRadius: 4,
                    backgroundImage: `url(${item.image ?? noImagePlaceholder})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </TableCell>
              <TitleTableCell
                title={
                  item.document.items.filter(
                    (documentItem) => documentItem.isDefault
                  )[0].title
                }
                id={item.id}
              />
              <TableCell>
                <TranslationsStack documentItems={item.document.items} />
              </TableCell>
              <TableCell>
                <Chip
                  label={item.availability}
                  size="small"
                  color={
                    item.availability === ProductAvailability.Limited
                      ? "warning"
                      : "success"
                  }
                />
              </TableCell>
              <TableCell>
                <Chip
                  label={capitalizeFirstCharacter(
                    item.visibility
                      .split(/(?<=[a-z])(?=[A-Z])/)
                      .join(" ")
                      .toLowerCase()
                  )}
                  size="small"
                  color={
                    item.visibility === ProductVisibility.Hidden
                      ? "warning"
                      : "success"
                  }
                />
              </TableCell>
              <TableCell>
                <PrimaryBadge
                  count={item.boughtCount}
                  icon={<LocalGroceryStoreRounded style={{ fontSize: 12 }} />}
                />
              </TableCell>
              <TableCell>
                <PrimaryBadge
                  count={
                    !item.inventoryCount &&
                    item.availability === ProductAvailability.Limited
                      ? 0
                      : item.inventoryCount ?? "∞"
                  }
                  icon={<Inventory2Rounded style={{ fontSize: 12 }} />}
                />
              </TableCell>
              <TableCell>
                <ButtonBase
                  disableRipple
                  disabled={item.team.id === "team_default"}
                  onClick={() => {
                    window.open(`/teams/${item.team.id}`)
                  }}
                >
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Typography
                      variant="caption"
                      style={{
                        opacity: item.team.id === "team_default" ? 0.2 : 1,
                      }}
                    >
                      {item.team.id === "team_default"
                        ? "none"
                        : item.team.document.items.find(
                            (item) => item.isDefault
                          ).title}
                    </Typography>
                    {item.team.id !== "team_default" && (
                      <OpenInNewRounded
                        style={{ fontSize: 15, opacity: 0.9 }}
                      />
                    )}
                  </Stack>
                </ButtonBase>
              </TableCell>
              <TableCell style={{ minWidth: 120 }}>
                <Typography variant="caption">
                  {calculateTimeElapsed(item.updatedAt)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        />
      )}
      <CreateProductDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
      />
    </ListContainer>
  )
}

export default ProductsList
