import { BadgeType } from "../services/config/enum"
import Category from "./category"
import Document from "./document"

class Badge {
  id?: string
  updatedAt?: string
  image?: string
  badgeType?: BadgeType
  document?: { items: Document[]; parentId: string }
  category?: Category

  constructor(
    id?: string,
    updatedAt?: string,
    image?: string,
    badgeType?: BadgeType,
    document?: { items: Document[]; parentId: string },
    category?: Category
  ) {
    this.id = id
    this.updatedAt = updatedAt
    this.image = image
    this.badgeType = badgeType
    this.document = document
    this.category = category
  }
}

export default Badge
