import { forwardRef, useCallback } from "react"
import { AdvancedType } from "easy-email-core"
import {
  EmailEditor,
  EmailEditorProvider,
  EmailEditorProviderProps,
  IEmailTemplate,
} from "easy-email-editor"
import { ExtensionProps, StandardLayout } from "easy-email-extensions"
import { useWindowSize } from "react-use"
import { Spin } from "@arco-design/web-react"
import Handlebars from "handlebars"
import "easy-email-editor/lib/style.css"
import "easy-email-extensions/lib/style.css"
import "@arco-themes/react-easy-email-theme/css/arco.css"
import { Config } from "final-form"
import { Button } from "@mui/material"
import "../../styles/mjmlEditor.scss"

const defaultCategories: ExtensionProps["categories"] = [
  {
    label: "Content",
    active: true,
    blocks: [
      {
        type: AdvancedType.TEXT,
      },
      {
        type: AdvancedType.IMAGE,
        payload: { attributes: { padding: "0px 0px 0px 0px" } },
      },
      {
        type: AdvancedType.BUTTON,
      },
      {
        type: AdvancedType.SOCIAL,
      },
      {
        type: AdvancedType.DIVIDER,
      },
      {
        type: AdvancedType.SPACER,
      },
      {
        type: AdvancedType.HERO,
      },
      {
        type: AdvancedType.WRAPPER,
      },
      {
        type: AdvancedType.CAROUSEL,
      },
    ],
  },
  {
    label: "Layout",
    active: true,
    displayType: "column",
    blocks: [
      {
        title: "2 columns",
        payload: [
          ["50%", "50%"],
          ["33%", "67%"],
          ["67%", "33%"],
          ["25%", "75%"],
          ["75%", "25%"],
        ],
      },
      {
        title: "3 columns",
        payload: [
          ["33.33%", "33.33%", "33.33%"],
          ["25%", "25%", "50%"],
          ["50%", "25%", "25%"],
        ],
      },
      {
        title: "4 columns",
        payload: [["25%", "25%", "25%", "25%"]],
      },
    ],
  },
]

type MjmlEditorProps = {
  template: IEmailTemplate
  dataJson: JSON
  onSubmit?: (values: IEmailTemplate) => void
  height?: string
}

const MjmlEditor = forwardRef<HTMLButtonElement, MjmlEditorProps>(
  ({ template, dataJson, onSubmit, height = "90vh" }, ref) => {
    const { width } = useWindowSize()

    const smallScene = width < 1400

    const onBeforePreview: EmailEditorProviderProps["onBeforePreview"] =
      useCallback(
        (html: string, mergeTags: Record<string, any> | undefined) => {
          const template = Handlebars.compile(html, { strict: true })
          return template(mergeTags)
        },
        []
      )

    const onSubmitLocal: Config<IEmailTemplate>["onSubmit"] = (values) => {
      onSubmit(values)
    }

    if (!template) return <Spin />

    return (
      <EmailEditorProvider
        data={template}
        height={height}
        autoComplete
        dashed={false}
        onSubmit={onSubmitLocal}
        mergeTags={dataJson}
        onBeforePreview={onBeforePreview}
      >
        {({ values }, { submit }) => {
          return (
            <StandardLayout
              compact={!smallScene}
              categories={defaultCategories}
            >
              <EmailEditor />
              <Button ref={ref} style={{ display: "none" }} onClick={submit} />
            </StandardLayout>
          )
        }}
      </EmailEditorProvider>
    )
  }
)

export default MjmlEditor
