import { LoadingButton } from "@mui/lab"
import {
  Button,
  CardActions,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Dialog,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { DialogTransition } from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import { useNavigate } from "react-router-dom"
import { MainContext } from "../../controllers/main"
import ImagePicker from "../global/imagePicker"
import { NftsContext } from "../../controllers/nfts"
import ISODatePicker from "../global/isoDatePicker"
import NftAttribute from "../../models/nftAttribute"
import { AddCircleRounded } from "@mui/icons-material"
import AttributeCardCreate from "./attributeCardCreate"

const CreateNftCatalogDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
}) => {
  const { setAnimation } = useContext(MainContext)
  const { loading, setLoading, createNftCatalog } = useContext(NftsContext)
  const navigate = useNavigate()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // configuration
  const [name, setName] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [mintAt, setMintAt] = useState<string | null>(null)
  const [symbol, setSymbol] = useState<string>("")
  const [kind, setKind] = useState<string>("")
  const [projectId, setProjectId] = useState<string>("")
  const [sourceId, setSourceId] = useState<string>("")
  const [sourceKind, setSourceKind] = useState<string>("")

  // image
  const [image, setImage] = useState<string>("")

  // attributes
  const [attributes, setAttributes] = useState<NftAttribute[]>([])

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  // slides
  const slides = [
    {
      label: "Configuration",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Configuration
          </Typography>
          <Stack direction="row" gap={2}>
            <TextField
              fullWidth
              size="small"
              label="Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
            <TextField
              fullWidth
              size="small"
              label="Description"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value)
              }}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <ISODatePicker
              label="Mint date"
              date={mintAt}
              setDate={(newDate: string) => {
                setMintAt(newDate)
              }}
              hour={12}
            />
            <TextField
              fullWidth
              size="small"
              label="Symbol"
              value={symbol}
              onChange={(e) => {
                setSymbol(e.target.value)
              }}
            />
            <TextField
              fullWidth
              size="small"
              label="Kind"
              value={kind}
              onChange={(e) => {
                setKind(e.target.value)
              }}
            />
          </Stack>
          <TextField
            fullWidth
            size="small"
            label="Project ID"
            value={projectId}
            onChange={(e) => {
              setProjectId(e.target.value)
            }}
          />
          <Stack direction="row" gap={2}>
            <TextField
              fullWidth
              size="small"
              label="Source ID"
              value={sourceId}
              onChange={(e) => {
                setSourceId(e.target.value)
              }}
            />
            <TextField
              fullWidth
              size="small"
              label="Source kind"
              value={sourceKind}
              onChange={(e) => {
                setSourceKind(e.target.value)
              }}
            />
          </Stack>
        </Stack>
      ),
    },
    {
      label: "Image",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Image
          </Typography>
          <ImagePicker
            title="Image"
            pickedImage={image}
            setPickedImage={(selectedImage: {
              url: string
              id: string
              description?: string
            }) => {
              setImage(selectedImage.url)
            }}
            filters={{ category: "nft" }}
            disableCategoryFilter
            height={200}
          />
        </Stack>
      ),
    },
    {
      label: "Attributes",
      component: (
        <Stack spacing={2} style={{ maxHeight: 290, overflowY: "scroll" }}>
          <Typography variant="h6" className="card-title">
            Attributes
          </Typography>
          {attributes.length ? (
            <Stack>
              {attributes.map((attribute, index) => (
                <AttributeCardCreate
                  key={index}
                  index={index}
                  attribute={attribute}
                  attributes={attributes}
                  setAttributes={setAttributes}
                />
              ))}
            </Stack>
          ) : null}
          <Button
            fullWidth
            endIcon={<AddCircleRounded />}
            variant="outlined"
            style={{
              maxHeight: 40,
              minHeight: 40,
            }}
            onClick={() => {
              setAttributes([
                ...attributes,
                {
                  trait_type: "",
                  value: "",
                },
              ])
            }}
          >
            Add attribute
          </Button>
        </Stack>
      ),
    },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setName("")
        setDescription("")
        setMintAt(null)
        setSymbol("")
        setKind("")
        setProjectId("")
        setSourceId("")
        setSourceKind("")
        setImage("")
        setAttributes([])
        setCurrentSlide(0)
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (
      name ||
      description ||
      mintAt ||
      symbol ||
      kind ||
      projectId ||
      sourceId ||
      sourceKind
    ) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [name, description, mintAt, symbol, kind, projectId, sourceId, sourceKind])

  // create nft catalog
  const createNewNftCatalog = async () => {
    setLoading(true)
    const input: {
      name: string
      description: string
      mintAt: string
      symbol: string
      image: string
      kind: string
      projectId: string
      sourceId: string
      sourceKind: string
      attributes: NftAttribute[]
    } = {
      name: name,
      description: description,
      mintAt: mintAt,
      symbol: symbol,
      image: image,
      kind: kind,
      projectId: projectId,
      sourceId: sourceId,
      sourceKind: sourceKind,
      attributes: attributes,
    }

    const result = await createNftCatalog(input)

    if (result !== false) {
      const newNftCatalogId = result as string
      setDialogOpen(false)
      setAnimation(false)
      setTimeout(() => {
        navigate(`/nftcatalogs/${newNftCatalogId}`)
      }, 250)
    } else {
      setLoading(false)
    }
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText="Add NFT catalog"
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            Add NFT catalog
          </Typography>
          <Stepper
            activeStep={currentSlide}
            style={{
              paddingTop: 24,
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 8,
              maxWidth: "100%",
              overflow: "overlay",
            }}
          >
            {slides.map((slide) => {
              return (
                <Step key={slide.label}>
                  <StepLabel>{slide.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (!slides[currentSlide - 1]) {
                    if (showDiscardDialog) {
                      setDiscardDialogOpen(true)
                    } else {
                      handleDialogChange(e)
                    }
                  } else {
                    setCurrentSlide(currentSlide - 1)
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  (currentSlide === 0 && !name) ||
                  (currentSlide === 0 && !description) ||
                  (currentSlide === 0 && !mintAt) ||
                  (currentSlide === 0 &&
                    mintAt &&
                    new Date(mintAt) <= new Date()) ||
                  (currentSlide === 0 && !symbol) ||
                  (currentSlide === 0 && !kind) ||
                  (currentSlide === 0 && !projectId) ||
                  (currentSlide === 0 && !sourceId) ||
                  (currentSlide === 0 && !sourceKind) ||
                  (currentSlide === 1 && !image) ||
                  (currentSlide === 2 && !attributes.length) ||
                  (currentSlide === 2 &&
                    attributes.length &&
                    attributes.some((item) => !item.trait_type || !item.value))
                }
                onMouseDown={
                  !slides[currentSlide + 1]
                    ? () => {
                        createNewNftCatalog()
                      }
                    : () => {
                        setCurrentSlide(currentSlide + 1)
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Save" : "Next"}
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={loading} />
    </Dialog>
  )
}

export default CreateNftCatalogDialog
