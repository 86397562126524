import {
  Dispatch,
  MouseEvent,
  MouseEventHandler,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import {
  Button,
  ButtonBase,
  ButtonGroup,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grow,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import {
  ArrowDropDownRounded,
  ArrowDropUpRounded,
  ChevronLeftRounded,
  InfoRounded,
  UndoRounded,
} from "@mui/icons-material"
import { MainContext } from "../../controllers/main"
import { logger, Status } from "../../services/utilities/utility"
import CopyToClipboardButton from "./copyToClipboardButton"
import { CSVLink } from "react-csv"
import LoadingBackdrop from "./loadingBackdrop"
import {
  NotificationTemplateValidationStatus,
  Stage,
} from "../../services/config/enum"

const EditHeadingWithStages = ({
  backButtonOnClick,
  title,
  id,
  saveButtonDisabled,
  undoButtonDisabled,
  saveButtonOnClick,
  undoButtonOnClick,
  saveButtonLoading = false,
  showArchiveButton = false,
  archiveButtonOnClick,
  editModeActive = false,
  setEditModeActive,
  doneChanges,
  showDeleteButton = false,
  deleteButtonOnClick,
  showCsvButton = false,
  csvButtonOnClick,
  csvFileName,
  csvButtonType = "default",
  showAddButton = false,
  addButtonLabel,
  addButtonOnClick,
  showBadge = false,
  badgeLabel,
  stage,
  showValidationStatus,
  validationStatus,
  validationInfoOnClick,
  showSuspendButton = false,
  isSuspended,
  suspendButtonOnClick,
}: {
  backButtonOnClick?: MouseEventHandler<HTMLButtonElement>
  title: string
  id?: string
  saveButtonDisabled?: boolean
  undoButtonDisabled?: boolean
  saveButtonOnClick?: MouseEventHandler<HTMLButtonElement>
  undoButtonOnClick?: MouseEventHandler<HTMLButtonElement>
  saveButtonLoading?: boolean
  showArchiveButton?: boolean
  archiveButtonOnClick?: MouseEventHandler<HTMLButtonElement>
  editModeActive?: boolean
  setEditModeActive?: Dispatch<SetStateAction<boolean>>
  doneChanges?: boolean
  showDeleteButton?: boolean
  deleteButtonOnClick?: MouseEventHandler<HTMLButtonElement>
  showCsvButton?: boolean
  csvButtonOnClick?: (withEmails?: boolean) => Promise<string[][]>
  csvFileName?: string
  csvButtonType?: "default" | "dropdown"
  showAddButton?: boolean
  addButtonLabel?: string
  addButtonOnClick?: () => void
  showBadge?: boolean
  badgeLabel?: string
  stage: Stage
  showValidationStatus?: boolean
  validationStatus?: NotificationTemplateValidationStatus
  validationInfoOnClick?: () => void
  showSuspendButton?: boolean
  isSuspended?: boolean
  suspendButtonOnClick?: () => void
}) => {
  const { animation } = useContext(MainContext)

  // undo dialog
  const [undoDialogOpen, setUndoDialogOpen] = useState<boolean>(false)

  // csv download button
  const csvButtonRef = useRef(null)
  const [csvData, setCsvData] = useState<string[][]>([])
  const [csvLoading, setCsvLoading] = useState<boolean>(false)

  useEffect(() => {
    if (csvData.length && !csvLoading) {
      csvButtonRef.current.link.click()
    }
  }, [csvData])

  // csv button dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Grow
      in={animation}
      timeout={250}
      style={{ marginTop: 16, paddingLeft: 16, paddingRight: 16 }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{ maxHeight: 38 }}
      >
        <Button variant="outlined" onClick={backButtonOnClick}>
          <ChevronLeftRounded />
        </Button>
        <Stack
          style={{ width: "-webkit-fill-available", overflow: "hidden" }}
          spacing={-0.5}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography
              variant="h6"
              style={{
                width: showBadge ? "auto" : "100%",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                lineHeight: 1.5,
              }}
            >
              {title}
            </Typography>
            {showBadge && (
              <Chip
                size="small"
                color="info"
                label={badgeLabel}
                style={{
                  backgroundColor: "#5562B4",
                  height: 18,
                  fontSize: 11,
                  fontWeight: 600,
                }}
              />
            )}
          </Stack>
          {id ? (
            <Stack
              direction="row"
              alignItems="center"
              style={{ marginLeft: -3 }}
            >
              <CopyToClipboardButton textToCopy={id} />
              <Typography
                variant="caption"
                style={{
                  width: "100%",
                  fontSize: 10,
                  color: "gray",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {id}
              </Typography>
            </Stack>
          ) : null}
        </Stack>
        {showCsvButton && (
          <div
            style={{
              width: 166,
              minWidth: 166,
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            {csvButtonType === "default" ? (
              <Button
                variant="contained"
                onClick={async () => {
                  setCsvLoading(true)
                  setCsvData([])
                  const data = await csvButtonOnClick()
                  setCsvData(data)
                  setCsvLoading(false)
                }}
              >
                Export
              </Button>
            ) : (
              <div>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  variant="contained"
                  endIcon={
                    open ? <ArrowDropUpRounded /> : <ArrowDropDownRounded />
                  }
                >
                  Export
                </Button>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                  <MenuItem
                    onClick={async () => {
                      handleClose()
                      setCsvLoading(true)
                      setCsvData([])
                      const data = await csvButtonOnClick(false)
                      setCsvData(data)
                      setCsvLoading(false)
                    }}
                  >
                    Without emails
                  </MenuItem>
                  <MenuItem
                    onClick={async () => {
                      handleClose()
                      setCsvLoading(true)
                      setCsvData([])
                      const data = await csvButtonOnClick(true)
                      setCsvData(data)
                      setCsvLoading(false)
                    }}
                  >
                    With emails
                  </MenuItem>
                </Menu>
              </div>
            )}
            <CSVLink
              data={csvData}
              ref={csvButtonRef}
              filename={csvFileName}
              style={{
                textDecoration: "none",
              }}
            />
          </div>
        )}
        {showAddButton && addButtonOnClick ? (
          <Button
            variant="contained"
            onClick={addButtonOnClick}
            style={{ width: 95, minWidth: 95 }}
          >
            {addButtonLabel}
          </Button>
        ) : null}
        <div
          style={{
            width: "-webkit-fill-available",
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
            gap: 16,
          }}
        >
          {showDeleteButton && (
            <Button
              variant="contained"
              color="error"
              onClick={deleteButtonOnClick}
              disabled={!editModeActive}
            >
              Delete
            </Button>
          )}
          {showArchiveButton && (
            <Button
              variant="contained"
              onClick={archiveButtonOnClick}
              disabled={!editModeActive}
            >
              Archive
            </Button>
          )}
          {showValidationStatus && (
            <div
              style={{
                width: "auto",
                height: 38,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:
                  validationStatus ===
                  NotificationTemplateValidationStatus.NOT_CHECKED
                    ? "#ECB358"
                    : validationStatus ===
                      NotificationTemplateValidationStatus.INVALID
                    ? "#C23F38"
                    : "#62B67F",
                paddingInline: 16,
                borderRadius: 8,
                cursor: "default",
                color: "white",
              }}
            >
              <Stack direction="row" alignItems="center" gap={0.5}>
                <Typography variant="overline" style={{ whiteSpace: "nowrap" }}>
                  {validationStatus ===
                  NotificationTemplateValidationStatus.NOT_CHECKED
                    ? "Not validated"
                    : validationStatus}
                </Typography>
                {validationStatus ===
                  NotificationTemplateValidationStatus.INVALID && (
                  <ButtonBase
                    onClick={validationInfoOnClick}
                    style={{
                      width: 28,
                      height: 28,
                      borderRadius: "100%",
                      marginRight: -6,
                    }}
                  >
                    <InfoRounded style={{ fontSize: 16 }} />
                  </ButtonBase>
                )}
              </Stack>
            </div>
          )}
          {showSuspendButton && (
            <Button variant="contained" onClick={suspendButtonOnClick}>
              {isSuspended ? "Resume" : "Suspend"}
            </Button>
          )}
          <div
            style={{
              width: "auto",
              height: 38,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor:
                stage === Stage.EDITED
                  ? "#7DB1E1"
                  : stage === Stage.DRAFT
                  ? "#ECB358"
                  : "#62B67F",
              paddingInline: 16,
              borderRadius: 8,
              cursor: "default",
              color: "white",
            }}
          >
            <Typography variant="overline">{stage}</Typography>
          </div>
          <ButtonGroup
            variant="outlined"
            aria-label="outlined button group"
            style={{ minWidth: 138 }}
          >
            <Button
              onClick={() => {
                setUndoDialogOpen(true)
              }}
              disabled={
                undoButtonDisabled ||
                saveButtonLoading ||
                !editModeActive ||
                !doneChanges
              }
            >
              <UndoRounded />
            </Button>
            {validationStatus ? (
              <Button
                fullWidth
                variant="contained"
                disabled={
                  saveButtonDisabled || saveButtonLoading || !editModeActive
                }
                onClick={
                  saveButtonOnClick
                    ? saveButtonOnClick
                    : () => {
                        logger(Status.Warning, "No actions")
                      }
                }
              >
                {(validationStatus ===
                  NotificationTemplateValidationStatus.NOT_CHECKED ||
                  validationStatus ===
                    NotificationTemplateValidationStatus.INVALID) &&
                stage === Stage.DRAFT
                  ? "Validate"
                  : stage === Stage.DRAFT
                  ? "Publish"
                  : "Save"}
              </Button>
            ) : (
              <Button
                fullWidth
                variant="contained"
                disabled={
                  saveButtonDisabled || saveButtonLoading || !editModeActive
                }
                onClick={
                  saveButtonOnClick
                    ? saveButtonOnClick
                    : () => {
                        logger(Status.Warning, "No actions")
                      }
                }
              >
                {stage === Stage.DRAFT ? "Publish" : "Save"}
              </Button>
            )}
          </ButtonGroup>
          <FormControlLabel
            labelPlacement="start"
            control={<Switch />}
            label="Edit"
            style={{ marginLeft: 0, minWidth: 86 }}
            checked={editModeActive}
            onChange={() => {
              setEditModeActive(!editModeActive)
            }}
          />
        </div>
        <Dialog
          open={undoDialogOpen}
          onClose={() => {
            setUndoDialogOpen(false)
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to undo all changes?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              All changes will be lost
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setUndoDialogOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                undoButtonOnClick(e)
                setUndoDialogOpen(false)
              }}
              autoFocus
            >
              Undo
            </Button>
          </DialogActions>
        </Dialog>
        <LoadingBackdrop open={csvLoading} style={{ left: -16 }} />
      </Stack>
    </Grow>
  )
}

export default EditHeadingWithStages
