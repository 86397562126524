import { Typography } from "@mui/material"
import { ReactNode } from "react"

const SliceTitle = ({ children }: { children: ReactNode }) => {
  return (
    <Typography
      variant="overline"
      style={{ fontWeight: 500, marginBottom: -4 }}
    >
      {children}
    </Typography>
  )
}

export default SliceTitle
