import {
  AddCircleRounded,
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  ChangeCircleRounded,
  CloseRounded,
} from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  AppBar,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material"
import { useContext, useEffect, useState } from "react"
import EditContainer from "../../components/global/editContainer"
import EditHeadingFade from "../../components/global/editHeadingFade"
import LoadingBackdrop from "../../components/global/loadingBackdrop"
import PageSpinner from "../../components/global/pageSpinner"
import { DailyEpisodesContext } from "../../controllers/dailyEpisodes"
import { EpisodesContext } from "../../controllers/episodes"
import { MainContext } from "../../controllers/main"
import Episode from "../../models/episode"
import {
  dateToStringForQueries,
  DialogTransition,
} from "../../services/utilities/utility"
import "../../styles/dailyEpisodesEdit.scss"
import EpisodesList from "../episode/episodesList"

const DailyEpisodesEdit = () => {
  const { animation, showPadding } = useContext(MainContext)
  const {
    setSearchValue,
    setSearchLanguage,
    setSearchTopic,
    setSearchSdg,
    setSearchSdgTarget,
    setSearchCategory,
  } = useContext(EpisodesContext)
  const {
    loading,
    updatingList,
    editMode,
    setEditMode,
    currentMonth,
    setCurrentMonth,
    currentYear,
    setCurrentYear,
    daysList,
    dailyEpisodesList,
    calculateCurrentDaysList,
    addDailyEpisode,
  } = useContext(DailyEpisodesContext)

  // days list
  const daysOfTheWeek = ["M", "T", "W", "T", "F", "S", "S"]

  // months list
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  useEffect(() => {
    calculateCurrentDaysList()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // popup menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  // date for daily episode add
  const [selectedDay, setSelectedDay] = useState<Date>()
  const [hasAlreadyEpisode, setHasAlreadyEpisode] = useState<boolean>(false)

  // add episode dialog
  const [addEpisodesDialogOpen, setAddEpisodesDialogOpen] =
    useState<boolean>(false)
  const [selectedEpisodes, setSelectedEpisodes] = useState<Episode[]>([])

  // reset episodes list filters
  const resetEpisodesFilters = () => {
    setSearchValue("")
    setSearchLanguage(null)
    setSearchTopic(null)
    setSearchSdg(null)
    setSearchSdgTarget(null)
    setSearchCategory(null)
  }

  // confirm selection dialog
  const [confirmSelectionDialogOpen, setConfirmSelectionDialogOpen] =
    useState<boolean>(false)

  // can't add episodes dialog
  const [cantAddEpisodeDialogOpen, setCantAddEpisodeDialogOpen] =
    useState<boolean>(false)

  // add episode loading
  const [addingEpisode, setAddingEpisode] = useState<boolean>(false)

  return loading ? (
    <PageSpinner />
  ) : (
    <EditContainer>
      <EditHeadingFade
        showBackButton={false}
        saveButtonLoading={loading}
        title="Daily Episodes"
        showButtons={false}
        showEditButton
        editModeActive={editMode}
        setEditModeActive={setEditMode}
        saveButtonDisabled={false}
      />
      <Fade
        in={animation}
        timeout={350}
        style={{ margin: showPadding === "yes" ? 16 : 0, marginTop: 0 }}
      >
        <Card style={{ paddingTop: 15 }}>
          <Stack style={{ position: "relative" }}>
            {updatingList && (
              <LinearProgress
                color="primary"
                style={{ position: "absolute", top: 45, width: "100%" }}
              />
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 46,
                position: "relative",
              }}
            >
              <IconButton
                style={{ width: 40, height: 40 }}
                onClick={() => {
                  if (currentMonth - 1 < 0) {
                    setCurrentMonth(11)
                    setCurrentYear(currentYear - 1)
                  } else {
                    setCurrentMonth(currentMonth - 1)
                  }
                }}
              >
                <ArrowBackIosRounded />
              </IconButton>
              <Typography
                variant="overline"
                style={{
                  fontSize: "0.7rem",
                  position: "absolute",
                  top: -8,
                  fontWeight: 500,
                }}
              >
                {currentYear}
              </Typography>
              <Typography
                variant="overline"
                style={{
                  fontSize: "1.1rem",
                  fontWeight: 500,
                  marginLeft: 30,
                  marginRight: 30,
                  marginTop: 2,
                  width: 115,
                  textAlign: "center",
                }}
              >
                {months[currentMonth]}
              </Typography>
              <IconButton
                style={{ width: 40, height: 40 }}
                onClick={() => {
                  if (currentMonth + 1 > 11) {
                    setCurrentMonth(0)
                    setCurrentYear(currentYear + 1)
                  } else {
                    setCurrentMonth(currentMonth + 1)
                  }
                }}
              >
                <ArrowForwardIosRounded />
              </IconButton>
            </div>
            <Stack direction="row">
              {daysOfTheWeek.map((day, index) => (
                <div
                  key={index}
                  style={{
                    width: "calc(100% / 7)",
                    height: 60,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="overline" style={{ fontSize: "1rem" }}>
                    {day}
                  </Typography>
                </div>
              ))}
            </Stack>
            <Stack
              direction="row"
              flexWrap="wrap"
              style={{
                height:
                  showPadding === "yes"
                    ? "calc(100vh - 271px)"
                    : "calc(100vh - 255px)",
              }}
            >
              <div
                style={{
                  minWidth: `calc(100% / 7 * ${
                    daysList[0].getDay() === 0 ? 6 : daysList[0].getDay() - 1
                  })`,
                }}
              />
              {daysList.map((day) => (
                <div
                  key={day.toLocaleDateString()}
                  style={{
                    minWidth: "calc(100% / 7)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    cursor: editMode && !updatingList ? "pointer" : "default",
                    border: "0.1px solid rgba(196, 196, 196, 0.4)",
                  }}
                  className={editMode && !updatingList ? "calendar-item" : null}
                  onClick={
                    editMode && !updatingList
                      ? (e) => {
                          setSelectedDay(day)
                          setHasAlreadyEpisode(
                            !dailyEpisodesList.filter(
                              (item) => item.sk === dateToStringForQueries(day)
                            )[0]
                              ? false
                              : true
                          )
                          handleClick(e)
                        }
                      : null
                  }
                >
                  <div
                    style={{
                      position: "absolute",
                      top: 6,
                      width: 28,
                      height: 28,
                      fontSize: "0.9rem",
                      borderRadius: 17.5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor:
                        new Date().toLocaleDateString() ===
                        day.toLocaleDateString()
                          ? "#5262ba"
                          : null,
                      color:
                        new Date().toLocaleDateString() ===
                        day.toLocaleDateString()
                          ? "white"
                          : null,
                    }}
                  >
                    {day.getDate()}
                  </div>
                  {dailyEpisodesList.filter(
                    (item) => item.sk === dateToStringForQueries(day)
                  )[0] ? (
                    <div
                      style={{
                        position: "absolute",
                        width: 38,
                        height: 38,
                        top: "34%",
                        borderRadius: 4,
                        backgroundImage: `url(${
                          dailyEpisodesList.filter(
                            (item) => item.sk === dateToStringForQueries(day)
                          )[0].episode.image
                        })`,
                        backgroundSize: "cover",
                      }}
                    />
                  ) : null}
                  {dailyEpisodesList.filter(
                    (item) => item.sk === dateToStringForQueries(day)
                  )[0] ? (
                    <div
                      style={{
                        position: "absolute",
                        maxWidth: "90%",
                        top: "77%",
                        textAlign: "center",
                        fontSize: 13,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {
                        dailyEpisodesList.filter(
                          (item) => item.sk === dateToStringForQueries(day)
                        )[0].episode.title
                      }
                    </div>
                  ) : null}
                </div>
              ))}
            </Stack>
          </Stack>
        </Card>
      </Fade>
      {/* popup menu */}
      <Menu
        id="popup-menu"
        aria-labelledby="popup-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PopoverClasses={{
          paper: "test",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose()
            setAddEpisodesDialogOpen(true)
          }}
        >
          <ListItemIcon>
            {hasAlreadyEpisode ? (
              <ChangeCircleRounded fontSize="small" />
            ) : (
              <AddCircleRounded fontSize="small" />
            )}
          </ListItemIcon>
          {hasAlreadyEpisode ? (
            <ListItemText>Change Episode</ListItemText>
          ) : (
            <ListItemText>Add Episode</ListItemText>
          )}
        </MenuItem>
      </Menu>
      {/* add episodes dialog */}
      <Dialog
        fullScreen
        open={addEpisodesDialogOpen}
        onClose={() => {
          setAddEpisodesDialogOpen(false)
          setSelectedEpisodes([])
          resetEpisodesFilters()
        }}
        TransitionComponent={DialogTransition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setAddEpisodesDialogOpen(false)
                setSelectedEpisodes([])
                resetEpisodesFilters()
              }}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Episode
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                setConfirmSelectionDialogOpen(true)
              }}
              disabled={!selectedEpisodes.length}
            >
              Select
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ backgroundColor: "#f5f5f5" }}>
          <EpisodesList
            forDialog
            singleDialogSelection
            selectedEpisodes={selectedEpisodes}
            setSelectedEpisodes={setSelectedEpisodes}
          />
        </Paper>
      </Dialog>
      {/* daily episode select confirmation dialog */}
      <Dialog
        open={confirmSelectionDialogOpen}
        onClose={() => {
          setConfirmSelectionDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-confirm-selection"
        aria-describedby="alert-dialog-description-confirm-selection"
      >
        <DialogTitle id="alert-dialog-title-confirm-selection">
          Are you sure you want to add this Episode?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-confirm-selection">
            <b>{!selectedEpisodes.length ? null : selectedEpisodes[0].title}</b>{" "}
            will become the daily Episode for the date{" "}
            {selectedDay ? selectedDay.toLocaleDateString() : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmSelectionDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={addingEpisode}
            onClick={async () => {
              let currentEpisode = selectedEpisodes[0]

              // check if selected episode is published
              if (
                (currentEpisode.stage === "DRAFT" &&
                  !currentEpisode.hasSibling) ||
                !currentEpisode.publishedLangs.length
              ) {
                setConfirmSelectionDialogOpen(false)
                setCantAddEpisodeDialogOpen(true)
                return
              }

              setAddingEpisode(true)

              await addDailyEpisode(
                dateToStringForQueries(selectedDay),
                currentEpisode.id
              )

              setAddingEpisode(false)
              setConfirmSelectionDialogOpen(false)
              setAddEpisodesDialogOpen(false)
              setSelectedEpisodes([])
              resetEpisodesFilters()
            }}
            autoFocus
          >
            Add Episode
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* can't add episodes dialog */}
      <Dialog
        open={cantAddEpisodeDialogOpen}
        onClose={() => {
          setCantAddEpisodeDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-cant-add-episode"
        aria-describedby="alert-dialog-description-cant-add-episode"
      >
        <DialogTitle id="alert-dialog-title-cant-add-episode">
          This Episode cannot be added
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-cant-add-episode">
            The selected Episode doesn't have at least one translation published
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCantAddEpisodeDialogOpen(false)
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={addingEpisode} />
    </EditContainer>
  )
}

export default DailyEpisodesEdit
