import {
  AppBar,
  Autocomplete,
  Button,
  CardContent,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { MainContext } from "../../controllers/main"
import { ChallengesContext } from "../../controllers/challenges"
import { MetricsContext } from "../../controllers/metrics"
import {
  ChallengeType,
  ChallengeVersionType,
  TeamAccessType,
} from "../../services/config/enum"
import {
  capitalizeFirstCharacter,
  DialogTransition,
  isValidURL,
  renderOption,
  renderTags,
  splitCamelCase,
} from "../../services/utilities/utility"
import { CloseRounded, OpenInNewRounded } from "@mui/icons-material"
import TeamsList from "../../views/team/teamsList"
import ISODatePicker from "../global/isoDatePicker"
import Action from "../../models/action"
import { ActionsContext } from "../../controllers/actions"
import { isDev, mobilityMetrics } from "../../services/config/constants"
import DataCard from "../global/dataCard"
import { useNavigate } from "react-router-dom"
import Team from "../../models/team"
import { TeamsContext } from "../../controllers/teams"
import { AutocompleteOption } from "../../services/config/interfaces"
import ISOTimePicker from "../global/isoTimePicker"

const ChallengeGeneralEdit = ({
  setGlobalPreviewOpen,
}: {
  setGlobalPreviewOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { showPadding, setAnimation } = useContext(MainContext)
  const {
    currentChallenge,
    preChangesCurrentChallenge,
    setCurrentChallenge,
    editMode,
    startsAtError,
    endsAtError,
    titleError,
    setTitleError,
    metricError,
    setMetricError,
    targetAmountError,
    setTargetAmountError,
    personalTargetAmountError,
    setPersonalTargetAmountError,
    personalTargetPointsError,
    setPersonalTargetPointsError,
    teamError,
    setTeamError,
    leaderboardMinMembersError,
    setLeaderboardMinMembersError,
    privacyPolicyError,
    setPrivacyPolicyError,
  } = useContext(ChallengesContext)
  const { metricsLoading, metricsList } = useContext(MetricsContext)
  const { resetTeamsFilters } = useContext(TeamsContext)
  const { resetFilters } = useContext(ActionsContext)
  const navigate = useNavigate()

  // change team dialog
  const [changeTeamDialogOpen, setChangeTeamDialogOpen] =
    useState<boolean>(false)
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)

  // actions
  const [selectedActions, setSelectedActions] = useState<Action[]>([])
  const [actionsToFilter, setActionsToFilter] = useState<Action[]>([])
  const [addActionsDialogOpen, setAddActionsDialogOpen] =
    useState<boolean>(false)

  // check team error
  useEffect(() => {
    if (
      (!currentChallenge.team &&
        currentChallenge.type !== ChallengeType.featured) ||
      (currentChallenge.type &&
        currentChallenge.type === ChallengeType.reservedFeatured &&
        currentChallenge.team &&
        currentChallenge.team.accessType === TeamAccessType.activationCode)
    ) {
      setTeamError(true)
    } else {
      setTeamError(false)
    }
  }, [currentChallenge.type, currentChallenge.team])

  // check personal target amount and personal target points error
  useEffect(() => {
    if (mobilityMetrics.includes(currentChallenge.metric.id)) {
      if (!currentChallenge.personalTargetAmount) {
        setPersonalTargetAmountError(true)
      }
      if (!currentChallenge.personalTargetPoints) {
        setPersonalTargetPointsError(true)
      }
    }
  }, [])

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Data
        </Typography>
        <Stack direction="row" gap={1}>
          <DataCard
            title="Info"
            htmlDescription={`<p style="line-height: 1.3"><span style="font-weight: 700; font-size: 1rem">${
              currentChallenge.userCount
            }</span> <span style="font-weight: 300; font-size: 0.9rem">users</span><br /><span style="font-weight: 700; font-size: 1rem">${Math.round(
              currentChallenge.currentAmount
            )}</span> <span style="font-weight: 300; font-size: 0.9rem">${
              currentChallenge.metric.id === "co2Saving" ||
              currentChallenge.metric.id === "waterSaving" ||
              currentChallenge.metric.id === "energySaving"
                ? currentChallenge.metric.unit
                : ""
            } ${
              currentChallenge.metric.name
            }</span><br /><span style="font-weight: 700; font-size: 1rem">${Math.round(
              (currentChallenge.currentAmount / currentChallenge.targetAmount) *
                100
            )}%</span> <span style="font-weight: 300; font-size: 0.9rem">completion</span></p>`}
          />
          <DataCard
            title="Preview"
            description="Preview of how this Challenge appears inside the app."
            buttonLabel="Open"
            buttonOnClick={() => {
              setGlobalPreviewOpen(true)
            }}
          />
          <DataCard
            title="Report"
            description="Looker Studio report of this Challenge."
            buttonOnClick={() => {
              setAnimation(false)
              setTimeout(() => {
                navigate(window.location.pathname + "/report")
              }, 250)
            }}
          />
          <DataCard
            title="Leaderboard"
            subtitle="by users"
            description="Users leaderboard of this Challenge, exportable in CSV."
            buttonOnClick={() => {
              setAnimation(false)
              setTimeout(() => {
                navigate(window.location.pathname + "/leaderboard/users")
              }, 250)
            }}
          />
          {preChangesCurrentChallenge.type !== ChallengeType.featured ? (
            <DataCard
              title="Leaderboard"
              subtitle="by groups"
              description="Groups leaderboard of this Challenge, exportable in CSV."
              buttonOnClick={() => {
                setAnimation(false)
                setTimeout(() => {
                  navigate(window.location.pathname + "/leaderboard/groups")
                }, 250)
              }}
            />
          ) : null}
        </Stack>
        <Typography variant="h6" className="card-title">
          Configuration
        </Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            disabled={!editMode}
            size="small"
            fullWidth
            label="Name"
            value={currentChallenge.name}
            onChange={(e) => {
              currentChallenge.name = e.target.value
              setCurrentChallenge({ ...currentChallenge })

              // check for errors
              if (!e.target.value.length) {
                setTitleError(true)
              } else {
                setTitleError(false)
              }
            }}
            error={titleError}
            helperText={titleError ? "This field is required" : ""}
            onBlur={() => {
              if (currentChallenge.name) {
                currentChallenge.name = currentChallenge.name.trim()
                setCurrentChallenge({ ...currentChallenge })
              }
            }}
          />
          <TextField
            disabled
            size="small"
            fullWidth
            label="UID"
            value={currentChallenge.uid}
          />
          <FormControl fullWidth size="small" disabled>
            <InputLabel id="version-type-select">Version type</InputLabel>
            <Select
              labelId="version-type-select"
              label="Version type"
              value={currentChallenge.versionType ?? ""}
              onChange={(e) => {
                setCurrentChallenge((current) => {
                  return {
                    ...current,
                    versionType: e.target.value as ChallengeVersionType,
                  }
                })
              }}
            >
              {(
                Object.keys(ChallengeVersionType) as Array<
                  keyof typeof ChallengeVersionType
                >
              ).map((key) => (
                <MenuItem key={key} value={key}>
                  {capitalizeFirstCharacter(splitCamelCase(key)).replace(
                    " challenge",
                    ""
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Autocomplete
            disabled={!editMode}
            loading={metricsLoading}
            fullWidth
            blurOnSelect
            size="small"
            value={
              currentChallenge.metric
                ? {
                    id: currentChallenge.metric.id,
                    label: capitalizeFirstCharacter(
                      currentChallenge.metric.name
                    ),
                  }
                : null
            }
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={metricsList
              .filter((item) =>
                currentChallenge.versionType ===
                ChallengeVersionType.pointsLeaderboardChallenge
                  ? item.versionType === "new" || item.versionType === "both"
                  : item.versionType === "old" || item.versionType === "both"
              )
              .map((item) => {
                return {
                  id: item.id,
                  label: capitalizeFirstCharacter(item.name),
                }
              })}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Metric"
                error={metricError}
                helperText={metricError ? "This field is required" : ""}
              />
            )}
            onChange={(e: any, newValue: AutocompleteOption | null) => {
              if (newValue) {
                currentChallenge.metric = metricsList.filter(
                  (item) => item.id === newValue.id
                )[0]
                setMetricError(false)

                if (mobilityMetrics.includes(newValue.id)) {
                  if (!currentChallenge.personalTargetAmount) {
                    setPersonalTargetAmountError(true)
                  } else {
                    setPersonalTargetAmountError(false)
                  }
                  if (!currentChallenge.personalTargetPoints) {
                    setPersonalTargetPointsError(true)
                  } else {
                    setPersonalTargetPointsError(false)
                  }
                }
              } else {
                currentChallenge.metric = null
                setMetricError(true)
              }
              setCurrentChallenge({ ...currentChallenge })
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
          <TextField
            disabled={!editMode}
            fullWidth
            size="small"
            type="number"
            label="Target"
            value={currentChallenge.targetAmount ?? ""}
            onChange={(e) => {
              if (e.target.value.length) {
                if (
                  e.target.value.includes(".") ||
                  e.target.value.includes(",")
                ) {
                  currentChallenge.targetAmount = parseFloat(e.target.value)
                  if (parseFloat(e.target.value) < 1) {
                    setTargetAmountError(true)
                  } else {
                    setTargetAmountError(false)
                  }
                } else {
                  currentChallenge.targetAmount = parseInt(e.target.value)
                  if (parseInt(e.target.value) < 1) {
                    setTargetAmountError(true)
                  } else {
                    setTargetAmountError(false)
                  }
                }
              } else {
                currentChallenge.targetAmount = null
                setTargetAmountError(true)
              }
              setCurrentChallenge({ ...currentChallenge })
            }}
            error={targetAmountError}
            helperText={targetAmountError ? "Invalid value" : ""}
          />
        </Stack>
        {mobilityMetrics.includes(currentChallenge.metric.id) ? (
          <Stack direction="row" gap={2}>
            <TextField
              disabled={!editMode}
              fullWidth
              size="small"
              type="number"
              label="Personal target"
              value={currentChallenge.personalTargetAmount ?? ""}
              onChange={(e) => {
                if (e.target.value.length) {
                  if (
                    e.target.value.includes(".") ||
                    e.target.value.includes(",")
                  ) {
                    currentChallenge.personalTargetAmount = parseFloat(
                      e.target.value
                    )
                    if (parseFloat(e.target.value) <= 0) {
                      setPersonalTargetAmountError(true)
                    } else {
                      setPersonalTargetAmountError(false)
                    }
                  } else {
                    currentChallenge.personalTargetAmount = parseInt(
                      e.target.value
                    )
                    if (parseInt(e.target.value) <= 0) {
                      setPersonalTargetAmountError(true)
                    } else {
                      setPersonalTargetAmountError(false)
                    }
                  }
                } else {
                  currentChallenge.personalTargetAmount = null
                  setPersonalTargetAmountError(true)
                }
                setCurrentChallenge({ ...currentChallenge })
              }}
              error={personalTargetAmountError}
              helperText={
                personalTargetAmountError &&
                !currentChallenge.personalTargetAmount
                  ? "This field is required"
                  : personalTargetAmountError
                  ? "Invalid value"
                  : ""
              }
            />
            <TextField
              disabled={!editMode}
              fullWidth
              size="small"
              type="number"
              label="Personal target points"
              value={currentChallenge.personalTargetPoints ?? ""}
              onChange={(e) => {
                if (e.target.value.length) {
                  if (
                    e.target.value.includes(".") ||
                    e.target.value.includes(",")
                  ) {
                    currentChallenge.personalTargetPoints = parseFloat(
                      e.target.value
                    )
                    if (parseFloat(e.target.value) < 1) {
                      setPersonalTargetPointsError(true)
                    } else {
                      setPersonalTargetPointsError(false)
                    }
                  } else {
                    currentChallenge.personalTargetPoints = parseInt(
                      e.target.value
                    )
                    if (parseInt(e.target.value) < 1) {
                      setPersonalTargetPointsError(true)
                    } else {
                      setPersonalTargetPointsError(false)
                    }
                  }
                } else {
                  currentChallenge.personalTargetPoints = null
                  setPersonalTargetPointsError(true)
                }
                setCurrentChallenge({ ...currentChallenge })
              }}
              error={personalTargetPointsError}
              helperText={
                personalTargetPointsError &&
                !currentChallenge.personalTargetPoints
                  ? "This field is required"
                  : personalTargetPointsError
                  ? "Invalid value"
                  : ""
              }
            />
          </Stack>
        ) : null}
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth size="small" disabled={!editMode}>
            <InputLabel id="type-select">Type</InputLabel>
            <Select
              labelId="type-select"
              label="Type"
              value={currentChallenge.type ?? ""}
              onChange={(e) => {
                if (
                  (e.target.value as ChallengeType) === ChallengeType.featured
                ) {
                  setTeamError(false)
                  currentChallenge.team = null
                }
                currentChallenge.type = e.target.value as ChallengeType
                setCurrentChallenge({ ...currentChallenge })
              }}
            >
              {(
                Object.keys(ChallengeType) as Array<keyof typeof ChallengeType>
              ).map((key) => (
                <MenuItem key={key} value={key}>
                  {key === "reservedFeatured"
                    ? "Reserved & Featured"
                    : capitalizeFirstCharacter(key)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {currentChallenge.type &&
          currentChallenge.type !== ChallengeType.featured ? (
            <TextField
              disabled={!editMode}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <Stack direction="row" spacing={0}>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        if (isDev) {
                          window.open(
                            window.location.protocol +
                              "//" +
                              window.location.host +
                              "/teams/" +
                              currentChallenge.team.id
                          )
                        } else {
                          window.open(
                            window.location.protocol +
                              "//" +
                              window.location.host +
                              "/teams/" +
                              currentChallenge.team.id
                          )
                        }
                      }}
                    >
                      <OpenInNewRounded
                        style={{
                          fontSize: 15,
                          opacity: 0.9,
                        }}
                      />
                    </Button>
                    <Button
                      sx={{ paddingInline: 2 }}
                      onClick={() => {
                        setChangeTeamDialogOpen(true)
                      }}
                      disabled={!editMode}
                    >
                      {currentChallenge.team ? "Change" : "Select"}
                    </Button>
                  </Stack>
                ),
              }}
              fullWidth
              label="Team"
              size="small"
              value={currentChallenge.team ? currentChallenge.team.name : ""}
              error={teamError}
              helperText={
                currentChallenge.type === ChallengeType.reservedFeatured &&
                currentChallenge.team &&
                currentChallenge.team.accessType ===
                  TeamAccessType.activationCode
                  ? "The Team must not be private for the current Challenge type"
                  : teamError
                  ? "This field is required"
                  : ""
              }
            />
          ) : null}
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth size="small" disabled={!editMode}>
            <InputLabel id="leaderboard-group-type-select">
              Groups leaderboard type
            </InputLabel>
            <Select
              labelId="leaderboard-group-type-select"
              label="Groups leaderboard type"
              value={currentChallenge.leaderboardGroupType}
              onChange={(e) => {
                currentChallenge.leaderboardGroupType = e.target.value as
                  | "average"
                  | "sum"
                setCurrentChallenge({ ...currentChallenge })
              }}
            >
              <MenuItem value="sum">Sum</MenuItem>
              <MenuItem value="average">Average</MenuItem>
            </Select>
          </FormControl>
          <TextField
            disabled={!editMode}
            fullWidth
            size="small"
            type="number"
            label="Leaderboard minimum members"
            value={currentChallenge.leaderboardMinMembers ?? ""}
            onChange={(e) => {
              if (e.target.value.length) {
                if (
                  e.target.value.includes(".") ||
                  e.target.value.includes(",")
                ) {
                  currentChallenge.leaderboardMinMembers = parseFloat(
                    e.target.value
                  )
                  if (parseFloat(e.target.value) < 1) {
                    setLeaderboardMinMembersError(true)
                  } else {
                    setLeaderboardMinMembersError(false)
                  }
                } else {
                  currentChallenge.leaderboardMinMembers = parseInt(
                    e.target.value
                  )
                  if (parseInt(e.target.value) < 1) {
                    setLeaderboardMinMembersError(true)
                  } else {
                    setLeaderboardMinMembersError(false)
                  }
                }
              } else {
                currentChallenge.leaderboardMinMembers = null
                setLeaderboardMinMembersError(true)
              }
              setCurrentChallenge({ ...currentChallenge })
            }}
            error={leaderboardMinMembersError}
            helperText={leaderboardMinMembersError ? "Invalid value" : ""}
          />
        </Stack>
        <TextField
          disabled={!editMode}
          fullWidth
          size="small"
          label="Privacy policy (URL)"
          value={currentChallenge.privacyPolicy ?? ""}
          onChange={(e) => {
            setCurrentChallenge((current) => {
              return {
                ...current,
                privacyPolicy: e.target.value ? e.target.value : null,
              }
            })

            // errors check
            if (e.target.value && !isValidURL(e.target.value)) {
              setPrivacyPolicyError(true)
            } else {
              setPrivacyPolicyError(false)
            }
          }}
          error={privacyPolicyError}
          helperText={privacyPolicyError ? "Invalid URL" : ""}
        />
        <Typography variant="h6" className="card-title">
          Duration
        </Typography>
        <Stack direction="row" spacing={2}>
          <ISODatePicker
            label="Start date"
            date={currentChallenge.startsAt}
            setDate={(newDate: string) => {
              currentChallenge.startsAt = newDate
                ? newDate.split("T")[0] +
                  "T" +
                  currentChallenge.startsAt.split("T")[1]
                : newDate
              setCurrentChallenge({ ...currentChallenge })
            }}
            error={startsAtError}
            disabled={!editMode}
            hour={12}
          />
          <ISOTimePicker
            label="Start time"
            time={currentChallenge.startsAt}
            setTime={(newDate: string) => {
              currentChallenge.startsAt = newDate
              setCurrentChallenge({ ...currentChallenge })
            }}
            error={startsAtError}
            disabled={!editMode}
          />
        </Stack>
        <Typography
          style={{
            lineHeight: 1.5,
            fontSize: "0.75rem",
            color: "#667380",
            marginLeft: 14,
            marginTop: 6,
          }}
        >
          {`The Challenge will start on ${new Date(
            currentChallenge.startsAt
          ).toLocaleDateString()} at ${new Date(
            currentChallenge.startsAt
          ).toLocaleTimeString("it", {
            hour12: true,
          })} in local time, ${new Date(
            new Date(currentChallenge.startsAt).getTime() +
              new Date().getTimezoneOffset() * 60000
          ).toLocaleDateString()} at ${new Date(
            new Date(currentChallenge.startsAt).getTime() +
              new Date().getTimezoneOffset() * 60000
          ).toLocaleTimeString("it", {
            hour12: true,
          })} UTC`}
        </Typography>
        <Stack direction="row" spacing={2}>
          <ISODatePicker
            label="End date"
            date={currentChallenge.endsAt}
            setDate={(newDate: string) => {
              currentChallenge.endsAt = newDate
                ? newDate.split("T")[0] +
                  "T" +
                  currentChallenge.endsAt.split("T")[1]
                : newDate
              setCurrentChallenge({ ...currentChallenge })
            }}
            error={endsAtError}
            minDate={currentChallenge.startsAt}
            disabled={!editMode}
            hour={12}
          />
          <ISOTimePicker
            label="End time"
            time={currentChallenge.endsAt}
            setTime={(newDate: string) => {
              currentChallenge.endsAt = newDate
              setCurrentChallenge({ ...currentChallenge })
            }}
            error={endsAtError}
            disabled={!editMode}
          />
        </Stack>
        <Typography
          style={{
            lineHeight: 1.5,
            fontSize: "0.75rem",
            color: "#667380",
            marginLeft: 14,
            marginTop: 6,
          }}
        >
          {`The Challenge will end on ${new Date(
            currentChallenge.endsAt
          ).toLocaleDateString()} at ${new Date(
            currentChallenge.endsAt
          ).toLocaleTimeString("it", {
            hour12: true,
          })} in local time, ${new Date(
            new Date(currentChallenge.endsAt).getTime() +
              new Date().getTimezoneOffset() * 60000
          ).toLocaleDateString()} at ${new Date(
            new Date(currentChallenge.endsAt).getTime() +
              new Date().getTimezoneOffset() * 60000
          ).toLocaleTimeString("it", {
            hour12: true,
          })} UTC`}
        </Typography>
      </Stack>
      {/* change team dialog */}
      <Dialog
        fullScreen
        open={changeTeamDialogOpen}
        onClose={() => {
          setChangeTeamDialogOpen(false)
          setSelectedTeam(null)
          resetTeamsFilters()
        }}
        TransitionComponent={DialogTransition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Team
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                currentChallenge.team = selectedTeam
                setCurrentChallenge({ ...currentChallenge })

                // check for errors
                if (
                  currentChallenge.type &&
                  currentChallenge.type === ChallengeType.reservedFeatured &&
                  selectedTeam.accessType === TeamAccessType.activationCode
                ) {
                  setTeamError(true)
                }

                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              disabled={!selectedTeam}
            >
              Select
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ backgroundColor: "#f5f5f5" }}>
          <TeamsList
            forDialog
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
          />
        </Paper>
      </Dialog>
    </CardContent>
  )
}

export default ChallengeGeneralEdit
