import { Cancel, Search } from "@mui/icons-material"
import {
  Button,
  Divider,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material"
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
} from "react"

const ListTopBar = ({
  loading,
  textFieldValue,
  textFieldPlaceholder,
  textFieldDisabled = false,
  addButtonLabel,
  showAddButton = true,
  addButtonOnClick,
  textFieldOnChange,
  textFieldOnKeyDown,
  cancelButtonOnClick,
  searchButtonOnClick,
  forDialog = false,
  searchButtonDisabledLength = 3,
}: {
  loading: boolean
  textFieldValue: string
  textFieldPlaceholder: string
  textFieldDisabled?: boolean
  addButtonLabel?: string
  showAddButton?: boolean
  textFieldOnChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  textFieldOnKeyDown: KeyboardEventHandler<HTMLDivElement>
  cancelButtonOnClick: MouseEventHandler<HTMLDivElement>
  searchButtonOnClick: MouseEventHandler<HTMLButtonElement>
  addButtonOnClick?: MouseEventHandler<HTMLButtonElement>
  forDialog?: boolean
  searchButtonDisabledLength?: number
}) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      style={{
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 4,
      }}
    >
      <TextField
        disabled={loading || textFieldDisabled}
        size="small"
        fullWidth
        id="outlined-basic"
        placeholder={textFieldPlaceholder}
        variant="outlined"
        value={textFieldValue}
        onChange={textFieldOnChange}
        onKeyDown={textFieldOnKeyDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: textFieldValue.length > 0 && (
            <InputAdornment position="start">
              <div
                style={{
                  marginTop: 6,
                  marginRight: -6,
                  cursor: "pointer",
                }}
                onClick={loading ? () => {} : cancelButtonOnClick}
              >
                <Cancel style={{ width: 18 }} />
              </div>
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="outlined"
        disabled={textFieldValue.length < searchButtonDisabledLength || loading}
        onClick={searchButtonOnClick}
        style={{ height: 40 }}
      >
        Search
      </Button>
      {!forDialog && showAddButton ? (
        <Divider orientation="vertical" flexItem />
      ) : null}
      {!forDialog && showAddButton ? (
        <Button
          variant="contained"
          disabled={loading}
          style={{ whiteSpace: "nowrap", minWidth: "max-content", height: 40 }}
          onClick={addButtonOnClick}
        >
          {addButtonLabel}
        </Button>
      ) : null}
    </Stack>
  )
}

export default ListTopBar
