import { Chip, IconButton, Stack, Typography } from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import ListContainer from "../../components/global/listContainer"
import { MainContext } from "../../controllers/main"
import {
  calculateTimeElapsed,
  registerAnalyticsEvent,
} from "../../services/utilities/utility"
import { ActivationCodesContext } from "../../controllers/activationCodes"
import CopyToClipboardButton from "../../components/global/copyToClipboardButton"
import PrimaryBadge from "../../components/global/primaryBadge"
import { HourglassEmptyRounded, OpenInNewRounded } from "@mui/icons-material"
import {
  ActivationCodePolicy,
  ActivationCodeType,
} from "../../services/config/enum"
import ActivationCodesListTopBar from "../../components/activationCode/activationCodesListTopBar"
import ListTableVirtualized from "../../components/global/listTableVirtualized"
import CreateActivationCodeDialog from "../../components/activationCode/createActivationCodeDialog"

const ActivationCodesList = () => {
  const { showPadding } = useContext(MainContext)
  const {
    loading,
    hasSearched,
    activationCodesList,
    activationCodesListNextToken,
    getActivationCodesList,
    loadMoreActivationCodes,
    updatingList,
    setUpdatingList,
    searchCodeType,
    searchTeam,
    searchChallenge,
    searchActivationCodes,
  } = useContext(ActivationCodesContext)

  // list background update
  const backgroundUpdate = () => {
    if (!activationCodesList.length) {
      getActivationCodesList()
    } else if (!loading && !hasSearched) {
      setUpdatingList(true)
      getActivationCodesList(false)
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    backgroundUpdate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create action dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // load more button loading
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // register analytics event
  useEffect(() => {
    registerAnalyticsEvent({ type: "page_view", name: "nft_catalogs_list" })
  }, [])

  return (
    <ListContainer style={{ position: "relative" }}>
      <ActivationCodesListTopBar
        loading={loading}
        textFieldValue=""
        textFieldPlaceholder="Search Activation Codes"
        addButtonLabel="Add Activation Code"
        addButtonOnClick={handleDialogChange}
      />
      <ListTableVirtualized
        columns={[
          { label: "Code", dataKey: "code" },
          { label: "Type", dataKey: "type" },
          { label: "Iterations", dataKey: "iterations" },
          { label: "Policy", dataKey: "policy" },
          { label: "Updated", dataKey: "updated" },
        ]}
        rows={activationCodesList.map((item) => {
          return {
            item: { id: item.code, ...item },
            code: (
              <Stack direction="row" alignItems="center">
                <Typography
                  style={{
                    fontSize: 15,
                  }}
                >
                  {item.code}
                </Typography>
                <CopyToClipboardButton textToCopy={item.code} />
              </Stack>
            ),
            type: (
              <Chip
                label={
                  <Stack direction="row" alignItems="center">
                    {item.codeType === ActivationCodeType.TeamActivationCode &&
                    item.groupId &&
                    item.groupId !== "group_default"
                      ? "Group"
                      : item.codeType.slice(
                          0,
                          item.codeType.indexOf("ActivationCode")
                        )}
                    <IconButton
                      sx={{
                        width: 20,
                        height: 20,
                      }}
                      onClick={(e) => {
                        e.stopPropagation()

                        const href = window.location.href.slice(
                          0,
                          window.location.href.indexOf("/activationcodes")
                        )

                        switch (item.codeType) {
                          case ActivationCodeType.ChallengeActivationCode:
                            window.open(
                              `${href}/challenges/${item.challengeId}`
                            )
                            break
                          case ActivationCodeType.NftActivationCode:
                            window.open(
                              `${href}/nftcatalogs/${item.nftCatalogId}`
                            )
                            break
                          case ActivationCodeType.TeamActivationCode:
                            if (
                              item.groupId &&
                              item.groupId !== "group_default"
                            ) {
                              window.open(
                                `${href}/teams/${item.teamId}?groupId=${item.groupId}`
                              )
                            } else {
                              window.open(`${href}/teams/${item.teamId}`)
                            }
                            break
                          case ActivationCodeType.WrapperActivationCode ||
                            ActivationCodeType.PlatformActivationCode:
                            window.open(`${href}/tools?code=${item.code}`)
                            break
                        }
                      }}
                    >
                      <OpenInNewRounded
                        sx={{
                          fontSize: 12,
                        }}
                      />
                    </IconButton>
                  </Stack>
                }
                size="small"
              />
            ),
            iterations: (
              <PrimaryBadge
                count={item.iterationCount}
                icon={<HourglassEmptyRounded style={{ fontSize: 12 }} />}
              />
            ),
            policy: (
              <Chip
                label={
                  item.policy === ActivationCodePolicy.IterationLimited
                    ? "Limited"
                    : "Unlimited"
                }
                color={
                  item.policy === ActivationCodePolicy.IterationLimited
                    ? "warning"
                    : "success"
                }
                size="small"
              />
            ),
            updated: (
              <Typography variant="caption">
                {calculateTimeElapsed(item.updatedAt)}
              </Typography>
            ),
          }
        })}
        height={
          showPadding === "yes" ? "calc(100vh - 208px)" : "calc(100vh - 175px)"
        }
        loading={loading}
        loadingMore={loadMoreLoading}
        setLoadingMore={setLoadMoreLoading}
        updating={updatingList}
        loadMore={
          !searchCodeType && !searchTeam && !searchChallenge
            ? loadMoreActivationCodes
            : async () => {
                const result = await searchActivationCodes(false, true)
                return result
              }
        }
        nextToken={activationCodesListNextToken}
        rowOnClickDisabled
      />
      <CreateActivationCodeDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
      />
    </ListContainer>
  )
}

export default ActivationCodesList
