import { Backdrop } from "@mui/material"
import { Dispatch, ReactNode, SetStateAction } from "react"

const BigPreview = ({
  children,
  isBigPreviewOpen,
  setIsBigPreviewOpen,
}: {
  children: ReactNode
  isBigPreviewOpen: boolean
  setIsBigPreviewOpen: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isBigPreviewOpen}
      onClick={() => {
        setIsBigPreviewOpen(false)
      }}
    >
      {children}
    </Backdrop>
  )
}

export default BigPreview
