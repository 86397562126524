import {
  AddCircleRounded,
  FormatQuoteOutlined,
  ImageOutlined,
  OndemandVideoOutlined,
  TitleOutlined,
} from "@mui/icons-material"
import { Button, Stack, Tooltip } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import { BodySliceType } from "../../../services/config/enum"

const AddBlockPanel = ({
  open,
  setOpen,
  editMode,
  addSlice,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  editMode: boolean
  addSlice: (type: BodySliceType) => void
}) => {
  return (
    <Stack direction="row" spacing={2}>
      <Button
        disabled={!editMode}
        startIcon={
          <AddCircleRounded
            style={{
              transform: open ? "rotate(45deg)" : null,
              transition: "180ms",
              marginLeft: open ? 12 : 0,
            }}
          />
        }
        style={{
          maxHeight: 40,
          minHeight: 40,
          minWidth: open ? 50 : "100%",
          transition: "250ms",
          marginRight: 1,
        }}
        variant="outlined"
        onClick={() => {
          setOpen(!open)
        }}
      >
        {open ? "" : "Add slice"}
      </Button>
      <Tooltip title="Text">
        <Button
          variant="outlined"
          fullWidth
          onClick={() => {
            addSlice(BodySliceType.BodyTextSlice)
          }}
          style={{
            maxHeight: 40,
            minHeight: 40,
          }}
        >
          <TitleOutlined />
        </Button>
      </Tooltip>
      <Tooltip title="Quote">
        <Button
          variant="outlined"
          fullWidth
          onClick={() => {
            addSlice(BodySliceType.BodyQuoteSlice)
          }}
          style={{
            maxHeight: 40,
            minHeight: 40,
          }}
        >
          <FormatQuoteOutlined />
        </Button>
      </Tooltip>
      <Tooltip title="Image">
        <Button
          variant="outlined"
          fullWidth
          onClick={() => {
            addSlice(BodySliceType.BodyImageSlice)
          }}
          style={{
            maxHeight: 40,
            minHeight: 40,
          }}
        >
          <ImageOutlined />
        </Button>
      </Tooltip>
      <Tooltip title="Embed">
        <Button
          variant="outlined"
          fullWidth
          onClick={() => {
            addSlice(BodySliceType.BodyEmbedSlice)
          }}
          style={{
            maxHeight: 40,
            minHeight: 40,
          }}
        >
          <OndemandVideoOutlined />
        </Button>
      </Tooltip>
    </Stack>
  )
}

export default AddBlockPanel
