import { Cancel, Search } from "@mui/icons-material"
import {
  Button,
  Divider,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material"
import { MouseEventHandler, useContext, useEffect, useRef } from "react"
import ListFilters from "../global/listFilters"
import { ActivationCodesContext } from "../../controllers/activationCodes"
import { enumAsArray } from "../../services/utilities/utility"
import { ActivationCodeType } from "../../services/config/enum"

const ActivationCodesListTopBar = ({
  loading,
  textFieldValue,
  textFieldPlaceholder,
  addButtonLabel,
  addButtonOnClick,
  forDialog = false,
  addButtonDisabled = false,
}: {
  loading: boolean
  textFieldValue: string
  textFieldPlaceholder: string
  addButtonLabel: string
  addButtonOnClick: MouseEventHandler<HTMLButtonElement>
  forDialog?: boolean
  addButtonDisabled?: boolean
}) => {
  const {
    updatingList,
    setUpdatingList,
    searchCodeType,
    setSearchCodeType,
    searchTeam,
    setSearchTeam,
    searchChallenge,
    setSearchChallenge,
    getActivationCodesList,
    searchActivationCodes,
  } = useContext(ActivationCodesContext)

  // search when filters update
  const isFirstRender = useRef<boolean>(true)

  useEffect(() => {
    if (!isFirstRender.current) {
      if (
        !searchCodeType &&
        !searchTeam &&
        !searchChallenge &&
        !textFieldValue.length
      ) {
        setUpdatingList(true)
        getActivationCodesList(false)
      } else {
        searchActivationCodes()
      }
    } else {
      isFirstRender.current = false
    }
  }, [searchCodeType, searchTeam, searchChallenge])

  return (
    <Stack
      style={{
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 4,
      }}
      spacing={1}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          disabled
          size="small"
          fullWidth
          id="outlined-basic"
          placeholder={textFieldPlaceholder}
          variant="outlined"
          value={textFieldValue}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: textFieldValue.length > 0 && (
              <InputAdornment position="start">
                <div
                  style={{
                    marginTop: 6,
                    marginRight: -6,
                    cursor: "pointer",
                  }}
                >
                  <Cancel style={{ width: 18 }} />
                </div>
              </InputAdornment>
            ),
          }}
        />
        {!forDialog && <Divider orientation="vertical" flexItem />}
        {!forDialog && (
          <Button
            variant="contained"
            disabled={loading || addButtonDisabled}
            style={{
              whiteSpace: "nowrap",
              minWidth: "max-content",
              height: 40,
            }}
            onClick={addButtonOnClick}
          >
            {addButtonLabel}
          </Button>
        )}
      </Stack>
      <ListFilters
        disabled={loading || updatingList}
        filters={[
          {
            type: "singular",
            label: "Type",
            value: searchCodeType,
            setValue: setSearchCodeType,
            options: enumAsArray(ActivationCodeType)
              .filter((key) => key !== ActivationCodeType.TeamActivationCode)
              .map((key) => {
                const keyAsString = key as string

                return {
                  id: keyAsString,
                  label: keyAsString.slice(
                    0,
                    keyAsString.indexOf("Activation")
                  ),
                }
              }),
            noValueLabel: "Team / Group",
            disableClearable: true,
          },
          {
            type: "team",
            value: searchTeam,
            setValue: setSearchTeam,
          },
          {
            type: "challenge",
            value: searchChallenge,
            setValue: setSearchChallenge,
          },
        ]}
      />
    </Stack>
  )
}

export default ActivationCodesListTopBar
