import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  Button,
  CardActions,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Dialog,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { EpisodesContext } from "../../controllers/episodes"
import Sdg from "../../models/sdg"
import SdgTarget from "../../models/sdgTarget"
import Topic from "../../models/topic"
import {
  capitalizeFirstCharacter,
  DialogTransition,
  enumAsArray,
  renderOption,
  renderTags,
} from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import ImagePicker from "../global/imagePicker"
import { EpisodeSpecialType, Esg } from "../../services/config/enum"
import { useNavigate } from "react-router-dom"
import { MainContext } from "../../controllers/main"
import { LanguagesContext } from "../../controllers/languages"
import { TagsContext } from "../../controllers/tags"
import { AutocompleteOption } from "../../services/config/interfaces"
import ClearableSelect from "../global/clearableSelect"
import ISODatePicker from "../global/isoDatePicker"

const CreateEpisodeDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
  topBarText,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
  topBarText: string
}) => {
  const { setAnimation } = useContext(MainContext)
  const { languages } = useContext(LanguagesContext)
  const { loading, createNewEpisode } = useContext(EpisodesContext)
  const {
    categoriesLoading,
    categoriesList,
    topicsLoading,
    topicsList,
    getTopicsList,
    sdgsLoading,
    sdgTargetsLoading,
    sdgsList,
    getSdgsList,
    sdgTargetsList,
    getSdgTargetsList,
  } = useContext(TagsContext)
  const navigate = useNavigate()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // get topics list
  useEffect(() => {
    if (dialogOpen && !topicsList.length) {
      getTopicsList()
    }
    if (dialogOpen && !sdgsList.length) {
      getSdgsList()
    }
    if (dialogOpen && !sdgTargetsList.length) {
      getSdgTargetsList()
    }
  }, [getTopicsList, getSdgsList, getSdgTargetsList, dialogOpen])

  // configuration
  const [lang, setLang] = useState<string>("")
  const [type, setType] = useState<"Standard" | "Custom">("Standard")
  const [specialType, setSpecialType] = useState<EpisodeSpecialType | null>(
    null
  )
  const [doNotRecommend, setDoNotRecommend] = useState<boolean>(false)
  const [recommendStartsAt, setRecommendStartsAt] = useState<string>("")
  const [recommendEndsAt, setRecommendEndsAt] = useState<string>("")
  const [recommendEndsAtError, setRecommendEndsAtError] =
    useState<boolean>(false)

  // name
  const [name, setName] = useState<string>("")

  // text
  const [text, setText] = useState<string>("")

  // primary topic
  const [primaryTopic, setPrimaryTopic] = useState<AutocompleteOption | null>(
    null
  )

  // secondary topics
  const [secondaryTopics, setSecondaryTopics] = useState<AutocompleteOption[]>(
    []
  )
  const [secondaryTopicsError, setSecondaryTopicsError] =
    useState<boolean>(false)

  // primary sdg
  const [primarySdg, setPrimarySdg] = useState<AutocompleteOption | null>(null)

  // secondary sdgs
  const [secondarySdgs, setSecondarySdgs] = useState<AutocompleteOption[]>([])
  const [secondarySdgsError, setSecondarySdgsError] = useState<boolean>(false)

  // primary sdg target
  const [primarySdgTarget, setPrimarySdgTarget] =
    useState<AutocompleteOption | null>(null)

  // secondary sdg targets
  const [secondarySdgTargets, setSecondarySdgTargets] = useState<
    AutocompleteOption[]
  >([])

  // category
  const [category, setCategory] = useState<AutocompleteOption | null>(null)
  const [esg, setEsg] = useState<string | null>("")

  // thumbnail
  const [thumbnail, setThumbnail] = useState<string>("")

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  // slides
  const slides = [
    {
      label: "Configuration",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Configuration
          </Typography>
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="language-select">Master language</InputLabel>
              <Select
                labelId="language-select"
                label="Master language"
                value={lang}
                onChange={(e) => {
                  setLang(e.target.value)
                }}
              >
                {languages.map((lang) => (
                  <MenuItem key={lang} value={lang}>
                    {lang.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="Type">Type</InputLabel>
              <Select
                labelId="Type"
                label="Type"
                value={type}
                onChange={(e) => {
                  setType(e.target.value as "Standard" | "Custom")
                }}
              >
                <MenuItem value="Standard">Standard</MenuItem>
                <MenuItem value="Custom">Custom</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="special-type">Special type</InputLabel>
              <Select
                labelId="special-type"
                label="Special type"
                value={specialType ?? "none"}
                onChange={(e) => {
                  if (e.target.value === "none") {
                    setSpecialType(null)
                  } else {
                    setSpecialType(e.target.value as EpisodeSpecialType)
                  }
                }}
              >
                <MenuItem value="none">None</MenuItem>
                {enumAsArray(EpisodeSpecialType).map((item: string, index) => (
                  <MenuItem key={index} value={item}>
                    {capitalizeFirstCharacter(item)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <FormControl fullWidth size="small">
            <InputLabel id="recommend-select">Recommend</InputLabel>
            <Select
              labelId="recommend-select"
              label="Recommend"
              value={doNotRecommend ? "no" : "yes"}
              onChange={(e) => {
                setDoNotRecommend(e.target.value === "no" ? true : false)
                setRecommendStartsAt("")
                setRecommendEndsAt("")
                setRecommendEndsAtError(false)
              }}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
          {!doNotRecommend && (
            <Stack direction="row" gap={2}>
              <ISODatePicker
                label="Recommendation start (optional)"
                date={recommendStartsAt}
                setDate={(newDate: string) => {
                  setRecommendStartsAt(newDate)

                  // check for errors
                  if (new Date(newDate) > new Date(recommendEndsAt)) {
                    setRecommendEndsAtError(true)
                  } else {
                    setRecommendEndsAtError(false)
                  }
                }}
                disablePast
              />
              <ISODatePicker
                label="Recommendation end (optional)"
                date={recommendEndsAt}
                setDate={(newDate: string) => {
                  setRecommendEndsAt(newDate)

                  // check for errors
                  if (new Date(newDate) < new Date(recommendStartsAt)) {
                    setRecommendEndsAtError(true)
                  } else {
                    setRecommendEndsAtError(false)
                  }
                }}
                disablePast
                minDate={recommendStartsAt}
                error={recommendEndsAt && recommendEndsAtError}
              />
            </Stack>
          )}
        </Stack>
      ),
    },
    {
      label: "Text",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Text
          </Typography>
          <TextField
            error={name.length !== 0 && name.length < 3}
            fullWidth
            label={`Title (${lang.toUpperCase()})`}
            variant="outlined"
            size="small"
            type="text"
            value={name}
            onChange={(e) => {
              setName(capitalizeFirstCharacter(e.target.value))
            }}
            helperText={
              name.length !== 0 && name.length < 3
                ? "Minimum length is 3 characters"
                : ""
            }
            onBlur={() => {
              if (name) {
                setName((current) => current.trim())
              }
            }}
          />
          <TextField
            error={text.length !== 0 && text.length < 3}
            fullWidth
            label={`Description (${lang.toUpperCase()})`}
            variant="outlined"
            size="small"
            type="text"
            multiline
            rows={2}
            value={text}
            onChange={(e) => {
              setText(capitalizeFirstCharacter(e.target.value))
            }}
            helperText={
              text.length !== 0 && text.length < 3
                ? "Minimum length is 3 characters"
                : ""
            }
            onBlur={() => {
              if (text) {
                setText((current) => current.trim())
              }
            }}
          />
        </Stack>
      ),
    },
    {
      label: "Category",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Category
          </Typography>
          <Autocomplete
            loading={categoriesLoading}
            fullWidth
            blurOnSelect
            size="small"
            value={category}
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={categoriesList.map((item) => {
              return {
                id: item.id,
                label: item.name,
              }
            })}
            renderInput={(params) => <TextField {...params} label="Category" />}
            onChange={(e: any, newValue: AutocompleteOption | null) => {
              setCategory(newValue)
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
          <ClearableSelect
            label="ESG"
            value={esg ?? ""}
            onChange={(newValue) => {
              setEsg(newValue)
            }}
          >
            {enumAsArray(Esg).map((key: string) => (
              <MenuItem key={key} value={key}>
                {capitalizeFirstCharacter(key)}
              </MenuItem>
            ))}
          </ClearableSelect>
        </Stack>
      ),
    },
    {
      label: "Topics",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Topics
          </Typography>
          <Autocomplete
            loading={topicsLoading}
            getOptionDisabled={(option: AutocompleteOption) => {
              if (
                secondaryTopics.filter(
                  (topic: AutocompleteOption) => topic.id === option.id
                )[0]
              ) {
                return true
              } else {
                return false
              }
            }}
            size="small"
            value={primaryTopic}
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              topicsList.length
                ? topicsList.map((topic: Topic) => {
                    return { label: topic.name, id: topic.id }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField {...params} label="Primary topic" />
            )}
            onChange={(e: any, newValue: AutocompleteOption | null) => {
              setPrimaryTopic(newValue)
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
          <Autocomplete
            loading={topicsLoading}
            getOptionDisabled={(option: AutocompleteOption) =>
              option.id === primaryTopic?.id
            }
            onBlur={() => {
              setSecondaryTopicsError(false)
            }}
            multiple
            size="small"
            value={secondaryTopics}
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              topicsList.length
                ? topicsList.map((topic: Topic) => {
                    return { label: topic.name, id: topic.id }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Secondary topics"
                error={secondaryTopicsError}
                helperText={
                  secondaryTopicsError
                    ? "The maximum number of secondary topics is 3"
                    : null
                }
              />
            )}
            onChange={(e: any, newValues: AutocompleteOption[]) => {
              if (newValues.length < 4) {
                setSecondaryTopics(newValues)
                setSecondaryTopicsError(false)
              } else {
                setSecondaryTopicsError(true)
              }
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
        </Stack>
      ),
    },
    {
      label: "SDGs",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            SDGs and SDG targets
          </Typography>
          <Autocomplete
            loading={sdgsLoading}
            getOptionDisabled={(option: AutocompleteOption) => {
              if (
                secondarySdgs.filter(
                  (sdg: AutocompleteOption) => sdg.id === option.id
                )[0]
              ) {
                return true
              } else {
                return false
              }
            }}
            size="small"
            value={primarySdg}
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              sdgsList.length
                ? sdgsList.map((sdg: Sdg) => {
                    let idForLabel =
                      sdg.id.charAt(4) === "0"
                        ? sdg.id.slice(5) + ". "
                        : sdg.id.slice(4) + ". "

                    return { label: idForLabel + sdg.name, id: sdg.id }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField {...params} label="Primary SDG" />
            )}
            onChange={(e: any, newValue: AutocompleteOption | null) => {
              setPrimarySdg(newValue)
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
          <Autocomplete
            loading={sdgsLoading}
            getOptionDisabled={(option: AutocompleteOption) =>
              option.id === primarySdg?.id
            }
            onBlur={() => {
              setSecondarySdgsError(false)
            }}
            multiple
            size="small"
            value={secondarySdgs}
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              sdgsList.length
                ? sdgsList.map((sdg: Sdg) => {
                    let idForLabel =
                      sdg.id.charAt(4) === "0"
                        ? sdg.id.slice(5) + ". "
                        : sdg.id.slice(4) + ". "

                    return { label: idForLabel + sdg.name, id: sdg.id }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Secondary SDGs"
                error={secondarySdgsError}
                helperText={
                  secondarySdgsError
                    ? "The maximum number of secondary SDGs is 3"
                    : null
                }
              />
            )}
            onChange={(e: any, newValues: AutocompleteOption[]) => {
              if (newValues.length < 4) {
                setSecondarySdgs(newValues)
                setSecondarySdgsError(false)
              } else {
                setSecondarySdgsError(true)
              }
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
          <Autocomplete
            loading={sdgTargetsLoading}
            getOptionDisabled={(option: AutocompleteOption) => {
              if (
                secondarySdgTargets.filter(
                  (sdgTarget: AutocompleteOption) => sdgTarget.id === option.id
                )[0]
              ) {
                return true
              } else {
                return false
              }
            }}
            size="small"
            value={primarySdgTarget}
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              sdgTargetsList.length
                ? sdgTargetsList.map((sdgTarget: SdgTarget) => {
                    return { label: sdgTarget.id.slice(4), id: sdgTarget.id }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField {...params} label="Primary SDG target" />
            )}
            onChange={(e: any, newValue: AutocompleteOption | null) => {
              setPrimarySdgTarget(newValue)
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
          <Autocomplete
            loading={sdgTargetsLoading}
            getOptionDisabled={(option: AutocompleteOption) =>
              option.id === primarySdgTarget?.id
            }
            multiple
            size="small"
            value={secondarySdgTargets}
            disablePortal
            isOptionEqualToValue={(
              option: AutocompleteOption,
              value: AutocompleteOption
            ) => option.id === value.id}
            options={
              sdgTargetsList.length
                ? sdgTargetsList.map((sdgTarget: SdgTarget) => {
                    return { label: sdgTarget.id.slice(4), id: sdgTarget.id }
                  })
                : []
            }
            renderInput={(params) => (
              <TextField {...params} label="Secondary SDG targets" />
            )}
            onChange={(e: any, newValues: AutocompleteOption[]) => {
              setSecondarySdgTargets(newValues)
            }}
            renderOption={renderOption}
            renderTags={renderTags}
          />
        </Stack>
      ),
    },
    {
      label: "Thumbnail",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Thumbnail
          </Typography>
          <ImagePicker
            filters={{
              category: "thumbnail",
            }}
            disableCategoryFilter
            title="Thumbnail"
            pickedImage={thumbnail}
            setPickedImage={(selectedImage: {
              url: string
              id: string
              description?: string
            }) => {
              setThumbnail(selectedImage.url)
            }}
            height={200}
            removeButton
          />
        </Stack>
      ),
    },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setLang("")
        setType("Standard")
        setSpecialType(null)
        setDoNotRecommend(false)
        setRecommendStartsAt("")
        setRecommendEndsAt("")
        setRecommendEndsAtError(false)
        setName("")
        setText("")
        setPrimaryTopic(null)
        setSecondaryTopics([])
        setSecondaryTopicsError(false)
        setPrimarySdg(null)
        setSecondarySdgs([])
        setSecondarySdgsError(false)
        setPrimarySdgTarget(null)
        setSecondarySdgTargets([])
        setCategory(null)
        setEsg("")
        setThumbnail("")
        setCurrentSlide(0)
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (name.length || text.length) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [name, text])

  // create episode
  const createEpisode = async () => {
    let input: {
      lang: string
      title: string
      text: string
      image: string
      topics: { id: string; primary: boolean }[]
      sdgs: { id: string; primary: boolean }[]
      sdgTargets: { id: string; primary: boolean }[]
      category: { id: string }
      defaultTranslation: { title: string; slides: []; quiz: [] }
      doNotRecommend: boolean
      type: string
      specialType?: EpisodeSpecialType
      esg?: string
      recommendStartsAt?: string
      recommendEndsAt?: string
    } = {
      lang: lang,
      title: name,
      text: text,
      image: thumbnail.length ? thumbnail : null,
      topics: [
        { id: primaryTopic.id, primary: true },
        ...secondaryTopics.map((topic) => {
          return { id: topic.id, primary: false }
        }),
      ],
      sdgs: [
        { id: primarySdg.id, primary: true },
        ...secondarySdgs.map((sdg) => {
          return { id: sdg.id, primary: false }
        }),
      ],
      sdgTargets: [
        ...secondarySdgTargets.map((sdg) => {
          return { id: sdg.id, primary: false }
        }),
      ],
      category: { id: category.id },
      defaultTranslation: {
        title: name,
        slides: [],
        quiz: [],
      },
      doNotRecommend: doNotRecommend,
      type: type,
    }

    if (primarySdgTarget) {
      input.sdgTargets.unshift({ id: primarySdgTarget.id, primary: true })
    }
    if (specialType) {
      input.specialType = specialType
    }
    if (esg) {
      input.esg = esg
    }
    if (recommendStartsAt && recommendEndsAt) {
      input.recommendStartsAt = recommendStartsAt
      input.recommendEndsAt = recommendEndsAt
    }

    const newEpisode = await createNewEpisode(input)

    setDialogOpen(false)

    setAnimation(false)
    setTimeout(() => {
      navigate(`/episodes/${newEpisode.id}`, { state: { enableEditing: true } })
    }, 250)
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText={topBarText}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            {topBarText}
          </Typography>
          <Stepper
            activeStep={currentSlide}
            style={{
              paddingTop: 24,
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 8,
              maxWidth: "100%",
              overflow: "overlay",
            }}
          >
            {slides.map((slide) => {
              return (
                <Step key={slide.label}>
                  <StepLabel>{slide.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (!slides[currentSlide - 1]) {
                    if (showDiscardDialog) {
                      setDiscardDialogOpen(true)
                    } else {
                      handleDialogChange(e)
                    }
                  } else {
                    setCurrentSlide(currentSlide - 1)
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  (currentSlide === 0 && !lang.length) ||
                  (currentSlide === 0 && recommendEndsAtError) ||
                  (currentSlide === 0 &&
                    !doNotRecommend &&
                    recommendStartsAt &&
                    !recommendEndsAt) ||
                  (currentSlide === 0 &&
                    !doNotRecommend &&
                    !recommendStartsAt &&
                    recommendEndsAt) ||
                  (currentSlide === 1 &&
                    (name.length < 3 || text.length < 3)) ||
                  (currentSlide === 2 && !category) ||
                  (currentSlide === 3 && !primaryTopic) ||
                  (currentSlide === 4 && !primarySdg)
                    ? true
                    : false
                }
                onMouseDown={
                  !slides[currentSlide + 1]
                    ? () => {
                        createEpisode()
                      }
                    : () => {
                        setCurrentSlide(currentSlide + 1)
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Save" : "Next"}
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={loading} />
    </Dialog>
  )
}

export default CreateEpisodeDialog
