import {
  Button,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import { TagsContext } from "../../../controllers/tags"
import ImagePicker from "../../global/imagePicker"

const SdgTranslationsEdit = ({
  itemIndex,
  errors,
}: {
  itemIndex: number
  errors: { type: string; error: boolean }[]
}) => {
  const { showPadding } = useContext(MainContext)
  const { currentSdg, setCurrentSdg, editMode } = useContext(TagsContext)

  // current item
  const currentItem = currentSdg.translations[itemIndex]

  // errors
  const nameError = errors.filter(
    (item) => item.type === "name" + currentSdg.translations[itemIndex].lang
  )[0].error

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="h6" className="card-title">
            Details
          </Typography>
          <Button
            size="small"
            variant="outlined"
            style={{ height: 26, fontSize: 12 }}
            onClick={() => {
              currentSdg.translations[itemIndex].name = currentSdg.name
              currentSdg.translations[itemIndex].image = currentSdg.image
              setCurrentSdg({ ...currentSdg })
            }}
          >
            Copy from main
          </Button>
        </Stack>
        <TextField
          fullWidth
          size="small"
          label="Name"
          value={currentItem.name}
          onChange={(e) => {
            currentSdg.translations[itemIndex].name = e.target.value
            setCurrentSdg({ ...currentSdg })
          }}
          onBlur={(e) => {
            currentSdg.translations[itemIndex].name =
              currentSdg.translations[itemIndex].name.trim()
            setCurrentSdg({ ...currentSdg })
          }}
          disabled={!editMode}
          error={nameError}
          helperText={nameError ? "This field is required" : ""}
        />
        <ImagePicker
          title="Image"
          pickedImage={currentItem.image}
          setPickedImage={(selectedImage: {
            url: string
            id: string
            description?: string
          }) => {
            currentSdg.translations[itemIndex].image = selectedImage.url
            setCurrentSdg({ ...currentSdg })
          }}
          filters={{ category: "thumbnail" }}
          disableCategoryFilter
          height={200}
        />
      </Stack>
    </CardContent>
  )
}

export default SdgTranslationsEdit
