import {
  Chip,
  LinearProgress,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import ListContainer from "../../components/global/listContainer"
import ListTable from "../../components/global/listTable"
import TableSpinner from "../../components/global/tableSpinner"
import { MainContext } from "../../controllers/main"
import {
  calculateTimeElapsed,
  capitalizeFirstCharacter,
  registerAnalyticsEvent,
} from "../../services/utilities/utility"
import TranslationsStack from "../../components/global/translationsStack"
import { MissionsContext } from "../../controllers/missions"
import MissionsListTopBar from "../../components/mission/missionsListTopBar"
import { MissionDifficultyLevel } from "../../services/config/enum"
import PrimaryBadge from "../../components/global/primaryBadge"
import { TrackChangesRounded } from "@mui/icons-material"
import CreateMissionDialog from "../../components/mission/createMissionDialog"
import CopyToClipboardButton from "../../components/global/copyToClipboardButton"
// import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"

const MissionsList = () => {
  const { setAnimation, showPadding, cmdPressed } = useContext(MainContext)
  const {
    loading,
    missionsList,
    missionsListNextToken,
    getMissionsList,
    searchMissionsList,
    loadMoreMissions,
    hasSearched,
    updatingList,
    setUpdatingList,
    searchTitle,
    setSearchTitle,
    searchLang,
    searchStage,
    searchSuspended,
    searchType,
    searchSpecialType,
    searchTimeframe,
    searchDifficultyLevel,
  } = useContext(MissionsContext)
  const navigate = useNavigate()

  // list background update
  const backgroundUpdate = () => {
    if (!missionsList.length) {
      getMissionsList()
    } else if (!loading && !hasSearched) {
      setUpdatingList(true)
      getMissionsList(false)
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    backgroundUpdate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create mission dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // load more button loading
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // register analytics event
  useEffect(() => {
    registerAnalyticsEvent({ type: "page_view", name: "missions_list" })
  }, [])

  return (
    <ListContainer style={{ position: "relative" }}>
      {updatingList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 143,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <MissionsListTopBar
        loading={loading}
        textFieldValue={searchTitle}
        textFieldPlaceholder="Search Missions"
        addButtonLabel="Add Mission"
        textFieldOnChange={(e) => {
          setSearchTitle(e.target.value)
          if (e.target.value.length === 0 && hasSearched) {
            setUpdatingList(true)

            if (
              !searchLang &&
              !searchStage &&
              searchSuspended === null &&
              !searchType &&
              !searchSpecialType &&
              !searchTimeframe &&
              !searchDifficultyLevel
            ) {
              getMissionsList(false)
            } else {
              searchMissionsList()
            }
          }
        }}
        textFieldOnKeyDown={(e) => {
          if (e.key === "Enter" && searchTitle.length > 2) {
            searchMissionsList()
          }
        }}
        cancelButtonOnClick={() => {
          setSearchTitle("")
          if (hasSearched) {
            setUpdatingList(true)

            if (
              !searchLang &&
              !searchStage &&
              searchSuspended === null &&
              !searchType &&
              !searchSpecialType &&
              !searchTimeframe &&
              !searchDifficultyLevel
            ) {
              getMissionsList(false)
            } else {
              searchMissionsList()
            }
          }
        }}
        searchButtonOnClick={() => {
          searchMissionsList()
        }}
        addButtonOnClick={handleDialogChange}
      />
      {loading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
          headingItems={[
            "Stage",
            // "Image",
            "Title & ID",
            "Translations",
            "Type",
            "Timeframe",
            "Target",
            "Difficulty",
            "Updated",
          ]}
          nextToken={missionsListNextToken}
          loadingMoreItems={loadMoreLoading}
          setLoadingMoreItems={setLoadMoreLoading}
          loadMore={
            !searchTitle.length &&
            !searchLang &&
            !searchStage &&
            searchSuspended === null &&
            !searchType &&
            !searchSpecialType &&
            !searchTimeframe &&
            !searchDifficultyLevel
              ? loadMoreMissions
              : async () => {
                  const result = await searchMissionsList(false, true)
                  return result
                }
          }
          tableBody={missionsList.map((item) => (
            <TableRow
              key={item.id}
              hover
              style={{
                cursor: "pointer",
              }}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
              onClick={() => {
                if (cmdPressed) {
                  window.open(`${window.location.href}/${item.id}`)
                } else {
                  setAnimation(false)
                  setTimeout(() => {
                    navigate(`/missions/${item.id}`)
                  }, 250)
                }
              }}
            >
              {item.suspended ? (
                <TableCell>
                  <Chip size="small" label="Suspended" color="error" />
                </TableCell>
              ) : (
                <TableCell style={{ position: "relative" }}>
                  {item.stage === "PUBLISHED" || item.hasSibling ? (
                    <Chip
                      size="small"
                      label="Live"
                      color="success"
                      style={{ position: "relative", zIndex: 1 }}
                    />
                  ) : item.stage === "ARCHIVED" ? (
                    <Chip
                      size="small"
                      label="Archived"
                      style={{ position: "relative", zIndex: 1 }}
                    />
                  ) : (
                    <Chip
                      size="small"
                      label="Draft"
                      color="warning"
                      style={{ position: "relative", zIndex: 1 }}
                    />
                  )}
                  {item.hasSibling ? (
                    <Chip
                      size="small"
                      label="Draft"
                      color="warning"
                      style={{ position: "absolute", left: 18 }}
                    />
                  ) : null}
                </TableCell>
              )}
              {/* <TableCell>
                <div
                  style={{
                    width: 42,
                    height: 42,
                    borderRadius: 4,
                    backgroundImage: `url(${item.image ?? noImagePlaceholder})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </TableCell> */}
              <TableCell>
                <Stack sx={{ gap: -5 }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{ height: 22.5 }}
                    gap={1}
                  >
                    <Typography
                      style={{
                        fontSize: 15,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: 320,
                      }}
                    >
                      {
                        item.document.items.filter(
                          (documentItem) => documentItem.isDefault
                        )[0].title
                      }
                    </Typography>
                    {item.specialType && (
                      <Chip
                        size="small"
                        color="info"
                        label={capitalizeFirstCharacter(item.specialType)}
                        style={{
                          backgroundColor: "#5562B4",
                          height: 18,
                          fontSize: 11,
                          fontWeight: 600,
                        }}
                      />
                    )}
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{ marginLeft: -3 }}
                  >
                    <CopyToClipboardButton textToCopy={item.id} size="small" />
                    <Typography
                      variant="caption"
                      style={{
                        fontSize: 10,
                        color: "gray",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.id}
                    </Typography>
                  </Stack>
                </Stack>
              </TableCell>
              <TableCell>
                <TranslationsStack
                  documentItems={item.document.items.map((item) => {
                    return {
                      ...item,
                      body: [],
                    }
                  })}
                />
              </TableCell>
              <TableCell>
                <Chip
                  label={capitalizeFirstCharacter(item.type.toLowerCase())}
                  size="small"
                />
              </TableCell>
              <TableCell>
                <Chip
                  label={capitalizeFirstCharacter(item.timeframe.toLowerCase())}
                  size="small"
                />
              </TableCell>
              <TableCell>
                <PrimaryBadge
                  count={item.target}
                  icon={<TrackChangesRounded style={{ fontSize: 12 }} />}
                />
              </TableCell>
              <TableCell>
                <Chip
                  label={capitalizeFirstCharacter(item.difficultyLevel)}
                  size="small"
                  style={{
                    backgroundColor:
                      item.difficultyLevel === MissionDifficultyLevel.easy
                        ? "#BAE1CB"
                        : item.difficultyLevel === MissionDifficultyLevel.medium
                        ? "#F9DCA9"
                        : item.difficultyLevel === MissionDifficultyLevel.hard
                        ? "#F9A9A9"
                        : "#BFC4E3",
                  }}
                />
              </TableCell>
              <TableCell style={{ minWidth: 120 }}>
                <Typography variant="caption">
                  {calculateTimeElapsed(item.updatedAt)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        />
      )}
      <CreateMissionDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
      />
    </ListContainer>
  )
}

export default MissionsList
