import { CircularProgress } from "@mui/material"

const TableSpinner = ({ height = "calc(100vh - 200px)" }) => {
  return (
    <div
      style={{
        width: "100%",
        height: height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  )
}

export default TableSpinner
