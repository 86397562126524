import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material"
import {
  Backdrop,
  Card,
  CardContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react"
import { LanguagesContext } from "../../controllers/languages"
import { Slide } from "@aworld/app-preview"
import { EpisodesContext } from "../../controllers/episodes"

const EpisodeGlobalPreview = ({
  isGlobalPreviewOpen,
  setIsGlobalPreviewOpen,
  backDisabled,
  forwardDisabled,
  currentPreviewSlide,
  setCurrentPreviewSlide,
  currentPreviewLanguage,
  setCurrentPreviewLanguage,
}: {
  isGlobalPreviewOpen: boolean
  setIsGlobalPreviewOpen: Dispatch<SetStateAction<boolean>>
  backDisabled: boolean
  forwardDisabled: boolean
  currentPreviewSlide: number
  setCurrentPreviewSlide: Dispatch<SetStateAction<number>>
  currentPreviewLanguage: string
  setCurrentPreviewLanguage: Dispatch<SetStateAction<string>>
}) => {
  const { languages } = useContext(LanguagesContext)
  const { slideConstraintsList, currentEpisode } = useContext(EpisodesContext)

  // refs to make changeSlide function not update
  const forwardDisabledRef = useRef(forwardDisabled)
  const backDisabledRef = useRef(backDisabled)
  const currentPreviewSlideRef = useRef(currentPreviewSlide)

  useEffect(() => {
    forwardDisabledRef.current = forwardDisabled
    backDisabledRef.current = backDisabled
    currentPreviewSlideRef.current = currentPreviewSlide
  }, [forwardDisabled, backDisabled, currentPreviewSlide])

  // event listener to change slide with arrow keys
  const changeSlide = useCallback((e: any) => {
    if (e.key === "ArrowRight" && !forwardDisabledRef.current) {
      setCurrentPreviewSlide(currentPreviewSlideRef.current + 1)
    } else if (e.key === "ArrowLeft" && !backDisabledRef.current) {
      setCurrentPreviewSlide(currentPreviewSlideRef.current - 1)
    }
  }, [])

  useEffect(() => {
    if (isGlobalPreviewOpen) {
      document.body.addEventListener("keydown", changeSlide)
    } else {
      document.body.removeEventListener("keydown", changeSlide)
    }
  }, [isGlobalPreviewOpen])

  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isGlobalPreviewOpen}
      onClick={() => {
        setIsGlobalPreviewOpen(false)
        setTimeout(() => {
          setCurrentPreviewSlide(0)
        }, 200)
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {currentEpisode.translations.find(
          (item) => item.lang === currentPreviewLanguage
        ).slides.length ? (
          <div
            style={{
              marginTop: 85,
            }}
          >
            {currentEpisode.translations.find(
              (item) => item.lang === currentPreviewLanguage
            ).slides[currentPreviewSlide] ? (
              <Slide
                slide={
                  currentEpisode.translations.find(
                    (item) => item.lang === currentPreviewLanguage
                  ).slides[currentPreviewSlide]
                }
                constraints={slideConstraintsList.find(
                  (item) =>
                    item.type ===
                    currentEpisode.translations.find(
                      (item) => item.lang === currentPreviewLanguage
                    ).slides[currentPreviewSlide].slideType
                )}
                className="preview"
                primaryTopicName={
                  currentEpisode.topics.find((topic) => topic.primary)
                    ? currentEpisode.topics.find((topic) => topic.primary).topic
                        .name
                    : null
                }
                episodeTitle={
                  currentEpisode.translations.find(
                    (item) => item.lang === currentPreviewLanguage
                  ).title ?? null
                }
                numberOfSlides={
                  currentEpisode.translations.find(
                    (item) => item.lang === currentPreviewLanguage
                  ).slides.length
                }
                numberOfQuiz={
                  currentEpisode.translations.find(
                    (item) => item.lang === currentPreviewLanguage
                  ).quiz.length
                }
                currentSlide={currentPreviewSlide}
              />
            ) : (
              <Slide
                slide={
                  currentEpisode.translations.find(
                    (item) => item.lang === currentPreviewLanguage
                  ).quiz[
                    currentPreviewSlide -
                      currentEpisode.translations.find(
                        (item) => item.lang === currentPreviewLanguage
                      ).slides.length
                  ]
                }
                constraints={slideConstraintsList.find(
                  (item) => item.type === "EmbedSlide"
                )}
                className="preview"
                episodeTitle={
                  currentEpisode.translations.find(
                    (item) => item.lang === currentPreviewLanguage
                  ).title ?? null
                }
                numberOfSlides={
                  currentEpisode.translations.find(
                    (item) => item.lang === currentPreviewLanguage
                  ).slides.length
                }
                numberOfQuiz={
                  currentEpisode.translations.find(
                    (item) => item.lang === currentPreviewLanguage
                  ).quiz.length
                }
                currentSlide={currentPreviewSlide}
              />
            )}
          </div>
        ) : null}
        <Card
          style={{ position: "absolute", top: 10, width: 350, height: 70 }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <CardContent>
            <div
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <FormControl fullWidth size="small">
                <InputLabel id="language-select-global-preview">
                  Language
                </InputLabel>
                <Select
                  labelId="language-select-global-preview"
                  label="Language"
                  value={currentPreviewLanguage}
                  onChange={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    setCurrentPreviewSlide(0)
                    setCurrentPreviewLanguage(e.target.value)
                  }}
                >
                  {languages
                    .filter((lang) => {
                      if (
                        currentEpisode.translations.filter(
                          (item) => item.lang === lang
                        )[0]
                      ) {
                        return lang
                      }
                    })
                    .map((lang) => (
                      <MenuItem key={lang} value={lang}>
                        {lang.toUpperCase()}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </CardContent>
        </Card>
        <div
          style={{
            backgroundColor: "white",
            width: 50,
            height: 50,
            borderRadius: 25,
            position: "absolute",
            left: 70,
            top: 0,
            bottom: 0,
            marginTop: "auto",
            marginBottom: "auto",
            cursor: backDisabled ? "default" : "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "6px 10px 30px -12px rgba(0,0,0,0.75)",
          }}
          onClick={(e: any) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <IconButton
            style={{ width: 50, height: 50 }}
            onClick={(e: any) => {
              e.preventDefault()
              e.stopPropagation()
              setCurrentPreviewSlide(currentPreviewSlide - 1)
            }}
            disabled={backDisabled}
          >
            <ArrowBackIosRounded
              color={backDisabled ? "disabled" : "primary"}
            />
          </IconButton>
        </div>
        <div
          style={{
            backgroundColor: "white",
            width: 50,
            height: 50,
            borderRadius: 25,
            position: "absolute",
            right: 70,
            top: 0,
            bottom: 0,
            marginTop: "auto",
            marginBottom: "auto",
            cursor: forwardDisabled ? "default" : "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "6px 10px 30px -12px rgba(0,0,0,0.75)",
          }}
          onClick={(e: any) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <IconButton
            style={{ width: 50, height: 50 }}
            onClick={(e: any) => {
              e.preventDefault()
              e.stopPropagation()
              setCurrentPreviewSlide(currentPreviewSlide + 1)
            }}
            disabled={forwardDisabled}
          >
            <ArrowForwardIosRounded
              color={forwardDisabled ? "disabled" : "primary"}
            />
          </IconButton>
        </div>
      </div>
    </Backdrop>
  )
}

export default EpisodeGlobalPreview
