import { LinearProgress, TableCell, TableRow, Typography } from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import ListContainer from "../../components/global/listContainer"
import ListTable from "../../components/global/listTable"
import TableSpinner from "../../components/global/tableSpinner"
import { MainContext } from "../../controllers/main"
import CreateTeamDialog from "../../components/teams/createTeamDialog"
import { LeaguesContext } from "../../controllers/leagues"
import LeaguesListTopBar from "../../components/leagues/leaguesListTopBar"
import { useNavigate } from "react-router-dom"
import TitleTableCell from "../../components/global/titleTableCell"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import TranslationsStack from "../../components/global/translationsStack"
import { calculateTimeElapsed } from "../../services/utilities/utility"

const LeaguesList = () => {
  const navigate = useNavigate()
  const { showPadding, cmdPressed, setAnimation } = useContext(MainContext)
  const {
    loading,
    updatingList,
    setUpdatingList,
    leaguesList,
    getLeaguesList,
    leaguesListNextToken,
    loadMoreLeagues,
    hasSearched,
  } = useContext(LeaguesContext)

  // list background update
  const backgroundUpdate = () => {
    if (!leaguesList.length) {
      getLeaguesList()
    } else if (!loading && !hasSearched) {
      setUpdatingList(true)
      getLeaguesList(false)
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    backgroundUpdate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create team dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // load more button loading
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  return (
    <ListContainer style={{ position: "relative" }}>
      {updatingList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 88,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <LeaguesListTopBar />
      {loading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 150px)"
              : "calc(100vh - 118px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 150px)"
              : "calc(100vh - 118px)"
          }
          headingItems={["Image", "Title & ID", "Translations", "Updated"]}
          nextToken={leaguesListNextToken}
          loadingMoreItems={loadMoreLoading}
          setLoadingMoreItems={setLoadMoreLoading}
          loadMore={loadMoreLeagues}
          tableBody={leaguesList.map((item) => (
            <TableRow
              key={item.id}
              hover
              style={{
                cursor: "pointer",
              }}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
              onClick={() => {
                if (cmdPressed) {
                  window.open(`${window.location.href}/${item.id}`)
                } else {
                  setAnimation(false)
                  setTimeout(() => {
                    navigate(`/leagues/${item.id}`)
                  }, 250)
                }
              }}
            >
              <TableCell>
                <div
                  style={{
                    width: 42,
                    height: 42,
                    borderRadius: 4,
                    backgroundImage: `url(${
                      item.document.items.find((item) => item.isDefault).logo ??
                      noImagePlaceholder
                    })`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </TableCell>
              <TitleTableCell
                title={
                  item.document.items.filter(
                    (documentItem) => documentItem.isDefault
                  )[0].title
                }
                id={item.id}
              />
              <TableCell>
                <TranslationsStack documentItems={item.document.items} />
              </TableCell>
              <TableCell style={{ minWidth: 120 }}>
                <Typography variant="caption">
                  {calculateTimeElapsed(item.updatedAt)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        />
      )}
      <CreateTeamDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
        topBarText="Add Team"
      />
    </ListContainer>
  )
}

export default LeaguesList
