import { Cancel, ExpandMoreRounded, Search } from "@mui/icons-material"
import {
  Button,
  Divider,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material"
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { ActionsContext } from "../../controllers/actions"
import ListFilters from "../global/listFilters"
import { CSVLink } from "react-csv"
import { LoadingButton } from "@mui/lab"
import { LanguagesContext } from "../../controllers/languages"
import { MainContext } from "../../controllers/main"
import { ActionType } from "../../services/config/enum"
import { enumAsArray } from "../../services/utilities/utility"
import { AutocompleteOption } from "../../services/config/interfaces"

const ActionsListTopBar = ({
  loading,
  textFieldValue,
  textFieldPlaceholder,
  addButtonLabel,
  addButtonOnClick,
  textFieldOnChange,
  textFieldOnKeyDown,
  cancelButtonOnClick,
  searchButtonOnClick,
  forDialog = false,
  addButtonDisabled = false,
  categoryFilterDisabled = false,
  addFromJsonButtonOnClick,
}: {
  loading: boolean
  textFieldValue: string
  textFieldPlaceholder: string
  addButtonLabel: string
  textFieldOnChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  textFieldOnKeyDown: KeyboardEventHandler<HTMLDivElement>
  cancelButtonOnClick: MouseEventHandler<HTMLDivElement>
  searchButtonOnClick: MouseEventHandler<HTMLButtonElement>
  addButtonOnClick: MouseEventHandler<HTMLButtonElement>
  forDialog?: boolean
  addButtonDisabled?: boolean
  categoryFilterDisabled?: boolean
  addFromJsonButtonOnClick: () => void
}) => {
  const {
    searchIsRepeatable,
    // setSearchIsRepeatable,
    searchLang,
    setSearchLang,
    searchSavingMetrics,
    setSearchSavingMetrics,
    searchSdg,
    setSearchSdg,
    searchSdgTarget,
    setSearchSdgTarget,
    searchTimesPerDay,
    // setSearchTimesPerDay,
    searchCategory,
    setSearchCategory,
    searchType,
    setSearchType,
    searchFeatured,
    setSearchFeatured,
    updatingList,
    setUpdatingList,
    getActionsList,
    searchActionsList,
    getAllActionsList,
  } = useContext(ActionsContext)
  const { languagesForEpisodeTabs } = useContext(LanguagesContext)
  const { showAdvancedOption } = useContext(MainContext)

  // search when filters update
  const [firstRender, setFirstRender] = useState<boolean>(true)

  useEffect(() => {
    if (!firstRender) {
      if (
        searchIsRepeatable === null &&
        !searchLang &&
        !searchSavingMetrics &&
        !searchSdg &&
        !searchSdgTarget &&
        !searchCategory &&
        !searchType &&
        !searchTimesPerDay &&
        searchFeatured === null &&
        !textFieldValue.length
      ) {
        setUpdatingList(true)
        getActionsList(false)
      } else {
        searchActionsList()
      }
    } else {
      setFirstRender(false)
    }
  }, [
    searchIsRepeatable,
    searchLang,
    searchSavingMetrics,
    searchSdg,
    searchSdgTarget,
    searchCategory,
    searchType,
    searchTimesPerDay,
    searchFeatured,
  ])

  // export menu open
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // csv download button
  const csvButtonRef = useRef(null)
  const [csvData, setCsvData] = useState<string[][]>([])
  const [csvLoading, setCsvLoading] = useState<boolean>(false)

  useEffect(() => {
    if (csvData.length && !csvLoading) {
      csvButtonRef.current.link.click()
    }
  }, [csvData])

  // export button on click
  const exportOnClick = async (titlesLanguage: string) => {
    handleClose()

    setCsvLoading(true)
    setCsvData([])
    const data = await getAllActionsList(titlesLanguage)
    setCsvData(data)
    setCsvLoading(false)
  }

  return (
    <Stack
      style={{
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 4,
      }}
      spacing={1}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          disabled={loading || updatingList}
          size="small"
          fullWidth
          id="outlined-basic"
          placeholder={textFieldPlaceholder}
          variant="outlined"
          value={textFieldValue}
          onChange={textFieldOnChange}
          onKeyDown={textFieldOnKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: textFieldValue.length > 0 && (
              <InputAdornment position="start">
                <div
                  style={{
                    marginTop: 6,
                    marginRight: -6,
                    cursor: "pointer",
                  }}
                  onClick={loading ? () => {} : cancelButtonOnClick}
                >
                  <Cancel style={{ width: 18 }} />
                </div>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="outlined"
          disabled={textFieldValue.length < 3 || loading || updatingList}
          onClick={searchButtonOnClick}
          style={{ height: 40, minWidth: 74 }}
        >
          Search
        </Button>
        {!forDialog && <Divider orientation="vertical" flexItem />}
        {!forDialog && (
          <div>
            <LoadingButton
              id="export-button"
              variant="contained"
              disabled={loading || addButtonDisabled}
              loading={csvLoading}
              aria-controls={menuOpen ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? "true" : undefined}
              style={{
                whiteSpace: "nowrap",
                minWidth: "max-content",
                height: 40,
              }}
              onClick={handleClick}
              endIcon={<ExpandMoreRounded />}
            >
              Export
            </LoadingButton>
            <Menu
              id="export-menu"
              aria-labelledby="export-button"
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {languagesForEpisodeTabs.map((item) =>
                item.value !== "general" &&
                item.value !== "zh_cn" &&
                item.value !== "zh_tw" ? (
                  <MenuItem
                    key={item.value}
                    onClick={() => {
                      exportOnClick(item.value)
                    }}
                  >
                    {item.flag + " " + item.label}
                  </MenuItem>
                ) : null
              )}
            </Menu>
            <CSVLink
              data={csvData}
              ref={csvButtonRef}
              filename={`actions-list-${new Date()
                .toISOString()
                .slice(0, 10)}.csv`}
              style={{
                textDecoration: "none",
              }}
            />
          </div>
        )}
        {!forDialog && showAdvancedOption === "yes" ? (
          <Button
            variant="contained"
            disabled={loading}
            style={{
              whiteSpace: "nowrap",
              minWidth: "max-content",
              height: 40,
            }}
            onClick={addFromJsonButtonOnClick}
          >
            Add from JSON
          </Button>
        ) : null}
        {!forDialog && (
          <Button
            variant="contained"
            disabled={loading}
            style={{
              whiteSpace: "nowrap",
              minWidth: "max-content",
              height: 40,
            }}
            onClick={addButtonOnClick}
          >
            {showAdvancedOption === "yes" ? "Add manually" : addButtonLabel}
          </Button>
        )}
      </Stack>
      <ListFilters
        disabled={loading || updatingList}
        filters={[
          { type: "language", value: searchLang, setValue: setSearchLang },
          {
            type: "singular",
            label: "Type",
            value: searchType,
            setValue: setSearchType,
            options: [
              ...(enumAsArray(ActionType).map((key) => {
                return {
                  id: key,
                  label: key,
                }
              }) as AutocompleteOption[]),
            ],
          },
          {
            type: "multiple",
            value: searchSavingMetrics,
            setValue: setSearchSavingMetrics,
            label: "Savings",
            options: [
              { label: "Co2 💨", id: "co2Saving" },
              { label: "Water 💧", id: "waterSaving" },
              { label: "Energy ⚡", id: "energySaving" },
            ],
          },
          {
            type: "boolean",
            value: searchFeatured,
            setValue: setSearchFeatured,
            label: "Featured",
          },
          {
            type: "category",
            value: searchCategory,
            setValue: setSearchCategory,
            disabled: categoryFilterDisabled,
          },
          { type: "sdg", value: searchSdg, setValue: setSearchSdg },
          {
            type: "sdgTarget",
            value: searchSdgTarget,
            setValue: setSearchSdgTarget,
          },
          // {
          //   type: "boolean",
          //   value: searchIsRepeatable,
          //   setValue: setSearchIsRepeatable,
          //   label: "Repeatable",
          // },
          // {
          //   type: "int",
          //   value: searchTimesPerDay,
          //   setValue: setSearchTimesPerDay,
          //   label: "Times per day",
          // },
        ]}
      />
    </Stack>
  )
}

export default ActionsListTopBar
