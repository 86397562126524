import { DeleteRounded, DragHandleRounded } from "@mui/icons-material"
import {
  Card,
  CardContent,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import { Draggable } from "react-beautiful-dnd"
import { JourneysContext } from "../../controllers/journeys"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import "../../styles/episodesStack.scss"
import TranslationsStackMultiple from "../global/translationsStackMultiple"

const EpisodesStack = ({
  setRemoveEpisodeDialogOpen,
  setEpisodeIdToRemove,
}: {
  setRemoveEpisodeDialogOpen: Dispatch<SetStateAction<boolean>>
  setEpisodeIdToRemove: Dispatch<SetStateAction<string>>
}) => {
  const { currentJourney, editMode } = useContext(JourneysContext)

  return (
    <>
      {currentJourney.episodes.map((item, index) => (
        // draggable item (episode)
        <Draggable
          key={item.episode.id.toString()}
          draggableId={item.episode.id.toString()}
          index={index}
        >
          {(provided) => (
            <Card
              variant="outlined"
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(`/episodes/${item.episode.id}`)
              }}
              className="episodes-stack-item"
              {...provided.draggableProps}
              ref={provided.innerRef}
              sx={{
                boxShadow: "none",
                borderTopLeftRadius: index === 0 ? undefined : 0,
                borderTopRightRadius: index === 0 ? undefined : 0,
                borderBottomLeftRadius:
                  index === currentJourney.episodes.length - 1 ? undefined : 0,
                borderBottomRightRadius:
                  index === currentJourney.episodes.length - 1 ? undefined : 0,
                borderTop: index === 0 ? undefined : "none",
              }}
            >
              <CardContent
                id={item.episode.id}
                style={{
                  overflow: "hidden",
                  height: 65,
                  paddingTop: 0,
                  paddingBottom: 0,
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={3}
                  style={{
                    marginLeft: !editMode ? -47 : 0,
                    transition: "200ms",
                    height: 65,
                    width: "90%",
                  }}
                >
                  <div
                    style={{
                      width: 30,
                      height: 26,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      opacity: !editMode ? 0 : 1,
                      transition: "100ms",
                      cursor: !editMode ? "default" : "grab",
                    }}
                    {...provided.dragHandleProps}
                  >
                    <DragHandleRounded />
                  </div>
                  <div style={{ position: "relative", marginLeft: 16 }}>
                    {item.episode.stage === "PUBLISHED" ||
                    item.episode.hasSibling ? (
                      <Chip
                        size="small"
                        label="Live"
                        color="success"
                        style={{ position: "relative", zIndex: 1, width: 40 }}
                      />
                    ) : (
                      <Chip
                        size="small"
                        label="Draft"
                        color="warning"
                        style={{ position: "relative", zIndex: 1, width: 40 }}
                      />
                    )}
                    {item.episode.hasSibling ? (
                      <Chip
                        size="small"
                        label="Draft"
                        color="warning"
                        style={{
                          position: "absolute",
                          left: 10,
                          top: 1,
                          width: 40,
                        }}
                      />
                    ) : null}
                  </div>
                  <div
                    style={{
                      width: 40,
                      minWidth: 40,
                      height: 40,
                      borderRadius: 4,
                      backgroundImage: `url(${
                        item.episode.image ?? noImagePlaceholder
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                  <Tooltip title={item.episode.title}>
                    <Typography
                      style={{
                        width: "calc(100% - 700px)",
                        minWidth: 44,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        cursor: "default",
                      }}
                    >
                      {item.episode.title}
                    </Typography>
                  </Tooltip>
                  <TranslationsStackMultiple
                    publishedLangs={item.episode.publishedLangs}
                    draftedLangs={item.episode.draftedLangs}
                  />
                </Stack>
                <IconButton
                  style={{
                    position: "absolute",
                    right: 18,
                    opacity: editMode ? 1 : 0,
                    transition: "100ms",
                    zIndex: 1,
                  }}
                  onClick={
                    editMode
                      ? (e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          setRemoveEpisodeDialogOpen(true)
                          setEpisodeIdToRemove(item.episode.id)
                        }
                      : null
                  }
                  color="error"
                >
                  <DeleteRounded style={{ width: 22 }} />
                </IconButton>
              </CardContent>
            </Card>
          )}
        </Draggable>
      ))}
    </>
  )
}

export default EpisodesStack
