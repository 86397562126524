import { useContext, useEffect, useState } from "react"
import { EpisodesContext } from "../../controllers/episodes"
import EpisodeSlide from "../../models/episodeSlide"
import SlideConstraints from "../../models/slideConstraints"
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import {
  onSlideBoxBackColorTextFieldChange,
  onSlideBoxShapeSelectChange,
  onSlideDescriptionTextFieldChange,
  onSlideBackgroundChange,
  onSlideTextColorTextFieldChange,
  onSlideTitleTextFieldChange,
  onSlideDecorChange,
  onSlideImageChange,
  checkTextColorOnBoxBackColorChange,
  onSlideTagBoxBackColorTextFieldChange,
  onSlideTagBoxTextColorTextFieldChange,
} from "../../services/utilities/episodeUtility"
import {
  capitalizeFirstCharacter,
  deepCopy,
} from "../../services/utilities/utility"
import BigPreview from "../episode/bigPreview"
import ImagePicker from "../global/imagePicker"
import { MediaSize } from "../../services/config/enum"
import {
  boxBackColors,
  tagBoxBackColors,
  textColors,
} from "../../services/config/colorsForSlides"
import { Slide } from "@aworld/app-preview"

const TitleSlide = ({
  slide,
  language,
  index,
  constraints,
  changesCancelled,
}: {
  slide: EpisodeSlide
  language: string
  index: number
  constraints: SlideConstraints
  changesCancelled: boolean
}) => {
  const {
    currentEpisode,
    setCurrentEpisode,
    slidesErrors,
    setSlidesErrors,
    editMode,
  } = useContext(EpisodesContext)

  // errors
  const [titleError, setTitleError] = useState<boolean>(false)
  const [titleErrorType, setTitleErrorType] = useState<string>("")
  const [backgroundError, setBackgroundError] = useState<boolean>(false)
  const [decorError, setDecorError] = useState<boolean>(false)
  const [descriptionError, setDescriptionError] = useState<boolean>(false)
  const [descriptionErrorType, setDescriptionErrorType] = useState<string>("")
  const [imageError, setImageError] = useState<boolean>(false)

  // check errors
  const [isFirstRender, setisFirstRender] = useState<boolean>(true)

  useEffect(() => {
    if (isFirstRender) {
      setisFirstRender(false)

      // first errors check
      if (slide.title.length > constraints.title.maxSize) {
        setTitleError(true)
        setTitleErrorType(
          "Maximum length is " + constraints.title.maxSize + " characters"
        )
      }
      if (slide.title.length < constraints.title.minSize) {
        setTitleError(true)
        setTitleErrorType(
          "Minimum length is " + constraints.title.minSize + " characters"
        )
      }
      if (slide.description.length > constraints.description.maxSize) {
        setDescriptionError(true)
        setDescriptionErrorType(
          "Maximum length is " + constraints.description.maxSize + " characters"
        )
      }
      if (
        slide.description.length &&
        slide.description.length < constraints.description.minSize
      ) {
        setDescriptionError(true)
        setDescriptionErrorType(
          "Minimum length is " + constraints.description.minSize + " characters"
        )
      }

      return
    }

    let newSlidesErrors: {
      lang: string
      slides: { slideId: string; error: boolean }[]
    }[] = deepCopy(slidesErrors)

    if (
      !titleError &&
      !backgroundError &&
      !decorError &&
      !descriptionError &&
      !imageError
    ) {
      newSlidesErrors
        .filter((item) => item.lang === language)[0]
        .slides.filter((item) => item.slideId === slide.id)[0].error = false
      setSlidesErrors(newSlidesErrors)
    } else {
      newSlidesErrors
        .filter((item) => item.lang === language)[0]
        .slides.filter((item) => item.slideId === slide.id)[0].error = true
      setSlidesErrors(newSlidesErrors)
    }
  }, [titleError, backgroundError, decorError, descriptionError, imageError])

  // changes cancelled
  useEffect(() => {
    if (changesCancelled) {
      setTitleError(false)
      setTitleErrorType("")
      setBackgroundError(false)
      setDecorError(false)
      setDescriptionError(false)
      setDescriptionErrorType("")
      setImageError(false)
    }
  }, [changesCancelled])

  // big preview
  const [isBigPreviewOpen, setIsBigPreviewOpen] = useState<boolean>(false)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8}>
        <Stack spacing={2}>
          <Typography variant="h6" className="card-subtitle">
            Text
          </Typography>
          <Stack direction="row" spacing={2}>
            <TextField
              disabled={!editMode}
              value={slide.title ? slide.title : ""}
              fullWidth
              label="Title"
              variant="outlined"
              size="small"
              error={titleError}
              helperText={titleErrorType}
              onChange={(e: any) => {
                onSlideTitleTextFieldChange(
                  currentEpisode,
                  setCurrentEpisode,
                  e,
                  language,
                  slide,
                  index
                )
                if (e.target.value.length > constraints.title.maxSize) {
                  setTitleError(true)
                  setTitleErrorType(
                    "Maximum length is " +
                      constraints.title.maxSize +
                      " characters"
                  )
                } else if (e.target.value.length < constraints.title.minSize) {
                  setTitleError(true)
                  setTitleErrorType(
                    "Minimum length is " +
                      constraints.title.minSize +
                      " characters"
                  )
                } else {
                  setTitleError(false)
                  setTitleErrorType("")
                }
              }}
            />
            <FormControl fullWidth disabled={!editMode}>
              <InputLabel id="text-color-label-titleSlide" size="small">
                Text color
              </InputLabel>
              <Select
                size="small"
                labelId="text-color-label-titleSlide"
                value={slide.textColor ? slide.textColor : ""}
                label="Text color"
                onChange={(e: any) => {
                  onSlideTextColorTextFieldChange(
                    currentEpisode,
                    setCurrentEpisode,
                    e.target.value,
                    language,
                    slide,
                    index
                  )
                }}
              >
                {textColors.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <TextField
            disabled={!editMode}
            value={slide.description ? slide.description : ""}
            fullWidth
            label="Description"
            variant="outlined"
            size="small"
            multiline
            rows={2}
            error={descriptionError}
            helperText={descriptionErrorType}
            onChange={(e: any) => {
              onSlideDescriptionTextFieldChange(
                currentEpisode,
                setCurrentEpisode,
                e,
                language,
                slide,
                index
              )
              if (e.target.value.length > constraints.description.maxSize) {
                setDescriptionError(true)
                setDescriptionErrorType(
                  "Maximum length is " +
                    constraints.description.maxSize +
                    " characters"
                )
              } else if (
                e.target.value.length &&
                e.target.value.length < constraints.description.minSize
              ) {
                setDescriptionError(true)
                setDescriptionErrorType(
                  "Minimum length is " +
                    constraints.description.minSize +
                    " characters"
                )
              } else {
                setDescriptionError(false)
                setDescriptionErrorType("")
              }
            }}
          />
          <Typography variant="h6" className="card-subtitle">
            Background
          </Typography>
          <Stack direction="row" spacing={2}>
            <ImagePicker
              filters={{ category: "background" }}
              disabled={!editMode}
              title="Background"
              pickedImage={slide.background ? slide.background : ""}
              setPickedImage={(selectedImage: {
                url: string
                id: string
                description?: string
              }) => {
                onSlideBackgroundChange(
                  currentEpisode,
                  setCurrentEpisode,
                  selectedImage,
                  language,
                  slide,
                  index
                )
              }}
              width="50%"
            />
            <Stack spacing={2} style={{ width: "50%" }}>
              <FormControl fullWidth disabled={!editMode || !slide.box}>
                <InputLabel id="box-shape-select-label-titleSlide" size="small">
                  Box shape
                </InputLabel>
                <Select
                  size="small"
                  labelId="box-shape-select-label-titleSlide"
                  value={
                    slide.box && slide.box.boxShape
                      ? slide.box.boxShape
                      : "squared"
                  }
                  label="Box shape"
                  onChange={(e) => {
                    onSlideBoxShapeSelectChange(
                      currentEpisode,
                      setCurrentEpisode,
                      e,
                      language,
                      slide,
                      index
                    )
                  }}
                >
                  {constraints.box.boxShape.map((item) => (
                    <MenuItem value={item} key={item}>
                      {capitalizeFirstCharacter(item)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth disabled={!editMode}>
                <InputLabel id="box-back-color-label-titleSlide" size="small">
                  Box back color
                </InputLabel>
                <Select
                  size="small"
                  labelId="box-back-color-label-titleSlide"
                  value={
                    slide.box && slide.box.boxBackColor
                      ? slide.box.boxBackColor
                      : "none"
                  }
                  label="Box back color"
                  onChange={(e: any) => {
                    if (e.target.value === "none") {
                      onSlideBoxBackColorTextFieldChange(
                        currentEpisode,
                        setCurrentEpisode,
                        null,
                        language,
                        slide,
                        index
                      )
                    } else {
                      onSlideBoxBackColorTextFieldChange(
                        currentEpisode,
                        setCurrentEpisode,
                        e.target.value,
                        language,
                        slide,
                        index
                      )
                    }

                    checkTextColorOnBoxBackColorChange(
                      currentEpisode,
                      setCurrentEpisode,
                      e,
                      language,
                      slide,
                      index
                    )
                  }}
                >
                  <MenuItem value="none">None</MenuItem>
                  {boxBackColors.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
          <Typography variant="h6" className="card-subtitle">
            Images
          </Typography>
          <Stack direction="row" spacing={2}>
            <ImagePicker
              filters={{ category: "decor" }}
              disabled={!editMode}
              title="Decor"
              removeButton
              pickedImage={slide.decor ? slide.decor : ""}
              setPickedImage={(selectedImage: {
                url: string
                id: string
                description?: string
              }) => {
                onSlideDecorChange(
                  currentEpisode,
                  setCurrentEpisode,
                  selectedImage,
                  language,
                  slide,
                  index
                )
              }}
              width="50%"
            />
            <ImagePicker
              filters={{
                category: "content",
                size: [MediaSize.s, MediaSize.m, MediaSize.xl],
              }}
              disabled={!editMode}
              title="Image"
              pickedImage={
                slide.image && slide.image.url ? slide.image.url : ""
              }
              setPickedImage={(selectedImage: {
                url: string
                id: string
                description?: string
                type?: MediaSize
              }) => {
                onSlideImageChange(
                  currentEpisode,
                  setCurrentEpisode,
                  selectedImage,
                  language,
                  slide,
                  index
                )
              }}
              width="50%"
            />
          </Stack>
          <Typography variant="h6" className="card-subtitle">
            Badge
          </Typography>
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth disabled={!editMode}>
              <InputLabel id="tag-back-color-label-titleSlide" size="small">
                Badge back color
              </InputLabel>
              <Select
                size="small"
                labelId="tag-back-color-label-titleSlide"
                value={slide.tagBox ? slide.tagBox.tagBoxBackColor : "#000000"}
                label="Badge back color"
                onChange={(e: any) => {
                  onSlideTagBoxBackColorTextFieldChange(
                    currentEpisode,
                    setCurrentEpisode,
                    e.target.value,
                    language,
                    slide,
                    index
                  )
                }}
              >
                {tagBoxBackColors.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth disabled={!editMode}>
              <InputLabel id="tag-text-color-label-titleSlide" size="small">
                Badge text color
              </InputLabel>
              <Select
                size="small"
                labelId="tag-text-color-label-titleSlide"
                value={slide.tagBox ? slide.tagBox.tagBoxTextColor : "#FFFFFF"}
                label="Badge text color"
                onChange={(e: any) => {
                  onSlideTagBoxTextColorTextFieldChange(
                    currentEpisode,
                    setCurrentEpisode,
                    e.target.value,
                    language,
                    slide,
                    index
                  )
                }}
              >
                {textColors.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Stack spacing={2}>
          <Typography variant="h6" className="card-subtitle">
            Preview
          </Typography>
          <div
            style={{
              zoom: 0.6,
              marginTop: 30,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              maxHeight: "100%",
              cursor: "pointer",
              opacity: isBigPreviewOpen ? 0 : 1,
              transition: "200ms",
            }}
            onClick={() => {
              setIsBigPreviewOpen(true)
            }}
          >
            <Slide
              slide={slide}
              constraints={constraints}
              className="preview"
              primaryTopicName={
                currentEpisode.topics.find((topic) => topic.primary)
                  ? currentEpisode.topics.find((topic) => topic.primary).topic
                      .name
                  : null
              }
              episodeTitle={
                currentEpisode.translations.find(
                  (item) => item.lang === language
                ).title ?? null
              }
              numberOfSlides={
                currentEpisode.translations.find(
                  (item) => item.lang === language
                ).slides.length
              }
              numberOfQuiz={
                currentEpisode.translations.find(
                  (item) => item.lang === language
                ).quiz.length
              }
              currentSlide={index}
            />
          </div>
        </Stack>
      </Grid>
      <BigPreview
        isBigPreviewOpen={isBigPreviewOpen}
        setIsBigPreviewOpen={setIsBigPreviewOpen}
      >
        <div
          style={{
            zoom: window.innerHeight / 950,
            marginTop: 70,
          }}
        >
          <Slide
            slide={slide}
            constraints={constraints}
            className="preview"
            primaryTopicName={
              currentEpisode.topics.find((topic) => topic.primary)
                ? currentEpisode.topics.find((topic) => topic.primary).topic
                    .name
                : null
            }
            episodeTitle={
              currentEpisode.translations.find((item) => item.lang === language)
                .title ?? null
            }
            numberOfSlides={
              currentEpisode.translations.find((item) => item.lang === language)
                .slides.length
            }
            numberOfQuiz={
              currentEpisode.translations.find((item) => item.lang === language)
                .quiz.length
            }
            currentSlide={index}
          />
        </div>
      </BigPreview>
    </Grid>
  )
}

export default TitleSlide
