export const flags = [
  { language: "en", flag: "🇺🇸" },
  { language: "it", flag: "🇮🇹" },
  { language: "es", flag: "🇪🇸" },
  { language: "fr", flag: "🇫🇷" },
  { language: "de", flag: "🇩🇪" },
  { language: "hu", flag: "🇭🇺" },
  { language: "hr", flag: "🇭🇷" },
  { language: "zh_cn", flag: "🇨🇳" },
  { language: "zh_tw", flag: "🇨🇳" },
]
