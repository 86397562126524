import { useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react"
import Journey from "../models/journey"
import Sdg from "../models/sdg"
import SdgTarget from "../models/sdgTarget"
import Topic from "../models/topic"
import { useMockDataForJourneysList } from "../services/config/constants"
import {
  archiveJourney,
  createJourney,
  publishJourney,
  updateJourney,
} from "../services/graphql/mutations"
import {
  archivedJourneys,
  journey,
  stagedJourney,
  stagedJourneys,
  translationsExportUrlGet,
  translationsImportUrlGet,
} from "../services/graphql/queries"
import { journeysMockData } from "../services/mockData/journeysMockData"
import { deepCopy, logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"

interface AutocompleteOption {
  label: string
  id: string
}

interface JourneysContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  journeysList: Journey[]
  setJourneysList: Dispatch<SetStateAction<Journey[]>>
  journeysListNextToken: string | null
  getJourneysList: (withLoading?: boolean, archived?: boolean) => void
  createNewJourney: (input: object) => Promise<Journey>
  currentJourney: Journey
  setCurrentJourney: Dispatch<SetStateAction<Journey>>
  currentJourneyStaged: Journey
  currentJourneyLive: Journey
  getJourneyDetails: (id: string) => Promise<boolean>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  doneChanges: boolean
  cancelChanges: () => void
  changeStage: (toStage: string) => void
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  updateAllJourney: (input: object) => Promise<Journey>
  publishAllJourney: (journeyId: string) => Promise<boolean>
  removeEpisode: (idToRemove: string) => void
  addPublishedLang: (langToAdd: string) => void
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
  searchLanguage: AutocompleteOption | null
  setSearchLanguage: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchTopic: AutocompleteOption | null
  setSearchTopic: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchSdg: AutocompleteOption | null
  setSearchSdg: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchSdgTarget: AutocompleteOption | null
  setSearchSdgTarget: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchCategory: AutocompleteOption | null
  setSearchCategory: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchEsg: AutocompleteOption | null
  setSearchEsg: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchStatus: "active" | "archived"
  setSearchStatus: Dispatch<SetStateAction<"active" | "archived">>
  searchType: ("Free" | "Business" | "Custom")[]
  setSearchType: Dispatch<SetStateAction<("Free" | "Business" | "Custom")[]>>
  hasSearched: boolean
  setHasSearched: Dispatch<SetStateAction<boolean>>
  searchJourneys: ({
    title,
    lang,
    topic,
    sdg,
    sdgTarget,
    category,
    esg,
    type,
    archived,
  }: {
    title?: string
    lang?: AutocompleteOption | null
    topic?: AutocompleteOption | null
    sdg?: AutocompleteOption | null
    sdgTarget?: AutocompleteOption | null
    category?: AutocompleteOption | null
    esg?: AutocompleteOption | null
    type?: ("Free" | "Business" | "Custom")[]
    archived?: boolean
  }) => void
  loadMoreJourneys: (archived?: boolean) => Promise<boolean>
  archiveAllJourney: (journeyId: string) => Promise<boolean>
  duplicateJourney: () => Promise<boolean | Journey>
}

const JourneysContext = createContext<JourneysContextInterface>({
  loading: true,
  setLoading: () => {},
  journeysList: [],
  setJourneysList: () => {},
  journeysListNextToken: null,
  getJourneysList: () => {},
  createNewJourney: async () => new Journey(),
  currentJourney: new Journey(),
  setCurrentJourney: () => {},
  currentJourneyStaged: new Journey(),
  currentJourneyLive: new Journey(),
  getJourneyDetails: async () => true,
  editMode: false,
  setEditMode: () => {},
  doneChanges: false,
  cancelChanges: () => {},
  changeStage: () => {},
  updatingList: false,
  setUpdatingList: () => {},
  updateAllJourney: async () => new Journey(),
  publishAllJourney: async () => true,
  removeEpisode: () => {},
  addPublishedLang: () => {},
  searchValue: "",
  setSearchValue: () => {},
  searchLanguage: null,
  setSearchLanguage: () => {},
  searchTopic: null,
  setSearchTopic: () => {},
  searchSdg: null,
  setSearchSdg: () => {},
  searchSdgTarget: null,
  setSearchSdgTarget: () => {},
  searchCategory: null,
  setSearchCategory: () => {},
  searchEsg: null,
  setSearchEsg: () => {},
  searchStatus: "active",
  setSearchStatus: () => {},
  searchType: ["Free", "Business"],
  setSearchType: () => {},
  hasSearched: false,
  setHasSearched: () => {},
  searchJourneys: () => {},
  loadMoreJourneys: async () => true,
  archiveAllJourney: async () => true,
  duplicateJourney: async () => true,
})

const JourneysController = ({ children }: { children: ReactNode }) => {
  const {
    setError,
    setErrorMessage,
    setChangesSaved,
    panelStatus,
    setPanelStatus,
  } = useContext(MainContext)

  const [loading, setLoading] = useState<boolean>(true) // loading
  const [journeysList, setJourneysList] = useState<Journey[]>([]) // all journeys list
  const [journeysListNextToken, setJourneysListNextToken] = useState<
    string | null
  >(null) // next token for journeys list
  const [currentJourney, setCurrentJourney] = useState<Journey>() // current journey details
  const [currentJourneyLive, setCurrentJourneyLive] = useState<Journey>() // current journey details
  const [currentJourneyStaged, setCurrentJourneyStaged] = useState<Journey>() // current journey details
  const [preChangesCurrentJourney, setPreChangesCurrentJourney] =
    useState<Journey>() // fetched current journey details
  const [editMode, setEditMode] = useState<boolean>(true) // if all journey details page should be disabled or not
  const [doneChanges, setDoneChanges] = useState<boolean>(false) // if user has done changes to journey details
  const [updatingList, setUpdatingList] = useState<boolean>(false) // indicates a list update
  const [searchValue, setSearchValue] = useState<string>("") // search input value
  const [searchLanguage, setSearchLanguage] =
    useState<AutocompleteOption | null>(null) // search language filter
  const [searchTopic, setSearchTopic] = useState<AutocompleteOption | null>(
    null
  ) // search topic filter
  const [searchSdg, setSearchSdg] = useState<AutocompleteOption | null>(null) // search sdg filter
  const [searchSdgTarget, setSearchSdgTarget] =
    useState<AutocompleteOption | null>(null) // search sdg target filter
  const [searchCategory, setSearchCategory] =
    useState<AutocompleteOption | null>(null) // search category filter
  const [searchEsg, setSearchEsg] = useState<AutocompleteOption | null>(null) // search esg filter
  const [searchStatus, setSearchStatus] = useState<"active" | "archived">(
    "active"
  ) // search status filter
  const [searchType, setSearchType] = useState<
    ("Free" | "Business" | "Custom")[]
  >(["Free", "Business"]) // search type filter
  const [hasSearched, setHasSearched] = useState<boolean>(false) // if user has searched or not

  // queries
  const [stagedJourneysQuery] = useLazyQuery(stagedJourneys)
  const [archivedJourneysQuery] = useLazyQuery(archivedJourneys)
  const [stagedJourneyQuery] = useLazyQuery(stagedJourney)
  const [journeyQuery] = useLazyQuery(journey)
  const [translationsExportUrlGetQuery] = useLazyQuery(translationsExportUrlGet)
  const [translationsImportUrlGetQuery] = useLazyQuery(translationsImportUrlGet)

  // mutations
  const [createJourneyMutation] = useMutation(createJourney)
  const [updateJourneyMutation] = useMutation(updateJourney)
  const [publishJourneyMutation] = useMutation(publishJourney)
  const [archiveJourneyMutation] = useMutation(archiveJourney)

  // get all journeys list
  const getJourneysList = useCallback(
    async (withLoading: boolean = true, archived: boolean = false) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter((item) => item.name === "Journeys")[0].loading = true
        setPanelStatus([...panelStatus])
      }

      try {
        if (!archived) {
          logger(Status.Api, "QUERY stagedJourneys", stagedJourneys)
          const { data } = await stagedJourneysQuery({
            variables: {
              limit: Math.round(window.innerHeight / 74) + 10,
              journeyType: searchType,
            },
            fetchPolicy: "no-cache",
          })
          let dataToSet: any
          if (useMockDataForJourneysList) {
            dataToSet = journeysMockData
          } else {
            dataToSet = data
          }
          setJourneysListNextToken(dataToSet.stagedJourneys.nextToken)
          logger(Status.Info, "journeys list", dataToSet.stagedJourneys.items)

          setJourneysList(dataToSet.stagedJourneys.items)
        } else {
          logger(Status.Api, "QUERY archivedJourneys", archivedJourneys)
          const { data } = await archivedJourneysQuery({
            variables: {
              limit: Math.round(window.innerHeight / 74) + 10,
              journeyType: searchType,
            },
            fetchPolicy: "no-cache",
          })
          let dataToSet: any
          if (useMockDataForJourneysList) {
            dataToSet = journeysMockData
          } else {
            dataToSet = data
          }
          setJourneysListNextToken(dataToSet.archivedJourneys.nextToken)
          logger(Status.Info, "journeys list", dataToSet.archivedJourneys.items)

          setJourneysList(dataToSet.archivedJourneys.items)
        }

        setHasSearched(false)
        setUpdatingList(false)
        if (withLoading) {
          setLoading(false)
        }
        panelStatus.filter((item) => item.name === "Journeys")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "Journeys")[0].loading =
          false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          if (!archived) {
            logger(Status.Error, `stagedJourneys`, e.message)
          } else {
            logger(Status.Error, `archivedJourneys`, e.message)
          }
          setUpdatingList(false)
          if (withLoading) {
            setLoading(false)
          }
          panelStatus.filter((item) => item.name === "Journeys")[0].status =
            "error"
          panelStatus.filter((item) => item.name === "Journeys")[0].loading =
            false
          setPanelStatus([...panelStatus])
        }
      }
    },
    [setError, setErrorMessage]
  )

  // load more journeys and add them in journeys list
  const loadMoreJourneys = async (archived: boolean = false) => {
    try {
      if (!archived) {
        logger(Status.Api, "QUERY stagedJourneys", stagedJourneys)
        const { data } = await stagedJourneysQuery({
          variables: {
            limit: Math.round(window.innerHeight / 74) + 10,
            nextToken: journeysListNextToken,
            title: searchValue,
            lang: searchLanguage ? searchLanguage.id : null,
            topic: searchTopic ? searchTopic.id : null,
            sdg: searchSdg ? searchSdg.id : null,
            sdgTarget: searchSdgTarget ? searchSdgTarget.id : null,
            category: searchCategory ? searchCategory.id : null,
            esg: searchEsg ? searchEsg.id : null,
            journeyType: searchType,
          },
          fetchPolicy: "no-cache",
        })
        setJourneysListNextToken(data.stagedJourneys.nextToken)
        logger(Status.Info, "journeys list", [
          ...journeysList,
          ...data.stagedJourneys.items,
        ])

        setJourneysList([...journeysList, ...data.stagedJourneys.items])
      } else {
        logger(Status.Api, "QUERY archivedJourneys", archivedJourneys)
        const { data } = await archivedJourneysQuery({
          variables: {
            limit: Math.round(window.innerHeight / 74) + 10,
            nextToken: journeysListNextToken,
            title: searchValue,
            lang: searchLanguage ? searchLanguage.id : null,
            topic: searchTopic ? searchTopic.id : null,
            sdg: searchSdg ? searchSdg.id : null,
            sdgTarget: searchSdgTarget ? searchSdgTarget.id : null,
            category: searchCategory ? searchCategory.id : null,
            esg: searchEsg ? searchEsg.id : null,
            journeyType: searchType,
          },
          fetchPolicy: "no-cache",
        })
        setJourneysListNextToken(data.archivedJourneys.nextToken)
        logger(Status.Info, "archived journeys list", [
          ...journeysList,
          ...data.archivedJourneys.items,
        ])

        setJourneysList([...journeysList, ...data.archivedJourneys.items])
      }

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        if (!archived) {
          logger(Status.Error, `stagedJourneys`, e.message)
        } else {
          logger(Status.Error, `archivedJourneys`, e.message)
        }
      }
      return false
    }
  }

  // search journeys by title and/or filters
  const searchJourneys = useCallback(
    async ({
      title,
      lang,
      topic,
      sdg,
      sdgTarget,
      category,
      esg,
      type,
      archived = false,
    }: {
      title?: string
      lang?: AutocompleteOption | null
      topic?: AutocompleteOption | null
      sdg?: AutocompleteOption | null
      sdgTarget?: AutocompleteOption | null
      category?: AutocompleteOption | null
      esg?: AutocompleteOption | null
      type?: ("Free" | "Business" | "Custom")[]
      archived?: boolean
    }) => {
      setUpdatingList(true)

      try {
        if (!archived) {
          logger(Status.Api, "QUERY stagedJourneys", stagedJourneys)
          const { data } = await stagedJourneysQuery({
            variables: {
              limit: Math.round(window.innerHeight / 74) + 10,
              title: title,
              lang: lang ? lang.id : null,
              topic: topic ? topic.id : null,
              sdg: sdg ? sdg.id : null,
              sdgTarget: sdgTarget ? sdgTarget.id : null,
              category: category ? category.id : null,
              esg: esg ? esg.id : null,
              journeyType: type,
            },
            fetchPolicy: "no-cache",
          })
          setJourneysListNextToken(data.stagedJourneys.nextToken)
          logger(Status.Info, "searched journeys", data.stagedJourneys.items)

          setJourneysList(data.stagedJourneys.items)
        } else {
          logger(Status.Api, "QUERY archivedJourneys", archivedJourneys)
          const { data } = await archivedJourneysQuery({
            variables: {
              limit: Math.round(window.innerHeight / 74) + 10,
              title: title,
              lang: lang ? lang.id : null,
              topic: topic ? topic.id : null,
              sdg: sdg ? sdg.id : null,
              sdgTarget: sdgTarget ? sdgTarget.id : null,
              category: category ? category.id : null,
              esg: esg ? esg.id : null,
              journeyType: type,
            },
            fetchPolicy: "no-cache",
          })
          setJourneysListNextToken(data.archivedJourneys.nextToken)
          logger(
            Status.Info,
            "searched archived journeys",
            data.archivedJourneys.items
          )

          setJourneysList(data.archivedJourneys.items)
        }

        setHasSearched(true)
        setUpdatingList(false)
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          if (!archived) {
            logger(Status.Error, `stagedJourneys`, e.message)
          } else {
            logger(Status.Error, `archivedJourneys`, e.message)
          }
        }
        setUpdatingList(false)
      }
    },
    [setError, setErrorMessage]
  )

  // create journey
  const createNewJourney = async (input: object) => {
    setLoading(true)

    try {
      logger(Status.Api, "MUTATION createJourney", createJourney)
      const result = await createJourneyMutation({
        variables: { input: input },
      })
      logger(Status.Info, `journey created`, result.data.createJourney)

      let journeyToUnshift = result.data.createJourney
      journeysList.unshift(journeyToUnshift)
      setJourneysList([...journeysList])

      setLoading(false)

      return journeyToUnshift
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `createJourney`, e.message)
      }

      setLoading(false)
    }
  }

  // get journey details
  const getJourneyDetails = useCallback(
    async (id: string) => {
      try {
        logger(Status.Api, "QUERY stagedJourney", stagedJourney)
        logger(Status.Api, "QUERY journey", journey)
        let result = await Promise.all([
          stagedJourneyQuery({
            variables: { stagedJourneyId: id },
            fetchPolicy: "no-cache",
          }),
          journeyQuery({
            variables: { journeyId: id, lang: "all" },
            fetchPolicy: "no-cache",
          }),
        ])
        logger(
          Status.Info,
          `staged journey ${id}`,
          result[0].data.stagedJourney
        )

        if (result[1].data && result[1].data.journey) {
          logger(Status.Info, `live journey ${id}`, result[1].data.journey)

          setCurrentJourneyLive(result[1].data.journey)
        } else {
          setCurrentJourneyLive(null)
        }

        setCurrentJourney(deepCopy(result[0].data.stagedJourney))
        setCurrentJourneyStaged(deepCopy(result[0].data.stagedJourney))
        setPreChangesCurrentJourney(deepCopy(result[0].data.stagedJourney))

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `stagedJourney`, e.message)
        }

        return false
      }
    },
    [setError, setErrorMessage]
  )

  // update journey
  const updateAllJourney = async (input: object) => {
    setLoading(true)

    try {
      logger(Status.Api, "MUTATION updateJourney", updateJourney)
      const { data } = await updateJourneyMutation({
        variables: { input: input },
      })
      logger(Status.Info, `journey updated`, data.updateJourney)

      setTimeout(() => {
        setLoading(false)
        setCurrentJourney(deepCopy(data.updateJourney))
        setPreChangesCurrentJourney(deepCopy(data.updateJourney))
        setCurrentJourneyStaged(deepCopy(data.updateJourney))
        setChangesSaved(true)
      }, 800)

      return data.updateJourney
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `updateJourney`, e.message)
      }

      setLoading(false)
    }
  }

  // publish journey
  const publishAllJourney = useCallback(
    async (journeyId: string) => {
      try {
        logger(Status.Api, "MUTATION publishJourney", publishJourney)
        await publishJourneyMutation({
          variables: { input: { id: journeyId } },
        })
        logger(Status.Info, `journey ${journeyId} published`)

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `publishJourney`, e.message)
        }

        return false
      }
    },
    [setError, setErrorMessage]
  )

  // check if user has done changes to journey details
  useEffect(() => {
    if (preChangesCurrentJourney && currentJourney) {
      let preChangesCurrentJourneyCopy: Journey = JSON.parse(
        JSON.stringify(preChangesCurrentJourney)
      )
      let currentJourneyCopy: Journey = JSON.parse(
        JSON.stringify(currentJourney)
      )
      delete preChangesCurrentJourneyCopy.stage
      delete currentJourneyCopy.stage

      preChangesCurrentJourneyCopy.topics =
        preChangesCurrentJourneyCopy.topics.map((item) => {
          let topic: Topic = {
            id: item.topic.id,
            name: item.topic.name,
          }

          return {
            primary: item.primary,
            topic: topic,
          }
        })
      if (preChangesCurrentJourneyCopy.sdgs) {
        preChangesCurrentJourneyCopy.sdgs =
          preChangesCurrentJourneyCopy.sdgs.map((item) => {
            let sdg: Sdg = {
              id: item.sdg.id,
              name: item.sdg.name,
            }

            return {
              primary: item.primary,
              sdg: sdg,
            }
          })
      }
      if (preChangesCurrentJourneyCopy.sdgTargets) {
        preChangesCurrentJourneyCopy.sdgTargets =
          preChangesCurrentJourneyCopy.sdgTargets.map((item) => {
            let sdgTarget: SdgTarget = {
              id: item.sdgTarget.id,
            }

            return {
              primary: item.primary,
              sdgTarget: sdgTarget,
            }
          })
      }
      currentJourneyCopy.topics = currentJourneyCopy.topics.map((item) => {
        let topic: Topic = {
          id: item.topic.id,
          name: item.topic.name,
        }

        return {
          primary: item.primary,
          topic: topic,
        }
      })
      if (currentJourneyCopy.sdgs) {
        currentJourneyCopy.sdgs = currentJourneyCopy.sdgs.map((item) => {
          let sdg: Sdg = {
            id: item.sdg.id,
            name: item.sdg.name,
          }

          return {
            primary: item.primary,
            sdg: sdg,
          }
        })
      }
      if (currentJourneyCopy.sdgTargets) {
        currentJourneyCopy.sdgTargets = currentJourneyCopy.sdgTargets.map(
          (item) => {
            let sdgTarget: SdgTarget = {
              id: item.sdgTarget.id,
            }

            return {
              primary: item.primary,
              sdgTarget: sdgTarget,
            }
          }
        )
      }
      delete (currentJourneyCopy.category as any).__typename
      delete (preChangesCurrentJourneyCopy.category as any).__typename

      if (
        JSON.stringify(currentJourneyCopy) ===
        JSON.stringify(preChangesCurrentJourneyCopy)
      ) {
        setDoneChanges(false)
      } else {
        setDoneChanges(true)
      }
    }
  }, [currentJourney, preChangesCurrentJourney])

  // cancel journey details changes
  const cancelChanges = () => {
    let currentJourneyCopy: Journey = deepCopy(currentJourney)
    currentJourneyCopy = deepCopy(preChangesCurrentJourney)

    setCurrentJourney(currentJourneyCopy)
  }

  // change stage
  const changeStage = (toStage: string) => {
    let currentJourneyCopy: Journey = deepCopy(currentJourney)
    let preChangesCurrentJourneyCopy: Journey = deepCopy(
      preChangesCurrentJourney
    )

    if (toStage === "PUBLISHED") {
      currentJourneyCopy = deepCopy(currentJourneyLive)
      preChangesCurrentJourneyCopy = deepCopy(currentJourneyLive)

      setCurrentJourney(currentJourneyCopy)
      setPreChangesCurrentJourney(preChangesCurrentJourneyCopy)
    }
    if (toStage === "DRAFT") {
      currentJourneyCopy = deepCopy(currentJourneyStaged)
      preChangesCurrentJourneyCopy = deepCopy(currentJourneyStaged)

      setCurrentJourney(currentJourneyCopy)
      setPreChangesCurrentJourney(preChangesCurrentJourneyCopy)
    }
  }

  // remove episode from list
  const removeEpisode = (idToRemove: string) => {
    let indexToRemove = currentJourney.episodes.findIndex(
      (item) => item.episode.id === idToRemove
    )
    currentJourney.episodes.splice(indexToRemove, 1)
    setCurrentJourney({ ...currentJourney })
  }

  // add published lang
  const addPublishedLang = (langToAdd: string) => {
    currentJourney.publishedLangs.push({
      lang: langToAdd,
      title: langToAdd === currentJourney.lang ? currentJourney.title : "",
    })
    setCurrentJourney({ ...currentJourney })
  }

  // archive journey
  const archiveAllJourney = async (journeyId: string) => {
    try {
      logger(Status.Api, "MUTATION archiveJourney", archiveJourney)
      await archiveJourneyMutation({
        variables: { input: { id: journeyId } },
      })
      logger(Status.Info, `journey ${journeyId} archived`)

      let journeysListCopy: Journey[] = deepCopy(journeysList)
      journeysListCopy.filter((item) => item.id === journeyId)[0].stage = null
      setJourneysList(journeysListCopy)

      setUpdatingList(true)

      setTimeout(() => {
        if (searchStatus === "active") {
          getJourneysList(false)
        } else {
          getJourneysList(false, true)
        }
      }, 4000)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `archiveJourney`, e.message)
      }

      return false
    }
  }

  // duplicate journey
  const duplicateJourney = async () => {
    setLoading(true)

    try {
      const input: {
        lang: string
        title: string
        text: string
        image: string
        topics: { id: string; primary: boolean }[]
        sdgs: { id: string; primary: boolean }[]
        sdgTargets: { id: string; primary: boolean }[]
        episodes: { id: string; sorting: number }[]
        type: string
        publishedLangs: { lang: string; title: string }[]
        category: { id: string }
      } = {
        lang: currentJourneyLive.lang,
        title: currentJourneyLive.title,
        text: currentJourneyLive.text,
        image: currentJourneyLive.image,
        topics: currentJourneyLive.topics.map((item) => {
          return {
            id: item.topic.id,
            primary: item.primary,
          }
        }),
        sdgs: currentJourneyLive.sdgs.map((item) => {
          return {
            id: item.sdg.id,
            primary: item.primary,
          }
        }),
        sdgTargets: currentJourneyLive.sdgTargets.map((item) => {
          return {
            id: item.sdgTarget.id,
            primary: item.primary,
          }
        }),
        episodes: currentJourneyLive.episodes.map((item) => {
          return {
            id: item.episode.id,
            sorting: item.sorting,
          }
        }),
        type: "Custom",
        publishedLangs: currentJourneyLive.publishedLangs.map((item) => {
          return {
            lang: item.lang,
            title: item.title,
          }
        }),
        category: { id: currentJourneyLive.category.id },
      }

      logger(Status.Api, "MUTATION createJourney", createJourney)
      const result = await createJourneyMutation({
        variables: { input: input },
      })
      logger(Status.Info, `journey created`, result.data.createJourney)

      setLoading(false)
      return result.data.createJourney
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `createJourney`, e.message)
      }

      setLoading(false)
      return false
    }
  }

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter((item) => item.name === "Journeys")[0].updateFunction =
      getJourneysList
  }, [])

  return (
    <JourneysContext.Provider
      value={{
        loading,
        setLoading,
        journeysList,
        setJourneysList,
        journeysListNextToken,
        getJourneysList,
        createNewJourney,
        currentJourney,
        setCurrentJourney,
        currentJourneyStaged,
        currentJourneyLive,
        getJourneyDetails,
        editMode,
        setEditMode,
        doneChanges,
        cancelChanges,
        changeStage,
        updatingList,
        setUpdatingList,
        updateAllJourney,
        publishAllJourney,
        removeEpisode,
        addPublishedLang,
        searchValue,
        setSearchValue,
        searchLanguage,
        setSearchLanguage,
        searchTopic,
        setSearchTopic,
        searchSdg,
        setSearchSdg,
        searchSdgTarget,
        setSearchSdgTarget,
        searchCategory,
        setSearchCategory,
        searchEsg,
        setSearchEsg,
        searchStatus,
        setSearchStatus,
        searchType,
        setSearchType,
        hasSearched,
        setHasSearched,
        searchJourneys,
        loadMoreJourneys,
        archiveAllJourney,
        duplicateJourney,
      }}
    >
      {children}
    </JourneysContext.Provider>
  )
}

export { JourneysController, JourneysContext }
