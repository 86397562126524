import chalk from "chalk"
import Slide from "@mui/material/Slide"
import { TransitionProps } from "@mui/material/transitions"
import {
  forwardRef,
  HTMLAttributes,
  ReactElement,
  Ref,
  RefObject,
  useEffect,
  useMemo,
  useState,
} from "react"
import { loggerEnabled } from "../config/constants"
import { getAnalytics, logEvent } from "firebase/analytics"
import { firebaseApp } from "../../controllers/main"
import Resizer from "react-image-file-resizer"
import ProductPrice from "../../models/productPrice"
import { DateTime } from "luxon"
import { AutocompleteOption } from "../config/interfaces"
import { AutocompleteRenderGetTagProps, Chip } from "@mui/material"

// register analytics event
export const registerAnalyticsEvent = ({
  type,
  name,
}: {
  type: string
  name: string
}) => {
  const analytics = getAnalytics(firebaseApp)
  logEvent(analytics, type, { name: name })
}

// status enum for logger
export enum Status {
  Info,
  Warning,
  Error,
  Api,
  Important,
}

// to log with 4 different status
export const logger = (status: Status, message: string, value: any = null) => {
  if (loggerEnabled) {
    if (status === Status.Info) {
      if (value) {
        console.log(chalk.bgWhite.black(message), value)
      } else {
        console.log(chalk.bgWhite.black(message))
      }
    } else if (status === Status.Warning) {
      if (value) {
        console.log(chalk.bgYellow.black(message), value)
      } else {
        console.log(chalk.bgYellow.black(message))
      }
    } else if (status === Status.Error) {
      if (value) {
        console.log(chalk.bgRed.black(message), value)
      } else {
        console.log(chalk.bgRed.black(message))
      }
    } else if (status === Status.Api) {
      if (value) {
        console.log(chalk.bgBlueBright.black(message), value)
      } else {
        console.log(chalk.bgBlueBright.black(message))
      }
    } else if (status === Status.Important) {
      if (value) {
        console.log(chalk.bgWhite.black.bold(message), value)
      } else {
        console.log(chalk.bgWhite.black.bold(message))
      }
    }
  }
}

// parse a string date into a readable one
export const parseStringDate = (stringDate: string, withHours = true) => {
  let months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ]

  let date = new Date(stringDate)

  if (withHours) {
    return `${date.getDate()} ${
      months[date.getMonth()]
    } ${date.getFullYear()} ${
      date.getHours().toString().length === 1
        ? "0" + date.getHours()
        : date.getHours()
    }:${
      date.getMinutes().toString().length === 1
        ? "0" + date.getMinutes()
        : date.getMinutes()
    }`
  } else {
    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`
  }
}

// compose controllers
interface Props {
  components: Array<React.JSXElementConstructor<React.PropsWithChildren<any>>>
  children: React.ReactNode
}

export const Compose = (props: Props) => {
  const { components = [], children } = props

  return (
    <>
      {components.reduceRight((acc, Comp) => {
        return <Comp>{acc}</Comp>
      }, children)}
    </>
  )
}

// transition for dialogs
export const DialogTransition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

// deep copy an object or an array
export const deepCopy = (input: any) => {
  return JSON.parse(JSON.stringify(input))
}

// capitalize first character of a string
export const capitalizeFirstCharacter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

// make lowercase first character of a string
export const lowercaseFirstCharacter = (text: string) => {
  return text.charAt(0).toLowerCase() + text.slice(1)
}

// preview sizes
export const screenWidth = 375
export const screenHeight = 812
export const columnWidth = 375 / 15
export const rowHeight = screenHeight / 43

// calculate the time elapsed since a date
export const calculateTimeElapsed = (stringDate: string) => {
  return DateTime.fromISO(stringDate).toRelative({
    locale: "en",
  })
}

// generate random alphanumeric string of the given length
export const generateRandomString = (length: number) => {
  var result = ""
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

// check if a YouTube url is valid or not
export const testYtURL = (url: string) => {
  return /www.youtube.com\/[A-z0-9]+/.test(url)
}

// date to string format for queries
export const dateToStringForQueries = (date: Date) => {
  let year = date.getFullYear().toString()
  let month = (date.getMonth() + 1).toString()
  let day = date.getDate().toString()

  if (month.toString().length === 1) {
    month = "0" + month
  }
  if (day.toString().length === 1) {
    day = "0" + day
  }

  return year + "-" + month + "-" + day
}

// copy text to clipboard
export const copyTextToClipboard = (text: string) => {
  navigator.clipboard.writeText(text)
}

// Enum as Array https://stackoverflow.com/questions/41308123/map-typescript-enum
export const enumAsArray = (enumToMap: any) => {
  return Object.keys(enumToMap) as Array<keyof typeof enumToMap>
}

// check string date with format yyyy-mm-dd
export const isValidDate = (dateString: string) => {
  var regEx = /^\d{4}-\d{2}-\d{2}$/
  if (!dateString.match(regEx)) return false // Invalid format
  var d = new Date(dateString)
  var dNum = d.getTime()
  if (!dNum && dNum !== 0) return false // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString
}

// check url
export const isValidURL = (stringToTest: string) => {
  const pattern = new RegExp(
    "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$"
  )
  return pattern.test(stringToTest)
}

// validate privacy version for channels
export const isPrivacyVersionValid = (privacyVersion: string) => {
  // regex for one number only
  const regex = /^\d+$/

  if (
    privacyVersion.length === 5 &&
    regex.test(privacyVersion.charAt(0)) &&
    regex.test(privacyVersion.charAt(2)) &&
    regex.test(privacyVersion.charAt(4)) &&
    privacyVersion.charAt(1) === "." &&
    privacyVersion.charAt(3) === "."
  ) {
    return true
  } else {
    return false
  }
}

// strip html from a string
export const stripHtml = (html: string) => {
  let tmp = document.createElement("DIV")
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ""
}

// add days to date
export const addDays = (date: Date, days: number) => {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

// blob to base64 string
export const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

// resize an image file
export const resizeFile = (
  blob: Blob,
  width: number,
  height: number,
  format: string
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      blob,
      width,
      height,
      format.toUpperCase(),
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      "base64",
      width,
      height
    )
  })

// add hours to a date
export const addHours = (date: Date, hours: number) => {
  date.setHours(date.getHours() + hours)

  return date
}

// check if an element is visible in the viewport or not
export const useIsInViewport = (ref: RefObject<Element>) => {
  const [isIntersecting, setIsIntersecting] = useState(false)

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  )

  useEffect(() => {
    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [ref, observer])

  return isIntersecting
}

// are there duplicated currency inside prices array
export const hasDuplicateCurrency = (items: ProductPrice[]): boolean => {
  const currencyCounts = new Map<string, number>()

  for (const item of items) {
    const count = currencyCounts.get(item.currency) || 0
    if (count > 0) {
      return true // found a duplicate currency
    }
    currencyCounts.set(item.currency, count + 1)
  }

  return false // no duplicate currency found
}

// return void promise with settimeout
export const wait = async (ms: number) => {
  return new Promise((r) => setTimeout(r, ms))
}

// render functions for mui autocomplete (to avoid 'A props object containing a "key" prop is being spread into JSX' error)
export const renderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: AutocompleteOption
) => {
  return (
    <li {...props} key={option.id}>
      {option.label}
    </li>
  )
}

export const renderTags = (
  tagValue: AutocompleteOption[],
  getTagProps: AutocompleteRenderGetTagProps
) => {
  return tagValue.map((option, index) => (
    <Chip
      {...getTagProps({ index })}
      size="small"
      key={option.id}
      label={option.label}
    />
  ))
}

// split a camel case string
export const splitCamelCase = (string: string) => {
  return string.replace(/([a-z0-9])([A-Z])/g, "$1 $2").toLowerCase()
}
