import blankImage from "../../assets/images/notificationTemplates/blank.jpg"
import MjmlTemplate from "../../models/mjmlTemplate"

export const mjmlTemplates: MjmlTemplate[] = [
  {
    id: "blank",
    label: "Blank",
    image: blankImage,
    schema: {
      content: {
        type: "page",
        data: {
          value: {
            breakpoint: "480px",
            headAttributes: "",
            "font-size": "15px",
            "line-height": "1.8",
            headStyles: [],
            fonts: [],
            responsive: true,
            "font-family": "'Lato', sans-serif",
            "text-color": "#000000",
            "content-background-color": "#fafafa",
          },
        },
        attributes: {
          "background-color": "#efeeea",
          width: "600px",
        },
        children: [],
      },
    },
  },
]
