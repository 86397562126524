import {
  Autocomplete,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material"
import {
  CSSProperties,
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { TagsContext } from "../../controllers/tags"
import { LanguagesContext } from "../../controllers/languages"
import { MetricsContext } from "../../controllers/metrics"
import Sdg from "../../models/sdg"
import SdgTarget from "../../models/sdgTarget"
import Topic from "../../models/topic"
import Category from "../../models/category"
import Team from "../../models/team"
import {
  Status,
  logger,
  renderOption,
  renderTags,
} from "../../services/utilities/utility"
import { useLazyQuery } from "@apollo/client"
import { searchChallenges, teamsSearch } from "../../services/graphql/queries"
import Challenge from "../../models/challenge"

interface AutocompleteOption {
  label: string
  id: string
}

const ListFilters = ({
  disabled,
  filters,
  style,
}: {
  disabled: boolean
  filters: {
    type:
      | "language"
      | "sdg"
      | "sdgTarget"
      | "topic"
      | "boolean"
      | "int"
      | "singular"
      | "multiple"
      | "team"
      | "challenge"
      | "category"
      | "metric"
      | "time"
      | "mainCategory"
      | "mainTopic"
    value: AutocompleteOption | AutocompleteOption[] | boolean | number | null
    setValue: Dispatch<
      SetStateAction<
        AutocompleteOption | AutocompleteOption[] | boolean | number | null
      >
    >
    label?: string
    options?: AutocompleteOption[]
    disabled?: boolean
    noValueLabel?: string
    disableClearable?: boolean
  }[]
  style?: CSSProperties
}) => {
  const { languagesForEpisodeTabs } = useContext(LanguagesContext)
  const {
    categoriesLoading,
    categoriesList,
    topicsLoading,
    topicsList,
    sdgsLoading,
    sdgsList,
    sdgTargetsLoading,
    sdgTargetsList,
    mainCategoriesLoading,
    mainCategoriesList,
    mainTopicsLoading,
    mainTopicsList,
  } = useContext(TagsContext)
  const { metricsLoading, metricsList } = useContext(MetricsContext)

  // teams filter
  const [teamSearchValue, setTeamSearchValue] = useState<string>("")
  const [teamsList, setTeamsList] = useState<Team[]>([])
  const [teamsListLoading, setTeamsListLoading] = useState<boolean>(false)
  const teamSearchTimeoutId = useRef<any | null>(null)

  const [teamsSearchQuery] = useLazyQuery(teamsSearch)

  useEffect(() => {
    if (filters.some((item) => item.type === "team")) {
      const searchTeams = async () => {
        logger(Status.Api, "QUERY teamsSearch", teamsSearch)
        const { data } = await teamsSearchQuery({
          variables: {
            input: {
              key: teamSearchValue,
            },
          },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "teams list", data.teamsSearch.items)

        setTeamsList(data.teamsSearch.items)
        setTeamsListLoading(false)
      }

      if (teamSearchTimeoutId.current) {
        clearTimeout(teamSearchTimeoutId.current)
        teamSearchTimeoutId.current = null
      }

      const value = filters.find((item) => item.type === "team")
        .value as AutocompleteOption
      if (
        value &&
        teamSearchValue.length >= 3 &&
        teamSearchValue !== value.label
      ) {
        setTeamsListLoading(true)
        teamSearchTimeoutId.current = setTimeout(searchTeams, 600)
      } else if (!value && teamSearchValue.length >= 3) {
        setTeamsListLoading(true)
        teamSearchTimeoutId.current = setTimeout(searchTeams, 600)
      }
    }
  }, [teamSearchValue])

  // challenges filter
  const [challengeSearchValue, setChallengeSearchValue] = useState<string>("")
  const [challengesList, setChallengesList] = useState<Challenge[]>([])
  const [challengesListLoading, setChallengesListLoading] =
    useState<boolean>(false)
  const challengeSearchTimeoutId = useRef<any | null>(null)

  const [searchChallengesQuery] = useLazyQuery(searchChallenges)

  useEffect(() => {
    if (filters.some((item) => item.type === "challenge")) {
      const challengesSearch = async () => {
        logger(Status.Api, "QUERY challengesSearch", searchChallenges)
        const { data } = await searchChallengesQuery({
          variables: {
            input: {
              text: challengeSearchValue,
            },
          },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "challenges list", data.challengesSearch.items)

        setChallengesList(data.challengesSearch.items)
        setChallengesListLoading(false)
      }

      if (challengeSearchTimeoutId.current) {
        clearTimeout(challengeSearchTimeoutId.current)
        challengeSearchTimeoutId.current = null
      }

      const value = filters.find((item) => item.type === "challenge")
        .value as AutocompleteOption
      if (
        value &&
        challengeSearchValue.length >= 3 &&
        challengeSearchValue !== value.label
      ) {
        setChallengesListLoading(true)
        challengeSearchTimeoutId.current = setTimeout(challengesSearch, 600)
      } else if (!value && challengeSearchValue.length >= 3) {
        setChallengesListLoading(true)
        challengeSearchTimeoutId.current = setTimeout(challengesSearch, 600)
      }
    }
  }, [challengeSearchValue])

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      style={{ overflowX: "scroll", height: 50, ...style }}
      className="filters-stack"
    >
      {filters.map((item, index) => {
        switch (item.type) {
          case "language":
            return (
              <Autocomplete
                style={{ minWidth: 110 }}
                key={index}
                disabled={disabled}
                fullWidth
                size="small"
                value={item.value ?? { label: "All", id: null }}
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={[
                  { label: "All", id: null },
                  ...languagesForEpisodeTabs
                    .slice(1)
                    .map((item: { value: string; label: string }) => {
                      return { label: item.label, id: item.value }
                    }),
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="Translations" />
                )}
                onChange={(e: any, newValue: AutocompleteOption | null) => {
                  if (newValue && newValue.id !== null) {
                    item.setValue(newValue)
                  } else {
                    item.setValue(null)
                  }
                }}
                disableClearable={!item.value}
                renderOption={renderOption}
                renderTags={renderTags}
              />
            )
          case "time":
            return (
              <Autocomplete
                style={{ minWidth: 110 }}
                key={index}
                disabled={disabled}
                fullWidth
                size="small"
                value={item.value ?? { label: "All", id: null }}
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={[
                  { label: "All", id: null },
                  { label: "Past", id: "past" },
                  { label: "Current", id: "current" },
                  { label: "Future", id: "future" },
                ]}
                renderInput={(params) => <TextField {...params} label="Time" />}
                onChange={(e: any, newValue: AutocompleteOption | null) => {
                  if (newValue && newValue.id !== null) {
                    item.setValue(newValue)
                  } else {
                    item.setValue(null)
                  }
                }}
                disableClearable={!item.value}
                renderOption={renderOption}
                renderTags={renderTags}
              />
            )
          case "topic":
            return (
              <Autocomplete
                style={{ minWidth: 110 }}
                key={index}
                disabled={disabled}
                fullWidth
                loading={topicsLoading}
                size="small"
                value={item.value ?? { label: "All", id: null }}
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={
                  topicsList.length
                    ? [
                        { label: "All", id: null },
                        ...topicsList.map((topic: Topic) => {
                          return { label: topic.name, id: topic.id }
                        }),
                      ]
                    : []
                }
                renderInput={(params) => (
                  <TextField {...params} label="Topic" />
                )}
                onChange={(e: any, newValue: AutocompleteOption | null) => {
                  if (newValue && newValue.id !== null) {
                    item.setValue(newValue)
                  } else {
                    item.setValue(null)
                  }
                }}
                disableClearable={!item.value}
                renderOption={renderOption}
                renderTags={renderTags}
              />
            )
          case "sdg":
            return (
              <Autocomplete
                style={{ minWidth: 110 }}
                key={index}
                disabled={disabled}
                fullWidth
                loading={sdgsLoading}
                size="small"
                value={item.value ?? { label: "All", id: null }}
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={
                  sdgsList.length
                    ? [
                        { label: "All", id: null },
                        ...sdgsList.map((sdg: Sdg) => {
                          return { label: sdg.name, id: sdg.id }
                        }),
                      ]
                    : []
                }
                renderInput={(params) => <TextField {...params} label="SDG" />}
                onChange={(e: any, newValue: AutocompleteOption | null) => {
                  if (newValue && newValue.id !== null) {
                    item.setValue(newValue)
                  } else {
                    item.setValue(null)
                  }
                }}
                disableClearable={!item.value}
                renderOption={renderOption}
                renderTags={renderTags}
              />
            )
          case "metric":
            return (
              <Autocomplete
                style={{ minWidth: 110 }}
                key={index}
                disabled={disabled}
                fullWidth
                loading={metricsLoading}
                size="small"
                value={item.value ?? { label: "All", id: null }}
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={
                  metricsList.length
                    ? [
                        { label: "All", id: null },
                        ...metricsList.map((metric) => {
                          return { label: metric.name, id: metric.id }
                        }),
                      ]
                    : []
                }
                renderInput={(params) => (
                  <TextField {...params} label="Metric" />
                )}
                onChange={(e: any, newValue: AutocompleteOption | null) => {
                  if (newValue && newValue.id !== null) {
                    item.setValue(newValue)
                  } else {
                    item.setValue(null)
                  }
                }}
                disableClearable={!item.value}
                renderOption={renderOption}
                renderTags={renderTags}
              />
            )
          case "sdgTarget":
            return (
              <Autocomplete
                style={{ minWidth: 110 }}
                key={index}
                disabled={disabled}
                fullWidth
                loading={sdgTargetsLoading}
                size="small"
                value={item.value ?? { label: "All", id: null }}
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={
                  sdgTargetsList.length
                    ? [
                        { label: "All", id: null },
                        ...sdgTargetsList.map((sdgTarget: SdgTarget) => {
                          return {
                            label: sdgTarget.id.slice(4),
                            id: sdgTarget.id,
                          }
                        }),
                      ]
                    : []
                }
                renderInput={(params) => (
                  <TextField {...params} label="SDG target" />
                )}
                onChange={(e: any, newValue: AutocompleteOption | null) => {
                  if (newValue && newValue.id !== null) {
                    item.setValue(newValue)
                  } else {
                    item.setValue(null)
                  }
                }}
                disableClearable={!item.value}
                renderOption={renderOption}
                renderTags={renderTags}
              />
            )
          case "category":
            return (
              <Autocomplete
                style={{ minWidth: 110 }}
                key={index}
                disabled={disabled || item.disabled}
                fullWidth
                loading={categoriesLoading}
                size="small"
                value={item.value ?? { label: "All", id: null }}
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={
                  categoriesList.length
                    ? [
                        { label: "All", id: null },
                        ...categoriesList.map((category) => {
                          return { label: category.name, id: category.id }
                        }),
                      ]
                    : []
                }
                renderInput={(params) => (
                  <TextField {...params} label="Category" />
                )}
                onChange={(e: any, newValue: AutocompleteOption | null) => {
                  if (newValue && newValue.id !== null) {
                    item.setValue(newValue)
                  } else {
                    item.setValue(null)
                  }
                }}
                disableClearable={!item.value}
                renderOption={renderOption}
                renderTags={renderTags}
              />
            )
          case "boolean":
            return (
              <FormControl
                style={{ minWidth: 110 }}
                fullWidth
                size="small"
                key={index}
                disabled={disabled}
              >
                <InputLabel id={item.label}>{item.label}</InputLabel>
                <Select
                  labelId={item.label}
                  label={item.label}
                  value={
                    item.value === null ? "all" : item.value ? "yes" : "no"
                  }
                  onChange={(e) => {
                    if (e.target.value === "all") {
                      item.setValue(null)
                    } else if (e.target.value === "yes") {
                      item.setValue(true)
                    } else {
                      item.setValue(false)
                    }
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </FormControl>
            )
          case "int":
            return (
              <TextField
                style={{ minWidth: 110 }}
                key={index}
                disabled={disabled}
                fullWidth
                size="small"
                type="number"
                label={item.label}
                value={item.value ?? ""}
                onChange={(e) => {
                  const value = e.target.value

                  if (
                    value.includes(",") ||
                    value.includes(".") ||
                    value.includes("e")
                  ) {
                    return
                  } else {
                    if (!value.length) {
                      item.setValue(null)
                    } else if (parseInt(value) > 0) {
                      item.setValue(parseInt(value))
                    } else {
                      item.setValue(null)
                    }
                  }
                }}
              />
            )
          case "singular":
            return (
              <Autocomplete
                style={{ minWidth: 110 }}
                key={index}
                disabled={disabled}
                fullWidth
                size="small"
                value={
                  item.value ?? { label: item.noValueLabel ?? "All", id: null }
                }
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={[
                  { label: item.noValueLabel ?? "All", id: null },
                  ...item.options,
                ]}
                renderInput={(params) => (
                  <TextField {...params} label={item.label} />
                )}
                onChange={(e: any, newValue: AutocompleteOption | null) => {
                  if (newValue && newValue.id !== null) {
                    item.setValue(newValue)
                  } else {
                    item.setValue(null)
                  }
                }}
                disableClearable={
                  item.disableClearable !== undefined
                    ? item.disableClearable
                    : !item.value
                }
                renderOption={renderOption}
                renderTags={renderTags}
              />
            )
          case "multiple":
            return (
              <Autocomplete
                style={{ minWidth: 110 }}
                key={index}
                disabled={disabled}
                fullWidth
                size="small"
                value={item.value ? (item.value as AutocompleteOption[]) : []}
                multiple
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={item.options}
                renderInput={(params) => (
                  <TextField {...params} label={item.label} />
                )}
                onChange={(e: any, newValues: AutocompleteOption[] | null) => {
                  if (newValues.length) {
                    item.setValue(newValues)
                  } else {
                    item.setValue(null)
                  }
                }}
                renderOption={renderOption}
                renderTags={renderTags}
              />
            )
          case "team":
            return (
              <Autocomplete
                style={{ minWidth: 110 }}
                key={index}
                disabled={disabled}
                fullWidth
                loading={teamsListLoading}
                size="small"
                value={item.value}
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={
                  teamsList.length
                    ? [
                        ...teamsList.map((team: Team) => {
                          return {
                            label: team.name,
                            id: team.id,
                          }
                        }),
                      ]
                    : []
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Team"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {teamsListLoading ? (
                            <CircularProgress
                              color="inherit"
                              size={20}
                              style={{ marginRight: 38 }}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
                inputValue={teamSearchValue}
                onInputChange={(_, newInputValue) => {
                  setTeamSearchValue(newInputValue)
                }}
                onChange={(e: any, newValue: AutocompleteOption | null) => {
                  if (newValue && newValue.id !== null) {
                    item.setValue(newValue)
                  } else {
                    item.setValue(null)
                  }
                }}
                disableClearable={!item.value}
                noOptionsText="Search Team"
                renderOption={renderOption}
                renderTags={renderTags}
              />
            )
          case "challenge":
            return (
              <Autocomplete
                style={{ minWidth: 110 }}
                key={index}
                disabled={disabled}
                fullWidth
                loading={challengesListLoading}
                size="small"
                value={item.value}
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={
                  challengesList.length
                    ? [
                        ...challengesList.map((challenge) => {
                          return {
                            label: challenge.name,
                            id: challenge.id,
                          }
                        }),
                      ]
                    : []
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Challenge"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {challengesListLoading ? (
                            <CircularProgress
                              color="inherit"
                              size={20}
                              style={{ marginRight: 38 }}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
                inputValue={challengeSearchValue}
                onInputChange={(_, newInputValue) => {
                  setChallengeSearchValue(newInputValue)
                }}
                onChange={(e: any, newValue: AutocompleteOption | null) => {
                  if (newValue && newValue.id !== null) {
                    item.setValue(newValue)
                  } else {
                    item.setValue(null)
                  }
                }}
                disableClearable={!item.value}
                noOptionsText="Search Challenge"
                renderOption={renderOption}
                renderTags={renderTags}
              />
            )
          case "mainCategory":
            return (
              <Autocomplete
                style={{ minWidth: 110 }}
                key={index}
                disabled={disabled}
                fullWidth
                loading={mainCategoriesLoading}
                size="small"
                value={item.value ?? { label: "All", id: null }}
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={
                  mainCategoriesList.length
                    ? [
                        { label: "All", id: null },
                        ...mainCategoriesList.map((category: Category) => {
                          return { label: category.name, id: category.id }
                        }),
                      ]
                    : []
                }
                renderInput={(params) => (
                  <TextField {...params} label="Main Category" />
                )}
                onChange={(e: any, newValue: AutocompleteOption | null) => {
                  if (newValue && newValue.id !== null) {
                    item.setValue(newValue)
                  } else {
                    item.setValue(null)
                  }
                }}
                disableClearable={!item.value}
                renderOption={renderOption}
                renderTags={renderTags}
              />
            )
          case "mainTopic":
            return (
              <Autocomplete
                style={{ minWidth: 110 }}
                key={index}
                disabled={disabled}
                fullWidth
                loading={mainTopicsLoading}
                size="small"
                value={item.value ?? { label: "All", id: null }}
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteOption,
                  value: AutocompleteOption
                ) => option.id === value.id}
                options={
                  mainTopicsList.length
                    ? [
                        { label: "All", id: null },
                        ...mainTopicsList.map((topic: Topic) => {
                          return { label: topic.name, id: topic.id }
                        }),
                      ]
                    : []
                }
                renderInput={(params) => (
                  <TextField {...params} label="Main Topic" />
                )}
                onChange={(e: any, newValue: AutocompleteOption | null) => {
                  if (newValue && newValue.id !== null) {
                    item.setValue(newValue)
                  } else {
                    item.setValue(null)
                  }
                }}
                disableClearable={!item.value}
                renderOption={renderOption}
                renderTags={renderTags}
              />
            )
        }
      })}
    </Stack>
  )
}

export default ListFilters
