import {
  Button,
  Card,
  CardContent,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { useContext } from "react"
import { MainContext } from "../../controllers/main"
import "../../styles/dashboard.scss"
import {
  InfoRounded,
  RefreshRounded,
  SyncRounded,
  SystemUpdateAltRounded,
} from "@mui/icons-material"
import { gt } from "semver"

const Dashboard = () => {
  const {
    animation,
    panelStatus,
    setPanelStatus,
    showPadding,
    showAdvancedOption,
    setShowAdvancedOption,
    manager,
    user,
  } = useContext(MainContext)

  return (
    <Fade in={animation} timeout={350}>
      <Card
        style={{
          height:
            showPadding === "yes" ? "calc(100vh - 96px)" : "calc(100vh - 64px)",
          margin: showPadding === "yes" ? 16 : 0,
          overflowY: "scroll",
        }}
      >
        <CardContent style={{ position: "relative" }}>
          {gt(manager.version, process.env.REACT_APP_VERSION) &&
          manager.phase === "deployed" ? (
            <Button
              style={{ position: "absolute", top: 10, right: 10 }}
              variant="contained"
              startIcon={
                <SystemUpdateAltRounded
                  style={{
                    fontSize: 16,
                  }}
                />
              }
              onClick={() => {
                window.location.href = window.location.href
              }}
            >
              Update
            </Button>
          ) : null}
          <Typography variant="h4" style={{ paddingTop: 0 }}>
            Welcome,
          </Typography>
          <Typography
            variant="h5"
            style={{ fontSize: "1.05rem", marginTop: -4, fontWeight: 500 }}
          >
            you are currently logged as {user ? user.email : ""}
          </Typography>
          {manager.changelog ? (
            <Typography variant="h6" style={{ marginTop: 20 }}>
              Last update
            </Typography>
          ) : null}
          <Typography
            variant="h5"
            style={{ fontSize: "1.05rem", fontWeight: 500 }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: manager.changelog,
              }}
            />
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            style={{ height: 28, marginTop: 20 }}
          >
            <Typography variant="h6">Panel status</Typography>
            <Tooltip
              title={
                <Typography style={{ fontSize: 11, fontWeight: 600 }}>
                  Use{" "}
                  <SyncRounded
                    className="rotate-slow"
                    style={{ fontSize: 12, marginBottom: -2 }}
                  />{" "}
                  icon to load the desired resource or{" "}
                  <RefreshRounded
                    className="rotate-clockwise-slow"
                    style={{ fontSize: 12, marginBottom: -2 }}
                  />{" "}
                  icon to reload a resource that encountered an error.
                </Typography>
              }
            >
              <InfoRounded
                style={{
                  fontSize: 14,
                  color: "#747474",
                  marginTop: 6,
                  cursor: "pointer",
                  transition: "100ms",
                }}
              />
            </Tooltip>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1.3}
            style={{ marginTop: 11, marginBottom: 8 }}
          >
            <div
              style={{
                width: 8,
                height: 8,
                borderRadius: 4,
                backgroundColor: "#f7b043",
              }}
            />
            <Typography
              variant="overline"
              style={{ lineHeight: 1, marginTop: 1 }}
            >
              waiting
            </Typography>
            <div
              style={{
                width: 8,
                height: 8,
                borderRadius: 4,
                backgroundColor: "#3bb97a",
                marginLeft: 16,
              }}
            />
            <Typography
              variant="overline"
              style={{ lineHeight: 1, marginTop: 1 }}
            >
              working
            </Typography>
            <div
              style={{
                width: 8,
                height: 8,
                borderRadius: 4,
                backgroundColor: "#d3302f",
                marginLeft: 16,
              }}
            />
            <Typography
              variant="overline"
              style={{ lineHeight: 1, marginTop: 1 }}
            >
              error
            </Typography>
          </Stack>
          <Grid container spacing={2} style={{ marginTop: -6 }}>
            {panelStatus.map((item) => (
              <Grid key={item.name} item xs={6} md={4} lg={3}>
                <Card variant="outlined" style={{ padding: 12 }}>
                  <Stack
                    direction="row"
                    spacing={1.3}
                    alignItems="center"
                    style={{ position: "relative" }}
                  >
                    <div
                      style={{
                        width: 8,
                        height: 8,
                        borderRadius: 4,
                        backgroundColor:
                          item.status === "loading"
                            ? "#f7b043"
                            : item.status === "success"
                            ? "#3bb97a"
                            : "#d3302f",
                      }}
                    />
                    <Typography
                      variant="overline"
                      style={{
                        lineHeight: 1,
                        marginTop: 1,
                        width: "68%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.name}
                    </Typography>
                    {item.status === "error" && item.updateFunction ? (
                      <IconButton
                        disabled={item.loading}
                        style={{
                          position: "absolute",
                          right: -6,
                          width: 34,
                          height: 34,
                        }}
                        onClick={() => {
                          item.loading = true
                          item.updateFunction()
                          setPanelStatus([...panelStatus])
                        }}
                      >
                        <RefreshRounded
                          className={item.loading ? "rotate-clockwise" : ""}
                          style={{ fontSize: 15 }}
                        />
                      </IconButton>
                    ) : item.status === "loading" && item.updateFunction ? (
                      <IconButton
                        disabled={item.loading}
                        style={{
                          position: "absolute",
                          right: -6,
                          width: 34,
                          height: 34,
                        }}
                        onClick={() => {
                          item.loading = true
                          item.updateFunction()
                          setPanelStatus([...panelStatus])
                        }}
                      >
                        <SyncRounded
                          className={item.loading ? "rotate" : ""}
                          style={{ fontSize: 15 }}
                        />
                      </IconButton>
                    ) : null}
                  </Stack>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Typography variant="h6" style={{ marginTop: 20 }}>
            Settings
          </Typography>
          <Stack direction="row" spacing={2} style={{ marginTop: 10 }}>
            <Card style={{ width: 220 }}>
              <CardContent style={{ padding: 16 }}>
                <Stack spacing={1}>
                  <Typography variant="h6" style={{ fontSize: "1rem" }}>
                    Show advanced options
                  </Typography>
                  <Typography variant="overline">
                    <FormControl>
                      <RadioGroup
                        name="radio-buttons-group-padding"
                        row
                        value={showAdvancedOption}
                        onChange={(e) => {
                          setShowAdvancedOption(e.target.value)
                        }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" />}
                          label="Yes"
                          className="form-control-label"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" />}
                          label="No"
                          className="form-control-label"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        </CardContent>
      </Card>
    </Fade>
  )
}

export default Dashboard
