import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { logger, Status } from "../services/utilities/utility"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  activationCodeCreate,
  activationCodeDelete,
} from "../services/graphql/mutations"
import { MainContext } from "./main"
import ActivationCode from "../models/activationCode"
import {
  activationCodeGet,
  activationCodeList,
} from "../services/graphql/queries"
import { AutocompleteOption } from "../services/config/interfaces"
import {
  ActivationCodePolicy,
  ActivationCodeType,
  NftActivationCodeType,
} from "../services/config/enum"

interface ActivationCodesContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  createActivationCode: (
    codeType: ActivationCodeType,
    policy: ActivationCodePolicy,
    maxIteration?: number,
    team?: { teamId: string; groupId?: string },
    challenge?: { challengeId: string },
    nft?: { nftCatalogId: string; nftCodeType?: NftActivationCodeType }
  ) => Promise<ActivationCode | boolean>
  getActivationCode: (code: string) => Promise<boolean | ActivationCode>
  deleteActivationCode: (code: string) => Promise<boolean>
  activationCodesList: ActivationCode[]
  activationCodesListNextToken: string | null
  getActivationCodesList: (withLoading?: boolean) => void
  loadMoreActivationCodes: () => Promise<boolean>
  hasSearched: boolean
  searchCodeType: AutocompleteOption | null
  setSearchCodeType: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchTeam: AutocompleteOption | null
  setSearchTeam: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchChallenge: AutocompleteOption | null
  setSearchChallenge: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchActivationCodes: (
    withLoading?: boolean,
    withNextToken?: boolean
  ) => Promise<boolean>
}

const ActivationCodesContext = createContext<ActivationCodesContextInterface>({
  loading: true,
  setLoading: () => {},
  updatingList: true,
  setUpdatingList: () => {},
  createActivationCode: async () => false,
  getActivationCode: async () => true,
  deleteActivationCode: async () => true,
  activationCodesList: [],
  activationCodesListNextToken: null,
  getActivationCodesList: () => {},
  loadMoreActivationCodes: async () => true,
  hasSearched: false,
  searchCodeType: null,
  setSearchCodeType: () => {},
  searchTeam: null,
  setSearchTeam: () => {},
  searchChallenge: null,
  setSearchChallenge: () => {},
  searchActivationCodes: async () => true,
})

const ActivationCodesController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // loadings
  const [loading, setLoading] = useState<boolean>(false)
  const [updatingList, setUpdatingList] = useState<boolean>(false)

  // states
  const [activationCodesList, setActivationCodesList] = useState<
    ActivationCode[]
  >([])
  const [activationCodesListNextToken, setActivationCodesListNextToken] =
    useState<string | null>(null)
  const [hasSearched, setHasSearched] = useState<boolean>(false) // if user has searched or not

  // search states
  const [searchCodeType, setSearchCodeType] =
    useState<AutocompleteOption | null>(null)
  const [searchTeam, setSearchTeam] = useState<AutocompleteOption | null>(null)
  const [searchChallenge, setSearchChallenge] =
    useState<AutocompleteOption | null>(null)

  // queries
  const [activationCodeListQuery] = useLazyQuery(activationCodeList)
  const [activationCodeGetQuery] = useLazyQuery(activationCodeGet)

  // mutations
  const [activationCodeCreateMutation] = useMutation(activationCodeCreate)
  const [activationCodeDeleteMutation] = useMutation(activationCodeDelete)

  // get activation codes list
  const getActivationCodesList = useCallback(
    async (withLoading: boolean = true) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter(
          (item) => item.name === "Activation codes"
        )[0].loading = true
        setPanelStatus([...panelStatus])
      }

      try {
        logger(Status.Api, "QUERY activationCodeList", activationCodeList)
        const { data } = await activationCodeListQuery({
          variables: {
            input: { limit: 100 },
          },
          fetchPolicy: "no-cache",
        })
        logger(
          Status.Info,
          "activation codes list",
          data.activationCodeList.items
        )

        setActivationCodesList(data.activationCodeList.items)
        setActivationCodesListNextToken(data.activationCodeList.nextToken)

        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter(
          (item) => item.name === "Activation codes"
        )[0].status = "success"
        panelStatus.filter(
          (item) => item.name === "Activation codes"
        )[0].loading = false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `activationCodeList`, e.message)
        }
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter(
          (item) => item.name === "Activation codes"
        )[0].status = "error"
        panelStatus.filter(
          (item) => item.name === "Activation codes"
        )[0].loading = false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // load more activation codes
  const loadMoreActivationCodes = async () => {
    try {
      logger(Status.Api, "QUERY activationCodeList", activationCodeList)
      const { data } = await activationCodeListQuery({
        variables: {
          input: {
            limit: 100,
            nextToken: activationCodesListNextToken,
          },
        },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "activation codes list", [
        ...activationCodesList,
        ...data.activationCodeList.items,
      ])

      setActivationCodesList((current) => [
        ...current,
        ...data.activationCodeList.items,
      ])
      setActivationCodesListNextToken(data.activationCodeList.nextToken)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `activationCodeList`, e.message)
      }
      return false
    }
  }

  // search activation codes
  const searchActivationCodes = async (
    withLoading = true,
    withNextToken = false
  ) => {
    if (withLoading) {
      setUpdatingList(true)
    }

    try {
      const input: {
        limit: number
        nextToken?: string
        codeType?: string
        team?: {
          teamId: string
          groupId?: string
        }
        challenge?: {
          challengeId: string
        }
      } = {
        limit: 100,
        codeType: searchCodeType ? searchCodeType.id : null,
        team: searchTeam
          ? {
              teamId: searchTeam.id,
            }
          : null,
        challenge: searchChallenge
          ? {
              challengeId: searchChallenge.id,
            }
          : null,
      }

      if (hasSearched && withNextToken) {
        input.nextToken = activationCodesListNextToken
      }

      logger(Status.Api, "QUERY activationCodeList", activationCodeList)
      const { data } = await activationCodeListQuery({
        variables: {
          input: input,
        },
        fetchPolicy: "no-cache",
      })

      if (input.nextToken) {
        logger(Status.Info, "activation codes list", [
          ...activationCodesList,
          ...data.activationCodeList.items,
        ])

        setActivationCodesList((current) => [
          ...current,
          ...data.activationCodeList.items,
        ])
        setActivationCodesListNextToken(data.activationCodeList.nextToken)
      } else {
        logger(
          Status.Info,
          "activation codes list",
          data.activationCodeList.items
        )

        setActivationCodesList(data.activationCodeList.items)
        setActivationCodesListNextToken(data.activationCodeList.nextToken)
      }

      setHasSearched(true)
      setUpdatingList(false)
      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `activationCodeList`, e.message)
      }
      setUpdatingList(false)
      return false
    }
  }

  // get activation code info
  const getActivationCode = async (code: string) => {
    try {
      logger(Status.Api, "QUERY activationCodeGet", activationCodeGet)
      const { data } = await activationCodeGetQuery({
        variables: {
          input: {
            code: code,
          },
        },
        fetchPolicy: "no-cache",
      })

      if (!data.activationCodeGet) {
        return false
      }

      if (data.activationCodeGet.challengeIdChallengeCode) {
        data.activationCodeGet.challengeId =
          data.activationCodeGet.challengeIdChallengeCode
        delete data.activationCodeGet.challengeIdChallengeCode
      }

      logger(Status.Info, `activation code ${code}`, data.activationCodeGet)

      return data.activationCodeGet
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `activationCodeGet`, e.message)
      }

      return false
    }
  }

  // create new activation code
  const createActivationCode = async (
    codeType: ActivationCodeType,
    policy: ActivationCodePolicy,
    maxIteration?: number,
    team?: { teamId: string; groupId?: string },
    challenge?: { challengeId: string },
    nft?: { nftCatalogId: string; nftCodeType?: NftActivationCodeType }
  ) => {
    setLoading(true)

    try {
      const input: {
        codeType: ActivationCodeType
        policy: ActivationCodePolicy
        maxIteration?: number
        team?: { teamId: string; groupId?: string }
        challenge?: { challengeId: string }
        nft?: { nftCatalogId: string; nftCodeType?: NftActivationCodeType }
      } = {
        codeType: codeType,
        policy: policy,
      }

      if (maxIteration && policy === ActivationCodePolicy.IterationLimited) {
        input.maxIteration = maxIteration
      }
      if (team) {
        input.team = team
      }
      if (challenge) {
        input.challenge = challenge
      }
      if (nft) {
        input.nft = nft
      }

      logger(Status.Api, "MUTATION activationCodeCreate", activationCodeCreate)
      const { data } = await activationCodeCreateMutation({
        variables: {
          input: input,
        },
      })
      logger(Status.Info, `activation code created`, data.activationCodeCreate)

      setLoading(false)

      return data.activationCodeCreate
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `activationCodeCreate`, e.message)
      }

      setLoading(false)
      return false
    }
  }

  // delete activation code
  const deleteActivationCode = async (code: string) => {
    try {
      logger(Status.Api, "MUTATION activationCodeDelete", activationCodeDelete)
      const { data } = await activationCodeDeleteMutation({
        variables: {
          input: {
            code: code,
          },
        },
      })
      logger(
        Status.Info,
        `activation code ${code} deleted`,
        data.activationCodeDelete
      )

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `activationCodeDelete`, e.message)
      }

      return false
    }
  }

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter(
      (item) => item.name === "Activation codes"
    )[0].updateFunction = getActivationCodesList
  }, [])

  return (
    <ActivationCodesContext.Provider
      value={{
        loading,
        setLoading,
        updatingList,
        setUpdatingList,
        createActivationCode,
        getActivationCode,
        deleteActivationCode,
        activationCodesList,
        activationCodesListNextToken,
        getActivationCodesList,
        loadMoreActivationCodes,
        hasSearched,
        searchCodeType,
        setSearchCodeType,
        searchTeam,
        setSearchTeam,
        searchChallenge,
        setSearchChallenge,
        searchActivationCodes,
      }}
    >
      {children}
    </ActivationCodesContext.Provider>
  )
}

export { ActivationCodesController, ActivationCodesContext }
