import Media from "../../models/media"

export const mediaMockData: Media[] = [
  {
    id: "2022/07/titolo-di-test-d922c967d90a",
    mediaTags: null,
    url: "https://d1kmq5hop6362i.cloudfront.net/2022/07/titolo-di-test-d922c967d90a",
    title: "Titolo di test",
    fileType: "image/jpeg",
    description: "Descrizione di test",
    createdAt: "2022-07-06T16:15:11.285Z",
    updatedAt: "2022-07-06T16:15:11.285Z",
    size: null,
    category: "background",
  },
  {
    id: "2022/07/titolo-di-test-d922c967d90b",
    mediaTags: null,
    url: "https://picsum.photos/id/1/1000/1500",
    title: "Titolo di test",
    fileType: "image/jpeg",
    description: "Descrizione di test",
    createdAt: "2022-07-06T16:15:11.285Z",
    updatedAt: "2022-07-06T16:15:11.285Z",
    size: null,
    category: "background",
  },
  {
    id: "2022/07/titolo-di-test-d922c967d90c",
    mediaTags: null,
    url: "https://picsum.photos/id/10/1000/1500",
    title: "Titolo di test",
    fileType: "image/jpeg",
    description: "Descrizione di test",
    createdAt: "2022-07-06T16:15:11.285Z",
    updatedAt: "2022-07-06T16:15:11.285Z",
    size: null,
    category: "background",
  },
  {
    id: "2022/07/titolo-di-test-d922c967d90d",
    mediaTags: null,
    url: "https://picsum.photos/id/1003/1000/1500",
    title: "Titolo di test",
    fileType: "image/jpeg",
    description: "Descrizione di test",
    createdAt: "2022-07-06T16:15:11.285Z",
    updatedAt: "2022-07-06T16:15:11.285Z",
    size: null,
    category: "background",
  },
  {
    id: "2022/07/titolo-di-test-d922c967d90e",
    mediaTags: null,
    url: "https://picsum.photos/id/1010/1000/1500",
    title: "Titolo di test",
    fileType: "image/jpeg",
    description: "Descrizione di test",
    createdAt: "2022-07-06T16:15:11.285Z",
    updatedAt: "2022-07-06T16:15:11.285Z",
    size: null,
    category: "background",
  },
  {
    id: "2022/07/titolo-di-test-d922c967d90f",
    mediaTags: null,
    url: "https://picsum.photos/id/1014/1000/1500",
    title: "Titolo di test",
    fileType: "image/jpeg",
    description: "Descrizione di test",
    createdAt: "2022-07-06T16:15:11.285Z",
    updatedAt: "2022-07-06T16:15:11.285Z",
    size: null,
    category: "background",
  },
  {
    id: "2022/07/titolo-di-test-d922c967d90g",
    mediaTags: null,
    url: "https://picsum.photos/id/1024/1000/1500",
    title: "Titolo di test",
    fileType: "image/jpeg",
    description: "Descrizione di test",
    createdAt: "2022-07-06T16:15:11.285Z",
    updatedAt: "2022-07-06T16:15:11.285Z",
    size: null,
    category: "background",
  },
  {
    id: "2022/07/titolo-di-test-d922c967d90h",
    mediaTags: null,
    url: "https://picsum.photos/id/1031/1000/1500",
    title: "Titolo di test",
    fileType: "image/jpeg",
    description: "Descrizione di test",
    createdAt: "2022-07-06T16:15:11.285Z",
    updatedAt: "2022-07-06T16:15:11.285Z",
    size: null,
    category: "background",
  },
  {
    id: "2022/07/titolo-di-test-d922c967d90i",
    mediaTags: null,
    url: "https://picsum.photos/id/1033/1000/1500",
    title: "Titolo di test",
    fileType: "image/jpeg",
    description: "Descrizione di test",
    createdAt: "2022-07-06T16:15:11.285Z",
    updatedAt: "2022-07-06T16:15:11.285Z",
    size: null,
    category: "background",
  },
  {
    id: "2022/07/titolo-di-test-d922c967d90j",
    mediaTags: null,
    url: "https://picsum.photos/id/1038/1000/1500",
    title: "Titolo di test",
    fileType: "image/jpeg",
    description: "Descrizione di test",
    createdAt: "2022-07-06T16:15:11.285Z",
    updatedAt: "2022-07-06T16:15:11.285Z",
    size: null,
    category: "background",
  },
  {
    id: "2022/07/titolo-di-test-d922c967d90k",
    mediaTags: null,
    url: "https://picsum.photos/id/1043/1000/1500",
    title: "Titolo di test",
    fileType: "image/jpeg",
    description: "Descrizione di test",
    createdAt: "2022-07-06T16:15:11.285Z",
    updatedAt: "2022-07-06T16:15:11.285Z",
    size: null,
    category: "background",
  },
  {
    id: "2022/07/titolo-di-test-d922c967d90l",
    mediaTags: null,
    url: "https://picsum.photos/id/1049/1000/1500",
    title: "Titolo di test",
    fileType: "image/jpeg",
    description: "Descrizione di test",
    createdAt: "2022-07-06T16:15:11.285Z",
    updatedAt: "2022-07-06T16:15:11.285Z",
    size: null,
    category: "background",
  },
  {
    id: "2022/07/titolo-di-test-d922c967d90m",
    mediaTags: null,
    url: "https://picsum.photos/id/1050/1000/1500",
    title: "Titolo di test",
    fileType: "image/jpeg",
    description: "Descrizione di test",
    createdAt: "2022-07-06T16:15:11.285Z",
    updatedAt: "2022-07-06T16:15:11.285Z",
    size: null,
    category: "background",
  },
  {
    id: "2022/07/titolo-di-test-d922c967d90n",
    mediaTags: null,
    url: "https://picsum.photos/id/1053/1000/1500",
    title: "Titolo di test",
    fileType: "image/jpeg",
    description: "Descrizione di test",
    createdAt: "2022-07-06T16:15:11.285Z",
    updatedAt: "2022-07-06T16:15:11.285Z",
    size: null,
    category: "background",
  },
  {
    id: "2022/07/titolo-di-test-d922c967d90o",
    mediaTags: null,
    url: "https://picsum.photos/id/1056/1000/1500",
    title: "Titolo di test",
    fileType: "image/jpeg",
    description: "Descrizione di test",
    createdAt: "2022-07-06T16:15:11.285Z",
    updatedAt: "2022-07-06T16:15:11.285Z",
    size: null,
    category: "background",
  },
]
