export const journeysMockData = {
  stagedJourneys: {
    items: [
      {
        id: "4c1ced95-773c-44dc-8e68-f182ea88d4f3",
        lang: "it",
        status: "ACTIVE",
        title: "Titolo journey di prova Marco 1",
        stage: "PUBLISHED",
        text: null,
        image: null,
        publishedLangs: [
          {
            lang: "it",
            title: "Panda",
          },
        ],
        hasSibling: true,
        createdAt: "2022-08-22T10:41:09.841Z",
        updatedAt: "2022-08-22T11:21:46.057Z",
        episodes: [
          {
            sorting: 1,
            episode: {
              id: "b41106c0-0d96-49f3-bb46-0c2c282664ce",
            },
          },
        ],
      },
    ],
    nextToken: null,
  },
}
