import {
  Chip,
  LinearProgress,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import ListContainer from "../../components/global/listContainer"
import ListTable from "../../components/global/listTable"
import TableSpinner from "../../components/global/tableSpinner"
import { MainContext } from "../../controllers/main"
import {
  calculateTimeElapsed,
  capitalizeFirstCharacter,
  registerAnalyticsEvent,
} from "../../services/utilities/utility"
import TitleTableCell from "../../components/global/titleTableCell"
import { NotificationsContext } from "../../controllers/notifications"
import NotificationTemplate from "../../models/notificationTemplate"
import TemplatesListTopBar from "../../components/notification/templatesListTopBar"
import CreateTemplateDialog from "../../components/notification/createTemplateDialog"
import TranslationsStack from "../../components/global/translationsStack"
import PrimaryBadge from "../../components/global/primaryBadge"
import {
  EmailRounded,
  NotificationsActiveRounded,
  SmartphoneRounded,
} from "@mui/icons-material"
import { NotificationTemplateType } from "../../services/config/enum"

const TemplatesList = () => {
  const { setAnimation, showPadding, cmdPressed } = useContext(MainContext)
  const {
    loading,
    templatesList,
    templatesListNextToken,
    getTemplatesList,
    searchTemplatesList,
    loadMoreTemplates,
    hasSearched,
    updatingList,
    setUpdatingList,
    searchTitle,
    setSearchTitle,
    searchStage,
    searchLang,
  } = useContext(NotificationsContext)
  const navigate = useNavigate()

  // list background update
  const backgroundUpdate = () => {
    if (!templatesList.length) {
      getTemplatesList()
    } else if (!loading && !hasSearched) {
      setUpdatingList(true)
      getTemplatesList(false)
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    backgroundUpdate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create action dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // load more button loading
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // register analytics event
  useEffect(() => {
    registerAnalyticsEvent({ type: "page_view", name: "templates_list" })
  }, [])

  return (
    <ListContainer style={{ position: "relative" }}>
      {updatingList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 143,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <TemplatesListTopBar
        loading={loading}
        textFieldValue={searchTitle}
        textFieldPlaceholder="Search Templates"
        addButtonLabel="Add Template"
        textFieldOnChange={(e) => {
          setSearchTitle(e.target.value)
          if (e.target.value.length === 0 && hasSearched) {
            setUpdatingList(true)

            if (!searchLang && !searchStage) {
              getTemplatesList(false)
            } else {
              searchTemplatesList()
            }
          }
        }}
        textFieldOnKeyDown={(e) => {
          if (e.key === "Enter" && searchTitle.length > 2) {
            searchTemplatesList()
          }
        }}
        cancelButtonOnClick={() => {
          setSearchTitle("")
          if (hasSearched) {
            setUpdatingList(true)

            if (!searchLang && !searchStage) {
              getTemplatesList(false)
            } else {
              searchTemplatesList()
            }
          }
        }}
        searchButtonOnClick={() => {
          searchTemplatesList()
        }}
        addButtonOnClick={handleDialogChange}
      />
      {loading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
          headingItems={[
            "Stage",
            "Handle & ID",
            "Translations",
            "Type",
            "Format",
            "Updated",
          ]}
          nextToken={templatesListNextToken}
          loadingMoreItems={loadMoreLoading}
          setLoadingMoreItems={setLoadMoreLoading}
          loadMore={
            !searchTitle.length
              ? loadMoreTemplates
              : async () => {
                  const result = await searchTemplatesList(false, true)
                  return result
                }
          }
          tableBody={templatesList.map((item: NotificationTemplate) => (
            <TableRow
              key={item.id}
              hover
              style={{
                cursor: "pointer",
              }}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
              onClick={() => {
                if (cmdPressed) {
                  window.open(`${window.location.href}/${item.id}`)
                } else {
                  setAnimation(false)
                  setTimeout(() => {
                    navigate(`/templates/${item.id}`)
                  }, 250)
                }
              }}
            >
              <TableCell style={{ position: "relative" }}>
                {item.stage === "PUBLISHED" || item.hasSibling ? (
                  <Chip
                    size="small"
                    label="Live"
                    color="success"
                    style={{ position: "relative", zIndex: 1 }}
                  />
                ) : item.stage === "ARCHIVED" ? (
                  <Chip
                    size="small"
                    label="Archived"
                    style={{ position: "relative", zIndex: 1 }}
                  />
                ) : (
                  <Chip
                    size="small"
                    label="Draft"
                    color="warning"
                    style={{ position: "relative", zIndex: 1 }}
                  />
                )}
                {item.hasSibling ? (
                  <Chip
                    size="small"
                    label="Draft"
                    color="warning"
                    style={{ position: "absolute", left: 18 }}
                  />
                ) : null}
              </TableCell>
              <TitleTableCell title={item.handle} id={item.id} />
              <TableCell>
                <TranslationsStack documentItems={item.document.items} />
              </TableCell>
              <TableCell>
                <PrimaryBadge
                  count={capitalizeFirstCharacter(item.type.toLowerCase())}
                  icon={
                    item.type === NotificationTemplateType.EMAIL ? (
                      <EmailRounded style={{ fontSize: 12 }} />
                    ) : item.type === NotificationTemplateType.PUSH ? (
                      <NotificationsActiveRounded style={{ fontSize: 12 }} />
                    ) : (
                      <SmartphoneRounded style={{ fontSize: 12 }} />
                    )
                  }
                />
              </TableCell>
              <TableCell>
                <Chip
                  label={
                    item.format === "MJML"
                      ? item.format
                      : capitalizeFirstCharacter(item.format.toLowerCase())
                  }
                  size="small"
                />
              </TableCell>
              <TableCell style={{ minWidth: 120 }}>
                <Typography variant="caption">
                  {calculateTimeElapsed(item.updatedAt)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        />
      )}
      <CreateTemplateDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
      />
    </ListContainer>
  )
}

export default TemplatesList
