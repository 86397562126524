import { useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  notificationTemplatePublish,
  notificationTemplateUpsert,
  notificationTemplateValidate,
  upsertDocumentNotificationTemplate,
} from "../services/graphql/mutations"
import {
  notificationTemplateStagedGet,
  notificationTemplatesStagedList,
  notificationTemplatesSearch,
  enrichersList as listEnrichers,
} from "../services/graphql/queries"
import { deepCopy, logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"
import {
  NotificationTemplateFormat,
  NotificationTemplateType,
  Stage,
} from "../services/config/enum"
import Document from "../models/document"
import NotificationTemplate from "../models/notificationTemplate"
import Enricher from "../models/enricher"
import NotificationTemplatesDocument from "../models/notificationTemplatesDocument"
import { AutocompleteOption } from "../services/config/interfaces"

interface NotificationsContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  templatesList: NotificationTemplate[]
  setTemplatesList: Dispatch<SetStateAction<NotificationTemplate[]>>
  getTemplatesList: (withLoading?: boolean) => void
  doneChanges: boolean
  cancelChanges: () => void
  currentTemplate: NotificationTemplate
  setCurrentTemplate: Dispatch<SetStateAction<NotificationTemplate>>
  getCurrentTemplate: (templateId: string) => Promise<boolean>
  preChangesCurrentTemplate: NotificationTemplate
  addTranslation: (translationToAdd: string) => void
  upsertTemplateParent: () => Promise<boolean>
  hasError: boolean
  translationsErrors: { lang: string; hasErrors: boolean }[]
  setTranslationsErrors: Dispatch<
    SetStateAction<{ lang: string; hasErrors: boolean }[]>
  >
  upsertTemplateDocument: (publish?: boolean) => Promise<boolean>
  copyDetailsFromDefault: (itemToCopyToIndex: number) => void
  createTemplate: (input: {
    handle: string
    format: NotificationTemplateFormat
    type: NotificationTemplateType
    defaultDataJson?: string
    requiredEnricherIds?: string[]
  }) => Promise<string | boolean>
  createTemplateDocument: (input: {
    parentId: string
    type: string
    notificationTemplateDocumentItems: {
      default: boolean
      lang: string
      title: string
      subtitle?: string
      contentBody: string
      richContentBody?: string
    }[]
  }) => Promise<boolean>
  templatesListNextToken: string | null
  loadMoreTemplates: () => Promise<boolean>
  hasSearched: boolean
  setHasSearched: Dispatch<SetStateAction<boolean | null>>
  searchTitle: string
  setSearchTitle: Dispatch<SetStateAction<string>>
  searchStage: AutocompleteOption | null
  setSearchStage: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchLang: AutocompleteOption | null
  setSearchLang: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchTemplatesList: (
    withLoading?: boolean,
    withNextToken?: boolean
  ) => Promise<boolean>
  handleError: boolean
  setHandleError: Dispatch<SetStateAction<boolean>>
  formErrors: any[]
  setFormErrors: Dispatch<SetStateAction<any[]>>
  publishTemplate: () => Promise<boolean>
  enrichersList: Enricher[]
  validateTemplate: () => Promise<boolean>
}

const NotificationsContext = createContext<NotificationsContextInterface>({
  loading: true,
  setLoading: () => {},
  updatingList: false,
  setUpdatingList: () => {},
  editMode: false,
  setEditMode: () => {},
  templatesList: [],
  setTemplatesList: () => {},
  getTemplatesList: () => {},
  doneChanges: false,
  cancelChanges: () => {},
  currentTemplate: null,
  setCurrentTemplate: () => {},
  getCurrentTemplate: async () => true,
  preChangesCurrentTemplate: null,
  addTranslation: () => {},
  upsertTemplateParent: async () => true,
  hasError: false,
  translationsErrors: [],
  setTranslationsErrors: () => {},
  upsertTemplateDocument: async () => true,
  copyDetailsFromDefault: () => {},
  createTemplate: async () => true,
  createTemplateDocument: async () => true,
  templatesListNextToken: null,
  loadMoreTemplates: async () => true,
  hasSearched: false,
  setHasSearched: () => {},
  searchTitle: "",
  setSearchTitle: () => {},
  searchStage: null,
  setSearchStage: () => {},
  searchLang: null,
  setSearchLang: () => {},
  searchTemplatesList: async () => true,
  handleError: false,
  setHandleError: () => {},
  formErrors: [],
  setFormErrors: () => {},
  publishTemplate: async () => true,
  enrichersList: [],
  validateTemplate: async () => true,
})

const NotificationsController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // states
  const [loading, setLoading] = useState<boolean>(true) // loading
  const [updatingList, setUpdatingList] = useState<boolean>(false) // to show loader
  const [editMode, setEditMode] = useState<boolean>(true) // if all page should be disabled or not
  const [templatesList, setTemplatesList] = useState<NotificationTemplate[]>([]) // list of all templates
  const [doneChanges, setDoneChanges] = useState<boolean>(false) // if user has done changes
  const [currentTemplate, setCurrentTemplate] = useState<NotificationTemplate>() // current template
  const [preChangesCurrentTemplate, setPreChangesCurrentTemplate] =
    useState<NotificationTemplate>() // fetched current template
  const [templatesListNextToken, setTemplatesListNextToken] = useState<
    string | null
  >(null) // next token for templates list
  const [hasSearched, setHasSearched] = useState<boolean>(false) // if user has searched or not
  const [enrichersList, setEnrichersList] = useState<Enricher[]>([]) // list of enrichers for templates

  // search states
  const [searchTitle, setSearchTitle] = useState<string>("")
  const [searchStage, setSearchStage] = useState<AutocompleteOption | null>(
    null
  )
  const [searchLang, setSearchLang] = useState<AutocompleteOption | null>(null)

  // errors for template edit
  const [handleError, setHandleError] = useState<boolean>(false)
  const [formErrors, setFormErrors] = useState<any[]>([])
  const [translationsErrors, setTranslationsErrors] = useState<
    { lang: string; hasErrors: boolean }[]
  >([]) // errors array for translations
  const hasError: boolean =
    handleError ||
    formErrors.length > 0 ||
    translationsErrors.filter((item) => item.hasErrors).length !== 0 // if there are errors or not

  // reset all errors
  const resetErrors = () => {
    setHandleError(false)
    setFormErrors([])
    const newTranslationsErrors: { lang: string; hasErrors: boolean }[] = []
    currentTemplate.document.items.forEach((item) => {
      newTranslationsErrors.push({ lang: item.lang, hasErrors: false })
    })
    setTranslationsErrors(newTranslationsErrors)
  }

  // queries
  const [notificationTemplatesStagedListQuery] = useLazyQuery(
    notificationTemplatesStagedList
  )
  const [notificationTemplatesSearchQuery] = useLazyQuery(
    notificationTemplatesSearch
  )
  const [notificationTemplateStagedGetQuery] = useLazyQuery(
    notificationTemplateStagedGet
  )
  const [listEnrichersQuery] = useLazyQuery(listEnrichers)

  // mutations
  const [notificationTemplateUpsertMutation] = useMutation(
    notificationTemplateUpsert
  )
  const [upsertDocumentNotificationTemplateMutation] = useMutation(
    upsertDocumentNotificationTemplate
  )
  const [notificationTemplatePublishMutation] = useMutation(
    notificationTemplatePublish
  )
  const [notificationTemplateValidateMutation] = useMutation(
    notificationTemplateValidate
  )

  // get templates list
  const getTemplatesList = useCallback(
    async (withLoading: boolean = true) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter((item) => item.name === "Templates")[0].loading =
          true
        setPanelStatus([...panelStatus])
      }

      try {
        logger(
          Status.Api,
          "QUERY notificationTemplatesStagedList",
          notificationTemplatesStagedList
        )
        const { data } = await notificationTemplatesStagedListQuery({
          variables: {
            input: {
              limit: Math.round(window.innerHeight / 74) + 15,
            },
          },
          fetchPolicy: "no-cache",
        })
        logger(
          Status.Info,
          "templates list",
          data.notificationTemplatesStagedList.items
        )

        setTemplatesList(data.notificationTemplatesStagedList.items)
        setTemplatesListNextToken(
          data.notificationTemplatesStagedList.nextToken
        )

        setHasSearched(false)
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Templates")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "Templates")[0].loading =
          false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `notificationTemplatesStagedList`, e.message)
        }
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Templates")[0].status =
          "error"
        panelStatus.filter((item) => item.name === "Templates")[0].loading =
          false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // load more templates
  const loadMoreTemplates = async () => {
    try {
      logger(
        Status.Api,
        "QUERY notificationTemplatesStagedList",
        notificationTemplatesStagedList
      )
      const { data } = await notificationTemplatesStagedListQuery({
        variables: {
          input: {
            limit: Math.round(window.innerHeight / 74) + 10,
            nextToken: templatesListNextToken,
          },
        },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "templates list", [
        ...templatesList,
        ...data.notificationTemplatesStagedList.items,
      ])

      setTemplatesList((current) => [
        ...current,
        ...data.notificationTemplatesStagedList.items,
      ])
      setTemplatesListNextToken(data.notificationTemplatesStagedList.nextToken)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `notificationTemplatesStagedList`, e.message)
      }
      return false
    }
  }

  // search templates
  const searchTemplatesList = async (
    withLoading = true,
    withNextToken = false
  ) => {
    if (withLoading) {
      setUpdatingList(true)
    }

    try {
      const input: {
        limit: number
        text?: string
        stage?: Stage
        lang?: string
        nextToken?: string
      } = {
        limit: Math.round(window.innerHeight / 74) + 10,
        text: searchTitle,
        stage: searchStage ? (searchStage.id as Stage) : null,
        lang: searchLang ? searchLang.id : null,
      }

      if (hasSearched && withNextToken) {
        input.nextToken = templatesListNextToken
      }

      logger(
        Status.Api,
        "QUERY notificationTemplatesSearch",
        notificationTemplatesSearch
      )
      const { data } = await notificationTemplatesSearchQuery({
        variables: {
          input: input,
        },
        fetchPolicy: "no-cache",
      })

      if (input.nextToken) {
        logger(Status.Info, "templates list", [
          ...templatesList,
          ...data.notificationTemplatesSearch.items,
        ])

        setTemplatesList((current) => [
          ...current,
          ...data.notificationTemplatesSearch.items,
        ])
        setTemplatesListNextToken(data.notificationTemplatesSearch.nextToken)
      } else {
        logger(
          Status.Info,
          "templates list",
          data.notificationTemplatesSearch.items
        )

        setTemplatesList(data.notificationTemplatesSearch.items)
        setTemplatesListNextToken(data.notificationTemplatesSearch.nextToken)
      }

      setHasSearched(true)
      setUpdatingList(false)
      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `notificationTemplatesSearch`, e.message)
      }
      setUpdatingList(false)
      return false
    }
  }

  // get current template
  const getCurrentTemplate = useCallback(
    async (templateId: string) => {
      try {
        logger(
          Status.Api,
          "QUERY notificationTemplateStagedGet",
          notificationTemplateStagedGet
        )
        const { data } = await notificationTemplateStagedGetQuery({
          variables: { input: { id: templateId } },
          fetchPolicy: "no-cache",
        })
        logger(
          Status.Info,
          `template ${templateId}`,
          data.notificationTemplateStagedGet
        )

        setCurrentTemplate(data.notificationTemplateStagedGet)
        setPreChangesCurrentTemplate(
          deepCopy(data.notificationTemplateStagedGet)
        )
        data.notificationTemplateStagedGet.document.items.forEach(
          (item: Document) => {
            translationsErrors.push({ lang: item.lang, hasErrors: false })
          }
        )
        setTranslationsErrors([...translationsErrors])

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `notificationTemplateStagedGet`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // upsert template parent
  const upsertTemplateParent = async () => {
    try {
      const input = {
        id: currentTemplate.id,
        handle: currentTemplate.handle,
        format: currentTemplate.format,
        type: currentTemplate.type,
        requiredEnricherIds: currentTemplate.requiredEnricherIds ?? [],
        defaultDataJson:
          currentTemplate.requiredEnricherIds &&
          currentTemplate.requiredEnricherIds.length
            ? currentTemplate.defaultDataJson
            : null,
      }

      logger(
        Status.Api,
        "MUTATION notificationTemplateUpsert",
        notificationTemplateUpsert
      )
      const { data } = await notificationTemplateUpsertMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `template ${data.notificationTemplateUpsert.id} upserted`,
        data.notificationTemplateUpsert
      )

      setCurrentTemplate((current) => {
        return {
          ...data.notificationTemplateUpsert,
          document: current.document,
        }
      })
      setPreChangesCurrentTemplate((current) => {
        return {
          ...deepCopy(data.notificationTemplateUpsert),
          document: current.document,
        }
      })

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `notificationTemplateUpsert`, e.message)
      }
      return false
    }
  }

  // upsert template document
  const upsertTemplateDocument = async (publish = false) => {
    try {
      // parse data for input
      const currentTemplateCopy = deepCopy(currentTemplate)
      currentTemplateCopy.document.items.forEach((item: any) => {
        item.default = item.isDefault
        delete item.isDefault
        delete item.type
        delete item.updatedAt
        delete item.parentId
        delete item.__typename
        delete item.body
      })

      const input = {
        type: "NotificationTemplate",
        parentId: `#id#${currentTemplateCopy.id}#stage#${
          publish ? "PUBLISHED" : "DRAFT"
        }`,
        notificationTemplateDocumentItems: currentTemplateCopy.document.items,
      }

      logger(
        Status.Api,
        "MUTATION documentUpsert",
        upsertDocumentNotificationTemplate
      )
      const { data } = await upsertDocumentNotificationTemplateMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `template ${currentTemplate.id} document upserted`,
        data.documentUpsert
      )

      setCurrentTemplate((current) => {
        return { ...current, document: data.documentUpsert }
      })
      setPreChangesCurrentTemplate((current) => {
        return { ...current, document: deepCopy(data.documentUpsert) }
      })

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // publish template
  const publishTemplate = async () => {
    try {
      logger(
        Status.Api,
        "MUTATION notificationTemplatePublish",
        notificationTemplatePublish
      )
      await Promise.all([
        notificationTemplatePublishMutation({
          variables: { input: { id: currentTemplate.id } },
        }),
        upsertTemplateDocument(true),
      ])
      logger(Status.Info, `template ${currentTemplate.id} published`)

      // update current template locally
      setCurrentTemplate({
        ...currentTemplate,
        stage: Stage.PUBLISHED,
      })
      setPreChangesCurrentTemplate({
        ...preChangesCurrentTemplate,
        stage: Stage.PUBLISHED,
      })

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `notificationTemplatePublish`, e.message)
      }
      return false
    }
  }

  // create template
  const createTemplate = async (input: {
    handle: string
    format: NotificationTemplateFormat
    type: NotificationTemplateType
    defaultDataJson?: string
    requiredEnricherIds?: string[]
  }) => {
    try {
      logger(
        Status.Api,
        "MUTATION notificationTemplateUpsert",
        notificationTemplateUpsert
      )
      const { data } = await notificationTemplateUpsertMutation({
        variables: { input: input },
      })
      logger(Status.Info, `template created`, data.notificationTemplateUpsert)

      return data.notificationTemplateUpsert.id
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `notificationTemplateUpsert`, e.message)
      }
      return false
    }
  }

  // create template document
  const createTemplateDocument = async (input: {
    parentId: string
    type: string
    notificationTemplateDocumentItems: {
      default: boolean
      lang: string
      title: string
      subtitle?: string
      contentBody: string
      richContentBody?: string
    }[]
  }) => {
    try {
      logger(
        Status.Api,
        "MUTATION documentUpsert",
        upsertDocumentNotificationTemplate
      )
      const { data } = await upsertDocumentNotificationTemplateMutation({
        variables: { input: input },
      })
      logger(Status.Info, `document created`, data.documentUpsert)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // add translation
  const addTranslation = (translationToAdd: string) => {
    const documentToAdd: NotificationTemplatesDocument = {
      body: [],
      isDefault: false,
      lang: translationToAdd,
      title: "Title",
      contentBody: "Content",
      type: "NotificationTemplate",
    }

    translationsErrors.push({
      lang: translationToAdd,
      hasErrors: false,
    })
    setTranslationsErrors([...translationsErrors])

    currentTemplate.document.items.push(documentToAdd)
    setCurrentTemplate({ ...currentTemplate })
  }

  // copy details from default translation
  const copyDetailsFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = deepCopy(
      currentTemplate.document.items.filter((item) => item.isDefault)[0]
    )

    currentTemplate.document.items[itemToCopyToIndex].title = defaultItem.title
    currentTemplate.document.items[itemToCopyToIndex].subtitle =
      defaultItem.subtitle
    currentTemplate.document.items[itemToCopyToIndex].contentBody =
      defaultItem.contentBody
    currentTemplate.document.items[itemToCopyToIndex].richContentBody =
      defaultItem.richContentBody

    setCurrentTemplate({ ...currentTemplate })
  }

  // cancel changes
  const cancelChanges = () => {
    setCurrentTemplate(deepCopy(preChangesCurrentTemplate))
    resetErrors()
  }

  // check if user has done changes
  useEffect(() => {
    if (currentTemplate) {
      if (
        JSON.stringify(currentTemplate) !==
        JSON.stringify(preChangesCurrentTemplate)
      ) {
        setDoneChanges(true)
      } else {
        setDoneChanges(false)
      }
    }
  }, [currentTemplate])

  // validate template
  const validateTemplate = async () => {
    try {
      logger(
        Status.Api,
        "MUTATION notificationTemplateValidate",
        notificationTemplateValidate
      )
      const { data } = await notificationTemplateValidateMutation({
        variables: { input: { id: currentTemplate.id } },
      })
      logger(Status.Info, `template ${currentTemplate.id} validation completed`)

      // update current template locally
      setCurrentTemplate({
        ...currentTemplate,
        invalidLangs: data.notificationTemplateValidate.invalidLangs,
        validationStatus: data.notificationTemplateValidate.validationStatus,
      })
      setPreChangesCurrentTemplate(
        deepCopy({
          ...preChangesCurrentTemplate,
          invalidLangs: data.notificationTemplateValidate.invalidLangs,
          validationStatus: data.notificationTemplateValidate.validationStatus,
        })
      )

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `notificationTemplateValidate`, e.message)
      }
      return false
    }
  }

  // get enrichers list
  const getEnrichersList = async () => {
    panelStatus.filter((item) => item.name === "Enrichers")[0].loading = true
    setPanelStatus([...panelStatus])

    try {
      logger(Status.Api, "QUERY enrichersList", listEnrichers)
      const { data } = await listEnrichersQuery({
        variables: {
          input: {
            limit: Math.round(window.innerHeight / 74) + 15,
          },
        },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "enrichers list", data.enrichersList.items)

      setEnrichersList(data.enrichersList.items)

      panelStatus.filter((item) => item.name === "Enrichers")[0].status =
        "success"
      panelStatus.filter((item) => item.name === "Enrichers")[0].loading = false
      setPanelStatus([...panelStatus])
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `enrichersList`, e.message)
      }
      panelStatus.filter((item) => item.name === "Enrichers")[0].status =
        "error"
      panelStatus.filter((item) => item.name === "Enrichers")[0].loading = false
      setPanelStatus([...panelStatus])
    }
  }

  // add update function to panel status
  useEffect(() => {
    getEnrichersList()

    panelStatus.filter((item) => item.name === "Templates")[0].updateFunction =
      getTemplatesList
    panelStatus.filter((item) => item.name === "Enrichers")[0].updateFunction =
      getEnrichersList
  }, [])

  return (
    <NotificationsContext.Provider
      value={{
        loading,
        setLoading,
        updatingList,
        setUpdatingList,
        editMode,
        setEditMode,
        templatesList,
        setTemplatesList,
        getTemplatesList,
        doneChanges,
        cancelChanges,
        currentTemplate,
        setCurrentTemplate,
        getCurrentTemplate,
        preChangesCurrentTemplate,
        addTranslation,
        upsertTemplateParent,
        hasError,
        translationsErrors,
        setTranslationsErrors,
        upsertTemplateDocument,
        copyDetailsFromDefault,
        createTemplate,
        createTemplateDocument,
        templatesListNextToken,
        loadMoreTemplates,
        hasSearched,
        setHasSearched,
        searchTitle,
        setSearchTitle,
        searchStage,
        setSearchStage,
        searchLang,
        setSearchLang,
        searchTemplatesList,
        handleError,
        setHandleError,
        formErrors,
        setFormErrors,
        publishTemplate,
        enrichersList,
        validateTemplate,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export { NotificationsController, NotificationsContext }
