import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  Button,
  CardActions,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Dialog,
  MenuItem,
  Select,
  InputAdornment,
  Divider,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import Sdg from "../../models/sdg"
import SdgTarget from "../../models/sdgTarget"
// import Topic from "../../models/topic"
import {
  DialogTransition,
  capitalizeFirstCharacter,
  isValidURL,
  lowercaseFirstCharacter,
  renderOption,
  renderTags,
  stripHtml,
  testYtURL,
} from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import { useNavigate } from "react-router-dom"
import { MainContext } from "../../controllers/main"
import { LanguagesContext } from "../../controllers/languages"
import { ActionsContext } from "../../controllers/actions"
import Action from "../../models/action"
import EmbedSlice from "../../models/bodySlices/embedSlice"
import ImageSlice from "../../models/bodySlices/imageSlice"
import QuoteSlice from "../../models/bodySlices/quoteSlice"
import TextSlice from "../../models/bodySlices/textSlice"
import BlockEditor from "../blockEditor/blockEditor"
import { CountriesContext } from "../../controllers/countries"
import { AddCircleRounded, DeleteRounded } from "@mui/icons-material"
import ImagePicker from "../global/imagePicker"
import { BodySliceType } from "../../services/config/enum"
import { TagsContext } from "../../controllers/tags"
import HtmlEditorGlobal from "../global/htmlEditorGlobal"

interface AutocompleteOption {
  id: string
  label: string
}

interface LocationAutocompleteOption {
  id: string
  name: string
  type: "Country" | "Region" | "SubRegion" | "DefaultLocation"
}

const CreateActionDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
  topBarText,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
  topBarText: string
}) => {
  const { setAnimation } = useContext(MainContext)
  const { languages, languagesForEpisodeTabs } = useContext(LanguagesContext)
  const {
    categoriesLoading,
    categoriesList,
    sdgsList,
    getSdgsList,
    sdgTargetsList,
    getSdgTargetsList,
    sdgsLoading,
    sdgTargetsLoading,
  } = useContext(TagsContext)
  const { regionsList, subRegionsList, countriesList } =
    useContext(CountriesContext)
  const { loading, setLoading, createAction, createActionDocument } =
    useContext(ActionsContext)
  const navigate = useNavigate()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // get topics, sdgs and sdg targets list
  useEffect(() => {
    if (dialogOpen && !sdgsList.length) {
      getSdgsList()
    }
    if (dialogOpen && !sdgTargetsList.length) {
      getSdgTargetsList()
    }
  }, [getSdgsList, getSdgTargetsList, dialogOpen])

  // configuration
  const [handle, setHandle] = useState<string>("")
  // const [isRepeatable, setIsRepeatable] = useState<boolean>(false)
  const [timesPerDay, setTimesPerDay] = useState<number | null>(1)
  const [category, setCategory] = useState<AutocompleteOption | null>(null)
  const [featured, setFeatured] = useState<boolean>(false)
  const [teamId, setTeamId] = useState<string>("team_default")

  // savings
  const [savingMetrics, setSavingMetrics] = useState<
    {
      action: number
      activity: number
      co2Saving: number
      energySaving: number
      waterSaving: number
      location: LocationAutocompleteOption
    }[]
  >([
    {
      action: 1,
      activity: 1,
      co2Saving: 0,
      energySaving: 0,
      waterSaving: 0,
      location: {
        id: "defaultLocation",
        name: "Default",
        type: "DefaultLocation",
      },
    },
  ])

  // image
  const [image, setImage] = useState<string>("")

  // sdgs
  const [primarySdg, setPrimarySdg] = useState<AutocompleteOption | null>(null)
  const [secondarySdgs, setSecondarySdgs] = useState<AutocompleteOption[]>([])
  const [secondarySdgsError, setSecondarySdgsError] = useState<boolean>(false)

  // sdg targets
  const [primarySdgTarget, setPrimarySdgTarget] =
    useState<AutocompleteOption | null>(null)
  const [secondarySdgTargets, setSecondarySdgTargets] = useState<
    AutocompleteOption[]
  >([])
  const [secondarySdgTargetsError, setSecondarySdgTargetsError] =
    useState<boolean>(false)

  // document
  const [defaultTranslation, setDefaultTranslation] = useState<string>("")
  const [title, setTitle] = useState<string>("")
  const [assumptions, setAssumptions] = useState<string>("")
  const [body, setBody] = useState<
    (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
  >([])

  // check errors in body
  const [bodyError, setBodyError] = useState<boolean>(false)

  useEffect(() => {
    let errorInBody = false
    body.forEach((bodyItem) => {
      if (
        (bodyItem.sliceType === BodySliceType.BodyTextSlice &&
          !stripHtml(bodyItem.text).length) ||
        (bodyItem.sliceType === BodySliceType.BodyQuoteSlice &&
          !bodyItem.quoteText.length) ||
        (bodyItem.sliceType === BodySliceType.BodyImageSlice &&
          (!bodyItem.imgUrl.length || !isValidURL(bodyItem.imgUrl))) ||
        (bodyItem.sliceType === BodySliceType.BodyEmbedSlice &&
          (!bodyItem.embedUrl.length || !testYtURL(bodyItem.embedUrl)))
      ) {
        errorInBody = true
      }
    })
    if (errorInBody) {
      setBodyError(true)
    } else {
      setBodyError(false)
    }
  }, [body])

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  // slides
  const slides = [
    {
      label: "Configuration",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Configuration
          </Typography>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              size="small"
              label="Handle"
              value={handle}
              onChange={(e) => {
                setHandle(e.target.value)
              }}
              onBlur={() => {
                if (handle) {
                  setHandle((current) => current.trim())
                }
              }}
            />
            <TextField
              fullWidth
              size="small"
              type="number"
              label="Times per day"
              value={timesPerDay ?? ""}
              onChange={(e) => {
                if (e.target.value.length) {
                  if (
                    e.target.value.includes(".") ||
                    e.target.value.includes(",")
                  ) {
                    setTimesPerDay(parseFloat(e.target.value))
                  } else {
                    setTimesPerDay(parseInt(e.target.value))
                  }
                } else {
                  setTimesPerDay(null)
                }
              }}
              error={
                timesPerDay === null ||
                (timesPerDay !== null && timesPerDay < 1) ||
                (timesPerDay !== null && timesPerDay.toString().includes("."))
              }
              helperText={
                timesPerDay === null ||
                (timesPerDay !== null && timesPerDay < 1) ||
                (timesPerDay !== null && timesPerDay.toString().includes("."))
                  ? "Invalid value"
                  : ""
              }
            />
            <FormControl fullWidth size="small">
              <InputLabel id="Featured">Featured</InputLabel>
              <Select
                labelId="Featured"
                label="Featured"
                value={featured ? "yes" : "no"}
                onChange={(e) => {
                  if (e.target.value === "yes") {
                    setFeatured(true)
                  } else {
                    setFeatured(false)
                  }
                }}
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Autocomplete
              loading={categoriesLoading}
              fullWidth
              blurOnSelect
              size="small"
              value={category}
              disablePortal
              isOptionEqualToValue={(
                option: AutocompleteOption,
                value: AutocompleteOption
              ) => option.id === value.id}
              options={categoriesList.map((item) => {
                return {
                  id: item.id,
                  label: item.name,
                }
              })}
              renderInput={(params) => (
                <TextField {...params} label="Category" />
              )}
              onChange={(e: any, newValue: AutocompleteOption | null) => {
                setCategory(newValue)
              }}
              renderOption={renderOption}
              renderTags={renderTags}
            />
            <FormControl fullWidth size="small">
              <InputLabel id="Type">Type</InputLabel>
              <Select
                labelId="Type"
                label="Type"
                value={teamId}
                onChange={(e) => {
                  setTeamId(e.target.value)
                }}
              >
                <MenuItem value="team_default">Standard</MenuItem>
                <MenuItem value="team_motion_tag">Autolog</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          {/* <FormControl fullWidth size="small">
            <InputLabel id="repeatable-select">Repeatable</InputLabel>
            <Select
              labelId="repeatable-select"
              label="Repeatable"
              value={isRepeatable ? "yes" : "no"}
              onChange={(e) => {
                if (e.target.value === "yes") {
                  setIsRepeatable(true)
                } else {
                  setIsRepeatable(false)
                }
              }}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl> */}
        </Stack>
      ),
    },
    {
      label: "Savings",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Savings
          </Typography>
          <Stack
            style={{
              height: 260,
              maxHeight: 260,
              overflowY: "scroll",
              paddingTop: 8,
              marginTop: 8,
            }}
            spacing={2}
          >
            {savingMetrics.map((item, index) => (
              <Stack direction="row" key={index} spacing={1.5}>
                <Divider orientation="vertical" flexItem />
                <Stack spacing={2} style={{ width: "calc(100% - 13px)" }}>
                  <div
                    style={{
                      height: 16,
                      position: "relative",
                      marginBottom: 3,
                    }}
                  >
                    <Typography
                      variant="overline"
                      style={{
                        height: 16,
                        lineHeight: "16px",
                      }}
                    >
                      {item.location.name}
                    </Typography>
                    {index !== 0 ? (
                      <Button
                        style={{
                          height: 24,
                          position: "absolute",
                          right: 0,
                          top: 1,
                        }}
                        onClick={() => {
                          savingMetrics.splice(index, 1)
                          setSavingMetrics([...savingMetrics])
                        }}
                      >
                        <DeleteRounded color="error" style={{ fontSize: 18 }} />
                      </Button>
                    ) : null}
                  </div>
                  <Stack direction="row" spacing={2}>
                    <Autocomplete
                      disabled={!index}
                      fullWidth
                      blurOnSelect
                      size="small"
                      value={item.location}
                      disablePortal
                      isOptionEqualToValue={(
                        option: LocationAutocompleteOption,
                        value: LocationAutocompleteOption
                      ) => option.id === value.id}
                      groupBy={(option: LocationAutocompleteOption) => {
                        if (option.type !== "DefaultLocation") {
                          return option.type
                        }
                      }}
                      options={[
                        {
                          id: "defaultLocation",
                          name: "Default",
                          type: "DefaultLocation",
                        },
                        ...regionsList.map((item) => {
                          return {
                            id: item.id,
                            name: item.name,
                            type: "Region",
                          }
                        }),
                        ...subRegionsList.map((item) => {
                          return {
                            id: item.id,
                            name: item.name,
                            type: "SubRegion",
                          }
                        }),
                        ...countriesList.map((item) => {
                          return {
                            id: item.id,
                            name: item.name,
                            type: "Country",
                          }
                        }),
                      ]}
                      getOptionLabel={(option: LocationAutocompleteOption) =>
                        option.name
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Location"
                          error={
                            savingMetrics.filter(
                              (item2) => item2.location.id === item.location.id
                            ).length > 1
                          }
                          helperText={
                            savingMetrics.filter(
                              (item2) => item2.location.id === item.location.id
                            ).length > 1
                              ? "There cannot be multiple savings for the same location"
                              : ""
                          }
                          FormHelperTextProps={{
                            style: {
                              margin: 0,
                              marginBottom: -10,
                            },
                          }}
                        />
                      )}
                      onChange={(
                        e: any,
                        newValue: LocationAutocompleteOption | null
                      ) => {
                        if (newValue) {
                          savingMetrics[index].location = newValue
                        } else {
                          savingMetrics[index].location = {
                            id: "defaultLocation",
                            name: "Default",
                            type: "DefaultLocation",
                          }
                        }
                        setSavingMetrics([...savingMetrics])
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )
                      }}
                    />
                    {/* <TextField
                      fullWidth
                      size="small"
                      type="number"
                      label="Actions"
                      value={item.action ?? ""}
                      onChange={(e) => {
                        if (e.target.value.length) {
                          if (
                            e.target.value.includes(".") ||
                            e.target.value.includes(",")
                          ) {
                            savingMetrics[index].action = parseFloat(
                              e.target.value
                            )
                            setSavingMetrics([...savingMetrics])
                          } else {
                            savingMetrics[index].action = parseInt(
                              e.target.value
                            )
                            setSavingMetrics([...savingMetrics])
                          }
                        } else {
                          savingMetrics[index].action = null
                          setSavingMetrics([...savingMetrics])
                        }
                      }}
                      error={
                        item.action === null ||
                        (item.action !== null && item.action < 0) ||
                        (item.action !== null &&
                          item.action.toString().includes("."))
                      }
                      helperText={
                        item.action === null ||
                        (item.action !== null && item.action < 0) ||
                        (item.action !== null &&
                          item.action.toString().includes("."))
                          ? "Invalid value"
                          : ""
                      }
                    /> */}
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      fullWidth
                      size="small"
                      type="number"
                      label="Co2 saving"
                      value={item.co2Saving ?? ""}
                      onChange={(e) => {
                        if (e.target.value.length) {
                          if (
                            e.target.value.includes(".") ||
                            e.target.value.includes(",")
                          ) {
                            savingMetrics[index].co2Saving = parseFloat(
                              e.target.value
                            )
                            setSavingMetrics([...savingMetrics])
                          } else {
                            savingMetrics[index].co2Saving = parseInt(
                              e.target.value
                            )
                            setSavingMetrics([...savingMetrics])
                          }
                        } else {
                          savingMetrics[index].co2Saving = null
                          setSavingMetrics([...savingMetrics])
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ marginLeft: 23 }}
                          >
                            kg
                          </InputAdornment>
                        ),
                      }}
                      error={
                        item.co2Saving === null ||
                        (item.co2Saving !== null && item.co2Saving < 0)
                      }
                      helperText={
                        item.co2Saving === null ||
                        (item.co2Saving !== null && item.co2Saving < 0)
                          ? "Invalid value"
                          : ""
                      }
                    />
                    <TextField
                      fullWidth
                      size="small"
                      type="number"
                      label="Energy saving"
                      value={item.energySaving ?? ""}
                      onChange={(e) => {
                        if (e.target.value.length) {
                          if (
                            e.target.value.includes(".") ||
                            e.target.value.includes(",")
                          ) {
                            savingMetrics[index].energySaving = parseFloat(
                              e.target.value
                            )
                            setSavingMetrics([...savingMetrics])
                          } else {
                            savingMetrics[index].energySaving = parseInt(
                              e.target.value
                            )
                            setSavingMetrics([...savingMetrics])
                          }
                        } else {
                          savingMetrics[index].energySaving = null
                          setSavingMetrics([...savingMetrics])
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">kWh</InputAdornment>
                        ),
                      }}
                      error={
                        item.energySaving === null ||
                        (item.energySaving !== null && item.energySaving < 0)
                      }
                      helperText={
                        item.energySaving === null ||
                        (item.energySaving !== null && item.energySaving < 0)
                          ? "Invalid value"
                          : ""
                      }
                    />
                    <TextField
                      fullWidth
                      size="small"
                      type="number"
                      label="Water saving"
                      value={item.waterSaving ?? ""}
                      onChange={(e) => {
                        if (e.target.value.length) {
                          if (
                            e.target.value.includes(".") ||
                            e.target.value.includes(",")
                          ) {
                            savingMetrics[index].waterSaving = parseFloat(
                              e.target.value
                            )
                            setSavingMetrics([...savingMetrics])
                          } else {
                            savingMetrics[index].waterSaving = parseInt(
                              e.target.value
                            )
                            setSavingMetrics([...savingMetrics])
                          }
                        } else {
                          savingMetrics[index].waterSaving = null
                          setSavingMetrics([...savingMetrics])
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ marginLeft: 35 }}
                          >
                            l
                          </InputAdornment>
                        ),
                      }}
                      error={
                        item.waterSaving === null ||
                        (item.waterSaving !== null && item.waterSaving < 0)
                      }
                      helperText={
                        item.waterSaving === null ||
                        (item.waterSaving !== null && item.waterSaving < 0)
                          ? "Invalid value"
                          : ""
                      }
                    />
                  </Stack>
                </Stack>
              </Stack>
            ))}
            <Button
              variant="outlined"
              startIcon={<AddCircleRounded />}
              onClick={() => {
                savingMetrics.push({
                  action: 1,
                  activity: 1,
                  co2Saving: 0,
                  energySaving: 0,
                  waterSaving: 0,
                  location: {
                    id: "defaultLocation",
                    name: "Default",
                    type: "DefaultLocation",
                  },
                })
                setSavingMetrics([...savingMetrics])
              }}
            >
              Add savings
            </Button>
          </Stack>
        </Stack>
      ),
    },
    {
      label: "Image",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Image
          </Typography>
          <ImagePicker
            title="Image"
            pickedImage={image}
            setPickedImage={(selectedImage: {
              url: string
              id: string
              description?: string
            }) => {
              setImage(selectedImage.url)
            }}
            filters={{ category: "action" }}
            disableCategoryFilter
            height={200}
          />
        </Stack>
      ),
    },
    {
      label: "Tags",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Topics, SDGs and SDG targets
          </Typography>
          <Stack direction="row" spacing={2}>
            <Autocomplete
              fullWidth
              loading={sdgsLoading}
              getOptionDisabled={(option: AutocompleteOption) => {
                if (
                  secondarySdgs.filter(
                    (sdg: AutocompleteOption) => sdg.id === option.id
                  )[0]
                ) {
                  return true
                } else {
                  return false
                }
              }}
              size="small"
              value={primarySdg}
              disablePortal
              isOptionEqualToValue={(
                option: AutocompleteOption,
                value: AutocompleteOption
              ) => option.id === value.id}
              options={
                sdgsList.length
                  ? sdgsList.map((sdg: Sdg) => {
                      let idForLabel =
                        sdg.id.charAt(4) === "0"
                          ? sdg.id.slice(5) + ". "
                          : sdg.id.slice(4) + ". "

                      return { label: idForLabel + sdg.name, id: sdg.id }
                    })
                  : []
              }
              renderInput={(params) => (
                <TextField {...params} label="Primary SDG" />
              )}
              onChange={(e: any, newValue: AutocompleteOption | null) => {
                setPrimarySdg(newValue)
              }}
              renderOption={renderOption}
              renderTags={renderTags}
            />
            <Autocomplete
              fullWidth
              loading={sdgsLoading}
              getOptionDisabled={(option: AutocompleteOption) =>
                option.id === primarySdg?.id
              }
              onBlur={() => {
                setSecondarySdgsError(false)
              }}
              multiple
              size="small"
              value={secondarySdgs}
              disablePortal
              isOptionEqualToValue={(
                option: AutocompleteOption,
                value: AutocompleteOption
              ) => option.id === value.id}
              options={
                sdgsList.length
                  ? sdgsList.map((sdg: Sdg) => {
                      let idForLabel =
                        sdg.id.charAt(4) === "0"
                          ? sdg.id.slice(5) + ". "
                          : sdg.id.slice(4) + ". "

                      return { label: idForLabel + sdg.name, id: sdg.id }
                    })
                  : []
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Secondary SDGs"
                  error={secondarySdgsError}
                  helperText={
                    secondarySdgsError
                      ? "The maximum number of secondary SDGs is 3"
                      : null
                  }
                />
              )}
              onChange={(e: any, newValues: AutocompleteOption[]) => {
                if (newValues.length < 4) {
                  setSecondarySdgs(newValues)
                  setSecondarySdgsError(false)
                } else {
                  setSecondarySdgsError(true)
                }
              }}
              renderOption={renderOption}
              renderTags={renderTags}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <Autocomplete
              fullWidth
              loading={sdgTargetsLoading}
              getOptionDisabled={(option: AutocompleteOption) => {
                if (
                  secondarySdgTargets.filter(
                    (sdgTarget: AutocompleteOption) =>
                      sdgTarget.id === option.id
                  )[0]
                ) {
                  return true
                } else {
                  return false
                }
              }}
              size="small"
              value={primarySdgTarget}
              disablePortal
              isOptionEqualToValue={(
                option: AutocompleteOption,
                value: AutocompleteOption
              ) => option.id === value.id}
              options={
                sdgTargetsList.length
                  ? sdgTargetsList.map((sdgTarget: SdgTarget) => {
                      return { label: sdgTarget.id.slice(4), id: sdgTarget.id }
                    })
                  : []
              }
              renderInput={(params) => (
                <TextField {...params} label="Primary SDG target" />
              )}
              onChange={(e: any, newValue: AutocompleteOption | null) => {
                setPrimarySdgTarget(newValue)
              }}
              renderOption={renderOption}
              renderTags={renderTags}
            />
            <Autocomplete
              fullWidth
              loading={sdgTargetsLoading}
              getOptionDisabled={(option: AutocompleteOption) =>
                option.id === primarySdgTarget?.id
              }
              onBlur={() => {
                setSecondarySdgTargetsError(false)
              }}
              multiple
              size="small"
              value={secondarySdgTargets}
              disablePortal
              isOptionEqualToValue={(
                option: AutocompleteOption,
                value: AutocompleteOption
              ) => option.id === value.id}
              options={
                sdgTargetsList.length
                  ? sdgTargetsList.map((sdgTarget: SdgTarget) => {
                      return { label: sdgTarget.id.slice(4), id: sdgTarget.id }
                    })
                  : []
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Secondary SDG targets"
                  error={secondarySdgTargetsError}
                  helperText={
                    secondarySdgTargetsError
                      ? "The maximum number of secondary SDG targets is 3"
                      : null
                  }
                />
              )}
              onChange={(e: any, newValues: AutocompleteOption[]) => {
                if (newValues.length < 4) {
                  setSecondarySdgTargets(newValues)
                  setSecondarySdgTargetsError(false)
                } else {
                  setSecondarySdgTargetsError(true)
                }
              }}
              renderOption={renderOption}
              renderTags={renderTags}
            />
          </Stack>
        </Stack>
      ),
    },
    {
      label: "Language",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Default language
          </Typography>
          <FormControl fullWidth size="small">
            <InputLabel id="language-select">Default language</InputLabel>
            <Select
              labelId="language-select"
              label="Default language"
              value={defaultTranslation}
              onChange={(e) => {
                setDefaultTranslation(e.target.value)
              }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {lang.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      ),
    },
    {
      label: "Details",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Details in{" "}
            {defaultTranslation.length
              ? languagesForEpisodeTabs.filter(
                  (item) => item.value === defaultTranslation
                )[0].label
              : ""}
          </Typography>
          <TextField
            size="small"
            label="Title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
            onBlur={() => {
              if (title) {
                setTitle((current) => current.trim())
              }
            }}
          />
          <HtmlEditorGlobal
            text={assumptions}
            setText={setAssumptions}
            label="Assumptions"
            options={["inline", "link", "history"]}
            inlineOptions={["bold", "italic", "superscript", "subscript"]}
          />
        </Stack>
      ),
    },
    {
      label: "Body",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Body in{" "}
            {defaultTranslation.length
              ? languagesForEpisodeTabs.filter(
                  (item) => item.value === defaultTranslation
                )[0].label
              : ""}
          </Typography>
          <div
            style={{
              height: 260,
              maxHeight: 260,
              overflowY: "scroll",
            }}
          >
            <BlockEditor
              body={body}
              setBody={(
                body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
              ) => {
                setBody([...body])
              }}
            />
          </div>
        </Stack>
      ),
    },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setHandle("")
        // setIsRepeatable(false)
        setTimesPerDay(1)
        setCategory(null)
        setFeatured(false)
        setTeamId("team_default")
        setSavingMetrics([
          {
            action: 1,
            activity: 1,
            co2Saving: 0,
            energySaving: 0,
            waterSaving: 0,
            location: {
              id: "defaultLocation",
              name: "Default",
              type: "DefaultLocation",
            },
          },
        ])
        setPrimarySdg(null)
        setSecondarySdgs([])
        setSecondarySdgsError(false)
        setPrimarySdgTarget(null)
        setSecondarySdgTargets([])
        setSecondarySdgTargetsError(false)
        setDefaultTranslation("")
        setTitle("")
        setAssumptions("")
        setBody([])
        setCurrentSlide(0)
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (handle || category) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [handle, category])

  // create action
  const createNewAction = async () => {
    setLoading(true)

    const input: {
      handle: string
      isRepeatable: boolean
      image: string
      category: {
        id: string
      }
      savingMetrics: {
        metrics: {
          action: number
          activity: number
          co2Saving: number
          energySaving: number
          waterSaving: number
        }
        location: {
          id: string
          type: "Country" | "Region" | "SubRegion" | "DefaultLocation"
        }
      }[]
      sdgs: { primary: boolean; id: string }[]
      sdgTargets: { primary: boolean; id: string }[]
      timesPerDay: number
      featured: boolean
      teamId: string
    } = {
      handle: handle,
      isRepeatable: true,
      image: image,
      category: {
        id: category.id,
      },
      savingMetrics: savingMetrics.map((item) => {
        return {
          metrics: {
            action: item.action,
            activity: item.activity,
            co2Saving: item.co2Saving,
            energySaving: item.energySaving,
            waterSaving: item.waterSaving,
          },
          location: {
            id: item.location.id,
            type: item.location.type,
          },
        }
      }),
      timesPerDay: timesPerDay,
      sdgs: [
        ...secondarySdgs.map((sdg) => {
          return { id: sdg.id, primary: false }
        }),
      ],
      sdgTargets: [
        ...secondarySdgTargets.map((sdgTarget) => {
          return { id: sdgTarget.id, primary: false }
        }),
      ],
      featured: featured,
      teamId: teamId,
    }

    if (primarySdg) {
      input.sdgs.unshift({ id: primarySdg.id, primary: true })
    }
    if (primarySdgTarget) {
      input.sdgTargets.unshift({ id: primarySdgTarget.id, primary: true })
    }

    const result = await createAction(input)

    if (result !== false) {
      const newAction = result as Action

      let bodyToSet = []
      const newBody = []
      body.forEach((bodyItem: any) => {
        const { sliceType, ...rest } = bodyItem
        newBody.push({
          [lowercaseFirstCharacter(bodyItem.sliceType)]: {
            ...rest,
          },
        })
      })
      bodyToSet = newBody

      const documentInput: {
        parentId: string
        type: string
        actionDocumentItems: {
          body: object[]
          default: boolean
          lang: string
          title: string
          assumptions?: string
        }[]
      } = {
        parentId: newAction.document.parentId,
        type: "Action",
        actionDocumentItems: [
          {
            body: bodyToSet,
            default: true,
            lang: defaultTranslation,
            title: title,
          },
        ],
      }

      if (assumptions && assumptions !== "<p></p>") {
        documentInput.actionDocumentItems[0].assumptions = assumptions
      }

      await createActionDocument(documentInput)

      setDialogOpen(false)
      setAnimation(false)
      setTimeout(() => {
        navigate(`/actions/${newAction.id}`)
      }, 250)
    } else {
      setLoading(false)
    }
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText={topBarText}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            {topBarText}
          </Typography>
          <Stepper
            activeStep={currentSlide}
            style={{
              paddingTop: 24,
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 8,
              maxWidth: "100%",
              overflow: "overlay",
            }}
          >
            {slides.map((slide) => {
              return (
                <Step key={slide.label}>
                  <StepLabel>{slide.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (!slides[currentSlide - 1]) {
                    if (showDiscardDialog) {
                      setDiscardDialogOpen(true)
                    } else {
                      handleDialogChange(e)
                    }
                  } else {
                    setCurrentSlide(currentSlide - 1)
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  (currentSlide === 0 && !handle.length) ||
                  (currentSlide === 0 && timesPerDay === null) ||
                  (currentSlide === 0 && !category) ||
                  (currentSlide === 0 &&
                    timesPerDay !== null &&
                    timesPerDay < 1) ||
                  (currentSlide === 0 &&
                    timesPerDay !== null &&
                    timesPerDay.toString().includes(".")) ||
                  (currentSlide === 1 &&
                    savingMetrics.filter((item) => item.action === null)
                      .length !== 0) ||
                  (currentSlide === 1 &&
                    savingMetrics.filter((item) => item.action === null)
                      .length === 0 &&
                    savingMetrics.filter((item) => item.action < 0).length !==
                      0) ||
                  (currentSlide === 1 &&
                    savingMetrics.filter((item) => item.action === null)
                      .length === 0 &&
                    savingMetrics.filter((item) =>
                      item.action.toString().includes(".")
                    ).length !== 0) ||
                  (currentSlide === 1 &&
                    savingMetrics.filter((item) => item.co2Saving === null)
                      .length !== 0) ||
                  (currentSlide === 1 &&
                    savingMetrics.filter((item) => item.co2Saving === null)
                      .length === 0 &&
                    savingMetrics.filter((item) => item.co2Saving < 0)
                      .length !== 0) ||
                  (currentSlide === 1 &&
                    savingMetrics.filter((item) => item.energySaving === null)
                      .length !== 0) ||
                  (currentSlide === 1 &&
                    savingMetrics.filter((item) => item.energySaving === null)
                      .length === 0 &&
                    savingMetrics.filter((item) => item.energySaving < 0)
                      .length !== 0) ||
                  (currentSlide === 1 &&
                    savingMetrics.filter((item) => item.waterSaving === null)
                      .length !== 0) ||
                  (currentSlide === 1 &&
                    savingMetrics.filter((item) => item.waterSaving === null)
                      .length === 0 &&
                    savingMetrics.filter((item) => item.waterSaving < 0)
                      .length !== 0) ||
                  (currentSlide === 1 &&
                    savingMetrics.filter((item, index) => {
                      if (
                        savingMetrics.findIndex(
                          (item2) => item2.location.id === item.location.id
                        ) === index
                      ) {
                        return item
                      }
                    }).length !== savingMetrics.length) ||
                  (currentSlide === 2 && !image) ||
                  (currentSlide === 3 && secondarySdgsError) ||
                  (currentSlide === 3 && secondarySdgTargetsError) ||
                  (currentSlide === 4 && !defaultTranslation.length) ||
                  (currentSlide === 5 && !title.length) ||
                  (currentSlide === 6 && bodyError)
                }
                onMouseDown={
                  !slides[currentSlide + 1]
                    ? () => {
                        createNewAction()
                      }
                    : () => {
                        setCurrentSlide(currentSlide + 1)
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Save" : "Next"}
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={loading} />
    </Dialog>
  )
}

export default CreateActionDialog
