import {
  Chip,
  LinearProgress,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"
import ListContainer from "../../components/global/listContainer"
import ListTable from "../../components/global/listTable"
import TableSpinner from "../../components/global/tableSpinner"
import { MainContext } from "../../controllers/main"
import {
  calculateTimeElapsed,
  capitalizeFirstCharacter,
  registerAnalyticsEvent,
} from "../../services/utilities/utility"
import TitleTableCell from "../../components/global/titleTableCell"
import NftCatalogsListTopBar from "../../components/nft/nftCatalogsListTopBar"
import { NftsContext } from "../../controllers/nfts"
import NftCatalog from "../../models/nftCatalog"
import { NftCatalogStatus } from "../../services/config/enum"
import CreateNftCatalogDialog from "../../components/nft/createNftCatalogDialog"

const NftCatalogsList = ({
  forDialog = false,
  selectedNftCatalog,
  setSelectedNftCatalog,
}: {
  forDialog?: boolean
  selectedNftCatalog?: NftCatalog | null
  setSelectedNftCatalog?: Dispatch<SetStateAction<NftCatalog | null>>
}) => {
  const { setAnimation, showPadding, cmdPressed } = useContext(MainContext)
  const {
    loading,
    nftCatalogsList,
    nftCatalogsListNextToken,
    getNftCatalogsList,
    loadMoreNftCatalogs,
    updatingList,
    setUpdatingList,
  } = useContext(NftsContext)
  const navigate = useNavigate()

  // list background update
  const backgroundUpdate = () => {
    if (!nftCatalogsList.length) {
      getNftCatalogsList()
    } else if (!loading) {
      setUpdatingList(true)
      getNftCatalogsList(false)
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    backgroundUpdate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create action dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // load more button loading
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // register analytics event
  useEffect(() => {
    registerAnalyticsEvent({ type: "page_view", name: "nft_catalogs_list" })
  }, [])

  return (
    <ListContainer style={{ position: "relative" }}>
      {updatingList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 88,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <NftCatalogsListTopBar
        forDialog={forDialog}
        loading={loading}
        textFieldValue=""
        textFieldPlaceholder="Search NFT Catalogs"
        addButtonLabel="Add NFT Catalog"
        addButtonOnClick={handleDialogChange}
      />
      {loading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 150px)"
              : "calc(100vh - 118px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 150px)"
              : "calc(100vh - 118px)"
          }
          headingItems={["Image", "Title & ID", "Status", "Mint", "Updated"]}
          nextToken={nftCatalogsListNextToken}
          loadingMoreItems={loadMoreLoading}
          setLoadingMoreItems={setLoadMoreLoading}
          loadMore={loadMoreNftCatalogs}
          tableBody={nftCatalogsList.map((item: NftCatalog) => (
            <TableRow
              key={item.id}
              hover
              style={{
                cursor: "pointer",
                backgroundColor:
                  selectedNftCatalog && selectedNftCatalog.id === item.id
                    ? "lightgray"
                    : null,
              }}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
              onClick={() => {
                if (!forDialog) {
                  if (cmdPressed) {
                    window.open(`${window.location.href}/${item.id}`)
                  } else {
                    setAnimation(false)
                    setTimeout(() => {
                      navigate(`/nftcatalogs/${item.id}`)
                    }, 250)
                  }
                } else {
                  if (selectedNftCatalog && selectedNftCatalog.id === item.id) {
                    setSelectedNftCatalog(null)
                  } else {
                    setSelectedNftCatalog(item)
                  }
                }
              }}
            >
              <TableCell>
                <div
                  style={{
                    width: 42,
                    height: 42,
                    borderRadius: 4,
                    backgroundImage: `url(${item.image ?? noImagePlaceholder})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </TableCell>
              <TitleTableCell title={item.name} id={item.id} />
              <TableCell style={{ minWidth: 120 }}>
                <Chip
                  label={capitalizeFirstCharacter(item.status.toLowerCase())}
                  size="small"
                  color={
                    item.status === NftCatalogStatus.IDLE
                      ? "default"
                      : item.status === NftCatalogStatus.PENDING
                      ? "warning"
                      : "success"
                  }
                />
              </TableCell>
              <TableCell style={{ minWidth: 120 }}>
                <Typography variant="caption">
                  {calculateTimeElapsed(item.mintAt)}
                </Typography>
              </TableCell>
              <TableCell style={{ minWidth: 120 }}>
                <Typography variant="caption">
                  {calculateTimeElapsed(item.updatedAt)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        />
      )}
      {!forDialog && (
        <CreateNftCatalogDialog
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          handleDialogChange={handleDialogChange}
        />
      )}
    </ListContainer>
  )
}

export default NftCatalogsList
