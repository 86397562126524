import { useLazyQuery } from "@apollo/client"
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import Metric from "../models/metric"
import { listMetrics } from "../services/graphql/queries"
import { logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"

interface MetricsContextInterface {
  metricsLoading: boolean
  metricsList: Metric[]
}

const MetricsContext = createContext<MetricsContextInterface>({
  metricsLoading: true,
  metricsList: [],
})

const MetricsController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // loadings
  const [metricsLoading, setMetricsLoading] = useState<boolean>(true) // loading for metrics

  // states
  const [metricsList, setMetricsList] = useState<Metric[]>([]) // all metrics list

  // constants
  const newMetricsIds = useMemo(
    () => ["routine", "story", "quiz", "mobility", "news"],
    []
  )

  // queries
  const [listMetricsQuery] = useLazyQuery(listMetrics)

  // get metrics list
  const getMetricsList = useCallback(async () => {
    panelStatus.filter((item) => item.name === "Metrics")[0].loading = true
    setPanelStatus([...panelStatus])

    try {
      logger(Status.Api, "QUERY listMetrics", listMetrics)
      const { data } = await listMetricsQuery({
        fetchPolicy: "no-cache",
      })

      // parse data
      const listToSet: Metric[] = data.listMetrics
      listToSet.forEach((metric) => {
        if (metric.id === "activity" || metric.id === "action") {
          metric.versionType = "both"
        } else if (newMetricsIds.includes(metric.id)) {
          metric.versionType = "new"
        } else {
          metric.versionType = "old"
        }
      })
      logger(Status.Info, "metrics list", data.listMetrics)

      setMetricsList(data.listMetrics)

      setMetricsLoading(false)
      panelStatus.filter((item) => item.name === "Metrics")[0].status =
        "success"
      panelStatus.filter((item) => item.name === "Metrics")[0].loading = false
      setPanelStatus([...panelStatus])
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `listMetrics`, e.message)
      }
      setMetricsLoading(false)
      panelStatus.filter((item) => item.name === "Metrics")[0].status = "error"
      panelStatus.filter((item) => item.name === "Metrics")[0].loading = false
      setPanelStatus([...panelStatus])
    }
  }, [setError, setErrorMessage])

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter((item) => item.name === "Metrics")[0].updateFunction =
      getMetricsList
  }, [])

  // initial fetch
  useEffect(() => {
    getMetricsList()
  }, [])

  return (
    <MetricsContext.Provider
      value={{
        metricsLoading,
        metricsList,
      }}
    >
      {children}
    </MetricsContext.Provider>
  )
}

export { MetricsController, MetricsContext }
