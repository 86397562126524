import { CloseRounded, HistoryRounded, UndoRounded } from "@mui/icons-material"
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { useParams } from "react-router-dom"
import { EpisodesContext } from "../../controllers/episodes"
import { LanguagesContext } from "../../controllers/languages"
import { MainContext } from "../../controllers/main"
import Sdg from "../../models/sdg"
import SdgTarget from "../../models/sdgTarget"
import Topic from "../../models/topic"
import { EpisodeSpecialType, Esg } from "../../services/config/enum"
import {
  calculateTimeElapsed,
  capitalizeFirstCharacter,
  copyTextToClipboard,
  deepCopy,
  enumAsArray,
  renderOption,
  renderTags,
} from "../../services/utilities/utility"
import CantPerformActionDialog from "../global/cantPerformActionDialog"
import ImagePicker from "../global/imagePicker"
import { TagsContext } from "../../controllers/tags"
import ClearableSelect from "../global/clearableSelect"
import DataCard from "../global/dataCard"
import { AutocompleteOption } from "../../services/config/interfaces"
import ISODatePicker from "../global/isoDatePicker"

const EpisodeGeneralEdit = ({
  saveButtonDisabled,
  setStageToSee,
  setParentStageDialogOpen,
  sidebarOpen,
  setSidebarOpen,
  fetchAll,
  saveChanges,
  currentPreviewLanguage,
  setCurrentPreviewLanguage,
  setGlobalPreviewOpen,
  setInfoDialogOpen,
  setFeedbackOpen,
}: {
  saveButtonDisabled: boolean
  setStageToSee: Dispatch<SetStateAction<string>>
  setParentStageDialogOpen: Dispatch<SetStateAction<boolean>>
  sidebarOpen: boolean
  setSidebarOpen: Dispatch<SetStateAction<boolean>>
  fetchAll: () => void
  saveChanges: () => void
  currentPreviewLanguage: string
  setCurrentPreviewLanguage: Dispatch<SetStateAction<string>>
  setGlobalPreviewOpen: Dispatch<SetStateAction<boolean>>
  setInfoDialogOpen: Dispatch<SetStateAction<boolean>>
  setFeedbackOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { showPadding, showAdvancedOption } = useContext(MainContext)
  const { languages } = useContext(LanguagesContext)
  const {
    doneChanges,
    currentEpisode,
    cancelChanges,
    currentEpisodeStaged,
    changeParentStage,
    currentEpisodeLive,
    setCurrentEpisode,
    setLoading,
    publishEpisodeParent,
    publishTranslation,
    editMode,
  } = useContext(EpisodesContext)
  const {
    categoriesLoading,
    categoriesList,
    topicsList,
    sdgsList,
    sdgTargetsList,
  } = useContext(TagsContext)
  const { episodeId } = useParams()

  // secondary topics error if trying to add more than 3 or less than 1
  const [secondaryTopicsError, setSecondaryTopicsError] =
    useState<boolean>(false)

  // sdgs error if trying to add more than 3 or less than 1
  const [secondarySdgsError, setSecondarySdgsError] = useState<boolean>(false)

  // undo changes dialog
  const [undoDialogOpen, setUndoDialogOpen] = useState<boolean>(false)

  // no thumnail alert dialog
  const [noThumbnailDialogOpen, setNoThumbnailDialogOpen] =
    useState<boolean>(false)

  // no slides alert dialog
  const [noSlidesDialogOpen, setNoSlidesDialogOpen] = useState<boolean>(false)

  // double publish dialog
  const [doublePublishDialogOpen, setdoublePublishDialogOpen] =
    useState<boolean>(false)

  return (
    <Stack spacing={0}>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: 36,
          backgroundColor: saveButtonDisabled
            ? "#d3302f"
            : doneChanges
            ? "#6cb3e6"
            : currentEpisode.stage === "PUBLISHED"
            ? "#3bb97a"
            : "#f7b043",
          color: "white",
          paddingLeft: 15,
          transition: "150ms",
          paddingTop: 5,
        }}
      >
        <Typography variant="overline" style={{ fontWeight: 500 }}>
          {doneChanges
            ? "Edited"
            : currentEpisode.stage === "PUBLISHED"
            ? "Live"
            : "Draft"}
        </Typography>
        {saveButtonDisabled && (
          <Typography variant="overline" style={{ fontWeight: 500 }}>
            {" - errors"}
          </Typography>
        )}
        <Button
          style={{
            height: 30,
            position: "absolute",
            top: 3,
            right: 144,
            transition: "150ms",
            opacity: doneChanges ? 1 : 0,
          }}
          disabled={!doneChanges}
          onClick={() => {
            setUndoDialogOpen(true)
          }}
        >
          <UndoRounded
            style={{
              color: "white",
              opacity: !doneChanges ? 0.3 : 1,
              transition: "150ms",
            }}
          />
        </Button>
        <Button
          size="small"
          style={{
            position: "absolute",
            color: "white",
            opacity:
              (currentEpisode.stage === "PUBLISHED" && !doneChanges) ||
              saveButtonDisabled ||
              !editMode
                ? 0.3
                : 1,
            top: 3,
            height: 30,
            right: 64,
            width: 80,
            transition: "150ms",
          }}
          disabled={
            (currentEpisode.stage === "PUBLISHED" && !doneChanges) ||
            saveButtonDisabled ||
            !editMode
          }
          onClick={async () => {
            if (doneChanges) {
              saveChanges()
              return
            }

            if (!currentEpisode.image) {
              setNoThumbnailDialogOpen(true)
              return
            }

            if (
              !currentEpisodeStaged.translations.filter(
                (item) => item.lang === currentEpisode.lang
              )[0].slides.length ||
              !currentEpisodeStaged.translations.filter(
                (item) => item.lang === currentEpisode.lang
              )[0].quiz.length
            ) {
              setNoSlidesDialogOpen(true)
              return
            }

            if (currentEpisode.stage === "DRAFT" && !doneChanges) {
              if (
                currentEpisodeStaged.translations.filter(
                  (item) => item.lang === currentEpisode.lang
                )[0].stage === "DRAFT"
              ) {
                setdoublePublishDialogOpen(true)
                return
              }

              setLoading(true)
              const noErrors = await publishEpisodeParent(episodeId)
              if (noErrors) {
                fetchAll()
              } else {
                setLoading(false)
              }
            }
          }}
        >
          {currentEpisode.stage === "DRAFT" && !doneChanges
            ? "Publish"
            : doneChanges
            ? "Save"
            : "Save"}
        </Button>
        <Button
          style={{
            height: 30,
            position: "absolute",
            top: 3,
            right: 0,
          }}
          onClick={() => {
            setSidebarOpen(!sidebarOpen)
          }}
        >
          {sidebarOpen ? (
            <CloseRounded
              style={{
                color: "white",
              }}
            />
          ) : (
            <HistoryRounded
              style={{
                color: "white",
              }}
            />
          )}
        </Button>
      </div>
      {/* parent episode edit */}
      <div
        style={{
          height:
            showPadding === "yes"
              ? "calc(100vh - 235px)"
              : "calc(100vh - 219px)",
          position: "relative",
        }}
      >
        <Card
          style={{
            position: "absolute",
            top: 0,
            right: sidebarOpen ? 0 : -245,
            height: "100%",
            width: 240,
            zIndex: 1,
            transition: "200ms",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeft: "1px solid #e0e0e0",
          }}
        >
          <CardContent>
            <Stack spacing={2}>
              {doneChanges && (
                <div
                  style={{
                    minHeight: 50,
                    backgroundColor: "#f9f9fb",
                    display: "flex",
                    alignItems: "center",
                    borderLeft: saveButtonDisabled
                      ? "4px solid " + "#d3302f"
                      : "4px solid #6cb3e6",
                    paddingLeft: 15,
                    paddingRight: 15,
                    borderRadius: "0 3px 3px 0",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography variant="overline" style={{ lineHeight: 1 }}>
                      {saveButtonDisabled ? "Edited - errors" : "Edited"}
                    </Typography>
                    <Typography variant="caption" style={{ lineHeight: 1 }}>
                      unsaved changes
                    </Typography>
                  </Stack>
                </div>
              )}
              {currentEpisodeStaged.stage === "DRAFT" && (
                <div
                  style={{
                    minHeight: 50,
                    backgroundColor:
                      currentEpisode.stage === "DRAFT" && !doneChanges
                        ? "#f9f9fb"
                        : "white",
                    display: "flex",
                    alignItems: "center",
                    borderLeft: "4px solid " + "#f7b043",
                    paddingLeft: 15,
                    paddingRight: 15,
                    borderRadius: "0 3px 3px 0",
                    boxShadow:
                      currentEpisode.stage === "PUBLISHED" || doneChanges
                        ? "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)"
                        : null,
                    cursor:
                      currentEpisode.stage === "PUBLISHED" || doneChanges
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() => {
                    if (doneChanges) {
                      setStageToSee("DRAFT")
                      setParentStageDialogOpen(true)
                    } else {
                      changeParentStage("DRAFT")
                    }
                  }}
                >
                  <Stack spacing={1}>
                    <Typography variant="overline" style={{ lineHeight: 1 }}>
                      Draft
                    </Typography>
                    <Typography variant="caption" style={{ lineHeight: 1 }}>
                      updated{" "}
                      {calculateTimeElapsed(currentEpisodeStaged.updatedAt)}
                    </Typography>
                  </Stack>
                </div>
              )}
              {currentEpisodeLive &&
              currentEpisodeLive.stage === "PUBLISHED" ? (
                <div
                  style={{
                    minHeight: 50,
                    backgroundColor:
                      currentEpisode.stage === "PUBLISHED" && !doneChanges
                        ? "#f9f9fb"
                        : "white",
                    display: "flex",
                    alignItems: "center",
                    borderLeft: "4px solid " + "#3bb97a",
                    paddingLeft: 15,
                    paddingRight: 15,
                    borderRadius: "0 3px 3px 0",
                    boxShadow:
                      currentEpisode.stage === "DRAFT" || doneChanges
                        ? "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)"
                        : null,
                    cursor:
                      currentEpisode.stage === "DRAFT" || doneChanges
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() => {
                    if (doneChanges) {
                      setStageToSee("PUBLISHED")
                      setParentStageDialogOpen(true)
                    } else {
                      changeParentStage("PUBLISHED")
                    }
                  }}
                >
                  <Stack spacing={1}>
                    <Typography variant="overline" style={{ lineHeight: 1 }}>
                      Live
                    </Typography>
                    <Typography variant="caption" style={{ lineHeight: 1 }}>
                      updated{" "}
                      {calculateTimeElapsed(
                        currentEpisodeLive
                          ? currentEpisodeLive.updatedAt
                          : currentEpisodeStaged.updatedAt
                      )}
                    </Typography>
                  </Stack>
                </div>
              ) : null}
            </Stack>
          </CardContent>
        </Card>
        <div
          style={{
            height:
              showPadding === "yes"
                ? "calc(100vh - 235px)"
                : "calc(100vh - 219px)",
            overflowY: "scroll",
          }}
        >
          <CardContent style={{ position: "relative" }}>
            <Stack spacing={2} style={{ marginTop: -6 }}>
              {/* required fields */}
              <Stack spacing={2}>
                <Typography variant="h6" className="card-title">
                  Data
                </Typography>
                <Stack direction="row" gap={1}>
                  <DataCard
                    title="Appears in"
                    description="Journeys in which this Episode appears."
                    buttonLabel="View"
                    buttonOnClick={() => {
                      setInfoDialogOpen(true)
                    }}
                  />
                  <DataCard
                    title="Preview"
                    description="Preview of how this Episode appears inside the app."
                    buttonLabel="Open"
                    buttonDisabled={
                      currentEpisode.translations.find(
                        (item) => item.lang === currentPreviewLanguage
                      ) &&
                      !currentEpisode.translations.find(
                        (item) => !item.slides.length
                      )
                        ? false
                        : true
                    }
                    buttonOnClick={() => {
                      setCurrentPreviewLanguage(currentEpisode.lang)
                      setGlobalPreviewOpen(true)
                    }}
                  />
                  {showAdvancedOption === "yes" ? (
                    <DataCard
                      title="Copy JSON"
                      description="Copy JSON string of the current Episode."
                      buttonLabel="Copy"
                      buttonOnClick={() => {
                        copyTextToClipboard(
                          JSON.stringify(currentEpisodeStaged)
                        )
                        setFeedbackOpen(true)
                      }}
                    />
                  ) : null}
                </Stack>
                <Typography variant="h6" className="card-title">
                  Configuration
                </Typography>
                <Stack direction="row" gap={1}>
                  <FormControl
                    fullWidth
                    size="small"
                    disabled={!editMode}
                    error={
                      (currentEpisodeLive &&
                        !currentEpisodeLive.translations.filter(
                          (item) => item.lang === currentEpisode.lang
                        )[0]) ||
                      (currentEpisodeLive &&
                        currentEpisodeLive.translations.filter(
                          (item) => item.lang === currentEpisode.lang
                        )[0] &&
                        currentEpisodeLive.translations.filter(
                          (item) => item.lang === currentEpisode.lang
                        )[0].slides.length === 0)
                    }
                  >
                    <InputLabel id="language-select">
                      Master language
                    </InputLabel>
                    <Select
                      labelId="language-select"
                      label="Master language"
                      value={currentEpisode?.lang}
                      onChange={(e) => {
                        if (
                          currentEpisode.translations.filter(
                            (item) => item.lang === e.target.value
                          )[0]
                        ) {
                          setCurrentEpisode({
                            ...currentEpisode!,
                            lang: e.target.value,
                            title: currentEpisode.translations.find(
                              (item) => item.lang === e.target.value
                            ).title,
                            text: currentEpisode.translations
                              .find((item) => item.lang === e.target.value)
                              .slides.find(
                                (slide) => slide.slideType === "TitleSlide"
                              ).description,
                          })
                        } else {
                          setCurrentEpisode({
                            ...currentEpisode!,
                            lang: e.target.value,
                          })
                        }
                      }}
                    >
                      {languages.map((lang) => (
                        <MenuItem key={lang} value={lang}>
                          {lang.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                    {!currentEpisode.translations.filter(
                      (item) => item.lang === currentEpisode.lang
                    )[0] ? (
                      <FormHelperText error>
                        {currentEpisode.lang.toUpperCase()} translation doesn't
                        exist
                      </FormHelperText>
                    ) : (currentEpisodeLive &&
                        !currentEpisodeLive.translations.filter(
                          (item) => item.lang === currentEpisode.lang
                        )[0]) ||
                      (currentEpisodeLive &&
                        currentEpisodeLive.translations.filter(
                          (item) => item.lang === currentEpisode.lang
                        )[0] &&
                        currentEpisodeLive.translations.filter(
                          (item) => item.lang === currentEpisode.lang
                        )[0].slides.length === 0) ? (
                      <FormHelperText error>
                        {currentEpisode.lang.toUpperCase()} translation doesn't
                        have published slides
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                  <FormControl fullWidth size="small">
                    <InputLabel id="Type">Type</InputLabel>
                    <Select
                      labelId="Type"
                      label="Type"
                      value={currentEpisode.type}
                      onChange={(e) => {
                        setCurrentEpisode({
                          ...currentEpisode,
                          type: e.target.value,
                        })
                      }}
                    >
                      <MenuItem value="Standard">Standard</MenuItem>
                      <MenuItem value="Custom">Custom</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth size="small" disabled={!editMode}>
                    <InputLabel id="special-type">Special type</InputLabel>
                    <Select
                      labelId="special-type"
                      label="Special type"
                      value={currentEpisode.specialType ?? "none"}
                      onChange={(e) => {
                        if (e.target.value === "none") {
                          setCurrentEpisode({
                            ...currentEpisode,
                            specialType: null,
                          })
                        } else {
                          setCurrentEpisode({
                            ...currentEpisode,
                            specialType: e.target.value as EpisodeSpecialType,
                          })
                        }
                      }}
                    >
                      <MenuItem value="none">None</MenuItem>
                      {enumAsArray(EpisodeSpecialType).map(
                        (item: string, index) => (
                          <MenuItem key={index} value={item}>
                            {capitalizeFirstCharacter(item)}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Stack>
                <Stack direction="row" gap={1}>
                  <FormControl fullWidth size="small" disabled={!editMode}>
                    <InputLabel id="recommend-select">Recommend</InputLabel>
                    <Select
                      labelId="recommend-select"
                      label="Recommend"
                      value={
                        currentEpisode?.doNotRecommend === null
                          ? "yes"
                          : currentEpisode.doNotRecommend === false
                          ? "yes"
                          : "no"
                      }
                      onChange={(e) => {
                        currentEpisode.recommendStartsAt = ""
                        currentEpisode.recommendEndsAt = ""

                        setCurrentEpisode({
                          ...currentEpisode!,
                          doNotRecommend:
                            e.target.value === "no" ? true : false,
                        })
                      }}
                    >
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No</MenuItem>
                    </Select>
                  </FormControl>
                  {!currentEpisode.doNotRecommend && (
                    <>
                      <ISODatePicker
                        label="Recommendation start (optional)"
                        date={currentEpisode.recommendStartsAt}
                        setDate={(newDate: string) => {
                          currentEpisode.recommendStartsAt = newDate
                          setCurrentEpisode({ ...currentEpisode })
                        }}
                        disablePast
                      />
                      <ISODatePicker
                        label="Recommendation end (optional)"
                        date={currentEpisode.recommendEndsAt}
                        setDate={(newDate: string) => {
                          currentEpisode.recommendEndsAt = newDate
                          setCurrentEpisode({ ...currentEpisode })
                        }}
                        disablePast
                        minDate={currentEpisode.recommendStartsAt}
                        error={
                          currentEpisode.recommendStartsAt &&
                          currentEpisode.recommendEndsAt &&
                          new Date(currentEpisode.recommendStartsAt) >
                            new Date(currentEpisode.recommendEndsAt)
                        }
                      />
                    </>
                  )}
                </Stack>
                <Typography variant="h6" className="card-title">
                  Text
                </Typography>
                <TextField
                  disabled
                  value={currentEpisode.title}
                  fullWidth
                  label={`Title (${currentEpisode.lang.toUpperCase()})`}
                  variant="outlined"
                  size="small"
                  helperText={`Edit this field in ${currentEpisode.lang.toUpperCase()} translation tab`}
                />
                <TextField
                  disabled
                  value={currentEpisode?.text}
                  fullWidth
                  label={`Description (${currentEpisode.lang.toUpperCase()})`}
                  variant="outlined"
                  size="small"
                  multiline
                  rows={2}
                  error={currentEpisode.text.length < 3}
                  helperText={
                    currentEpisode.text.length < 3
                      ? "Minimum length is 3 characters"
                      : `Edit this field in ${currentEpisode.lang.toUpperCase()} translation tab`
                  }
                  onBlur={() => {
                    if (currentEpisode.text) {
                      setCurrentEpisode({
                        ...currentEpisode!,
                        text: currentEpisode.text.trim(),
                      })
                    }
                  }}
                />
              </Stack>
              {/* category */}
              <Stack spacing={2}>
                <Typography variant="h6" className="card-title">
                  Category
                </Typography>
                <Autocomplete
                  disabled={!editMode}
                  loading={categoriesLoading}
                  fullWidth
                  blurOnSelect
                  size="small"
                  value={
                    currentEpisode.category
                      ? {
                          id: currentEpisode.category.id,
                          label: currentEpisode.category.name,
                        }
                      : null
                  }
                  disablePortal
                  isOptionEqualToValue={(
                    option: AutocompleteOption,
                    value: AutocompleteOption
                  ) => option.id === value.id}
                  options={categoriesList.map((item) => {
                    return {
                      id: item.id,
                      label: item.name,
                    }
                  })}
                  renderInput={(params) => (
                    <TextField {...params} label="Category" />
                  )}
                  onChange={(e: any, newValue: AutocompleteOption | null) => {
                    let currentEpisodeCopy = deepCopy(currentEpisode)
                    if (newValue) {
                      currentEpisodeCopy.category = {
                        id: categoriesList.filter(
                          (item) => item.id === newValue.id
                        )[0].id,
                        name: categoriesList.filter(
                          (item) => item.id === newValue.id
                        )[0].name,
                      }
                    } else {
                      currentEpisodeCopy.category = null
                    }
                    setCurrentEpisode(currentEpisodeCopy)
                  }}
                  renderOption={renderOption}
                  renderTags={renderTags}
                />
                <ClearableSelect
                  label="ESG"
                  value={currentEpisode.esg ?? ""}
                  onChange={(newValue) => {
                    let currentEpisodeCopy = deepCopy(currentEpisode)
                    currentEpisodeCopy.esg = newValue as Esg
                    setCurrentEpisode(currentEpisodeCopy)
                  }}
                >
                  {enumAsArray(Esg).map((key: string) => (
                    <MenuItem key={key} value={key}>
                      {capitalizeFirstCharacter(key)}
                    </MenuItem>
                  ))}
                </ClearableSelect>
              </Stack>
              {/* topics */}
              <Stack spacing={2}>
                <Typography variant="h6" className="card-title">
                  Topics
                </Typography>
                <Autocomplete
                  disabled={!editMode}
                  getOptionDisabled={(option: AutocompleteOption) => {
                    if (
                      currentEpisode.topics.filter(
                        (topic) => topic.topic.id === option.id
                      )[0] &&
                      !currentEpisode.topics.filter(
                        (topic) => topic.topic.id === option.id
                      )[0].primary
                    ) {
                      return true
                    } else {
                      return false
                    }
                  }}
                  fullWidth
                  size="small"
                  value={
                    currentEpisode.topics.filter((item) => item.primary)[0]
                      ? {
                          label: currentEpisode.topics.filter(
                            (item) => item.primary
                          )[0].topic.name,
                          id: currentEpisode.topics.filter(
                            (item) => item.primary
                          )[0].topic.id,
                        }
                      : null
                  }
                  disablePortal
                  isOptionEqualToValue={(
                    option: AutocompleteOption,
                    value: AutocompleteOption
                  ) => option.id === value.id}
                  options={
                    topicsList.length
                      ? topicsList.map((topic: Topic) => {
                          return { label: topic.name, id: topic.id }
                        })
                      : []
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Primary topic" />
                  )}
                  onChange={(e: any, newValue: AutocompleteOption | null) => {
                    if (newValue) {
                      setCurrentEpisode({
                        ...currentEpisode!,
                        topics: [
                          {
                            primary: true,
                            topic: {
                              id: newValue.id,
                              name: newValue.label,
                            },
                          },
                          ...currentEpisode.topics.filter(
                            (topic) => !topic.primary
                          ),
                        ],
                      })
                    }
                  }}
                  renderOption={renderOption}
                  renderTags={renderTags}
                />
                <Autocomplete
                  disabled={!editMode}
                  getOptionDisabled={(option: AutocompleteOption) => {
                    if (
                      currentEpisode.topics.filter(
                        (topic) => topic.topic.id === option.id
                      )[0] &&
                      currentEpisode.topics.filter(
                        (topic) => topic.topic.id === option.id
                      )[0].primary
                    ) {
                      return true
                    } else {
                      return false
                    }
                  }}
                  multiple
                  fullWidth
                  onBlur={() => {
                    setSecondaryTopicsError(false)
                  }}
                  size="small"
                  value={currentEpisode.topics
                    .filter((topic) => !topic.primary)
                    .map((topic) => {
                      return {
                        label: topic.topic.name,
                        id: topic.topic.id,
                      }
                    })}
                  disablePortal
                  isOptionEqualToValue={(
                    option: AutocompleteOption,
                    value: AutocompleteOption
                  ) => option.id === value.id}
                  options={
                    topicsList.length
                      ? topicsList.map((topic: Topic) => {
                          return { label: topic.name, id: topic.id }
                        })
                      : []
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={secondaryTopicsError}
                      helperText={
                        secondaryTopicsError
                          ? "The maximum number of secondary topics is 3"
                          : null
                      }
                      label="Secondary topics"
                    />
                  )}
                  onChange={(e: any, newValues: AutocompleteOption[]) => {
                    if (newValues.length < 4) {
                      setCurrentEpisode({
                        ...currentEpisode!,
                        topics: [
                          ...currentEpisode.topics.filter(
                            (topic) => topic.primary
                          ),
                          ...newValues.map((topic) => {
                            return {
                              primary: false,
                              topic: {
                                id: topic.id,
                                name: topic.label,
                              },
                            }
                          }),
                        ],
                      })
                      setSecondaryTopicsError(false)
                    } else {
                      setSecondaryTopicsError(true)
                    }
                  }}
                  renderOption={renderOption}
                  renderTags={renderTags}
                />
              </Stack>
              {/* sdgs */}
              <Stack spacing={2}>
                <Typography variant="h6" className="card-title">
                  SDGs
                </Typography>
                <Autocomplete
                  disabled={!editMode}
                  getOptionDisabled={(option: AutocompleteOption) => {
                    if (
                      currentEpisode.sdgs.filter(
                        (sdg) => sdg.sdg.id === option.id
                      )[0] &&
                      !currentEpisode.sdgs.filter(
                        (sdg) => sdg.sdg.id === option.id
                      )[0].primary
                    ) {
                      return true
                    } else {
                      return false
                    }
                  }}
                  fullWidth
                  size="small"
                  value={
                    currentEpisode.sdgs.filter((item) => item.primary)[0]
                      ? {
                          label:
                            (currentEpisode.sdgs
                              .filter((item) => item.primary)[0]
                              .sdg.id.charAt(4) === "0"
                              ? currentEpisode.sdgs
                                  .filter((item) => item.primary)[0]
                                  .sdg.id.slice(5) + ". "
                              : currentEpisode.sdgs
                                  .filter((item) => item.primary)[0]
                                  .sdg.id.slice(4) + ". ") +
                            currentEpisode.sdgs.filter(
                              (item) => item.primary
                            )[0].sdg.name,
                          id: currentEpisode.sdgs.filter(
                            (item) => item.primary
                          )[0].sdg.id,
                        }
                      : null
                  }
                  disablePortal
                  isOptionEqualToValue={(
                    option: AutocompleteOption,
                    value: AutocompleteOption
                  ) => option.id === value.id}
                  options={
                    sdgsList.length
                      ? sdgsList.map((sdg: Sdg) => {
                          let idForLabel =
                            sdg.id.charAt(4) === "0"
                              ? sdg.id.slice(5) + ". "
                              : sdg.id.slice(4) + ". "

                          return { label: idForLabel + sdg.name, id: sdg.id }
                        })
                      : []
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Primary SDG" />
                  )}
                  onChange={(e: any, newValue: AutocompleteOption | null) => {
                    if (newValue) {
                      setCurrentEpisode({
                        ...currentEpisode!,
                        sdgs: [
                          {
                            primary: true,
                            sdg: {
                              id: newValue.id,
                              name: newValue.label.slice(
                                newValue.label.indexOf(".") + 2
                              ),
                            },
                          },
                          ...currentEpisode.sdgs.filter((sdg) => !sdg.primary),
                        ],
                      })
                    }
                  }}
                  renderOption={renderOption}
                  renderTags={renderTags}
                />
                <Autocomplete
                  disabled={!editMode}
                  getOptionDisabled={(option: AutocompleteOption) => {
                    if (
                      currentEpisode.sdgs.filter(
                        (sdg) => sdg.sdg.id === option.id
                      )[0] &&
                      currentEpisode.sdgs.filter(
                        (sdg) => sdg.sdg.id === option.id
                      )[0].primary
                    ) {
                      return true
                    } else {
                      return false
                    }
                  }}
                  multiple
                  fullWidth
                  onBlur={() => {
                    setSecondarySdgsError(false)
                  }}
                  size="small"
                  value={currentEpisode.sdgs
                    .filter((sdg) => !sdg.primary)
                    .map((sdg) => {
                      let idForLabel =
                        sdg.sdg.id.charAt(4) === "0"
                          ? sdg.sdg.id.slice(5) + ". "
                          : sdg.sdg.id.slice(4) + ". "

                      return {
                        label: idForLabel + sdg.sdg.name,
                        id: sdg.sdg.id,
                      }
                    })}
                  disablePortal
                  isOptionEqualToValue={(
                    option: AutocompleteOption,
                    value: AutocompleteOption
                  ) => option.id === value.id}
                  options={
                    sdgsList.length
                      ? sdgsList.map((sdg: Sdg) => {
                          let idForLabel =
                            sdg.id.charAt(4) === "0"
                              ? sdg.id.slice(5) + ". "
                              : sdg.id.slice(4) + ". "

                          return { label: idForLabel + sdg.name, id: sdg.id }
                        })
                      : []
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={secondarySdgsError}
                      helperText={
                        secondarySdgsError
                          ? "The maximum number of secondary SDGs is 3"
                          : null
                      }
                      label="Secondary SDGs"
                    />
                  )}
                  onChange={(e: any, newValues: AutocompleteOption[]) => {
                    if (newValues.length < 4) {
                      setCurrentEpisode({
                        ...currentEpisode!,
                        sdgs: [
                          ...currentEpisode.sdgs.filter((sdg) => sdg.primary),
                          ...newValues.map((sdg) => {
                            return {
                              primary: false,
                              sdg: {
                                id: sdg.id,
                                name: sdg.label.slice(
                                  sdg.label.indexOf(".") + 2
                                ),
                              },
                            }
                          }),
                        ],
                      })
                      setSecondarySdgsError(false)
                    } else {
                      setSecondarySdgsError(true)
                    }
                  }}
                  renderOption={renderOption}
                  renderTags={renderTags}
                />
              </Stack>
              {/* sdg targets */}
              <Stack spacing={2}>
                <Typography variant="h6" className="card-title">
                  SDG targets
                </Typography>
                <Autocomplete
                  disabled={!editMode}
                  getOptionDisabled={(option: AutocompleteOption) => {
                    if (
                      currentEpisode.sdgTargets.filter(
                        (sdgTarget) => sdgTarget.sdgTarget.id === option.id
                      )[0] &&
                      !currentEpisode.sdgTargets.filter(
                        (sdgTarget) => sdgTarget.sdgTarget.id === option.id
                      )[0].primary
                    ) {
                      return true
                    } else {
                      return false
                    }
                  }}
                  fullWidth
                  size="small"
                  value={
                    currentEpisode.sdgTargets.filter((item) => item.primary)[0]
                      ? {
                          label: currentEpisode.sdgTargets
                            .filter((item) => item.primary)[0]
                            .sdgTarget.id.slice(4),
                          id: currentEpisode.sdgTargets.filter(
                            (item) => item.primary
                          )[0].sdgTarget.id,
                        }
                      : null
                  }
                  disablePortal
                  isOptionEqualToValue={(
                    option: AutocompleteOption,
                    value: AutocompleteOption
                  ) => option.id === value.id}
                  options={
                    sdgTargetsList.length
                      ? sdgTargetsList.map((sdgTarget: SdgTarget) => {
                          return {
                            label: sdgTarget.id.slice(4),
                            id: sdgTarget.id,
                          }
                        })
                      : []
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Primary SDG target" />
                  )}
                  onChange={(e: any, newValue: AutocompleteOption | null) => {
                    if (newValue) {
                      setCurrentEpisode({
                        ...currentEpisode!,
                        sdgTargets: [
                          {
                            primary: true,
                            sdgTarget: {
                              id: newValue.id,
                            },
                          },
                          ...currentEpisode.sdgTargets.filter(
                            (sdgTarget) => !sdgTarget.primary
                          ),
                        ],
                      })
                    }
                  }}
                  renderOption={renderOption}
                  renderTags={renderTags}
                />
                <Autocomplete
                  disabled={!editMode}
                  getOptionDisabled={(option: AutocompleteOption) => {
                    if (
                      currentEpisode.sdgTargets.filter(
                        (sdgTarget) => sdgTarget.sdgTarget.id === option.id
                      )[0] &&
                      currentEpisode.sdgTargets.filter(
                        (sdgTarget) => sdgTarget.sdgTarget.id === option.id
                      )[0].primary
                    ) {
                      return true
                    } else {
                      return false
                    }
                  }}
                  multiple
                  fullWidth
                  onBlur={() => {
                    setSecondarySdgsError(false)
                  }}
                  size="small"
                  value={currentEpisode.sdgTargets
                    .filter((sdgTarget) => !sdgTarget.primary)
                    .map((sdgTarget) => {
                      return {
                        label: sdgTarget.sdgTarget.id.slice(4),
                        id: sdgTarget.sdgTarget.id,
                      }
                    })}
                  disablePortal
                  isOptionEqualToValue={(
                    option: AutocompleteOption,
                    value: AutocompleteOption
                  ) => option.id === value.id}
                  options={
                    sdgTargetsList.length
                      ? sdgTargetsList.map((sdgTarget: SdgTarget) => {
                          return {
                            label: sdgTarget.id.slice(4),
                            id: sdgTarget.id,
                          }
                        })
                      : []
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Secondary SDG targets" />
                  )}
                  onChange={(e: any, newValues: AutocompleteOption[]) => {
                    setCurrentEpisode({
                      ...currentEpisode!,
                      sdgTargets: [
                        ...currentEpisode.sdgTargets.filter(
                          (sdgTarget) => sdgTarget.primary
                        ),
                        ...newValues.map((sdgTarget) => {
                          return {
                            primary: false,
                            sdgTarget: {
                              id: sdgTarget.id,
                            },
                          }
                        }),
                      ],
                    })
                  }}
                  renderOption={renderOption}
                  renderTags={renderTags}
                />
              </Stack>
              {/* thumbnail */}
              <Stack spacing={2}>
                <Typography variant="h6" className="card-title">
                  Thumbnail
                </Typography>
                <ImagePicker
                  filters={{
                    category: "thumbnail",
                  }}
                  disableCategoryFilter
                  disabled={!editMode}
                  title="Thumbnail"
                  pickedImage={currentEpisode.image ?? ""}
                  setPickedImage={(selectedImage: {
                    url: string
                    id: string
                    description?: string
                  }) => {
                    setCurrentEpisode({
                      ...currentEpisode!,
                      image: selectedImage.url.length
                        ? selectedImage.url
                        : null,
                    })
                  }}
                  height={200}
                />
              </Stack>
            </Stack>
          </CardContent>
        </div>
      </div>
      {/* undo changes dialog */}
      <Dialog
        open={undoDialogOpen}
        onClose={() => {
          setUndoDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-translation"
        aria-describedby="alert-dialog-description-translation"
      >
        <DialogTitle id="alert-dialog-title-translation">
          Are you sure you want to undo changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-translation">
            All changes will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setUndoDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              cancelChanges()
              setUndoDialogOpen(false)
            }}
            autoFocus
          >
            Undo changes
          </Button>
        </DialogActions>
      </Dialog>
      {/* no thumbnail alert dialog */}
      <CantPerformActionDialog
        open={noThumbnailDialogOpen}
        setOpen={setNoThumbnailDialogOpen}
        content="You must add a thumbnail in order to publish this master Episode"
      />
      {/* no slides alert dialog */}
      <CantPerformActionDialog
        open={noSlidesDialogOpen}
        setOpen={setNoSlidesDialogOpen}
        content={`The master translation (${currentEpisode.lang.toUpperCase()}) must
        have at least one slide and one quiz in order to publish this master
        Episode`}
      />
      {/* double publish dialog */}
      <Dialog
        open={doublePublishDialogOpen}
        onClose={() => {
          setdoublePublishDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-doublepublish"
        aria-describedby="alert-dialog-description-doublepublish"
      >
        <DialogTitle id="alert-dialog-title-doublepublish">
          Attention
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-doublepublish">
            Publishing this master episode you will also publish the master
            translation ({currentEpisode.lang.toUpperCase()}). Are you sure you
            want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setdoublePublishDialogOpen(false)
            }}
            autoFocus
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              setdoublePublishDialogOpen(false)

              setLoading(true)
              let noErrors = await publishTranslation(
                currentEpisode.id,
                currentEpisode.lang
              )
              if (noErrors) {
                noErrors = await publishEpisodeParent(episodeId)
                if (noErrors) {
                  fetchAll()
                } else {
                  setLoading(false)
                }
              } else {
                setLoading(false)
              }
            }}
            autoFocus
          >
            Publish
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default EpisodeGeneralEdit
