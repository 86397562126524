import { useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import Channel from "../models/channel"
import {
  createChannel,
  updateChannel,
  upsertChannelJourney,
} from "../services/graphql/mutations"
import {
  channel,
  channelJourneys,
  listChannels,
  teamGet,
} from "../services/graphql/queries"
import { deepCopy, logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"

interface ChannelsContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  channelsList: Channel[]
  getChannelsList: (withLoading?: boolean) => void
  doneChanges: boolean
  cancelChanges: () => void
  getChannel: (channelId: string) => Promise<boolean>
  currentChannel: Channel
  setCurrentChannel: Dispatch<SetStateAction<Channel>>
  updateAllChannel: (
    channelId: string,
    clientCredentialId: string,
    issuer: string,
    oldTeamId: string,
    projectId: string,
    teamId: string,
    webhookUrl: string,
    reportUrl: string,
    privacyVersion: string,
    journeyItems: { id: string; startsAt: string; endsAt: string }[]
  ) => Promise<boolean>
  createNewChannel: (
    projectId: string,
    clientCredentialId: string,
    issuer: string,
    webhookUrl: string,
    reportUrl: string,
    privacyVersion: string,
    oldTeamId: string,
    teamId: string
  ) => Promise<Channel>
}

const ChannelsContext = createContext<ChannelsContextInterface>({
  loading: true,
  setLoading: () => {},
  updatingList: false,
  setUpdatingList: () => {},
  editMode: false,
  setEditMode: () => {},
  channelsList: [],
  getChannelsList: () => {},
  doneChanges: false,
  cancelChanges: () => {},
  getChannel: async () => true,
  currentChannel: new Channel(),
  setCurrentChannel: () => {},
  updateAllChannel: async () => true,
  createNewChannel: async () => new Channel(),
})

const ChannelsController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // states
  const [loading, setLoading] = useState<boolean>(true) // loading
  const [updatingList, setUpdatingList] = useState<boolean>(false) // to show loader
  const [editMode, setEditMode] = useState<boolean>(true) // if all page should be disabled or not
  const [channelsList, setChannelsList] = useState<Channel[]>([]) // list of all channels
  const [doneChanges, setDoneChanges] = useState<boolean>(false) // if user has done changes
  const [currentChannel, setCurrentChannel] = useState<Channel>(new Channel()) // current channel
  const [preChangesCurrentChannel, setPreChangesCurrentChannel] =
    useState<Channel>(new Channel()) // fetched current channel

  // queries
  const [listChannelsQuery] = useLazyQuery(listChannels)
  const [channelQuery] = useLazyQuery(channel)
  const [channelJourneysQuery] = useLazyQuery(channelJourneys)
  const [teamGetQuery] = useLazyQuery(teamGet)

  // mutations
  const [updateChannelMutation] = useMutation(updateChannel)
  const [upsertChannelJourneyMutation] = useMutation(upsertChannelJourney)
  const [createChannelMutation] = useMutation(createChannel)

  // get channels list
  const getChannelsList = useCallback(
    async (withLoading: boolean = true) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter((item) => item.name === "Channels")[0].loading = true
        setPanelStatus([...panelStatus])
      }

      try {
        logger(Status.Api, "QUERY listChannels", listChannels)
        const { data } = await listChannelsQuery({ fetchPolicy: "no-cache" })
        logger(Status.Info, "channels list", data.listChannels.items)

        setChannelsList(data.listChannels.items)

        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Channels")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "Channels")[0].loading =
          false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `listChannels`, e.message)
        }
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Channels")[0].status =
          "error"
        panelStatus.filter((item) => item.name === "Channels")[0].loading =
          false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // get channel
  const getChannel = useCallback(
    async (channelId: string) => {
      try {
        logger(Status.Api, "QUERY channel", channel)
        logger(Status.Api, "QUERY channelJourneys", channelJourneys)
        logger(Status.Api, "QUERY teamGet", teamGet)
        const result = await Promise.all([
          channelQuery({
            variables: { channelId: channelId },
            fetchPolicy: "no-cache",
          }),
          channelJourneysQuery({
            variables: { channelId: channelId, lang: "en" },
            fetchPolicy: "no-cache",
          }),
        ])
        const { data } = await teamGetQuery({
          variables: { input: { id: result[0].data.channel.teamId } },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, `channel ${channelId}`, {
          ...result[0].data.channel,
          journeys: result[1].data.channelJourneys.items,
          teamName: data.teamGet.name,
        })

        setCurrentChannel({
          ...result[0].data.channel,
          journeys: result[1].data.channelJourneys.items,
          teamName: data.teamGet.name,
        })
        setPreChangesCurrentChannel(
          deepCopy({
            ...result[0].data.channel,
            journeys: result[1].data.channelJourneys.items,
            teamName: data.teamGet.name,
          })
        )

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `channel`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // update channel
  const updateAllChannel = async (
    channelId: string,
    clientCredentialId: string,
    issuer: string,
    oldTeamId: string,
    projectId: string,
    teamId: string,
    webhookUrl: string,
    reportUrl: string,
    privacyVersion: string,
    journeyItems: { id: string; startsAt: string; endsAt: string }[]
  ) => {
    try {
      logger(Status.Api, "MUTATION updateChannel", updateChannel)
      logger(Status.Api, "MUTATION upsertChannelJourney", upsertChannelJourney)
      const result = await Promise.all([
        updateChannelMutation({
          variables: {
            input: {
              channelId: channelId,
              clientCredentialId: clientCredentialId,
              issuer: issuer,
              oldTeamId: oldTeamId,
              projectId: projectId,
              teamId: teamId,
              webhookUrl: webhookUrl,
              reportUrl: reportUrl,
              privacyVersion: privacyVersion,
            },
          },
        }),
        upsertChannelJourneyMutation({
          variables: {
            input: {
              channelId: channelId,
              journeyItems: journeyItems,
            },
          },
        }),
      ])
      logger(Status.Info, `channel ${channelId} updated`, {
        ...result[0].data.updateChannel,
        teamName: currentChannel.teamName,
        journeys: result[1].data.upsertChannelJourney.items,
      })

      setCurrentChannel({
        ...result[0].data.updateChannel,
        teamName: currentChannel.teamName,
        journeys: result[1].data.upsertChannelJourney.items,
      })
      setPreChangesCurrentChannel(
        deepCopy({
          ...result[0].data.updateChannel,
          teamName: currentChannel.teamName,
          journeys: result[1].data.upsertChannelJourney.items,
        })
      )

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `updateChannel`, e.message)
      }
      return false
    }
  }

  // create new channel
  const createNewChannel = async (
    projectId: string,
    clientCredentialId: string,
    issuer: string,
    webhookUrl: string,
    reportUrl: string,
    privacyVersion: string,
    oldTeamId: string,
    teamId: string
  ) => {
    setLoading(true)

    try {
      logger(Status.Api, "MUTATION createChannel", createChannel)
      const { data } = await createChannelMutation({
        variables: {
          input: {
            projectId: projectId,
            clientCredentialId: clientCredentialId,
            issuer: issuer,
            webhookUrl: webhookUrl,
            reportUrl: reportUrl,
            privacyVersion: privacyVersion,
            oldTeamId: oldTeamId,
            teamId: teamId,
          },
        },
      })
      logger(Status.Info, `channel created`, data.createChannel)

      setLoading(false)

      return data.createChannel
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `createChannel`, e.message)
      }
      setLoading(false)
    }
  }

  // cancel changes
  const cancelChanges = () => {
    setCurrentChannel(deepCopy(preChangesCurrentChannel))
  }

  // check if user has done changes
  useEffect(() => {
    if (
      JSON.stringify(currentChannel) !==
      JSON.stringify(preChangesCurrentChannel)
    ) {
      setDoneChanges(true)
    } else {
      setDoneChanges(false)
    }
  }, [currentChannel])

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter((item) => item.name === "Channels")[0].updateFunction =
      getChannelsList
  }, [])

  // initial fetch
  useEffect(() => {
    getChannelsList()
  }, [])

  return (
    <ChannelsContext.Provider
      value={{
        loading,
        setLoading,
        updatingList,
        setUpdatingList,
        editMode,
        setEditMode,
        channelsList,
        getChannelsList,
        doneChanges,
        cancelChanges,
        getChannel,
        currentChannel,
        setCurrentChannel,
        updateAllChannel,
        createNewChannel,
      }}
    >
      {children}
    </ChannelsContext.Provider>
  )
}

export { ChannelsController, ChannelsContext }
