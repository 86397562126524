import Journey from "./journey"

class Channel {
  id: string
  teamId: string
  projectId: string
  type: string
  oldTeamId: string
  issuer: string
  clientCredentialId: string
  webhookUrl: string
  reportUrl: string
  createdAt: string
  updatedAt: string
  journeys: { startsAt: string; endsAt: string; journey: Journey }[]
  teamName: string
  privacyVersion: string

  constructor(
    id?: string,
    teamId?: string,
    projectId?: string,
    type?: string,
    oldTeamId?: string,
    issuer?: string,
    clientCredentialId?: string,
    webhookUrl?: string,
    reportUrl?: string,
    createdAt?: string,
    updatedAt?: string,
    journeys?: { startsAt: string; endsAt: string; journey: Journey }[],
    teamName?: string,
    privacyVersion?: string
  ) {
    this.id = id
    this.teamId = teamId
    this.projectId = projectId
    this.type = type
    this.oldTeamId = oldTeamId
    this.issuer = issuer
    this.clientCredentialId = clientCredentialId
    this.webhookUrl = webhookUrl
    this.reportUrl = reportUrl
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.journeys = journeys
    this.teamName = teamName
    this.privacyVersion = privacyVersion
  }
}

export default Channel
