import { CardContent, Stack, TextField, Typography } from "@mui/material"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import { TagsContext } from "../../../controllers/tags"

const SdgTargetTranslationsEdit = ({
  itemIndex,
  errors,
}: {
  itemIndex: number
  errors: { type: string; error: boolean }[]
}) => {
  const { showPadding } = useContext(MainContext)
  const { currentSdgTarget, setCurrentSdgTarget, editMode } =
    useContext(TagsContext)

  // current item
  const currentItem = currentSdgTarget.translations[itemIndex]

  // errors
  const descriptionError = errors.filter(
    (item) =>
      item.type ===
      "description" + currentSdgTarget.translations[itemIndex].lang
  )[0].error

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Details
        </Typography>
        <TextField
          fullWidth
          size="small"
          label="Description"
          value={currentItem.description}
          onChange={(e) => {
            currentSdgTarget.translations[itemIndex].description =
              e.target.value
            setCurrentSdgTarget({ ...currentSdgTarget })
          }}
          onBlur={() => {
            currentSdgTarget.translations[itemIndex].description =
              currentSdgTarget.translations[itemIndex].description.trim()
            setCurrentSdgTarget({ ...currentSdgTarget })
          }}
          multiline
          minRows={3}
          disabled={!editMode}
          error={descriptionError}
          helperText={descriptionError ? "This field is required" : ""}
        />
      </Stack>
    </CardContent>
  )
}

export default SdgTargetTranslationsEdit
