import { useLazyQuery } from "@apollo/client"
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  translationsExportUrlGet,
  translationsImportUrlGet,
  translationsJobGet,
} from "../services/graphql/queries"
import { logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"
import axios from "axios"
import { JobStatus } from "../services/config/enum"

interface TranslationsContextInterface {
  exportEpisodesTranslations: (input: {
    episodes: { episodeId: string; sourceLang: string; langs: string[] }[]
    formatHtml: boolean
    stripHtml: boolean
  }) => Promise<boolean>
  exportJourneysTranslations: (input: {
    journeys: { journeyId: string; sourceLang: string; langs: string[] }[]
    formatHtml: boolean
    stripHtml: boolean
  }) => Promise<boolean>
  importTranslations: (
    input: {
      contentType: string
      fileName: string
    },
    dataUrl: string
  ) => Promise<boolean>
  currentJobStatus: JobStatus | null
  currentJobError: string | null
  jobRunning: boolean
}

const TranslationsContext = createContext<TranslationsContextInterface>({
  exportEpisodesTranslations: async () => true,
  exportJourneysTranslations: async () => true,
  importTranslations: async () => true,
  currentJobStatus: null,
  currentJobError: null,
  jobRunning: false,
})

const TranslationsController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage } = useContext(MainContext)

  // states
  const [currentJobId, setCurrentJobId] = useState<string | null>(null) // id of the current import job
  const [currentJobStatus, setCurrentJobStatus] = useState<JobStatus | null>(
    null
  ) // status of the current import job
  const [currentJobError, setCurrentJobError] = useState<string | null>(null) // if current job has failed
  const [jobPollingIntervalId, setJobPollingIntervalId] = useState<any | null>(
    null
  ) // id of polling interval for the job
  const [jobPollingTimeoutId, setJobPollingTimeoutId] = useState<any | null>(
    null
  ) // id of polling timeout for the job
  const [jobResetTimeoutId, setJobResetTimeoutId] = useState<any | null>(null) // id of reset timeout for the job
  const [jobRunning, setJobRunning] = useState<boolean>(false) // if there is a job running or not

  // queries
  const [translationsExportUrlGetQuery] = useLazyQuery(translationsExportUrlGet)
  const [translationsImportUrlGetQuery] = useLazyQuery(translationsImportUrlGet)
  const [translationsJobGetQuery] = useLazyQuery(translationsJobGet)

  // export episodes translations
  const exportEpisodesTranslations = async (input: {
    episodes: { episodeId: string; sourceLang: string; langs: string[] }[]
    formatHtml: boolean
    stripHtml: boolean
  }) => {
    try {
      logger(
        Status.Api,
        "QUERY translationsExportUrlGet",
        translationsExportUrlGet
      )
      const { data } = await translationsExportUrlGetQuery({
        variables: { input: input },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, `s3 url: ${data.translationsExportUrlGet.s3Url}`)

      // download file
      const link = document.createElement("a")
      link.href = data.translationsExportUrlGet.s3Url
      link.click()

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `translationsExportUrlGet`, e.message)
      }

      return false
    }
  }

  // export journeys translations
  const exportJourneysTranslations = async (input: {
    journeys: { journeyId: string; sourceLang: string; langs: string[] }[]
    formatHtml: boolean
    stripHtml: boolean
  }) => {
    try {
      logger(
        Status.Api,
        "QUERY translationsExportUrlGet",
        translationsExportUrlGet
      )
      const { data } = await translationsExportUrlGetQuery({
        variables: { input: input },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, `s3 url: ${data.translationsExportUrlGet.s3Url}`)

      // download file
      const link = document.createElement("a")
      link.href = data.translationsExportUrlGet.s3Url
      link.click()

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `translationsExportUrlGet`, e.message)
      }

      return false
    }
  }

  // import translations
  const importTranslations = async (
    input: {
      contentType: string
      fileName: string
    },
    dataUrl: string
  ) => {
    // clear last import
    if (jobPollingIntervalId) {
      clearInterval(jobPollingIntervalId)
    }
    if (jobPollingTimeoutId) {
      clearTimeout(jobPollingTimeoutId)
    }
    if (jobResetTimeoutId) {
      clearTimeout(jobResetTimeoutId)
    }
    setJobPollingIntervalId(null)
    setJobPollingTimeoutId(null)
    setCurrentJobId(null)
    setCurrentJobStatus(null)
    setCurrentJobError(null)
    setJobRunning(false)

    try {
      logger(
        Status.Api,
        "QUERY translationsImportUrlGet",
        translationsImportUrlGet
      )
      const { data } = await translationsImportUrlGetQuery({
        variables: { input: input },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, `s3 url: ${data.translationsImportUrlGet.s3Url}`)
      logger(Status.Info, `job id: ${data.translationsImportUrlGet.jobId}`)

      // s3 put
      logger(Status.Api, "axios S3 PUT")
      const blob = await fetch(dataUrl).then((r) => r.blob())
      let config = {
        method: "put",
        url: data.translationsImportUrlGet.s3Url,
        data: blob,
      }
      await axios(config)

      setJobRunning(true)
      setCurrentJobId(data.translationsImportUrlGet.jobId)

      logger(Status.Info, "job started")

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `translationsExportUrlGet`, e.message)
      }

      return false
    }
  }

  // get job status
  const getJobStatus = async () => {
    try {
      logger(Status.Api, "QUERY translationsJobGet", translationsJobGet)
      const { data } = await translationsJobGetQuery({
        variables: { input: { id: currentJobId } },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, `job status: ${data.translationsJobGet.status}`)

      setCurrentJobStatus(data.translationsJobGet.status)
      setCurrentJobError(data.translationsJobGet.error)
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `translationsExportUrlGet`, e.message)
      }
    }
  }

  // start to do a polling to get job status if job id is not null
  useEffect(() => {
    if (currentJobId && !jobPollingIntervalId) {
      getJobStatus()
      const intervalId = setInterval(getJobStatus, 2000)
      const timeoutId = setTimeout(() => {
        clearInterval(intervalId)
        setCurrentJobStatus(JobStatus.ERROR)
        setCurrentJobError("TIMEOUT")
        setJobRunning(false)
        const id = setTimeout(() => {
          setCurrentJobId(null)
          setCurrentJobStatus(null)
          setCurrentJobError(null)
        }, 6000)
        setJobResetTimeoutId(id)
      }, 60000)
      setJobPollingIntervalId(intervalId)
      setJobPollingTimeoutId(timeoutId)
    } else if (
      currentJobId &&
      jobPollingIntervalId &&
      currentJobStatus &&
      (currentJobStatus === JobStatus.COMPLETED ||
        currentJobStatus === JobStatus.ERROR)
    ) {
      clearInterval(jobPollingIntervalId)
      clearTimeout(jobPollingTimeoutId)
      setJobPollingIntervalId(null)
      setJobPollingTimeoutId(null)
      setJobRunning(false)
      const id = setTimeout(() => {
        setCurrentJobId(null)
        setCurrentJobStatus(null)
        setCurrentJobError(null)
      }, 6000)
      setJobResetTimeoutId(id)
    }
  }, [currentJobId, currentJobStatus])

  return (
    <TranslationsContext.Provider
      value={{
        exportEpisodesTranslations,
        exportJourneysTranslations,
        importTranslations,
        currentJobStatus,
        currentJobError,
        jobRunning,
      }}
    >
      {children}
    </TranslationsContext.Provider>
  )
}

export { TranslationsController, TranslationsContext }
