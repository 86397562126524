import { useContext, useEffect, useState } from "react"
import EditContainer from "../../components/global/editContainer"
import EditHeading from "../../components/global/editHeading"
import { MainContext } from "../../controllers/main"
import { useNavigate, useParams } from "react-router-dom"
import { ChallengesContext } from "../../controllers/challenges"
import PageSpinner from "../../components/global/pageSpinner"
import ListTable from "../../components/global/listTable"
import { Chip, TableCell, TableRow, Typography } from "@mui/material"
import LeaderboardListContainer from "../../components/challenge/leaderboardListContainer"
import TitleTableCell from "../../components/global/titleTableCell"
import PrimaryBadge from "../../components/global/primaryBadge"

const ChallengeLeaderboardUsers = () => {
  const { setAnimation, showPadding } = useContext(MainContext)
  const {
    preChangesCurrentChallenge,
    getCurrentChallenge,
    currentUsersLeaderboard,
    currentUsersLeaderboardNextToken,
    getUsersLeaderboard,
    getAllLeaderboard,
  } = useContext(ChallengesContext)
  const navigate = useNavigate()
  const { challengeId } = useParams()

  // loading
  const [loading, setLoading] = useState<boolean>(true)
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // page error
  const [pageError, setPageError] = useState<boolean>(false)

  // fetch leaderboard
  const fetchAll = async () => {
    if (!preChangesCurrentChallenge.id) {
      const noErrors = await Promise.all([
        getUsersLeaderboard(challengeId, false),
        getCurrentChallenge(challengeId),
      ])
      if (noErrors[0] && noErrors[1]) {
        setLoading(false)
      } else {
        setPageError(true)
      }
    } else {
      const noErrors = await getUsersLeaderboard(challengeId, false)
      if (noErrors) {
        setLoading(false)
      } else {
        setPageError(true)
      }
    }
  }

  useEffect(() => {
    fetchAll()
  }, [])

  return loading ? (
    <PageSpinner
      showBackButton={pageError}
      backButtonOnClick={() => {
        navigate(
          window.location.pathname.slice(
            0,
            window.location.pathname.indexOf("/leaderboard")
          )
        )
      }}
    />
  ) : (
    <EditContainer>
      <EditHeading
        backButtonOnClick={() => {
          setAnimation(false)
          setTimeout(() => {
            navigate(
              window.location.pathname.slice(
                0,
                window.location.pathname.indexOf("/leaderboard")
              )
            )
          }, 300)
        }}
        title={preChangesCurrentChallenge.name + " / Users leaderboard"}
        id={preChangesCurrentChallenge.id}
        showCsvButton
        csvButtonType="dropdown"
        csvButtonOnClick={async (withEmails) => {
          const reult = await getAllLeaderboard(
            challengeId,
            withEmails,
            "users"
          )
          return reult
        }}
        csvFileName={`${preChangesCurrentChallenge.name} users leaderboard.csv`}
      />
      <LeaderboardListContainer>
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 150px)"
              : "calc(100vh - 132px)"
          }
          headingItems={["Rank", "Image", "Nickname & SUB", "UID", "Score"]}
          nextToken={currentUsersLeaderboardNextToken}
          loadingMoreItems={loadMoreLoading}
          setLoadingMoreItems={setLoadMoreLoading}
          loadMore={async () => {
            const result = await getUsersLeaderboard(challengeId)
            return result
          }}
          tableBody={currentUsersLeaderboard.map((item, index) => (
            <TableRow
              key={item.user.sub}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell style={{ width: 36, minWidth: 36 }}>
                <Typography>{index + 1}</Typography>
              </TableCell>
              <TableCell style={{ width: 36, minWidth: 36 }}>
                <div
                  style={{
                    width: 36,
                    height: 36,
                    borderRadius: 20,
                    backgroundImage: `url(http://cdn.aworld.io/users/${item.user.sub}/profile.jpg), url(https://cdn.aworld.io/users/default/profile.jpg)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </TableCell>
              <TitleTableCell
                title={item.user.nickname ?? "null"}
                id={item.user.sub}
              />
              <TableCell>
                <Chip label={item.user.uid ?? "null"} size="small" />
              </TableCell>
              <TableCell style={{ minWidth: 100 }}>
                <PrimaryBadge
                  count={
                    Math.round((item.metricSum + Number.EPSILON) * 10) / 10
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        />
      </LeaderboardListContainer>
    </EditContainer>
  )
}

export default ChallengeLeaderboardUsers
