import {
  Button,
  Card,
  CardContent,
  Grow,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import EditContainer from "../../components/global/editContainer"
import PageSpinner from "../../components/global/pageSpinner"
import { MainContext } from "../../controllers/main"
import LoadingBackdrop from "../../components/global/loadingBackdrop"
import EditHeading from "../../components/global/editHeading"
import { NftsContext } from "../../controllers/nfts"
import ImagePicker from "../../components/global/imagePicker"
import { AddCircleRounded } from "@mui/icons-material"
import AttributeCard from "../../components/nft/attributeCard"
import { NftCatalogStatus } from "../../services/config/enum"
import NftContractCard from "../../components/nft/nftContractCard"
import ISODatePicker from "../../components/global/isoDatePicker"

const NftCatalogEdit = () => {
  const { animation, setAnimation, setChangesSaved, showPadding } =
    useContext(MainContext)
  const {
    loading: controllerLoading,
    setLoading: setControllerLoading,
    editMode,
    setEditMode,
    doneChanges,
    cancelChanges,
    currentNftCatalog,
    setCurrentNftCatalog,
    getCurrentNftCatalog,
    updateNftCatalog,
    preChangesCurrentNftCatalog,
    hasError,
    nameError,
    setNameError,
    descriptionError,
    setDescriptionError,
    mintAtError,
    setMintAtError,
    symbolError,
    setSymbolError,
    setAttributesError,
    setContractsError,
  } = useContext(NftsContext)
  const { catalogId } = useParams()
  const navigate = useNavigate()

  // prevent tab close
  useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault()
      console.log("beforeunload event triggered")
      return (event.returnValue = "Are you sure you want to exit?")
    }

    window.addEventListener("beforeunload", handleTabClose)

    return () => {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [])

  // loading
  const [loading, setLoading] = useState<boolean>(true)

  // page error
  const [pageError, setPageError] = useState<boolean>(false)

  // fetch current product
  const fetchAll = async () => {
    const noErrors = await getCurrentNftCatalog(catalogId)
    if (noErrors) {
      setLoading(false)
      setControllerLoading(false)
    } else {
      setPageError(true)
    }
  }

  useEffect(() => {
    fetchAll()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // check mintAt, attributes and contracts errors
  useEffect(() => {
    if (currentNftCatalog) {
      if (
        !currentNftCatalog.attributes.length ||
        currentNftCatalog.attributes.some(
          (attribute) => !attribute.trait_type
        ) ||
        currentNftCatalog.attributes.some((attribute) => !attribute.value)
      ) {
        setAttributesError(true)
      } else {
        setAttributesError(false)
      }

      if (
        !currentNftCatalog.contracts ||
        !currentNftCatalog.contracts.length ||
        currentNftCatalog.contracts.some((contract) => !contract)
      ) {
        setContractsError(true)
      } else {
        setContractsError(false)
      }

      if (
        !currentNftCatalog.mintAt ||
        (currentNftCatalog.mintAt &&
          new Date(currentNftCatalog.mintAt) <= new Date())
      ) {
        setMintAtError(true)
      } else {
        setMintAtError(false)
      }
    }
  }, [currentNftCatalog])

  // set edit mode to false if nft catalog status !== IDLE
  useEffect(() => {
    if (
      currentNftCatalog &&
      currentNftCatalog.status !== NftCatalogStatus.IDLE
    ) {
      setEditMode(false)
    } else {
      setEditMode(true)
    }
  }, [currentNftCatalog])

  return loading ? (
    <PageSpinner
      showBackButton={pageError}
      backButtonOnClick={() => {
        setAnimation(false)
        navigate("/marketplace")
      }}
    />
  ) : (
    <EditContainer>
      <EditHeading
        saveButtonLoading={controllerLoading}
        backButtonOnClick={() => {
          setAnimation(false)
          setTimeout(() => {
            navigate("/nftcatalogs")
          }, 300)
        }}
        title={preChangesCurrentNftCatalog.name}
        id={preChangesCurrentNftCatalog.id}
        editModeActive={editMode}
        setEditModeActive={setEditMode}
        saveButtonDisabled={hasError || !doneChanges}
        undoButtonDisabled={!doneChanges}
        doneChanges={doneChanges}
        undoButtonOnClick={() => {
          cancelChanges()
        }}
        editSwitchDisabled={currentNftCatalog.status !== NftCatalogStatus.IDLE}
        showButtons
        showEditButton
        saveButtonOnClick={async () => {
          setControllerLoading(true)

          const noErrors = await updateNftCatalog()
          if (noErrors) {
            setChangesSaved(true)
          }

          setControllerLoading(false)
        }}
      />
      <Grow
        in={animation}
        timeout={300}
        style={{
          margin: showPadding === "yes" ? 16 : 0,
          marginTop: 0,
        }}
      >
        <Card>
          <CardContent
            style={{
              height:
                showPadding === "yes"
                  ? "calc(100vh - 150px)"
                  : "calc(100vh - 133px)",
              maxHeight:
                showPadding === "yes"
                  ? "calc(100vh - 150px)"
                  : "calc(100vh - 133px)",
              overflowY: "scroll",
            }}
          >
            <Stack spacing={2}>
              <Typography variant="h6" className="card-title">
                Configuration
              </Typography>
              <TextField
                fullWidth
                size="small"
                label="Name"
                disabled={!editMode}
                value={currentNftCatalog.name}
                onChange={(e) => {
                  setCurrentNftCatalog({
                    ...currentNftCatalog,
                    name: e.target.value,
                  })

                  // errors check
                  if (!e.target.value) {
                    setNameError(true)
                  } else {
                    setNameError(false)
                  }
                }}
                error={nameError}
                helperText={nameError ? "This field is required" : ""}
              />
              <TextField
                fullWidth
                size="small"
                label="Description"
                disabled={!editMode}
                value={currentNftCatalog.description}
                onChange={(e) => {
                  setCurrentNftCatalog({
                    ...currentNftCatalog,
                    description: e.target.value,
                  })

                  // errors check
                  if (!e.target.value) {
                    setDescriptionError(true)
                  } else {
                    setDescriptionError(false)
                  }
                }}
                error={descriptionError}
                helperText={descriptionError ? "This field is required" : ""}
                multiline
                rows={2}
              />
              <Stack direction="row" spacing={2}>
                <ISODatePicker
                  label="Mint date"
                  date={currentNftCatalog.mintAt}
                  setDate={(newDate: string) => {
                    setCurrentNftCatalog({
                      ...currentNftCatalog,
                      mintAt: newDate,
                    })
                  }}
                  error={
                    mintAtError &&
                    currentNftCatalog.status === NftCatalogStatus.IDLE
                  }
                  errorLabel={
                    currentNftCatalog.mintAt
                      ? "Mint date must be in the future"
                      : "Invalid date"
                  }
                  disabled={!editMode}
                  hour={12}
                />
                <TextField
                  fullWidth
                  size="small"
                  label="Symbol"
                  disabled={!editMode}
                  value={currentNftCatalog.symbol}
                  onChange={(e) => {
                    setCurrentNftCatalog({
                      ...currentNftCatalog,
                      symbol: e.target.value,
                    })

                    // errors check
                    if (!e.target.value) {
                      setSymbolError(true)
                    } else {
                      setSymbolError(false)
                    }
                  }}
                  error={symbolError}
                  helperText={symbolError ? "This field is required" : ""}
                />
              </Stack>
              <Typography variant="h6" className="card-title">
                Image
              </Typography>
              <ImagePicker
                title="Image"
                pickedImage={currentNftCatalog.image ?? ""}
                setPickedImage={(selectedImage: {
                  url: string
                  id: string
                  description?: string
                }) => {
                  currentNftCatalog.image = selectedImage.url
                  setCurrentNftCatalog({ ...currentNftCatalog })
                }}
                filters={{ category: "nft" }}
                disableCategoryFilter
                height={200}
                disabled={!editMode}
              />
              <Typography variant="h6" className="card-title">
                Attributes
              </Typography>
              {currentNftCatalog.attributes.length ? (
                <Stack>
                  {currentNftCatalog.attributes.map((attribute, index) => (
                    <AttributeCard
                      key={index}
                      index={index}
                      attribute={attribute}
                    />
                  ))}
                </Stack>
              ) : null}
              <Button
                fullWidth
                disabled={!editMode}
                endIcon={<AddCircleRounded />}
                variant="outlined"
                style={{
                  maxHeight: 40,
                  minHeight: 40,
                }}
                onClick={() => {
                  currentNftCatalog.attributes.push({
                    trait_type: "",
                    value: "",
                  })
                  setCurrentNftCatalog({ ...currentNftCatalog })
                }}
              >
                Add attribute
              </Button>
              <Typography variant="h6" className="card-title">
                Contracts
              </Typography>
              {currentNftCatalog.contracts &&
              currentNftCatalog.contracts.length ? (
                <Stack>
                  {currentNftCatalog.contracts.map((contract, index) => (
                    <NftContractCard
                      key={index}
                      index={index}
                      contract={contract}
                    />
                  ))}
                </Stack>
              ) : null}
            </Stack>
          </CardContent>
        </Card>
      </Grow>
      <LoadingBackdrop open={controllerLoading} />
    </EditContainer>
  )
}

export default NftCatalogEdit
