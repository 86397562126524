import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react"
import Media from "../models/media"
import { mediaMockData } from "../services/mockData/mediaMockData"
import {
  getMediaUploadUrl,
  listMedia,
  listMediaTags,
  mediaCategoriesGet,
  searchMedia,
} from "../services/graphql/queries"
import { logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"
import { useMockDataForMediaList } from "../services/config/constants"
import { MediaSize } from "../services/config/enum"
import axios from "axios"
import { deleteMedia, updateMediaMetadata } from "../services/graphql/mutations"

interface AutocompleteOption {
  label: string
  id: string
}

interface MediaContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  mediaList: Media[]
  setMediaList: Dispatch<SetStateAction<Media[]>>
  getMediaList: (withLoading?: boolean) => void
  uploadMedia: (
    title: string,
    mediaTags: string[],
    category: string,
    size: MediaSize | null,
    image: {
      name: string
      size: number
      dataUrl: string
    },
    description?: string,
    fileType?: string
  ) => Promise<boolean>
  searchMediaList: ({
    title,
    size,
    category,
    mediaTags,
  }: {
    title: string
    size: AutocompleteOption[] | null
    category: AutocompleteOption | null
    mediaTags: string[]
  }) => void
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
  searchSize: AutocompleteOption[] | null
  setSearchSize: Dispatch<SetStateAction<AutocompleteOption[] | null>>
  searchCategory: AutocompleteOption | null
  setSearchCategory: Dispatch<SetStateAction<AutocompleteOption | null>>
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  hasSearched: boolean
  loadMoreMedia: () => Promise<boolean>
  mediaListNextToken: string | null
  deleteMediaFromList: (mediaId: string) => Promise<boolean>
  searchMediaTags: string[]
  setSearchMediaTags: Dispatch<SetStateAction<string[]>>
  mediaTagsList: string[]
  mediaTagsLoading: boolean
  updateMedia: (
    id: string,
    title: string,
    description: string,
    mediaTags: string[]
  ) => Promise<boolean>
  mediaCategoriesList: string[]
}

const MediaContext = createContext<MediaContextInterface>({
  loading: true,
  setLoading: () => {},
  mediaList: [],
  setMediaList: () => {},
  getMediaList: () => {},
  uploadMedia: async () => true,
  searchMediaList: () => {},
  searchValue: "",
  setSearchValue: () => {},
  searchSize: null,
  setSearchSize: () => {},
  searchCategory: null,
  setSearchCategory: () => {},
  updatingList: false,
  setUpdatingList: () => {},
  hasSearched: false,
  loadMoreMedia: async () => true,
  mediaListNextToken: null,
  deleteMediaFromList: async () => true,
  searchMediaTags: null,
  setSearchMediaTags: () => {},
  mediaTagsList: [],
  mediaTagsLoading: true,
  updateMedia: async () => true,
  mediaCategoriesList: [],
})

const MediaController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)
  const client = useApolloClient()

  const [loading, setLoading] = useState<boolean>(true) // loading
  const [mediaList, setMediaList] = useState<Media[]>([]) // all media list
  const [mediaListNextToken, setMediaListNextToken] = useState<string | null>(
    null
  ) // media list next token
  const [searchValue, setSearchValue] = useState<string>("") // search input value
  const [searchSize, setSearchSize] = useState<AutocompleteOption[] | null>(
    null
  ) // search size filter
  const [searchCategory, setSearchCategory] =
    useState<AutocompleteOption | null>(null) // search category filter
  const [searchMediaTags, setSearchMediaTags] = useState<string[]>([]) // search media tags filter
  const [mediaTagsList, setMediaTagsList] = useState<string[]>([]) // list of all media tags
  const [mediaTagsLoading, setMediaTagsLoading] = useState<boolean>(true) // media tags list loading
  const [updatingList, setUpdatingList] = useState<boolean>(false) // indicates a list update
  const [hasSearched, setHasSearched] = useState<boolean>(false) // if user has searched or not
  const [mediaCategoriesList, setMediaCategoriesList] = useState<string[]>([]) // list of media categories

  // queries
  const [listMediaQuery] = useLazyQuery(listMedia)
  const [searchMediaQuery] = useLazyQuery(searchMedia)
  const [listMediaTagsQuery] = useLazyQuery(listMediaTags)
  const [mediaCategoriesGetQuery] = useLazyQuery(mediaCategoriesGet)

  // mutations
  const [deleteMediaMutation] = useMutation(deleteMedia)
  const [updateMediaMetadataMutation] = useMutation(updateMediaMetadata)

  // get media categories
  const getMediaCategories = useCallback(async () => {
    panelStatus.filter((item) => item.name === "Media Categories")[0].loading =
      true
    setPanelStatus([...panelStatus])

    try {
      logger(Status.Api, "QUERY mediaCategoriesGet", mediaCategoriesGet)
      const { data } = await mediaCategoriesGetQuery({
        fetchPolicy: "no-cache",
      })
      const dataToSet = data.__type.enumValues.map((item: any) => item.name)
      logger(Status.Info, "media categories list", dataToSet)

      setMediaCategoriesList(dataToSet)

      panelStatus.filter((item) => item.name === "Media Categories")[0].status =
        "success"
      panelStatus.filter(
        (item) => item.name === "Media Categories"
      )[0].loading = false
      setPanelStatus([...panelStatus])
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `mediaCategoriesGet`, e.message)
      }
      panelStatus.filter((item) => item.name === "Media Categories")[0].status =
        "error"
      panelStatus.filter(
        (item) => item.name === "Media Categories"
      )[0].loading = false
      setPanelStatus([...panelStatus])
    }
  }, [setError, setErrorMessage])

  // get all media list
  const getMediaList = useCallback(
    async (withLoading: boolean = true) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter((item) => item.name === "Media")[0].loading = true
        setPanelStatus([...panelStatus])
      }

      try {
        logger(Status.Api, "QUERY mediaList", listMedia)
        const { data } = await listMediaQuery({
          variables: {
            input: { limit: Math.round(window.innerHeight / 74) + 10 },
          },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "media list", data.mediaList.items)
        setMediaListNextToken(data.mediaList.nextToken)

        if (useMockDataForMediaList) {
          setMediaList(mediaMockData)
        } else {
          setMediaList(data.mediaList.items)
        }

        setHasSearched(false)
        setUpdatingList(false)
        if (withLoading) {
          setLoading(false)
        }
        panelStatus.filter((item) => item.name === "Media")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "Media")[0].loading = false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `mediaList`, e.message)
        }
        setUpdatingList(false)
        if (withLoading) {
          setLoading(false)
        }
        panelStatus.filter((item) => item.name === "Media")[0].status = "error"
        panelStatus.filter((item) => item.name === "Media")[0].loading = false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // load more media and add them to media list
  const loadMoreMedia = async () => {
    try {
      if (
        !searchValue.length &&
        !searchSize &&
        !searchCategory &&
        !searchMediaTags.length
      ) {
        logger(Status.Api, "QUERY mediaList", listMedia)
        const { data } = await listMediaQuery({
          variables: {
            input: {
              limit: Math.round(window.innerHeight / 74) + 10,
              nextToken: mediaListNextToken,
            },
          },
          fetchPolicy: "no-cache",
        })
        setMediaListNextToken(data.mediaList.nextToken)
        logger(Status.Info, "media list", [
          ...mediaList,
          ...data.mediaList.items,
        ])

        setMediaList([...mediaList, ...data.mediaList.items])
      } else {
        let input: {
          title?: string
          size?: MediaSize[]
          category?: string
          mediaTags?: string[]
          nextToken?: string
        } = {}
        if (searchValue.length) {
          input.title = searchValue
        }
        if (searchSize) {
          input.size = searchSize.map((item) => item.id) as MediaSize[]
        }
        if (searchCategory) {
          input.category = searchCategory.id
        }
        if (searchMediaTags.length) {
          input.mediaTags = searchMediaTags
        }
        if (mediaListNextToken) {
          input.nextToken = mediaListNextToken
        }

        logger(Status.Api, "QUERY mediaSearch", searchMedia)
        const { data } = await searchMediaQuery({
          variables: { input: input },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "searched media list", [
          ...mediaList,
          ...data.mediaSearch.items,
        ])
        setMediaListNextToken(data.mediaSearch.nextToken)

        setMediaList([...mediaList, ...data.mediaSearch.items])
      }

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `mediaList`, e.message)
      }

      return false
    }
  }

  // search in media list
  const searchMediaList = useCallback(
    async ({
      title,
      size,
      category,
      mediaTags,
    }: {
      title: string
      size: AutocompleteOption[] | null
      category: AutocompleteOption | null
      mediaTags: string[]
    }) => {
      setUpdatingList(true)

      try {
        let input: {
          limit: number
          title?: string
          size?: MediaSize[]
          category?: string
          mediaTags?: string[]
        } = {
          limit: Math.round(window.innerHeight / 74) + 10,
        }
        if (title.length) {
          input.title = title
        }
        if (size) {
          input.size = size.map((item) => item.id) as MediaSize[]
        }
        if (category) {
          input.category = category.id
        }
        if (mediaTags.length) {
          input.mediaTags = mediaTags
        }

        logger(Status.Api, "QUERY mediaSearch", searchMedia)
        const { data } = await searchMediaQuery({
          variables: { input: input },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "searched media list", data.mediaSearch.items)
        setMediaListNextToken(data.mediaSearch.nextToken)

        setMediaList(data.mediaSearch.items)

        setHasSearched(true)
        setUpdatingList(false)
        setLoading(false)
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `mediaSearch`, e.message)
        }
        setUpdatingList(false)
        setLoading(false)
      }
    },
    [setError, setErrorMessage]
  )

  // upload media
  const uploadMedia = async (
    title: string,
    mediaTags: string[],
    category: string,
    size: MediaSize | null,
    image: {
      name: string
      size: number
      dataUrl: string
    },
    description?: string,
    fileType?: string
  ) => {
    setLoading(true)

    let input: {
      title: string
      mediaTags: string[]
      category: string
      size?: MediaSize
      description?: string
      fileType?: string
    } = {
      title: title,
      mediaTags: mediaTags,
      category: category,
    }
    if (size) {
      input.size = size
    }
    if (description) {
      input.description = description
    }
    if (fileType) {
      input.fileType = fileType
    }

    try {
      logger(Status.Api, "QUERY mediaUploadUrlGet", getMediaUploadUrl)
      const { data } = await client.query({
        query: getMediaUploadUrl,
        variables: {
          input: input,
        },
      })
      logger(Status.Info, "media upload url", data.mediaUploadUrlGet)

      logger(Status.Api, "axios S3 PUT")
      const blob = await fetch(image.dataUrl).then((r) => r.blob())
      let config = {
        method: "put",
        url: data.mediaUploadUrlGet.s3Url,
        headers: {
          "Content-Type":
            image.name.slice(image.name.lastIndexOf(".") + 1) === "svg"
              ? `image/${image.name.slice(image.name.lastIndexOf(".") + 1)}+xml`
              : `image/${image.name.slice(image.name.lastIndexOf(".") + 1)}`,
        },
        data: blob,
      }
      await axios(config)

      if (
        !searchValue.length ||
        (searchValue.length &&
          data.mediaUploadUrlGet.title
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        !searchSize ||
        (searchSize &&
          searchSize.filter((item) => item.id === data.mediaUploadUrlGet.size)
            .length) ||
        !searchCategory ||
        (searchCategory.id &&
          data.mediaUploadUrlGet.category === searchCategory)
      ) {
        mediaList.unshift({
          id: data.mediaUploadUrlGet.id,
          url: data.mediaUploadUrlGet.url,
          title: data.mediaUploadUrlGet.title,
          description: data.mediaUploadUrlGet.description,
          size: data.mediaUploadUrlGet.size,
          category: data.mediaUploadUrlGet.category,
          createdAt: data.mediaUploadUrlGet.createdAt,
          updatedAt: data.mediaUploadUrlGet.updatedAt,
          mediaTags: data.mediaUploadUrlGet.mediaTags,
        })
        setMediaList([...mediaList])
      }

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `mediaUploadUrlGet`, e.message)
      }
      return false
    }
  }

  // delete media
  const deleteMediaFromList = async (mediaId: string) => {
    try {
      logger(Status.Api, "MUTATION mediaDelete", deleteMedia)
      const { data } = await deleteMediaMutation({
        variables: { input: { id: mediaId } },
      })
      logger(
        Status.Info,
        `${mediaId} media deleted: ${data.mediaDelete.deleted}`
      )

      setTimeout(() => {
        if (
          searchValue.length ||
          searchSize ||
          searchCategory ||
          searchMediaTags.length
        ) {
          searchMediaList({
            title: searchValue,
            size: searchSize,
            category: searchCategory,
            mediaTags: searchMediaTags,
          })
        } else {
          getMediaList(false)
        }
      }, 4000)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `mediaDelete`, e.message)
      }

      setTimeout(() => {
        if (
          searchValue.length ||
          searchSize ||
          searchCategory ||
          searchMediaTags.length
        ) {
          searchMediaList({
            title: searchValue,
            size: searchSize,
            category: searchCategory,
            mediaTags: searchMediaTags,
          })
        } else {
          getMediaList(false)
        }
      }, 4000)

      return false
    }
  }

  // get all media tags
  const getMediaTagsList = useCallback(async () => {
    setMediaTagsLoading(true)
    panelStatus.filter((item) => item.name === "Media tags")[0].loading = true
    setPanelStatus([...panelStatus])

    try {
      logger(Status.Api, "QUERY mediaTagsList", listMediaTags)
      const { data } = await listMediaTagsQuery({
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "media tags list", data.mediaTagsList)

      setMediaTagsList(data.mediaTagsList)

      setMediaTagsLoading(false)
      panelStatus.filter((item) => item.name === "Media tags")[0].status =
        "success"
      panelStatus.filter((item) => item.name === "Media tags")[0].loading =
        false
      setPanelStatus([...panelStatus])
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `mediaTagsList`, e.message)
      }
      setMediaTagsLoading(false)
      panelStatus.filter((item) => item.name === "Media tags")[0].status =
        "error"
      panelStatus.filter((item) => item.name === "Media tags")[0].loading =
        false
      setPanelStatus([...panelStatus])
    }
  }, [setError, setErrorMessage])

  // update media metadata
  const updateMedia = async (
    id: string,
    title: string,
    description: string,
    mediaTags: string[]
  ) => {
    try {
      logger(Status.Api, "MUTATION mediaMetadataUpdate", updateMediaMetadata)
      const { data } = await updateMediaMetadataMutation({
        variables: {
          input: {
            id: id,
            title: title,
            description: description,
            mediaTags: mediaTags,
          },
        },
      })
      logger(Status.Info, `updated media ${id}`, data.mediaMetadataUpdate)

      let indexToChange = mediaList.findIndex((item) => item.id === id)
      mediaList.splice(indexToChange, 1, data.mediaMetadataUpdate)
      setMediaList([...mediaList])

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `mediaMetadataUpdate`, e.message)
      }

      return false
    }
  }

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter((item) => item.name === "Media")[0].updateFunction =
      getMediaList
    panelStatus.filter((item) => item.name === "Media tags")[0].updateFunction =
      getMediaTagsList
    panelStatus.filter(
      (item) => item.name === "Media Categories"
    )[0].updateFunction = getMediaCategories
  }, [])

  // initial fetch
  useEffect(() => {
    getMediaTagsList()
    getMediaCategories()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MediaContext.Provider
      value={{
        loading,
        setLoading,
        mediaList,
        setMediaList,
        getMediaList,
        uploadMedia,
        searchMediaList,
        searchValue,
        setSearchValue,
        searchSize,
        setSearchSize,
        searchCategory,
        setSearchCategory,
        updatingList,
        setUpdatingList,
        hasSearched,
        loadMoreMedia,
        mediaListNextToken,
        deleteMediaFromList,
        searchMediaTags,
        setSearchMediaTags,
        mediaTagsList,
        mediaTagsLoading,
        updateMedia,
        mediaCategoriesList,
      }}
    >
      {children}
    </MediaContext.Provider>
  )
}

export { MediaController, MediaContext }
