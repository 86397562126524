import { Cancel, Search } from "@mui/icons-material"
import {
  Button,
  Divider,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material"
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useContext,
  useEffect,
  useState,
} from "react"
import { BadgesContext } from "../../controllers/badges"
import ListFilters from "../global/listFilters"

interface AutocompleteOption {
  label: string
  id: string
}

const BadgesListTopBar = ({
  loading,
  textFieldValue,
  textFieldPlaceholder,
  addButtonLabel,
  addButtonOnClick,
  textFieldOnChange,
  textFieldOnKeyDown,
  cancelButtonOnClick,
  searchButtonOnClick,
  forDialog = false,
  addButtonDisabled = false,
  categoryFilter,
}: {
  loading: boolean
  textFieldValue: string
  textFieldPlaceholder: string
  addButtonLabel: string
  textFieldOnChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  textFieldOnKeyDown: KeyboardEventHandler<HTMLDivElement>
  cancelButtonOnClick: MouseEventHandler<HTMLDivElement>
  searchButtonOnClick: MouseEventHandler<HTMLButtonElement>
  addButtonOnClick: MouseEventHandler<HTMLButtonElement>
  forDialog?: boolean
  addButtonDisabled?: boolean
  categoryFilter?: AutocompleteOption
}) => {
  const {
    searchLang,
    setSearchLang,
    searchCategory,
    setSearchCategory,
    updatingList,
    setUpdatingList,
    getBadgesList,
    searchBadgesList,
  } = useContext(BadgesContext)

  // search when filters update
  const [firstRender, setFirstRender] = useState<boolean>(true)

  useEffect(() => {
    if (!firstRender) {
      if (!searchLang && !searchCategory && !textFieldValue.length) {
        setUpdatingList(true)
        getBadgesList(false)
      } else {
        searchBadgesList()
      }
    } else {
      setFirstRender(false)
    }
  }, [searchLang, searchCategory])

  return (
    <Stack
      style={{
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 4,
      }}
      spacing={1}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          disabled={loading || updatingList}
          size="small"
          fullWidth
          id="outlined-basic"
          placeholder={textFieldPlaceholder}
          variant="outlined"
          value={textFieldValue}
          onChange={textFieldOnChange}
          onKeyDown={textFieldOnKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: textFieldValue.length > 0 && (
              <InputAdornment position="start">
                <div
                  style={{
                    marginTop: 6,
                    marginRight: -6,
                    cursor: "pointer",
                  }}
                  onClick={loading ? () => {} : cancelButtonOnClick}
                >
                  <Cancel style={{ width: 18 }} />
                </div>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="outlined"
          disabled={textFieldValue.length < 3 || loading || updatingList}
          onClick={searchButtonOnClick}
          style={{ height: 40, minWidth: 74 }}
        >
          Search
        </Button>
        {!forDialog && <Divider orientation="vertical" flexItem />}
        {!forDialog && (
          <Button
            variant="contained"
            disabled={loading || addButtonDisabled}
            style={{
              whiteSpace: "nowrap",
              minWidth: "max-content",
              height: 40,
            }}
            onClick={addButtonOnClick}
          >
            {addButtonLabel}
          </Button>
        )}
      </Stack>
      <ListFilters
        disabled={loading || updatingList}
        filters={[
          { type: "language", value: searchLang, setValue: setSearchLang },
          {
            type: "category",
            value: searchCategory,
            setValue: setSearchCategory,
            disabled: forDialog ? true : false,
          },
        ]}
      />
    </Stack>
  )
}

export default BadgesListTopBar
