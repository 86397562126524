import { DeleteRounded, DragHandleRounded } from "@mui/icons-material"
import { IconButton, Stack } from "@mui/material"
import { ReactNode } from "react"
import { DraggableProvided } from "react-beautiful-dnd"

const SliceWrapper = ({
  children,
  editMode,
  sliceIndex,
  removeSlice,
  provided,
}: {
  children: ReactNode
  editMode: boolean
  sliceIndex: number
  removeSlice: (sliceToRemoveIndex: number) => void
  provided: DraggableProvided
}) => {
  return (
    <Stack
      style={{
        position: "relative",
        paddingInline: 12,
        paddingBottom: 14,
        paddingTop: 0,
        boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.3)",
        marginInline: 2,
        borderRadius: 5,
        backgroundColor: "white",
      }}
      spacing={2}
    >
      <IconButton
        aria-label="delete"
        style={{
          position: "absolute",
          top: 8,
          right: 12,
          opacity: editMode ? 1 : 0.6,
          transition: "160ms",
        }}
        disabled={!editMode}
        onClick={() => {
          removeSlice(sliceIndex)
        }}
        color="error"
      >
        <DeleteRounded fontSize="small" />
      </IconButton>
      <div
        style={{
          width: 36,
          height: 36,
          position: "absolute",
          top: 0,
          right: 40,
          opacity: editMode ? 1 : 0.2,
          transition: "160ms",
          pointerEvents: editMode ? undefined : "none",
        }}
        {...provided.dragHandleProps}
      >
        <DragHandleRounded fontSize="small" />
      </div>
      {children}
    </Stack>
  )
}

export default SliceWrapper
